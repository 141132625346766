@import url("https://fonts.googleapis.com/css?family=Roboto:300,400,500,700,900");
@import url("https://fonts.googleapis.com/css?family=Roboto+Mono");
@import url("https://fonts.googleapis.com/css?family=Lato:300,400,700,900");
@import url("https://fonts.googleapis.com/css?family=Rubik:300,400");

@font-face {
    font-family: 'IBM Plex Sans';
    font-style: normal;
    font-weight: 700;
    src: local("IBM Plex Sans Bold"), local("IBMPlexSans-Bold"), url("../fonts/ibm-plex-sans/complete/woff/IBMPlexSans-Bold.woff") format("woff")
}

@font-face {
    font-family: 'IBM Plex Sans';
    font-style: normal;
    font-weight: 700;
    src: local("IBM Plex Sans Bold"), local("IBMPlexSans-Bold"), url("../fonts/ibm-plex-sans/split/woff2/IBMPlexSans-Bold-Cyrillic.woff2") format("woff2");
    unicode-range: U+0400-045F, U+0472-0473, U+0490-049D, U+04A0-04A5, U+04AA-04AB, U+04AE-04B3, U+04B6-04BB, U+04C0-04C2, U+04CF-04D9, U+04DC-04DF, U+04E2-04E9, U+04EE-04F5, U+04F8-04F9
}

@font-face {
    font-family: 'IBM Plex Sans';
    font-style: normal;
    font-weight: 700;
    src: local("IBM Plex Sans Bold"), local("IBMPlexSans-Bold"), url("../fonts/ibm-plex-sans/split/woff2/IBMPlexSans-Bold-Pi.woff2") format("woff2");
    unicode-range: U+0E3F, U+2032-2033, U+2070, U+2075-2079, U+2080-2081, U+2083, U+2085-2089, U+2113, U+2116, U+2126, U+212E, U+2150-2151, U+2153-215E, U+2190-2199, U+21A9-21AA, U+21B0-21B3, U+21B6-21B7, U+21BA-21BB, U+21C4, U+21C6, U+2202, U+2206, U+220F, U+2211, U+221A, U+221E, U+222B, U+2248, U+2260, U+2264-2265, U+25CA, U+2713, U+274C, U+2B0E-2B11, U+EBE1-EBE7, U+ECE0, U+EFCC
}

@font-face {
    font-family: 'IBM Plex Sans';
    font-style: normal;
    font-weight: 700;
    src: local("IBM Plex Sans Bold"), local("IBMPlexSans-Bold"), url("../fonts/ibm-plex-sans/split/woff2/IBMPlexSans-Bold-Latin3.woff2") format("woff2");
    unicode-range: U+0102-0103, U+1EA0-1EF9, U+20AB
}

@font-face {
    font-family: 'IBM Plex Sans';
    font-style: normal;
    font-weight: 700;
    src: local("IBM Plex Sans Bold"), local("IBMPlexSans-Bold"), url("../fonts/ibm-plex-sans/split/woff2/IBMPlexSans-Bold-Latin2.woff2") format("woff2");
    unicode-range: U+0100-024F, U+0259, U+1E00-1EFF, U+20A0-20AB, U+20AD-20CF, U+2C60-2C7F, U+A720-A7FF, U+FB01-FB02
}

@font-face {
    font-family: 'IBM Plex Sans';
    font-style: normal;
    font-weight: 700;
    src: local("IBM Plex Sans Bold"), local("IBMPlexSans-Bold"), url("../fonts/ibm-plex-sans/split/woff2/IBMPlexSans-Bold-Latin1.woff2") format("woff2");
    unicode-range: U+0000, U+000D, U+0020-007E, U+00A0-00A3, U+00A4-00FF, U+0131, U+0152-0153, U+02C6, U+02DA, U+02DC, U+2013-2014, U+2018-201A, U+201C-201E, U+2020-2022, U+2026, U+2030, U+2039-203A, U+2044, U+2074, U+20AC, U+2122, U+2212, U+FB01-FB02
}

@font-face {
    font-family: 'IBM Plex Sans';
    font-style: normal;
    font-weight: 700;
    src: local("IBM Plex Sans Bold"), local("IBMPlexSans-Bold"), url("../fonts/ibm-plex-sans/split/woff2/IBMPlexSans-Bold-Greek.woff2") format("woff2");
    unicode-range: U+0384-038A, U+038C, U+038E-03A1, U+03A3-03CE
}

@font-face {
    font-family: 'IBM Plex Sans';
    font-style: italic;
    font-weight: 700;
    src: local("IBM Plex Sans Bold Italic"), local("IBMPlexSans-BoldItalic"), url("../fonts/ibm-plex-sans/complete/woff/IBMPlexSans-BoldItalic.woff") format("woff")
}

@font-face {
    font-family: 'IBM Plex Sans';
    font-style: italic;
    font-weight: 700;
    src: local("IBM Plex Sans Bold Italic"), local("IBMPlexSans-BoldItalic"), url("../fonts/ibm-plex-sans/split/woff2/IBMPlexSans-BoldItalic-Cyrillic.woff2") format("woff2");
    unicode-range: U+0400-045F, U+0472-0473, U+0490-049D, U+04A0-04A5, U+04AA-04AB, U+04AE-04B3, U+04B6-04BB, U+04C0-04C2, U+04CF-04D9, U+04DC-04DF, U+04E2-04E9, U+04EE-04F5, U+04F8-04F9
}

@font-face {
    font-family: 'IBM Plex Sans';
    font-style: italic;
    font-weight: 700;
    src: local("IBM Plex Sans Bold Italic"), local("IBMPlexSans-BoldItalic"), url("../fonts/ibm-plex-sans/split/woff2/IBMPlexSans-BoldItalic-Pi.woff2") format("woff2");
    unicode-range: U+0E3F, U+2032-2033, U+2070, U+2075-2079, U+2080-2081, U+2083, U+2085-2089, U+2113, U+2116, U+2126, U+212E, U+2150-2151, U+2153-215E, U+2190-2199, U+21A9-21AA, U+21B0-21B3, U+21B6-21B7, U+21BA-21BB, U+21C4, U+21C6, U+2202, U+2206, U+220F, U+2211, U+221A, U+221E, U+222B, U+2248, U+2260, U+2264-2265, U+25CA, U+2713, U+274C, U+2B0E-2B11, U+EBE1-EBE7, U+ECE0, U+EFCC
}

@font-face {
    font-family: 'IBM Plex Sans';
    font-style: italic;
    font-weight: 700;
    src: local("IBM Plex Sans Bold Italic"), local("IBMPlexSans-BoldItalic"), url("../fonts/ibm-plex-sans/split/woff2/IBMPlexSans-BoldItalic-Latin3.woff2") format("woff2");
    unicode-range: U+0102-0103, U+1EA0-1EF9, U+20AB
}

@font-face {
    font-family: 'IBM Plex Sans';
    font-style: italic;
    font-weight: 700;
    src: local("IBM Plex Sans Bold Italic"), local("IBMPlexSans-BoldItalic"), url("../fonts/ibm-plex-sans/split/woff2/IBMPlexSans-BoldItalic-Latin2.woff2") format("woff2");
    unicode-range: U+0100-024F, U+0259, U+1E00-1EFF, U+20A0-20AB, U+20AD-20CF, U+2C60-2C7F, U+A720-A7FF, U+FB01-FB02
}

@font-face {
    font-family: 'IBM Plex Sans';
    font-style: italic;
    font-weight: 700;
    src: local("IBM Plex Sans Bold Italic"), local("IBMPlexSans-BoldItalic"), url("../fonts/ibm-plex-sans/split/woff2/IBMPlexSans-BoldItalic-Latin1.woff2") format("woff2");
    unicode-range: U+0000, U+000D, U+0020-007E, U+00A0-00A3, U+00A4-00FF, U+0131, U+0152-0153, U+02C6, U+02DA, U+02DC, U+2013-2014, U+2018-201A, U+201C-201E, U+2020-2022, U+2026, U+2030, U+2039-203A, U+2044, U+2074, U+20AC, U+2122, U+2212, U+FB01-FB02
}

@font-face {
    font-family: 'IBM Plex Sans';
    font-style: italic;
    font-weight: 700;
    src: local("IBM Plex Sans Bold Italic"), local("IBMPlexSans-BoldItalic"), url("../fonts/ibm-plex-sans/split/woff2/IBMPlexSans-BoldItalic-Greek.woff2") format("woff2");
    unicode-range: U+0384-038A, U+038C, U+038E-03A1, U+03A3-03CE
}

@font-face {
    font-family: 'IBM Plex Sans';
    font-style: normal;
    font-weight: 200;
    src: local("IBM Plex Sans ExtraLight"), local("IBMPlexSans-ExtraLight"), url("../fonts/ibm-plex-sans/complete/woff/IBMPlexSans-ExtraLight.woff") format("woff")
}

@font-face {
    font-family: 'IBM Plex Sans';
    font-style: normal;
    font-weight: 200;
    src: local("IBM Plex Sans ExtraLight"), local("IBMPlexSans-ExtraLight"), url("../fonts/ibm-plex-sans/split/woff2/IBMPlexSans-ExtraLight-Cyrillic.woff2") format("woff2");
    unicode-range: U+0400-045F, U+0472-0473, U+0490-049D, U+04A0-04A5, U+04AA-04AB, U+04AE-04B3, U+04B6-04BB, U+04C0-04C2, U+04CF-04D9, U+04DC-04DF, U+04E2-04E9, U+04EE-04F5, U+04F8-04F9
}

@font-face {
    font-family: 'IBM Plex Sans';
    font-style: normal;
    font-weight: 200;
    src: local("IBM Plex Sans ExtraLight"), local("IBMPlexSans-ExtraLight"), url("../fonts/ibm-plex-sans/split/woff2/IBMPlexSans-ExtraLight-Pi.woff2") format("woff2");
    unicode-range: U+0E3F, U+2032-2033, U+2070, U+2075-2079, U+2080-2081, U+2083, U+2085-2089, U+2113, U+2116, U+2126, U+212E, U+2150-2151, U+2153-215E, U+2190-2199, U+21A9-21AA, U+21B0-21B3, U+21B6-21B7, U+21BA-21BB, U+21C4, U+21C6, U+2202, U+2206, U+220F, U+2211, U+221A, U+221E, U+222B, U+2248, U+2260, U+2264-2265, U+25CA, U+2713, U+274C, U+2B0E-2B11, U+EBE1-EBE7, U+ECE0, U+EFCC
}

@font-face {
    font-family: 'IBM Plex Sans';
    font-style: normal;
    font-weight: 200;
    src: local("IBM Plex Sans ExtraLight"), local("IBMPlexSans-ExtraLight"), url("../fonts/ibm-plex-sans/split/woff2/IBMPlexSans-ExtraLight-Latin3.woff2") format("woff2");
    unicode-range: U+0102-0103, U+1EA0-1EF9, U+20AB
}

@font-face {
    font-family: 'IBM Plex Sans';
    font-style: normal;
    font-weight: 200;
    src: local("IBM Plex Sans ExtraLight"), local("IBMPlexSans-ExtraLight"), url("../fonts/ibm-plex-sans/split/woff2/IBMPlexSans-ExtraLight-Latin2.woff2") format("woff2");
    unicode-range: U+0100-024F, U+0259, U+1E00-1EFF, U+20A0-20AB, U+20AD-20CF, U+2C60-2C7F, U+A720-A7FF, U+FB01-FB02
}

@font-face {
    font-family: 'IBM Plex Sans';
    font-style: normal;
    font-weight: 200;
    src: local("IBM Plex Sans ExtraLight"), local("IBMPlexSans-ExtraLight"), url("../fonts/ibm-plex-sans/split/woff2/IBMPlexSans-ExtraLight-Latin1.woff2") format("woff2");
    unicode-range: U+0000, U+000D, U+0020-007E, U+00A0-00A3, U+00A4-00FF, U+0131, U+0152-0153, U+02C6, U+02DA, U+02DC, U+2013-2014, U+2018-201A, U+201C-201E, U+2020-2022, U+2026, U+2030, U+2039-203A, U+2044, U+2074, U+20AC, U+2122, U+2212, U+FB01-FB02
}

@font-face {
    font-family: 'IBM Plex Sans';
    font-style: normal;
    font-weight: 200;
    src: local("IBM Plex Sans ExtraLight"), local("IBMPlexSans-ExtraLight"), url("../fonts/ibm-plex-sans/split/woff2/IBMPlexSans-ExtraLight-Greek.woff2") format("woff2");
    unicode-range: U+0384-038A, U+038C, U+038E-03A1, U+03A3-03CE
}

@font-face {
    font-family: 'IBM Plex Sans';
    font-style: italic;
    font-weight: 200;
    src: local("IBM Plex Sans ExtraLight Italic"), local("IBMPlexSans-ExtraLightItalic"), url("../fonts/ibm-plex-sans/complete/woff/IBMPlexSans-ExtraLightItalic.woff") format("woff")
}

@font-face {
    font-family: 'IBM Plex Sans';
    font-style: italic;
    font-weight: 200;
    src: local("IBM Plex Sans ExtraLight Italic"), local("IBMPlexSans-ExtraLightItalic"), url("../fonts/ibm-plex-sans/split/woff2/IBMPlexSans-ExtraLightItalic-Cyrillic.woff2") format("woff2");
    unicode-range: U+0400-045F, U+0472-0473, U+0490-049D, U+04A0-04A5, U+04AA-04AB, U+04AE-04B3, U+04B6-04BB, U+04C0-04C2, U+04CF-04D9, U+04DC-04DF, U+04E2-04E9, U+04EE-04F5, U+04F8-04F9
}

@font-face {
    font-family: 'IBM Plex Sans';
    font-style: italic;
    font-weight: 200;
    src: local("IBM Plex Sans ExtraLight Italic"), local("IBMPlexSans-ExtraLightItalic"), url("../fonts/ibm-plex-sans/split/woff2/IBMPlexSans-ExtraLightItalic-Pi.woff2") format("woff2");
    unicode-range: U+0E3F, U+2032-2033, U+2070, U+2075-2079, U+2080-2081, U+2083, U+2085-2089, U+2113, U+2116, U+2126, U+212E, U+2150-2151, U+2153-215E, U+2190-2199, U+21A9-21AA, U+21B0-21B3, U+21B6-21B7, U+21BA-21BB, U+21C4, U+21C6, U+2202, U+2206, U+220F, U+2211, U+221A, U+221E, U+222B, U+2248, U+2260, U+2264-2265, U+25CA, U+2713, U+274C, U+2B0E-2B11, U+EBE1-EBE7, U+ECE0, U+EFCC
}

@font-face {
    font-family: 'IBM Plex Sans';
    font-style: italic;
    font-weight: 200;
    src: local("IBM Plex Sans ExtraLight Italic"), local("IBMPlexSans-ExtraLightItalic"), url("../fonts/ibm-plex-sans/split/woff2/IBMPlexSans-ExtraLightItalic-Latin3.woff2") format("woff2");
    unicode-range: U+0102-0103, U+1EA0-1EF9, U+20AB
}

@font-face {
    font-family: 'IBM Plex Sans';
    font-style: italic;
    font-weight: 200;
    src: local("IBM Plex Sans ExtraLight Italic"), local("IBMPlexSans-ExtraLightItalic"), url("../fonts/ibm-plex-sans/split/woff2/IBMPlexSans-ExtraLightItalic-Latin2.woff2") format("woff2");
    unicode-range: U+0100-024F, U+0259, U+1E00-1EFF, U+20A0-20AB, U+20AD-20CF, U+2C60-2C7F, U+A720-A7FF, U+FB01-FB02
}

@font-face {
    font-family: 'IBM Plex Sans';
    font-style: italic;
    font-weight: 200;
    src: local("IBM Plex Sans ExtraLight Italic"), local("IBMPlexSans-ExtraLightItalic"), url("../fonts/ibm-plex-sans/split/woff2/IBMPlexSans-ExtraLightItalic-Latin1.woff2") format("woff2");
    unicode-range: U+0000, U+000D, U+0020-007E, U+00A0-00A3, U+00A4-00FF, U+0131, U+0152-0153, U+02C6, U+02DA, U+02DC, U+2013-2014, U+2018-201A, U+201C-201E, U+2020-2022, U+2026, U+2030, U+2039-203A, U+2044, U+2074, U+20AC, U+2122, U+2212, U+FB01-FB02
}

@font-face {
    font-family: 'IBM Plex Sans';
    font-style: italic;
    font-weight: 200;
    src: local("IBM Plex Sans ExtraLight Italic"), local("IBMPlexSans-ExtraLightItalic"), url("../fonts/ibm-plex-sans/split/woff2/IBMPlexSans-ExtraLightItalic-Greek.woff2") format("woff2");
    unicode-range: U+0384-038A, U+038C, U+038E-03A1, U+03A3-03CE
}

@font-face {
    font-family: 'IBM Plex Sans';
    font-style: italic;
    font-weight: 400;
    src: local("IBM Plex Sans Italic"), local("IBMPlexSans-Italic"), url("../fonts/ibm-plex-sans/complete/woff/IBMPlexSans-Italic.woff") format("woff")
}

@font-face {
    font-family: 'IBM Plex Sans';
    font-style: italic;
    font-weight: 400;
    src: local("IBM Plex Sans Italic"), local("IBMPlexSans-Italic"), url("../fonts/ibm-plex-sans/split/woff2/IBMPlexSans-Italic-Cyrillic.woff2") format("woff2");
    unicode-range: U+0400-045F, U+0472-0473, U+0490-049D, U+04A0-04A5, U+04AA-04AB, U+04AE-04B3, U+04B6-04BB, U+04C0-04C2, U+04CF-04D9, U+04DC-04DF, U+04E2-04E9, U+04EE-04F5, U+04F8-04F9
}

@font-face {
    font-family: 'IBM Plex Sans';
    font-style: italic;
    font-weight: 400;
    src: local("IBM Plex Sans Italic"), local("IBMPlexSans-Italic"), url("../fonts/ibm-plex-sans/split/woff2/IBMPlexSans-Italic-Pi.woff2") format("woff2");
    unicode-range: U+0E3F, U+2032-2033, U+2070, U+2075-2079, U+2080-2081, U+2083, U+2085-2089, U+2113, U+2116, U+2126, U+212E, U+2150-2151, U+2153-215E, U+2190-2199, U+21A9-21AA, U+21B0-21B3, U+21B6-21B7, U+21BA-21BB, U+21C4, U+21C6, U+2202, U+2206, U+220F, U+2211, U+221A, U+221E, U+222B, U+2248, U+2260, U+2264-2265, U+25CA, U+2713, U+274C, U+2B0E-2B11, U+EBE1-EBE7, U+ECE0, U+EFCC
}

@font-face {
    font-family: 'IBM Plex Sans';
    font-style: italic;
    font-weight: 400;
    src: local("IBM Plex Sans Italic"), local("IBMPlexSans-Italic"), url("../fonts/ibm-plex-sans/split/woff2/IBMPlexSans-Italic-Latin3.woff2") format("woff2");
    unicode-range: U+0102-0103, U+1EA0-1EF9, U+20AB
}

@font-face {
    font-family: 'IBM Plex Sans';
    font-style: italic;
    font-weight: 400;
    src: local("IBM Plex Sans Italic"), local("IBMPlexSans-Italic"), url("../fonts/ibm-plex-sans/split/woff2/IBMPlexSans-Italic-Latin2.woff2") format("woff2");
    unicode-range: U+0100-024F, U+0259, U+1E00-1EFF, U+20A0-20AB, U+20AD-20CF, U+2C60-2C7F, U+A720-A7FF, U+FB01-FB02
}

@font-face {
    font-family: 'IBM Plex Sans';
    font-style: italic;
    font-weight: 400;
    src: local("IBM Plex Sans Italic"), local("IBMPlexSans-Italic"), url("../fonts/ibm-plex-sans/split/woff2/IBMPlexSans-Italic-Latin1.woff2") format("woff2");
    unicode-range: U+0000, U+000D, U+0020-007E, U+00A0-00A3, U+00A4-00FF, U+0131, U+0152-0153, U+02C6, U+02DA, U+02DC, U+2013-2014, U+2018-201A, U+201C-201E, U+2020-2022, U+2026, U+2030, U+2039-203A, U+2044, U+2074, U+20AC, U+2122, U+2212, U+FB01-FB02
}

@font-face {
    font-family: 'IBM Plex Sans';
    font-style: italic;
    font-weight: 400;
    src: local("IBM Plex Sans Italic"), local("IBMPlexSans-Italic"), url("../fonts/ibm-plex-sans/split/woff2/IBMPlexSans-Italic-Greek.woff2") format("woff2");
    unicode-range: U+0384-038A, U+038C, U+038E-03A1, U+03A3-03CE
}

@font-face {
    font-family: 'IBM Plex Sans';
    font-style: normal;
    font-weight: 300;
    src: local("IBM Plex Sans Light"), local("IBMPlexSans-Light"), url("../fonts/ibm-plex-sans/complete/woff/IBMPlexSans-Light.woff") format("woff")
}

@font-face {
    font-family: 'IBM Plex Sans';
    font-style: normal;
    font-weight: 300;
    src: local("IBM Plex Sans Light"), local("IBMPlexSans-Light"), url("../fonts/ibm-plex-sans/split/woff2/IBMPlexSans-Light-Cyrillic.woff2") format("woff2");
    unicode-range: U+0400-045F, U+0472-0473, U+0490-049D, U+04A0-04A5, U+04AA-04AB, U+04AE-04B3, U+04B6-04BB, U+04C0-04C2, U+04CF-04D9, U+04DC-04DF, U+04E2-04E9, U+04EE-04F5, U+04F8-04F9
}

@font-face {
    font-family: 'IBM Plex Sans';
    font-style: normal;
    font-weight: 300;
    src: local("IBM Plex Sans Light"), local("IBMPlexSans-Light"), url("../fonts/ibm-plex-sans/split/woff2/IBMPlexSans-Light-Pi.woff2") format("woff2");
    unicode-range: U+0E3F, U+2032-2033, U+2070, U+2075-2079, U+2080-2081, U+2083, U+2085-2089, U+2113, U+2116, U+2126, U+212E, U+2150-2151, U+2153-215E, U+2190-2199, U+21A9-21AA, U+21B0-21B3, U+21B6-21B7, U+21BA-21BB, U+21C4, U+21C6, U+2202, U+2206, U+220F, U+2211, U+221A, U+221E, U+222B, U+2248, U+2260, U+2264-2265, U+25CA, U+2713, U+274C, U+2B0E-2B11, U+EBE1-EBE7, U+ECE0, U+EFCC
}

@font-face {
    font-family: 'IBM Plex Sans';
    font-style: normal;
    font-weight: 300;
    src: local("IBM Plex Sans Light"), local("IBMPlexSans-Light"), url("../fonts/ibm-plex-sans/split/woff2/IBMPlexSans-Light-Latin3.woff2") format("woff2");
    unicode-range: U+0102-0103, U+1EA0-1EF9, U+20AB
}

@font-face {
    font-family: 'IBM Plex Sans';
    font-style: normal;
    font-weight: 300;
    src: local("IBM Plex Sans Light"), local("IBMPlexSans-Light"), url("../fonts/ibm-plex-sans/split/woff2/IBMPlexSans-Light-Latin2.woff2") format("woff2");
    unicode-range: U+0100-024F, U+0259, U+1E00-1EFF, U+20A0-20AB, U+20AD-20CF, U+2C60-2C7F, U+A720-A7FF, U+FB01-FB02
}

@font-face {
    font-family: 'IBM Plex Sans';
    font-style: normal;
    font-weight: 300;
    src: local("IBM Plex Sans Light"), local("IBMPlexSans-Light"), url("../fonts/ibm-plex-sans/split/woff2/IBMPlexSans-Light-Latin1.woff2") format("woff2");
    unicode-range: U+0000, U+000D, U+0020-007E, U+00A0-00A3, U+00A4-00FF, U+0131, U+0152-0153, U+02C6, U+02DA, U+02DC, U+2013-2014, U+2018-201A, U+201C-201E, U+2020-2022, U+2026, U+2030, U+2039-203A, U+2044, U+2074, U+20AC, U+2122, U+2212, U+FB01-FB02
}

@font-face {
    font-family: 'IBM Plex Sans';
    font-style: normal;
    font-weight: 300;
    src: local("IBM Plex Sans Light"), local("IBMPlexSans-Light"), url("../fonts/ibm-plex-sans/split/woff2/IBMPlexSans-Light-Greek.woff2") format("woff2");
    unicode-range: U+0384-038A, U+038C, U+038E-03A1, U+03A3-03CE
}

@font-face {
    font-family: 'IBM Plex Sans';
    font-style: italic;
    font-weight: 300;
    src: local("IBM Plex Sans Light Italic"), local("IBMPlexSans-LightItalic"), url("../fonts/ibm-plex-sans/complete/woff/IBMPlexSans-LightItalic.woff") format("woff")
}

@font-face {
    font-family: 'IBM Plex Sans';
    font-style: italic;
    font-weight: 300;
    src: local("IBM Plex Sans Light Italic"), local("IBMPlexSans-LightItalic"), url("../fonts/ibm-plex-sans/split/woff2/IBMPlexSans-LightItalic-Cyrillic.woff2") format("woff2");
    unicode-range: U+0400-045F, U+0472-0473, U+0490-049D, U+04A0-04A5, U+04AA-04AB, U+04AE-04B3, U+04B6-04BB, U+04C0-04C2, U+04CF-04D9, U+04DC-04DF, U+04E2-04E9, U+04EE-04F5, U+04F8-04F9
}

@font-face {
    font-family: 'IBM Plex Sans';
    font-style: italic;
    font-weight: 300;
    src: local("IBM Plex Sans Light Italic"), local("IBMPlexSans-LightItalic"), url("../fonts/ibm-plex-sans/split/woff2/IBMPlexSans-LightItalic-Pi.woff2") format("woff2");
    unicode-range: U+0E3F, U+2032-2033, U+2070, U+2075-2079, U+2080-2081, U+2083, U+2085-2089, U+2113, U+2116, U+2126, U+212E, U+2150-2151, U+2153-215E, U+2190-2199, U+21A9-21AA, U+21B0-21B3, U+21B6-21B7, U+21BA-21BB, U+21C4, U+21C6, U+2202, U+2206, U+220F, U+2211, U+221A, U+221E, U+222B, U+2248, U+2260, U+2264-2265, U+25CA, U+2713, U+274C, U+2B0E-2B11, U+EBE1-EBE7, U+ECE0, U+EFCC
}

@font-face {
    font-family: 'IBM Plex Sans';
    font-style: italic;
    font-weight: 300;
    src: local("IBM Plex Sans Light Italic"), local("IBMPlexSans-LightItalic"), url("../fonts/ibm-plex-sans/split/woff2/IBMPlexSans-LightItalic-Latin3.woff2") format("woff2");
    unicode-range: U+0102-0103, U+1EA0-1EF9, U+20AB
}

@font-face {
    font-family: 'IBM Plex Sans';
    font-style: italic;
    font-weight: 300;
    src: local("IBM Plex Sans Light Italic"), local("IBMPlexSans-LightItalic"), url("../fonts/ibm-plex-sans/split/woff2/IBMPlexSans-LightItalic-Latin2.woff2") format("woff2");
    unicode-range: U+0100-024F, U+0259, U+1E00-1EFF, U+20A0-20AB, U+20AD-20CF, U+2C60-2C7F, U+A720-A7FF, U+FB01-FB02
}

@font-face {
    font-family: 'IBM Plex Sans';
    font-style: italic;
    font-weight: 300;
    src: local("IBM Plex Sans Light Italic"), local("IBMPlexSans-LightItalic"), url("../fonts/ibm-plex-sans/split/woff2/IBMPlexSans-LightItalic-Latin1.woff2") format("woff2");
    unicode-range: U+0000, U+000D, U+0020-007E, U+00A0-00A3, U+00A4-00FF, U+0131, U+0152-0153, U+02C6, U+02DA, U+02DC, U+2013-2014, U+2018-201A, U+201C-201E, U+2020-2022, U+2026, U+2030, U+2039-203A, U+2044, U+2074, U+20AC, U+2122, U+2212, U+FB01-FB02
}

@font-face {
    font-family: 'IBM Plex Sans';
    font-style: italic;
    font-weight: 300;
    src: local("IBM Plex Sans Light Italic"), local("IBMPlexSans-LightItalic"), url("../fonts/ibm-plex-sans/split/woff2/IBMPlexSans-LightItalic-Greek.woff2") format("woff2");
    unicode-range: U+0384-038A, U+038C, U+038E-03A1, U+03A3-03CE
}

@font-face {
    font-family: 'IBM Plex Sans';
    font-style: normal;
    font-weight: 500;
    src: local("IBM Plex Sans Medium"), local("IBMPlexSans-Medium"), url("../fonts/ibm-plex-sans/complete/woff/IBMPlexSans-Medium.woff") format("woff")
}

@font-face {
    font-family: 'IBM Plex Sans';
    font-style: normal;
    font-weight: 500;
    src: local("IBM Plex Sans Medium"), local("IBMPlexSans-Medium"), url("../fonts/ibm-plex-sans/split/woff2/IBMPlexSans-Medium-Cyrillic.woff2") format("woff2");
    unicode-range: U+0400-045F, U+0472-0473, U+0490-049D, U+04A0-04A5, U+04AA-04AB, U+04AE-04B3, U+04B6-04BB, U+04C0-04C2, U+04CF-04D9, U+04DC-04DF, U+04E2-04E9, U+04EE-04F5, U+04F8-04F9
}

@font-face {
    font-family: 'IBM Plex Sans';
    font-style: normal;
    font-weight: 500;
    src: local("IBM Plex Sans Medium"), local("IBMPlexSans-Medium"), url("../fonts/ibm-plex-sans/split/woff2/IBMPlexSans-Medium-Pi.woff2") format("woff2");
    unicode-range: U+0E3F, U+2032-2033, U+2070, U+2075-2079, U+2080-2081, U+2083, U+2085-2089, U+2113, U+2116, U+2126, U+212E, U+2150-2151, U+2153-215E, U+2190-2199, U+21A9-21AA, U+21B0-21B3, U+21B6-21B7, U+21BA-21BB, U+21C4, U+21C6, U+2202, U+2206, U+220F, U+2211, U+221A, U+221E, U+222B, U+2248, U+2260, U+2264-2265, U+25CA, U+2713, U+274C, U+2B0E-2B11, U+EBE1-EBE7, U+ECE0, U+EFCC
}

@font-face {
    font-family: 'IBM Plex Sans';
    font-style: normal;
    font-weight: 500;
    src: local("IBM Plex Sans Medium"), local("IBMPlexSans-Medium"), url("../fonts/ibm-plex-sans/split/woff2/IBMPlexSans-Medium-Latin3.woff2") format("woff2");
    unicode-range: U+0102-0103, U+1EA0-1EF9, U+20AB
}

@font-face {
    font-family: 'IBM Plex Sans';
    font-style: normal;
    font-weight: 500;
    src: local("IBM Plex Sans Medium"), local("IBMPlexSans-Medium"), url("../fonts/ibm-plex-sans/split/woff2/IBMPlexSans-Medium-Latin2.woff2") format("woff2");
    unicode-range: U+0100-024F, U+0259, U+1E00-1EFF, U+20A0-20AB, U+20AD-20CF, U+2C60-2C7F, U+A720-A7FF, U+FB01-FB02
}

@font-face {
    font-family: 'IBM Plex Sans';
    font-style: normal;
    font-weight: 500;
    src: local("IBM Plex Sans Medium"), local("IBMPlexSans-Medium"), url("../fonts/ibm-plex-sans/split/woff2/IBMPlexSans-Medium-Latin1.woff2") format("woff2");
    unicode-range: U+0000, U+000D, U+0020-007E, U+00A0-00A3, U+00A4-00FF, U+0131, U+0152-0153, U+02C6, U+02DA, U+02DC, U+2013-2014, U+2018-201A, U+201C-201E, U+2020-2022, U+2026, U+2030, U+2039-203A, U+2044, U+2074, U+20AC, U+2122, U+2212, U+FB01-FB02
}

@font-face {
    font-family: 'IBM Plex Sans';
    font-style: normal;
    font-weight: 500;
    src: local("IBM Plex Sans Medium"), local("IBMPlexSans-Medium"), url("../fonts/ibm-plex-sans/split/woff2/IBMPlexSans-Medium-Greek.woff2") format("woff2");
    unicode-range: U+0384-038A, U+038C, U+038E-03A1, U+03A3-03CE
}

@font-face {
    font-family: 'IBM Plex Sans';
    font-style: italic;
    font-weight: 500;
    src: local("IBM Plex Sans Medium Italic"), local("IBMPlexSans-MediumItalic"), url("../fonts/ibm-plex-sans/complete/woff/IBMPlexSans-MediumItalic.woff") format("woff")
}

@font-face {
    font-family: 'IBM Plex Sans';
    font-style: italic;
    font-weight: 500;
    src: local("IBM Plex Sans Medium Italic"), local("IBMPlexSans-MediumItalic"), url("../fonts/ibm-plex-sans/split/woff2/IBMPlexSans-MediumItalic-Cyrillic.woff2") format("woff2");
    unicode-range: U+0400-045F, U+0472-0473, U+0490-049D, U+04A0-04A5, U+04AA-04AB, U+04AE-04B3, U+04B6-04BB, U+04C0-04C2, U+04CF-04D9, U+04DC-04DF, U+04E2-04E9, U+04EE-04F5, U+04F8-04F9
}

@font-face {
    font-family: 'IBM Plex Sans';
    font-style: italic;
    font-weight: 500;
    src: local("IBM Plex Sans Medium Italic"), local("IBMPlexSans-MediumItalic"), url("../fonts/ibm-plex-sans/split/woff2/IBMPlexSans-MediumItalic-Pi.woff2") format("woff2");
    unicode-range: U+0E3F, U+2032-2033, U+2070, U+2075-2079, U+2080-2081, U+2083, U+2085-2089, U+2113, U+2116, U+2126, U+212E, U+2150-2151, U+2153-215E, U+2190-2199, U+21A9-21AA, U+21B0-21B3, U+21B6-21B7, U+21BA-21BB, U+21C4, U+21C6, U+2202, U+2206, U+220F, U+2211, U+221A, U+221E, U+222B, U+2248, U+2260, U+2264-2265, U+25CA, U+2713, U+274C, U+2B0E-2B11, U+EBE1-EBE7, U+ECE0, U+EFCC
}

@font-face {
    font-family: 'IBM Plex Sans';
    font-style: italic;
    font-weight: 500;
    src: local("IBM Plex Sans Medium Italic"), local("IBMPlexSans-MediumItalic"), url("../fonts/ibm-plex-sans/split/woff2/IBMPlexSans-MediumItalic-Latin3.woff2") format("woff2");
    unicode-range: U+0102-0103, U+1EA0-1EF9, U+20AB
}

@font-face {
    font-family: 'IBM Plex Sans';
    font-style: italic;
    font-weight: 500;
    src: local("IBM Plex Sans Medium Italic"), local("IBMPlexSans-MediumItalic"), url("../fonts/ibm-plex-sans/split/woff2/IBMPlexSans-MediumItalic-Latin2.woff2") format("woff2");
    unicode-range: U+0100-024F, U+0259, U+1E00-1EFF, U+20A0-20AB, U+20AD-20CF, U+2C60-2C7F, U+A720-A7FF, U+FB01-FB02
}

@font-face {
    font-family: 'IBM Plex Sans';
    font-style: italic;
    font-weight: 500;
    src: local("IBM Plex Sans Medium Italic"), local("IBMPlexSans-MediumItalic"), url("../fonts/ibm-plex-sans/split/woff2/IBMPlexSans-MediumItalic-Latin1.woff2") format("woff2");
    unicode-range: U+0000, U+000D, U+0020-007E, U+00A0-00A3, U+00A4-00FF, U+0131, U+0152-0153, U+02C6, U+02DA, U+02DC, U+2013-2014, U+2018-201A, U+201C-201E, U+2020-2022, U+2026, U+2030, U+2039-203A, U+2044, U+2074, U+20AC, U+2122, U+2212, U+FB01-FB02
}

@font-face {
    font-family: 'IBM Plex Sans';
    font-style: italic;
    font-weight: 500;
    src: local("IBM Plex Sans Medium Italic"), local("IBMPlexSans-MediumItalic"), url("../fonts/ibm-plex-sans/split/woff2/IBMPlexSans-MediumItalic-Greek.woff2") format("woff2");
    unicode-range: U+0384-038A, U+038C, U+038E-03A1, U+03A3-03CE
}

@font-face {
    font-family: 'IBM Plex Sans';
    font-style: normal;
    font-weight: 400;
    src: local("IBM Plex Sans"), local("IBMPlexSans"), url("../fonts/ibm-plex-sans/complete/woff/IBMPlexSans-Regular.woff") format("woff")
}

@font-face {
    font-family: 'IBM Plex Sans';
    font-style: normal;
    font-weight: 400;
    src: local("IBM Plex Sans"), local("IBMPlexSans"), url("../fonts/ibm-plex-sans/split/woff2/IBMPlexSans-Regular-Cyrillic.woff2") format("woff2");
    unicode-range: U+0400-045F, U+0472-0473, U+0490-049D, U+04A0-04A5, U+04AA-04AB, U+04AE-04B3, U+04B6-04BB, U+04C0-04C2, U+04CF-04D9, U+04DC-04DF, U+04E2-04E9, U+04EE-04F5, U+04F8-04F9
}

@font-face {
    font-family: 'IBM Plex Sans';
    font-style: normal;
    font-weight: 400;
    src: local("IBM Plex Sans"), local("IBMPlexSans"), url("../fonts/ibm-plex-sans/split/woff2/IBMPlexSans-Regular-Pi.woff2") format("woff2");
    unicode-range: U+0E3F, U+2032-2033, U+2070, U+2075-2079, U+2080-2081, U+2083, U+2085-2089, U+2113, U+2116, U+2126, U+212E, U+2150-2151, U+2153-215E, U+2190-2199, U+21A9-21AA, U+21B0-21B3, U+21B6-21B7, U+21BA-21BB, U+21C4, U+21C6, U+2202, U+2206, U+220F, U+2211, U+221A, U+221E, U+222B, U+2248, U+2260, U+2264-2265, U+25CA, U+2713, U+274C, U+2B0E-2B11, U+EBE1-EBE7, U+ECE0, U+EFCC
}

@font-face {
    font-family: 'IBM Plex Sans';
    font-style: normal;
    font-weight: 400;
    src: local("IBM Plex Sans"), local("IBMPlexSans"), url("../fonts/ibm-plex-sans/split/woff2/IBMPlexSans-Regular-Latin3.woff2") format("woff2");
    unicode-range: U+0102-0103, U+1EA0-1EF9, U+20AB
}

@font-face {
    font-family: 'IBM Plex Sans';
    font-style: normal;
    font-weight: 400;
    src: local("IBM Plex Sans"), local("IBMPlexSans"), url("../fonts/ibm-plex-sans/split/woff2/IBMPlexSans-Regular-Latin2.woff2") format("woff2");
    unicode-range: U+0100-024F, U+0259, U+1E00-1EFF, U+20A0-20AB, U+20AD-20CF, U+2C60-2C7F, U+A720-A7FF, U+FB01-FB02
}

@font-face {
    font-family: 'IBM Plex Sans';
    font-style: normal;
    font-weight: 400;
    src: local("IBM Plex Sans"), local("IBMPlexSans"), url("../fonts/ibm-plex-sans/split/woff2/IBMPlexSans-Regular-Latin1.woff2") format("woff2");
    unicode-range: U+0000, U+000D, U+0020-007E, U+00A0-00A3, U+00A4-00FF, U+0131, U+0152-0153, U+02C6, U+02DA, U+02DC, U+2013-2014, U+2018-201A, U+201C-201E, U+2020-2022, U+2026, U+2030, U+2039-203A, U+2044, U+2074, U+20AC, U+2122, U+2212, U+FB01-FB02
}

@font-face {
    font-family: 'IBM Plex Sans';
    font-style: normal;
    font-weight: 400;
    src: local("IBM Plex Sans"), local("IBMPlexSans"), url("../fonts/ibm-plex-sans/split/woff2/IBMPlexSans-Regular-Greek.woff2") format("woff2");
    unicode-range: U+0384-038A, U+038C, U+038E-03A1, U+03A3-03CE
}

@font-face {
    font-family: 'IBM Plex Sans';
    font-style: normal;
    font-weight: 600;
    src: local("IBM Plex Sans SemiBold"), local("IBMPlexSans-SemiBold"), url("../fonts/ibm-plex-sans/complete/woff/IBMPlexSans-SemiBold.woff") format("woff")
}

@font-face {
    font-family: 'IBM Plex Sans';
    font-style: normal;
    font-weight: 600;
    src: local("IBM Plex Sans SemiBold"), local("IBMPlexSans-SemiBold"), url("../fonts/ibm-plex-sans/split/woff2/IBMPlexSans-SemiBold-Cyrillic.woff2") format("woff2");
    unicode-range: U+0400-045F, U+0472-0473, U+0490-049D, U+04A0-04A5, U+04AA-04AB, U+04AE-04B3, U+04B6-04BB, U+04C0-04C2, U+04CF-04D9, U+04DC-04DF, U+04E2-04E9, U+04EE-04F5, U+04F8-04F9
}

@font-face {
    font-family: 'IBM Plex Sans';
    font-style: normal;
    font-weight: 600;
    src: local("IBM Plex Sans SemiBold"), local("IBMPlexSans-SemiBold"), url("../fonts/ibm-plex-sans/split/woff2/IBMPlexSans-SemiBold-Pi.woff2") format("woff2");
    unicode-range: U+0E3F, U+2032-2033, U+2070, U+2075-2079, U+2080-2081, U+2083, U+2085-2089, U+2113, U+2116, U+2126, U+212E, U+2150-2151, U+2153-215E, U+2190-2199, U+21A9-21AA, U+21B0-21B3, U+21B6-21B7, U+21BA-21BB, U+21C4, U+21C6, U+2202, U+2206, U+220F, U+2211, U+221A, U+221E, U+222B, U+2248, U+2260, U+2264-2265, U+25CA, U+2713, U+274C, U+2B0E-2B11, U+EBE1-EBE7, U+ECE0, U+EFCC
}

@font-face {
    font-family: 'IBM Plex Sans';
    font-style: normal;
    font-weight: 600;
    src: local("IBM Plex Sans SemiBold"), local("IBMPlexSans-SemiBold"), url("../fonts/ibm-plex-sans/split/woff2/IBMPlexSans-SemiBold-Latin3.woff2") format("woff2");
    unicode-range: U+0102-0103, U+1EA0-1EF9, U+20AB
}

@font-face {
    font-family: 'IBM Plex Sans';
    font-style: normal;
    font-weight: 600;
    src: local("IBM Plex Sans SemiBold"), local("IBMPlexSans-SemiBold"), url("../fonts/ibm-plex-sans/split/woff2/IBMPlexSans-SemiBold-Latin2.woff2") format("woff2");
    unicode-range: U+0100-024F, U+0259, U+1E00-1EFF, U+20A0-20AB, U+20AD-20CF, U+2C60-2C7F, U+A720-A7FF, U+FB01-FB02
}

@font-face {
    font-family: 'IBM Plex Sans';
    font-style: normal;
    font-weight: 600;
    src: local("IBM Plex Sans SemiBold"), local("IBMPlexSans-SemiBold"), url("../fonts/ibm-plex-sans/split/woff2/IBMPlexSans-SemiBold-Latin1.woff2") format("woff2");
    unicode-range: U+0000, U+000D, U+0020-007E, U+00A0-00A3, U+00A4-00FF, U+0131, U+0152-0153, U+02C6, U+02DA, U+02DC, U+2013-2014, U+2018-201A, U+201C-201E, U+2020-2022, U+2026, U+2030, U+2039-203A, U+2044, U+2074, U+20AC, U+2122, U+2212, U+FB01-FB02
}

@font-face {
    font-family: 'IBM Plex Sans';
    font-style: normal;
    font-weight: 600;
    src: local("IBM Plex Sans SemiBold"), local("IBMPlexSans-SemiBold"), url("../fonts/ibm-plex-sans/split/woff2/IBMPlexSans-SemiBold-Greek.woff2") format("woff2");
    unicode-range: U+0384-038A, U+038C, U+038E-03A1, U+03A3-03CE
}

@font-face {
    font-family: 'IBM Plex Sans';
    font-style: italic;
    font-weight: 600;
    src: local("IBM Plex Sans SemiBold Italic"), local("IBMPlexSans-SemiBoldItalic"), url("../fonts/ibm-plex-sans/complete/woff/IBMPlexSans-SemiBoldItalic.woff") format("woff")
}

@font-face {
    font-family: 'IBM Plex Sans';
    font-style: italic;
    font-weight: 600;
    src: local("IBM Plex Sans SemiBold Italic"), local("IBMPlexSans-SemiBoldItalic"), url("../fonts/ibm-plex-sans/split/woff2/IBMPlexSans-SemiBoldItalic-Cyrillic.woff2") format("woff2");
    unicode-range: U+0400-045F, U+0472-0473, U+0490-049D, U+04A0-04A5, U+04AA-04AB, U+04AE-04B3, U+04B6-04BB, U+04C0-04C2, U+04CF-04D9, U+04DC-04DF, U+04E2-04E9, U+04EE-04F5, U+04F8-04F9
}

@font-face {
    font-family: 'IBM Plex Sans';
    font-style: italic;
    font-weight: 600;
    src: local("IBM Plex Sans SemiBold Italic"), local("IBMPlexSans-SemiBoldItalic"), url("../fonts/ibm-plex-sans/split/woff2/IBMPlexSans-SemiBoldItalic-Pi.woff2") format("woff2");
    unicode-range: U+0E3F, U+2032-2033, U+2070, U+2075-2079, U+2080-2081, U+2083, U+2085-2089, U+2113, U+2116, U+2126, U+212E, U+2150-2151, U+2153-215E, U+2190-2199, U+21A9-21AA, U+21B0-21B3, U+21B6-21B7, U+21BA-21BB, U+21C4, U+21C6, U+2202, U+2206, U+220F, U+2211, U+221A, U+221E, U+222B, U+2248, U+2260, U+2264-2265, U+25CA, U+2713, U+274C, U+2B0E-2B11, U+EBE1-EBE7, U+ECE0, U+EFCC
}

@font-face {
    font-family: 'IBM Plex Sans';
    font-style: italic;
    font-weight: 600;
    src: local("IBM Plex Sans SemiBold Italic"), local("IBMPlexSans-SemiBoldItalic"), url("../fonts/ibm-plex-sans/split/woff2/IBMPlexSans-SemiBoldItalic-Latin3.woff2") format("woff2");
    unicode-range: U+0102-0103, U+1EA0-1EF9, U+20AB
}

@font-face {
    font-family: 'IBM Plex Sans';
    font-style: italic;
    font-weight: 600;
    src: local("IBM Plex Sans SemiBold Italic"), local("IBMPlexSans-SemiBoldItalic"), url("../fonts/ibm-plex-sans/split/woff2/IBMPlexSans-SemiBoldItalic-Latin2.woff2") format("woff2");
    unicode-range: U+0100-024F, U+0259, U+1E00-1EFF, U+20A0-20AB, U+20AD-20CF, U+2C60-2C7F, U+A720-A7FF, U+FB01-FB02
}

@font-face {
    font-family: 'IBM Plex Sans';
    font-style: italic;
    font-weight: 600;
    src: local("IBM Plex Sans SemiBold Italic"), local("IBMPlexSans-SemiBoldItalic"), url("../fonts/ibm-plex-sans/split/woff2/IBMPlexSans-SemiBoldItalic-Latin1.woff2") format("woff2");
    unicode-range: U+0000, U+000D, U+0020-007E, U+00A0-00A3, U+00A4-00FF, U+0131, U+0152-0153, U+02C6, U+02DA, U+02DC, U+2013-2014, U+2018-201A, U+201C-201E, U+2020-2022, U+2026, U+2030, U+2039-203A, U+2044, U+2074, U+20AC, U+2122, U+2212, U+FB01-FB02
}

@font-face {
    font-family: 'IBM Plex Sans';
    font-style: italic;
    font-weight: 600;
    src: local("IBM Plex Sans SemiBold Italic"), local("IBMPlexSans-SemiBoldItalic"), url("../fonts/ibm-plex-sans/split/woff2/IBMPlexSans-SemiBoldItalic-Greek.woff2") format("woff2");
    unicode-range: U+0384-038A, U+038C, U+038E-03A1, U+03A3-03CE
}

@font-face {
    font-family: 'IBM Plex Sans';
    font-style: normal;
    font-weight: 450;
    src: local("IBM Plex Sans Text"), local("IBMPlexSans-Text"), url("../fonts/ibm-plex-sans/complete/woff/IBMPlexSans-Text.woff") format("woff")
}

@font-face {
    font-family: 'IBM Plex Sans';
    font-style: normal;
    font-weight: 450;
    src: local("IBM Plex Sans Text"), local("IBMPlexSans-Text"), url("../fonts/ibm-plex-sans/split/woff2/IBMPlexSans-Text-Cyrillic.woff2") format("woff2");
    unicode-range: U+0400-045F, U+0472-0473, U+0490-049D, U+04A0-04A5, U+04AA-04AB, U+04AE-04B3, U+04B6-04BB, U+04C0-04C2, U+04CF-04D9, U+04DC-04DF, U+04E2-04E9, U+04EE-04F5, U+04F8-04F9
}

@font-face {
    font-family: 'IBM Plex Sans';
    font-style: normal;
    font-weight: 450;
    src: local("IBM Plex Sans Text"), local("IBMPlexSans-Text"), url("../fonts/ibm-plex-sans/split/woff2/IBMPlexSans-Text-Pi.woff2") format("woff2");
    unicode-range: U+0E3F, U+2032-2033, U+2070, U+2075-2079, U+2080-2081, U+2083, U+2085-2089, U+2113, U+2116, U+2126, U+212E, U+2150-2151, U+2153-215E, U+2190-2199, U+21A9-21AA, U+21B0-21B3, U+21B6-21B7, U+21BA-21BB, U+21C4, U+21C6, U+2202, U+2206, U+220F, U+2211, U+221A, U+221E, U+222B, U+2248, U+2260, U+2264-2265, U+25CA, U+2713, U+274C, U+2B0E-2B11, U+EBE1-EBE7, U+ECE0, U+EFCC
}

@font-face {
    font-family: 'IBM Plex Sans';
    font-style: normal;
    font-weight: 450;
    src: local("IBM Plex Sans Text"), local("IBMPlexSans-Text"), url("../fonts/ibm-plex-sans/split/woff2/IBMPlexSans-Text-Latin3.woff2") format("woff2");
    unicode-range: U+0102-0103, U+1EA0-1EF9, U+20AB
}

@font-face {
    font-family: 'IBM Plex Sans';
    font-style: normal;
    font-weight: 450;
    src: local("IBM Plex Sans Text"), local("IBMPlexSans-Text"), url("../fonts/ibm-plex-sans/split/woff2/IBMPlexSans-Text-Latin2.woff2") format("woff2");
    unicode-range: U+0100-024F, U+0259, U+1E00-1EFF, U+20A0-20AB, U+20AD-20CF, U+2C60-2C7F, U+A720-A7FF, U+FB01-FB02
}

@font-face {
    font-family: 'IBM Plex Sans';
    font-style: normal;
    font-weight: 450;
    src: local("IBM Plex Sans Text"), local("IBMPlexSans-Text"), url("../fonts/ibm-plex-sans/split/woff2/IBMPlexSans-Text-Latin1.woff2") format("woff2");
    unicode-range: U+0000, U+000D, U+0020-007E, U+00A0-00A3, U+00A4-00FF, U+0131, U+0152-0153, U+02C6, U+02DA, U+02DC, U+2013-2014, U+2018-201A, U+201C-201E, U+2020-2022, U+2026, U+2030, U+2039-203A, U+2044, U+2074, U+20AC, U+2122, U+2212, U+FB01-FB02
}

@font-face {
    font-family: 'IBM Plex Sans';
    font-style: normal;
    font-weight: 450;
    src: local("IBM Plex Sans Text"), local("IBMPlexSans-Text"), url("../fonts/ibm-plex-sans/split/woff2/IBMPlexSans-Text-Greek.woff2") format("woff2");
    unicode-range: U+0384-038A, U+038C, U+038E-03A1, U+03A3-03CE
}

@font-face {
    font-family: 'IBM Plex Sans';
    font-style: italic;
    font-weight: 450;
    src: local("IBM Plex Sans Text Italic"), local("IBMPlexSans-TextItalic"), url("../fonts/ibm-plex-sans/complete/woff/IBMPlexSans-TextItalic.woff") format("woff")
}

@font-face {
    font-family: 'IBM Plex Sans';
    font-style: italic;
    font-weight: 450;
    src: local("IBM Plex Sans Text Italic"), local("IBMPlexSans-TextItalic"), url("../fonts/ibm-plex-sans/split/woff2/IBMPlexSans-TextItalic-Cyrillic.woff2") format("woff2");
    unicode-range: U+0400-045F, U+0472-0473, U+0490-049D, U+04A0-04A5, U+04AA-04AB, U+04AE-04B3, U+04B6-04BB, U+04C0-04C2, U+04CF-04D9, U+04DC-04DF, U+04E2-04E9, U+04EE-04F5, U+04F8-04F9
}

@font-face {
    font-family: 'IBM Plex Sans';
    font-style: italic;
    font-weight: 450;
    src: local("IBM Plex Sans Text Italic"), local("IBMPlexSans-TextItalic"), url("../fonts/ibm-plex-sans/split/woff2/IBMPlexSans-TextItalic-Pi.woff2") format("woff2");
    unicode-range: U+0E3F, U+2032-2033, U+2070, U+2075-2079, U+2080-2081, U+2083, U+2085-2089, U+2113, U+2116, U+2126, U+212E, U+2150-2151, U+2153-215E, U+2190-2199, U+21A9-21AA, U+21B0-21B3, U+21B6-21B7, U+21BA-21BB, U+21C4, U+21C6, U+2202, U+2206, U+220F, U+2211, U+221A, U+221E, U+222B, U+2248, U+2260, U+2264-2265, U+25CA, U+2713, U+274C, U+2B0E-2B11, U+EBE1-EBE7, U+ECE0, U+EFCC
}

@font-face {
    font-family: 'IBM Plex Sans';
    font-style: italic;
    font-weight: 450;
    src: local("IBM Plex Sans Text Italic"), local("IBMPlexSans-TextItalic"), url("../fonts/ibm-plex-sans/split/woff2/IBMPlexSans-TextItalic-Latin3.woff2") format("woff2");
    unicode-range: U+0102-0103, U+1EA0-1EF9, U+20AB
}

@font-face {
    font-family: 'IBM Plex Sans';
    font-style: italic;
    font-weight: 450;
    src: local("IBM Plex Sans Text Italic"), local("IBMPlexSans-TextItalic"), url("../fonts/ibm-plex-sans/split/woff2/IBMPlexSans-TextItalic-Latin2.woff2") format("woff2");
    unicode-range: U+0100-024F, U+0259, U+1E00-1EFF, U+20A0-20AB, U+20AD-20CF, U+2C60-2C7F, U+A720-A7FF, U+FB01-FB02
}

@font-face {
    font-family: 'IBM Plex Sans';
    font-style: italic;
    font-weight: 450;
    src: local("IBM Plex Sans Text Italic"), local("IBMPlexSans-TextItalic"), url("../fonts/ibm-plex-sans/split/woff2/IBMPlexSans-TextItalic-Latin1.woff2") format("woff2");
    unicode-range: U+0000, U+000D, U+0020-007E, U+00A0-00A3, U+00A4-00FF, U+0131, U+0152-0153, U+02C6, U+02DA, U+02DC, U+2013-2014, U+2018-201A, U+201C-201E, U+2020-2022, U+2026, U+2030, U+2039-203A, U+2044, U+2074, U+20AC, U+2122, U+2212, U+FB01-FB02
}

@font-face {
    font-family: 'IBM Plex Sans';
    font-style: italic;
    font-weight: 450;
    src: local("IBM Plex Sans Text Italic"), local("IBMPlexSans-TextItalic"), url("../fonts/ibm-plex-sans/split/woff2/IBMPlexSans-TextItalic-Greek.woff2") format("woff2");
    unicode-range: U+0384-038A, U+038C, U+038E-03A1, U+03A3-03CE
}

@font-face {
    font-family: 'IBM Plex Sans';
    font-style: normal;
    font-weight: 100;
    src: local("IBM Plex Sans Thin"), local("IBMPlexSans-Thin"), url("../fonts/ibm-plex-sans/complete/woff/IBMPlexSans-Thin.woff") format("woff")
}

@font-face {
    font-family: 'IBM Plex Sans';
    font-style: normal;
    font-weight: 100;
    src: local("IBM Plex Sans Thin"), local("IBMPlexSans-Thin"), url("../fonts/ibm-plex-sans/split/woff2/IBMPlexSans-Thin-Cyrillic.woff2") format("woff2");
    unicode-range: U+0400-045F, U+0472-0473, U+0490-049D, U+04A0-04A5, U+04AA-04AB, U+04AE-04B3, U+04B6-04BB, U+04C0-04C2, U+04CF-04D9, U+04DC-04DF, U+04E2-04E9, U+04EE-04F5, U+04F8-04F9
}

@font-face {
    font-family: 'IBM Plex Sans';
    font-style: normal;
    font-weight: 100;
    src: local("IBM Plex Sans Thin"), local("IBMPlexSans-Thin"), url("../fonts/ibm-plex-sans/split/woff2/IBMPlexSans-Thin-Pi.woff2") format("woff2");
    unicode-range: U+0E3F, U+2032-2033, U+2070, U+2075-2079, U+2080-2081, U+2083, U+2085-2089, U+2113, U+2116, U+2126, U+212E, U+2150-2151, U+2153-215E, U+2190-2199, U+21A9-21AA, U+21B0-21B3, U+21B6-21B7, U+21BA-21BB, U+21C4, U+21C6, U+2202, U+2206, U+220F, U+2211, U+221A, U+221E, U+222B, U+2248, U+2260, U+2264-2265, U+25CA, U+2713, U+274C, U+2B0E-2B11, U+EBE1-EBE7, U+ECE0, U+EFCC
}

@font-face {
    font-family: 'IBM Plex Sans';
    font-style: normal;
    font-weight: 100;
    src: local("IBM Plex Sans Thin"), local("IBMPlexSans-Thin"), url("../fonts/ibm-plex-sans/split/woff2/IBMPlexSans-Thin-Latin3.woff2") format("woff2");
    unicode-range: U+0102-0103, U+1EA0-1EF9, U+20AB
}

@font-face {
    font-family: 'IBM Plex Sans';
    font-style: normal;
    font-weight: 100;
    src: local("IBM Plex Sans Thin"), local("IBMPlexSans-Thin"), url("../fonts/ibm-plex-sans/split/woff2/IBMPlexSans-Thin-Latin2.woff2") format("woff2");
    unicode-range: U+0100-024F, U+0259, U+1E00-1EFF, U+20A0-20AB, U+20AD-20CF, U+2C60-2C7F, U+A720-A7FF, U+FB01-FB02
}

@font-face {
    font-family: 'IBM Plex Sans';
    font-style: normal;
    font-weight: 100;
    src: local("IBM Plex Sans Thin"), local("IBMPlexSans-Thin"), url("../fonts/ibm-plex-sans/split/woff2/IBMPlexSans-Thin-Latin1.woff2") format("woff2");
    unicode-range: U+0000, U+000D, U+0020-007E, U+00A0-00A3, U+00A4-00FF, U+0131, U+0152-0153, U+02C6, U+02DA, U+02DC, U+2013-2014, U+2018-201A, U+201C-201E, U+2020-2022, U+2026, U+2030, U+2039-203A, U+2044, U+2074, U+20AC, U+2122, U+2212, U+FB01-FB02
}

@font-face {
    font-family: 'IBM Plex Sans';
    font-style: normal;
    font-weight: 100;
    src: local("IBM Plex Sans Thin"), local("IBMPlexSans-Thin"), url("../fonts/ibm-plex-sans/split/woff2/IBMPlexSans-Thin-Greek.woff2") format("woff2");
    unicode-range: U+0384-038A, U+038C, U+038E-03A1, U+03A3-03CE
}

@font-face {
    font-family: 'IBM Plex Sans';
    font-style: italic;
    font-weight: 100;
    src: local("IBM Plex Sans Thin Italic"), local("IBMPlexSans-ThinItalic"), url("../fonts/ibm-plex-sans/complete/woff/IBMPlexSans-ThinItalic.woff") format("woff")
}

@font-face {
    font-family: 'IBM Plex Sans';
    font-style: italic;
    font-weight: 100;
    src: local("IBM Plex Sans Thin Italic"), local("IBMPlexSans-ThinItalic"), url("../fonts/ibm-plex-sans/split/woff2/IBMPlexSans-ThinItalic-Cyrillic.woff2") format("woff2");
    unicode-range: U+0400-045F, U+0472-0473, U+0490-049D, U+04A0-04A5, U+04AA-04AB, U+04AE-04B3, U+04B6-04BB, U+04C0-04C2, U+04CF-04D9, U+04DC-04DF, U+04E2-04E9, U+04EE-04F5, U+04F8-04F9
}

@font-face {
    font-family: 'IBM Plex Sans';
    font-style: italic;
    font-weight: 100;
    src: local("IBM Plex Sans Thin Italic"), local("IBMPlexSans-ThinItalic"), url("../fonts/ibm-plex-sans/split/woff2/IBMPlexSans-ThinItalic-Pi.woff2") format("woff2");
    unicode-range: U+0E3F, U+2032-2033, U+2070, U+2075-2079, U+2080-2081, U+2083, U+2085-2089, U+2113, U+2116, U+2126, U+212E, U+2150-2151, U+2153-215E, U+2190-2199, U+21A9-21AA, U+21B0-21B3, U+21B6-21B7, U+21BA-21BB, U+21C4, U+21C6, U+2202, U+2206, U+220F, U+2211, U+221A, U+221E, U+222B, U+2248, U+2260, U+2264-2265, U+25CA, U+2713, U+274C, U+2B0E-2B11, U+EBE1-EBE7, U+ECE0, U+EFCC
}

@font-face {
    font-family: 'IBM Plex Sans';
    font-style: italic;
    font-weight: 100;
    src: local("IBM Plex Sans Thin Italic"), local("IBMPlexSans-ThinItalic"), url("../fonts/ibm-plex-sans/split/woff2/IBMPlexSans-ThinItalic-Latin3.woff2") format("woff2");
    unicode-range: U+0102-0103, U+1EA0-1EF9, U+20AB
}

@font-face {
    font-family: 'IBM Plex Sans';
    font-style: italic;
    font-weight: 100;
    src: local("IBM Plex Sans Thin Italic"), local("IBMPlexSans-ThinItalic"), url("../fonts/ibm-plex-sans/split/woff2/IBMPlexSans-ThinItalic-Latin2.woff2") format("woff2");
    unicode-range: U+0100-024F, U+0259, U+1E00-1EFF, U+20A0-20AB, U+20AD-20CF, U+2C60-2C7F, U+A720-A7FF, U+FB01-FB02
}

@font-face {
    font-family: 'IBM Plex Sans';
    font-style: italic;
    font-weight: 100;
    src: local("IBM Plex Sans Thin Italic"), local("IBMPlexSans-ThinItalic"), url("../fonts/ibm-plex-sans/split/woff2/IBMPlexSans-ThinItalic-Latin1.woff2") format("woff2");
    unicode-range: U+0000, U+000D, U+0020-007E, U+00A0-00A3, U+00A4-00FF, U+0131, U+0152-0153, U+02C6, U+02DA, U+02DC, U+2013-2014, U+2018-201A, U+201C-201E, U+2020-2022, U+2026, U+2030, U+2039-203A, U+2044, U+2074, U+20AC, U+2122, U+2212, U+FB01-FB02
}

@font-face {
    font-family: 'IBM Plex Sans';
    font-style: italic;
    font-weight: 100;
    src: local("IBM Plex Sans Thin Italic"), local("IBMPlexSans-ThinItalic"), url("../fonts/ibm-plex-sans/split/woff2/IBMPlexSans-ThinItalic-Greek.woff2") format("woff2");
    unicode-range: U+0384-038A, U+038C, U+038E-03A1, U+03A3-03CE
}

@font-face {
    font-family: 'Inter UI';
    font-style: normal;
    font-weight: 100;
    src: url("../fonts/inter-ui/Inter-UI-Thin-BETA.woff2") format("woff2"), url("../fonts/inter-ui/Inter-UI-Thin-BETA.woff") format("woff")
}

@font-face {
    font-family: 'Inter UI';
    font-style: italic;
    font-weight: 100;
    src: url("../fonts/inter-ui/Inter-UI-ThinItalic-BETA.woff2") format("woff2"), url("../fonts/inter-ui/Inter-UI-ThinItalic-BETA.woff") format("woff")
}

@font-face {
    font-family: 'Inter UI';
    font-style: normal;
    font-weight: 200;
    src: url("../fonts/inter-ui/Inter-UI-ExtraLight-BETA.woff2") format("woff2"), url("../fonts/inter-ui/Inter-UI-ExtraLight-BETA.woff") format("woff")
}

@font-face {
    font-family: 'Inter UI';
    font-style: italic;
    font-weight: 200;
    src: url("../fonts/inter-ui/Inter-UI-ExtraLightItalic-BETA.woff2") format("woff2"), url("../fonts/inter-ui/Inter-UI-ExtraLightItalic-BETA.woff") format("woff")
}

@font-face {
    font-family: 'Inter UI';
    font-style: normal;
    font-weight: 300;
    src: url("../fonts/inter-ui/Inter-UI-Light-BETA.woff2") format("woff2"), url("../fonts/inter-ui/Inter-UI-Light-BETA.woff") format("woff")
}

@font-face {
    font-family: 'Inter UI';
    font-style: italic;
    font-weight: 300;
    src: url("../fonts/inter-ui/Inter-UI-LightItalic-BETA.woff2") format("woff2"), url("../fonts/inter-ui/Inter-UI-LightItalic-BETA.woff") format("woff")
}

@font-face {
    font-family: 'Inter UI';
    font-style: normal;
    font-weight: 400;
    src: url("../fonts/inter-ui/Inter-UI-Regular.woff2") format("woff2"), url("../fonts/inter-ui/Inter-UI-Regular.woff") format("woff")
}

@font-face {
    font-family: 'Inter UI';
    font-style: italic;
    font-weight: 400;
    src: url("../fonts/inter-ui/Inter-UI-Italic.woff2") format("woff2"), url("../fonts/inter-ui/Inter-UI-Italic.woff") format("woff")
}

@font-face {
    font-family: 'Inter UI';
    font-style: normal;
    font-weight: 500;
    src: url("../fonts/inter-ui/Inter-UI-Medium.woff2") format("woff2"), url("../fonts/inter-ui/Inter-UI-Medium.woff") format("woff")
}

@font-face {
    font-family: 'Inter UI';
    font-style: italic;
    font-weight: 500;
    src: url("../fonts/inter-ui/Inter-UI-MediumItalic.woff2") format("woff2"), url("../fonts/inter-ui/Inter-UI-MediumItalic.woff") format("woff")
}

@font-face {
    font-family: 'Inter UI';
    font-style: normal;
    font-weight: 600;
    src: url("../fonts/inter-ui/Inter-UI-SemiBold.woff2") format("woff2"), url("../fonts/inter-ui/Inter-UI-SemiBold.woff") format("woff")
}

@font-face {
    font-family: 'Inter UI';
    font-style: italic;
    font-weight: 600;
    src: url("../fonts/inter-ui/Inter-UI-SemiBoldItalic.woff2") format("woff2"), url("../fonts/inter-ui/Inter-UI-SemiBoldItalic.woff") format("woff")
}

@font-face {
    font-family: 'Inter UI';
    font-style: normal;
    font-weight: 700;
    src: url("../fonts/inter-ui/Inter-UI-Bold.woff2") format("woff2"), url("../fonts/inter-ui/Inter-UI-Bold.woff") format("woff")
}

@font-face {
    font-family: 'Inter UI';
    font-style: italic;
    font-weight: 700;
    src: url("../fonts/inter-ui/Inter-UI-BoldItalic.woff2") format("woff2"), url("../fonts/inter-ui/Inter-UI-BoldItalic.woff") format("woff")
}

@font-face {
    font-family: 'Inter UI';
    font-style: normal;
    font-weight: 800;
    src: url("../fonts/inter-ui/Inter-UI-ExtraBold.woff2") format("woff2"), url("../fonts/inter-ui/Inter-UI-ExtraBold.woff") format("woff")
}

@font-face {
    font-family: 'Inter UI';
    font-style: italic;
    font-weight: 800;
    src: url("../fonts/inter-ui/Inter-UI-ExtraBoldItalic.woff2") format("woff2"), url("../fonts/inter-ui/Inter-UI-ExtraBoldItalic.woff") format("woff")
}

@font-face {
    font-family: 'Inter UI';
    font-style: normal;
    font-weight: 900;
    src: url("../fonts/inter-ui/Inter-UI-Black.woff2") format("woff2"), url("../fonts/inter-ui/Inter-UI-Black.woff") format("woff")
}

@font-face {
    font-family: 'Inter UI';
    font-style: italic;
    font-weight: 900;
    src: url("../fonts/inter-ui/Inter-UI-BlackItalic.woff2") format("woff2"), url("../fonts/inter-ui/Inter-UI-BlackItalic.woff") format("woff")
}

/* @font-face {
    font-family: 'Inter UI var';
    font-weight: 100 900;
    font-style: oblique 0deg 10deg;
    src: url("Inter-UI.var.woff2") format("woff2-variations"), url("Inter-UI.var.woff2") format("woff2") 
} */

/* @font-face {
    font-family: 'Inter UI var alt';
    font-weight: 100 900;
    font-style: normal;
    font-named-instance: 'Regular';
    src: url("../fonts/inter-ui/Inter-UI-upright.var.woff2") format("woff2 supports variations(gvar)"), url("../fonts/inter-ui/Inter-UI-upright.var.woff2") format("woff2-variations"), url("../fonts/inter-ui/Inter-UI-upright.var.woff2") format("woff2")
} */

/* @font-face {
    font-family: 'Inter UI var alt';
    font-weight: 100 900;
    font-style: italic;
    font-named-instance: 'Italic';
    src: url("../fonts/inter-ui/Inter-UI-Italic.woff2") format("woff2 supports variations(gvar)"), url("../fonts/inter-ui/Inter-UI-Italic.woff2") format("woff2-variations"), url("../fonts/inter-ui/Inter-UI-Italic.woff2") format("woff2")
} */

/*!
 * Bootstrap v4.4.1 (https://getbootstrap.com/)
 * Copyright 2011-2019 The Bootstrap Authors
 * Copyright 2011-2019 Twitter, Inc.
 * Licensed under MIT (https://github.com/twbs/bootstrap/blob/master/LICENSE)
 */
:root {
    --blue: #0168fa;
    --indigo: #5b47fb;
    --purple: #6f42c1;
    --pink: #f10075;
    --red: #dc3545;
    --orange: #fd7e14;
    --yellow: #ffc107;
    --green: #10b759;
    --teal: #0cc;
    --cyan: #00b8d4;
    --white: #fff;
    --gray: #7987a1;
    --gray-dark: #3b4863;
    --primary: #0168fa;
    --secondary: #7987a1;
    --success: #10b759;
    --info: #00b8d4;
    --warning: #ffc107;
    --danger: #dc3545;
    --light: #f4f5f8;
    --dark: #3b4863;
    --breakpoint-xs: 0;
    --breakpoint-sm: 576px;
    --breakpoint-md: 768px;
    --breakpoint-lg: 992px;
    --breakpoint-xl: 1200px;
    --font-family-sans-serif: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, "Noto Sans", sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji";
    --font-family-monospace: SFMono-Regular, Menlo, Monaco, Consolas, "Liberation Mono", "Courier New", monospace
}

*,
*::before,
*::after {
    box-sizing: border-box
}

html {
    font-family: sans-serif;
    line-height: 1.15;
    -webkit-text-size-adjust: 100%;
    -webkit-tap-highlight-color: rgba(0, 0, 0, 0)
}

article,
aside,
figcaption,
figure,
footer,
header,
hgroup,
main,
nav,
section {
    display: block
}

body {
    margin: 0;
    font-family: "IBM Plex Sans", sans-serif;
    font-size: .875rem;
    font-weight: 400;
    line-height: 1.5;
    color: #001737;
    text-align: left;
    background-color: #fff
}

[tabindex="-1"]:focus:not(:focus-visible) {
    outline: 0 !important
}

hr {
    box-sizing: content-box;
    height: 0;
    overflow: visible
}

h1,
h2,
h3,
h4,
h5,
h6 {
    margin-top: 0;
    margin-bottom: .5rem
}

p {
    margin-top: 0;
    margin-bottom: 1rem
}

abbr[title],
abbr[data-original-title] {
    text-decoration: underline;
    text-decoration: underline dotted;
    cursor: help;
    border-bottom: 0;
    text-decoration-skip-ink: none
}

address {
    margin-bottom: 1rem;
    font-style: normal;
    line-height: inherit
}

ol,
ul,
dl {
    margin-top: 0;
    margin-bottom: 1rem
}

ol ol,
ul ul,
ol ul,
ul ol {
    margin-bottom: 0
}

dt {
    font-weight: 700
}

dd {
    margin-bottom: .5rem;
    margin-left: 0
}

blockquote {
    margin: 0 0 1rem
}

b,
strong {
    font-weight: bolder
}

small {
    font-size: 80%
}

sub,
sup {
    position: relative;
    font-size: 75%;
    line-height: 0;
    vertical-align: baseline
}

sub {
    bottom: -.25em
}

sup {
    top: -.5em
}

a {
    color: #0168fa;
    text-decoration: none;
    background-color: transparent
}

a:hover {
    color: #0148ae;
    text-decoration: none
}

a:not([href]) {
    color: inherit;
    text-decoration: none
}

a:not([href]):hover {
    color: inherit;
    text-decoration: none
}

pre,
code,
kbd,
samp {
    font-family: SFMono-Regular, Menlo, Monaco, Consolas, "Liberation Mono", "Courier New", monospace;
    font-size: 1em
}

pre {
    margin-top: 0;
    margin-bottom: 1rem;
    overflow: auto
}

figure {
    margin: 0 0 1rem
}

img {
    vertical-align: middle;
    border-style: none
}

svg {
    overflow: hidden;
    vertical-align: middle
}

table {
    border-collapse: collapse
}

caption {
    padding-top: .75rem;
    padding-bottom: .75rem;
    color: #7987a1;
    text-align: left;
    caption-side: bottom
}

th {
    text-align: inherit
}

label {
    display: inline-block;
    margin-bottom: .5rem
}

button {
    border-radius: 0
}

button:focus {
    outline: 1px dotted;
    outline: 5px auto -webkit-focus-ring-color
}

input,
button,
select,
optgroup,
textarea {
    margin: 0;
    font-family: inherit;
    font-size: inherit;
    line-height: inherit
}

button,
input {
    overflow: visible
}

button,
select {
    text-transform: none
}

select {
    word-wrap: normal
}

button,
[type="button"],
[type="reset"],
[type="submit"] {
    -webkit-appearance: button
}

button:not(:disabled),
[type="button"]:not(:disabled),
[type="reset"]:not(:disabled),
[type="submit"]:not(:disabled) {
    cursor: pointer
}

button::-moz-focus-inner,
[type="button"]::-moz-focus-inner,
[type="reset"]::-moz-focus-inner,
[type="submit"]::-moz-focus-inner {
    padding: 0;
    border-style: none
}

input[type="radio"],
input[type="checkbox"] {
    box-sizing: border-box;
    padding: 0
}

input[type="date"],
input[type="time"],
input[type="datetime-local"],
input[type="month"] {
    -webkit-appearance: listbox
}

textarea {
    overflow: auto;
    resize: vertical
}

fieldset {
    min-width: 0;
    padding: 0;
    margin: 0;
    border: 0
}

legend {
    display: block;
    width: 100%;
    max-width: 100%;
    padding: 0;
    margin-bottom: .5rem;
    font-size: 1.5rem;
    line-height: inherit;
    color: inherit;
    white-space: normal
}

progress {
    vertical-align: baseline
}

[type="number"]::-webkit-inner-spin-button,
[type="number"]::-webkit-outer-spin-button {
    height: auto
}

[type="search"] {
    outline-offset: -2px;
    -webkit-appearance: none
}

[type="search"]::-webkit-search-decoration {
    -webkit-appearance: none
}

::-webkit-file-upload-button {
    font: inherit;
    -webkit-appearance: button
}

output {
    display: inline-block
}

summary {
    display: list-item;
    cursor: pointer
}

template {
    display: none
}

[hidden] {
    display: none !important
}

h1,
h2,
h3,
h4,
h5,
h6,
.h1,
.h2,
.h3,
.h4,
.h5,
.h6 {
    margin-bottom: .5rem;
    font-weight: 500;
    line-height: 1.25;
    color: #001737
}

h1,
.h1 {
    font-size: 2.1875rem
}

h2,
.h2 {
    font-size: 1.75rem
}

h3,
.h3 {
    font-size: 1.53125rem
}

h4,
.h4 {
    font-size: 1.3125rem
}

h5,
.h5 {
    font-size: 1.09375rem
}

h6,
.h6 {
    font-size: .875rem
}

.lead {
    font-size: 1.09375rem;
    font-weight: 300
}

.display-1 {
    font-size: 6rem;
    font-weight: 300;
    line-height: 1.25
}

.display-2 {
    font-size: 5.5rem;
    font-weight: 300;
    line-height: 1.25
}

.display-3 {
    font-size: 4.5rem;
    font-weight: 300;
    line-height: 1.25
}

.display-4 {
    font-size: 3.5rem;
    font-weight: 300;
    line-height: 1.25
}

hr {
    margin-top: 1rem;
    margin-bottom: 1rem;
    border: 0;
    border-top: 1px solid rgba(0, 0, 0, 0.1)
}

small,
.small {
    font-size: 80%;
    font-weight: 400
}

mark,
.mark {
    padding: .2em;
    background-color: #fcf8e3
}

.list-unstyled {
    padding-left: 0;
    list-style: none
}

.list-inline {
    padding-left: 0;
    list-style: none
}

.list-inline-item {
    display: inline-block
}

.list-inline-item:not(:last-child) {
    margin-right: .5rem
}

.initialism {
    font-size: 90%;
    text-transform: uppercase
}

.blockquote {
    margin-bottom: 1rem;
    font-size: 1.09375rem
}

.blockquote-footer {
    display: block;
    font-size: 80%;
    color: #7987a1
}

.blockquote-footer::before {
    content: "\2014\00A0"
}

.img-fluid {
    max-width: 100%;
    height: auto
}

.img-thumbnail {
    padding: .25rem;
    background-color: #fff;
    border: 1px solid #cdd4e0;
    border-radius: .25rem;
    max-width: 100%;
    height: auto
}

.figure {
    display: inline-block
}

.figure-img {
    margin-bottom: .5rem;
    line-height: 1
}

.figure-caption {
    font-size: 90%;
    color: #7987a1
}

code {
    font-size: 87.5%;
    color: #f10075;
    word-wrap: break-word
}

a>code {
    color: inherit
}

kbd {
    padding: .2rem .4rem;
    font-size: 87.5%;
    color: #fff;
    background-color: #1c273c;
    border-radius: .2rem
}

kbd kbd {
    padding: 0;
    font-size: 100%;
    font-weight: 700
}

pre {
    display: block;
    font-size: 87.5%;
    color: #1c273c
}

pre code {
    font-size: inherit;
    color: inherit;
    word-break: normal
}

.pre-scrollable {
    max-height: 340px;
    overflow-y: scroll
}

.container {
    width: 100%;
    padding-right: 15px;
    padding-left: 15px;
    margin-right: auto;
    margin-left: auto
}

@media (min-width: 576px) {
    .container {
        max-width: 540px
    }
}

@media (min-width: 768px) {
    .container {
        max-width: 720px
    }
}

@media (min-width: 992px) {
    .container {
        max-width: 960px
    }
}

@media (min-width: 1200px) {
    .container {
        max-width: 1140px
    }
}

.container-fluid,
.container-sm,
.container-md,
.container-lg,
.container-xl {
    width: 100%;
    padding-right: 15px;
    padding-left: 15px;
    margin-right: auto;
    margin-left: auto
}

@media (min-width: 576px) {

    .container,
    .container-sm {
        max-width: 540px
    }
}

@media (min-width: 768px) {

    .container,
    .container-sm,
    .container-md {
        max-width: 720px
    }
}

@media (min-width: 992px) {

    .container,
    .container-sm,
    .container-md,
    .container-lg {
        max-width: 960px
    }
}

@media (min-width: 1200px) {

    .container,
    .container-sm,
    .container-md,
    .container-lg,
    .container-xl {
        max-width: 1140px
    }
}

.row {
    display: flex;
    flex-wrap: wrap;
    margin-right: -15px;
    margin-left: -15px
}

.no-gutters {
    margin-right: 0;
    margin-left: 0
}

.no-gutters>.col,
.no-gutters>[class*="col-"] {
    padding-right: 0;
    padding-left: 0
}

.col-1,
.col-2,
.col-3,
.col-4,
.col-5,
.col-6,
.col-7,
.col-8,
.col-9,
.col-10,
.col-11,
.col-12,
.col,
.col-auto,
.col-sm-1,
.col-sm-2,
.col-sm-3,
.col-sm-4,
.col-sm-5,
.col-sm-6,
.col-sm-7,
.col-sm-8,
.col-sm-9,
.col-sm-10,
.col-sm-11,
.col-sm-12,
.col-sm,
.col-sm-auto,
.col-md-1,
.col-md-2,
.col-md-3,
.col-md-4,
.col-md-5,
.col-md-6,
.col-md-7,
.col-md-8,
.col-md-9,
.col-md-10,
.col-md-11,
.col-md-12,
.col-md,
.col-md-auto,
.col-lg-1,
.col-lg-2,
.col-lg-3,
.col-lg-4,
.col-lg-5,
.col-lg-6,
.col-lg-7,
.col-lg-8,
.col-lg-9,
.col-lg-10,
.col-lg-11,
.col-lg-12,
.col-lg,
.col-lg-auto,
.col-xl-1,
.col-xl-2,
.col-xl-3,
.col-xl-4,
.col-xl-5,
.col-xl-6,
.col-xl-7,
.col-xl-8,
.col-xl-9,
.col-xl-10,
.col-xl-11,
.col-xl-12,
.col-xl,
.col-xl-auto {
    position: relative;
    width: 100%;
    padding-right: 15px;
    padding-left: 15px
}

.col {
    flex-basis: 0;
    flex-grow: 1;
    max-width: 100%
}

.row-cols-1>* {
    flex: 0 0 100%;
    max-width: 100%
}

.row-cols-2>* {
    flex: 0 0 50%;
    max-width: 50%
}

.row-cols-3>* {
    flex: 0 0 33.33333%;
    max-width: 33.33333%
}

.row-cols-4>* {
    flex: 0 0 25%;
    max-width: 25%
}

.row-cols-5>* {
    flex: 0 0 20%;
    max-width: 20%
}

.row-cols-6>* {
    flex: 0 0 16.66667%;
    max-width: 16.66667%
}

.col-auto {
    flex: 0 0 auto;
    width: auto;
    max-width: 100%
}

.col-1 {
    flex: 0 0 8.33333%;
    max-width: 8.33333%
}

.col-2 {
    flex: 0 0 16.66667%;
    max-width: 16.66667%
}

.col-3 {
    flex: 0 0 25%;
    max-width: 25%
}

.col-4 {
    flex: 0 0 33.33333%;
    max-width: 33.33333%
}

.col-5 {
    flex: 0 0 41.66667%;
    max-width: 41.66667%
}

.col-6 {
    flex: 0 0 50%;
    max-width: 50%
}

.col-7 {
    flex: 0 0 58.33333%;
    max-width: 58.33333%
}

.col-8 {
    flex: 0 0 66.66667%;
    max-width: 66.66667%
}

.col-9 {
    flex: 0 0 75%;
    max-width: 75%
}

.col-10 {
    flex: 0 0 83.33333%;
    max-width: 83.33333%
}

.col-11 {
    flex: 0 0 91.66667%;
    max-width: 91.66667%
}

.col-12 {
    flex: 0 0 100%;
    max-width: 100%
}

.order-first {
    order: -1
}

.order-last {
    order: 13
}

.order-0 {
    order: 0
}

.order-1 {
    order: 1
}

.order-2 {
    order: 2
}

.order-3 {
    order: 3
}

.order-4 {
    order: 4
}

.order-5 {
    order: 5
}

.order-6 {
    order: 6
}

.order-7 {
    order: 7
}

.order-8 {
    order: 8
}

.order-9 {
    order: 9
}

.order-10 {
    order: 10
}

.order-11 {
    order: 11
}

.order-12 {
    order: 12
}

.offset-1 {
    margin-left: 8.33333%
}

.offset-2 {
    margin-left: 16.66667%
}

.offset-3 {
    margin-left: 25%
}

.offset-4 {
    margin-left: 33.33333%
}

.offset-5 {
    margin-left: 41.66667%
}

.offset-6 {
    margin-left: 50%
}

.offset-7 {
    margin-left: 58.33333%
}

.offset-8 {
    margin-left: 66.66667%
}

.offset-9 {
    margin-left: 75%
}

.offset-10 {
    margin-left: 83.33333%
}

.offset-11 {
    margin-left: 91.66667%
}

@media (min-width: 576px) {
    .col-sm {
        flex-basis: 0;
        flex-grow: 1;
        max-width: 100%
    }

    .row-cols-sm-1>* {
        flex: 0 0 100%;
        max-width: 100%
    }

    .row-cols-sm-2>* {
        flex: 0 0 50%;
        max-width: 50%
    }

    .row-cols-sm-3>* {
        flex: 0 0 33.33333%;
        max-width: 33.33333%
    }

    .row-cols-sm-4>* {
        flex: 0 0 25%;
        max-width: 25%
    }

    .row-cols-sm-5>* {
        flex: 0 0 20%;
        max-width: 20%
    }

    .row-cols-sm-6>* {
        flex: 0 0 16.66667%;
        max-width: 16.66667%
    }

    .col-sm-auto {
        flex: 0 0 auto;
        width: auto;
        max-width: 100%
    }

    .col-sm-1 {
        flex: 0 0 8.33333%;
        max-width: 8.33333%
    }

    .col-sm-2 {
        flex: 0 0 16.66667%;
        max-width: 16.66667%
    }

    .col-sm-3 {
        flex: 0 0 25%;
        max-width: 25%
    }

    .col-sm-4 {
        flex: 0 0 33.33333%;
        max-width: 33.33333%
    }

    .col-sm-5 {
        flex: 0 0 41.66667%;
        max-width: 41.66667%
    }

    .col-sm-6 {
        flex: 0 0 50%;
        max-width: 50%
    }

    .col-sm-7 {
        flex: 0 0 58.33333%;
        max-width: 58.33333%
    }

    .col-sm-8 {
        flex: 0 0 66.66667%;
        max-width: 66.66667%
    }

    .col-sm-9 {
        flex: 0 0 75%;
        max-width: 75%
    }

    .col-sm-10 {
        flex: 0 0 83.33333%;
        max-width: 83.33333%
    }

    .col-sm-11 {
        flex: 0 0 91.66667%;
        max-width: 91.66667%
    }

    .col-sm-12 {
        flex: 0 0 100%;
        max-width: 100%
    }

    .order-sm-first {
        order: -1
    }

    .order-sm-last {
        order: 13
    }

    .order-sm-0 {
        order: 0
    }

    .order-sm-1 {
        order: 1
    }

    .order-sm-2 {
        order: 2
    }

    .order-sm-3 {
        order: 3
    }

    .order-sm-4 {
        order: 4
    }

    .order-sm-5 {
        order: 5
    }

    .order-sm-6 {
        order: 6
    }

    .order-sm-7 {
        order: 7
    }

    .order-sm-8 {
        order: 8
    }

    .order-sm-9 {
        order: 9
    }

    .order-sm-10 {
        order: 10
    }

    .order-sm-11 {
        order: 11
    }

    .order-sm-12 {
        order: 12
    }

    .offset-sm-0 {
        margin-left: 0
    }

    .offset-sm-1 {
        margin-left: 8.33333%
    }

    .offset-sm-2 {
        margin-left: 16.66667%
    }

    .offset-sm-3 {
        margin-left: 25%
    }

    .offset-sm-4 {
        margin-left: 33.33333%
    }

    .offset-sm-5 {
        margin-left: 41.66667%
    }

    .offset-sm-6 {
        margin-left: 50%
    }

    .offset-sm-7 {
        margin-left: 58.33333%
    }

    .offset-sm-8 {
        margin-left: 66.66667%
    }

    .offset-sm-9 {
        margin-left: 75%
    }

    .offset-sm-10 {
        margin-left: 83.33333%
    }

    .offset-sm-11 {
        margin-left: 91.66667%
    }
}

@media (min-width: 768px) {
    .col-md {
        flex-basis: 0;
        flex-grow: 1;
        max-width: 100%
    }

    .row-cols-md-1>* {
        flex: 0 0 100%;
        max-width: 100%
    }

    .row-cols-md-2>* {
        flex: 0 0 50%;
        max-width: 50%
    }

    .row-cols-md-3>* {
        flex: 0 0 33.33333%;
        max-width: 33.33333%
    }

    .row-cols-md-4>* {
        flex: 0 0 25%;
        max-width: 25%
    }

    .row-cols-md-5>* {
        flex: 0 0 20%;
        max-width: 20%
    }

    .row-cols-md-6>* {
        flex: 0 0 16.66667%;
        max-width: 16.66667%
    }

    .col-md-auto {
        flex: 0 0 auto;
        width: auto;
        max-width: 100%
    }

    .col-md-1 {
        flex: 0 0 8.33333%;
        max-width: 8.33333%
    }

    .col-md-2 {
        flex: 0 0 16.66667%;
        max-width: 16.66667%
    }

    .col-md-3 {
        flex: 0 0 25%;
        max-width: 25%
    }

    .col-md-4 {
        flex: 0 0 33.33333%;
        max-width: 33.33333%
    }

    .col-md-5 {
        flex: 0 0 41.66667%;
        max-width: 41.66667%
    }

    .col-md-6 {
        flex: 0 0 50%;
        max-width: 50%
    }

    .col-md-7 {
        flex: 0 0 58.33333%;
        max-width: 58.33333%
    }

    .col-md-8 {
        flex: 0 0 66.66667%;
        max-width: 66.66667%
    }

    .col-md-9 {
        flex: 0 0 75%;
        max-width: 75%
    }

    .col-md-10 {
        flex: 0 0 83.33333%;
        max-width: 83.33333%
    }

    .col-md-11 {
        flex: 0 0 91.66667%;
        max-width: 91.66667%
    }

    .col-md-12 {
        flex: 0 0 100%;
        max-width: 100%
    }

    .order-md-first {
        order: -1
    }

    .order-md-last {
        order: 13
    }

    .order-md-0 {
        order: 0
    }

    .order-md-1 {
        order: 1
    }

    .order-md-2 {
        order: 2
    }

    .order-md-3 {
        order: 3
    }

    .order-md-4 {
        order: 4
    }

    .order-md-5 {
        order: 5
    }

    .order-md-6 {
        order: 6
    }

    .order-md-7 {
        order: 7
    }

    .order-md-8 {
        order: 8
    }

    .order-md-9 {
        order: 9
    }

    .order-md-10 {
        order: 10
    }

    .order-md-11 {
        order: 11
    }

    .order-md-12 {
        order: 12
    }

    .offset-md-0 {
        margin-left: 0
    }

    .offset-md-1 {
        margin-left: 8.33333%
    }

    .offset-md-2 {
        margin-left: 16.66667%
    }

    .offset-md-3 {
        margin-left: 25%
    }

    .offset-md-4 {
        margin-left: 33.33333%
    }

    .offset-md-5 {
        margin-left: 41.66667%
    }

    .offset-md-6 {
        margin-left: 50%
    }

    .offset-md-7 {
        margin-left: 58.33333%
    }

    .offset-md-8 {
        margin-left: 66.66667%
    }

    .offset-md-9 {
        margin-left: 75%
    }

    .offset-md-10 {
        margin-left: 83.33333%
    }

    .offset-md-11 {
        margin-left: 91.66667%
    }
}

@media (min-width: 992px) {
    .col-lg {
        flex-basis: 0;
        flex-grow: 1;
        max-width: 100%
    }

    .row-cols-lg-1>* {
        flex: 0 0 100%;
        max-width: 100%
    }

    .row-cols-lg-2>* {
        flex: 0 0 50%;
        max-width: 50%
    }

    .row-cols-lg-3>* {
        flex: 0 0 33.33333%;
        max-width: 33.33333%
    }

    .row-cols-lg-4>* {
        flex: 0 0 25%;
        max-width: 25%
    }

    .row-cols-lg-5>* {
        flex: 0 0 20%;
        max-width: 20%
    }

    .row-cols-lg-6>* {
        flex: 0 0 16.66667%;
        max-width: 16.66667%
    }

    .col-lg-auto {
        flex: 0 0 auto;
        width: auto;
        max-width: 100%
    }

    .col-lg-1 {
        flex: 0 0 8.33333%;
        max-width: 8.33333%
    }

    .col-lg-2 {
        flex: 0 0 16.66667%;
        max-width: 16.66667%
    }

    .col-lg-3 {
        flex: 0 0 25%;
        max-width: 25%
    }

    .col-lg-4 {
        flex: 0 0 33.33333%;
        max-width: 33.33333%
    }

    .col-lg-5 {
        flex: 0 0 41.66667%;
        max-width: 41.66667%
    }

    .col-lg-6 {
        flex: 0 0 50%;
        max-width: 50%
    }

    .col-lg-7 {
        flex: 0 0 58.33333%;
        max-width: 58.33333%
    }

    .col-lg-8 {
        flex: 0 0 66.66667%;
        max-width: 66.66667%
    }

    .col-lg-9 {
        flex: 0 0 75%;
        max-width: 75%
    }

    .col-lg-10 {
        flex: 0 0 83.33333%;
        max-width: 83.33333%
    }

    .col-lg-11 {
        flex: 0 0 91.66667%;
        max-width: 91.66667%
    }

    .col-lg-12 {
        flex: 0 0 100%;
        max-width: 100%
    }

    .order-lg-first {
        order: -1
    }

    .order-lg-last {
        order: 13
    }

    .order-lg-0 {
        order: 0
    }

    .order-lg-1 {
        order: 1
    }

    .order-lg-2 {
        order: 2
    }

    .order-lg-3 {
        order: 3
    }

    .order-lg-4 {
        order: 4
    }

    .order-lg-5 {
        order: 5
    }

    .order-lg-6 {
        order: 6
    }

    .order-lg-7 {
        order: 7
    }

    .order-lg-8 {
        order: 8
    }

    .order-lg-9 {
        order: 9
    }

    .order-lg-10 {
        order: 10
    }

    .order-lg-11 {
        order: 11
    }

    .order-lg-12 {
        order: 12
    }

    .offset-lg-0 {
        margin-left: 0
    }

    .offset-lg-1 {
        margin-left: 8.33333%
    }

    .offset-lg-2 {
        margin-left: 16.66667%
    }

    .offset-lg-3 {
        margin-left: 25%
    }

    .offset-lg-4 {
        margin-left: 33.33333%
    }

    .offset-lg-5 {
        margin-left: 41.66667%
    }

    .offset-lg-6 {
        margin-left: 50%
    }

    .offset-lg-7 {
        margin-left: 58.33333%
    }

    .offset-lg-8 {
        margin-left: 66.66667%
    }

    .offset-lg-9 {
        margin-left: 75%
    }

    .offset-lg-10 {
        margin-left: 83.33333%
    }

    .offset-lg-11 {
        margin-left: 91.66667%
    }
}

@media (min-width: 1200px) {
    .col-xl {
        flex-basis: 0;
        flex-grow: 1;
        max-width: 100%
    }

    .row-cols-xl-1>* {
        flex: 0 0 100%;
        max-width: 100%
    }

    .row-cols-xl-2>* {
        flex: 0 0 50%;
        max-width: 50%
    }

    .row-cols-xl-3>* {
        flex: 0 0 33.33333%;
        max-width: 33.33333%
    }

    .row-cols-xl-4>* {
        flex: 0 0 25%;
        max-width: 25%
    }

    .row-cols-xl-5>* {
        flex: 0 0 20%;
        max-width: 20%
    }

    .row-cols-xl-6>* {
        flex: 0 0 16.66667%;
        max-width: 16.66667%
    }

    .col-xl-auto {
        flex: 0 0 auto;
        width: auto;
        max-width: 100%
    }

    .col-xl-1 {
        flex: 0 0 8.33333%;
        max-width: 8.33333%
    }

    .col-xl-2 {
        flex: 0 0 16.66667%;
        max-width: 16.66667%
    }

    .col-xl-3 {
        flex: 0 0 25%;
        max-width: 25%
    }

    .col-xl-4 {
        flex: 0 0 33.33333%;
        max-width: 33.33333%
    }

    .col-xl-5 {
        flex: 0 0 41.66667%;
        max-width: 41.66667%
    }

    .col-xl-6 {
        flex: 0 0 50%;
        max-width: 50%
    }

    .col-xl-7 {
        flex: 0 0 58.33333%;
        max-width: 58.33333%
    }

    .col-xl-8 {
        flex: 0 0 66.66667%;
        max-width: 66.66667%
    }

    .col-xl-9 {
        flex: 0 0 75%;
        max-width: 75%
    }

    .col-xl-10 {
        flex: 0 0 83.33333%;
        max-width: 83.33333%
    }

    .col-xl-11 {
        flex: 0 0 91.66667%;
        max-width: 91.66667%
    }

    .col-xl-12 {
        flex: 0 0 100%;
        max-width: 100%
    }

    .order-xl-first {
        order: -1
    }

    .order-xl-last {
        order: 13
    }

    .order-xl-0 {
        order: 0
    }

    .order-xl-1 {
        order: 1
    }

    .order-xl-2 {
        order: 2
    }

    .order-xl-3 {
        order: 3
    }

    .order-xl-4 {
        order: 4
    }

    .order-xl-5 {
        order: 5
    }

    .order-xl-6 {
        order: 6
    }

    .order-xl-7 {
        order: 7
    }

    .order-xl-8 {
        order: 8
    }

    .order-xl-9 {
        order: 9
    }

    .order-xl-10 {
        order: 10
    }

    .order-xl-11 {
        order: 11
    }

    .order-xl-12 {
        order: 12
    }

    .offset-xl-0 {
        margin-left: 0
    }

    .offset-xl-1 {
        margin-left: 8.33333%
    }

    .offset-xl-2 {
        margin-left: 16.66667%
    }

    .offset-xl-3 {
        margin-left: 25%
    }

    .offset-xl-4 {
        margin-left: 33.33333%
    }

    .offset-xl-5 {
        margin-left: 41.66667%
    }

    .offset-xl-6 {
        margin-left: 50%
    }

    .offset-xl-7 {
        margin-left: 58.33333%
    }

    .offset-xl-8 {
        margin-left: 66.66667%
    }

    .offset-xl-9 {
        margin-left: 75%
    }

    .offset-xl-10 {
        margin-left: 83.33333%
    }

    .offset-xl-11 {
        margin-left: 91.66667%
    }
}

.table {
    width: 100%;
    margin-bottom: 1rem;
    color: #001737
}

.table th,
.table td {
    padding: .75rem;
    vertical-align: top;
    border-top: 1px solid rgba(72, 94, 144, 0.16)
}

.table thead th {
    vertical-align: bottom;
    border-bottom: 2px solid rgba(72, 94, 144, 0.16)
}

.table tbody+tbody {
    border-top: 2px solid rgba(72, 94, 144, 0.16)
}

.table-sm th,
.table-sm td {
    padding: .3rem
}

.table-bordered {
    border: 1px solid rgba(72, 94, 144, 0.16)
}

.table-bordered th,
.table-bordered td {
    border: 1px solid rgba(72, 94, 144, 0.16)
}

.table-bordered thead th,
.table-bordered thead td {
    border-bottom-width: 2px
}

.table-borderless th,
.table-borderless td,
.table-borderless thead th,
.table-borderless tbody+tbody {
    border: 0
}

.table-striped tbody tr:nth-of-type(odd) {
    background-color: rgba(0, 0, 0, 0.05)
}

.table-hover tbody tr:hover {
    color: #001737;
    background-color: rgba(0, 0, 0, 0.075)
}

.table-primary,
.table-primary>th,
.table-primary>td {
    background-color: #b8d5fe
}

.table-primary th,
.table-primary td,
.table-primary thead th,
.table-primary tbody+tbody {
    border-color: #7bb0fc
}

.table-hover .table-primary:hover {
    background-color: #9fc6fe
}

.table-hover .table-primary:hover>td,
.table-hover .table-primary:hover>th {
    background-color: #9fc6fe
}

.table-secondary,
.table-secondary>th,
.table-secondary>td {
    background-color: #d9dde5
}

.table-secondary th,
.table-secondary td,
.table-secondary thead th,
.table-secondary tbody+tbody {
    border-color: #b9c1ce
}

.table-hover .table-secondary:hover {
    background-color: #cacfdb
}

.table-hover .table-secondary:hover>td,
.table-hover .table-secondary:hover>th {
    background-color: #cacfdb
}

.table-success,
.table-success>th,
.table-success>td {
    background-color: #bcebd1
}

.table-success th,
.table-success td,
.table-success thead th,
.table-success tbody+tbody {
    border-color: #83daa9
}

.table-hover .table-success:hover {
    background-color: #a8e5c4
}

.table-hover .table-success:hover>td,
.table-hover .table-success:hover>th {
    background-color: #a8e5c4
}

.table-info,
.table-info>th,
.table-info>td {
    background-color: #b8ebf3
}

.table-info th,
.table-info td,
.table-info thead th,
.table-info tbody+tbody {
    border-color: #7adae9
}

.table-hover .table-info:hover {
    background-color: #a2e5ef
}

.table-hover .table-info:hover>td,
.table-hover .table-info:hover>th {
    background-color: #a2e5ef
}

.table-warning,
.table-warning>th,
.table-warning>td {
    background-color: #ffeeba
}

.table-warning th,
.table-warning td,
.table-warning thead th,
.table-warning tbody+tbody {
    border-color: #ffdf7e
}

.table-hover .table-warning:hover {
    background-color: #ffe8a1
}

.table-hover .table-warning:hover>td,
.table-hover .table-warning:hover>th {
    background-color: #ffe8a1
}

.table-danger,
.table-danger>th,
.table-danger>td {
    background-color: #f5c6cb
}

.table-danger th,
.table-danger td,
.table-danger thead th,
.table-danger tbody+tbody {
    border-color: #ed969e
}

.table-hover .table-danger:hover {
    background-color: #f1b0b7
}

.table-hover .table-danger:hover>td,
.table-hover .table-danger:hover>th {
    background-color: #f1b0b7
}

.table-light,
.table-light>th,
.table-light>td {
    background-color: #fcfcfd
}

.table-light th,
.table-light td,
.table-light thead th,
.table-light tbody+tbody {
    border-color: #f9fafb
}

.table-hover .table-light:hover {
    background-color: #ededf3
}

.table-hover .table-light:hover>td,
.table-hover .table-light:hover>th {
    background-color: #ededf3
}

.table-dark,
.table-dark>th,
.table-dark>td {
    background-color: #c8ccd3
}

.table-dark th,
.table-dark td,
.table-dark thead th,
.table-dark tbody+tbody {
    border-color: #99a0ae
}

.table-hover .table-dark:hover {
    background-color: #babfc8
}

.table-hover .table-dark:hover>td,
.table-hover .table-dark:hover>th {
    background-color: #babfc8
}

.table-active,
.table-active>th,
.table-active>td {
    background-color: rgba(0, 0, 0, 0.075)
}

.table-hover .table-active:hover {
    background-color: rgba(0, 0, 0, 0.075)
}

.table-hover .table-active:hover>td,
.table-hover .table-active:hover>th {
    background-color: rgba(0, 0, 0, 0.075)
}

.table .thead-dark th {
    color: #fff;
    background-color: #3b4863;
    border-color: #49597b
}

.table .thead-light th {
    color: #596882;
    background-color: #e3e7ed;
    border-color: rgba(72, 94, 144, 0.16)
}

.table-dark {
    color: #fff;
    background-color: #3b4863
}

.table-dark th,
.table-dark td,
.table-dark thead th {
    border-color: #49597b
}

.table-dark.table-bordered {
    border: 0
}

.table-dark.table-striped tbody tr:nth-of-type(odd) {
    background-color: rgba(255, 255, 255, 0.05)
}

.table-dark.table-hover tbody tr:hover {
    color: #fff;
    background-color: rgba(255, 255, 255, 0.075)
}

@media (max-width: 575.98px) {
    .table-responsive-sm {
        display: block;
        width: 100%;
        overflow-x: auto;
        -webkit-overflow-scrolling: touch
    }

    .table-responsive-sm>.table-bordered {
        border: 0
    }
}

@media (max-width: 767.98px) {
    .table-responsive-md {
        display: block;
        width: 100%;
        overflow-x: auto;
        -webkit-overflow-scrolling: touch
    }

    .table-responsive-md>.table-bordered {
        border: 0
    }
}

@media (max-width: 991.98px) {
    .table-responsive-lg {
        display: block;
        width: 100%;
        overflow-x: auto;
        -webkit-overflow-scrolling: touch
    }

    .table-responsive-lg>.table-bordered {
        border: 0
    }
}

@media (max-width: 1199.98px) {
    .table-responsive-xl {
        display: block;
        width: 100%;
        overflow-x: auto;
        -webkit-overflow-scrolling: touch
    }

    .table-responsive-xl>.table-bordered {
        border: 0
    }
}

.table-responsive {
    display: block;
    width: 100%;
    overflow-x: auto;
    -webkit-overflow-scrolling: touch
}

.table-responsive>.table-bordered {
    border: 0
}

.form-control {
    display: block;
    width: 100%;
    height: calc(1.5em + .9375rem + 2px);
    padding: .46875rem .625rem;
    font-size: .875rem;
    font-weight: 400;
    line-height: 1.5;
    color: #596882;
    background-color: #fff;
    background-clip: padding-box;
    border: 1px solid #c0ccda;
    border-radius: .25rem;
    transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out
}

@media (prefers-reduced-motion: reduce) {
    .form-control {
        transition: none
    }
}

.form-control::-ms-expand {
    background-color: transparent;
    border: 0
}

.form-control:-moz-focusring {
    color: transparent;
    text-shadow: 0 0 0 #596882
}

.form-control:focus {
    color: #596882;
    background-color: #fff;
    border-color: #7cb2fe;
    outline: 0;
    box-shadow: 0 0 0 .2rem rgba(1, 104, 250, 0.25)
}

.form-control::placeholder {
    color: #7987a1;
    opacity: 1
}

.form-control:disabled,
.form-control[readonly] {
    background-color: #e3e7ed;
    opacity: 1
}

select.form-control:focus::-ms-value {
    color: #596882;
    background-color: #fff
}

.form-control-file,
.form-control-range {
    display: block;
    width: 100%
}

.col-form-label {
    padding-top: calc(.46875rem + 1px);
    padding-bottom: calc(.46875rem + 1px);
    margin-bottom: 0;
    font-size: inherit;
    line-height: 1.5
}

.col-form-label-lg {
    padding-top: calc(.6rem + 1px);
    padding-bottom: calc(.6rem + 1px);
    font-size: 1.09375rem;
    line-height: 1.5
}

.col-form-label-sm {
    padding-top: calc(.391rem + 1px);
    padding-bottom: calc(.391rem + 1px);
    font-size: .8125rem;
    line-height: 1.5
}

.form-control-plaintext {
    display: block;
    width: 100%;
    padding: .46875rem 0;
    margin-bottom: 0;
    font-size: .875rem;
    line-height: 1.5;
    color: #001737;
    background-color: transparent;
    border: solid transparent;
    border-width: 1px 0
}

.form-control-plaintext.form-control-sm,
.form-control-plaintext.form-control-lg {
    padding-right: 0;
    padding-left: 0
}

.form-control-sm {
    height: calc(1.5em + .782rem + 2px);
    padding: .391rem .5rem;
    font-size: .8125rem;
    line-height: 1.5;
    border-radius: .2rem
}

.form-control-lg {
    height: calc(1.5em + 1.2rem + 2px);
    padding: .6rem 1rem;
    font-size: 1.09375rem;
    line-height: 1.5;
    border-radius: .3rem
}

select.form-control[size],
select.form-control[multiple] {
    height: auto
}

textarea.form-control {
    height: auto
}

.form-group {
    margin-bottom: 1rem
}

.form-text {
    display: block;
    margin-top: .25rem
}

.form-row {
    display: flex;
    flex-wrap: wrap;
    margin-right: -5px;
    margin-left: -5px
}

.form-row>.col,
.form-row>[class*="col-"] {
    padding-right: 5px;
    padding-left: 5px
}

.form-check {
    position: relative;
    display: block;
    padding-left: 1.25rem
}

.form-check-input {
    position: absolute;
    margin-top: .3rem;
    margin-left: -1.25rem
}

.form-check-input[disabled]~.form-check-label,
.form-check-input:disabled~.form-check-label {
    color: #7987a1
}

.form-check-label {
    margin-bottom: 0
}

.form-check-inline {
    display: inline-flex;
    align-items: center;
    padding-left: 0;
    margin-right: .75rem
}

.form-check-inline .form-check-input {
    position: static;
    margin-top: 0;
    margin-right: .3125rem;
    margin-left: 0
}

.valid-feedback {
    display: none;
    width: 100%;
    margin-top: .25rem;
    font-size: 80%;
    color: #10b759
}

.valid-tooltip {
    position: absolute;
    top: 100%;
    z-index: 5;
    display: none;
    max-width: 100%;
    padding: .25rem .5rem;
    margin-top: .1rem;
    font-size: .76562rem;
    line-height: 1.5;
    color: #fff;
    background-color: rgba(16, 183, 89, 0.9);
    border-radius: .25rem
}

.was-validated :valid~.valid-feedback,
.was-validated :valid~.valid-tooltip,
.is-valid~.valid-feedback,
.is-valid~.valid-tooltip {
    display: block
}

.was-validated .form-control:valid,
.form-control.is-valid {
    border-color: #10b759;
    padding-right: calc(1.5em + .9375rem);
    background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' width='8' height='8' viewBox='0 0 8 8'%3e%3cpath fill='%2310b759' d='M2.3 6.73L.6 4.53c-.4-1.04.46-1.4 1.1-.8l1.1 1.4 3.4-3.8c.6-.63 1.6-.27 1.2.7l-4 4.6c-.43.5-.8.4-1.1.1z'/%3e%3c/svg%3e");
    background-repeat: no-repeat;
    background-position: right calc(.375em + .23438rem) center;
    background-size: calc(.75em + .46875rem) calc(.75em + .46875rem)
}

.was-validated .form-control:valid:focus,
.form-control.is-valid:focus {
    border-color: #10b759;
    box-shadow: 0 0 0 .2rem rgba(16, 183, 89, 0.25)
}

.was-validated textarea.form-control:valid,
textarea.form-control.is-valid {
    padding-right: calc(1.5em + .9375rem);
    background-position: top calc(.375em + .23438rem) right calc(.375em + .23438rem)
}

.was-validated .custom-select:valid,
.custom-select.is-valid {
    border-color: #10b759;
    padding-right: calc(.75em + 2.32812rem);
    background: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' width='4' height='5' viewBox='0 0 4 5'%3e%3cpath fill='%233b4863' d='M2 0L0 2h4zm0 5L0 3h4z'/%3e%3c/svg%3e") no-repeat right .625rem center/8px 10px, url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' width='8' height='8' viewBox='0 0 8 8'%3e%3cpath fill='%2310b759' d='M2.3 6.73L.6 4.53c-.4-1.04.46-1.4 1.1-.8l1.1 1.4 3.4-3.8c.6-.63 1.6-.27 1.2.7l-4 4.6c-.43.5-.8.4-1.1.1z'/%3e%3c/svg%3e") #fff no-repeat center right 1.625rem/calc(.75em + .46875rem) calc(.75em + .46875rem)
}

.was-validated .custom-select:valid:focus,
.custom-select.is-valid:focus {
    border-color: #10b759;
    box-shadow: 0 0 0 .2rem rgba(16, 183, 89, 0.25)
}

.was-validated .form-check-input:valid~.form-check-label,
.form-check-input.is-valid~.form-check-label {
    color: #10b759
}

.was-validated .form-check-input:valid~.valid-feedback,
.was-validated .form-check-input:valid~.valid-tooltip,
.form-check-input.is-valid~.valid-feedback,
.form-check-input.is-valid~.valid-tooltip {
    display: block
}

.was-validated .custom-control-input:valid~.custom-control-label,
.custom-control-input.is-valid~.custom-control-label {
    color: #10b759
}

.was-validated .custom-control-input:valid~.custom-control-label::before,
.custom-control-input.is-valid~.custom-control-label::before {
    border-color: #10b759
}

.was-validated .custom-control-input:valid:checked~.custom-control-label::before,
.custom-control-input.is-valid:checked~.custom-control-label::before {
    border-color: #14e670;
    background-color: #14e670
}

.was-validated .custom-control-input:valid:focus~.custom-control-label::before,
.custom-control-input.is-valid:focus~.custom-control-label::before {
    box-shadow: 0 0 0 .2rem rgba(16, 183, 89, 0.25)
}

.was-validated .custom-control-input:valid:focus:not(:checked)~.custom-control-label::before,
.custom-control-input.is-valid:focus:not(:checked)~.custom-control-label::before {
    border-color: #10b759
}

.was-validated .custom-file-input:valid~.custom-file-label,
.custom-file-input.is-valid~.custom-file-label {
    border-color: #10b759
}

.was-validated .custom-file-input:valid:focus~.custom-file-label,
.custom-file-input.is-valid:focus~.custom-file-label {
    border-color: #10b759;
    box-shadow: 0 0 0 .2rem rgba(16, 183, 89, 0.25)
}

.invalid-feedback {
    display: none;
    width: 100%;
    margin-top: .25rem;
    font-size: 80%;
    color: #dc3545
}

.invalid-tooltip {
    position: absolute;
    top: 100%;
    z-index: 5;
    display: none;
    max-width: 100%;
    padding: .25rem .5rem;
    margin-top: .1rem;
    font-size: .76562rem;
    line-height: 1.5;
    color: #fff;
    background-color: rgba(220, 53, 69, 0.9);
    border-radius: .25rem
}

.was-validated :invalid~.invalid-feedback,
.was-validated :invalid~.invalid-tooltip,
.is-invalid~.invalid-feedback,
.is-invalid~.invalid-tooltip {
    display: block
}

.was-validated .form-control:invalid,
.form-control.is-invalid {
    border-color: #dc3545;
    padding-right: calc(1.5em + .9375rem);
    background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' width='12' height='12' fill='none' stroke='%23dc3545' viewBox='0 0 12 12'%3e%3ccircle cx='6' cy='6' r='4.5'/%3e%3cpath stroke-linejoin='round' d='M5.8 3.6h.4L6 6.5z'/%3e%3ccircle cx='6' cy='8.2' r='.6' fill='%23dc3545' stroke='none'/%3e%3c/svg%3e");
    background-repeat: no-repeat;
    background-position: right calc(.375em + .23438rem) center;
    background-size: calc(.75em + .46875rem) calc(.75em + .46875rem)
}

.was-validated .form-control:invalid:focus,
.form-control.is-invalid:focus {
    border-color: #dc3545;
    box-shadow: 0 0 0 .2rem rgba(220, 53, 69, 0.25)
}

.was-validated textarea.form-control:invalid,
textarea.form-control.is-invalid {
    padding-right: calc(1.5em + .9375rem);
    background-position: top calc(.375em + .23438rem) right calc(.375em + .23438rem)
}

.was-validated .custom-select:invalid,
.custom-select.is-invalid {
    border-color: #dc3545;
    padding-right: calc(.75em + 2.32812rem);
    background: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' width='4' height='5' viewBox='0 0 4 5'%3e%3cpath fill='%233b4863' d='M2 0L0 2h4zm0 5L0 3h4z'/%3e%3c/svg%3e") no-repeat right .625rem center/8px 10px, url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' width='12' height='12' fill='none' stroke='%23dc3545' viewBox='0 0 12 12'%3e%3ccircle cx='6' cy='6' r='4.5'/%3e%3cpath stroke-linejoin='round' d='M5.8 3.6h.4L6 6.5z'/%3e%3ccircle cx='6' cy='8.2' r='.6' fill='%23dc3545' stroke='none'/%3e%3c/svg%3e") #fff no-repeat center right 1.625rem/calc(.75em + .46875rem) calc(.75em + .46875rem)
}

.was-validated .custom-select:invalid:focus,
.custom-select.is-invalid:focus {
    border-color: #dc3545;
    box-shadow: 0 0 0 .2rem rgba(220, 53, 69, 0.25)
}

.was-validated .form-check-input:invalid~.form-check-label,
.form-check-input.is-invalid~.form-check-label {
    color: #dc3545
}

.was-validated .form-check-input:invalid~.invalid-feedback,
.was-validated .form-check-input:invalid~.invalid-tooltip,
.form-check-input.is-invalid~.invalid-feedback,
.form-check-input.is-invalid~.invalid-tooltip {
    display: block
}

.was-validated .custom-control-input:invalid~.custom-control-label,
.custom-control-input.is-invalid~.custom-control-label {
    color: #dc3545
}

.was-validated .custom-control-input:invalid~.custom-control-label::before,
.custom-control-input.is-invalid~.custom-control-label::before {
    border-color: #dc3545
}

.was-validated .custom-control-input:invalid:checked~.custom-control-label::before,
.custom-control-input.is-invalid:checked~.custom-control-label::before {
    border-color: #e4606d;
    background-color: #e4606d
}

.was-validated .custom-control-input:invalid:focus~.custom-control-label::before,
.custom-control-input.is-invalid:focus~.custom-control-label::before {
    box-shadow: 0 0 0 .2rem rgba(220, 53, 69, 0.25)
}

.was-validated .custom-control-input:invalid:focus:not(:checked)~.custom-control-label::before,
.custom-control-input.is-invalid:focus:not(:checked)~.custom-control-label::before {
    border-color: #dc3545
}

.was-validated .custom-file-input:invalid~.custom-file-label,
.custom-file-input.is-invalid~.custom-file-label {
    border-color: #dc3545
}

.was-validated .custom-file-input:invalid:focus~.custom-file-label,
.custom-file-input.is-invalid:focus~.custom-file-label {
    border-color: #dc3545;
    box-shadow: 0 0 0 .2rem rgba(220, 53, 69, 0.25)
}

.form-inline {
    display: flex;
    flex-flow: row wrap;
    align-items: center
}

.form-inline .form-check {
    width: 100%
}

@media (min-width: 576px) {
    .form-inline label {
        display: flex;
        align-items: center;
        justify-content: center;
        margin-bottom: 0
    }

    .form-inline .form-group {
        display: flex;
        flex: 0 0 auto;
        flex-flow: row wrap;
        align-items: center;
        margin-bottom: 0
    }

    .form-inline .form-control {
        display: inline-block;
        width: auto;
        vertical-align: middle
    }

    .form-inline .form-control-plaintext {
        display: inline-block
    }

    .form-inline .input-group,
    .form-inline .custom-select {
        width: auto
    }

    .form-inline .form-check {
        display: flex;
        align-items: center;
        justify-content: center;
        width: auto;
        padding-left: 0
    }

    .form-inline .form-check-input {
        position: relative;
        flex-shrink: 0;
        margin-top: 0;
        margin-right: .25rem;
        margin-left: 0
    }

    .form-inline .custom-control {
        align-items: center;
        justify-content: center
    }

    .form-inline .custom-control-label {
        margin-bottom: 0
    }
}

.btn,
.ui-datepicker-buttonpane button,
.sp-container button {
    display: inline-block;
    font-weight: 400;
    color: #001737;
    text-align: center;
    vertical-align: middle;
    cursor: pointer;
    user-select: none;
    background-color: transparent;
    border: 1px solid transparent;
    padding: .46875rem .9375rem;
    font-size: .875rem;
    line-height: 1.5;
    border-radius: .25rem;
    transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out
}

@media (prefers-reduced-motion: reduce) {

    .btn,
    .ui-datepicker-buttonpane button,
    .sp-container button {
        transition: none
    }
}

.btn:hover,
.ui-datepicker-buttonpane button:hover,
.sp-container button:hover {
    color: #001737;
    text-decoration: none
}

.btn:focus,
.ui-datepicker-buttonpane button:focus,
.sp-container button:focus,
.btn.focus,
.ui-datepicker-buttonpane button.focus,
.sp-container button.focus {
    outline: 0;
    box-shadow: none
}

.btn.disabled,
.ui-datepicker-buttonpane button.disabled,
.sp-container button.disabled,
.btn:disabled,
.ui-datepicker-buttonpane button:disabled,
.sp-container button:disabled {
    opacity: .65
}

a.btn.disabled,
fieldset:disabled a.btn {
    pointer-events: none
}

.btn-primary {
    color: #fff;
    background-color: #0168fa;
    border-color: #0168fa
}

.btn-primary:hover {
    color: #fff;
    background-color: #0158d4;
    border-color: #0153c7
}

.btn-primary:focus,
.btn-primary.focus {
    color: #fff;
    background-color: #0158d4;
    border-color: #0153c7;
    box-shadow: 0 0 0 .2rem rgba(39, 127, 251, 0.5)
}

.btn-primary.disabled,
.btn-primary:disabled {
    color: #fff;
    background-color: #0168fa;
    border-color: #0168fa
}

.btn-primary:not(:disabled):not(.disabled):active,
.btn-primary:not(:disabled):not(.disabled).active,
.show>.btn-primary.dropdown-toggle {
    color: #fff;
    background-color: #0153c7;
    border-color: #014ebb
}

.btn-primary:not(:disabled):not(.disabled):active:focus,
.btn-primary:not(:disabled):not(.disabled).active:focus,
.show>.btn-primary.dropdown-toggle:focus {
    box-shadow: 0 0 0 .2rem rgba(39, 127, 251, 0.5)
}

.btn-secondary,
.ui-datepicker-buttonpane button {
    color: #fff;
    background-color: #7987a1;
    border-color: #7987a1
}

.btn-secondary:hover,
.ui-datepicker-buttonpane button:hover {
    color: #fff;
    background-color: #64738f;
    border-color: #5f6d88
}

.btn-secondary:focus,
.ui-datepicker-buttonpane button:focus,
.btn-secondary.focus,
.ui-datepicker-buttonpane button.focus {
    color: #fff;
    background-color: #64738f;
    border-color: #5f6d88;
    box-shadow: 0 0 0 .2rem rgba(141, 153, 175, 0.5)
}

.btn-secondary.disabled,
.ui-datepicker-buttonpane button.disabled,
.btn-secondary:disabled,
.ui-datepicker-buttonpane button:disabled {
    color: #fff;
    background-color: #7987a1;
    border-color: #7987a1
}

.btn-secondary:not(:disabled):not(.disabled):active,
.ui-datepicker-buttonpane button:not(:disabled):not(.disabled):active,
.btn-secondary:not(:disabled):not(.disabled).active,
.ui-datepicker-buttonpane button:not(:disabled):not(.disabled).active,
.show>.btn-secondary.dropdown-toggle,
.ui-datepicker-buttonpane .show>button.dropdown-toggle {
    color: #fff;
    background-color: #5f6d88;
    border-color: #5a6780
}

.btn-secondary:not(:disabled):not(.disabled):active:focus,
.ui-datepicker-buttonpane button:not(:disabled):not(.disabled):active:focus,
.btn-secondary:not(:disabled):not(.disabled).active:focus,
.ui-datepicker-buttonpane button:not(:disabled):not(.disabled).active:focus,
.show>.btn-secondary.dropdown-toggle:focus,
.ui-datepicker-buttonpane .show>button.dropdown-toggle:focus {
    box-shadow: 0 0 0 .2rem rgba(141, 153, 175, 0.5)
}

.btn-success {
    color: #fff;
    background-color: #10b759;
    border-color: #10b759
}

.btn-success:hover {
    color: #fff;
    background-color: #0d9448;
    border-color: #0c8842
}

.btn-success:focus,
.btn-success.focus {
    color: #fff;
    background-color: #0d9448;
    border-color: #0c8842;
    box-shadow: 0 0 0 .2rem rgba(52, 194, 114, 0.5)
}

.btn-success.disabled,
.btn-success:disabled {
    color: #fff;
    background-color: #10b759;
    border-color: #10b759
}

.btn-success:not(:disabled):not(.disabled):active,
.btn-success:not(:disabled):not(.disabled).active,
.show>.btn-success.dropdown-toggle {
    color: #fff;
    background-color: #0c8842;
    border-color: #0b7c3c
}

.btn-success:not(:disabled):not(.disabled):active:focus,
.btn-success:not(:disabled):not(.disabled).active:focus,
.show>.btn-success.dropdown-toggle:focus {
    box-shadow: 0 0 0 .2rem rgba(52, 194, 114, 0.5)
}

.btn-info {
    color: #fff;
    background-color: #00b8d4;
    border-color: #00b8d4
}

.btn-info:hover {
    color: #fff;
    background-color: #0097ae;
    border-color: #008ca1
}

.btn-info:focus,
.btn-info.focus {
    color: #fff;
    background-color: #0097ae;
    border-color: #008ca1;
    box-shadow: 0 0 0 .2rem rgba(38, 195, 218, 0.5)
}

.btn-info.disabled,
.btn-info:disabled {
    color: #fff;
    background-color: #00b8d4;
    border-color: #00b8d4
}

.btn-info:not(:disabled):not(.disabled):active,
.btn-info:not(:disabled):not(.disabled).active,
.show>.btn-info.dropdown-toggle {
    color: #fff;
    background-color: #008ca1;
    border-color: #008194
}

.btn-info:not(:disabled):not(.disabled):active:focus,
.btn-info:not(:disabled):not(.disabled).active:focus,
.show>.btn-info.dropdown-toggle:focus {
    box-shadow: 0 0 0 .2rem rgba(38, 195, 218, 0.5)
}

.btn-warning {
    color: #1c273c;
    background-color: #ffc107;
    border-color: #ffc107
}

.btn-warning:hover {
    color: #1c273c;
    background-color: #e0a800;
    border-color: #d39e00
}

.btn-warning:focus,
.btn-warning.focus {
    color: #1c273c;
    background-color: #e0a800;
    border-color: #d39e00;
    box-shadow: 0 0 0 .2rem rgba(221, 170, 15, 0.5)
}

.btn-warning.disabled,
.btn-warning:disabled {
    color: #1c273c;
    background-color: #ffc107;
    border-color: #ffc107
}

.btn-warning:not(:disabled):not(.disabled):active,
.btn-warning:not(:disabled):not(.disabled).active,
.show>.btn-warning.dropdown-toggle {
    color: #1c273c;
    background-color: #d39e00;
    border-color: #c69500
}

.btn-warning:not(:disabled):not(.disabled):active:focus,
.btn-warning:not(:disabled):not(.disabled).active:focus,
.show>.btn-warning.dropdown-toggle:focus {
    box-shadow: 0 0 0 .2rem rgba(221, 170, 15, 0.5)
}

.btn-danger {
    color: #fff;
    background-color: #dc3545;
    border-color: #dc3545
}

.btn-danger:hover {
    color: #fff;
    background-color: #c82333;
    border-color: #bd2130
}

.btn-danger:focus,
.btn-danger.focus {
    color: #fff;
    background-color: #c82333;
    border-color: #bd2130;
    box-shadow: 0 0 0 .2rem rgba(225, 83, 97, 0.5)
}

.btn-danger.disabled,
.btn-danger:disabled {
    color: #fff;
    background-color: #dc3545;
    border-color: #dc3545
}

.btn-danger:not(:disabled):not(.disabled):active,
.btn-danger:not(:disabled):not(.disabled).active,
.show>.btn-danger.dropdown-toggle {
    color: #fff;
    background-color: #bd2130;
    border-color: #b21f2d
}

.btn-danger:not(:disabled):not(.disabled):active:focus,
.btn-danger:not(:disabled):not(.disabled).active:focus,
.show>.btn-danger.dropdown-toggle:focus {
    box-shadow: 0 0 0 .2rem rgba(225, 83, 97, 0.5)
}

.btn-light {
    color: #1c273c;
    background-color: #f4f5f8;
    border-color: #f4f5f8
}

.btn-light:hover {
    color: #1c273c;
    background-color: #dde0e9;
    border-color: #d5d9e4
}

.btn-light:focus,
.btn-light.focus {
    color: #1c273c;
    background-color: #dde0e9;
    border-color: #d5d9e4;
    box-shadow: 0 0 0 .2rem rgba(212, 214, 220, 0.5)
}

.btn-light.disabled,
.btn-light:disabled {
    color: #1c273c;
    background-color: #f4f5f8;
    border-color: #f4f5f8
}

.btn-light:not(:disabled):not(.disabled):active,
.btn-light:not(:disabled):not(.disabled).active,
.show>.btn-light.dropdown-toggle {
    color: #1c273c;
    background-color: #d5d9e4;
    border-color: #cdd2df
}

.btn-light:not(:disabled):not(.disabled):active:focus,
.btn-light:not(:disabled):not(.disabled).active:focus,
.show>.btn-light.dropdown-toggle:focus {
    box-shadow: 0 0 0 .2rem rgba(212, 214, 220, 0.5)
}

.btn-dark {
    color: #fff;
    background-color: #3b4863;
    border-color: #3b4863
}

.btn-dark:hover {
    color: #fff;
    background-color: #2d374b;
    border-color: #283143
}

.btn-dark:focus,
.btn-dark.focus {
    color: #fff;
    background-color: #2d374b;
    border-color: #283143;
    box-shadow: 0 0 0 .2rem rgba(88, 99, 122, 0.5)
}

.btn-dark.disabled,
.btn-dark:disabled {
    color: #fff;
    background-color: #3b4863;
    border-color: #3b4863
}

.btn-dark:not(:disabled):not(.disabled):active,
.btn-dark:not(:disabled):not(.disabled).active,
.show>.btn-dark.dropdown-toggle {
    color: #fff;
    background-color: #283143;
    border-color: #232b3b
}

.btn-dark:not(:disabled):not(.disabled):active:focus,
.btn-dark:not(:disabled):not(.disabled).active:focus,
.show>.btn-dark.dropdown-toggle:focus {
    box-shadow: 0 0 0 .2rem rgba(88, 99, 122, 0.5)
}

.btn-outline-primary {
    color: #0168fa;
    border-color: #0168fa
}

.btn-outline-primary:hover {
    color: #fff;
    background-color: #0168fa;
    border-color: #0168fa
}

.btn-outline-primary:focus,
.btn-outline-primary.focus {
    box-shadow: 0 0 0 .2rem rgba(1, 104, 250, 0.5)
}

.btn-outline-primary.disabled,
.btn-outline-primary:disabled {
    color: #0168fa;
    background-color: transparent
}

.btn-outline-primary:not(:disabled):not(.disabled):active,
.btn-outline-primary:not(:disabled):not(.disabled).active,
.show>.btn-outline-primary.dropdown-toggle {
    color: #fff;
    background-color: #0168fa;
    border-color: #0168fa
}

.btn-outline-primary:not(:disabled):not(.disabled):active:focus,
.btn-outline-primary:not(:disabled):not(.disabled).active:focus,
.show>.btn-outline-primary.dropdown-toggle:focus {
    box-shadow: 0 0 0 .2rem rgba(1, 104, 250, 0.5)
}

.btn-outline-secondary {
    color: #7987a1;
    border-color: #7987a1
}

.btn-outline-secondary:hover {
    color: #fff;
    background-color: #7987a1;
    border-color: #7987a1
}

.btn-outline-secondary:focus,
.btn-outline-secondary.focus {
    box-shadow: 0 0 0 .2rem rgba(121, 135, 161, 0.5)
}

.btn-outline-secondary.disabled,
.btn-outline-secondary:disabled {
    color: #7987a1;
    background-color: transparent
}

.btn-outline-secondary:not(:disabled):not(.disabled):active,
.btn-outline-secondary:not(:disabled):not(.disabled).active,
.show>.btn-outline-secondary.dropdown-toggle {
    color: #fff;
    background-color: #7987a1;
    border-color: #7987a1
}

.btn-outline-secondary:not(:disabled):not(.disabled):active:focus,
.btn-outline-secondary:not(:disabled):not(.disabled).active:focus,
.show>.btn-outline-secondary.dropdown-toggle:focus {
    box-shadow: 0 0 0 .2rem rgba(121, 135, 161, 0.5)
}

.btn-outline-success {
    color: #10b759;
    border-color: #10b759
}

.btn-outline-success:hover {
    color: #fff;
    background-color: #10b759;
    border-color: #10b759
}

.btn-outline-success:focus,
.btn-outline-success.focus {
    box-shadow: 0 0 0 .2rem rgba(16, 183, 89, 0.5)
}

.btn-outline-success.disabled,
.btn-outline-success:disabled {
    color: #10b759;
    background-color: transparent
}

.btn-outline-success:not(:disabled):not(.disabled):active,
.btn-outline-success:not(:disabled):not(.disabled).active,
.show>.btn-outline-success.dropdown-toggle {
    color: #fff;
    background-color: #10b759;
    border-color: #10b759
}

.btn-outline-success:not(:disabled):not(.disabled):active:focus,
.btn-outline-success:not(:disabled):not(.disabled).active:focus,
.show>.btn-outline-success.dropdown-toggle:focus {
    box-shadow: 0 0 0 .2rem rgba(16, 183, 89, 0.5)
}

.btn-outline-info {
    color: #00b8d4;
    border-color: #00b8d4
}

.btn-outline-info:hover {
    color: #fff;
    background-color: #00b8d4;
    border-color: #00b8d4
}

.btn-outline-info:focus,
.btn-outline-info.focus {
    box-shadow: 0 0 0 .2rem rgba(0, 184, 212, 0.5)
}

.btn-outline-info.disabled,
.btn-outline-info:disabled {
    color: #00b8d4;
    background-color: transparent
}

.btn-outline-info:not(:disabled):not(.disabled):active,
.btn-outline-info:not(:disabled):not(.disabled).active,
.show>.btn-outline-info.dropdown-toggle {
    color: #fff;
    background-color: #00b8d4;
    border-color: #00b8d4
}

.btn-outline-info:not(:disabled):not(.disabled):active:focus,
.btn-outline-info:not(:disabled):not(.disabled).active:focus,
.show>.btn-outline-info.dropdown-toggle:focus {
    box-shadow: 0 0 0 .2rem rgba(0, 184, 212, 0.5)
}

.btn-outline-warning {
    color: #ffc107;
    border-color: #ffc107
}

.btn-outline-warning:hover {
    color: #1c273c;
    background-color: #ffc107;
    border-color: #ffc107
}

.btn-outline-warning:focus,
.btn-outline-warning.focus {
    box-shadow: 0 0 0 .2rem rgba(255, 193, 7, 0.5)
}

.btn-outline-warning.disabled,
.btn-outline-warning:disabled {
    color: #ffc107;
    background-color: transparent
}

.btn-outline-warning:not(:disabled):not(.disabled):active,
.btn-outline-warning:not(:disabled):not(.disabled).active,
.show>.btn-outline-warning.dropdown-toggle {
    color: #1c273c;
    background-color: #ffc107;
    border-color: #ffc107
}

.btn-outline-warning:not(:disabled):not(.disabled):active:focus,
.btn-outline-warning:not(:disabled):not(.disabled).active:focus,
.show>.btn-outline-warning.dropdown-toggle:focus {
    box-shadow: 0 0 0 .2rem rgba(255, 193, 7, 0.5)
}

.btn-outline-danger {
    color: #dc3545;
    border-color: #dc3545
}

.btn-outline-danger:hover {
    color: #fff;
    background-color: #dc3545;
    border-color: #dc3545
}

.btn-outline-danger:focus,
.btn-outline-danger.focus {
    box-shadow: 0 0 0 .2rem rgba(220, 53, 69, 0.5)
}

.btn-outline-danger.disabled,
.btn-outline-danger:disabled {
    color: #dc3545;
    background-color: transparent
}

.btn-outline-danger:not(:disabled):not(.disabled):active,
.btn-outline-danger:not(:disabled):not(.disabled).active,
.show>.btn-outline-danger.dropdown-toggle {
    color: #fff;
    background-color: #dc3545;
    border-color: #dc3545
}

.btn-outline-danger:not(:disabled):not(.disabled):active:focus,
.btn-outline-danger:not(:disabled):not(.disabled).active:focus,
.show>.btn-outline-danger.dropdown-toggle:focus {
    box-shadow: 0 0 0 .2rem rgba(220, 53, 69, 0.5)
}

.btn-outline-light {
    color: #f4f5f8;
    border-color: #f4f5f8
}

.btn-outline-light:hover {
    color: #1c273c;
    background-color: #f4f5f8;
    border-color: #f4f5f8
}

.btn-outline-light:focus,
.btn-outline-light.focus {
    box-shadow: 0 0 0 .2rem rgba(244, 245, 248, 0.5)
}

.btn-outline-light.disabled,
.btn-outline-light:disabled {
    color: #f4f5f8;
    background-color: transparent
}

.btn-outline-light:not(:disabled):not(.disabled):active,
.btn-outline-light:not(:disabled):not(.disabled).active,
.show>.btn-outline-light.dropdown-toggle {
    color: #1c273c;
    background-color: #f4f5f8;
    border-color: #f4f5f8
}

.btn-outline-light:not(:disabled):not(.disabled):active:focus,
.btn-outline-light:not(:disabled):not(.disabled).active:focus,
.show>.btn-outline-light.dropdown-toggle:focus {
    box-shadow: 0 0 0 .2rem rgba(244, 245, 248, 0.5)
}

.btn-outline-dark {
    color: #3b4863;
    border-color: #3b4863
}

.btn-outline-dark:hover {
    color: #fff;
    background-color: #3b4863;
    border-color: #3b4863
}

.btn-outline-dark:focus,
.btn-outline-dark.focus {
    box-shadow: 0 0 0 .2rem rgba(59, 72, 99, 0.5)
}

.btn-outline-dark.disabled,
.btn-outline-dark:disabled {
    color: #3b4863;
    background-color: transparent
}

.btn-outline-dark:not(:disabled):not(.disabled):active,
.btn-outline-dark:not(:disabled):not(.disabled).active,
.show>.btn-outline-dark.dropdown-toggle {
    color: #fff;
    background-color: #3b4863;
    border-color: #3b4863
}

.btn-outline-dark:not(:disabled):not(.disabled):active:focus,
.btn-outline-dark:not(:disabled):not(.disabled).active:focus,
.show>.btn-outline-dark.dropdown-toggle:focus {
    box-shadow: 0 0 0 .2rem rgba(59, 72, 99, 0.5)
}

.btn-link {
    font-weight: 400;
    color: #0168fa;
    text-decoration: none
}

.btn-link:hover {
    color: #0148ae;
    text-decoration: none
}

.btn-link:focus,
.btn-link.focus {
    text-decoration: none;
    box-shadow: none
}

.btn-link:disabled,
.btn-link.disabled {
    color: #7987a1;
    pointer-events: none
}

.btn-lg,
.btn-group-lg>.btn,
.ui-datepicker-buttonpane .btn-group-lg>button,
.sp-container .btn-group-lg>button {
    padding: .6rem 1rem;
    font-size: 1.09375rem;
    line-height: 1.5;
    border-radius: .3rem
}

.btn-sm,
.btn-group-sm>.btn,
.ui-datepicker-buttonpane .btn-group-sm>button,
.sp-container .btn-group-sm>button,
.ui-datepicker-buttonpane button {
    padding: .391rem .5rem;
    font-size: .8125rem;
    line-height: 1.5;
    border-radius: .2rem
}

.btn-block {
    display: block;
    width: 100%
}

.btn-block+.btn-block {
    margin-top: .5rem
}

input[type="submit"].btn-block,
input[type="reset"].btn-block,
input[type="button"].btn-block {
    width: 100%
}

.fade {
    transition: opacity 0.15s linear
}

@media (prefers-reduced-motion: reduce) {
    .fade {
        transition: none
    }
}

.fade:not(.show) {
    opacity: 0
}

.collapse:not(.show) {
    display: none
}

.collapsing {
    position: relative;
    height: 0;
    overflow: hidden;
    transition: height 0.35s ease
}

@media (prefers-reduced-motion: reduce) {
    .collapsing {
        transition: none
    }
}

.dropup,
.dropright,
.dropdown,
.dropleft {
    position: relative
}

.dropdown-toggle {
    white-space: nowrap
}

.dropdown-toggle::after {
    display: inline-block;
    margin-left: .255em;
    vertical-align: .255em;
    content: "";
    border-top: .3em solid;
    border-right: .3em solid transparent;
    border-bottom: 0;
    border-left: .3em solid transparent
}

.dropdown-toggle:empty::after {
    margin-left: 0
}

.dropdown-menu {
    position: absolute;
    top: 100%;
    left: 0;
    z-index: 1000;
    display: none;
    float: left;
    min-width: 10rem;
    padding: .5rem 0;
    margin: .125rem 0 0;
    font-size: .875rem;
    color: #001737;
    text-align: left;
    list-style: none;
    background-color: #fff;
    background-clip: padding-box;
    border: 1px solid rgba(0, 0, 0, 0.15);
    border-radius: .25rem
}

.dropdown-menu-left {
    right: auto;
    left: 0
}

.dropdown-menu-right {
    right: 0;
    left: auto
}

@media (min-width: 576px) {
    .dropdown-menu-sm-left {
        right: auto;
        left: 0
    }

    .dropdown-menu-sm-right {
        right: 0;
        left: auto
    }
}

@media (min-width: 768px) {
    .dropdown-menu-md-left {
        right: auto;
        left: 0
    }

    .dropdown-menu-md-right {
        right: 0;
        left: auto
    }
}

@media (min-width: 992px) {
    .dropdown-menu-lg-left {
        right: auto;
        left: 0
    }

    .dropdown-menu-lg-right {
        right: 0;
        left: auto
    }
}

@media (min-width: 1200px) {
    .dropdown-menu-xl-left {
        right: auto;
        left: 0
    }

    .dropdown-menu-xl-right {
        right: 0;
        left: auto
    }
}

.dropup .dropdown-menu {
    top: auto;
    bottom: 100%;
    margin-top: 0;
    margin-bottom: .125rem
}

.dropup .dropdown-toggle::after {
    display: inline-block;
    margin-left: .255em;
    vertical-align: .255em;
    content: "";
    border-top: 0;
    border-right: .3em solid transparent;
    border-bottom: .3em solid;
    border-left: .3em solid transparent
}

.dropup .dropdown-toggle:empty::after {
    margin-left: 0
}

.dropright .dropdown-menu {
    top: 0;
    right: auto;
    left: 100%;
    margin-top: 0;
    margin-left: .125rem
}

.dropright .dropdown-toggle::after {
    display: inline-block;
    margin-left: .255em;
    vertical-align: .255em;
    content: "";
    border-top: .3em solid transparent;
    border-right: 0;
    border-bottom: .3em solid transparent;
    border-left: .3em solid
}

.dropright .dropdown-toggle:empty::after {
    margin-left: 0
}

.dropright .dropdown-toggle::after {
    vertical-align: 0
}

.dropleft .dropdown-menu {
    top: 0;
    right: 100%;
    left: auto;
    margin-top: 0;
    margin-right: .125rem
}

.dropleft .dropdown-toggle::after {
    display: inline-block;
    margin-left: .255em;
    vertical-align: .255em;
    content: ""
}

.dropleft .dropdown-toggle::after {
    display: none
}

.dropleft .dropdown-toggle::before {
    display: inline-block;
    margin-right: .255em;
    vertical-align: .255em;
    content: "";
    border-top: .3em solid transparent;
    border-right: .3em solid;
    border-bottom: .3em solid transparent
}

.dropleft .dropdown-toggle:empty::after {
    margin-left: 0
}

.dropleft .dropdown-toggle::before {
    vertical-align: 0
}

.dropdown-menu[x-placement^="top"],
.dropdown-menu[x-placement^="right"],
.dropdown-menu[x-placement^="bottom"],
.dropdown-menu[x-placement^="left"] {
    right: auto;
    bottom: auto
}

.dropdown-divider {
    height: 0;
    margin: .5rem 0;
    overflow: hidden;
    border-top: 1px solid #e3e7ed
}

.dropdown-item {
    display: block;
    width: 100%;
    padding: .25rem 1.5rem;
    clear: both;
    font-weight: 400;
    color: #1c273c;
    text-align: inherit;
    white-space: nowrap;
    background-color: transparent;
    border: 0
}

.dropdown-item:hover,
.dropdown-item:focus {
    color: #141c2b;
    text-decoration: none;
    background-color: #f4f5f8
}

.dropdown-item.active,
.dropdown-item:active {
    color: #fff;
    text-decoration: none;
    background-color: #0168fa
}

.dropdown-item.disabled,
.dropdown-item:disabled {
    color: #7987a1;
    pointer-events: none;
    background-color: transparent
}

.dropdown-menu.show {
    display: block
}

.dropdown-header {
    display: block;
    padding: .5rem 1.5rem;
    margin-bottom: 0;
    font-size: .76562rem;
    color: #7987a1;
    white-space: nowrap
}

.dropdown-item-text {
    display: block;
    padding: .25rem 1.5rem;
    color: #1c273c
}

.btn-group,
.btn-group-vertical {
    position: relative;
    display: inline-flex;
    vertical-align: middle
}

.btn-group>.btn,
.ui-datepicker-buttonpane .btn-group>button,
.sp-container .btn-group>button,
.btn-group-vertical>.btn,
.ui-datepicker-buttonpane .btn-group-vertical>button,
.sp-container .btn-group-vertical>button {
    position: relative;
    flex: 1 1 auto
}

.btn-group>.btn:hover,
.ui-datepicker-buttonpane .btn-group>button:hover,
.sp-container .btn-group>button:hover,
.btn-group-vertical>.btn:hover,
.ui-datepicker-buttonpane .btn-group-vertical>button:hover,
.sp-container .btn-group-vertical>button:hover {
    z-index: 1
}

.btn-group>.btn:focus,
.ui-datepicker-buttonpane .btn-group>button:focus,
.sp-container .btn-group>button:focus,
.btn-group>.btn:active,
.ui-datepicker-buttonpane .btn-group>button:active,
.sp-container .btn-group>button:active,
.btn-group>.btn.active,
.ui-datepicker-buttonpane .btn-group>button.active,
.sp-container .btn-group>button.active,
.btn-group-vertical>.btn:focus,
.ui-datepicker-buttonpane .btn-group-vertical>button:focus,
.sp-container .btn-group-vertical>button:focus,
.btn-group-vertical>.btn:active,
.ui-datepicker-buttonpane .btn-group-vertical>button:active,
.sp-container .btn-group-vertical>button:active,
.btn-group-vertical>.btn.active,
.ui-datepicker-buttonpane .btn-group-vertical>button.active,
.sp-container .btn-group-vertical>button.active {
    z-index: 1
}

.btn-toolbar {
    display: flex;
    flex-wrap: wrap;
    justify-content: flex-start
}

.btn-toolbar .input-group {
    width: auto
}

.btn-group>.btn:not(:first-child),
.ui-datepicker-buttonpane .btn-group>button:not(:first-child),
.sp-container .btn-group>button:not(:first-child),
.btn-group>.btn-group:not(:first-child) {
    margin-left: -1px
}

.btn-group>.btn:not(:last-child):not(.dropdown-toggle),
.ui-datepicker-buttonpane .btn-group>button:not(:last-child):not(.dropdown-toggle),
.sp-container .btn-group>button:not(:last-child):not(.dropdown-toggle),
.btn-group>.btn-group:not(:last-child)>.btn,
.ui-datepicker-buttonpane .btn-group>.btn-group:not(:last-child)>button,
.sp-container .btn-group>.btn-group:not(:last-child)>button {
    border-top-right-radius: 0;
    border-bottom-right-radius: 0
}

.btn-group>.btn:not(:first-child),
.ui-datepicker-buttonpane .btn-group>button:not(:first-child),
.sp-container .btn-group>button:not(:first-child),
.btn-group>.btn-group:not(:first-child)>.btn,
.ui-datepicker-buttonpane .btn-group>.btn-group:not(:first-child)>button,
.sp-container .btn-group>.btn-group:not(:first-child)>button {
    border-top-left-radius: 0;
    border-bottom-left-radius: 0
}

.dropdown-toggle-split {
    padding-right: .70312rem;
    padding-left: .70312rem
}

.dropdown-toggle-split::after,
.dropup .dropdown-toggle-split::after,
.dropright .dropdown-toggle-split::after {
    margin-left: 0
}

.dropleft .dropdown-toggle-split::before {
    margin-right: 0
}

.btn-sm+.dropdown-toggle-split,
.btn-group-sm>.btn+.dropdown-toggle-split,
.ui-datepicker-buttonpane .btn-group-sm>button+.dropdown-toggle-split,
.sp-container .btn-group-sm>button+.dropdown-toggle-split,
.ui-datepicker-buttonpane button+.dropdown-toggle-split {
    padding-right: .375rem;
    padding-left: .375rem
}

.btn-lg+.dropdown-toggle-split,
.btn-group-lg>.btn+.dropdown-toggle-split,
.ui-datepicker-buttonpane .btn-group-lg>button+.dropdown-toggle-split,
.sp-container .btn-group-lg>button+.dropdown-toggle-split {
    padding-right: .75rem;
    padding-left: .75rem
}

.btn-group-vertical {
    flex-direction: column;
    align-items: flex-start;
    justify-content: center
}

.btn-group-vertical>.btn,
.ui-datepicker-buttonpane .btn-group-vertical>button,
.sp-container .btn-group-vertical>button,
.btn-group-vertical>.btn-group {
    width: 100%
}

.btn-group-vertical>.btn:not(:first-child),
.ui-datepicker-buttonpane .btn-group-vertical>button:not(:first-child),
.sp-container .btn-group-vertical>button:not(:first-child),
.btn-group-vertical>.btn-group:not(:first-child) {
    margin-top: -1px
}

.btn-group-vertical>.btn:not(:last-child):not(.dropdown-toggle),
.ui-datepicker-buttonpane .btn-group-vertical>button:not(:last-child):not(.dropdown-toggle),
.sp-container .btn-group-vertical>button:not(:last-child):not(.dropdown-toggle),
.btn-group-vertical>.btn-group:not(:last-child)>.btn,
.ui-datepicker-buttonpane .btn-group-vertical>.btn-group:not(:last-child)>button,
.sp-container .btn-group-vertical>.btn-group:not(:last-child)>button {
    border-bottom-right-radius: 0;
    border-bottom-left-radius: 0
}

.btn-group-vertical>.btn:not(:first-child),
.ui-datepicker-buttonpane .btn-group-vertical>button:not(:first-child),
.sp-container .btn-group-vertical>button:not(:first-child),
.btn-group-vertical>.btn-group:not(:first-child)>.btn,
.ui-datepicker-buttonpane .btn-group-vertical>.btn-group:not(:first-child)>button,
.sp-container .btn-group-vertical>.btn-group:not(:first-child)>button {
    border-top-left-radius: 0;
    border-top-right-radius: 0
}

.btn-group-toggle>.btn,
.ui-datepicker-buttonpane .btn-group-toggle>button,
.sp-container .btn-group-toggle>button,
.btn-group-toggle>.btn-group>.btn,
.ui-datepicker-buttonpane .btn-group-toggle>.btn-group>button,
.sp-container .btn-group-toggle>.btn-group>button {
    margin-bottom: 0
}

.btn-group-toggle>.btn input[type="radio"],
.ui-datepicker-buttonpane .btn-group-toggle>button input[type="radio"],
.sp-container .btn-group-toggle>button input[type="radio"],
.btn-group-toggle>.btn input[type="checkbox"],
.ui-datepicker-buttonpane .btn-group-toggle>button input[type="checkbox"],
.sp-container .btn-group-toggle>button input[type="checkbox"],
.btn-group-toggle>.btn-group>.btn input[type="radio"],
.ui-datepicker-buttonpane .btn-group-toggle>.btn-group>button input[type="radio"],
.sp-container .btn-group-toggle>.btn-group>button input[type="radio"],
.btn-group-toggle>.btn-group>.btn input[type="checkbox"],
.ui-datepicker-buttonpane .btn-group-toggle>.btn-group>button input[type="checkbox"],
.sp-container .btn-group-toggle>.btn-group>button input[type="checkbox"] {
    position: absolute;
    clip: rect(0, 0, 0, 0);
    pointer-events: none
}

.input-group {
    position: relative;
    display: flex;
    flex-wrap: wrap;
    align-items: stretch;
    width: 100%
}

.input-group>.form-control,
.input-group>.form-control-plaintext,
.input-group>.custom-select,
.input-group>.custom-file {
    position: relative;
    flex: 1 1 0%;
    min-width: 0;
    margin-bottom: 0
}

.input-group>.form-control+.form-control,
.input-group>.form-control+.custom-select,
.input-group>.form-control+.custom-file,
.input-group>.form-control-plaintext+.form-control,
.input-group>.form-control-plaintext+.custom-select,
.input-group>.form-control-plaintext+.custom-file,
.input-group>.custom-select+.form-control,
.input-group>.custom-select+.custom-select,
.input-group>.custom-select+.custom-file,
.input-group>.custom-file+.form-control,
.input-group>.custom-file+.custom-select,
.input-group>.custom-file+.custom-file {
    margin-left: -1px
}

.input-group>.form-control:focus,
.input-group>.custom-select:focus,
.input-group>.custom-file .custom-file-input:focus~.custom-file-label {
    z-index: 3
}

.input-group>.custom-file .custom-file-input:focus {
    z-index: 4
}

.input-group>.form-control:not(:last-child),
.input-group>.custom-select:not(:last-child) {
    border-top-right-radius: 0;
    border-bottom-right-radius: 0
}

.input-group>.form-control:not(:first-child),
.input-group>.custom-select:not(:first-child) {
    border-top-left-radius: 0;
    border-bottom-left-radius: 0
}

.input-group>.custom-file {
    display: flex;
    align-items: center
}

.input-group>.custom-file:not(:last-child) .custom-file-label,
.input-group>.custom-file:not(:last-child) .custom-file-label::after {
    border-top-right-radius: 0;
    border-bottom-right-radius: 0
}

.input-group>.custom-file:not(:first-child) .custom-file-label {
    border-top-left-radius: 0;
    border-bottom-left-radius: 0
}

.input-group-prepend,
.input-group-append {
    display: flex
}

.input-group-prepend .btn,
.input-group-prepend .ui-datepicker-buttonpane button,
.ui-datepicker-buttonpane .input-group-prepend button,
.input-group-prepend .sp-container button,
.sp-container .input-group-prepend button,
.input-group-append .btn,
.input-group-append .ui-datepicker-buttonpane button,
.ui-datepicker-buttonpane .input-group-append button,
.input-group-append .sp-container button,
.sp-container .input-group-append button {
    position: relative;
    z-index: 2
}

.input-group-prepend .btn:focus,
.input-group-prepend .ui-datepicker-buttonpane button:focus,
.ui-datepicker-buttonpane .input-group-prepend button:focus,
.input-group-prepend .sp-container button:focus,
.sp-container .input-group-prepend button:focus,
.input-group-append .btn:focus,
.input-group-append .ui-datepicker-buttonpane button:focus,
.ui-datepicker-buttonpane .input-group-append button:focus,
.input-group-append .sp-container button:focus,
.sp-container .input-group-append button:focus {
    z-index: 3
}

.input-group-prepend .btn+.btn,
.input-group-prepend .ui-datepicker-buttonpane button+.btn,
.ui-datepicker-buttonpane .input-group-prepend button+.btn,
.input-group-prepend .sp-container button+.btn,
.sp-container .input-group-prepend button+.btn,
.input-group-prepend .ui-datepicker-buttonpane .btn+button,
.ui-datepicker-buttonpane .input-group-prepend .btn+button,
.input-group-prepend .ui-datepicker-buttonpane button+button,
.ui-datepicker-buttonpane .input-group-prepend button+button,
.input-group-prepend .sp-container .ui-datepicker-buttonpane button+button,
.ui-datepicker-buttonpane .sp-container .input-group-prepend button+button,
.input-group-prepend .sp-container .btn+button,
.sp-container .input-group-prepend .btn+button,
.input-group-prepend .ui-datepicker-buttonpane .sp-container button+button,
.sp-container .input-group-prepend .ui-datepicker-buttonpane button+button,
.ui-datepicker-buttonpane .input-group-prepend .sp-container button+button,
.sp-container .ui-datepicker-buttonpane .input-group-prepend button+button,
.input-group-prepend .sp-container button+button,
.sp-container .input-group-prepend button+button,
.input-group-prepend .btn+.input-group-text,
.input-group-prepend .ui-datepicker-buttonpane button+.input-group-text,
.ui-datepicker-buttonpane .input-group-prepend button+.input-group-text,
.input-group-prepend .sp-container button+.input-group-text,
.sp-container .input-group-prepend button+.input-group-text,
.input-group-prepend .input-group-text+.input-group-text,
.input-group-prepend .input-group-text+.btn,
.input-group-prepend .ui-datepicker-buttonpane .input-group-text+button,
.ui-datepicker-buttonpane .input-group-prepend .input-group-text+button,
.input-group-prepend .sp-container .input-group-text+button,
.sp-container .input-group-prepend .input-group-text+button,
.input-group-append .btn+.btn,
.input-group-append .ui-datepicker-buttonpane button+.btn,
.ui-datepicker-buttonpane .input-group-append button+.btn,
.input-group-append .sp-container button+.btn,
.sp-container .input-group-append button+.btn,
.input-group-append .ui-datepicker-buttonpane .btn+button,
.ui-datepicker-buttonpane .input-group-append .btn+button,
.input-group-append .ui-datepicker-buttonpane button+button,
.ui-datepicker-buttonpane .input-group-append button+button,
.input-group-append .sp-container .ui-datepicker-buttonpane button+button,
.ui-datepicker-buttonpane .sp-container .input-group-append button+button,
.input-group-append .sp-container .btn+button,
.sp-container .input-group-append .btn+button,
.input-group-append .ui-datepicker-buttonpane .sp-container button+button,
.sp-container .input-group-append .ui-datepicker-buttonpane button+button,
.ui-datepicker-buttonpane .input-group-append .sp-container button+button,
.sp-container .ui-datepicker-buttonpane .input-group-append button+button,
.input-group-append .sp-container button+button,
.sp-container .input-group-append button+button,
.input-group-append .btn+.input-group-text,
.input-group-append .ui-datepicker-buttonpane button+.input-group-text,
.ui-datepicker-buttonpane .input-group-append button+.input-group-text,
.input-group-append .sp-container button+.input-group-text,
.sp-container .input-group-append button+.input-group-text,
.input-group-append .input-group-text+.input-group-text,
.input-group-append .input-group-text+.btn,
.input-group-append .ui-datepicker-buttonpane .input-group-text+button,
.ui-datepicker-buttonpane .input-group-append .input-group-text+button,
.input-group-append .sp-container .input-group-text+button,
.sp-container .input-group-append .input-group-text+button {
    margin-left: -1px
}

.input-group-prepend {
    margin-right: -1px
}

.input-group-append {
    margin-left: -1px
}

.input-group-text {
    display: flex;
    align-items: center;
    padding: .46875rem .625rem;
    margin-bottom: 0;
    font-size: .875rem;
    font-weight: 400;
    line-height: 1.5;
    color: #596882;
    text-align: center;
    white-space: nowrap;
    background-color: #f5f6fa;
    border: 1px solid #c0ccda;
    border-radius: .25rem
}

.input-group-text input[type="radio"],
.input-group-text input[type="checkbox"] {
    margin-top: 0
}

.input-group-lg>.form-control:not(textarea),
.input-group-lg>.custom-select {
    height: calc(1.5em + 1.2rem + 2px)
}

.input-group-lg>.form-control,
.input-group-lg>.custom-select,
.input-group-lg>.input-group-prepend>.input-group-text,
.input-group-lg>.input-group-append>.input-group-text,
.input-group-lg>.input-group-prepend>.btn,
.ui-datepicker-buttonpane .input-group-lg>.input-group-prepend>button,
.sp-container .input-group-lg>.input-group-prepend>button,
.input-group-lg>.input-group-append>.btn,
.ui-datepicker-buttonpane .input-group-lg>.input-group-append>button,
.sp-container .input-group-lg>.input-group-append>button {
    padding: .6rem 1rem;
    font-size: 1.09375rem;
    line-height: 1.5;
    border-radius: .3rem
}

.input-group-sm>.form-control:not(textarea),
.input-group-sm>.custom-select {
    height: calc(1.5em + .782rem + 2px)
}

.input-group-sm>.form-control,
.input-group-sm>.custom-select,
.input-group-sm>.input-group-prepend>.input-group-text,
.input-group-sm>.input-group-append>.input-group-text,
.input-group-sm>.input-group-prepend>.btn,
.ui-datepicker-buttonpane .input-group-sm>.input-group-prepend>button,
.sp-container .input-group-sm>.input-group-prepend>button,
.input-group-sm>.input-group-append>.btn,
.ui-datepicker-buttonpane .input-group-sm>.input-group-append>button,
.sp-container .input-group-sm>.input-group-append>button {
    padding: .391rem .5rem;
    font-size: .8125rem;
    line-height: 1.5;
    border-radius: .2rem
}

.input-group-lg>.custom-select,
.input-group-sm>.custom-select {
    padding-right: 1.625rem
}

.input-group>.input-group-prepend>.btn,
.ui-datepicker-buttonpane .input-group>.input-group-prepend>button,
.sp-container .input-group>.input-group-prepend>button,
.input-group>.input-group-prepend>.input-group-text,
.input-group>.input-group-append:not(:last-child)>.btn,
.ui-datepicker-buttonpane .input-group>.input-group-append:not(:last-child)>button,
.sp-container .input-group>.input-group-append:not(:last-child)>button,
.input-group>.input-group-append:not(:last-child)>.input-group-text,
.input-group>.input-group-append:last-child>.btn:not(:last-child):not(.dropdown-toggle),
.ui-datepicker-buttonpane .input-group>.input-group-append:last-child>button:not(:last-child):not(.dropdown-toggle),
.sp-container .input-group>.input-group-append:last-child>button:not(:last-child):not(.dropdown-toggle),
.input-group>.input-group-append:last-child>.input-group-text:not(:last-child) {
    border-top-right-radius: 0;
    border-bottom-right-radius: 0
}

.input-group>.input-group-append>.btn,
.ui-datepicker-buttonpane .input-group>.input-group-append>button,
.sp-container .input-group>.input-group-append>button,
.input-group>.input-group-append>.input-group-text,
.input-group>.input-group-prepend:not(:first-child)>.btn,
.ui-datepicker-buttonpane .input-group>.input-group-prepend:not(:first-child)>button,
.sp-container .input-group>.input-group-prepend:not(:first-child)>button,
.input-group>.input-group-prepend:not(:first-child)>.input-group-text,
.input-group>.input-group-prepend:first-child>.btn:not(:first-child),
.ui-datepicker-buttonpane .input-group>.input-group-prepend:first-child>button:not(:first-child),
.sp-container .input-group>.input-group-prepend:first-child>button:not(:first-child),
.input-group>.input-group-prepend:first-child>.input-group-text:not(:first-child) {
    border-top-left-radius: 0;
    border-bottom-left-radius: 0
}

.custom-control {
    position: relative;
    display: block;
    min-height: 1.3125rem;
    padding-left: 1.5rem
}

.custom-control-inline {
    display: inline-flex;
    margin-right: 1rem
}

.custom-control-input {
    position: absolute;
    left: 0;
    z-index: -1;
    width: 1rem;
    height: 1.15625rem;
    opacity: 0
}

.custom-control-input:checked~.custom-control-label::before {
    color: #fff;
    border-color: #0168fa;
    background-color: #0168fa
}

.custom-control-input:focus~.custom-control-label::before {
    box-shadow: 0 0 0 .2rem rgba(1, 104, 250, 0.25)
}

.custom-control-input:focus:not(:checked)~.custom-control-label::before {
    border-color: #7cb2fe
}

.custom-control-input:not(:disabled):active~.custom-control-label::before {
    color: #fff;
    background-color: #afd0ff;
    border-color: #afd0ff
}

.custom-control-input[disabled]~.custom-control-label,
.custom-control-input:disabled~.custom-control-label {
    color: #7987a1
}

.custom-control-input[disabled]~.custom-control-label::before,
.custom-control-input:disabled~.custom-control-label::before {
    background-color: #e3e7ed
}

.custom-control-label {
    position: relative;
    margin-bottom: 0;
    vertical-align: top
}

.custom-control-label::before {
    position: absolute;
    top: .15625rem;
    left: -1.5rem;
    display: block;
    width: 1rem;
    height: 1rem;
    pointer-events: none;
    content: "";
    background-color: #fff;
    border: #97a3b9 solid 1px
}

.custom-control-label::after {
    position: absolute;
    top: .15625rem;
    left: -1.5rem;
    display: block;
    width: 1rem;
    height: 1rem;
    content: "";
    background: no-repeat 50% / 50% 50%
}

.custom-checkbox .custom-control-label::before {
    border-radius: .25rem
}

.custom-checkbox .custom-control-input:checked~.custom-control-label::after {
    background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' width='8' height='8' viewBox='0 0 8 8'%3e%3cpath fill='%23fff' d='M6.564.75l-3.59 3.612-1.538-1.55L0 4.26l2.974 2.99L8 2.193z'/%3e%3c/svg%3e")
}

.custom-checkbox .custom-control-input:indeterminate~.custom-control-label::before {
    border-color: #0168fa;
    background-color: #0168fa
}

.custom-checkbox .custom-control-input:indeterminate~.custom-control-label::after {
    background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' width='4' height='4' viewBox='0 0 4 4'%3e%3cpath stroke='%23fff' d='M0 2h4'/%3e%3c/svg%3e")
}

.custom-checkbox .custom-control-input:disabled:checked~.custom-control-label::before {
    background-color: rgba(1, 104, 250, 0.5)
}

.custom-checkbox .custom-control-input:disabled:indeterminate~.custom-control-label::before {
    background-color: rgba(1, 104, 250, 0.5)
}

.custom-radio .custom-control-label::before {
    border-radius: 50%
}

.custom-radio .custom-control-input:checked~.custom-control-label::after {
    background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' width='12' height='12' viewBox='-4 -4 8 8'%3e%3ccircle r='3' fill='%23fff'/%3e%3c/svg%3e")
}

.custom-radio .custom-control-input:disabled:checked~.custom-control-label::before {
    background-color: rgba(1, 104, 250, 0.5)
}

.custom-switch {
    padding-left: 2.25rem
}

.custom-switch .custom-control-label::before {
    left: -2.25rem;
    width: 1.75rem;
    pointer-events: all;
    border-radius: .5rem
}

.custom-switch .custom-control-label::after {
    top: calc(.15625rem + 2px);
    left: calc(-2.25rem + 2px);
    width: calc(1rem - 4px);
    height: calc(1rem - 4px);
    background-color: #97a3b9;
    border-radius: .5rem;
    transition: transform 0.15s ease-in-out, background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out
}

@media (prefers-reduced-motion: reduce) {
    .custom-switch .custom-control-label::after {
        transition: none
    }
}

.custom-switch .custom-control-input:checked~.custom-control-label::after {
    background-color: #fff;
    transform: translateX(.75rem)
}

.custom-switch .custom-control-input:disabled:checked~.custom-control-label::before {
    background-color: rgba(1, 104, 250, 0.5)
}

.custom-select {
    display: inline-block;
    width: 100%;
    height: calc(1.5em + .9375rem + 2px);
    padding: .46875rem 1.625rem .46875rem .625rem;
    font-size: .875rem;
    font-weight: 400;
    line-height: 1.5;
    color: #596882;
    vertical-align: middle;
    background: #fff url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' width='4' height='5' viewBox='0 0 4 5'%3e%3cpath fill='%233b4863' d='M2 0L0 2h4zm0 5L0 3h4z'/%3e%3c/svg%3e") no-repeat right .625rem center/8px 10px;
    border: 1px solid #c0ccda;
    border-radius: .25rem;
    appearance: none
}

.custom-select:focus {
    border-color: #7cb2fe;
    outline: 0;
    box-shadow: 0 0 0 .2rem rgba(1, 104, 250, 0.25)
}

.custom-select:focus::-ms-value {
    color: #596882;
    background-color: #fff
}

.custom-select[multiple],
.custom-select[size]:not([size="1"]) {
    height: auto;
    padding-right: .625rem;
    background-image: none
}

.custom-select:disabled {
    color: #7987a1;
    background-color: #e3e7ed
}

.custom-select::-ms-expand {
    display: none
}

.custom-select:-moz-focusring {
    color: transparent;
    text-shadow: 0 0 0 #596882
}

.custom-select-sm {
    height: calc(1.5em + .782rem + 2px);
    padding-top: .391rem;
    padding-bottom: .391rem;
    padding-left: .5rem;
    font-size: .8125rem
}

.custom-select-lg {
    height: calc(1.5em + 1.2rem + 2px);
    padding-top: .6rem;
    padding-bottom: .6rem;
    padding-left: 1rem;
    font-size: 1.09375rem
}

.custom-file {
    position: relative;
    display: inline-block;
    width: 100%;
    height: calc(1.5em + .9375rem + 2px);
    margin-bottom: 0
}

.custom-file-input {
    position: relative;
    z-index: 2;
    width: 100%;
    height: calc(1.5em + .9375rem + 2px);
    margin: 0;
    opacity: 0
}

.custom-file-input:focus~.custom-file-label {
    border-color: #7cb2fe;
    box-shadow: 0 0 0 .2rem rgba(1, 104, 250, 0.25)
}

.custom-file-input[disabled]~.custom-file-label,
.custom-file-input:disabled~.custom-file-label {
    background-color: #e3e7ed
}

.custom-file-input:lang(en)~.custom-file-label::after {
    content: "Browse"
}

.custom-file-input~.custom-file-label[data-browse]::after {
    content: attr(data-browse)
}

.custom-file-label {
    position: absolute;
    top: 0;
    right: 0;
    left: 0;
    z-index: 1;
    height: calc(1.5em + .9375rem + 2px);
    padding: .46875rem .625rem;
    font-weight: 400;
    line-height: 1.5;
    color: #596882;
    background-color: #fff;
    border: 1px solid #c0ccda;
    border-radius: .25rem
}

.custom-file-label::after {
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    z-index: 3;
    display: block;
    height: calc(1.5em + .9375rem);
    padding: .46875rem .625rem;
    line-height: 1.5;
    color: #596882;
    content: "Browse";
    background-color: #f5f6fa;
    border-left: inherit;
    border-radius: 0 .25rem .25rem 0
}

.custom-range {
    width: 100%;
    height: 1.4rem;
    padding: 0;
    background-color: transparent;
    appearance: none
}

.custom-range:focus {
    outline: none
}

.custom-range:focus::-webkit-slider-thumb {
    box-shadow: 0 0 0 1px #fff, 0 0 0 .2rem rgba(1, 104, 250, 0.25)
}

.custom-range:focus::-moz-range-thumb {
    box-shadow: 0 0 0 1px #fff, 0 0 0 .2rem rgba(1, 104, 250, 0.25)
}

.custom-range:focus::-ms-thumb {
    box-shadow: 0 0 0 1px #fff, 0 0 0 .2rem rgba(1, 104, 250, 0.25)
}

.custom-range::-moz-focus-outer {
    border: 0
}

.custom-range::-webkit-slider-thumb {
    width: 1rem;
    height: 1rem;
    margin-top: -.25rem;
    background-color: #0168fa;
    border: 0;
    border-radius: 1rem;
    transition: background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
    appearance: none
}

@media (prefers-reduced-motion: reduce) {
    .custom-range::-webkit-slider-thumb {
        transition: none
    }
}

.custom-range::-webkit-slider-thumb:active {
    background-color: #afd0ff
}

.custom-range::-webkit-slider-runnable-track {
    width: 100%;
    height: .5rem;
    color: transparent;
    cursor: pointer;
    background-color: #cdd4e0;
    border-color: transparent;
    border-radius: 1rem
}

.custom-range::-moz-range-thumb {
    width: 1rem;
    height: 1rem;
    background-color: #0168fa;
    border: 0;
    border-radius: 1rem;
    transition: background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
    appearance: none
}

@media (prefers-reduced-motion: reduce) {
    .custom-range::-moz-range-thumb {
        transition: none
    }
}

.custom-range::-moz-range-thumb:active {
    background-color: #afd0ff
}

.custom-range::-moz-range-track {
    width: 100%;
    height: .5rem;
    color: transparent;
    cursor: pointer;
    background-color: #cdd4e0;
    border-color: transparent;
    border-radius: 1rem
}

.custom-range::-ms-thumb {
    width: 1rem;
    height: 1rem;
    margin-top: 0;
    margin-right: .2rem;
    margin-left: .2rem;
    background-color: #0168fa;
    border: 0;
    border-radius: 1rem;
    transition: background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
    appearance: none
}

@media (prefers-reduced-motion: reduce) {
    .custom-range::-ms-thumb {
        transition: none
    }
}

.custom-range::-ms-thumb:active {
    background-color: #afd0ff
}

.custom-range::-ms-track {
    width: 100%;
    height: .5rem;
    color: transparent;
    cursor: pointer;
    background-color: transparent;
    border-color: transparent;
    border-width: .5rem
}

.custom-range::-ms-fill-lower {
    background-color: #cdd4e0;
    border-radius: 1rem
}

.custom-range::-ms-fill-upper {
    margin-right: 15px;
    background-color: #cdd4e0;
    border-radius: 1rem
}

.custom-range:disabled::-webkit-slider-thumb {
    background-color: #97a3b9
}

.custom-range:disabled::-webkit-slider-runnable-track {
    cursor: default
}

.custom-range:disabled::-moz-range-thumb {
    background-color: #97a3b9
}

.custom-range:disabled::-moz-range-track {
    cursor: default
}

.custom-range:disabled::-ms-thumb {
    background-color: #97a3b9
}

.custom-control-label::before,
.custom-file-label,
.custom-select {
    transition: background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out
}

@media (prefers-reduced-motion: reduce) {

    .custom-control-label::before,
    .custom-file-label,
    .custom-select {
        transition: none
    }
}

.nav {
    display: flex;
    flex-wrap: wrap;
    padding-left: 0;
    margin-bottom: 0;
    list-style: none
}

.nav-link {
    display: block;
    padding: .5rem 1rem
}

.nav-link:hover,
.nav-link:focus {
    text-decoration: none
}

.nav-link.disabled {
    color: #7987a1;
    pointer-events: none;
    cursor: default
}

.nav-tabs {
    border-bottom: 1px solid #cdd4e0
}

.nav-tabs .nav-item {
    margin-bottom: -1px
}

.nav-tabs .nav-link {
    border: 1px solid transparent;
    border-top-left-radius: .25rem;
    border-top-right-radius: .25rem
}

.nav-tabs .nav-link:hover,
.nav-tabs .nav-link:focus {
    border-color: #e3e7ed #e3e7ed #cdd4e0
}

.nav-tabs .nav-link.disabled {
    color: #7987a1;
    background-color: transparent;
    border-color: transparent
}

.nav-tabs .nav-link.active,
.nav-tabs .nav-item.show .nav-link {
    color: #596882;
    background-color: #fff;
    border-color: #cdd4e0 #cdd4e0 #fff
}

.nav-tabs .dropdown-menu {
    margin-top: -1px;
    border-top-left-radius: 0;
    border-top-right-radius: 0
}

.nav-pills .nav-link {
    border-radius: .25rem
}

.nav-pills .nav-link.active,
.nav-pills .show>.nav-link {
    color: #fff;
    background-color: #0168fa
}

.nav-fill .nav-item {
    flex: 1 1 auto;
    text-align: center
}

.nav-justified .nav-item {
    flex-basis: 0;
    flex-grow: 1;
    text-align: center
}

.tab-content>.tab-pane {
    display: none
}

.tab-content>.active {
    display: block
}

.navbar {
    position: relative;
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    justify-content: space-between;
    padding: .5rem 1rem
}

.navbar .container,
.navbar .container-fluid,
.navbar .container-sm,
.navbar .container-md,
.navbar .container-lg,
.navbar .container-xl {
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    justify-content: space-between
}

.navbar-brand {
    display: inline-block;
    padding-top: .33594rem;
    padding-bottom: .33594rem;
    margin-right: 1rem;
    font-size: 1.09375rem;
    line-height: inherit;
    white-space: nowrap
}

.navbar-brand:hover,
.navbar-brand:focus {
    text-decoration: none
}

.navbar-nav {
    display: flex;
    flex-direction: column;
    padding-left: 0;
    margin-bottom: 0;
    list-style: none
}

.navbar-nav .nav-link {
    padding-right: 0;
    padding-left: 0
}

.navbar-nav .dropdown-menu {
    position: static;
    float: none
}

.navbar-text {
    display: inline-block;
    padding-top: .5rem;
    padding-bottom: .5rem
}

.navbar-collapse {
    flex-basis: 100%;
    flex-grow: 1;
    align-items: center
}

.navbar-toggler {
    padding: .25rem .75rem;
    font-size: 1.09375rem;
    line-height: 1;
    background-color: transparent;
    border: 1px solid transparent;
    border-radius: .25rem
}

.navbar-toggler:hover,
.navbar-toggler:focus {
    text-decoration: none
}

.navbar-toggler-icon {
    display: inline-block;
    width: 1.5em;
    height: 1.5em;
    vertical-align: middle;
    content: "";
    background: no-repeat center center;
    background-size: 100% 100%
}

@media (max-width: 575.98px) {

    .navbar-expand-sm>.container,
    .navbar-expand-sm>.container-fluid,
    .navbar-expand-sm>.container-sm,
    .navbar-expand-sm>.container-md,
    .navbar-expand-sm>.container-lg,
    .navbar-expand-sm>.container-xl {
        padding-right: 0;
        padding-left: 0
    }
}

@media (min-width: 576px) {
    .navbar-expand-sm {
        flex-flow: row nowrap;
        justify-content: flex-start
    }

    .navbar-expand-sm .navbar-nav {
        flex-direction: row
    }

    .navbar-expand-sm .navbar-nav .dropdown-menu {
        position: absolute
    }

    .navbar-expand-sm .navbar-nav .nav-link {
        padding-right: .5rem;
        padding-left: .5rem
    }

    .navbar-expand-sm>.container,
    .navbar-expand-sm>.container-fluid,
    .navbar-expand-sm>.container-sm,
    .navbar-expand-sm>.container-md,
    .navbar-expand-sm>.container-lg,
    .navbar-expand-sm>.container-xl {
        flex-wrap: nowrap
    }

    .navbar-expand-sm .navbar-collapse {
        display: flex !important;
        flex-basis: auto
    }

    .navbar-expand-sm .navbar-toggler {
        display: none
    }
}

@media (max-width: 767.98px) {

    .navbar-expand-md>.container,
    .navbar-expand-md>.container-fluid,
    .navbar-expand-md>.container-sm,
    .navbar-expand-md>.container-md,
    .navbar-expand-md>.container-lg,
    .navbar-expand-md>.container-xl {
        padding-right: 0;
        padding-left: 0
    }
}

@media (min-width: 768px) {
    .navbar-expand-md {
        flex-flow: row nowrap;
        justify-content: flex-start
    }

    .navbar-expand-md .navbar-nav {
        flex-direction: row
    }

    .navbar-expand-md .navbar-nav .dropdown-menu {
        position: absolute
    }

    .navbar-expand-md .navbar-nav .nav-link {
        padding-right: .5rem;
        padding-left: .5rem
    }

    .navbar-expand-md>.container,
    .navbar-expand-md>.container-fluid,
    .navbar-expand-md>.container-sm,
    .navbar-expand-md>.container-md,
    .navbar-expand-md>.container-lg,
    .navbar-expand-md>.container-xl {
        flex-wrap: nowrap
    }

    .navbar-expand-md .navbar-collapse {
        display: flex !important;
        flex-basis: auto
    }

    .navbar-expand-md .navbar-toggler {
        display: none
    }
}

@media (max-width: 991.98px) {

    .navbar-expand-lg>.container,
    .navbar-expand-lg>.container-fluid,
    .navbar-expand-lg>.container-sm,
    .navbar-expand-lg>.container-md,
    .navbar-expand-lg>.container-lg,
    .navbar-expand-lg>.container-xl {
        padding-right: 0;
        padding-left: 0
    }
}

@media (min-width: 992px) {
    .navbar-expand-lg {
        flex-flow: row nowrap;
        justify-content: flex-start
    }

    .navbar-expand-lg .navbar-nav {
        flex-direction: row
    }

    .navbar-expand-lg .navbar-nav .dropdown-menu {
        position: absolute
    }

    .navbar-expand-lg .navbar-nav .nav-link {
        padding-right: .5rem;
        padding-left: .5rem
    }

    .navbar-expand-lg>.container,
    .navbar-expand-lg>.container-fluid,
    .navbar-expand-lg>.container-sm,
    .navbar-expand-lg>.container-md,
    .navbar-expand-lg>.container-lg,
    .navbar-expand-lg>.container-xl {
        flex-wrap: nowrap
    }

    .navbar-expand-lg .navbar-collapse {
        display: flex !important;
        flex-basis: auto
    }

    .navbar-expand-lg .navbar-toggler {
        display: none
    }
}

@media (max-width: 1199.98px) {

    .navbar-expand-xl>.container,
    .navbar-expand-xl>.container-fluid,
    .navbar-expand-xl>.container-sm,
    .navbar-expand-xl>.container-md,
    .navbar-expand-xl>.container-lg,
    .navbar-expand-xl>.container-xl {
        padding-right: 0;
        padding-left: 0
    }
}

@media (min-width: 1200px) {
    .navbar-expand-xl {
        flex-flow: row nowrap;
        justify-content: flex-start
    }

    .navbar-expand-xl .navbar-nav {
        flex-direction: row
    }

    .navbar-expand-xl .navbar-nav .dropdown-menu {
        position: absolute
    }

    .navbar-expand-xl .navbar-nav .nav-link {
        padding-right: .5rem;
        padding-left: .5rem
    }

    .navbar-expand-xl>.container,
    .navbar-expand-xl>.container-fluid,
    .navbar-expand-xl>.container-sm,
    .navbar-expand-xl>.container-md,
    .navbar-expand-xl>.container-lg,
    .navbar-expand-xl>.container-xl {
        flex-wrap: nowrap
    }

    .navbar-expand-xl .navbar-collapse {
        display: flex !important;
        flex-basis: auto
    }

    .navbar-expand-xl .navbar-toggler {
        display: none
    }
}

.navbar-expand {
    flex-flow: row nowrap;
    justify-content: flex-start
}

.navbar-expand>.container,
.navbar-expand>.container-fluid,
.navbar-expand>.container-sm,
.navbar-expand>.container-md,
.navbar-expand>.container-lg,
.navbar-expand>.container-xl {
    padding-right: 0;
    padding-left: 0
}

.navbar-expand .navbar-nav {
    flex-direction: row
}

.navbar-expand .navbar-nav .dropdown-menu {
    position: absolute
}

.navbar-expand .navbar-nav .nav-link {
    padding-right: .5rem;
    padding-left: .5rem
}

.navbar-expand>.container,
.navbar-expand>.container-fluid,
.navbar-expand>.container-sm,
.navbar-expand>.container-md,
.navbar-expand>.container-lg,
.navbar-expand>.container-xl {
    flex-wrap: nowrap
}

.navbar-expand .navbar-collapse {
    display: flex !important;
    flex-basis: auto
}

.navbar-expand .navbar-toggler {
    display: none
}

.navbar-light .navbar-brand {
    color: rgba(0, 0, 0, 0.9)
}

.navbar-light .navbar-brand:hover,
.navbar-light .navbar-brand:focus {
    color: rgba(0, 0, 0, 0.9)
}

.navbar-light .navbar-nav .nav-link {
    color: rgba(0, 0, 0, 0.5)
}

.navbar-light .navbar-nav .nav-link:hover,
.navbar-light .navbar-nav .nav-link:focus {
    color: rgba(0, 0, 0, 0.7)
}

.navbar-light .navbar-nav .nav-link.disabled {
    color: rgba(0, 0, 0, 0.3)
}

.navbar-light .navbar-nav .show>.nav-link,
.navbar-light .navbar-nav .active>.nav-link,
.navbar-light .navbar-nav .nav-link.show,
.navbar-light .navbar-nav .nav-link.active {
    color: rgba(0, 0, 0, 0.9)
}

.navbar-light .navbar-toggler {
    color: rgba(0, 0, 0, 0.5);
    border-color: rgba(0, 0, 0, 0.1)
}

.navbar-light .navbar-toggler-icon {
    background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' width='30' height='30' viewBox='0 0 30 30'%3e%3cpath stroke='rgba(0,0,0,0.5)' stroke-linecap='round' stroke-miterlimit='10' stroke-width='2' d='M4 7h22M4 15h22M4 23h22'/%3e%3c/svg%3e")
}

.navbar-light .navbar-text {
    color: rgba(0, 0, 0, 0.5)
}

.navbar-light .navbar-text a {
    color: rgba(0, 0, 0, 0.9)
}

.navbar-light .navbar-text a:hover,
.navbar-light .navbar-text a:focus {
    color: rgba(0, 0, 0, 0.9)
}

.navbar-dark .navbar-brand {
    color: #fff
}

.navbar-dark .navbar-brand:hover,
.navbar-dark .navbar-brand:focus {
    color: #fff
}

.navbar-dark .navbar-nav .nav-link {
    color: rgba(255, 255, 255, 0.5)
}

.navbar-dark .navbar-nav .nav-link:hover,
.navbar-dark .navbar-nav .nav-link:focus {
    color: rgba(255, 255, 255, 0.75)
}

.navbar-dark .navbar-nav .nav-link.disabled {
    color: rgba(255, 255, 255, 0.25)
}

.navbar-dark .navbar-nav .show>.nav-link,
.navbar-dark .navbar-nav .active>.nav-link,
.navbar-dark .navbar-nav .nav-link.show,
.navbar-dark .navbar-nav .nav-link.active {
    color: #fff
}

.navbar-dark .navbar-toggler {
    color: rgba(255, 255, 255, 0.5);
    border-color: rgba(255, 255, 255, 0.1)
}

.navbar-dark .navbar-toggler-icon {
    background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' width='30' height='30' viewBox='0 0 30 30'%3e%3cpath stroke='rgba(255,255,255,0.5)' stroke-linecap='round' stroke-miterlimit='10' stroke-width='2' d='M4 7h22M4 15h22M4 23h22'/%3e%3c/svg%3e")
}

.navbar-dark .navbar-text {
    color: rgba(255, 255, 255, 0.5)
}

.navbar-dark .navbar-text a {
    color: #fff
}

.navbar-dark .navbar-text a:hover,
.navbar-dark .navbar-text a:focus {
    color: #fff
}

.card {
    position: relative;
    display: flex;
    flex-direction: column;
    min-width: 0;
    word-wrap: break-word;
    background-color: #fff;
    background-clip: border-box;
    border: 1px solid rgba(72, 94, 144, 0.16);
    border-radius: .25rem
}

.card>hr {
    margin-right: 0;
    margin-left: 0
}

.card>.list-group:first-child .list-group-item:first-child {
    border-top-left-radius: .25rem;
    border-top-right-radius: .25rem
}

.card>.list-group:last-child .list-group-item:last-child {
    border-bottom-right-radius: .25rem;
    border-bottom-left-radius: .25rem
}

.card-body {
    flex: 1 1 auto;
    min-height: 1px;
    padding: 1.25rem
}

.card-title {
    margin-bottom: .75rem
}

.card-subtitle {
    margin-top: -.375rem;
    margin-bottom: 0
}

.card-text:last-child {
    margin-bottom: 0
}

.card-link:hover {
    text-decoration: none
}

.card-link+.card-link {
    margin-left: 1.25rem
}

.card-header {
    padding: .75rem 1.25rem;
    margin-bottom: 0;
    background-color: rgba(0, 0, 0, 0.03);
    border-bottom: 1px solid rgba(72, 94, 144, 0.16)
}

.card-header:first-child {
    border-radius: calc(.25rem - 1px) calc(.25rem - 1px) 0 0
}

.card-header+.list-group .list-group-item:first-child {
    border-top: 0
}

.card-footer {
    padding: .75rem 1.25rem;
    background-color: rgba(0, 0, 0, 0.03);
    border-top: 1px solid rgba(72, 94, 144, 0.16)
}

.card-footer:last-child {
    border-radius: 0 0 calc(.25rem - 1px) calc(.25rem - 1px)
}

.card-header-tabs {
    margin-right: -.625rem;
    margin-bottom: -.75rem;
    margin-left: -.625rem;
    border-bottom: 0
}

.card-header-pills {
    margin-right: -.625rem;
    margin-left: -.625rem
}

.card-img-overlay {
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    padding: 1.25rem
}

.card-img,
.card-img-top,
.card-img-bottom {
    flex-shrink: 0;
    width: 100%
}

.card-img,
.card-img-top {
    border-top-left-radius: calc(.25rem - 1px);
    border-top-right-radius: calc(.25rem - 1px)
}

.card-img,
.card-img-bottom {
    border-bottom-right-radius: calc(.25rem - 1px);
    border-bottom-left-radius: calc(.25rem - 1px)
}

.card-deck .card {
    margin-bottom: 15px
}

@media (min-width: 576px) {
    .card-deck {
        display: flex;
        flex-flow: row wrap;
        margin-right: -15px;
        margin-left: -15px
    }

    .card-deck .card {
        flex: 1 0 0%;
        margin-right: 15px;
        margin-bottom: 0;
        margin-left: 15px
    }
}

.card-group>.card {
    margin-bottom: 15px
}

@media (min-width: 576px) {
    .card-group {
        display: flex;
        flex-flow: row wrap
    }

    .card-group>.card {
        flex: 1 0 0%;
        margin-bottom: 0
    }

    .card-group>.card+.card {
        margin-left: 0;
        border-left: 0
    }

    .card-group>.card:not(:last-child) {
        border-top-right-radius: 0;
        border-bottom-right-radius: 0
    }

    .card-group>.card:not(:last-child) .card-img-top,
    .card-group>.card:not(:last-child) .card-header {
        border-top-right-radius: 0
    }

    .card-group>.card:not(:last-child) .card-img-bottom,
    .card-group>.card:not(:last-child) .card-footer {
        border-bottom-right-radius: 0
    }

    .card-group>.card:not(:first-child) {
        border-top-left-radius: 0;
        border-bottom-left-radius: 0
    }

    .card-group>.card:not(:first-child) .card-img-top,
    .card-group>.card:not(:first-child) .card-header {
        border-top-left-radius: 0
    }

    .card-group>.card:not(:first-child) .card-img-bottom,
    .card-group>.card:not(:first-child) .card-footer {
        border-bottom-left-radius: 0
    }
}

.card-columns .card {
    margin-bottom: .75rem
}

@media (min-width: 576px) {
    .card-columns {
        column-count: 3;
        column-gap: 1.25rem;
        orphans: 1;
        widows: 1
    }

    .card-columns .card {
        display: inline-block;
        width: 100%
    }
}

.accordion>.card {
    overflow: hidden
}

.accordion>.card:not(:last-of-type) {
    border-bottom: 0;
    border-bottom-right-radius: 0;
    border-bottom-left-radius: 0
}

.accordion>.card:not(:first-of-type) {
    border-top-left-radius: 0;
    border-top-right-radius: 0
}

.accordion>.card>.card-header {
    border-radius: 0;
    margin-bottom: -1px
}

.breadcrumb {
    display: flex;
    flex-wrap: wrap;
    padding: .75rem 1rem;
    margin-bottom: 1rem;
    list-style: none;
    background-color: #e3e7ed;
    border-radius: .25rem
}

.breadcrumb-item+.breadcrumb-item {
    padding-left: .5rem
}

.breadcrumb-item+.breadcrumb-item::before {
    display: inline-block;
    padding-right: .5rem;
    color: #7987a1;
    content: "/"
}

.breadcrumb-item+.breadcrumb-item:hover::before {
    text-decoration: underline
}

.breadcrumb-item+.breadcrumb-item:hover::before {
    text-decoration: none
}

.breadcrumb-item.active {
    color: #7987a1
}

.pagination {
    display: flex;
    padding-left: 0;
    list-style: none;
    border-radius: .25rem
}

.page-link {
    position: relative;
    display: block;
    padding: .5rem .75rem;
    margin-left: -1px;
    line-height: 1.25;
    color: #0168fa;
    background-color: #fff;
    border: 1px solid #cdd4e0
}

.page-link:hover {
    z-index: 2;
    color: #0148ae;
    text-decoration: none;
    background-color: #e3e7ed;
    border-color: #cdd4e0
}

.page-link:focus {
    z-index: 3;
    outline: 0;
    box-shadow: 0 0 0 .2rem rgba(1, 104, 250, 0.25)
}

.page-item:first-child .page-link {
    margin-left: 0;
    border-top-left-radius: .25rem;
    border-bottom-left-radius: .25rem
}

.page-item:last-child .page-link {
    border-top-right-radius: .25rem;
    border-bottom-right-radius: .25rem
}

.page-item.active .page-link {
    z-index: 3;
    color: #fff;
    background-color: #0168fa;
    border-color: #0168fa
}

.page-item.disabled .page-link {
    color: #7987a1;
    pointer-events: none;
    cursor: auto;
    background-color: #fff;
    border-color: #cdd4e0
}

.pagination-lg .page-link {
    padding: .75rem 1.5rem;
    font-size: 1.09375rem;
    line-height: 1.5
}

.pagination-lg .page-item:first-child .page-link {
    border-top-left-radius: .3rem;
    border-bottom-left-radius: .3rem
}

.pagination-lg .page-item:last-child .page-link {
    border-top-right-radius: .3rem;
    border-bottom-right-radius: .3rem
}

.pagination-sm .page-link {
    padding: .25rem .5rem;
    font-size: .76562rem;
    line-height: 1.5
}

.pagination-sm .page-item:first-child .page-link {
    border-top-left-radius: .2rem;
    border-bottom-left-radius: .2rem
}

.pagination-sm .page-item:last-child .page-link {
    border-top-right-radius: .2rem;
    border-bottom-right-radius: .2rem
}

.badge {
    display: inline-block;
    padding: .25em .4em;
    font-size: 75%;
    font-weight: 700;
    line-height: 1;
    text-align: center;
    white-space: nowrap;
    vertical-align: baseline;
    border-radius: .25rem;
    transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out
}

@media (prefers-reduced-motion: reduce) {
    .badge {
        transition: none
    }
}

a.badge:hover,
a.badge:focus {
    text-decoration: none
}

.badge:empty {
    display: none
}

.btn .badge,
.ui-datepicker-buttonpane button .badge,
.sp-container button .badge {
    position: relative;
    top: -1px
}

.badge-pill {
    padding-right: .6em;
    padding-left: .6em;
    border-radius: 10rem
}

.badge-primary {
    color: #fff;
    background-color: #0168fa
}

a.badge-primary:hover,
a.badge-primary:focus {
    color: #fff;
    background-color: #0153c7
}

a.badge-primary:focus,
a.badge-primary.focus {
    outline: 0;
    box-shadow: 0 0 0 .2rem rgba(1, 104, 250, 0.5)
}

.badge-secondary {
    color: #fff;
    background-color: #7987a1
}

a.badge-secondary:hover,
a.badge-secondary:focus {
    color: #fff;
    background-color: #5f6d88
}

a.badge-secondary:focus,
a.badge-secondary.focus {
    outline: 0;
    box-shadow: 0 0 0 .2rem rgba(121, 135, 161, 0.5)
}

.badge-success {
    color: #fff;
    background-color: #10b759
}

a.badge-success:hover,
a.badge-success:focus {
    color: #fff;
    background-color: #0c8842
}

a.badge-success:focus,
a.badge-success.focus {
    outline: 0;
    box-shadow: 0 0 0 .2rem rgba(16, 183, 89, 0.5)
}

.badge-info {
    color: #fff;
    background-color: #00b8d4
}

a.badge-info:hover,
a.badge-info:focus {
    color: #fff;
    background-color: #008ca1
}

a.badge-info:focus,
a.badge-info.focus {
    outline: 0;
    box-shadow: 0 0 0 .2rem rgba(0, 184, 212, 0.5)
}

.badge-warning {
    color: #1c273c;
    background-color: #ffc107
}

a.badge-warning:hover,
a.badge-warning:focus {
    color: #1c273c;
    background-color: #d39e00
}

a.badge-warning:focus,
a.badge-warning.focus {
    outline: 0;
    box-shadow: 0 0 0 .2rem rgba(255, 193, 7, 0.5)
}

.badge-danger {
    color: #fff;
    background-color: #dc3545
}

a.badge-danger:hover,
a.badge-danger:focus {
    color: #fff;
    background-color: #bd2130
}

a.badge-danger:focus,
a.badge-danger.focus {
    outline: 0;
    box-shadow: 0 0 0 .2rem rgba(220, 53, 69, 0.5)
}

.badge-light {
    color: #1c273c;
    background-color: #f4f5f8
}

a.badge-light:hover,
a.badge-light:focus {
    color: #1c273c;
    background-color: #d5d9e4
}

a.badge-light:focus,
a.badge-light.focus {
    outline: 0;
    box-shadow: 0 0 0 .2rem rgba(244, 245, 248, 0.5)
}

.badge-dark {
    color: #fff;
    background-color: #3b4863
}

a.badge-dark:hover,
a.badge-dark:focus {
    color: #fff;
    background-color: #283143
}

a.badge-dark:focus,
a.badge-dark.focus {
    outline: 0;
    box-shadow: 0 0 0 .2rem rgba(59, 72, 99, 0.5)
}

.jumbotron {
    padding: 2rem 1rem;
    margin-bottom: 2rem;
    background-color: #e3e7ed;
    border-radius: .3rem
}

@media (min-width: 576px) {
    .jumbotron {
        padding: 4rem 2rem
    }
}

.jumbotron-fluid {
    padding-right: 0;
    padding-left: 0;
    border-radius: 0
}

.alert {
    position: relative;
    padding: .75rem 1.25rem;
    margin-bottom: 1rem;
    border: 1px solid transparent;
    border-radius: .25rem
}

.alert-heading {
    color: inherit
}

.alert-link {
    font-weight: 700
}

.alert-dismissible {
    padding-right: 3.8125rem
}

.alert-dismissible .close {
    position: absolute;
    top: 0;
    right: 0;
    padding: .75rem 1.25rem;
    color: inherit
}

.alert-primary {
    color: #013682;
    background-color: #cce1fe;
    border-color: #b8d5fe
}

.alert-primary hr {
    border-top-color: #9fc6fe
}

.alert-primary .alert-link {
    color: #01214f
}

.alert-secondary {
    color: #3f4654;
    background-color: #e4e7ec;
    border-color: #d9dde5
}

.alert-secondary hr {
    border-top-color: #cacfdb
}

.alert-secondary .alert-link {
    color: #292e37
}

.alert-success {
    color: #085f2e;
    background-color: #cff1de;
    border-color: #bcebd1
}

.alert-success hr {
    border-top-color: #a8e5c4
}

.alert-success .alert-link {
    color: #043017
}

.alert-info {
    color: #00606e;
    background-color: #ccf1f6;
    border-color: #b8ebf3
}

.alert-info hr {
    border-top-color: #a2e5ef
}

.alert-info .alert-link {
    color: #00333b
}

.alert-warning {
    color: #856404;
    background-color: #fff3cd;
    border-color: #ffeeba
}

.alert-warning hr {
    border-top-color: #ffe8a1
}

.alert-warning .alert-link {
    color: #533f03
}

.alert-danger {
    color: #721c24;
    background-color: #f8d7da;
    border-color: #f5c6cb
}

.alert-danger hr {
    border-top-color: #f1b0b7
}

.alert-danger .alert-link {
    color: #491217
}

.alert-light {
    color: #7f7f81;
    background-color: #fdfdfe;
    border-color: #fcfcfd
}

.alert-light hr {
    border-top-color: #ededf3
}

.alert-light .alert-link {
    color: #666667
}

.alert-dark {
    color: #1f2533;
    background-color: #d8dae0;
    border-color: #c8ccd3
}

.alert-dark hr {
    border-top-color: #babfc8
}

.alert-dark .alert-link {
    color: #0c0e13
}

@keyframes progress-bar-stripes {
    from {
        background-position: 1rem 0
    }

    to {
        background-position: 0 0
    }
}

.progress {
    display: flex;
    height: 1rem;
    overflow: hidden;
    font-size: .65625rem;
    background-color: #e3e7ed;
    border-radius: .25rem
}

.progress-bar {
    display: flex;
    flex-direction: column;
    justify-content: center;
    overflow: hidden;
    color: #fff;
    text-align: center;
    white-space: nowrap;
    background-color: #0168fa;
    transition: width 0.6s ease
}

@media (prefers-reduced-motion: reduce) {
    .progress-bar {
        transition: none
    }
}

.progress-bar-striped {
    background-image: linear-gradient(45deg, rgba(255, 255, 255, 0.15) 25%, transparent 25%, transparent 50%, rgba(255, 255, 255, 0.15) 50%, rgba(255, 255, 255, 0.15) 75%, transparent 75%, transparent);
    background-size: 1rem 1rem
}

.progress-bar-animated {
    animation: progress-bar-stripes 1s linear infinite
}

@media (prefers-reduced-motion: reduce) {
    .progress-bar-animated {
        animation: none
    }
}

.media {
    display: flex;
    align-items: flex-start
}

.media-body {
    flex: 1
}

.list-group {
    display: flex;
    flex-direction: column;
    padding-left: 0;
    margin-bottom: 0
}

.list-group-item-action {
    width: 100%;
    color: #596882;
    text-align: inherit
}

.list-group-item-action:hover,
.list-group-item-action:focus {
    z-index: 1;
    color: #596882;
    text-decoration: none;
    background-color: #f4f5f8
}

.list-group-item-action:active {
    color: #001737;
    background-color: #e3e7ed
}

.list-group-item {
    position: relative;
    display: block;
    padding: .75rem 1.25rem;
    background-color: #fff;
    border: 1px solid rgba(0, 0, 0, 0.125)
}

.list-group-item:first-child {
    border-top-left-radius: .25rem;
    border-top-right-radius: .25rem
}

.list-group-item:last-child {
    border-bottom-right-radius: .25rem;
    border-bottom-left-radius: .25rem
}

.list-group-item.disabled,
.list-group-item:disabled {
    color: #7987a1;
    pointer-events: none;
    background-color: #fff
}

.list-group-item.active {
    z-index: 2;
    color: #fff;
    background-color: #0168fa;
    border-color: #0168fa
}

.list-group-item+.list-group-item {
    border-top-width: 0
}

.list-group-item+.list-group-item.active {
    margin-top: -1px;
    border-top-width: 1px
}

.list-group-horizontal {
    flex-direction: row
}

.list-group-horizontal .list-group-item:first-child {
    border-bottom-left-radius: .25rem;
    border-top-right-radius: 0
}

.list-group-horizontal .list-group-item:last-child {
    border-top-right-radius: .25rem;
    border-bottom-left-radius: 0
}

.list-group-horizontal .list-group-item.active {
    margin-top: 0
}

.list-group-horizontal .list-group-item+.list-group-item {
    border-top-width: 1px;
    border-left-width: 0
}

.list-group-horizontal .list-group-item+.list-group-item.active {
    margin-left: -1px;
    border-left-width: 1px
}

@media (min-width: 576px) {
    .list-group-horizontal-sm {
        flex-direction: row
    }

    .list-group-horizontal-sm .list-group-item:first-child {
        border-bottom-left-radius: .25rem;
        border-top-right-radius: 0
    }

    .list-group-horizontal-sm .list-group-item:last-child {
        border-top-right-radius: .25rem;
        border-bottom-left-radius: 0
    }

    .list-group-horizontal-sm .list-group-item.active {
        margin-top: 0
    }

    .list-group-horizontal-sm .list-group-item+.list-group-item {
        border-top-width: 1px;
        border-left-width: 0
    }

    .list-group-horizontal-sm .list-group-item+.list-group-item.active {
        margin-left: -1px;
        border-left-width: 1px
    }
}

@media (min-width: 768px) {
    .list-group-horizontal-md {
        flex-direction: row
    }

    .list-group-horizontal-md .list-group-item:first-child {
        border-bottom-left-radius: .25rem;
        border-top-right-radius: 0
    }

    .list-group-horizontal-md .list-group-item:last-child {
        border-top-right-radius: .25rem;
        border-bottom-left-radius: 0
    }

    .list-group-horizontal-md .list-group-item.active {
        margin-top: 0
    }

    .list-group-horizontal-md .list-group-item+.list-group-item {
        border-top-width: 1px;
        border-left-width: 0
    }

    .list-group-horizontal-md .list-group-item+.list-group-item.active {
        margin-left: -1px;
        border-left-width: 1px
    }
}

@media (min-width: 992px) {
    .list-group-horizontal-lg {
        flex-direction: row
    }

    .list-group-horizontal-lg .list-group-item:first-child {
        border-bottom-left-radius: .25rem;
        border-top-right-radius: 0
    }

    .list-group-horizontal-lg .list-group-item:last-child {
        border-top-right-radius: .25rem;
        border-bottom-left-radius: 0
    }

    .list-group-horizontal-lg .list-group-item.active {
        margin-top: 0
    }

    .list-group-horizontal-lg .list-group-item+.list-group-item {
        border-top-width: 1px;
        border-left-width: 0
    }

    .list-group-horizontal-lg .list-group-item+.list-group-item.active {
        margin-left: -1px;
        border-left-width: 1px
    }
}

@media (min-width: 1200px) {
    .list-group-horizontal-xl {
        flex-direction: row
    }

    .list-group-horizontal-xl .list-group-item:first-child {
        border-bottom-left-radius: .25rem;
        border-top-right-radius: 0
    }

    .list-group-horizontal-xl .list-group-item:last-child {
        border-top-right-radius: .25rem;
        border-bottom-left-radius: 0
    }

    .list-group-horizontal-xl .list-group-item.active {
        margin-top: 0
    }

    .list-group-horizontal-xl .list-group-item+.list-group-item {
        border-top-width: 1px;
        border-left-width: 0
    }

    .list-group-horizontal-xl .list-group-item+.list-group-item.active {
        margin-left: -1px;
        border-left-width: 1px
    }
}

.list-group-flush .list-group-item {
    border-right-width: 0;
    border-left-width: 0;
    border-radius: 0
}

.list-group-flush .list-group-item:first-child {
    border-top-width: 0
}

.list-group-flush:last-child .list-group-item:last-child {
    border-bottom-width: 0
}

.list-group-item-primary {
    color: #013682;
    background-color: #b8d5fe
}

.list-group-item-primary.list-group-item-action:hover,
.list-group-item-primary.list-group-item-action:focus {
    color: #013682;
    background-color: #9fc6fe
}

.list-group-item-primary.list-group-item-action.active {
    color: #fff;
    background-color: #013682;
    border-color: #013682
}

.list-group-item-secondary {
    color: #3f4654;
    background-color: #d9dde5
}

.list-group-item-secondary.list-group-item-action:hover,
.list-group-item-secondary.list-group-item-action:focus {
    color: #3f4654;
    background-color: #cacfdb
}

.list-group-item-secondary.list-group-item-action.active {
    color: #fff;
    background-color: #3f4654;
    border-color: #3f4654
}

.list-group-item-success {
    color: #085f2e;
    background-color: #bcebd1
}

.list-group-item-success.list-group-item-action:hover,
.list-group-item-success.list-group-item-action:focus {
    color: #085f2e;
    background-color: #a8e5c4
}

.list-group-item-success.list-group-item-action.active {
    color: #fff;
    background-color: #085f2e;
    border-color: #085f2e
}

.list-group-item-info {
    color: #00606e;
    background-color: #b8ebf3
}

.list-group-item-info.list-group-item-action:hover,
.list-group-item-info.list-group-item-action:focus {
    color: #00606e;
    background-color: #a2e5ef
}

.list-group-item-info.list-group-item-action.active {
    color: #fff;
    background-color: #00606e;
    border-color: #00606e
}

.list-group-item-warning {
    color: #856404;
    background-color: #ffeeba
}

.list-group-item-warning.list-group-item-action:hover,
.list-group-item-warning.list-group-item-action:focus {
    color: #856404;
    background-color: #ffe8a1
}

.list-group-item-warning.list-group-item-action.active {
    color: #fff;
    background-color: #856404;
    border-color: #856404
}

.list-group-item-danger {
    color: #721c24;
    background-color: #f5c6cb
}

.list-group-item-danger.list-group-item-action:hover,
.list-group-item-danger.list-group-item-action:focus {
    color: #721c24;
    background-color: #f1b0b7
}

.list-group-item-danger.list-group-item-action.active {
    color: #fff;
    background-color: #721c24;
    border-color: #721c24
}

.list-group-item-light {
    color: #7f7f81;
    background-color: #fcfcfd
}

.list-group-item-light.list-group-item-action:hover,
.list-group-item-light.list-group-item-action:focus {
    color: #7f7f81;
    background-color: #ededf3
}

.list-group-item-light.list-group-item-action.active {
    color: #fff;
    background-color: #7f7f81;
    border-color: #7f7f81
}

.list-group-item-dark {
    color: #1f2533;
    background-color: #c8ccd3
}

.list-group-item-dark.list-group-item-action:hover,
.list-group-item-dark.list-group-item-action:focus {
    color: #1f2533;
    background-color: #babfc8
}

.list-group-item-dark.list-group-item-action.active {
    color: #fff;
    background-color: #1f2533;
    border-color: #1f2533
}

.close {
    float: right;
    font-size: 1.3125rem;
    font-weight: 700;
    line-height: 1;
    color: #1b2e4b;
    text-shadow: 0 1px 0 #fff;
    opacity: .5
}

.close:hover {
    color: #1b2e4b;
    text-decoration: none
}

.close:not(:disabled):not(.disabled):hover,
.close:not(:disabled):not(.disabled):focus {
    opacity: .75
}

button.close {
    padding: 0;
    background-color: transparent;
    border: 0;
    appearance: none
}

a.close.disabled {
    pointer-events: none
}

.toast {
    max-width: 350px;
    overflow: hidden;
    font-size: .875rem;
    background-color: rgba(255, 255, 255, 0.85);
    background-clip: padding-box;
    border: 1px solid rgba(0, 0, 0, 0.1);
    box-shadow: 0 0.25rem 0.75rem rgba(0, 0, 0, 0.1);
    backdrop-filter: blur(10px);
    opacity: 0;
    border-radius: .25rem
}

.toast:not(:last-child) {
    margin-bottom: .75rem
}

.toast.showing {
    opacity: 1
}

.toast.show {
    display: block;
    opacity: 1
}

.toast.hide {
    display: none
}

.toast-header {
    display: flex;
    align-items: center;
    padding: .25rem .75rem;
    color: #7987a1;
    background-color: rgba(255, 255, 255, 0.85);
    background-clip: padding-box;
    border-bottom: 1px solid rgba(0, 0, 0, 0.05)
}

.toast-body {
    padding: .75rem
}

.modal-open {
    overflow: hidden
}

.modal-open .modal {
    overflow-x: hidden;
    overflow-y: auto
}

.modal {
    position: fixed;
    top: 0;
    left: 0;
    z-index: 1050;
    display: none;
    width: 100%;
    height: 100%;
    overflow: hidden;
    outline: 0
}

.modal-dialog {
    position: relative;
    width: auto;
    margin: .5rem;
    pointer-events: none
}

.modal.fade .modal-dialog {
    transition: transform 0.3s ease-out;
    transform: translate(0, -50px)
}

@media (prefers-reduced-motion: reduce) {
    .modal.fade .modal-dialog {
        transition: none
    }
}

.modal.show .modal-dialog {
    transform: none
}

.modal.modal-static .modal-dialog {
    transform: scale(1.02)
}

.modal-dialog-scrollable {
    display: flex;
    max-height: calc(100% - 1rem)
}

.modal-dialog-scrollable .modal-content {
    max-height: calc(100vh - 1rem);
    overflow: hidden
}

.modal-dialog-scrollable .modal-header,
.modal-dialog-scrollable .modal-footer {
    flex-shrink: 0
}

.modal-dialog-scrollable .modal-body {
    overflow-y: auto
}

.modal-dialog-centered {
    display: flex;
    align-items: center;
    min-height: calc(100% - 1rem)
}

.modal-dialog-centered::before {
    display: block;
    height: calc(100vh - 1rem);
    content: ""
}

.modal-dialog-centered.modal-dialog-scrollable {
    flex-direction: column;
    justify-content: center;
    height: 100%
}

.modal-dialog-centered.modal-dialog-scrollable .modal-content {
    max-height: none
}

.modal-dialog-centered.modal-dialog-scrollable::before {
    content: none
}

.modal-content {
    position: relative;
    display: flex;
    flex-direction: column;
    width: 100%;
    pointer-events: auto;
    background-color: #fff;
    background-clip: padding-box;
    border: 1px solid rgba(0, 0, 0, 0.2);
    border-radius: .3rem;
    outline: 0
}

.modal-backdrop {
    position: fixed;
    top: 0;
    left: 0;
    z-index: 1040;
    width: 100vw;
    height: 100vh;
    background-color: #000
}

.modal-backdrop.fade {
    opacity: 0
}

.modal-backdrop.show {
    opacity: .5
}

.modal-header {
    display: flex;
    align-items: flex-start;
    justify-content: space-between;
    padding: 1rem 1rem;
    border-bottom: 1px solid rgba(72, 94, 144, 0.16);
    border-top-left-radius: calc(.3rem - 1px);
    border-top-right-radius: calc(.3rem - 1px)
}

.modal-header .close {
    padding: 1rem 1rem;
    margin: -1rem -1rem -1rem auto
}

.modal-title {
    margin-bottom: 0;
    line-height: 1.5
}

.modal-body {
    position: relative;
    flex: 1 1 auto;
    padding: 1rem
}

.modal-footer {
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    justify-content: flex-end;
    padding: .75rem;
    border-top: 1px solid rgba(72, 94, 144, 0.16);
    border-bottom-right-radius: calc(.3rem - 1px);
    border-bottom-left-radius: calc(.3rem - 1px)
}

.modal-footer>* {
    margin: .25rem
}

.modal-scrollbar-measure {
    position: absolute;
    top: -9999px;
    width: 50px;
    height: 50px;
    overflow: scroll
}

@media (min-width: 576px) {
    .modal-dialog {
        max-width: 500px;
        margin: 1.75rem auto
    }

    .modal-dialog-scrollable {
        max-height: calc(100% - 3.5rem)
    }

    .modal-dialog-scrollable .modal-content {
        max-height: calc(100vh - 3.5rem)
    }

    .modal-dialog-centered {
        min-height: calc(100% - 3.5rem)
    }

    .modal-dialog-centered::before {
        height: calc(100vh - 3.5rem)
    }

    .modal-sm {
        max-width: 300px
    }
}

@media (min-width: 992px) {

    .modal-lg,
    .modal-xl {
        max-width: 800px
    }
}

@media (min-width: 1200px) {
    .modal-xl {
        max-width: 1140px
    }
}

.tooltip {
    position: absolute;
    z-index: 1070;
    display: block;
    margin: 0;
    font-family: "IBM Plex Sans", sans-serif;
    font-style: normal;
    font-weight: 400;
    line-height: 1.5;
    text-align: left;
    text-align: start;
    text-decoration: none;
    text-shadow: none;
    text-transform: none;
    letter-spacing: normal;
    word-break: normal;
    word-spacing: normal;
    white-space: normal;
    line-break: auto;
    font-size: .76562rem;
    word-wrap: break-word;
    opacity: 0
}

.tooltip.show {
    opacity: .9
}

.tooltip .arrow {
    position: absolute;
    display: block;
    width: .8rem;
    height: .4rem
}

.tooltip .arrow::before {
    position: absolute;
    content: "";
    border-color: transparent;
    border-style: solid
}

.bs-tooltip-top,
.bs-tooltip-auto[x-placement^="top"] {
    padding: .4rem 0
}

.bs-tooltip-top .arrow,
.bs-tooltip-auto[x-placement^="top"] .arrow {
    bottom: 0
}

.bs-tooltip-top .arrow::before,
.bs-tooltip-auto[x-placement^="top"] .arrow::before {
    top: 0;
    border-width: .4rem .4rem 0;
    border-top-color: #000
}

.bs-tooltip-right,
.bs-tooltip-auto[x-placement^="right"] {
    padding: 0 .4rem
}

.bs-tooltip-right .arrow,
.bs-tooltip-auto[x-placement^="right"] .arrow {
    left: 0;
    width: .4rem;
    height: .8rem
}

.bs-tooltip-right .arrow::before,
.bs-tooltip-auto[x-placement^="right"] .arrow::before {
    right: 0;
    border-width: .4rem .4rem .4rem 0;
    border-right-color: #000
}

.bs-tooltip-bottom,
.bs-tooltip-auto[x-placement^="bottom"] {
    padding: .4rem 0
}

.bs-tooltip-bottom .arrow,
.bs-tooltip-auto[x-placement^="bottom"] .arrow {
    top: 0
}

.bs-tooltip-bottom .arrow::before,
.bs-tooltip-auto[x-placement^="bottom"] .arrow::before {
    bottom: 0;
    border-width: 0 .4rem .4rem;
    border-bottom-color: #000
}

.bs-tooltip-left,
.bs-tooltip-auto[x-placement^="left"] {
    padding: 0 .4rem
}

.bs-tooltip-left .arrow,
.bs-tooltip-auto[x-placement^="left"] .arrow {
    right: 0;
    width: .4rem;
    height: .8rem
}

.bs-tooltip-left .arrow::before,
.bs-tooltip-auto[x-placement^="left"] .arrow::before {
    left: 0;
    border-width: .4rem 0 .4rem .4rem;
    border-left-color: #000
}

.tooltip-inner {
    max-width: 200px;
    padding: .25rem .5rem;
    color: #fff;
    text-align: center;
    background-color: #000;
    border-radius: .25rem
}

.popover {
    position: absolute;
    top: 0;
    left: 0;
    z-index: 1060;
    display: block;
    max-width: 276px;
    font-family: "IBM Plex Sans", sans-serif;
    font-style: normal;
    font-weight: 400;
    line-height: 1.5;
    text-align: left;
    text-align: start;
    text-decoration: none;
    text-shadow: none;
    text-transform: none;
    letter-spacing: normal;
    word-break: normal;
    word-spacing: normal;
    white-space: normal;
    line-break: auto;
    font-size: .76562rem;
    word-wrap: break-word;
    background-color: #fff;
    background-clip: padding-box;
    border: 1px solid rgba(72, 94, 144, 0.16);
    border-radius: .3rem
}

.popover .arrow {
    position: absolute;
    display: block;
    width: 1rem;
    height: .5rem;
    margin: 0 .3rem
}

.popover .arrow::before,
.popover .arrow::after {
    position: absolute;
    display: block;
    content: "";
    border-color: transparent;
    border-style: solid
}

.bs-popover-top,
.bs-popover-auto[x-placement^="top"] {
    margin-bottom: .5rem
}

.bs-popover-top>.arrow,
.bs-popover-auto[x-placement^="top"]>.arrow {
    bottom: calc(-.5rem - 1px)
}

.bs-popover-top>.arrow::before,
.bs-popover-auto[x-placement^="top"]>.arrow::before {
    bottom: 0;
    border-width: .5rem .5rem 0;
    border-top-color: rgba(72, 94, 144, 0.21)
}

.bs-popover-top>.arrow::after,
.bs-popover-auto[x-placement^="top"]>.arrow::after {
    bottom: 1px;
    border-width: .5rem .5rem 0;
    border-top-color: #fff
}

.bs-popover-right,
.bs-popover-auto[x-placement^="right"] {
    margin-left: .5rem
}

.bs-popover-right>.arrow,
.bs-popover-auto[x-placement^="right"]>.arrow {
    left: calc(-.5rem - 1px);
    width: .5rem;
    height: 1rem;
    margin: .3rem 0
}

.bs-popover-right>.arrow::before,
.bs-popover-auto[x-placement^="right"]>.arrow::before {
    left: 0;
    border-width: .5rem .5rem .5rem 0;
    border-right-color: rgba(72, 94, 144, 0.21)
}

.bs-popover-right>.arrow::after,
.bs-popover-auto[x-placement^="right"]>.arrow::after {
    left: 1px;
    border-width: .5rem .5rem .5rem 0;
    border-right-color: #fff
}

.bs-popover-bottom,
.bs-popover-auto[x-placement^="bottom"] {
    margin-top: .5rem
}

.bs-popover-bottom>.arrow,
.bs-popover-auto[x-placement^="bottom"]>.arrow {
    top: calc(-.5rem - 1px)
}

.bs-popover-bottom>.arrow::before,
.bs-popover-auto[x-placement^="bottom"]>.arrow::before {
    top: 0;
    border-width: 0 .5rem .5rem .5rem;
    border-bottom-color: rgba(72, 94, 144, 0.21)
}

.bs-popover-bottom>.arrow::after,
.bs-popover-auto[x-placement^="bottom"]>.arrow::after {
    top: 1px;
    border-width: 0 .5rem .5rem .5rem;
    border-bottom-color: #fff
}

.bs-popover-bottom .popover-header::before,
.bs-popover-auto[x-placement^="bottom"] .popover-header::before {
    position: absolute;
    top: 0;
    left: 50%;
    display: block;
    width: 1rem;
    margin-left: -.5rem;
    content: "";
    border-bottom: 1px solid #f7f7f7
}

.bs-popover-left,
.bs-popover-auto[x-placement^="left"] {
    margin-right: .5rem
}

.bs-popover-left>.arrow,
.bs-popover-auto[x-placement^="left"]>.arrow {
    right: calc(-.5rem - 1px);
    width: .5rem;
    height: 1rem;
    margin: .3rem 0
}

.bs-popover-left>.arrow::before,
.bs-popover-auto[x-placement^="left"]>.arrow::before {
    right: 0;
    border-width: .5rem 0 .5rem .5rem;
    border-left-color: rgba(72, 94, 144, 0.21)
}

.bs-popover-left>.arrow::after,
.bs-popover-auto[x-placement^="left"]>.arrow::after {
    right: 1px;
    border-width: .5rem 0 .5rem .5rem;
    border-left-color: #fff
}

.popover-header {
    padding: .5rem .75rem;
    margin-bottom: 0;
    font-size: .875rem;
    color: #001737;
    background-color: #f7f7f7;
    border-bottom: 1px solid #ebebeb;
    border-top-left-radius: calc(.3rem - 1px);
    border-top-right-radius: calc(.3rem - 1px)
}

.popover-header:empty {
    display: none
}

.popover-body {
    padding: .5rem .75rem;
    color: #001737
}

.carousel {
    position: relative
}

.carousel.pointer-event {
    touch-action: pan-y
}

.carousel-inner {
    position: relative;
    width: 100%;
    overflow: hidden
}

.carousel-inner::after {
    display: block;
    clear: both;
    content: ""
}

.carousel-item {
    position: relative;
    display: none;
    float: left;
    width: 100%;
    margin-right: -100%;
    backface-visibility: hidden;
    transition: transform .6s ease-in-out
}

@media (prefers-reduced-motion: reduce) {
    .carousel-item {
        transition: none
    }
}

.carousel-item.active,
.carousel-item-next,
.carousel-item-prev {
    display: block
}

.carousel-item-next:not(.carousel-item-left),
.active.carousel-item-right {
    transform: translateX(100%)
}

.carousel-item-prev:not(.carousel-item-right),
.active.carousel-item-left {
    transform: translateX(-100%)
}

.carousel-fade .carousel-item {
    opacity: 0;
    transition-property: opacity;
    transform: none
}

.carousel-fade .carousel-item.active,
.carousel-fade .carousel-item-next.carousel-item-left,
.carousel-fade .carousel-item-prev.carousel-item-right {
    z-index: 1;
    opacity: 1
}

.carousel-fade .active.carousel-item-left,
.carousel-fade .active.carousel-item-right {
    z-index: 0;
    opacity: 0;
    transition: opacity 0s .6s
}

@media (prefers-reduced-motion: reduce) {

    .carousel-fade .active.carousel-item-left,
    .carousel-fade .active.carousel-item-right {
        transition: none
    }
}

.carousel-control-prev,
.carousel-control-next {
    position: absolute;
    top: 0;
    bottom: 0;
    z-index: 1;
    display: flex;
    align-items: center;
    justify-content: center;
    width: 15%;
    color: #fff;
    text-align: center;
    opacity: .5;
    transition: opacity 0.15s ease
}

@media (prefers-reduced-motion: reduce) {

    .carousel-control-prev,
    .carousel-control-next {
        transition: none
    }
}

.carousel-control-prev:hover,
.carousel-control-prev:focus,
.carousel-control-next:hover,
.carousel-control-next:focus {
    color: #fff;
    text-decoration: none;
    outline: 0;
    opacity: .9
}

.carousel-control-prev {
    left: 0
}

.carousel-control-next {
    right: 0
}

.carousel-control-prev-icon,
.carousel-control-next-icon {
    display: inline-block;
    width: 20px;
    height: 20px;
    background: no-repeat 50% / 100% 100%
}

.carousel-control-prev-icon {
    background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' fill='%23fff' width='8' height='8' viewBox='0 0 8 8'%3e%3cpath d='M5.25 0l-4 4 4 4 1.5-1.5L4.25 4l2.5-2.5L5.25 0z'/%3e%3c/svg%3e")
}

.carousel-control-next-icon {
    background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' fill='%23fff' width='8' height='8' viewBox='0 0 8 8'%3e%3cpath d='M2.75 0l-1.5 1.5L3.75 4l-2.5 2.5L2.75 8l4-4-4-4z'/%3e%3c/svg%3e")
}

.carousel-indicators {
    position: absolute;
    right: 0;
    bottom: 0;
    left: 0;
    z-index: 15;
    display: flex;
    justify-content: center;
    padding-left: 0;
    margin-right: 15%;
    margin-left: 15%;
    list-style: none
}

.carousel-indicators li {
    box-sizing: content-box;
    flex: 0 1 auto;
    width: 30px;
    height: 3px;
    margin-right: 3px;
    margin-left: 3px;
    text-indent: -999px;
    cursor: pointer;
    background-color: #fff;
    background-clip: padding-box;
    border-top: 10px solid transparent;
    border-bottom: 10px solid transparent;
    opacity: .5;
    transition: opacity 0.6s ease
}

@media (prefers-reduced-motion: reduce) {
    .carousel-indicators li {
        transition: none
    }
}

.carousel-indicators .active {
    opacity: 1
}

.carousel-caption {
    position: absolute;
    right: 15%;
    bottom: 20px;
    left: 15%;
    z-index: 10;
    padding-top: 20px;
    padding-bottom: 20px;
    color: #fff;
    text-align: center
}

@keyframes spinner-border {
    to {
        transform: rotate(360deg)
    }
}

.spinner-border {
    display: inline-block;
    width: 2rem;
    height: 2rem;
    vertical-align: text-bottom;
    border: .25em solid currentColor;
    border-right-color: transparent;
    border-radius: 50%;
    animation: spinner-border .75s linear infinite
}

.spinner-border-sm {
    width: 1rem;
    height: 1rem;
    border-width: .2em
}

@keyframes spinner-grow {
    0% {
        transform: scale(0)
    }

    50% {
        opacity: 1
    }
}

.spinner-grow {
    display: inline-block;
    width: 2rem;
    height: 2rem;
    vertical-align: text-bottom;
    background-color: currentColor;
    border-radius: 50%;
    opacity: 0;
    animation: spinner-grow .75s linear infinite
}

.spinner-grow-sm {
    width: 1rem;
    height: 1rem
}

.align-baseline {
    vertical-align: baseline !important
}

.align-top {
    vertical-align: top !important
}

.align-middle {
    vertical-align: middle !important
}

.align-bottom {
    vertical-align: bottom !important
}

.align-text-bottom {
    vertical-align: text-bottom !important
}

.align-text-top {
    vertical-align: text-top !important
}

.bg-primary {
    background-color: #0168fa !important
}

a.bg-primary:hover,
a.bg-primary:focus,
button.bg-primary:hover,
button.bg-primary:focus {
    background-color: #0153c7 !important
}

.bg-secondary {
    background-color: #7987a1 !important
}

a.bg-secondary:hover,
a.bg-secondary:focus,
button.bg-secondary:hover,
button.bg-secondary:focus {
    background-color: #5f6d88 !important
}

.bg-success {
    background-color: #10b759 !important
}

a.bg-success:hover,
a.bg-success:focus,
button.bg-success:hover,
button.bg-success:focus {
    background-color: #0c8842 !important
}

.bg-info {
    background-color: #00b8d4 !important
}

a.bg-info:hover,
a.bg-info:focus,
button.bg-info:hover,
button.bg-info:focus {
    background-color: #008ca1 !important
}

.bg-warning {
    background-color: #ffc107 !important
}

a.bg-warning:hover,
a.bg-warning:focus,
button.bg-warning:hover,
button.bg-warning:focus {
    background-color: #d39e00 !important
}

.bg-danger {
    background-color: #dc3545 !important
}

a.bg-danger:hover,
a.bg-danger:focus,
button.bg-danger:hover,
button.bg-danger:focus {
    background-color: #bd2130 !important
}

.bg-light {
    background-color: #f4f5f8 !important
}

a.bg-light:hover,
a.bg-light:focus,
button.bg-light:hover,
button.bg-light:focus {
    background-color: #d5d9e4 !important
}

.bg-dark {
    background-color: #3b4863 !important
}

a.bg-dark:hover,
a.bg-dark:focus,
button.bg-dark:hover,
button.bg-dark:focus {
    background-color: #283143 !important
}

.bg-white {
    background-color: #fff !important
}

.bg-transparent {
    background-color: transparent !important
}

.border {
    border: 1px solid rgba(72, 94, 144, 0.16) !important
}

.border-top {
    border-top: 1px solid rgba(72, 94, 144, 0.16) !important
}

.border-right {
    border-right: 1px solid rgba(72, 94, 144, 0.16) !important
}

.border-bottom {
    border-bottom: 1px solid rgba(72, 94, 144, 0.16) !important
}

.border-left {
    border-left: 1px solid rgba(72, 94, 144, 0.16) !important
}

.border-0 {
    border: 0 !important
}

.border-top-0 {
    border-top: 0 !important
}

.border-right-0 {
    border-right: 0 !important
}

.border-bottom-0 {
    border-bottom: 0 !important
}

.border-left-0 {
    border-left: 0 !important
}

.border-primary {
    border-color: #0168fa !important
}

.border-secondary {
    border-color: #7987a1 !important
}

.border-success {
    border-color: #10b759 !important
}

.border-info {
    border-color: #00b8d4 !important
}

.border-warning {
    border-color: #ffc107 !important
}

.border-danger {
    border-color: #dc3545 !important
}

.border-light {
    border-color: #f4f5f8 !important
}

.border-dark {
    border-color: #3b4863 !important
}

.border-white {
    border-color: #fff !important
}

.rounded-sm {
    border-radius: .2rem !important
}

.rounded {
    border-radius: .25rem !important
}

.rounded-top {
    border-top-left-radius: .25rem !important;
    border-top-right-radius: .25rem !important
}

.rounded-right {
    border-top-right-radius: .25rem !important;
    border-bottom-right-radius: .25rem !important
}

.rounded-bottom {
    border-bottom-right-radius: .25rem !important;
    border-bottom-left-radius: .25rem !important
}

.rounded-left {
    border-top-left-radius: .25rem !important;
    border-bottom-left-radius: .25rem !important
}

.rounded-lg {
    border-radius: .3rem !important
}

.rounded-circle {
    border-radius: 50% !important
}

.rounded-pill {
    border-radius: 50rem !important
}

.rounded-0 {
    border-radius: 0 !important
}

.clearfix::after {
    display: block;
    clear: both;
    content: ""
}

.d-none {
    display: none !important
}

.d-inline {
    display: inline !important
}

.d-inline-block {
    display: inline-block !important
}

.d-block {
    display: block !important
}

.d-table {
    display: table !important
}

.d-table-row {
    display: table-row !important
}

.d-table-cell {
    display: table-cell !important
}

.d-flex {
    display: flex !important
}

.d-inline-flex {
    display: inline-flex !important
}

@media (min-width: 576px) {
    .d-sm-none {
        display: none !important
    }

    .d-sm-inline {
        display: inline !important
    }

    .d-sm-inline-block {
        display: inline-block !important
    }

    .d-sm-block {
        display: block !important
    }

    .d-sm-table {
        display: table !important
    }

    .d-sm-table-row {
        display: table-row !important
    }

    .d-sm-table-cell {
        display: table-cell !important
    }

    .d-sm-flex {
        display: flex !important
    }

    .d-sm-inline-flex {
        display: inline-flex !important
    }
}

@media (min-width: 768px) {
    .d-md-none {
        display: none !important
    }

    .d-md-inline {
        display: inline !important
    }

    .d-md-inline-block {
        display: inline-block !important
    }

    .d-md-block {
        display: block !important
    }

    .d-md-table {
        display: table !important
    }

    .d-md-table-row {
        display: table-row !important
    }

    .d-md-table-cell {
        display: table-cell !important
    }

    .d-md-flex {
        display: flex !important
    }

    .d-md-inline-flex {
        display: inline-flex !important
    }
}

@media (min-width: 992px) {
    .d-lg-none {
        display: none !important
    }

    .d-lg-inline {
        display: inline !important
    }

    .d-lg-inline-block {
        display: inline-block !important
    }

    .d-lg-block {
        display: block !important
    }

    .d-lg-table {
        display: table !important
    }

    .d-lg-table-row {
        display: table-row !important
    }

    .d-lg-table-cell {
        display: table-cell !important
    }

    .d-lg-flex {
        display: flex !important
    }

    .d-lg-inline-flex {
        display: inline-flex !important
    }
}

@media (min-width: 1200px) {
    .d-xl-none {
        display: none !important
    }

    .d-xl-inline {
        display: inline !important
    }

    .d-xl-inline-block {
        display: inline-block !important
    }

    .d-xl-block {
        display: block !important
    }

    .d-xl-table {
        display: table !important
    }

    .d-xl-table-row {
        display: table-row !important
    }

    .d-xl-table-cell {
        display: table-cell !important
    }

    .d-xl-flex {
        display: flex !important
    }

    .d-xl-inline-flex {
        display: inline-flex !important
    }
}

@media print {
    .d-print-none {
        display: none !important
    }

    .d-print-inline {
        display: inline !important
    }

    .d-print-inline-block {
        display: inline-block !important
    }

    .d-print-block {
        display: block !important
    }

    .d-print-table {
        display: table !important
    }

    .d-print-table-row {
        display: table-row !important
    }

    .d-print-table-cell {
        display: table-cell !important
    }

    .d-print-flex {
        display: flex !important
    }

    .d-print-inline-flex {
        display: inline-flex !important
    }
}

.embed-responsive {
    position: relative;
    display: block;
    width: 100%;
    padding: 0;
    overflow: hidden
}

.embed-responsive::before {
    display: block;
    content: ""
}

.embed-responsive .embed-responsive-item,
.embed-responsive iframe,
.embed-responsive embed,
.embed-responsive object,
.embed-responsive video {
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    width: 100%;
    height: 100%;
    border: 0
}

.embed-responsive-21by9::before {
    padding-top: 42.85714%
}

.embed-responsive-16by9::before {
    padding-top: 56.25%
}

.embed-responsive-4by3::before {
    padding-top: 75%
}

.embed-responsive-1by1::before {
    padding-top: 100%
}

.flex-row {
    flex-direction: row !important
}

.flex-column {
    flex-direction: column !important
}

.flex-row-reverse {
    flex-direction: row-reverse !important
}

.flex-column-reverse {
    flex-direction: column-reverse !important
}

.flex-wrap {
    flex-wrap: wrap !important
}

.flex-nowrap {
    flex-wrap: nowrap !important
}

.flex-wrap-reverse {
    flex-wrap: wrap-reverse !important
}

.flex-fill {
    flex: 1 1 auto !important
}

.flex-grow-0 {
    flex-grow: 0 !important
}

.flex-grow-1 {
    flex-grow: 1 !important
}

.flex-shrink-0 {
    flex-shrink: 0 !important
}

.flex-shrink-1 {
    flex-shrink: 1 !important
}

.justify-content-start {
    justify-content: flex-start !important
}

.justify-content-end {
    justify-content: flex-end !important
}

.justify-content-center {
    justify-content: center !important
}

.justify-content-between {
    justify-content: space-between !important
}

.justify-content-around {
    justify-content: space-around !important
}

.align-items-start {
    align-items: flex-start !important
}

.align-items-end {
    align-items: flex-end !important
}

.align-items-center {
    align-items: center !important
}

.align-items-baseline {
    align-items: baseline !important
}

.align-items-stretch {
    align-items: stretch !important
}

.align-content-start {
    align-content: flex-start !important
}

.align-content-end {
    align-content: flex-end !important
}

.align-content-center {
    align-content: center !important
}

.align-content-between {
    align-content: space-between !important
}

.align-content-around {
    align-content: space-around !important
}

.align-content-stretch {
    align-content: stretch !important
}

.align-self-auto {
    align-self: auto !important
}

.align-self-start {
    align-self: flex-start !important
}

.align-self-end {
    align-self: flex-end !important
}

.align-self-center {
    align-self: center !important
}

.align-self-baseline {
    align-self: baseline !important
}

.align-self-stretch {
    align-self: stretch !important
}

@media (min-width: 576px) {
    .flex-sm-row {
        flex-direction: row !important
    }

    .flex-sm-column {
        flex-direction: column !important
    }

    .flex-sm-row-reverse {
        flex-direction: row-reverse !important
    }

    .flex-sm-column-reverse {
        flex-direction: column-reverse !important
    }

    .flex-sm-wrap {
        flex-wrap: wrap !important
    }

    .flex-sm-nowrap {
        flex-wrap: nowrap !important
    }

    .flex-sm-wrap-reverse {
        flex-wrap: wrap-reverse !important
    }

    .flex-sm-fill {
        flex: 1 1 auto !important
    }

    .flex-sm-grow-0 {
        flex-grow: 0 !important
    }

    .flex-sm-grow-1 {
        flex-grow: 1 !important
    }

    .flex-sm-shrink-0 {
        flex-shrink: 0 !important
    }

    .flex-sm-shrink-1 {
        flex-shrink: 1 !important
    }

    .justify-content-sm-start {
        justify-content: flex-start !important
    }

    .justify-content-sm-end {
        justify-content: flex-end !important
    }

    .justify-content-sm-center {
        justify-content: center !important
    }

    .justify-content-sm-between {
        justify-content: space-between !important
    }

    .justify-content-sm-around {
        justify-content: space-around !important
    }

    .align-items-sm-start {
        align-items: flex-start !important
    }

    .align-items-sm-end {
        align-items: flex-end !important
    }

    .align-items-sm-center {
        align-items: center !important
    }

    .align-items-sm-baseline {
        align-items: baseline !important
    }

    .align-items-sm-stretch {
        align-items: stretch !important
    }

    .align-content-sm-start {
        align-content: flex-start !important
    }

    .align-content-sm-end {
        align-content: flex-end !important
    }

    .align-content-sm-center {
        align-content: center !important
    }

    .align-content-sm-between {
        align-content: space-between !important
    }

    .align-content-sm-around {
        align-content: space-around !important
    }

    .align-content-sm-stretch {
        align-content: stretch !important
    }

    .align-self-sm-auto {
        align-self: auto !important
    }

    .align-self-sm-start {
        align-self: flex-start !important
    }

    .align-self-sm-end {
        align-self: flex-end !important
    }

    .align-self-sm-center {
        align-self: center !important
    }

    .align-self-sm-baseline {
        align-self: baseline !important
    }

    .align-self-sm-stretch {
        align-self: stretch !important
    }
}

@media (min-width: 768px) {
    .flex-md-row {
        flex-direction: row !important
    }

    .flex-md-column {
        flex-direction: column !important
    }

    .flex-md-row-reverse {
        flex-direction: row-reverse !important
    }

    .flex-md-column-reverse {
        flex-direction: column-reverse !important
    }

    .flex-md-wrap {
        flex-wrap: wrap !important
    }

    .flex-md-nowrap {
        flex-wrap: nowrap !important
    }

    .flex-md-wrap-reverse {
        flex-wrap: wrap-reverse !important
    }

    .flex-md-fill {
        flex: 1 1 auto !important
    }

    .flex-md-grow-0 {
        flex-grow: 0 !important
    }

    .flex-md-grow-1 {
        flex-grow: 1 !important
    }

    .flex-md-shrink-0 {
        flex-shrink: 0 !important
    }

    .flex-md-shrink-1 {
        flex-shrink: 1 !important
    }

    .justify-content-md-start {
        justify-content: flex-start !important
    }

    .justify-content-md-end {
        justify-content: flex-end !important
    }

    .justify-content-md-center {
        justify-content: center !important
    }

    .justify-content-md-between {
        justify-content: space-between !important
    }

    .justify-content-md-around {
        justify-content: space-around !important
    }

    .align-items-md-start {
        align-items: flex-start !important
    }

    .align-items-md-end {
        align-items: flex-end !important
    }

    .align-items-md-center {
        align-items: center !important
    }

    .align-items-md-baseline {
        align-items: baseline !important
    }

    .align-items-md-stretch {
        align-items: stretch !important
    }

    .align-content-md-start {
        align-content: flex-start !important
    }

    .align-content-md-end {
        align-content: flex-end !important
    }

    .align-content-md-center {
        align-content: center !important
    }

    .align-content-md-between {
        align-content: space-between !important
    }

    .align-content-md-around {
        align-content: space-around !important
    }

    .align-content-md-stretch {
        align-content: stretch !important
    }

    .align-self-md-auto {
        align-self: auto !important
    }

    .align-self-md-start {
        align-self: flex-start !important
    }

    .align-self-md-end {
        align-self: flex-end !important
    }

    .align-self-md-center {
        align-self: center !important
    }

    .align-self-md-baseline {
        align-self: baseline !important
    }

    .align-self-md-stretch {
        align-self: stretch !important
    }
}

@media (min-width: 992px) {
    .flex-lg-row {
        flex-direction: row !important
    }

    .flex-lg-column {
        flex-direction: column !important
    }

    .flex-lg-row-reverse {
        flex-direction: row-reverse !important
    }

    .flex-lg-column-reverse {
        flex-direction: column-reverse !important
    }

    .flex-lg-wrap {
        flex-wrap: wrap !important
    }

    .flex-lg-nowrap {
        flex-wrap: nowrap !important
    }

    .flex-lg-wrap-reverse {
        flex-wrap: wrap-reverse !important
    }

    .flex-lg-fill {
        flex: 1 1 auto !important
    }

    .flex-lg-grow-0 {
        flex-grow: 0 !important
    }

    .flex-lg-grow-1 {
        flex-grow: 1 !important
    }

    .flex-lg-shrink-0 {
        flex-shrink: 0 !important
    }

    .flex-lg-shrink-1 {
        flex-shrink: 1 !important
    }

    .justify-content-lg-start {
        justify-content: flex-start !important
    }

    .justify-content-lg-end {
        justify-content: flex-end !important
    }

    .justify-content-lg-center {
        justify-content: center !important
    }

    .justify-content-lg-between {
        justify-content: space-between !important
    }

    .justify-content-lg-around {
        justify-content: space-around !important
    }

    .align-items-lg-start {
        align-items: flex-start !important
    }

    .align-items-lg-end {
        align-items: flex-end !important
    }

    .align-items-lg-center {
        align-items: center !important
    }

    .align-items-lg-baseline {
        align-items: baseline !important
    }

    .align-items-lg-stretch {
        align-items: stretch !important
    }

    .align-content-lg-start {
        align-content: flex-start !important
    }

    .align-content-lg-end {
        align-content: flex-end !important
    }

    .align-content-lg-center {
        align-content: center !important
    }

    .align-content-lg-between {
        align-content: space-between !important
    }

    .align-content-lg-around {
        align-content: space-around !important
    }

    .align-content-lg-stretch {
        align-content: stretch !important
    }

    .align-self-lg-auto {
        align-self: auto !important
    }

    .align-self-lg-start {
        align-self: flex-start !important
    }

    .align-self-lg-end {
        align-self: flex-end !important
    }

    .align-self-lg-center {
        align-self: center !important
    }

    .align-self-lg-baseline {
        align-self: baseline !important
    }

    .align-self-lg-stretch {
        align-self: stretch !important
    }
}

@media (min-width: 1200px) {
    .flex-xl-row {
        flex-direction: row !important
    }

    .flex-xl-column {
        flex-direction: column !important
    }

    .flex-xl-row-reverse {
        flex-direction: row-reverse !important
    }

    .flex-xl-column-reverse {
        flex-direction: column-reverse !important
    }

    .flex-xl-wrap {
        flex-wrap: wrap !important
    }

    .flex-xl-nowrap {
        flex-wrap: nowrap !important
    }

    .flex-xl-wrap-reverse {
        flex-wrap: wrap-reverse !important
    }

    .flex-xl-fill {
        flex: 1 1 auto !important
    }

    .flex-xl-grow-0 {
        flex-grow: 0 !important
    }

    .flex-xl-grow-1 {
        flex-grow: 1 !important
    }

    .flex-xl-shrink-0 {
        flex-shrink: 0 !important
    }

    .flex-xl-shrink-1 {
        flex-shrink: 1 !important
    }

    .justify-content-xl-start {
        justify-content: flex-start !important
    }

    .justify-content-xl-end {
        justify-content: flex-end !important
    }

    .justify-content-xl-center {
        justify-content: center !important
    }

    .justify-content-xl-between {
        justify-content: space-between !important
    }

    .justify-content-xl-around {
        justify-content: space-around !important
    }

    .align-items-xl-start {
        align-items: flex-start !important
    }

    .align-items-xl-end {
        align-items: flex-end !important
    }

    .align-items-xl-center {
        align-items: center !important
    }

    .align-items-xl-baseline {
        align-items: baseline !important
    }

    .align-items-xl-stretch {
        align-items: stretch !important
    }

    .align-content-xl-start {
        align-content: flex-start !important
    }

    .align-content-xl-end {
        align-content: flex-end !important
    }

    .align-content-xl-center {
        align-content: center !important
    }

    .align-content-xl-between {
        align-content: space-between !important
    }

    .align-content-xl-around {
        align-content: space-around !important
    }

    .align-content-xl-stretch {
        align-content: stretch !important
    }

    .align-self-xl-auto {
        align-self: auto !important
    }

    .align-self-xl-start {
        align-self: flex-start !important
    }

    .align-self-xl-end {
        align-self: flex-end !important
    }

    .align-self-xl-center {
        align-self: center !important
    }

    .align-self-xl-baseline {
        align-self: baseline !important
    }

    .align-self-xl-stretch {
        align-self: stretch !important
    }
}

.float-left {
    float: left !important
}

.float-right {
    float: right !important
}

.float-none {
    float: none !important
}

@media (min-width: 576px) {
    .float-sm-left {
        float: left !important
    }

    .float-sm-right {
        float: right !important
    }

    .float-sm-none {
        float: none !important
    }
}

@media (min-width: 768px) {
    .float-md-left {
        float: left !important
    }

    .float-md-right {
        float: right !important
    }

    .float-md-none {
        float: none !important
    }
}

@media (min-width: 992px) {
    .float-lg-left {
        float: left !important
    }

    .float-lg-right {
        float: right !important
    }

    .float-lg-none {
        float: none !important
    }
}

@media (min-width: 1200px) {
    .float-xl-left {
        float: left !important
    }

    .float-xl-right {
        float: right !important
    }

    .float-xl-none {
        float: none !important
    }
}

.overflow-auto {
    overflow: auto !important
}

.overflow-hidden {
    overflow: hidden !important
}

.position-static {
    position: static !important
}

.position-relative {
    position: relative !important
}

.position-absolute {
    position: absolute !important
}

.position-fixed {
    position: fixed !important
}

.position-sticky {
    position: sticky !important
}

.fixed-top {
    position: fixed;
    top: 0;
    right: 0;
    left: 0;
    z-index: 1030
}

.fixed-bottom {
    position: fixed;
    right: 0;
    bottom: 0;
    left: 0;
    z-index: 1030
}

@supports (position: sticky) {
    .sticky-top {
        position: sticky;
        top: 0;
        z-index: 1020
    }
}

.sr-only {
    position: absolute;
    width: 1px;
    height: 1px;
    padding: 0;
    margin: -1px;
    overflow: hidden;
    clip: rect(0, 0, 0, 0);
    white-space: nowrap;
    border: 0
}

.sr-only-focusable:active,
.sr-only-focusable:focus {
    position: static;
    width: auto;
    height: auto;
    overflow: visible;
    clip: auto;
    white-space: normal
}

.shadow-sm {
    box-shadow: 0 0.125rem 0.25rem rgba(0, 0, 0, 0.075) !important
}

.shadow {
    box-shadow: 0 0.5rem 1rem rgba(0, 0, 0, 0.15) !important
}

.shadow-lg {
    box-shadow: 0 1rem 3rem rgba(0, 0, 0, 0.175) !important
}

.shadow-none {
    box-shadow: none !important
}

.w-25 {
    width: 25% !important
}

.w-50 {
    width: 50% !important
}

.w-75 {
    width: 75% !important
}

.w-100 {
    width: 100% !important
}

.w-auto {
    width: auto !important
}

.h-25 {
    height: 25% !important
}

.h-50 {
    height: 50% !important
}

.h-75 {
    height: 75% !important
}

.h-100 {
    height: 100% !important
}

.h-auto {
    height: auto !important
}

.mw-100 {
    max-width: 100% !important
}

.mh-100 {
    max-height: 100% !important
}

.min-vw-100 {
    min-width: 100vw !important
}

.min-vh-100 {
    min-height: 100vh !important
}

.vw-100 {
    width: 100vw !important
}

.vh-100 {
    height: 100vh !important
}

.stretched-link::after {
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    z-index: 1;
    pointer-events: auto;
    content: "";
    background-color: rgba(0, 0, 0, 0)
}

.m-0 {
    margin: 0 !important
}

.mt-0,
.my-0 {
    margin-top: 0 !important
}

.mr-0,
.mx-0 {
    margin-right: 0 !important
}

.mb-0,
.my-0 {
    margin-bottom: 0 !important
}

.ml-0,
.mx-0 {
    margin-left: 0 !important
}

.m-1 {
    margin: .25rem !important
}

.mt-1,
.my-1 {
    margin-top: .25rem !important
}

.mr-1,
.mx-1 {
    margin-right: .25rem !important
}

.mb-1,
.my-1 {
    margin-bottom: .25rem !important
}

.ml-1,
.mx-1 {
    margin-left: .25rem !important
}

.m-2 {
    margin: .5rem !important
}

.mt-2,
.my-2 {
    margin-top: .5rem !important
}

.mr-2,
.mx-2 {
    margin-right: .5rem !important
}

.mb-2,
.my-2 {
    margin-bottom: .5rem !important
}

.ml-2,
.mx-2 {
    margin-left: .5rem !important
}

.m-3 {
    margin: 1rem !important
}

.mt-3,
.my-3 {
    margin-top: 1rem !important
}

.mr-3,
.mx-3 {
    margin-right: 1rem !important
}

.mb-3,
.my-3 {
    margin-bottom: 1rem !important
}

.ml-3,
.mx-3 {
    margin-left: 1rem !important
}

.m-4 {
    margin: 1.5rem !important
}

.mt-4,
.my-4 {
    margin-top: 1.5rem !important
}

.mr-4,
.mx-4 {
    margin-right: 1.5rem !important
}

.mb-4,
.my-4 {
    margin-bottom: 1.5rem !important
}

.ml-4,
.mx-4 {
    margin-left: 1.5rem !important
}

.m-5 {
    margin: 3rem !important
}

.mt-5,
.my-5 {
    margin-top: 3rem !important
}

.mr-5,
.mx-5 {
    margin-right: 3rem !important
}

.mb-5,
.my-5 {
    margin-bottom: 3rem !important
}

.ml-5,
.mx-5 {
    margin-left: 3rem !important
}

.p-0 {
    padding: 0 !important
}

.pt-0,
.py-0 {
    padding-top: 0 !important
}

.pr-0,
.px-0 {
    padding-right: 0 !important
}

.pb-0,
.py-0 {
    padding-bottom: 0 !important
}

.pl-0,
.px-0 {
    padding-left: 0 !important
}

.p-1 {
    padding: .25rem !important
}

.pt-1,
.py-1 {
    padding-top: .25rem !important
}

.pr-1,
.px-1 {
    padding-right: .25rem !important
}

.pb-1,
.py-1 {
    padding-bottom: .25rem !important
}

.pl-1,
.px-1 {
    padding-left: .25rem !important
}

.p-2 {
    padding: .5rem !important
}

.pt-2,
.py-2 {
    padding-top: .5rem !important
}

.pr-2,
.px-2 {
    padding-right: .5rem !important
}

.pb-2,
.py-2 {
    padding-bottom: .5rem !important
}

.pl-2,
.px-2 {
    padding-left: .5rem !important
}

.p-3 {
    padding: 1rem !important
}

.pt-3,
.py-3 {
    padding-top: 1rem !important
}

.pr-3,
.px-3 {
    padding-right: 1rem !important
}

.pb-3,
.py-3 {
    padding-bottom: 1rem !important
}

.pl-3,
.px-3 {
    padding-left: 1rem !important
}

.p-4 {
    padding: 1.5rem !important
}

.pt-4,
.py-4 {
    padding-top: 1.5rem !important
}

.pr-4,
.px-4 {
    padding-right: 1.5rem !important
}

.pb-4,
.py-4 {
    padding-bottom: 1.5rem !important
}

.pl-4,
.px-4 {
    padding-left: 1.5rem !important
}

.p-5 {
    padding: 3rem !important
}

.pt-5,
.py-5 {
    padding-top: 3rem !important
}

.pr-5,
.px-5 {
    padding-right: 3rem !important
}

.pb-5,
.py-5 {
    padding-bottom: 3rem !important
}

.pl-5,
.px-5 {
    padding-left: 3rem !important
}

.m-n1 {
    margin: -.25rem !important
}

.mt-n1,
.my-n1 {
    margin-top: -.25rem !important
}

.mr-n1,
.mx-n1 {
    margin-right: -.25rem !important
}

.mb-n1,
.my-n1 {
    margin-bottom: -.25rem !important
}

.ml-n1,
.mx-n1 {
    margin-left: -.25rem !important
}

.m-n2 {
    margin: -.5rem !important
}

.mt-n2,
.my-n2 {
    margin-top: -.5rem !important
}

.mr-n2,
.mx-n2 {
    margin-right: -.5rem !important
}

.mb-n2,
.my-n2 {
    margin-bottom: -.5rem !important
}

.ml-n2,
.mx-n2 {
    margin-left: -.5rem !important
}

.m-n3 {
    margin: -1rem !important
}

.mt-n3,
.my-n3 {
    margin-top: -1rem !important
}

.mr-n3,
.mx-n3 {
    margin-right: -1rem !important
}

.mb-n3,
.my-n3 {
    margin-bottom: -1rem !important
}

.ml-n3,
.mx-n3 {
    margin-left: -1rem !important
}

.m-n4 {
    margin: -1.5rem !important
}

.mt-n4,
.my-n4 {
    margin-top: -1.5rem !important
}

.mr-n4,
.mx-n4 {
    margin-right: -1.5rem !important
}

.mb-n4,
.my-n4 {
    margin-bottom: -1.5rem !important
}

.ml-n4,
.mx-n4 {
    margin-left: -1.5rem !important
}

.m-n5 {
    margin: -3rem !important
}

.mt-n5,
.my-n5 {
    margin-top: -3rem !important
}

.mr-n5,
.mx-n5 {
    margin-right: -3rem !important
}

.mb-n5,
.my-n5 {
    margin-bottom: -3rem !important
}

.ml-n5,
.mx-n5 {
    margin-left: -3rem !important
}

.m-auto {
    margin: auto !important
}

.mt-auto,
.my-auto {
    margin-top: auto !important
}

.mr-auto,
.mx-auto {
    margin-right: auto !important
}

.mb-auto,
.my-auto {
    margin-bottom: auto !important
}

.ml-auto,
.mx-auto {
    margin-left: auto !important
}

@media (min-width: 576px) {
    .m-sm-0 {
        margin: 0 !important
    }

    .mt-sm-0,
    .my-sm-0 {
        margin-top: 0 !important
    }

    .mr-sm-0,
    .mx-sm-0 {
        margin-right: 0 !important
    }

    .mb-sm-0,
    .my-sm-0 {
        margin-bottom: 0 !important
    }

    .ml-sm-0,
    .mx-sm-0 {
        margin-left: 0 !important
    }

    .m-sm-1 {
        margin: .25rem !important
    }

    .mt-sm-1,
    .my-sm-1 {
        margin-top: .25rem !important
    }

    .mr-sm-1,
    .mx-sm-1 {
        margin-right: .25rem !important
    }

    .mb-sm-1,
    .my-sm-1 {
        margin-bottom: .25rem !important
    }

    .ml-sm-1,
    .mx-sm-1 {
        margin-left: .25rem !important
    }

    .m-sm-2 {
        margin: .5rem !important
    }

    .mt-sm-2,
    .my-sm-2 {
        margin-top: .5rem !important
    }

    .mr-sm-2,
    .mx-sm-2 {
        margin-right: .5rem !important
    }

    .mb-sm-2,
    .my-sm-2 {
        margin-bottom: .5rem !important
    }

    .ml-sm-2,
    .mx-sm-2 {
        margin-left: .5rem !important
    }

    .m-sm-3 {
        margin: 1rem !important
    }

    .mt-sm-3,
    .my-sm-3 {
        margin-top: 1rem !important
    }

    .mr-sm-3,
    .mx-sm-3 {
        margin-right: 1rem !important
    }

    .mb-sm-3,
    .my-sm-3 {
        margin-bottom: 1rem !important
    }

    .ml-sm-3,
    .mx-sm-3 {
        margin-left: 1rem !important
    }

    .m-sm-4 {
        margin: 1.5rem !important
    }

    .mt-sm-4,
    .my-sm-4 {
        margin-top: 1.5rem !important
    }

    .mr-sm-4,
    .mx-sm-4 {
        margin-right: 1.5rem !important
    }

    .mb-sm-4,
    .my-sm-4 {
        margin-bottom: 1.5rem !important
    }

    .ml-sm-4,
    .mx-sm-4 {
        margin-left: 1.5rem !important
    }

    .m-sm-5 {
        margin: 3rem !important
    }

    .mt-sm-5,
    .my-sm-5 {
        margin-top: 3rem !important
    }

    .mr-sm-5,
    .mx-sm-5 {
        margin-right: 3rem !important
    }

    .mb-sm-5,
    .my-sm-5 {
        margin-bottom: 3rem !important
    }

    .ml-sm-5,
    .mx-sm-5 {
        margin-left: 3rem !important
    }

    .p-sm-0 {
        padding: 0 !important
    }

    .pt-sm-0,
    .py-sm-0 {
        padding-top: 0 !important
    }

    .pr-sm-0,
    .px-sm-0 {
        padding-right: 0 !important
    }

    .pb-sm-0,
    .py-sm-0 {
        padding-bottom: 0 !important
    }

    .pl-sm-0,
    .px-sm-0 {
        padding-left: 0 !important
    }

    .p-sm-1 {
        padding: .25rem !important
    }

    .pt-sm-1,
    .py-sm-1 {
        padding-top: .25rem !important
    }

    .pr-sm-1,
    .px-sm-1 {
        padding-right: .25rem !important
    }

    .pb-sm-1,
    .py-sm-1 {
        padding-bottom: .25rem !important
    }

    .pl-sm-1,
    .px-sm-1 {
        padding-left: .25rem !important
    }

    .p-sm-2 {
        padding: .5rem !important
    }

    .pt-sm-2,
    .py-sm-2 {
        padding-top: .5rem !important
    }

    .pr-sm-2,
    .px-sm-2 {
        padding-right: .5rem !important
    }

    .pb-sm-2,
    .py-sm-2 {
        padding-bottom: .5rem !important
    }

    .pl-sm-2,
    .px-sm-2 {
        padding-left: .5rem !important
    }

    .p-sm-3 {
        padding: 1rem !important
    }

    .pt-sm-3,
    .py-sm-3 {
        padding-top: 1rem !important
    }

    .pr-sm-3,
    .px-sm-3 {
        padding-right: 1rem !important
    }

    .pb-sm-3,
    .py-sm-3 {
        padding-bottom: 1rem !important
    }

    .pl-sm-3,
    .px-sm-3 {
        padding-left: 1rem !important
    }

    .p-sm-4 {
        padding: 1.5rem !important
    }

    .pt-sm-4,
    .py-sm-4 {
        padding-top: 1.5rem !important
    }

    .pr-sm-4,
    .px-sm-4 {
        padding-right: 1.5rem !important
    }

    .pb-sm-4,
    .py-sm-4 {
        padding-bottom: 1.5rem !important
    }

    .pl-sm-4,
    .px-sm-4 {
        padding-left: 1.5rem !important
    }

    .p-sm-5 {
        padding: 3rem !important
    }

    .pt-sm-5,
    .py-sm-5 {
        padding-top: 3rem !important
    }

    .pr-sm-5,
    .px-sm-5 {
        padding-right: 3rem !important
    }

    .pb-sm-5,
    .py-sm-5 {
        padding-bottom: 3rem !important
    }

    .pl-sm-5,
    .px-sm-5 {
        padding-left: 3rem !important
    }

    .m-sm-n1 {
        margin: -.25rem !important
    }

    .mt-sm-n1,
    .my-sm-n1 {
        margin-top: -.25rem !important
    }

    .mr-sm-n1,
    .mx-sm-n1 {
        margin-right: -.25rem !important
    }

    .mb-sm-n1,
    .my-sm-n1 {
        margin-bottom: -.25rem !important
    }

    .ml-sm-n1,
    .mx-sm-n1 {
        margin-left: -.25rem !important
    }

    .m-sm-n2 {
        margin: -.5rem !important
    }

    .mt-sm-n2,
    .my-sm-n2 {
        margin-top: -.5rem !important
    }

    .mr-sm-n2,
    .mx-sm-n2 {
        margin-right: -.5rem !important
    }

    .mb-sm-n2,
    .my-sm-n2 {
        margin-bottom: -.5rem !important
    }

    .ml-sm-n2,
    .mx-sm-n2 {
        margin-left: -.5rem !important
    }

    .m-sm-n3 {
        margin: -1rem !important
    }

    .mt-sm-n3,
    .my-sm-n3 {
        margin-top: -1rem !important
    }

    .mr-sm-n3,
    .mx-sm-n3 {
        margin-right: -1rem !important
    }

    .mb-sm-n3,
    .my-sm-n3 {
        margin-bottom: -1rem !important
    }

    .ml-sm-n3,
    .mx-sm-n3 {
        margin-left: -1rem !important
    }

    .m-sm-n4 {
        margin: -1.5rem !important
    }

    .mt-sm-n4,
    .my-sm-n4 {
        margin-top: -1.5rem !important
    }

    .mr-sm-n4,
    .mx-sm-n4 {
        margin-right: -1.5rem !important
    }

    .mb-sm-n4,
    .my-sm-n4 {
        margin-bottom: -1.5rem !important
    }

    .ml-sm-n4,
    .mx-sm-n4 {
        margin-left: -1.5rem !important
    }

    .m-sm-n5 {
        margin: -3rem !important
    }

    .mt-sm-n5,
    .my-sm-n5 {
        margin-top: -3rem !important
    }

    .mr-sm-n5,
    .mx-sm-n5 {
        margin-right: -3rem !important
    }

    .mb-sm-n5,
    .my-sm-n5 {
        margin-bottom: -3rem !important
    }

    .ml-sm-n5,
    .mx-sm-n5 {
        margin-left: -3rem !important
    }

    .m-sm-auto {
        margin: auto !important
    }

    .mt-sm-auto,
    .my-sm-auto {
        margin-top: auto !important
    }

    .mr-sm-auto,
    .mx-sm-auto {
        margin-right: auto !important
    }

    .mb-sm-auto,
    .my-sm-auto {
        margin-bottom: auto !important
    }

    .ml-sm-auto,
    .mx-sm-auto {
        margin-left: auto !important
    }
}

@media (min-width: 768px) {
    .m-md-0 {
        margin: 0 !important
    }

    .mt-md-0,
    .my-md-0 {
        margin-top: 0 !important
    }

    .mr-md-0,
    .mx-md-0 {
        margin-right: 0 !important
    }

    .mb-md-0,
    .my-md-0 {
        margin-bottom: 0 !important
    }

    .ml-md-0,
    .mx-md-0 {
        margin-left: 0 !important
    }

    .m-md-1 {
        margin: .25rem !important
    }

    .mt-md-1,
    .my-md-1 {
        margin-top: .25rem !important
    }

    .mr-md-1,
    .mx-md-1 {
        margin-right: .25rem !important
    }

    .mb-md-1,
    .my-md-1 {
        margin-bottom: .25rem !important
    }

    .ml-md-1,
    .mx-md-1 {
        margin-left: .25rem !important
    }

    .m-md-2 {
        margin: .5rem !important
    }

    .mt-md-2,
    .my-md-2 {
        margin-top: .5rem !important
    }

    .mr-md-2,
    .mx-md-2 {
        margin-right: .5rem !important
    }

    .mb-md-2,
    .my-md-2 {
        margin-bottom: .5rem !important
    }

    .ml-md-2,
    .mx-md-2 {
        margin-left: .5rem !important
    }

    .m-md-3 {
        margin: 1rem !important
    }

    .mt-md-3,
    .my-md-3 {
        margin-top: 1rem !important
    }

    .mr-md-3,
    .mx-md-3 {
        margin-right: 1rem !important
    }

    .mb-md-3,
    .my-md-3 {
        margin-bottom: 1rem !important
    }

    .ml-md-3,
    .mx-md-3 {
        margin-left: 1rem !important
    }

    .m-md-4 {
        margin: 1.5rem !important
    }

    .mt-md-4,
    .my-md-4 {
        margin-top: 1.5rem !important
    }

    .mr-md-4,
    .mx-md-4 {
        margin-right: 1.5rem !important
    }

    .mb-md-4,
    .my-md-4 {
        margin-bottom: 1.5rem !important
    }

    .ml-md-4,
    .mx-md-4 {
        margin-left: 1.5rem !important
    }

    .m-md-5 {
        margin: 3rem !important
    }

    .mt-md-5,
    .my-md-5 {
        margin-top: 3rem !important
    }

    .mr-md-5,
    .mx-md-5 {
        margin-right: 3rem !important
    }

    .mb-md-5,
    .my-md-5 {
        margin-bottom: 3rem !important
    }

    .ml-md-5,
    .mx-md-5 {
        margin-left: 3rem !important
    }

    .p-md-0 {
        padding: 0 !important
    }

    .pt-md-0,
    .py-md-0 {
        padding-top: 0 !important
    }

    .pr-md-0,
    .px-md-0 {
        padding-right: 0 !important
    }

    .pb-md-0,
    .py-md-0 {
        padding-bottom: 0 !important
    }

    .pl-md-0,
    .px-md-0 {
        padding-left: 0 !important
    }

    .p-md-1 {
        padding: .25rem !important
    }

    .pt-md-1,
    .py-md-1 {
        padding-top: .25rem !important
    }

    .pr-md-1,
    .px-md-1 {
        padding-right: .25rem !important
    }

    .pb-md-1,
    .py-md-1 {
        padding-bottom: .25rem !important
    }

    .pl-md-1,
    .px-md-1 {
        padding-left: .25rem !important
    }

    .p-md-2 {
        padding: .5rem !important
    }

    .pt-md-2,
    .py-md-2 {
        padding-top: .5rem !important
    }

    .pr-md-2,
    .px-md-2 {
        padding-right: .5rem !important
    }

    .pb-md-2,
    .py-md-2 {
        padding-bottom: .5rem !important
    }

    .pl-md-2,
    .px-md-2 {
        padding-left: .5rem !important
    }

    .p-md-3 {
        padding: 1rem !important
    }

    .pt-md-3,
    .py-md-3 {
        padding-top: 1rem !important
    }

    .pr-md-3,
    .px-md-3 {
        padding-right: 1rem !important
    }

    .pb-md-3,
    .py-md-3 {
        padding-bottom: 1rem !important
    }

    .pl-md-3,
    .px-md-3 {
        padding-left: 1rem !important
    }

    .p-md-4 {
        padding: 1.5rem !important
    }

    .pt-md-4,
    .py-md-4 {
        padding-top: 1.5rem !important
    }

    .pr-md-4,
    .px-md-4 {
        padding-right: 1.5rem !important
    }

    .pb-md-4,
    .py-md-4 {
        padding-bottom: 1.5rem !important
    }

    .pl-md-4,
    .px-md-4 {
        padding-left: 1.5rem !important
    }

    .p-md-5 {
        padding: 3rem !important
    }

    .pt-md-5,
    .py-md-5 {
        padding-top: 3rem !important
    }

    .pr-md-5,
    .px-md-5 {
        padding-right: 3rem !important
    }

    .pb-md-5,
    .py-md-5 {
        padding-bottom: 3rem !important
    }

    .pl-md-5,
    .px-md-5 {
        padding-left: 3rem !important
    }

    .m-md-n1 {
        margin: -.25rem !important
    }

    .mt-md-n1,
    .my-md-n1 {
        margin-top: -.25rem !important
    }

    .mr-md-n1,
    .mx-md-n1 {
        margin-right: -.25rem !important
    }

    .mb-md-n1,
    .my-md-n1 {
        margin-bottom: -.25rem !important
    }

    .ml-md-n1,
    .mx-md-n1 {
        margin-left: -.25rem !important
    }

    .m-md-n2 {
        margin: -.5rem !important
    }

    .mt-md-n2,
    .my-md-n2 {
        margin-top: -.5rem !important
    }

    .mr-md-n2,
    .mx-md-n2 {
        margin-right: -.5rem !important
    }

    .mb-md-n2,
    .my-md-n2 {
        margin-bottom: -.5rem !important
    }

    .ml-md-n2,
    .mx-md-n2 {
        margin-left: -.5rem !important
    }

    .m-md-n3 {
        margin: -1rem !important
    }

    .mt-md-n3,
    .my-md-n3 {
        margin-top: -1rem !important
    }

    .mr-md-n3,
    .mx-md-n3 {
        margin-right: -1rem !important
    }

    .mb-md-n3,
    .my-md-n3 {
        margin-bottom: -1rem !important
    }

    .ml-md-n3,
    .mx-md-n3 {
        margin-left: -1rem !important
    }

    .m-md-n4 {
        margin: -1.5rem !important
    }

    .mt-md-n4,
    .my-md-n4 {
        margin-top: -1.5rem !important
    }

    .mr-md-n4,
    .mx-md-n4 {
        margin-right: -1.5rem !important
    }

    .mb-md-n4,
    .my-md-n4 {
        margin-bottom: -1.5rem !important
    }

    .ml-md-n4,
    .mx-md-n4 {
        margin-left: -1.5rem !important
    }

    .m-md-n5 {
        margin: -3rem !important
    }

    .mt-md-n5,
    .my-md-n5 {
        margin-top: -3rem !important
    }

    .mr-md-n5,
    .mx-md-n5 {
        margin-right: -3rem !important
    }

    .mb-md-n5,
    .my-md-n5 {
        margin-bottom: -3rem !important
    }

    .ml-md-n5,
    .mx-md-n5 {
        margin-left: -3rem !important
    }

    .m-md-auto {
        margin: auto !important
    }

    .mt-md-auto,
    .my-md-auto {
        margin-top: auto !important
    }

    .mr-md-auto,
    .mx-md-auto {
        margin-right: auto !important
    }

    .mb-md-auto,
    .my-md-auto {
        margin-bottom: auto !important
    }

    .ml-md-auto,
    .mx-md-auto {
        margin-left: auto !important
    }
}

@media (min-width: 992px) {
    .m-lg-0 {
        margin: 0 !important
    }

    .mt-lg-0,
    .my-lg-0 {
        margin-top: 0 !important
    }

    .mr-lg-0,
    .mx-lg-0 {
        margin-right: 0 !important
    }

    .mb-lg-0,
    .my-lg-0 {
        margin-bottom: 0 !important
    }

    .ml-lg-0,
    .mx-lg-0 {
        margin-left: 0 !important
    }

    .m-lg-1 {
        margin: .25rem !important
    }

    .mt-lg-1,
    .my-lg-1 {
        margin-top: .25rem !important
    }

    .mr-lg-1,
    .mx-lg-1 {
        margin-right: .25rem !important
    }

    .mb-lg-1,
    .my-lg-1 {
        margin-bottom: .25rem !important
    }

    .ml-lg-1,
    .mx-lg-1 {
        margin-left: .25rem !important
    }

    .m-lg-2 {
        margin: .5rem !important
    }

    .mt-lg-2,
    .my-lg-2 {
        margin-top: .5rem !important
    }

    .mr-lg-2,
    .mx-lg-2 {
        margin-right: .5rem !important
    }

    .mb-lg-2,
    .my-lg-2 {
        margin-bottom: .5rem !important
    }

    .ml-lg-2,
    .mx-lg-2 {
        margin-left: .5rem !important
    }

    .m-lg-3 {
        margin: 1rem !important
    }

    .mt-lg-3,
    .my-lg-3 {
        margin-top: 1rem !important
    }

    .mr-lg-3,
    .mx-lg-3 {
        margin-right: 1rem !important
    }

    .mb-lg-3,
    .my-lg-3 {
        margin-bottom: 1rem !important
    }

    .ml-lg-3,
    .mx-lg-3 {
        margin-left: 1rem !important
    }

    .m-lg-4 {
        margin: 1.5rem !important
    }

    .mt-lg-4,
    .my-lg-4 {
        margin-top: 1.5rem !important
    }

    .mr-lg-4,
    .mx-lg-4 {
        margin-right: 1.5rem !important
    }

    .mb-lg-4,
    .my-lg-4 {
        margin-bottom: 1.5rem !important
    }

    .ml-lg-4,
    .mx-lg-4 {
        margin-left: 1.5rem !important
    }

    .m-lg-5 {
        margin: 3rem !important
    }

    .mt-lg-5,
    .my-lg-5 {
        margin-top: 3rem !important
    }

    .mr-lg-5,
    .mx-lg-5 {
        margin-right: 3rem !important
    }

    .mb-lg-5,
    .my-lg-5 {
        margin-bottom: 3rem !important
    }

    .ml-lg-5,
    .mx-lg-5 {
        margin-left: 3rem !important
    }

    .p-lg-0 {
        padding: 0 !important
    }

    .pt-lg-0,
    .py-lg-0 {
        padding-top: 0 !important
    }

    .pr-lg-0,
    .px-lg-0 {
        padding-right: 0 !important
    }

    .pb-lg-0,
    .py-lg-0 {
        padding-bottom: 0 !important
    }

    .pl-lg-0,
    .px-lg-0 {
        padding-left: 0 !important
    }

    .p-lg-1 {
        padding: .25rem !important
    }

    .pt-lg-1,
    .py-lg-1 {
        padding-top: .25rem !important
    }

    .pr-lg-1,
    .px-lg-1 {
        padding-right: .25rem !important
    }

    .pb-lg-1,
    .py-lg-1 {
        padding-bottom: .25rem !important
    }

    .pl-lg-1,
    .px-lg-1 {
        padding-left: .25rem !important
    }

    .p-lg-2 {
        padding: .5rem !important
    }

    .pt-lg-2,
    .py-lg-2 {
        padding-top: .5rem !important
    }

    .pr-lg-2,
    .px-lg-2 {
        padding-right: .5rem !important
    }

    .pb-lg-2,
    .py-lg-2 {
        padding-bottom: .5rem !important
    }

    .pl-lg-2,
    .px-lg-2 {
        padding-left: .5rem !important
    }

    .p-lg-3 {
        padding: 1rem !important
    }

    .pt-lg-3,
    .py-lg-3 {
        padding-top: 1rem !important
    }

    .pr-lg-3,
    .px-lg-3 {
        padding-right: 1rem !important
    }

    .pb-lg-3,
    .py-lg-3 {
        padding-bottom: 1rem !important
    }

    .pl-lg-3,
    .px-lg-3 {
        padding-left: 1rem !important
    }

    .p-lg-4 {
        padding: 1.5rem !important
    }

    .pt-lg-4,
    .py-lg-4 {
        padding-top: 1.5rem !important
    }

    .pr-lg-4,
    .px-lg-4 {
        padding-right: 1.5rem !important
    }

    .pb-lg-4,
    .py-lg-4 {
        padding-bottom: 1.5rem !important
    }

    .pl-lg-4,
    .px-lg-4 {
        padding-left: 1.5rem !important
    }

    .p-lg-5 {
        padding: 3rem !important
    }

    .pt-lg-5,
    .py-lg-5 {
        padding-top: 3rem !important
    }

    .pr-lg-5,
    .px-lg-5 {
        padding-right: 3rem !important
    }

    .pb-lg-5,
    .py-lg-5 {
        padding-bottom: 3rem !important
    }

    .pl-lg-5,
    .px-lg-5 {
        padding-left: 3rem !important
    }

    .m-lg-n1 {
        margin: -.25rem !important
    }

    .mt-lg-n1,
    .my-lg-n1 {
        margin-top: -.25rem !important
    }

    .mr-lg-n1,
    .mx-lg-n1 {
        margin-right: -.25rem !important
    }

    .mb-lg-n1,
    .my-lg-n1 {
        margin-bottom: -.25rem !important
    }

    .ml-lg-n1,
    .mx-lg-n1 {
        margin-left: -.25rem !important
    }

    .m-lg-n2 {
        margin: -.5rem !important
    }

    .mt-lg-n2,
    .my-lg-n2 {
        margin-top: -.5rem !important
    }

    .mr-lg-n2,
    .mx-lg-n2 {
        margin-right: -.5rem !important
    }

    .mb-lg-n2,
    .my-lg-n2 {
        margin-bottom: -.5rem !important
    }

    .ml-lg-n2,
    .mx-lg-n2 {
        margin-left: -.5rem !important
    }

    .m-lg-n3 {
        margin: -1rem !important
    }

    .mt-lg-n3,
    .my-lg-n3 {
        margin-top: -1rem !important
    }

    .mr-lg-n3,
    .mx-lg-n3 {
        margin-right: -1rem !important
    }

    .mb-lg-n3,
    .my-lg-n3 {
        margin-bottom: -1rem !important
    }

    .ml-lg-n3,
    .mx-lg-n3 {
        margin-left: -1rem !important
    }

    .m-lg-n4 {
        margin: -1.5rem !important
    }

    .mt-lg-n4,
    .my-lg-n4 {
        margin-top: -1.5rem !important
    }

    .mr-lg-n4,
    .mx-lg-n4 {
        margin-right: -1.5rem !important
    }

    .mb-lg-n4,
    .my-lg-n4 {
        margin-bottom: -1.5rem !important
    }

    .ml-lg-n4,
    .mx-lg-n4 {
        margin-left: -1.5rem !important
    }

    .m-lg-n5 {
        margin: -3rem !important
    }

    .mt-lg-n5,
    .my-lg-n5 {
        margin-top: -3rem !important
    }

    .mr-lg-n5,
    .mx-lg-n5 {
        margin-right: -3rem !important
    }

    .mb-lg-n5,
    .my-lg-n5 {
        margin-bottom: -3rem !important
    }

    .ml-lg-n5,
    .mx-lg-n5 {
        margin-left: -3rem !important
    }

    .m-lg-auto {
        margin: auto !important
    }

    .mt-lg-auto,
    .my-lg-auto {
        margin-top: auto !important
    }

    .mr-lg-auto,
    .mx-lg-auto {
        margin-right: auto !important
    }

    .mb-lg-auto,
    .my-lg-auto {
        margin-bottom: auto !important
    }

    .ml-lg-auto,
    .mx-lg-auto {
        margin-left: auto !important
    }
}

@media (min-width: 1200px) {
    .m-xl-0 {
        margin: 0 !important
    }

    .mt-xl-0,
    .my-xl-0 {
        margin-top: 0 !important
    }

    .mr-xl-0,
    .mx-xl-0 {
        margin-right: 0 !important
    }

    .mb-xl-0,
    .my-xl-0 {
        margin-bottom: 0 !important
    }

    .ml-xl-0,
    .mx-xl-0 {
        margin-left: 0 !important
    }

    .m-xl-1 {
        margin: .25rem !important
    }

    .mt-xl-1,
    .my-xl-1 {
        margin-top: .25rem !important
    }

    .mr-xl-1,
    .mx-xl-1 {
        margin-right: .25rem !important
    }

    .mb-xl-1,
    .my-xl-1 {
        margin-bottom: .25rem !important
    }

    .ml-xl-1,
    .mx-xl-1 {
        margin-left: .25rem !important
    }

    .m-xl-2 {
        margin: .5rem !important
    }

    .mt-xl-2,
    .my-xl-2 {
        margin-top: .5rem !important
    }

    .mr-xl-2,
    .mx-xl-2 {
        margin-right: .5rem !important
    }

    .mb-xl-2,
    .my-xl-2 {
        margin-bottom: .5rem !important
    }

    .ml-xl-2,
    .mx-xl-2 {
        margin-left: .5rem !important
    }

    .m-xl-3 {
        margin: 1rem !important
    }

    .mt-xl-3,
    .my-xl-3 {
        margin-top: 1rem !important
    }

    .mr-xl-3,
    .mx-xl-3 {
        margin-right: 1rem !important
    }

    .mb-xl-3,
    .my-xl-3 {
        margin-bottom: 1rem !important
    }

    .ml-xl-3,
    .mx-xl-3 {
        margin-left: 1rem !important
    }

    .m-xl-4 {
        margin: 1.5rem !important
    }

    .mt-xl-4,
    .my-xl-4 {
        margin-top: 1.5rem !important
    }

    .mr-xl-4,
    .mx-xl-4 {
        margin-right: 1.5rem !important
    }

    .mb-xl-4,
    .my-xl-4 {
        margin-bottom: 1.5rem !important
    }

    .ml-xl-4,
    .mx-xl-4 {
        margin-left: 1.5rem !important
    }

    .m-xl-5 {
        margin: 3rem !important
    }

    .mt-xl-5,
    .my-xl-5 {
        margin-top: 3rem !important
    }

    .mr-xl-5,
    .mx-xl-5 {
        margin-right: 3rem !important
    }

    .mb-xl-5,
    .my-xl-5 {
        margin-bottom: 3rem !important
    }

    .ml-xl-5,
    .mx-xl-5 {
        margin-left: 3rem !important
    }

    .p-xl-0 {
        padding: 0 !important
    }

    .pt-xl-0,
    .py-xl-0 {
        padding-top: 0 !important
    }

    .pr-xl-0,
    .px-xl-0 {
        padding-right: 0 !important
    }

    .pb-xl-0,
    .py-xl-0 {
        padding-bottom: 0 !important
    }

    .pl-xl-0,
    .px-xl-0 {
        padding-left: 0 !important
    }

    .p-xl-1 {
        padding: .25rem !important
    }

    .pt-xl-1,
    .py-xl-1 {
        padding-top: .25rem !important
    }

    .pr-xl-1,
    .px-xl-1 {
        padding-right: .25rem !important
    }

    .pb-xl-1,
    .py-xl-1 {
        padding-bottom: .25rem !important
    }

    .pl-xl-1,
    .px-xl-1 {
        padding-left: .25rem !important
    }

    .p-xl-2 {
        padding: .5rem !important
    }

    .pt-xl-2,
    .py-xl-2 {
        padding-top: .5rem !important
    }

    .pr-xl-2,
    .px-xl-2 {
        padding-right: .5rem !important
    }

    .pb-xl-2,
    .py-xl-2 {
        padding-bottom: .5rem !important
    }

    .pl-xl-2,
    .px-xl-2 {
        padding-left: .5rem !important
    }

    .p-xl-3 {
        padding: 1rem !important
    }

    .pt-xl-3,
    .py-xl-3 {
        padding-top: 1rem !important
    }

    .pr-xl-3,
    .px-xl-3 {
        padding-right: 1rem !important
    }

    .pb-xl-3,
    .py-xl-3 {
        padding-bottom: 1rem !important
    }

    .pl-xl-3,
    .px-xl-3 {
        padding-left: 1rem !important
    }

    .p-xl-4 {
        padding: 1.5rem !important
    }

    .pt-xl-4,
    .py-xl-4 {
        padding-top: 1.5rem !important
    }

    .pr-xl-4,
    .px-xl-4 {
        padding-right: 1.5rem !important
    }

    .pb-xl-4,
    .py-xl-4 {
        padding-bottom: 1.5rem !important
    }

    .pl-xl-4,
    .px-xl-4 {
        padding-left: 1.5rem !important
    }

    .p-xl-5 {
        padding: 3rem !important
    }

    .pt-xl-5,
    .py-xl-5 {
        padding-top: 3rem !important
    }

    .pr-xl-5,
    .px-xl-5 {
        padding-right: 3rem !important
    }

    .pb-xl-5,
    .py-xl-5 {
        padding-bottom: 3rem !important
    }

    .pl-xl-5,
    .px-xl-5 {
        padding-left: 3rem !important
    }

    .m-xl-n1 {
        margin: -.25rem !important
    }

    .mt-xl-n1,
    .my-xl-n1 {
        margin-top: -.25rem !important
    }

    .mr-xl-n1,
    .mx-xl-n1 {
        margin-right: -.25rem !important
    }

    .mb-xl-n1,
    .my-xl-n1 {
        margin-bottom: -.25rem !important
    }

    .ml-xl-n1,
    .mx-xl-n1 {
        margin-left: -.25rem !important
    }

    .m-xl-n2 {
        margin: -.5rem !important
    }

    .mt-xl-n2,
    .my-xl-n2 {
        margin-top: -.5rem !important
    }

    .mr-xl-n2,
    .mx-xl-n2 {
        margin-right: -.5rem !important
    }

    .mb-xl-n2,
    .my-xl-n2 {
        margin-bottom: -.5rem !important
    }

    .ml-xl-n2,
    .mx-xl-n2 {
        margin-left: -.5rem !important
    }

    .m-xl-n3 {
        margin: -1rem !important
    }

    .mt-xl-n3,
    .my-xl-n3 {
        margin-top: -1rem !important
    }

    .mr-xl-n3,
    .mx-xl-n3 {
        margin-right: -1rem !important
    }

    .mb-xl-n3,
    .my-xl-n3 {
        margin-bottom: -1rem !important
    }

    .ml-xl-n3,
    .mx-xl-n3 {
        margin-left: -1rem !important
    }

    .m-xl-n4 {
        margin: -1.5rem !important
    }

    .mt-xl-n4,
    .my-xl-n4 {
        margin-top: -1.5rem !important
    }

    .mr-xl-n4,
    .mx-xl-n4 {
        margin-right: -1.5rem !important
    }

    .mb-xl-n4,
    .my-xl-n4 {
        margin-bottom: -1.5rem !important
    }

    .ml-xl-n4,
    .mx-xl-n4 {
        margin-left: -1.5rem !important
    }

    .m-xl-n5 {
        margin: -3rem !important
    }

    .mt-xl-n5,
    .my-xl-n5 {
        margin-top: -3rem !important
    }

    .mr-xl-n5,
    .mx-xl-n5 {
        margin-right: -3rem !important
    }

    .mb-xl-n5,
    .my-xl-n5 {
        margin-bottom: -3rem !important
    }

    .ml-xl-n5,
    .mx-xl-n5 {
        margin-left: -3rem !important
    }

    .m-xl-auto {
        margin: auto !important
    }

    .mt-xl-auto,
    .my-xl-auto {
        margin-top: auto !important
    }

    .mr-xl-auto,
    .mx-xl-auto {
        margin-right: auto !important
    }

    .mb-xl-auto,
    .my-xl-auto {
        margin-bottom: auto !important
    }

    .ml-xl-auto,
    .mx-xl-auto {
        margin-left: auto !important
    }
}

.text-monospace {
    font-family: SFMono-Regular, Menlo, Monaco, Consolas, "Liberation Mono", "Courier New", monospace !important
}

.text-justify {
    text-align: justify !important
}

.text-wrap {
    white-space: normal !important
}

.text-nowrap {
    white-space: nowrap !important
}

.text-truncate {
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap
}

.text-left {
    text-align: left !important
}

.text-right {
    text-align: right !important
}

.text-center {
    text-align: center !important
}

@media (min-width: 576px) {
    .text-sm-left {
        text-align: left !important
    }

    .text-sm-right {
        text-align: right !important
    }

    .text-sm-center {
        text-align: center !important
    }
}

@media (min-width: 768px) {
    .text-md-left {
        text-align: left !important
    }

    .text-md-right {
        text-align: right !important
    }

    .text-md-center {
        text-align: center !important
    }
}

@media (min-width: 992px) {
    .text-lg-left {
        text-align: left !important
    }

    .text-lg-right {
        text-align: right !important
    }

    .text-lg-center {
        text-align: center !important
    }
}

@media (min-width: 1200px) {
    .text-xl-left {
        text-align: left !important
    }

    .text-xl-right {
        text-align: right !important
    }

    .text-xl-center {
        text-align: center !important
    }
}

.text-lowercase {
    text-transform: lowercase !important
}

.text-uppercase {
    text-transform: uppercase !important
}

.text-capitalize {
    text-transform: capitalize !important
}

.font-weight-light {
    font-weight: 300 !important
}

.font-weight-lighter {
    font-weight: lighter !important
}

.font-weight-normal {
    font-weight: 400 !important
}

.font-weight-bold {
    font-weight: 700 !important
}

.font-weight-bolder {
    font-weight: bolder !important
}

.font-italic {
    font-style: italic !important
}

.text-white {
    color: #fff !important
}

.text-primary {
    color: #0168fa !important
}

a.text-primary:hover,
a.text-primary:focus {
    color: #0148ae !important
}

.text-secondary {
    color: #7987a1 !important
}

a.text-secondary:hover,
a.text-secondary:focus {
    color: #556179 !important
}

.text-success {
    color: #10b759 !important
}

a.text-success:hover,
a.text-success:focus {
    color: #0a7137 !important
}

.text-info {
    color: #00b8d4 !important
}

a.text-info:hover,
a.text-info:focus {
    color: #007688 !important
}

.text-warning {
    color: #ffc107 !important
}

a.text-warning:hover,
a.text-warning:focus {
    color: #ba8b00 !important
}

.text-danger {
    color: #dc3545 !important
}

a.text-danger:hover,
a.text-danger:focus {
    color: #a71d2a !important
}

.text-light {
    color: #f4f5f8 !important
}

a.text-light:hover,
a.text-light:focus {
    color: #c5cbda !important
}

.text-dark {
    color: #3b4863 !important
}

a.text-dark:hover,
a.text-dark:focus {
    color: #1e2533 !important
}

.text-body {
    color: #001737 !important
}

.text-muted {
    color: #7987a1 !important
}

.text-black-50 {
    color: rgba(0, 0, 0, 0.5) !important
}

.text-white-50 {
    color: rgba(255, 255, 255, 0.5) !important
}

.text-hide {
    font: 0/0 a;
    color: transparent;
    text-shadow: none;
    background-color: transparent;
    border: 0
}

.text-decoration-none {
    text-decoration: none !important
}

.text-break {
    word-break: break-word !important;
    overflow-wrap: break-word !important
}

.text-reset {
    color: inherit !important
}

.visible {
    visibility: visible !important
}

.invisible {
    visibility: hidden !important
}

@media print {

    *,
    *::before,
    *::after {
        text-shadow: none !important;
        box-shadow: none !important
    }

    a:not(.btn) {
        text-decoration: underline
    }

    abbr[title]::after {
        content: " (" attr(title) ")"
    }

    pre {
        white-space: pre-wrap !important
    }

    pre,
    blockquote {
        border: 1px solid #97a3b9;
        page-break-inside: avoid
    }

    thead {
        display: table-header-group
    }

    tr,
    img {
        page-break-inside: avoid
    }

    p,
    h2,
    h3 {
        orphans: 3;
        widows: 3
    }

    h2,
    h3 {
        page-break-after: avoid
    }

    @page {
        size: a3
    }

    body {
        min-width: 992px !important
    }

    .container {
        min-width: 992px !important
    }

    .navbar {
        display: none
    }

    .badge {
        border: 1px solid #000
    }

    .table {
        border-collapse: collapse !important
    }

    .table td,
    .table th {
        background-color: #fff !important
    }

    .table-bordered th,
    .table-bordered td {
        border: 1px solid #cdd4e0 !important
    }

    .table-dark {
        color: inherit
    }

    .table-dark th,
    .table-dark td,
    .table-dark thead th,
    .table-dark tbody+tbody {
        border-color: rgba(72, 94, 144, 0.16)
    }

    .table .thead-dark th {
        color: inherit;
        border-color: rgba(72, 94, 144, 0.16)
    }
}

hr {
    border-color: rgba(72, 94, 144, 0.16)
}

strong {
    font-weight: 600
}

.alert {
    padding: 12px 15px
}

.alert-secondary {
    background-color: #e3e7ed;
    border-color: #cdd4e0
}

.alert-light {
    background-color: #eeeff4;
    border-color: #e3e7ed
}

.alert-dark {
    background-color: #97a3b9;
    border-color: #8e9bb3
}

.alert-dismissible .close {
    padding: 0;
    top: 9px;
    right: 15px;
    font-weight: 500
}

.alert-outline {
    background-color: transparent;
    border-width: 2px;
    font-weight: 500;
    padding: 11px 15px;
    border-radius: 3px
}

.alert-outline.alert-primary {
    border-color: #0168fa;
    color: #0168fa
}

.alert-outline.alert-secondary {
    border-color: #97a3b9;
    color: #7987a1
}

.alert-outline.alert-success {
    border-color: #10b759;
    color: #10b759
}

.alert-outline.alert-danger {
    border-color: #dc3545;
    color: #dc3545
}

.alert-outline.alert-warning {
    border-color: #edb100;
    color: #edb100
}

.alert-outline.alert-info {
    border-color: #00b8d4;
    color: #00b8d4
}

.alert-outline.alert-light {
    border-color: #cdd4e0;
    color: #97a3b9
}

.alert-outline.alert-dark {
    border-color: #7987a1;
    color: #596882
}

.alert-solid {
    border-width: 0
}

.alert-solid:not(.alert-secondary),
.alert-solid:not(.alert-light) {
    color: #fff
}

.alert-solid.alert-primary {
    background-color: #0168fa
}

.alert-solid.alert-secondary {
    background-color: #7987a1
}

.alert-solid.alert-success {
    background-color: #10b759
}

.alert-solid.alert-danger {
    background-color: #dc3545
}

.alert-solid.alert-warning {
    background-color: #ffc107
}

.alert-solid.alert-info {
    background-color: #00b8d4
}

.alert-solid.alert-light {
    background-color: #b4bdce
}

.alert-solid.alert-dark {
    background-color: #596882
}

.badge {
    font-size: 10px;
    font-weight: 500;
    font-family: -apple-system, BlinkMacSystemFont, "Inter UI", Roboto, sans-serif;
    padding: 3px 5px 4px;
    border-radius: 3px
}

.badge-light {
    background-color: #cdd4e0
}

.badge-pill {
    padding-left: 8px;
    padding-right: 8px
}

.breadcrumb {
    background-color: #e5e9f2
}

.breadcrumb-style1,
.breadcrumb-style2,
.breadcrumb-style3 {
    border-radius: 0;
    background-color: transparent;
    padding: 0
}

.breadcrumb-style1 .breadcrumb-item,
.breadcrumb-style2 .breadcrumb-item,
.breadcrumb-style3 .breadcrumb-item {
    font-size: 10px;
    font-weight: 600;
    font-family: -apple-system, BlinkMacSystemFont, "Inter UI", Roboto, sans-serif;
    letter-spacing: .5px;
    text-transform: uppercase
}

.breadcrumb-style1 .breadcrumb-item a,
.breadcrumb-style2 .breadcrumb-item a,
.breadcrumb-style3 .breadcrumb-item a {
    color: #1c273c
}

.breadcrumb-style1 .breadcrumb-item a:hover,
.breadcrumb-style1 .breadcrumb-item a:focus,
.breadcrumb-style2 .breadcrumb-item a:hover,
.breadcrumb-style2 .breadcrumb-item a:focus,
.breadcrumb-style3 .breadcrumb-item a:hover,
.breadcrumb-style3 .breadcrumb-item a:focus {
    color: #0168fa
}

.breadcrumb-style1 .breadcrumb-item.active,
.breadcrumb-style2 .breadcrumb-item.active,
.breadcrumb-style3 .breadcrumb-item.active {
    color: #0168fa
}

.breadcrumb-style1 .breadcrumb-item+.breadcrumb-item::before,
.breadcrumb-style2 .breadcrumb-item+.breadcrumb-item::before,
.breadcrumb-style3 .breadcrumb-item+.breadcrumb-item::before {
    color: #97a3b9
}

.breadcrumb-style2 .breadcrumb-item+.breadcrumb-item::before,
.breadcrumb-style3 .breadcrumb-item+.breadcrumb-item::before {
    width: 16px;
    text-align: center;
    font-family: 'Ionicons'
}

.breadcrumb-style2 .breadcrumb-item+.breadcrumb-item::before {
    content: '\f3d1'
}

.breadcrumb-style3 .breadcrumb-item+.breadcrumb-item::before {
    content: '\f287'
}

.btn,
.ui-datepicker-buttonpane button,
.sp-container button {
    font-size: inherit
}

.btn:focus,
.ui-datepicker-buttonpane button:focus,
.sp-container button:focus,
.btn:active,
.ui-datepicker-buttonpane button:active,
.sp-container button:active {
    box-shadow: none
}

.btn svg,
.ui-datepicker-buttonpane button svg,
.sp-container button svg {
    width: 14px;
    height: 14px;
    stroke-width: 2.5px;
    margin-top: -2px
}

.btn.active-primary,
.ui-datepicker-buttonpane button.active-primary,
.sp-container button.active-primary,
.btn.active-primary:hover,
.ui-datepicker-buttonpane button.active-primary:hover,
.sp-container button.active-primary:hover,
.btn.active-primary:focus,
.ui-datepicker-buttonpane button.active-primary:focus,
.sp-container button.active-primary:focus {
    background-color: #fff;
    border-color: #0168fa;
    color: #0168fa
}

.btn-sm,
.btn-group-sm>.btn,
.ui-datepicker-buttonpane .btn-group-sm>button,
.sp-container .btn-group-sm>button,
.ui-datepicker-buttonpane button {
    font-size: 13px;
    padding-left: 15px;
    padding-right: 15px
}

.btn-xs {
    font-size: 12px;
    padding-top: 5px;
    padding-bottom: 5px;
    border-radius: .25rem
}

.btn-lg,
.btn-group-lg>.btn,
.ui-datepicker-buttonpane .btn-group-lg>button,
.sp-container .btn-group-lg>button {
    font-size: .875rem;
    border-radius: .25rem
}

.btn-light {
    background-color: #e5e9f2;
    border-color: #cdd5e6;
    color: #8392a5
}

.btn-light:hover,
.btn-light:focus {
    background-color: #e5e9f2;
    border-color: #cdd5e6
}

.btn-outline-light {
    border-color: #b4bdce;
    color: #7987a1
}

.btn-outline-light:hover,
.btn-outline-light:focus,
.btn-outline-light:active {
    background-color: #b4bdce;
    border-color: #b4bdce
}

.btn-outline-light:not(:disabled):not(.disabled):active,
.btn-outline-light:not(:disabled):not(.disabled).active,
.show>.btn-outline-light.dropdown-toggle {
    background-color: #cdd4e0;
    border-color: #b4bdce
}

.btn-white {
    background-color: #fff;
    border-color: #c0ccda;
    color: rgba(27, 46, 75, 0.7)
}

.btn-white:hover,
.btn-white:focus {
    border-color: #8392a5;
    color: #1b2e4b
}

.btn-white:active,
.btn-white.active {
    background-color: #f5f6fa
}

.btn-icon {
    padding-left: 10px;
    padding-right: 10px
}

.btn-icon.btn-sm,
.btn-group-sm>.btn-icon.btn,
.sp-container .btn-group-sm>button.btn-icon,
.ui-datepicker-buttonpane button.btn-icon {
    padding-left: 8px;
    padding-right: 8px
}

.btn-icon.btn-xs {
    padding-left: 6px;
    padding-right: 6px
}

.btn-icon svg {
    margin: 0
}

.btn-brand-01 {
    background-color: #015de1;
    border-color: #015de1;
    color: #fff
}

.btn-brand-01:hover,
.btn-brand-01:focus {
    background-color: #0153c7;
    border-color: #0153c7;
    color: #fff
}

.btn-brand-02 {
    background-color: #042893;
    border-color: #042893;
    color: #fff
}

.btn-brand-02:hover,
.btn-brand-02:focus {
    background-color: #063cdd;
    border-color: #063cdd;
    color: #fff
}

.btn-outline-facebook {
    background-color: transparent;
    border-color: #4064ac;
    color: #4064ac
}

.btn-outline-facebook:hover,
.btn-outline-facebook:focus {
    background-color: #4064ac;
    border-color: #4064ac;
    color: #fff
}

.btn-outline-twitter {
    background-color: transparent;
    border-color: #00a7e6;
    color: #00a7e6
}

.btn-outline-twitter:hover,
.btn-outline-twitter:focus {
    background-color: #00a7e6;
    border-color: #00a7e6;
    color: #fff
}

.btn-uppercase {
    text-transform: uppercase;
    font-size: 12px;
    font-weight: 600;
    letter-spacing: .5px;
    line-height: 1.75
}

.btn-uppercase.btn-sm,
.btn-group-sm>.btn-uppercase.btn,
.sp-container .btn-group-sm>button.btn-uppercase,
.ui-datepicker-buttonpane button.btn-uppercase {
    font-size: 11px;
    line-height: 1.773
}

.btn-uppercase.btn-xs {
    font-size: 10px;
    line-height: 1.8
}

.card.bg-primary,
.card.bg-secondary,
.card.bg-success,
.card.bg-warning,
.card.bg-danger,
.card.bg-info,
.card.bg-dark {
    border-width: 0
}

.card.bg-primary .card-header,
.card.bg-secondary .card-header,
.card.bg-success .card-header,
.card.bg-warning .card-header,
.card.bg-danger .card-header,
.card.bg-info .card-header,
.card.bg-dark .card-header {
    background-color: transparent;
    border-bottom-color: rgba(255, 255, 255, 0.1)
}

.card-header,
.card-footer {
    background-color: transparent;
    border-color: rgba(72, 94, 144, 0.16)
}

.card-header {
    padding: 15px
}

@media (min-width: 576px) {
    .card-header {
        padding: 15px 20px
    }
}

.card-body {
    padding: 15px
}

@media (min-width: 576px) {
    .card-body {
        padding: 20px
    }
}

.card-img {
    border-radius: .25rem
}

.card-img-top {
    border-top-left-radius: .25rem;
    border-top-right-radius: .25rem
}

.card-img-bottom {
    border-bottom-right-radius: .25rem;
    border-bottom-left-radius: .25rem
}

.carousel-control-prev-icon,
.carousel-control-next-icon {
    background-image: none;
    display: block;
    width: auto;
    height: auto
}

.carousel-control-prev-icon svg,
.carousel-control-next-icon svg {
    width: 48px;
    height: 48px
}

.carousel-indicators li {
    width: 10px;
    height: 10px;
    border-radius: 100%
}

.custom-control {
    min-height: inherit
}

.custom-checkbox .custom-control-input:disabled:checked~.custom-control-label::before,
.custom-radio .custom-control-input:disabled:checked~.custom-control-label::before,
.custom-switch .custom-control-input:disabled:checked~.custom-control-label::before {
    border-color: rgba(1, 104, 250, 0.2)
}

.custom-checkbox .custom-control-label::before,
.custom-checkbox .custom-control-label::after,
.custom-radio .custom-control-label::before,
.custom-radio .custom-control-label::after,
.custom-switch .custom-control-label::before,
.custom-switch .custom-control-label::after {
    top: 44%;
    transform: translateY(-50%)
}

.custom-checkbox .custom-control-label:empty,
.custom-radio .custom-control-label:empty,
.custom-switch .custom-control-label:empty {
    height: 20px
}

.custom-checkbox .custom-control-label:empty::before,
.custom-checkbox .custom-control-label:empty::after,
.custom-radio .custom-control-label:empty::before,
.custom-radio .custom-control-label:empty::after,
.custom-switch .custom-control-label:empty::before,
.custom-switch .custom-control-label:empty::after {
    left: -16px
}

.custom-switch .custom-control-input:checked~.custom-control-label::after {
    transform: translate(0.75rem, -50%)
}

.custom-select {
    border-color: #c0ccda;
    -webkit-appearance: none;
    -moz-appearance: none;
    appearance: none
}

.custom-select:invalid {
    color: #97a3b9
}

.custom-select option {
    color: #001737
}

.custom-file-label,
.custom-file-label::after {
    display: flex;
    align-items: center
}

.custom-file-label::after {
    background-color: #f5f6fa
}

.dropdown-menu {
    border-color: rgba(131, 146, 165, 0.27);
    box-shadow: 0 0 8px 2px rgba(28, 39, 60, 0.04);
    padding: 5px
}

.dropdown-item {
    padding: 6px 15px;
    border-radius: .25rem;
    transition: all 0.2s ease-in-out
}

@media (prefers-reduced-motion: reduce) {
    .dropdown-item {
        transition: none
    }
}

.dropdown-item:hover,
.dropdown-item:focus {
    background-color: #e5e9f2
}

.dropdown-item.active {
    background-color: #0168fa
}

.dropdown-header {
    padding-left: 15px;
    padding-right: 15px
}

.dropdown-divider {
    margin: 2px 15px
}

.form-control {
    color: #1b2e4b;
    font-size: inherit
}

.form-control::placeholder {
    color: #c0ccda
}

.form-control:disabled,
.form-control[readonly] {
    background-color: #f5f6fa
}

textarea.form-control {
    min-height: 38px
}

.form-group {
    margin-bottom: 20px
}

.form-fieldset {
    background-color: #fff;
    border: 1px solid rgba(72, 94, 144, 0.16);
    padding: 20px 25px 25px;
    border-radius: .25rem
}

.form-fieldset .form-group:last-child {
    margin-bottom: 0
}

.form-fieldset legend {
    width: auto;
    background-color: inherit;
    padding: 0 10px;
    position: relative;
    margin-left: -10px;
    text-transform: uppercase;
    font-weight: 600;
    font-size: inherit;
    color: #1c273c;
    letter-spacing: .5;
    margin-bottom: 0
}

.row-sm {
    margin-left: -10px;
    margin-right: -10px
}

.row-sm>div {
    padding-left: 10px;
    padding-right: 10px
}

.row-xs {
    margin-left: -5px;
    margin-right: -5px
}

.row-xs>div {
    padding-left: 5px;
    padding-right: 5px
}

.row-xxs {
    margin-left: -1px;
    margin-right: -1px
}

.row-xxs>div {
    padding-left: 1px;
    padding-right: 1px
}

@media (min-width: 576px) {
    .row-xs--sm {
        margin-left: -5px;
        margin-right: -5px
    }

    .row-xs--sm>div {
        padding-left: 5px;
        padding-right: 5px
    }

    .row-sm--sm {
        margin-left: -10px;
        margin-right: -10px
    }

    .row-sm--sm>div {
        padding-left: 10px;
        padding-right: 10px
    }

    .row--sm {
        margin-left: -15px;
        margin-right: -15px
    }

    .row--sm>div {
        padding-left: 15px;
        padding-right: 15px
    }
}

@media (min-width: 768px) {
    .row-xs--md {
        margin-left: -5px;
        margin-right: -5px
    }

    .row-xs--md>div {
        padding-left: 5px;
        padding-right: 5px
    }

    .row-sm--md {
        margin-left: -10px;
        margin-right: -10px
    }

    .row-sm--md>div {
        padding-left: 10px;
        padding-right: 10px
    }

    .row--md {
        margin-left: -15px;
        margin-right: -15px
    }

    .row--md>div {
        padding-left: 15px;
        padding-right: 15px
    }
}

@media (min-width: 992px) {
    .row-xs--lg {
        margin-left: -5px;
        margin-right: -5px
    }

    .row-xs--lg>div {
        padding-left: 5px;
        padding-right: 5px
    }

    .row-sm--lg {
        margin-left: -10px;
        margin-right: -10px
    }

    .row-sm--lg>div {
        padding-left: 10px;
        padding-right: 10px
    }

    .row--lg {
        margin-left: -15px;
        margin-right: -15px
    }

    .row--lg>div {
        padding-left: 15px;
        padding-right: 15px
    }
}

@media (min-width: 1200px) {
    .row-xs--xl {
        margin-left: -5px;
        margin-right: -5px
    }

    .row-xs--xl>div {
        padding-left: 5px;
        padding-right: 5px
    }

    .row-sm--xl {
        margin-left: -10px;
        margin-right: -10px
    }

    .row-sm--xl>div {
        padding-left: 10px;
        padding-right: 10px
    }

    .row--xl {
        margin-left: -15px;
        margin-right: -15px
    }

    .row--xl>div {
        padding-left: 15px;
        padding-right: 15px
    }
}

.list-group-item {
    padding: 10px 15px;
    border-color: #e5e9f2
}

.modal-content {
    border-color: rgba(28, 39, 60, 0.16);
    border-radius: .25rem
}

.modal-content .close {
    font-weight: 300;
    font-size: 28px;
    line-height: 0.87
}

.modal-backdrop {
    background-color: rgba(15, 21, 32, 0.7)
}

.modal-backdrop.show {
    opacity: 1
}

.nav-link {
    color: #596882
}

.nav-link:hover,
.nav-link:focus {
    color: #0168fa
}

.nav-link.active {
    color: #0168fa
}

.nav-pills .nav-link {
    transition: all 0.2s ease-in-out
}

@media (prefers-reduced-motion: reduce) {
    .nav-pills .nav-link {
        transition: none
    }
}

.nav-pills .nav-link:not(.active):hover,
.nav-pills .nav-link:not(.active):focus {
    background-color: #eeeff4;
    color: #001737
}

.nav-pills:not(.flex-column) .nav-item+.nav-item,
.nav-pills:not(.flex-column)>.nav-link+.nav-link {
    margin-left: 2px
}

.navbar-toggler {
    align-self: stretch;
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 0;
    border-width: 0;
    color: #7987a1
}

.navbar-toggler:hover,
.navbar-toggler:focus {
    color: #1c273c
}

.navbar-toggler .feather {
    stroke-width: 2.5px
}

.navbar-light .navbar-toggler {
    color: #7987a1
}

.navbar-light .navbar-toggler:hover,
.navbar-light .navbar-toggler:focus {
    color: #1c273c
}

.navbar-dark .navbar-toggler {
    color: rgba(255, 255, 255, 0.5)
}

.navbar-dark .navbar-toggler:hover,
.navbar-dark .navbar-toggler:focus {
    color: #fff
}

.pagination {
    font-size: 14px;
    font-weight: 500
}

.page-item.disabled {
    font-weight: 400
}

.page-item.disabled .page-link {
    color: #97a3b9
}

.page-item.active .page-link {
    background-color: #0168fa
}

.page-link {
    min-width: 36px;
    height: 100%;
    padding: 9px 10px;
    display: flex;
    align-items: center;
    justify-content: center;
    color: #0168fa;
    transition: all 0.2s ease-in-out
}

@media (prefers-reduced-motion: reduce) {
    .page-link {
        transition: none
    }
}

.page-link:hover,
.page-link:focus {
    z-index: auto;
    background-color: #f1f2f6
}

.page-link .feather {
    width: 18px;
    height: 18px;
    stroke-width: 2.5px
}

.page-link-icon {
    padding: 9px 8px
}

.pagination-space .page-item+.page-item {
    margin-left: 5px
}

.pagination-space .page-link {
    margin-left: 0;
    border-radius: .25rem
}

.pagination-outline .page-item+.page-item {
    margin-left: 5px
}

.pagination-outline .page-item:first-child .page-link,
.pagination-outline .page-item:last-child .page-link {
    border-radius: 3px
}

.pagination-outline .page-link {
    padding: 8px 7px;
    margin-left: 0;
    border-width: 2px;
    border-color: #0168fa;
    border-radius: 3px
}

.pagination-filled .page-item+.page-item {
    margin-left: 5px
}

.pagination-filled .page-item.disabled .page-link {
    background-color: #e9ecf1;
    color: #b4bdce
}

.pagination-filled .page-link {
    min-width: 34px;
    padding: 9px 6px;
    border-width: 0;
    background-color: #b4bdce;
    color: #596882;
    border-radius: .25rem
}

.pagination-filled .page-link:hover,
.pagination-filled .page-link:focus {
    background-color: #97a3b9
}

.pagination-circle .page-item+.page-item {
    margin-left: 5px
}

.pagination-circle .page-item:first-child .page-link,
.pagination-circle .page-item:last-child .page-link {
    border-radius: 100%
}

.pagination-circle .page-link {
    margin-left: 0;
    width: 36px;
    height: 36px;
    border-radius: 100%
}

.popover {
    border-color: rgba(28, 39, 60, 0.12);
    box-shadow: 0 0 8px rgba(28, 39, 60, 0.08);
    border-radius: .25rem;
    font-size: 13px;
    font-family: -apple-system, BlinkMacSystemFont, "Inter UI", Roboto, sans-serif
}

.popover-body {
    padding: 10px 15px 15px
}

.popover-header {
    background-color: #f1f2f6;
    border-top-left-radius: .25rem;
    border-top-right-radius: .25rem;
    font-size: 14px;
    color: #1c273c;
    border-bottom-color: #e3e7ed;
    padding: 10px 15px
}

.popover-header-primary .popover-header,
.popover-header-secondary .popover-header,
.popover-header-success .popover-header,
.popover-header-danger .popover-header,
.popover-header-warning .popover-header,
.popover-header-info .popover-header {
    color: #fff;
    border-bottom-width: 0;
    position: relative;
    margin-top: -1px;
    margin-left: -1px;
    margin-right: -1px
}

.popover-header-primary.bs-popover-bottom .popover-header::before,
.popover-header-primary.bs-popover-auto[x-placement^="bottom"] .popover-header::before,
.popover-header-secondary.bs-popover-bottom .popover-header::before,
.popover-header-secondary.bs-popover-auto[x-placement^="bottom"] .popover-header::before,
.popover-header-success.bs-popover-bottom .popover-header::before,
.popover-header-success.bs-popover-auto[x-placement^="bottom"] .popover-header::before,
.popover-header-danger.bs-popover-bottom .popover-header::before,
.popover-header-danger.bs-popover-auto[x-placement^="bottom"] .popover-header::before,
.popover-header-warning.bs-popover-bottom .popover-header::before,
.popover-header-warning.bs-popover-auto[x-placement^="bottom"] .popover-header::before,
.popover-header-info.bs-popover-bottom .popover-header::before,
.popover-header-info.bs-popover-auto[x-placement^="bottom"] .popover-header::before {
    display: none
}

.popover-header-warning .popover-header {
    color: #001737
}

.popover-header-primary.bs-popover-bottom .arrow::before,
.popover-header-primary.bs-popover-auto[x-placement^="bottom"] .arrow::before,
.popover-header-primary.bs-popover-bottom .arrow::after,
.popover-header-primary.bs-popover-auto[x-placement^="bottom"] .arrow::after {
    border-bottom-color: #0168fa
}

.popover-header-primary .popover-header {
    background-color: #0168fa
}

.popover-header-secondary.bs-popover-bottom .arrow::before,
.popover-header-secondary.bs-popover-auto[x-placement^="bottom"] .arrow::before,
.popover-header-secondary.bs-popover-bottom .arrow::after,
.popover-header-secondary.bs-popover-auto[x-placement^="bottom"] .arrow::after {
    border-bottom-color: #7987a1
}

.popover-header-secondary .popover-header {
    background-color: #7987a1
}

.popover-header-success.bs-popover-bottom .arrow::before,
.popover-header-success.bs-popover-auto[x-placement^="bottom"] .arrow::before,
.popover-header-success.bs-popover-bottom .arrow::after,
.popover-header-success.bs-popover-auto[x-placement^="bottom"] .arrow::after {
    border-bottom-color: #10b759
}

.popover-header-success .popover-header {
    background-color: #10b759
}

.popover-header-danger.bs-popover-bottom .arrow::before,
.popover-header-danger.bs-popover-auto[x-placement^="bottom"] .arrow::before,
.popover-header-danger.bs-popover-bottom .arrow::after,
.popover-header-danger.bs-popover-auto[x-placement^="bottom"] .arrow::after {
    border-bottom-color: #dc3545
}

.popover-header-danger .popover-header {
    background-color: #dc3545
}

.popover-header-warning.bs-popover-bottom .arrow::before,
.popover-header-warning.bs-popover-auto[x-placement^="bottom"] .arrow::before,
.popover-header-warning.bs-popover-bottom .arrow::after,
.popover-header-warning.bs-popover-auto[x-placement^="bottom"] .arrow::after {
    border-bottom-color: #ffc107
}

.popover-header-warning .popover-header {
    background-color: #ffc107
}

.popover-header-info.bs-popover-bottom .arrow::before,
.popover-header-info.bs-popover-auto[x-placement^="bottom"] .arrow::before,
.popover-header-info.bs-popover-bottom .arrow::after,
.popover-header-info.bs-popover-auto[x-placement^="bottom"] .arrow::after {
    border-bottom-color: #00b8d4
}

.popover-header-info .popover-header {
    background-color: #00b8d4
}

.popover-primary,
.popover-secondary,
.popover-success,
.popover-warning,
.popover-danger,
.popover-info {
    border-width: 0
}

.popover-primary .popover-header,
.popover-secondary .popover-header,
.popover-success .popover-header,
.popover-warning .popover-header,
.popover-danger .popover-header,
.popover-info .popover-header {
    background-color: transparent;
    border-bottom-color: rgba(255, 255, 255, 0.08);
    color: #fff
}

.popover-primary .popover-header::before,
.popover-secondary .popover-header::before,
.popover-success .popover-header::before,
.popover-warning .popover-header::before,
.popover-danger .popover-header::before,
.popover-info .popover-header::before {
    display: none
}

.popover-primary .popover-body,
.popover-secondary .popover-body,
.popover-success .popover-body,
.popover-warning .popover-body,
.popover-danger .popover-body,
.popover-info .popover-body {
    color: rgba(255, 255, 255, 0.7)
}

.popover-primary .arrow::before,
.popover-secondary .arrow::before,
.popover-success .arrow::before,
.popover-warning .arrow::before,
.popover-danger .arrow::before,
.popover-info .arrow::before {
    display: none
}

.popover-primary {
    background-color: #0168fa
}

.popover-primary.bs-popover-top .arrow::after,
.popover-primary.bs-popover-auto[x-placement^="top"] .arrow::after {
    border-top-color: #0168fa
}

.popover-primary.bs-popover-bottom .arrow::after,
.popover-primary.bs-popover-auto[x-placement^="bottom"] .arrow::after {
    border-bottom-color: #0168fa
}

.popover-primary.bs-popover-right .arrow::after,
.popover-primary.bs-popover-auto[x-placement^="right"] .arrow::after {
    border-right-color: #0168fa
}

.popover-primary.bs-popover-left .arrow::after,
.popover-primary.bs-popover-auto[x-placement^="left"] .arrow::after {
    border-left-color: #0168fa
}

.popover-secondary {
    background-color: #7987a1
}

.popover-secondary.bs-popover-top .arrow::after,
.popover-secondary.bs-popover-auto[x-placement^="top"] .arrow::after {
    border-top-color: #7987a1
}

.popover-secondary.bs-popover-bottom .arrow::after,
.popover-secondary.bs-popover-auto[x-placement^="bottom"] .arrow::after {
    border-bottom-color: #7987a1
}

.popover-secondary.bs-popover-right .arrow::after,
.popover-secondary.bs-popover-auto[x-placement^="right"] .arrow::after {
    border-right-color: #7987a1
}

.popover-secondary.bs-popover-left .arrow::after,
.popover-secondary.bs-popover-auto[x-placement^="left"] .arrow::after {
    border-left-color: #7987a1
}

.popover-success {
    background-color: #10b759
}

.popover-success.bs-popover-top .arrow::after,
.popover-success.bs-popover-auto[x-placement^="top"] .arrow::after {
    border-top-color: #10b759
}

.popover-success.bs-popover-bottom .arrow::after,
.popover-success.bs-popover-auto[x-placement^="bottom"] .arrow::after {
    border-bottom-color: #10b759
}

.popover-success.bs-popover-right .arrow::after,
.popover-success.bs-popover-auto[x-placement^="right"] .arrow::after {
    border-right-color: #10b759
}

.popover-success.bs-popover-left .arrow::after,
.popover-success.bs-popover-auto[x-placement^="left"] .arrow::after {
    border-left-color: #10b759
}

.popover-warning {
    background-color: #ffc107
}

.popover-warning.bs-popover-top .arrow::after,
.popover-warning.bs-popover-auto[x-placement^="top"] .arrow::after {
    border-top-color: #ffc107
}

.popover-warning.bs-popover-bottom .arrow::after,
.popover-warning.bs-popover-auto[x-placement^="bottom"] .arrow::after {
    border-bottom-color: #ffc107
}

.popover-warning.bs-popover-right .arrow::after,
.popover-warning.bs-popover-auto[x-placement^="right"] .arrow::after {
    border-right-color: #ffc107
}

.popover-warning.bs-popover-left .arrow::after,
.popover-warning.bs-popover-auto[x-placement^="left"] .arrow::after {
    border-left-color: #ffc107
}

.popover-danger {
    background-color: #dc3545
}

.popover-danger.bs-popover-top .arrow::after,
.popover-danger.bs-popover-auto[x-placement^="top"] .arrow::after {
    border-top-color: #dc3545
}

.popover-danger.bs-popover-bottom .arrow::after,
.popover-danger.bs-popover-auto[x-placement^="bottom"] .arrow::after {
    border-bottom-color: #dc3545
}

.popover-danger.bs-popover-right .arrow::after,
.popover-danger.bs-popover-auto[x-placement^="right"] .arrow::after {
    border-right-color: #dc3545
}

.popover-danger.bs-popover-left .arrow::after,
.popover-danger.bs-popover-auto[x-placement^="left"] .arrow::after {
    border-left-color: #dc3545
}

.popover-info {
    background-color: #00b8d4
}

.popover-info.bs-popover-top .arrow::after,
.popover-info.bs-popover-auto[x-placement^="top"] .arrow::after {
    border-top-color: #00b8d4
}

.popover-info.bs-popover-bottom .arrow::after,
.popover-info.bs-popover-auto[x-placement^="bottom"] .arrow::after {
    border-bottom-color: #00b8d4
}

.popover-info.bs-popover-right .arrow::after,
.popover-info.bs-popover-auto[x-placement^="right"] .arrow::after {
    border-right-color: #00b8d4
}

.popover-info.bs-popover-left .arrow::after,
.popover-info.bs-popover-auto[x-placement^="left"] .arrow::after {
    border-left-color: #00b8d4
}

.popover-warning .popover-header {
    color: #001737;
    border-bottom-color: rgba(0, 23, 55, 0.05)
}

.popover-warning .popover-body {
    color: rgba(0, 23, 55, 0.8)
}

.progress {
    height: 10px
}

.toast {
    background-color: rgba(255, 255, 255, 0.95);
    border-color: rgba(72, 94, 144, 0.16);
    box-shadow: 0 2px 3px rgba(28, 39, 60, 0.06);
    border-radius: .25rem
}

.toast-header {
    border-bottom-color: rgba(205, 212, 224, 0.4)
}

.toast-header .close {
    font-weight: 300;
    color: #97a3b9
}

.tooltip-inner {
    font-size: 12px;
    padding: 5px 10px
}

.tooltip-primary .tooltip-inner {
    background-color: #0168fa
}

.tooltip-primary.bs-tooltip-top .arrow::before,
.tooltip-primary.bs-tooltip-auto[x-placement^="top"] .arrow::before,
.tooltip-primary.bs-tooltip-auto[x-placement^="top"] .arrow::before {
    border-top-color: #0168fa
}

.tooltip-primary.bs-tooltip-bottom .arrow::before,
.tooltip-primary.bs-tooltip-auto[x-placement^="bottom"] .arrow::before,
.tooltip-primary.bs-tooltip-auto[x-placement^="bottom"] .arrow::before {
    border-bottom-color: #0168fa
}

.tooltip-primary.bs-tooltip-left .arrow::before,
.tooltip-primary.bs-tooltip-auto[x-placement^="left"] .arrow::before,
.tooltip-primary.bs-tooltip-auto[x-placement^="left"] .arrow::before {
    border-left-color: #0168fa
}

.tooltip-primary.bs-tooltip-right .arrow::before,
.tooltip-primary.bs-tooltip-auto[x-placement^="right"] .arrow::before,
.tooltip-primary.bs-tooltip-auto[x-placement^="right"] .arrow::before {
    border-right-color: #0168fa
}

.tooltip-secondary .tooltip-inner {
    background-color: #7987a1
}

.tooltip-secondary.bs-tooltip-top .arrow::before,
.tooltip-secondary.bs-tooltip-auto[x-placement^="top"] .arrow::before,
.tooltip-secondary.bs-tooltip-auto[x-placement^="top"] .arrow::before {
    border-top-color: #7987a1
}

.tooltip-secondary.bs-tooltip-bottom .arrow::before,
.tooltip-secondary.bs-tooltip-auto[x-placement^="bottom"] .arrow::before,
.tooltip-secondary.bs-tooltip-auto[x-placement^="bottom"] .arrow::before {
    border-bottom-color: #7987a1
}

.tooltip-secondary.bs-tooltip-left .arrow::before,
.tooltip-secondary.bs-tooltip-auto[x-placement^="left"] .arrow::before,
.tooltip-secondary.bs-tooltip-auto[x-placement^="left"] .arrow::before {
    border-left-color: #7987a1
}

.tooltip-secondary.bs-tooltip-right .arrow::before,
.tooltip-secondary.bs-tooltip-auto[x-placement^="right"] .arrow::before,
.tooltip-secondary.bs-tooltip-auto[x-placement^="right"] .arrow::before {
    border-right-color: #7987a1
}

.tooltip-success .tooltip-inner {
    background-color: #10b759
}

.tooltip-success.bs-tooltip-top .arrow::before,
.tooltip-success.bs-tooltip-auto[x-placement^="top"] .arrow::before,
.tooltip-success.bs-tooltip-auto[x-placement^="top"] .arrow::before {
    border-top-color: #10b759
}

.tooltip-success.bs-tooltip-bottom .arrow::before,
.tooltip-success.bs-tooltip-auto[x-placement^="bottom"] .arrow::before,
.tooltip-success.bs-tooltip-auto[x-placement^="bottom"] .arrow::before {
    border-bottom-color: #10b759
}

.tooltip-success.bs-tooltip-left .arrow::before,
.tooltip-success.bs-tooltip-auto[x-placement^="left"] .arrow::before,
.tooltip-success.bs-tooltip-auto[x-placement^="left"] .arrow::before {
    border-left-color: #10b759
}

.tooltip-success.bs-tooltip-right .arrow::before,
.tooltip-success.bs-tooltip-auto[x-placement^="right"] .arrow::before,
.tooltip-success.bs-tooltip-auto[x-placement^="right"] .arrow::before {
    border-right-color: #10b759
}

.tooltip-danger .tooltip-inner {
    background-color: #dc3545
}

.tooltip-danger.bs-tooltip-top .arrow::before,
.tooltip-danger.bs-tooltip-auto[x-placement^="top"] .arrow::before,
.tooltip-danger.bs-tooltip-auto[x-placement^="top"] .arrow::before {
    border-top-color: #dc3545
}

.tooltip-danger.bs-tooltip-bottom .arrow::before,
.tooltip-danger.bs-tooltip-auto[x-placement^="bottom"] .arrow::before,
.tooltip-danger.bs-tooltip-auto[x-placement^="bottom"] .arrow::before {
    border-bottom-color: #dc3545
}

.tooltip-danger.bs-tooltip-left .arrow::before,
.tooltip-danger.bs-tooltip-auto[x-placement^="left"] .arrow::before,
.tooltip-danger.bs-tooltip-auto[x-placement^="left"] .arrow::before {
    border-left-color: #dc3545
}

.tooltip-danger.bs-tooltip-right .arrow::before,
.tooltip-danger.bs-tooltip-auto[x-placement^="right"] .arrow::before,
.tooltip-danger.bs-tooltip-auto[x-placement^="right"] .arrow::before {
    border-right-color: #dc3545
}

.tooltip-warning .tooltip-inner {
    background-color: #ffc107
}

.tooltip-warning.bs-tooltip-top .arrow::before,
.tooltip-warning.bs-tooltip-auto[x-placement^="top"] .arrow::before,
.tooltip-warning.bs-tooltip-auto[x-placement^="top"] .arrow::before {
    border-top-color: #ffc107
}

.tooltip-warning.bs-tooltip-bottom .arrow::before,
.tooltip-warning.bs-tooltip-auto[x-placement^="bottom"] .arrow::before,
.tooltip-warning.bs-tooltip-auto[x-placement^="bottom"] .arrow::before {
    border-bottom-color: #ffc107
}

.tooltip-warning.bs-tooltip-left .arrow::before,
.tooltip-warning.bs-tooltip-auto[x-placement^="left"] .arrow::before,
.tooltip-warning.bs-tooltip-auto[x-placement^="left"] .arrow::before {
    border-left-color: #ffc107
}

.tooltip-warning.bs-tooltip-right .arrow::before,
.tooltip-warning.bs-tooltip-auto[x-placement^="right"] .arrow::before,
.tooltip-warning.bs-tooltip-auto[x-placement^="right"] .arrow::before {
    border-right-color: #ffc107
}

.tooltip-info .tooltip-inner {
    background-color: #00b8d4
}

.tooltip-info.bs-tooltip-top .arrow::before,
.tooltip-info.bs-tooltip-auto[x-placement^="top"] .arrow::before,
.tooltip-info.bs-tooltip-auto[x-placement^="top"] .arrow::before {
    border-top-color: #00b8d4
}

.tooltip-info.bs-tooltip-bottom .arrow::before,
.tooltip-info.bs-tooltip-auto[x-placement^="bottom"] .arrow::before,
.tooltip-info.bs-tooltip-auto[x-placement^="bottom"] .arrow::before {
    border-bottom-color: #00b8d4
}

.tooltip-info.bs-tooltip-left .arrow::before,
.tooltip-info.bs-tooltip-auto[x-placement^="left"] .arrow::before,
.tooltip-info.bs-tooltip-auto[x-placement^="left"] .arrow::before {
    border-left-color: #00b8d4
}

.tooltip-info.bs-tooltip-right .arrow::before,
.tooltip-info.bs-tooltip-auto[x-placement^="right"] .arrow::before,
.tooltip-info.bs-tooltip-auto[x-placement^="right"] .arrow::before {
    border-right-color: #00b8d4
}

.tooltip-light .tooltip-inner {
    background-color: #b4bdce
}

.tooltip-light.bs-tooltip-top .arrow::before,
.tooltip-light.bs-tooltip-auto[x-placement^="top"] .arrow::before,
.tooltip-light.bs-tooltip-auto[x-placement^="top"] .arrow::before {
    border-top-color: #b4bdce
}

.tooltip-light.bs-tooltip-bottom .arrow::before,
.tooltip-light.bs-tooltip-auto[x-placement^="bottom"] .arrow::before,
.tooltip-light.bs-tooltip-auto[x-placement^="bottom"] .arrow::before {
    border-bottom-color: #b4bdce
}

.tooltip-light.bs-tooltip-left .arrow::before,
.tooltip-light.bs-tooltip-auto[x-placement^="left"] .arrow::before,
.tooltip-light.bs-tooltip-auto[x-placement^="left"] .arrow::before {
    border-left-color: #b4bdce
}

.tooltip-light.bs-tooltip-right .arrow::before,
.tooltip-light.bs-tooltip-auto[x-placement^="right"] .arrow::before,
.tooltip-light.bs-tooltip-auto[x-placement^="right"] .arrow::before {
    border-right-color: #b4bdce
}

.tooltip-dark .tooltip-inner {
    background-color: #3b4863
}

.tooltip-dark.bs-tooltip-top .arrow::before,
.tooltip-dark.bs-tooltip-auto[x-placement^="top"] .arrow::before,
.tooltip-dark.bs-tooltip-auto[x-placement^="top"] .arrow::before {
    border-top-color: #3b4863
}

.tooltip-dark.bs-tooltip-bottom .arrow::before,
.tooltip-dark.bs-tooltip-auto[x-placement^="bottom"] .arrow::before,
.tooltip-dark.bs-tooltip-auto[x-placement^="bottom"] .arrow::before {
    border-bottom-color: #3b4863
}

.tooltip-dark.bs-tooltip-left .arrow::before,
.tooltip-dark.bs-tooltip-auto[x-placement^="left"] .arrow::before,
.tooltip-dark.bs-tooltip-auto[x-placement^="left"] .arrow::before {
    border-left-color: #3b4863
}

.tooltip-dark.bs-tooltip-right .arrow::before,
.tooltip-dark.bs-tooltip-auto[x-placement^="right"] .arrow::before,
.tooltip-dark.bs-tooltip-auto[x-placement^="right"] .arrow::before {
    border-right-color: #3b4863
}

.table th,
.table td {
    padding: 8px 10px;
    line-height: 1.5
}

.table thead th,
.table tbody th {
    font-weight: 500
}

.table thead th {
    border-bottom-width: 0
}

.table-striped tbody tr:nth-of-type(2n+1) {
    background-color: #f3f4f7
}

.table-hover tbody tr:hover {
    background-color: #e9ecf1
}

.table-sm th,
.table-sm td {
    padding: 5px 10px
}

.thead-primary,
.thead-secondary,
.thead-success,
.thead-danger,
.thead-warning,
.thead-info,
.thead-light,
.thead-dark {
    color: #fff
}

.thead-primary th,
.thead-secondary th,
.thead-success th,
.thead-danger th,
.thead-warning th,
.thead-info th,
.thead-light th,
.thead-dark th {
    border-top-width: 0
}

.thead-primary+tbody tr:first-child th,
.thead-primary+tbody tr:first-child td,
.thead-secondary+tbody tr:first-child th,
.thead-secondary+tbody tr:first-child td,
.thead-success+tbody tr:first-child th,
.thead-success+tbody tr:first-child td,
.thead-danger+tbody tr:first-child th,
.thead-danger+tbody tr:first-child td,
.thead-warning+tbody tr:first-child th,
.thead-warning+tbody tr:first-child td,
.thead-info+tbody tr:first-child th,
.thead-info+tbody tr:first-child td,
.thead-light+tbody tr:first-child th,
.thead-light+tbody tr:first-child td,
.thead-dark+tbody tr:first-child th,
.thead-dark+tbody tr:first-child td {
    border-top-width: 0
}

.thead-primary {
    background-color: #0168fa
}

.thead-secondary {
    background-color: #7987a1
}

.thead-success {
    background-color: #10b759
}

.thead-danger {
    background-color: #dc3545
}

.thead-warning {
    background-color: #ffc107
}

.thead-info {
    background-color: #00b8d4
}

.thead-dark {
    background-color: #3b4863
}

.thead-light {
    background-color: rgba(72, 94, 144, 0.16);
    color: #596882
}

.table-primary thead,
.table-secondary thead,
.table-success thead,
.table-danger thead,
.table-warning thead,
.table-info thead,
.table-light thead,
.table-dark thead {
    color: #fff
}

.table-primary thead th,
.table-secondary thead th,
.table-success thead th,
.table-danger thead th,
.table-warning thead th,
.table-info thead th,
.table-light thead th,
.table-dark thead th {
    border-top-width: 0
}

.table-primary tbody tr th,
.table-primary tbody tr td,
.table-secondary tbody tr th,
.table-secondary tbody tr td,
.table-success tbody tr th,
.table-success tbody tr td,
.table-danger tbody tr th,
.table-danger tbody tr td,
.table-warning tbody tr th,
.table-warning tbody tr td,
.table-info tbody tr th,
.table-info tbody tr td,
.table-light tbody tr th,
.table-light tbody tr td,
.table-dark tbody tr th,
.table-dark tbody tr td {
    border-top-color: rgba(255, 255, 255, 0.45)
}

.table-primary tbody tr:first-child th,
.table-primary tbody tr:first-child td,
.table-secondary tbody tr:first-child th,
.table-secondary tbody tr:first-child td,
.table-success tbody tr:first-child th,
.table-success tbody tr:first-child td,
.table-danger tbody tr:first-child th,
.table-danger tbody tr:first-child td,
.table-warning tbody tr:first-child th,
.table-warning tbody tr:first-child td,
.table-info tbody tr:first-child th,
.table-info tbody tr:first-child td,
.table-light tbody tr:first-child th,
.table-light tbody tr:first-child td,
.table-dark tbody tr:first-child th,
.table-dark tbody tr:first-child td {
    border-top-width: 0
}

.table-primary thead {
    background-color: #0168fa
}

.table-primary tbody tr {
    background-color: #f1f7ff
}

.table-primary.table-striped tbody tr:nth-of-type(2n+1) {
    background-color: #e2eeff
}

.table-primary.table-hover tbody tr:hover {
    background-color: #d2e5ff
}

.table-secondary thead {
    background-color: #7987a1
}

.table-secondary tbody tr {
    background-color: #e3e7ed
}

.table-secondary.table-striped tbody tr:nth-of-type(2n+1) {
    background-color: #dadfe7
}

.table-secondary.table-hover tbody tr:hover {
    background-color: #d0d7e1
}

.table-success thead {
    background-color: #10b759
}

.table-success tbody tr {
    background-color: #e8fdf1
}

.table-success.table-striped tbody tr:nth-of-type(2n+1) {
    background-color: #dafce9
}

.table-success.table-hover tbody tr:hover {
    background-color: #ccfae0
}

.table-danger thead {
    background-color: #dc3545
}

.table-danger tbody tr {
    background-color: #fef9f9
}

.table-danger.table-striped tbody tr:nth-of-type(2n+1) {
    background-color: #fceced
}

.table-danger.table-hover tbody tr:hover {
    background-color: #f9dfe1
}

.table-warning thead {
    background-color: #ffc107
}

.table-warning tbody tr {
    background-color: #fffbf8
}

.table-warning.table-striped tbody tr:nth-of-type(2n+1) {
    background-color: #fff3e8
}

.table-warning.table-hover tbody tr:hover {
    background-color: #ffead9
}

.table-info thead {
    background-color: #00b8d4
}

.table-info tbody tr {
    background-color: #eefdff
}

.table-info.table-striped tbody tr:nth-of-type(2n+1) {
    background-color: #defbff
}

.table-info.table-hover tbody tr:hover {
    background-color: #cff9ff
}

.table-light thead {
    background-color: #e3e7ed
}

.table-light tbody tr {
    background-color: #f9fafb
}

.table-light.table-striped tbody tr:nth-of-type(2n+1) {
    background-color: #eff2f5
}

.table-light.table-hover tbody tr:hover {
    background-color: #e6eaef
}

.table-dark thead {
    background-color: #3b4863
}

.table-dark tbody tr {
    background-color: #e3e7ed
}

.table-dark.table-striped tbody tr:nth-of-type(2n+1) {
    background-color: #dadfe7
}

.table-dark.table-hover tbody tr:hover {
    background-color: #d0d7e1
}

.table-light thead {
    color: #001737
}

.table-dark tbody {
    color: #001737
}

.accordion {
    border-radius: .25rem
}

.ui-accordion-header {
    font-size: 14px;
    font-weight: 500;
    color: #1b2e4b;
    margin-bottom: 0;
    border: 1px solid rgba(72, 94, 144, 0.16);
    background-color: #f5f6fa;
    padding: 12px 15px 12px 35px;
    position: relative;
    outline: none;
    cursor: pointer;
    transition: all 0.2s ease-in-out
}

@media (prefers-reduced-motion: reduce) {
    .ui-accordion-header {
        transition: none
    }
}

.ui-accordion-header~.ui-accordion-header {
    margin-top: -1px
}

.ui-accordion-header:first-of-type {
    border-top-left-radius: .25rem;
    border-top-right-radius: .25rem
}

.ui-accordion-header:last-of-type:not(.ui-state-active) {
    border-bottom-right-radius: .25rem;
    border-bottom-left-radius: .25rem
}

.ui-accordion-header:hover {
    color: #001737;
    background-color: #eef0f7
}

.ui-accordion-header .ui-accordion-header-icon {
    position: absolute;
    left: 15px;
    top: 12px;
    display: inline-block;
    margin-right: 10px
}

.ui-accordion-header .ui-accordion-header-icon::before {
    content: '\f273';
    font-family: 'Ionicons';
    font-size: 14px;
    display: inline-block
}

.ui-accordion-header.ui-state-active {
    background-color: #fff;
    border-bottom-width: 0;
    color: #0168fa
}

.ui-accordion-header.ui-state-active .ui-accordion-header-icon::before {
    content: '\f368'
}

.ui-accordion-content {
    border: 1px solid rgba(72, 94, 144, 0.16);
    border-top-width: 0;
    padding: 0 20px 20px 35px;
    position: relative
}

.ui-accordion-content:last-of-type {
    border-bottom-right-radius: .25rem;
    border-bottom-left-radius: .25rem
}

.accordion-style1 .ui-accordion-header.ui-state-active {
    border-color: #0168fa;
    border-top-left-radius: .25rem;
    border-top-right-radius: .25rem;
    background-color: #f1f7ff
}

.accordion-style1 .ui-accordion-content.ui-accordion-content-active {
    z-index: 5;
    border-color: #0168fa;
    border-bottom-right-radius: .25rem;
    border-bottom-left-radius: .25rem;
    background-color: #f1f7ff
}

.accordion-style2 {
    box-shadow: none
}

.accordion-style2 .ui-accordion-header {
    border-width: 0;
    background-color: #0168fa;
    color: #fff;
    border-width: 2px;
    border-bottom-width: 0;
    border-color: transparent;
    border-radius: .25rem
}

.accordion-style2 .ui-accordion-header~.ui-accordion-header {
    margin-top: 5px
}

.accordion-style2 .ui-accordion-header.ui-state-active {
    border-bottom-right-radius: 0;
    border-bottom-left-radius: 0;
    border-top-left-radius: 3px;
    border-top-right-radius: 3px;
    background-color: #f1f7ff;
    color: #0168fa;
    border-color: #0168fa
}

.accordion-style2 .ui-accordion-content {
    background-color: #f1f7ff;
    border-width: 2px;
    border-top-width: 0;
    border-color: #0168fa
}

.accordion-style2 .ui-accordion-content.ui-accordion-content-active {
    border-top-left-radius: 0;
    border-top-right-radius: 0;
    border-bottom-right-radius: 3px;
    border-bottom-left-radius: 3px
}

.accordion-pink .ui-accordion-header.ui-state-active {
    color: #f10075
}

.accordion-pink.accordion-style1 .ui-accordion-header.ui-state-active {
    border-color: #f10075;
    background-color: #fffbfd
}

.accordion-pink.accordion-style1 .ui-accordion-content.ui-accordion-content-active {
    border-color: #f10075;
    background-color: #fffbfd
}

.accordion-pink.accordion-style2 .ui-accordion-header {
    background-color: #f10075
}

.accordion-pink.accordion-style2 .ui-accordion-header:hover {
    background-color: #e70070
}

.accordion-pink.accordion-style2 .ui-accordion-header.ui-state-active {
    background-color: #fffbfd;
    color: #f10075;
    border-color: #f10075
}

.accordion-pink.accordion-style2 .ui-accordion-content {
    background-color: #fffbfd;
    border-color: #f10075
}

.accordion-teal .ui-accordion-header.ui-state-active {
    color: #0cc
}

.accordion-teal.accordion-style1 .ui-accordion-header.ui-state-active {
    border-color: #0cc;
    background-color: #ebffff
}

.accordion-teal.accordion-style1 .ui-accordion-content.ui-accordion-content-active {
    border-color: #0cc;
    background-color: #ebffff
}

.accordion-teal.accordion-style2 .ui-accordion-header {
    background-color: #0cc
}

.accordion-teal.accordion-style2 .ui-accordion-header:hover {
    background-color: #00c2c2
}

.accordion-teal.accordion-style2 .ui-accordion-header.ui-state-active {
    background-color: #ebffff;
    color: #0cc;
    border-color: #0cc
}

.accordion-teal.accordion-style2 .ui-accordion-content {
    background-color: #ebffff;
    border-color: #0cc
}

.accordion-dark .ui-accordion-header.ui-state-active {
    color: #596882
}

.accordion-dark.accordion-style1 .ui-accordion-header.ui-state-active {
    border-color: #596882;
    background-color: #e3e7ed
}

.accordion-dark.accordion-style1 .ui-accordion-content.ui-accordion-content-active {
    border-color: #596882;
    background-color: #e3e7ed
}

.accordion-dark.accordion-style2 .ui-accordion-header {
    background-color: #596882
}

.accordion-dark.accordion-style2 .ui-accordion-header:hover {
    background-color: #55637c
}

.accordion-dark.accordion-style2 .ui-accordion-header.ui-state-active {
    background-color: #e3e7ed;
    color: #596882;
    border-color: #596882
}

.accordion-dark.accordion-style2 .ui-accordion-content {
    background-color: #e3e7ed;
    border-color: #596882
}

.avatar {
    position: relative;
    width: 38px;
    height: 38px
}

.avatar img {
    width: 100%;
    height: 100%;
    object-fit: cover
}

.avatar-initial {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: #8392a5;
    color: #fff;
    font-weight: 700;
    font-size: 15px;
    font-family: -apple-system, BlinkMacSystemFont, "Inter UI", Roboto, sans-serif;
    text-transform: uppercase;
    display: flex;
    align-items: center;
    justify-content: center
}

.avatar-offline::after,
.avatar-online::after {
    content: '';
    position: absolute;
    bottom: 0;
    right: 2px;
    width: 8px;
    height: 8px;
    border-radius: 100%;
    box-shadow: 0 0 0 2px #fff
}

.avatar-offline::after {
    background-color: #c0ccda
}

.avatar-online::after {
    background-color: #10b759
}

.avatar-xs {
    width: 26px;
    height: 26px
}

.avatar-xs .avatar-initial {
    font-size: 10px
}

.avatar-xs.avatar-offline::after,
.avatar-xs.avatar-online::after {
    width: 5px;
    height: 5px
}

.avatar-sm {
    width: 32px;
    height: 32px
}

.avatar-sm .avatar-initial {
    font-size: 13px
}

.avatar-sm.avatar-offline::after,
.avatar-sm.avatar-online::after {
    width: 7px;
    height: 7px
}

.avatar-md {
    width: 48px;
    height: 48px
}

.avatar-md .avatar-initial {
    font-size: 18px
}

.avatar-md.avatar-offline::after,
.avatar-md.avatar-online::after {
    width: 9px;
    height: 9px;
    right: 2px;
    bottom: 2px
}

.avatar-lg {
    width: 64px;
    height: 64px
}

.avatar-lg .avatar-initial {
    font-size: 24px
}

.avatar-lg.avatar-offline::after,
.avatar-lg.avatar-online::after {
    width: 10px;
    height: 10px;
    bottom: 3px;
    right: 4px
}

.avatar-xl {
    width: 72px;
    height: 72px
}

.avatar-xl .avatar-initial {
    font-size: 30px
}

.avatar-xl.avatar-offline::after,
.avatar-xl.avatar-online::after {
    width: 11px;
    height: 11px;
    bottom: 4px;
    right: 5px;
    box-shadow: 0 0 0 2.5px #fff
}

.avatar-xxl {
    width: 100px;
    height: 100px
}

.avatar-xxl .avatar-initial {
    font-size: 45px
}

.avatar-xxl.avatar-offline::after,
.avatar-xxl.avatar-online::after {
    width: 13px;
    height: 13px;
    bottom: 6px;
    right: 7px;
    box-shadow: 0 0 0 3px #fff
}

.avatar-group {
    display: flex;
    align-items: center
}

.avatar-group .avatar {
    position: relative;
    width: 20px;
    height: 20px;
    line-height: 0
}

.avatar-group .avatar img {
    border: 1.5px solid #fff
}

.avatar-group .avatar+.avatar {
    margin-left: -5px
}

.avatar-group .avatar-add {
    margin-left: 5px;
    display: flex;
    align-items: center;
    justify-content: center;
    width: 18px;
    height: 18px;
    border: 1.5px solid #b4bdce;
    border-radius: 100%;
    color: #97a3b9
}

.avatar-group .avatar-add svg {
    width: 12px;
    height: 12px;
    stroke-width: 3px
}

.button-group {
    display: flex;
    align-items: stretch;
    height: 38px
}

.button-group .btn,
.button-group .ui-datepicker-buttonpane button,
.ui-datepicker-buttonpane .button-group button,
.button-group .sp-container button,
.sp-container .button-group button {
    display: flex;
    align-items: center;
    font-size: inherit;
    font-weight: inherit
}

.button-group .btn+.btn,
.button-group .ui-datepicker-buttonpane button+.btn,
.ui-datepicker-buttonpane .button-group button+.btn,
.button-group .sp-container button+.btn,
.sp-container .button-group button+.btn,
.button-group .ui-datepicker-buttonpane .btn+button,
.ui-datepicker-buttonpane .button-group .btn+button,
.button-group .ui-datepicker-buttonpane button+button,
.ui-datepicker-buttonpane .button-group button+button,
.button-group .sp-container .ui-datepicker-buttonpane button+button,
.ui-datepicker-buttonpane .sp-container .button-group button+button,
.button-group .sp-container .btn+button,
.sp-container .button-group .btn+button,
.button-group .ui-datepicker-buttonpane .sp-container button+button,
.sp-container .button-group .ui-datepicker-buttonpane button+button,
.ui-datepicker-buttonpane .button-group .sp-container button+button,
.sp-container .ui-datepicker-buttonpane .button-group button+button,
.button-group .sp-container button+button,
.sp-container .button-group button+button {
    margin-left: 5px
}

.card-profile {
    border-width: 0
}

.card-profile .card-img-top {
    height: 100px;
    object-fit: cover
}

.card-profile .card-body {
    padding: 15px;
    border: 1px solid rgba(72, 94, 144, 0.16);
    border-bottom-right-radius: .25rem;
    border-bottom-left-radius: .25rem
}

@media (min-width: 992px) {
    .card-profile .card-body {
        padding: 20px
    }
}

.card-profile .card-body>div {
    display: flex;
    flex-direction: column;
    align-items: center;
    position: relative;
    margin-top: -55px
}

.card-profile h5 {
    font-size: 14px;
    margin-top: 15px;
    margin-bottom: 0
}

@media (min-width: 992px) {
    .card-profile h5 {
        font-size: 15px
    }
}

.card-profile h5 a {
    color: #1b2e4b
}

.card-profile h5 a:hover,
.card-profile h5 a:focus {
    color: #0168fa
}

.card-profile p {
    color: #8392a5
}

.card-event {
    border-width: 0
}

.card-event .card-img-top {
    height: 150px;
    object-fit: cover
}

.card-event .card-body {
    border: 1px solid rgba(72, 94, 144, 0.16);
    border-top-width: 0;
    padding: 18px 20px
}

.card-event .card-body h5 {
    font-size: 15px;
    margin-bottom: 0
}

.card-event .card-body h5 a {
    color: #1b2e4b
}

.card-event .card-body h5 a:hover,
.card-event .card-body h5 a:focus {
    color: #0168fa
}

.card-event .card-footer {
    border: 1px solid rgba(72, 94, 144, 0.16);
    border-top-width: 0;
    border-bottom-right-radius: .25rem;
    border-bottom-left-radius: .25rem;
    display: flex;
    align-items: center;
    justify-content: space-between
}

.card-help .card-body {
    display: flex;
    flex-direction: column;
    align-items: center;
    text-align: center
}

.card-help .card-footer {
    display: flex;
    align-items: center;
    justify-content: space-between
}

.card-help .card-footer .icon::before {
    width: auto
}

.divider-text {
    position: relative;
    display: flex;
    align-items: center;
    text-transform: uppercase;
    color: #8392a5;
    font-size: 10px;
    font-weight: 500;
    font-family: -apple-system, BlinkMacSystemFont, "Inter UI", Roboto, sans-serif;
    letter-spacing: .5px;
    margin: 15px 0
}

.divider-text::before,
.divider-text::after {
    content: '';
    display: block;
    flex: 1;
    height: 1px;
    background-color: #e5e9f2
}

.divider-text::before {
    margin-right: 10px
}

.divider-text::after {
    margin-left: 10px
}

.divider-vertical {
    flex-direction: column;
    margin: 0 15px
}

.divider-vertical::before,
.divider-vertical::after {
    height: auto;
    width: 1px
}

.divider-vertical::before {
    margin-right: 0;
    margin-bottom: 10px
}

.divider-vertical::after {
    margin-left: 0;
    margin-top: 10px
}

.dropdown-message .dropdown-link,
.dropdown-notification .dropdown-link {
    position: relative;
    color: #8392a5
}

.dropdown-message .dropdown-link svg,
.dropdown-notification .dropdown-link svg {
    width: 16px;
    height: 16px;
    stroke-width: 3px
}

@media (min-width: 576px) {

    .dropdown-message .dropdown-link svg,
    .dropdown-notification .dropdown-link svg {
        width: 20px;
        height: 20px;
        stroke-width: 2.25px
    }
}

.dropdown-message .dropdown-link.new-indicator,
.dropdown-notification .dropdown-link.new-indicator {
    color: #1b2e4b
}

.dropdown-message .dropdown-link.new-indicator span,
.dropdown-notification .dropdown-link.new-indicator span {
    display: flex;
    width: 15px;
    height: 15px;
    justify-content: center;
    position: absolute;
    top: -8px;
    right: -2px;
    background-color: #dc3545;
    color: #fff;
    font-size: 8px;
    font-weight: 400;
    font-family: sans-serif;
    line-height: 2;
    border-radius: 100%
}

.dropdown-message .dropdown-link:hover,
.dropdown-message .dropdown-link:focus,
.dropdown-notification .dropdown-link:hover,
.dropdown-notification .dropdown-link:focus {
    color: #001737
}

.dropdown-message .dropdown-menu,
.dropdown-notification .dropdown-menu {
    width: 300px;
    padding: 0;
    margin-right: -20px;
    box-shadow: none
}

.dropdown-message .dropdown-menu::before,
.dropdown-notification .dropdown-menu::before {
    content: '';
    position: absolute;
    top: -10px;
    left: 25px;
    border-bottom: 10px solid rgba(72, 94, 144, 0.16);
    border-left: 10px solid transparent;
    border-right: 10px solid transparent
}

.dropdown-message .dropdown-menu::after,
.dropdown-notification .dropdown-menu::after {
    content: '';
    position: absolute;
    top: -8.5px;
    left: 26px;
    border-bottom: 9px solid #fff;
    border-left: 9px solid transparent;
    border-right: 9px solid transparent
}

.dropdown-message .dropdown-menu.dropdown-menu-right,
.dropdown-notification .dropdown-menu.dropdown-menu-right {
    margin-right: -20px
}

.dropdown-message .dropdown-menu.dropdown-menu-right::before,
.dropdown-notification .dropdown-menu.dropdown-menu-right::before {
    left: auto;
    right: 20px
}

.dropdown-message .dropdown-menu.dropdown-menu-right::after,
.dropdown-notification .dropdown-menu.dropdown-menu-right::after {
    left: auto;
    right: 21px
}

.dropdown-message .dropdown-header,
.dropdown-notification .dropdown-header {
    padding: 12px 15px;
    text-transform: uppercase;
    font-size: 12px;
    font-weight: 600;
    letter-spacing: .5px;
    color: #1b2e4b;
    border-bottom: 1px solid rgba(72, 94, 144, 0.16);
    margin-bottom: 5px
}

.dropdown-message .dropdown-item,
.dropdown-notification .dropdown-item {
    transition: none;
    border-radius: .25rem;
    white-space: normal;
    padding: 8px 10px;
    margin: 0 5px;
    width: auto
}

@media (prefers-reduced-motion: reduce) {

    .dropdown-message .dropdown-item,
    .dropdown-notification .dropdown-item {
        transition: none
    }
}

.dropdown-message .dropdown-item:hover,
.dropdown-message .dropdown-item:focus,
.dropdown-notification .dropdown-item:hover,
.dropdown-notification .dropdown-item:focus {
    background-color: rgba(229, 233, 242, 0.5)
}

.dropdown-message .dropdown-item:hover .avatar-online::after,
.dropdown-message .dropdown-item:hover .avatar-offline::after,
.dropdown-message .dropdown-item:focus .avatar-online::after,
.dropdown-message .dropdown-item:focus .avatar-offline::after,
.dropdown-notification .dropdown-item:hover .avatar-online::after,
.dropdown-notification .dropdown-item:hover .avatar-offline::after,
.dropdown-notification .dropdown-item:focus .avatar-online::after,
.dropdown-notification .dropdown-item:focus .avatar-offline::after {
    box-shadow: 0 0 0 2px #f2f4f8
}

.dropdown-message .avatar,
.dropdown-notification .avatar {
    flex-shrink: 0;
    margin-top: 2px
}

.dropdown-message .media-body,
.dropdown-notification .media-body {
    font-size: 13px;
    color: rgba(27, 46, 75, 0.85)
}

.dropdown-message .media-body p,
.dropdown-notification .media-body p {
    margin-bottom: 2px
}

.dropdown-message .media-body span,
.dropdown-notification .media-body span {
    color: #8392a5;
    font-size: 11px;
    font-family: -apple-system, BlinkMacSystemFont, "Inter UI", Roboto, sans-serif
}

.dropdown-message .media-body strong,
.dropdown-notification .media-body strong {
    font-weight: 500;
    color: #1b2e4b
}

.dropdown-message .dropdown-footer,
.dropdown-notification .dropdown-footer {
    margin-top: 5px;
    border-top: 1px solid rgba(72, 94, 144, 0.16);
    padding: 10px;
    text-align: center;
    font-size: 10px;
    font-weight: 500;
    font-family: -apple-system, BlinkMacSystemFont, "Inter UI", Roboto, sans-serif;
    letter-spacing: .5px;
    text-transform: uppercase
}

.dropdown-message .dropdown-footer a,
.dropdown-notification .dropdown-footer a {
    color: #8392a5;
    transition: all 0.2s ease-in-out
}

@media (prefers-reduced-motion: reduce) {

    .dropdown-message .dropdown-footer a,
    .dropdown-notification .dropdown-footer a {
        transition: none
    }
}

.dropdown-message .dropdown-footer a:hover,
.dropdown-message .dropdown-footer a:focus,
.dropdown-notification .dropdown-footer a:hover,
.dropdown-notification .dropdown-footer a:focus {
    color: #1b2e4b
}

.dropdown-profile .dropdown-link {
    display: flex;
    align-items: center
}

.dropdown-profile .dropdown-link:hover .avatar img,
.dropdown-profile .dropdown-link:focus .avatar img {
    transform: scale(1.23, 1.23);
    padding: 3px;
    background-color: #f5f6fa
}

.dropdown-profile .dropdown-menu {
    border-color: rgba(72, 94, 144, 0.16);
    width: 230px;
    padding: 25px;
    margin-top: 14.5px;
    box-shadow: none;
    border-bottom-right-radius: .25rem;
    border-bottom-left-radius: .25rem
}

.dropdown-profile .dropdown-menu::before {
    content: '';
    position: absolute;
    top: -10px;
    left: 25px;
    border-bottom: 10px solid rgba(72, 94, 144, 0.16);
    border-left: 10px solid transparent;
    border-right: 10px solid transparent
}

.dropdown-profile .dropdown-menu::after {
    content: '';
    position: absolute;
    top: -8.5px;
    left: 26px;
    border-bottom: 9px solid #fff;
    border-left: 9px solid transparent;
    border-right: 9px solid transparent
}

.dropdown-profile .dropdown-menu.dropdown-menu-right {
    margin-right: -10px
}

.dropdown-profile .dropdown-menu.dropdown-menu-right::before {
    left: auto;
    right: 15px
}

.dropdown-profile .dropdown-menu.dropdown-menu-right::after {
    left: auto;
    right: 16px
}

.dropdown-profile .dropdown-divider {
    margin: 15px 0
}

.dropdown-profile .dropdown-item {
    padding: 0;
    display: flex;
    align-items: center;
    color: #1b2e4b
}

.dropdown-profile .dropdown-item+.dropdown-item {
    margin-top: 10px
}

.dropdown-profile .dropdown-item svg {
    width: 16px;
    height: 16px;
    margin-right: 15px
}

.dropdown-profile .dropdown-item:hover,
.dropdown-profile .dropdown-item:focus {
    background-color: transparent;
    color: #0168fa
}

.dropdown-icon .dropdown-item {
    display: flex;
    align-items: center;
    padding: 5px 10px
}

.dropdown-icon .dropdown-item svg {
    width: 16px;
    height: 16px;
    margin-right: 10px
}

.dropdown-icon .dropdown-item span {
    display: block;
    position: relative;
    top: 1.5px
}

.dropdown-icon .dropdown-divider {
    margin: 10px 15px
}

.img-group {
    display: flex;
    align-items: center;
    justify-content: center
}

.img-group .img {
    position: relative;
    background-color: #fff;
    padding: 2px
}

@media (min-width: 576px) {
    .img-group .img {
        padding: 3px
    }
}

.img-group .img+.img {
    margin-left: -15px
}

@media (min-width: 576px) {
    .img-group .img+.img {
        margin-left: -25px
    }
}

.img-group.img-group-sm .img+.img {
    margin-left: -15px
}

.link-01 {
    color: #001737
}

.link-01:hover,
.link-01:focus {
    color: #0168fa
}

.link-02 {
    color: #1b2e4b
}

.link-02:hover,
.link-02:focus {
    color: #001737
}

.link-03 {
    color: #8392a5
}

.link-03:hover,
.link-03:focus {
    color: #1b2e4b
}

.hover-primary:hover,
.hover-primary:focus {
    color: #0168fa
}

.activity {
    margin: 0;
    padding: 0;
    list-style: none
}

.activity-item {
    display: flex;
    padding: 5px 0 10px;
    position: relative;
    min-height: 70px
}

.activity-item::before {
    content: '';
    position: absolute;
    top: 50px;
    bottom: 0;
    left: 20px;
    border-left: 2px dotted #c0ccda
}

.activity-item:last-child {
    padding-bottom: 0;
    min-height: inherit
}

.activity-icon {
    width: 40px;
    height: 40px;
    background-color: #e5e9f2;
    border-radius: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-shrink: 0;
    color: #8392a5
}

.activity-icon svg {
    width: 20px;
    height: 20px
}

.activity-body {
    margin-left: 15px;
    flex: 1;
    color: #5c6c7f
}

.activity-body strong {
    color: #1b2e4b;
    font-weight: 500
}

.activity-body small {
    font-size: 11px
}

.marker {
    background-color: #f3f5f9;
    color: #001737;
    padding: 5px 10px;
    font-size: 10px;
    font-weight: 700;
    font-family: -apple-system, BlinkMacSystemFont, "Inter UI", Roboto, sans-serif;
    letter-spacing: .5px;
    text-transform: uppercase
}

.marker-icon {
    position: relative;
    overflow: hidden;
    display: flex;
    width: 45px;
    height: 45px
}

.marker-icon::before {
    content: '';
    position: absolute;
    top: -70%;
    left: -70%;
    width: 140%;
    height: 140%;
    background-color: #f3f5f9;
    transform: rotate(45deg)
}

.marker-icon svg {
    width: 16px;
    height: 16px;
    margin-top: calc(30% - 8px);
    margin-left: calc(30% - 8px);
    position: relative;
    z-index: 1
}

.marker-icon.marker-top-right::before {
    left: auto;
    right: -70%
}

.marker-icon.marker-top-right svg {
    margin-left: auto;
    margin-right: calc(30% - 8px)
}

.marker-icon.marker-bottom-left::before {
    top: auto;
    bottom: -70%
}

.marker-icon.marker-bottom-left svg {
    margin-top: auto;
    margin-bottom: calc(30% - 8px)
}

.marker-icon.marker-bottom-right::before {
    top: auto;
    bottom: -70%;
    left: auto;
    right: -70%
}

.marker-icon.marker-bottom-right svg {
    margin-top: auto;
    margin-left: auto;
    margin-bottom: calc(30% - 8px);
    margin-right: calc(30% - 8px)
}

.marker-ribbon {
    margin-left: -10px;
    box-shadow: 1px 1px 1px rgba(28, 39, 60, 0.16)
}

.marker-ribbon::after {
    content: '';
    position: absolute;
    bottom: -6px;
    left: 0;
    border-bottom: 7px solid #b4bdce;
    border-left: 7px solid transparent;
    border-right: 7px solid transparent;
    transform: rotate(45deg)
}

.marker-ribbon.marker-top-right {
    margin-left: 0;
    margin-right: -10px
}

.marker-ribbon.marker-top-right::after {
    left: auto;
    right: 0;
    transform: rotate(-45deg)
}

.marker-primary {
    color: #fff
}

.marker-primary.marker-icon::before {
    background-color: #0168fa
}

.marker-primary.marker-ribbon {
    background-color: #0168fa
}

.marker-primary.marker-ribbon::after {
    border-bottom-color: #0148ae
}

.marker-secondary {
    color: #fff
}

.marker-secondary.marker-icon::before {
    background-color: #97a3b9
}

.marker-secondary.marker-ribbon {
    background-color: #97a3b9
}

.marker-secondary.marker-ribbon::after {
    border-bottom-color: #697b9a
}

.marker-success {
    color: #fff
}

.marker-success.marker-icon::before {
    background-color: #10b759
}

.marker-success.marker-ribbon {
    background-color: #10b759
}

.marker-success.marker-ribbon::after {
    border-bottom-color: #0a7137
}

.marker-danger {
    color: #fff
}

.marker-danger.marker-icon::before {
    background-color: #dc3545
}

.marker-danger.marker-ribbon {
    background-color: #dc3545
}

.marker-danger.marker-ribbon::after {
    border-bottom-color: #a71d2a
}

.marker-warning {
    color: #fff
}

.marker-warning.marker-icon::before {
    background-color: #ffc107
}

.marker-warning.marker-ribbon {
    background-color: #ffc107
}

.marker-warning.marker-ribbon::after {
    border-bottom-color: #ba8b00
}

.marker-info {
    color: #fff
}

.marker-info.marker-icon::before {
    background-color: #00b8d4
}

.marker-info.marker-ribbon {
    background-color: #00b8d4
}

.marker-info.marker-ribbon::after {
    border-bottom-color: #007688
}

.marker-dark {
    color: #fff
}

.marker-dark.marker-icon::before {
    background-color: #1c273c
}

.marker-dark.marker-ribbon {
    background-color: #1c273c
}

.marker-dark.marker-ribbon::after {
    border-bottom-color: #040508
}

.modal.animated .modal-dialog {
    transform: translate(0, 0)
}

.modal.effect-scale .modal-dialog {
    transform: scale(0.7);
    opacity: 0;
    transition: all 0.3s
}

.modal.effect-scale.show .modal-dialog {
    transform: scale(1);
    opacity: 1
}

.modal.effect-slide-in-right .modal-dialog {
    transform: translateX(20%);
    opacity: 0;
    transition: all 0.3s cubic-bezier(0.25, 0.5, 0.5, 0.9)
}

.modal.effect-slide-in-right.show .modal-dialog {
    transform: translateX(0);
    opacity: 1
}

.modal.effect-slide-in-bottom .modal-dialog {
    transform: translateY(20%);
    opacity: 0;
    transition: all 0.3s
}

.modal.effect-slide-in-bottom.show .modal-dialog {
    transform: translateY(0);
    opacity: 1
}

.modal.effect-newspaper .modal-dialog {
    transform: scale(0) rotate(720deg);
    opacity: 0
}

.modal.effect-newspaper.show~.modal-backdrop,
.modal.effect-newspaper .modal-dialog {
    transition: all 0.5s
}

.modal.effect-newspaper.show .modal-dialog {
    transform: scale(1) rotate(0deg);
    opacity: 1
}

.modal.effect-fall {
    -webkit-perspective: 1300px;
    -moz-perspective: 1300px;
    perspective: 1300px
}

.modal.effect-fall .modal-dialog {
    -moz-transform-style: preserve-3d;
    transform-style: preserve-3d;
    transform: translateZ(600px) rotateX(20deg);
    opacity: 0
}

.modal.effect-fall.show .modal-dialog {
    transition: all 0.3s ease-in;
    transform: translateZ(0px) rotateX(0deg);
    opacity: 1
}

.modal.effect-flip-horizontal {
    perspective: 1300px
}

.modal.effect-flip-horizontal .modal-dialog {
    -moz-transform-style: preserve-3d;
    transform-style: preserve-3d;
    transform: rotateY(-70deg);
    transition: all 0.3s;
    opacity: 0
}

.modal.effect-flip-horizontal.show .modal-dialog {
    transform: rotateY(0deg);
    opacity: 1
}

.modal.effect-flip-vertical {
    perspective: 1300px
}

.modal.effect-flip-vertical .modal-dialog {
    -moz-transform-style: preserve-3d;
    transform-style: preserve-3d;
    transform: rotateX(-70deg);
    transition: all 0.3s;
    opacity: 0
}

.modal.effect-flip-vertical.show .modal-dialog {
    transform: rotateX(0deg);
    opacity: 1
}

.modal.effect-super-scaled .modal-dialog {
    transform: scale(2);
    opacity: 0;
    transition: all 0.3s
}

.modal.effect-super-scaled.show .modal-dialog {
    transform: scale(1);
    opacity: 1
}

.modal.effect-sign {
    perspective: 1300px
}

.modal.effect-sign .modal-dialog {
    -moz-transform-style: preserve-3d;
    transform-style: preserve-3d;
    transform: rotateX(-60deg);
    transform-origin: 50% 0;
    opacity: 0;
    transition: all 0.3s
}

.modal.effect-sign.show .modal-dialog {
    transform: rotateX(0deg);
    opacity: 1
}

.modal.effect-rotate-bottom {
    perspective: 1300px
}

.modal.effect-rotate-bottom .modal-dialog {
    -moz-transform-style: preserve-3d;
    transform-style: preserve-3d;
    transform: translateY(100%) rotateX(90deg);
    transform-origin: 0 100%;
    opacity: 0;
    transition: all 0.3s ease-out
}

.modal.effect-rotate-bottom.show .modal-dialog {
    transform: translateY(0%) rotateX(0deg);
    opacity: 1
}

.modal.effect-rotate-left {
    perspective: 1300px
}

.modal.effect-rotate-left .modal-dialog {
    -moz-transform-style: preserve-3d;
    transform-style: preserve-3d;
    transform: translateZ(100px) translateX(-30%) rotateY(90deg);
    transform-origin: 0 100%;
    opacity: 0;
    transition: all 0.3s
}

.modal.effect-rotate-left.show .modal-dialog {
    transform: translateZ(0px) translateX(0%) rotateY(0deg);
    opacity: 1
}

.modal.effect-just-me .modal-dialog {
    transform: scale(0.8);
    opacity: 0;
    transition: all 0.3s
}

.modal.effect-just-me .modal-content {
    background-color: transparent
}

.modal.effect-just-me .close {
    text-shadow: none;
    color: #fff
}

.modal.effect-just-me .modal-header {
    background-color: transparent;
    border-bottom-color: rgba(255, 255, 255, 0.1);
    padding-left: 0;
    padding-right: 0
}

.modal.effect-just-me .modal-header h6 {
    color: #fff;
    font-weight: 500
}

.modal.effect-just-me .modal-body {
    color: rgba(255, 255, 255, 0.8);
    padding-left: 0;
    padding-right: 0
}

.modal.effect-just-me .modal-body h6 {
    color: #fff
}

.modal.effect-just-me .modal-footer {
    background-color: transparent;
    padding-left: 0;
    padding-right: 0;
    border-top-color: rgba(255, 255, 255, 0.1)
}

.modal.effect-just-me.show~.modal-backdrop {
    opacity: .96
}

.modal.effect-just-me.show .modal-dialog {
    transform: scale(1);
    opacity: 1
}

.nav-social {
    align-items: center
}

.nav-social .nav-link {
    padding: 0;
    color: #1b2e4b
}

.nav-social .nav-link:hover,
.nav-social .nav-link:focus {
    color: #0168fa
}

.nav-social .nav-link+.nav-link {
    margin-left: 8px
}

.nav-social .nav-link svg {
    width: 18px
}

.nav-sidebar {
    flex-direction: column
}

.nav-sidebar .nav-link {
    padding: 6px 10px;
    display: flex;
    align-items: center;
    color: #1b2e4b;
    border-radius: .25rem
}

.nav-sidebar .nav-link:hover,
.nav-sidebar .nav-link:focus {
    background-color: #f5f6fa
}

.nav-sidebar .nav-link svg {
    width: 18px;
    height: 18px;
    stroke-width: 2.3px;
    margin-right: 12px;
    color: #566476;
    fill: rgba(27, 46, 75, 0.06)
}

.nav-sidebar .nav-link .badge {
    margin-left: auto;
    color: #c0ccda;
    font-weight: 400;
    font-size: 11px
}

.nav-sidebar .nav-link.active {
    font-weight: 500;
    color: #0168fa;
    background-color: #eef0f7
}

.nav-sidebar .nav-link.active svg {
    color: #0168fa;
    fill: rgba(1, 104, 250, 0.2)
}

.nav-sidebar .nav-link+.nav-link {
    margin-top: 2px
}

.nav-classic {
    flex-direction: column
}

.nav-classic .nav-link {
    padding: 0;
    display: flex;
    align-items: center;
    color: #1b2e4b
}

.nav-classic .nav-link:hover,
.nav-classic .nav-link:focus {
    color: #0168fa
}

.nav-classic .nav-link:hover svg,
.nav-classic .nav-link:focus svg {
    color: #0168fa;
    fill: rgba(1, 104, 250, 0.2)
}

.nav-classic .nav-link svg {
    width: 18px;
    height: 18px;
    stroke-width: 2.3px;
    margin-right: 12px;
    color: #566476;
    fill: rgba(27, 46, 75, 0.06)
}

.nav-classic .nav-link span:not(.badge) {
    display: block;
    transform: translateY(1px)
}

.nav-classic .nav-link .badge {
    margin-left: auto;
    color: #c0ccda;
    font-weight: 400;
    font-size: 11px
}

.nav-classic .nav-link.active {
    font-weight: 500;
    color: #0168fa;
    background-color: transparent
}

.nav-classic .nav-link.active svg {
    color: #0168fa
}

.nav-classic .nav-link+.nav-link {
    margin-top: 10px
}

.nav-row .nav-link {
    padding: 0
}

.nav-row .nav-link+.nav-link {
    margin-left: 15px
}

.nav-icon-only {
    align-items: center
}

.nav-icon-only .nav-link {
    padding: 0;
    color: #8392a5
}

.nav-icon-only .nav-link:hover,
.nav-icon-only .nav-link:focus {
    color: #1b2e4b
}

.nav-icon-only .nav-link+.nav-link {
    margin-left: 10px
}

.nav-icon-only .nav-link svg {
    width: 18px;
    height: 18px
}

.nav-icon-only .nav-divider {
    width: 1px;
    background-color: #e5e9f2;
    margin: 0 15px
}

.nav-icon-only.nav-lg .nav-link+.nav-link {
    margin-left: 15px
}

.nav-icon-only.nav-lg .nav-link svg {
    width: 28px;
    height: 28px
}

.nav-with-icon .nav-link {
    padding: 0;
    display: flex;
    align-items: center
}

.nav-with-icon .nav-link+.nav-link {
    margin-left: 15px
}

@media (min-width: 576px) {
    .nav-with-icon .nav-link+.nav-link {
        margin-left: 25px
    }
}

.nav-with-icon svg {
    width: 16px;
    height: 16px;
    stroke-width: 2.5px;
    margin-right: 7px;
    position: relative;
    top: -1px
}

.nav-row-xs {
    font-size: 11px
}

.nav-row-xs .nav-link {
    padding: 0;
    font-weight: 500;
    letter-spacing: .5px;
    text-transform: uppercase;
    color: #8392a5
}

.nav-row-xs .nav-link.active {
    font-weight: 600;
    color: #0168fa
}

.nav-row-xs .nav-link+.nav-link {
    margin-left: 10px
}

.nav-wrapper {
    overflow: hidden;
    height: 40px
}

.nav-wrapper>div {
    height: 60px;
    overflow-x: auto;
    padding-right: 20px
}

@media (min-width: 992px) {
    .nav-wrapper>div {
        padding-right: 0
    }
}

.nav-wrapper .nav {
    flex-wrap: nowrap;
    white-space: nowrap
}

.off-canvas {
    position: fixed;
    top: 0;
    left: 0;
    z-index: 1050;
    height: 100%;
    width: 240px;
    background-color: #fff;
    transform: translate(-100%, 0);
    transition: all 0.4s
}

@media (prefers-reduced-motion: reduce) {
    .off-canvas {
        transition: none
    }
}

.off-canvas.show {
    transform: translate(0, 0);
    border-right: 1px solid rgba(28, 39, 60, 0.1);
    box-shadow: 0 0 18px rgba(28, 39, 60, 0.06)
}

.off-canvas>.close {
    position: absolute;
    top: 18px;
    right: 18px;
    border-radius: 100%;
    width: 30px;
    height: 30px;
    display: flex;
    align-items: center;
    justify-content: center;
    color: #7987a1;
    transition: all 0.2s ease-in-out
}

@media (prefers-reduced-motion: reduce) {
    .off-canvas>.close {
        transition: none
    }
}

.off-canvas>.close .feather {
    width: 20px;
    height: 20px;
    stroke-width: 2.5px
}

.off-canvas-overlay.show {
    border-right-width: 0
}

.off-canvas-overlay.show~.off-canvas-pusher {
    overflow: hidden
}

.off-canvas-overlay.show~.backdrop {
    opacity: 1;
    visibility: visible;
    z-index: 60
}

.off-canvas-right {
    left: auto;
    right: 0;
    transform: translate(100%, 0)
}

.off-canvas-right.show {
    border-right: 0;
    border-left: 1px solid rgba(28, 39, 60, 0.1)
}

.off-canvas-right.off-canvas-overlay.show {
    border-left-width: 0
}

.off-canvas-header {
    height: 55px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 0 20px 0 25px;
    border-bottom: 1px solid rgba(72, 94, 144, 0.5)
}

@media (min-width: 992px) {
    .off-canvas-header {
        height: 60px
    }
}

.off-canvas-header .close {
    color: #7987a1
}

.off-canvas-header .close .feather {
    stroke-width: 2.5px;
    width: 20px
}

.off-canvas-body {
    position: relative;
    overflow-y: auto;
    height: calc(100vh - 60px)
}

.off-canvas-dark {
    background-color: #1c273c;
    color: #fff
}

.off-canvas-dark .close {
    color: inherit
}

.off-canvas-dark .off-canvas-header {
    border-bottom-color: rgba(255, 255, 255, 0.05)
}

.off-canvas-dark .df-logo,
.off-canvas-dark .df-logo:hover,
.off-canvas-dark .df-logo:focus {
    color: inherit
}

.off-canvas-dark .df-logo span,
.off-canvas-dark .df-logo:hover span,
.off-canvas-dark .df-logo:focus span {
    color: inherit
}

.off-canvas-dark .sidebar-nav .nav-link {
    color: rgba(255, 255, 255, 0.8);
    font-weight: 500
}

.off-canvas-dark .sidebar-nav .nav-link .feather {
    color: rgba(255, 255, 255, 0.4)
}

.off-canvas-dark .sidebar-nav .nav {
    border-color: rgba(255, 255, 255, 0.05)
}

.off-canvas-dark .sidebar-nav .nav a {
    color: rgba(255, 255, 255, 0.6);
    font-weight: 400
}

.off-canvas-dark.bg-primary .df-logo,
.off-canvas-dark.bg-secondary .df-logo {
    color: inherit
}

.off-canvas-dark.bg-primary .df-logo::before,
.off-canvas-dark.bg-secondary .df-logo::before {
    background-color: rgba(255, 255, 255, 0.2);
    color: inherit
}

.off-canvas-dark.bg-primary .sidebar-nav .nav-label,
.off-canvas-dark.bg-secondary .sidebar-nav .nav-label {
    color: rgba(255, 255, 255, 0.5)
}

.off-canvas-dark.bg-primary .sidebar-nav .nav-link,
.off-canvas-dark.bg-secondary .sidebar-nav .nav-link {
    color: rgba(255, 255, 255, 0.7)
}

.off-canvas-dark.bg-primary .sidebar-nav .nav-link:hover,
.off-canvas-dark.bg-primary .sidebar-nav .nav-link:focus,
.off-canvas-dark.bg-secondary .sidebar-nav .nav-link:hover,
.off-canvas-dark.bg-secondary .sidebar-nav .nav-link:focus {
    color: #fff
}

.off-canvas-dark.bg-primary .sidebar-nav .nav-link .feather,
.off-canvas-dark.bg-secondary .sidebar-nav .nav-link .feather {
    color: inherit
}

.off-canvas-dark.bg-primary .sidebar-nav .nav-link.active,
.off-canvas-dark.bg-secondary .sidebar-nav .nav-link.active {
    color: #fff
}

.off-canvas-dark.bg-primary .sidebar-nav .nav-link.active::before,
.off-canvas-dark.bg-secondary .sidebar-nav .nav-link.active::before {
    background-color: #fff
}

.off-canvas-dark.bg-primary .sidebar-nav .nav-link.active .feather,
.off-canvas-dark.bg-secondary .sidebar-nav .nav-link.active .feather {
    color: inherit
}

.off-canvas-dark.bg-primary .sidebar-nav .nav,
.off-canvas-dark.bg-secondary .sidebar-nav .nav {
    border-color: rgba(255, 255, 255, 0.16)
}

.off-canvas-dark.bg-primary .sidebar-nav .nav a,
.off-canvas-dark.bg-secondary .sidebar-nav .nav a {
    color: rgba(255, 255, 255, 0.5)
}

.off-canvas-dark.bg-primary .sidebar-nav .nav a:hover,
.off-canvas-dark.bg-primary .sidebar-nav .nav a:focus,
.off-canvas-dark.bg-secondary .sidebar-nav .nav a:hover,
.off-canvas-dark.bg-secondary .sidebar-nav .nav a:focus {
    color: rgba(255, 255, 255, 0.8)
}

.off-canvas-dark.bg-primary .sidebar-nav .nav a.active,
.off-canvas-dark.bg-secondary .sidebar-nav .nav a.active {
    color: #fff
}

.off-canvas-dark.bg-primary .sidebar-nav .nav a.active::before,
.off-canvas-dark.bg-secondary .sidebar-nav .nav a.active::before {
    border-color: #fff
}

.off-canvas-dark.bg-secondary {
    background-color: #596882 !important
}

.off-canvas-pusher {
    position: fixed;
    top: 0;
    left: 0;
    width: 100vw;
    height: 100vh;
    overflow-y: scroll;
    z-index: 20;
    transition: none
}

@media (prefers-reduced-motion: reduce) {
    .off-canvas-pusher {
        transition: none
    }
}

.off-canvas-pusher .navbar-header,
.off-canvas-pusher .sidebar,
.off-canvas-pusher .section-nav,
.off-canvas-pusher .content {
    transition: all 0.4s
}

@media (prefers-reduced-motion: reduce) {

    .off-canvas-pusher .navbar-header,
    .off-canvas-pusher .sidebar,
    .off-canvas-pusher .section-nav,
    .off-canvas-pusher .content {
        transition: none
    }
}

.off-canvas-pusher::before {
    content: '';
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    height: 100%;
    background-color: #fff;
    z-index: -1;
    pointer-events: none;
    transition: all 0.4s
}

@media (prefers-reduced-motion: reduce) {
    .off-canvas-pusher::before {
        transition: none
    }
}

.off-canvas-reveal {
    transform: translate(0, 0);
    transition: none;
    z-index: 10
}

@media (prefers-reduced-motion: reduce) {
    .off-canvas-reveal {
        transition: none
    }
}

.off-canvas-reveal.show {
    box-shadow: none
}

.off-canvas-reveal.show~.off-canvas-pusher {
    overflow: hidden;
    pointer-events: none
}

.off-canvas-reveal.show~.off-canvas-pusher::before,
.off-canvas-reveal.show~.off-canvas-pusher .navbar-header,
.off-canvas-reveal.show~.off-canvas-pusher .section-nav,
.off-canvas-reveal.show~.off-canvas-pusher .content {
    transform: translateX(240px)
}

.off-canvas-reveal.show~.off-canvas-pusher .sidebar {
    display: none
}

@media (min-width: 992px) {
    .off-canvas-reveal.show~.off-canvas-pusher .sidebar {
        display: block;
        transform: translateX(240px)
    }
}

.off-canvas-reveal.show~.backdrop {
    transform: translateX(240px)
}

.off-canvas-reveal.off-canvas-right {
    left: auto;
    right: 0
}

.off-canvas-reveal.off-canvas-right.show~.off-canvas-pusher::before,
.off-canvas-reveal.off-canvas-right.show~.off-canvas-pusher .navbar-header,
.off-canvas-reveal.off-canvas-right.show~.off-canvas-pusher .sidebar,
.off-canvas-reveal.off-canvas-right.show~.off-canvas-pusher .section-nav,
.off-canvas-reveal.off-canvas-right.show~.off-canvas-pusher .content {
    transform: translateX(-240px)
}

.off-canvas-push.show~.off-canvas-pusher {
    overflow: hidden;
    pointer-events: none
}

.off-canvas-push.show~.off-canvas-pusher::before,
.off-canvas-push.show~.off-canvas-pusher .navbar-header,
.off-canvas-push.show~.off-canvas-pusher .sidebar,
.off-canvas-push.show~.off-canvas-pusher .section-nav,
.off-canvas-push.show~.off-canvas-pusher .content {
    transform: translateX(240px)
}

.off-canvas-push.off-canvas-right.show~.off-canvas-pusher::before,
.off-canvas-push.off-canvas-right.show~.off-canvas-pusher .navbar-header,
.off-canvas-push.off-canvas-right.show~.off-canvas-pusher .sidebar,
.off-canvas-push.off-canvas-right.show~.off-canvas-pusher .section-nav,
.off-canvas-push.off-canvas-right.show~.off-canvas-pusher .content {
    transform: translateX(-240px)
}

.overlay {
    position: relative
}

.overlay-body {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0
}

@keyframes shimmer {
    0% {
        background-position: -400px 0
    }

    100% {
        background-position: 400px 0
    }
}

.placeholder-paragraph .line {
    position: relative;
    width: 87%;
    margin: 20px 0
}

.placeholder-paragraph .line,
.placeholder-paragraph .line::before,
.placeholder-paragraph .line::after {
    animation-duration: .8s;
    animation-fill-mode: forwards;
    animation-iteration-count: infinite;
    animation-name: shimmer;
    animation-timing-function: linear;
    background: #f4f5f8;
    background: linear-gradient(to right, #e3e7ed 8%, #cdd4e0 18%, #e3e7ed 33%);
    background-size: 800px 10px;
    height: 8px
}

.placeholder-paragraph .line::before,
.placeholder-paragraph .line::after {
    content: '';
    position: absolute;
    left: 0
}

.placeholder-paragraph .line::before {
    top: -20px;
    width: 84%
}

.placeholder-paragraph .line::after {
    bottom: -20px;
    width: 64%
}

.placeholder-paragraph .line+.line {
    margin-top: 52px;
    width: 93%
}

.placeholder-paragraph .line+.line::before {
    width: 88%
}

.placeholder-paragraph .line+.line::after {
    width: 36%
}

.placeholder-paragraph.aligned-centered {
    display: flex;
    flex-direction: column;
    align-items: center
}

.placeholder-paragraph.aligned-centered .line::before {
    margin-left: 50%;
    left: -42%
}

.placeholder-paragraph.aligned-centered .line::after {
    margin-left: 50%;
    left: -32%
}

.placeholder-paragraph.aligned-centered .line+.line {
    margin-top: 32px
}

.placeholder-paragraph.aligned-centered .line+.line::before {
    left: -44%
}

.placeholder-paragraph.aligned-centered .line+.line::after {
    left: -18%
}

.placeholder-paragraph.aligned-right {
    display: flex;
    flex-direction: column;
    align-items: flex-end
}

.placeholder-paragraph.aligned-right .line::before,
.placeholder-paragraph.aligned-right .line::after {
    left: auto;
    right: 0
}

.placeholder-paragraph.aligned-right .line+.line {
    margin-top: 32px
}

.placeholder-media .line {
    position: relative;
    margin-top: 8px;
    margin-bottom: 25px;
    margin-left: 70px;
    height: 16px
}

.placeholder-media .line::before {
    content: '';
    position: absolute;
    top: -11px;
    left: -70px;
    width: 55px;
    height: 55px;
    border-radius: 100%
}

.placeholder-media .line::after {
    content: '';
    position: absolute;
    bottom: -20px;
    left: 0;
    width: 65%;
    height: 8px
}

.placeholder-media .line,
.placeholder-media .line::before,
.placeholder-media .line::after {
    animation-duration: .8s;
    animation-fill-mode: forwards;
    animation-iteration-count: infinite;
    animation-name: shimmer;
    animation-timing-function: linear;
    background: #e3e7ed;
    background: linear-gradient(to right, #e3e7ed 8%, #cdd4e0 18%, #e3e7ed 33%);
    background-size: 800px 10px
}

.search-form {
    display: flex;
    align-items: stretch
}

.search-form .form-control {
    border-top-right-radius: 0;
    border-bottom-right-radius: 0;
    border-right-width: 0
}

.search-form .form-control:focus,
.search-form .form-control:active {
    border-color: #0168fa;
    box-shadow: none !important
}

.search-form .form-control:focus+button,
.search-form .form-control:active+button {
    border-color: #0168fa
}

.search-form button {
    display: flex;
    align-items: center;
    padding: 0 .5rem;
    border: 1px solid #c0ccda;
    border-left-width: 0;
    border-top-left-radius: 0;
    border-bottom-left-radius: 0;
    color: #8392a5
}

.search-form button svg {
    width: 18px;
    height: 18px;
    stroke-width: 2.5px
}

.search-form .twitter-typeahead,
.search-form .tt-hint,
.search-form .tt-input,
.search-form .tt-menu {
    width: 100%
}

.search-form .tt-hint,
.search-form .tt-input {
    border-right-width: 1px;
    border-radius: .25rem
}

.search-form .tt-hint {
    color: #c0ccda
}

.search-form .tt-input {
    color: #1b2e4b
}

.search-form .tt-dataset {
    margin-bottom: 5px
}

.search-form .tt-dataset h3 {
    font-size: 14px;
    margin-bottom: 5px;
    padding-left: 5px
}

.search-form .tt-suggestion {
    padding: 2px 5px;
    border-radius: .25rem
}

.search-form .tt-suggestion strong {
    font-weight: 500
}

.search-form-expanding {
    width: 0%;
    min-width: 39px;
    height: 38px;
    position: relative;
    overflow: hidden;
    flex-shrink: 0;
    transition: all 0.3s
}

@media (prefers-reduced-motion: reduce) {
    .search-form-expanding {
        transition: none
    }
}

.search-form-expanding .form-control {
    border-radius: .25rem
}

.search-form-expanding button {
    padding: 0;
    background-color: #fff;
    position: absolute;
    right: 0;
    top: 0;
    width: 38px;
    height: 38px;
    display: flex;
    align-items: center;
    justify-content: center;
    border-top-left-radius: .25rem;
    border-bottom-left-radius: .25rem
}

.search-form-expanding button svg {
    width: 18px;
    height: 18px
}

.search-form-expanding.expand {
    width: 100%
}

.search-form-expanding.expand button {
    border-left-width: 0;
    border-top-left-radius: 0;
    border-bottom-left-radius: 0
}

.search-suggestion-fixed .tt-menu {
    height: 120px;
    overflow-y: auto
}

.steps {
    margin: 0;
    padding: 0;
    list-style: none;
    display: flex;
    align-items: flex-start
}

.step-item {
    display: inline-block;
    position: relative
}

.step-item+.step-item {
    margin-left: 5px
}

@media (min-width: 480px) {
    .step-item+.step-item {
        margin-left: 10px
    }
}

@media (min-width: 768px) {
    .step-item+.step-item {
        margin-left: 36px
    }
}

.step-item+.step-item::before {
    content: '\f287';
    font-family: 'Ionicons';
    font-size: 16px;
    font-weight: 400;
    color: #c0ccda;
    position: absolute;
    top: 50%;
    left: -26px;
    line-height: 0;
    display: none
}

@media (min-width: 768px) {
    .step-item+.step-item::before {
        display: block
    }
}

.step-item.active .step-link {
    color: #0168fa
}

.step-item.active .step-number,
.step-item.active .step-icon {
    background-color: #0168fa;
    border-color: #0168fa;
    color: #fff
}

.step-item.active ul li.complete a {
    color: #0168fa;
    text-decoration: line-through
}

.step-item.active ul li.active a {
    color: #0168fa
}

.step-item.active ul li.disabled a {
    color: #97a3b9
}

.step-item.disabled .step-link {
    color: #8392a5;
    cursor: default
}

.step-item.disabled .step-number,
.step-item.disabled .step-icon {
    border-color: #8392a5;
    color: #8392a5
}

.step-item.disabled .step-desc {
    color: #8392a5
}

.step-item.disabled ul a {
    color: #8392a5
}

.step-item.complete .step-link {
    color: #10b759
}

.step-item.complete .step-number,
.step-item.complete .step-icon {
    background-color: #10b759;
    border-color: #10b759;
    color: #fff
}

.step-item.complete ul li.complete a {
    color: #10b759;
    text-decoration: line-through
}

.step-item ul {
    position: relative;
    margin: 5px 0 -15px 12px;
    padding: 0;
    list-style: none;
    border-left: 1px solid rgba(72, 94, 144, 0.16);
    padding-left: 26px
}

@media (min-width: 576px) {
    .step-item ul {
        margin-left: 16px
    }
}

.step-item ul a {
    color: #8392a5;
    display: block
}

.step-item:last-child ul {
    margin-bottom: 0
}

.step-link {
    display: flex;
    align-items: center;
    font-weight: 500;
    font-size: 12px;
    color: #1b2e4b
}

@media (min-width: 576px) {
    .step-link {
        font-size: 13px
    }
}

@media (min-width: 992px) {
    .step-link {
        font-size: 14px
    }
}

.step-link:hover,
.step-link:focus {
    color: #1b2e4b
}

.step-link>div {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: flex-start;
    margin-left: 12px
}

.step-link>div span {
    display: block
}

.step-link>div .step-title {
    margin-left: 0
}

.step-number {
    width: 25px;
    height: 25px;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-shrink: 0;
    font-family: -apple-system, BlinkMacSystemFont, "Inter UI", Roboto, sans-serif;
    color: #8392a5;
    border: 2px solid #8392a5;
    border-radius: 100%
}

@media (min-width: 768px) {
    .step-number {
        width: 32px;
        height: 32px
    }
}

.step-icon {
    width: 36px;
    height: 36px;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-shrink: 0;
    border: 2px solid #8392a5;
    color: #8392a5;
    border-radius: 100%
}

.step-icon svg {
    width: 20px;
    height: 20px
}

.step-title {
    margin-left: 5px;
    width: 50px;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap
}

@media (min-width: 375px) {
    .step-title {
        width: 60px
    }
}

@media (min-width: 480px) {
    .step-title {
        width: 100px
    }
}

@media (min-width: 576px) {
    .step-title {
        margin-left: 10px;
        width: auto;
        white-space: normal
    }
}

@media (min-width: 768px) {
    .step-title {
        width: auto;
        overflow: visible;
        text-overflow: inherit
    }
}

.step-desc {
    display: block;
    font-size: 11px;
    font-weight: 400;
    color: #8392a5
}

.steps-sm .step-item+.step-item::before {
    font-size: 12px
}

.steps-sm .step-link {
    font-size: 11px;
    font-weight: 600;
    font-family: -apple-system, BlinkMacSystemFont, "Inter UI", Roboto, sans-serif;
    text-transform: uppercase;
    letter-spacing: .2px
}

.steps-sm .step-number {
    width: 25px;
    height: 25px
}

.steps-lg .step-link {
    font-size: 12px
}

@media (min-width: 576px) {
    .steps-lg .step-link {
        font-size: 14px
    }
}

@media (min-width: 992px) {
    .steps-lg .step-link {
        font-size: 16px
    }
}

.steps-lg .step-number {
    width: 32px;
    height: 32px
}

@media (min-width: 576px) {
    .steps-lg .step-number {
        width: 38px;
        height: 38px
    }
}

@media (min-width: 992px) {
    .steps-lg .step-number {
        width: 42px;
        height: 42px
    }
}

.steps-lg .step-title {
    display: flex;
    flex-direction: column
}

.steps-lg .step-title span {
    display: block;
    line-height: 1.2
}

.steps-justified {
    display: flex;
    align-items: center
}

.steps-justified .step-item {
    flex: 1
}

.steps-justified .step-item+.step-item::before {
    display: none
}

.steps-justified .step-link {
    width: 100%
}

.steps-tab .step-item+.step-item {
    margin-left: 1px
}

.steps-tab .step-item+.step-item::before {
    display: none
}

.steps-tab .step-item.active .step-link {
    background-color: #0168fa;
    color: #fff
}

.steps-tab .step-item.active .step-number {
    border-color: #fff
}

.steps-tab .step-item.complete .step-link {
    background-color: #10b759;
    color: #fff
}

.steps-tab .step-item.complete .step-number {
    border-color: #fff
}

.steps-tab .step-item.disabled .step-number {
    border-color: #e3e7ed
}

.steps-tab .step-link {
    background-color: #eff2f5;
    padding: 5px 7px
}

@media (min-width: 576px) {
    .steps-tab .step-link {
        padding: 8px 15px
    }
}

.steps-vertical {
    display: flex;
    flex-direction: column
}

.steps-vertical .step-item+.step-item {
    margin-left: 0;
    margin-top: 25px
}

.steps-vertical .step-item+.step-item::before {
    content: '';
    position: absolute;
    top: -20px;
    left: 12px;
    height: 15px;
    width: 1px;
    background-color: #cdd4e0;
    display: block
}

@media (min-width: 576px) {
    .steps-vertical .step-item+.step-item::before {
        left: 16px
    }
}

.steps-vertical .step-title {
    width: auto;
    white-space: normal
}

.steps-vertical.steps-tab .step-item+.step-item {
    margin-top: 1px
}

.steps-vertical.steps-sm .step-item+.step-item::before {
    left: 12px
}

.nav-tabs {
    border-color: rgba(72, 94, 144, 0.16)
}

.nav-tabs .nav-item {
    margin-bottom: 0
}

.nav-tabs .nav-item+.nav-item {
    margin-left: 5px
}

.nav-tabs .nav-link {
    background-color: rgba(72, 94, 144, 0.16);
    border-color: rgba(72, 94, 144, 0.16);
    color: #596882;
    border-bottom-width: 0;
    position: relative;
    margin-bottom: -1px;
    padding: 0 20px;
    display: flex;
    align-items: center;
    justify-content: center;
    height: 38px;
    transition: background-color 0.2s
}

@media (prefers-reduced-motion: reduce) {
    .nav-tabs .nav-link {
        transition: none
    }
}

.nav-tabs .nav-link:hover,
.nav-tabs .nav-link:focus {
    background-color: rgba(81, 105, 161, 0.16);
    color: #1c273c
}

.nav-tabs .nav-link.active {
    background-color: #fff;
    z-index: 5;
    color: #0168fa
}

.nav-tabs .nav-link.disabled {
    background-color: transparent;
    color: #b4bdce
}

.nav-tabs.flex-column {
    border-bottom: 0;
    border-right: 1px solid rgba(72, 94, 144, 0.16);
    justify-content: flex-start
}

.nav-tabs.flex-column .nav-item+.nav-item {
    margin-left: 0;
    margin-top: 5px
}

.nav-tabs.flex-column .nav-item .nav-link {
    border: 1px solid rgba(72, 94, 144, 0.16);
    border-right-width: 0;
    margin-bottom: 0;
    margin-right: -1px;
    border-top-right-radius: 0;
    border-bottom-right-radius: 0;
    border-top-left-radius: .25rem;
    border-bottom-left-radius: .25rem
}

.nav-line {
    border-bottom: 2px solid rgba(72, 94, 144, 0.16)
}

.nav-line .nav-item+.nav-item {
    margin-left: 25px
}

.nav-line>.nav-link+.nav-link {
    margin-left: 25px
}

.nav-line .nav-link {
    padding: 8px 0
}

.nav-line .nav-link.active {
    position: relative
}

.nav-line .nav-link.active::after {
    content: '';
    position: absolute;
    bottom: -2px;
    left: 0;
    right: 0;
    height: 2px;
    background-color: #0168fa
}

.nav-line.flex-column {
    border-bottom: 0;
    border-right: 2px solid rgba(72, 94, 144, 0.16)
}

.nav-line.flex-column .nav-item+.nav-item {
    margin-left: 0;
    margin-top: 25px
}

.nav-line.flex-column>.nav-link+.nav-link {
    margin-top: 25px;
    margin-left: 0
}

.nav-line.flex-column .nav-link {
    padding: 0 15px 0 25px
}

.nav-line.flex-column .nav-link.active::after {
    top: 0;
    bottom: 0;
    width: 2px;
    height: auto;
    left: auto;
    right: -2px
}

table.dataTable {
    border: 1px solid rgba(72, 94, 144, 0.16)
}

table.dataTable.no-footer {
    border-bottom-color: rgba(72, 94, 144, 0.16)
}

table.dataTable thead th,
table.dataTable thead td {
    border-top-width: 0;
    border-bottom-width: 0;
    padding: 8px 10px;
    font-weight: 500;
    font-size: inherit
}

table.dataTable thead .sorting_asc,
table.dataTable thead .sorting_desc {
    background-image: none;
    background-color: #eceff3;
    position: relative
}

table.dataTable thead .sorting_asc::after,
table.dataTable thead .sorting_desc::after {
    font-family: 'Ionicons';
    font-size: 11px;
    position: absolute;
    line-height: 0;
    top: 50%;
    right: 10px
}

table.dataTable thead .sorting_asc::after {
    content: '\f3d8'
}

table.dataTable thead .sorting_desc::after {
    content: '\f3d0'
}

table.dataTable thead .sorting {
    background-image: none;
    position: relative
}

table.dataTable thead .sorting::before,
table.dataTable thead .sorting::after {
    font-family: 'Ionicons';
    font-size: 11px;
    position: absolute;
    line-height: 0;
    right: 10px
}

table.dataTable thead .sorting::before {
    content: '\f3d8';
    top: 40%
}

table.dataTable thead .sorting::after {
    content: '\f3d0';
    top: 60%
}

table.dataTable tbody td.sorting_1 {
    background-color: #f3f4f7
}

table.dataTable.dtr-inline.collapsed>tbody>tr[role="row"]>td:first-child::before,
table.dataTable.dtr-inline.collapsed>tbody>tr[role="row"]>td:first-child::before {
    top: 9.5px;
    left: 7px;
    width: 16px;
    height: 16px;
    display: flex;
    align-items: center;
    justify-content: center;
    border-width: 0;
    box-shadow: none;
    background-color: #cdd4e0;
    font-size: 14px;
    font-weight: 700;
    color: #97a3b9
}

table.dataTable>tbody>tr.child ul.dtr-details {
    display: block
}

table.dataTable>tbody>tr.child span.dtr-title {
    font-weight: 500
}

.dataTables_wrapper .dataTables_length {
    text-align: left
}

@media (min-width: 576px) {
    .dataTables_wrapper .dataTables_length {
        float: left
    }
}

.dataTables_wrapper .dataTables_length label {
    display: block;
    margin-bottom: 15px
}

.dataTables_wrapper .dataTables_length .select2-container--default {
    margin-right: 5px
}

.dataTables_wrapper .dataTables_length .select2-container--default .select2-selection--single {
    height: 32px;
    border-color: rgba(72, 94, 144, 0.16);
    border-radius: .25rem
}

.dataTables_wrapper .dataTables_length .select2-container--default .select2-selection--single .select2-selection__rendered {
    line-height: 32px
}

.dataTables_wrapper .dataTables_length .select2-container--default .select2-selection--single .select2-selection__arrow {
    height: 30px
}

.dataTables_wrapper .dataTables_length .select2-container--default .select2-selection--single .select2-selection__arrow b {
    border-top-color: #97a3b9
}

.dataTables_wrapper .dataTables_filter {
    text-align: left
}

@media (min-width: 576px) {
    .dataTables_wrapper .dataTables_filter {
        float: right;
        margin-top: 0
    }
}

.dataTables_wrapper .dataTables_filter label {
    display: block;
    margin-bottom: 15px
}

.dataTables_wrapper .dataTables_filter input {
    margin-left: 0;
    border: 1px solid rgba(72, 94, 144, 0.16);
    padding: 5px 10px;
    line-height: 1.539;
    color: #001737;
    border-radius: .25rem;
    width: 100%
}

@media (min-width: 576px) {
    .dataTables_wrapper .dataTables_filter input {
        width: auto
    }
}

.dataTables_wrapper .dataTables_filter input::placeholder {
    color: #97a3b9
}

.dataTables_wrapper .dataTables_info {
    margin-top: 15px;
    padding: 5px 0;
    text-align: left
}

@media (min-width: 576px) {
    .dataTables_wrapper .dataTables_info {
        float: left
    }
}

.dataTables_wrapper .dataTables_paginate {
    padding-top: 0;
    margin-top: 15px;
    text-align: left
}

@media (min-width: 576px) {
    .dataTables_wrapper .dataTables_paginate {
        float: right
    }
}

.dataTables_wrapper .dataTables_paginate .paginate_button {
    background-color: #e3e7ed;
    padding: 5px 10px;
    margin: 0;
    border: 0;
    border-radius: 1px;
    transition: all 0.2s ease-in-out
}

@media (prefers-reduced-motion: reduce) {
    .dataTables_wrapper .dataTables_paginate .paginate_button {
        transition: none
    }
}

.dataTables_wrapper .dataTables_paginate .paginate_button+.paginate_button {
    margin-left: 3px
}

.dataTables_wrapper .dataTables_paginate .paginate_button.disabled,
.dataTables_wrapper .dataTables_paginate .paginate_button.disabled:hover,
.dataTables_wrapper .dataTables_paginate .paginate_button.disabled:focus {
    background-color: #eceff3;
    color: #b4bdce !important
}

.dataTables_wrapper .dataTables_paginate .paginate_button.previous {
    margin-right: 3px
}

.dataTables_wrapper .dataTables_paginate .paginate_button.next {
    margin-left: 3px
}

.dataTables_wrapper .dataTables_paginate .paginate_button:hover,
.dataTables_wrapper .dataTables_paginate .paginate_button:focus {
    border: 0;
    background-image: none;
    background-color: #b4bdce;
    color: #001737 !important
}

.dataTables_wrapper .dataTables_paginate .paginate_button.current,
.dataTables_wrapper .dataTables_paginate .paginate_button.current:hover,
.dataTables_wrapper .dataTables_paginate .paginate_button.current:focus {
    border: 0;
    background-image: none;
    background-color: #0168fa;
    color: #fff !important
}

.ui-datepicker {
    background-color: #fff;
    border: 1px solid rgba(72, 94, 144, 0.16);
    font-family: inherit;
    font-size: inherit;
    padding: 10px;
    margin: 1px 0 0;
    display: none;
    width: auto !important;
    z-index: 5 !important;
    border-radius: .25rem
}

.ui-datepicker .ui-datepicker-header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    position: relative;
    font-weight: 500;
    font-size: 12px;
    text-transform: uppercase;
    color: #001737;
    padding: 0 0 5px;
    letter-spacing: 1px;
    border: 0;
    background-color: transparent;
    border-top-left-radius: .25rem;
    border-top-right-radius: .25rem
}

.ui-datepicker .ui-datepicker-header .ui-datepicker-next,
.ui-datepicker .ui-datepicker-header .ui-datepicker-prev {
    text-indent: -99999px;
    color: #8392a5;
    top: 0
}

.ui-datepicker .ui-datepicker-header .ui-datepicker-next::before,
.ui-datepicker .ui-datepicker-header .ui-datepicker-prev::before {
    font-size: 16px;
    font-family: 'Ionicons';
    position: absolute;
    top: -1px;
    text-indent: 0
}

.ui-datepicker .ui-datepicker-header .ui-datepicker-next:hover::before,
.ui-datepicker .ui-datepicker-header .ui-datepicker-next:focus::before,
.ui-datepicker .ui-datepicker-header .ui-datepicker-prev:hover::before,
.ui-datepicker .ui-datepicker-header .ui-datepicker-prev:focus::before {
    color: #1b2e4b
}

.ui-datepicker .ui-datepicker-header .ui-datepicker-next {
    order: 3
}

.ui-datepicker .ui-datepicker-header .ui-datepicker-next:before {
    right: 5px;
    content: '\f3d1'
}

.ui-datepicker .ui-datepicker-header .ui-datepicker-prev:before {
    left: 5px;
    content: '\f3cf'
}

.ui-datepicker .ui-datepicker-header .ui-datepicker-next-hover,
.ui-datepicker .ui-datepicker-header .ui-datepicker-prev-hover {
    color: #1b2e4b;
    cursor: pointer;
    top: 1px;
    border: 0;
    background-color: transparent
}

.ui-datepicker .ui-datepicker-title {
    color: #001737;
    font-weight: 700;
    font-size: 13px
}

.ui-datepicker select.ui-datepicker-month,
.ui-datepicker select.ui-datepicker-year {
    font-size: 11px;
    border: 1px solid #c0ccda;
    -webkit-appearance: none;
    -moz-appearance: none;
    appearance: none;
    padding: 2px 20px 2px 5px;
    border-radius: .25rem;
    position: relative;
    margin-top: -3px;
    background: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 4 5'%3e%3cpath fill='%233b4863' d='M2 0L0 2h4zm0 5L0 3h4z'/%3e%3c/svg%3e") no-repeat right 5px center/8px 8px
}

.ui-datepicker .ui-datepicker-year {
    margin-left: 5px
}

.ui-datepicker .ui-datepicker-calendar {
    margin: 0;
    background-color: transparent;
    border-bottom-right-radius: .25rem;
    border-bottom-left-radius: .25rem
}

.ui-datepicker .ui-datepicker-calendar th {
    text-transform: uppercase;
    font-size: 11px;
    font-weight: 600;
    letter-spacing: 1px;
    padding: 6px 9px;
    color: #1b2e4b
}

@media (max-width: 320px) {
    .ui-datepicker .ui-datepicker-calendar th {
        padding: 4px 0;
        letter-spacing: normal
    }
}

.ui-datepicker .ui-datepicker-calendar td {
    border: 1px solid #fff;
    padding: 0;
    background-color: #fff;
    text-align: right
}

.ui-datepicker .ui-datepicker-calendar td:last-child {
    border-right: 0
}

.ui-datepicker .ui-datepicker-calendar td.ui-datepicker-other-month .ui-state-default {
    color: #c0ccda
}

.ui-datepicker .ui-datepicker-calendar td span,
.ui-datepicker .ui-datepicker-calendar td a {
    transition: all 0.2s ease-in-out;
    padding: 5px;
    color: #1b2e4b;
    padding: 6px 10px;
    display: block;
    font-weight: 400;
    font-size: 12px;
    border: 0;
    border-radius: 1px
}

@media (prefers-reduced-motion: reduce) {

    .ui-datepicker .ui-datepicker-calendar td span,
    .ui-datepicker .ui-datepicker-calendar td a {
        transition: none
    }
}

.ui-datepicker .ui-datepicker-calendar td a:hover {
    background-color: #fff;
    color: #001737
}

.ui-datepicker .ui-datepicker-calendar .ui-datepicker-today a,
.ui-datepicker .ui-datepicker-calendar .ui-datepicker-today a:hover,
.ui-datepicker .ui-datepicker-calendar .ui-datepicker-today a:focus {
    background-color: #0168fa;
    color: #fff
}

.ui-datepicker .ui-datepicker-calendar .ui-state-disabled .ui-state-default {
    color: #c0ccda;
    background-color: #f5f6fa
}

.ui-datepicker-multi .ui-datepicker-group {
    padding-right: 15px;
    width: auto;
    float: left
}

.ui-datepicker-multi .ui-datepicker-group .ui-datepicker-title {
    margin: auto
}

.ui-datepicker-multi .ui-datepicker-group .ui-datepicker-prev::before {
    left: 10px
}

.ui-datepicker-multi .ui-datepicker-group .ui-datepicker-next::before {
    right: 10px
}

.ui-datepicker-multi .ui-datepicker-group table {
    margin: 0
}

.ui-datepicker-multi .ui-datepicker-group-last {
    padding-right: 0
}

.ui-datepicker-inline {
    border-color: #c0ccda
}

@media (min-width: 576px) {
    .ui-datepicker-inline {
        max-width: 270px
    }
}

.ui-datepicker-buttonpane {
    display: flex;
    align-items: center;
    justify-content: space-between;
    font-size: 13px;
    border-top: 1px solid #e5e9f2;
    padding-top: 10px
}

.ui-datepicker-buttonpane button {
    min-height: inherit;
    border-radius: .25rem
}

.legendColorBox>div {
    border: 0 !important;
    padding: 0 !important;
    border-radius: 100%;
    overflow: hidden
}

.legendLabel {
    font-size: 10px;
    font-weight: 500;
    color: #8392a5;
    font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, "Noto Sans", sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji";
    text-transform: uppercase;
    padding-left: 5px
}

.wizard {
    border: 1px solid rgba(72, 94, 144, 0.16);
    background-color: #fff;
    border-radius: .25rem
}

.wizard>.steps {
    padding: 10px 15px
}

@media (min-width: 768px) {
    .wizard>.steps {
        padding: 15px 20px
    }
}

.wizard>.steps>ul {
    padding: 0;
    margin-bottom: 0;
    display: flex
}

.wizard>.steps>ul li {
    float: none;
    display: block;
    width: auto
}

.wizard>.steps>ul li .current-info {
    display: none
}

.wizard>.steps>ul li .title {
    margin-left: 5px;
    white-space: nowrap;
    transition: all 0.2s ease-in-out
}

@media (prefers-reduced-motion: reduce) {
    .wizard>.steps>ul li .title {
        transition: none
    }
}

@media (min-width: 576px) {
    .wizard>.steps>ul li .title {
        display: none;
        margin-left: 10px
    }
}

@media (min-width: 768px) {
    .wizard>.steps>ul li .title {
        display: inline-block
    }
}

.wizard>.steps>ul li+li {
    margin-left: 10px;
    position: relative
}

@media (min-width: 576px) {
    .wizard>.steps>ul li+li {
        margin-left: 20px
    }
}

@media (min-width: 992px) {
    .wizard>.steps>ul li+li {
        margin-left: 40px
    }
}

.wizard>.steps>ul li+li::before {
    content: '\f287';
    font-family: 'Ionicons';
    font-size: 16px;
    font-weight: 400;
    color: #b4bdce;
    position: absolute;
    top: 50%;
    left: -26px;
    line-height: 0;
    display: none
}

@media (min-width: 768px) {
    .wizard>.steps>ul li+li::before {
        display: block
    }
}

.wizard>.steps a,
.wizard>.steps a:hover,
.wizard>.steps a:active {
    color: #1c273c;
    font-weight: 500;
    font-size: inherit;
    display: flex;
    justify-content: center;
    align-items: center;
    outline: none
}

@media (min-width: 1200px) {

    .wizard>.steps a,
    .wizard>.steps a:hover,
    .wizard>.steps a:active {
        justify-content: flex-start
    }
}

.wizard>.steps a .number,
.wizard>.steps a:hover .number,
.wizard>.steps a:active .number {
    width: 25px;
    height: 25px;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-shrink: 0;
    font-family: -apple-system, BlinkMacSystemFont, "Inter UI", Roboto, sans-serif;
    border: 2px solid #7987a1;
    border-radius: 100%
}

@media (min-width: 768px) {

    .wizard>.steps a .number,
    .wizard>.steps a:hover .number,
    .wizard>.steps a:active .number {
        width: 32px;
        height: 32px
    }
}

.wizard>.steps .disabled a,
.wizard>.steps .disabled a:hover,
.wizard>.steps .disabled a:active {
    color: #97a3b9
}

.wizard>.steps .disabled a .number,
.wizard>.steps .disabled a:hover .number,
.wizard>.steps .disabled a:active .number {
    color: #97a3b9;
    border-color: #b4bdce
}

.wizard>.steps .disabled .title {
    display: none
}

@media (min-width: 768px) {
    .wizard>.steps .disabled .title {
        display: inline-block
    }
}

.wizard>.steps .current a,
.wizard>.steps .current a:hover,
.wizard>.steps .current a:active {
    color: #0168fa
}

.wizard>.steps .current a .title,
.wizard>.steps .current a:hover .title,
.wizard>.steps .current a:active .title {
    display: inline-block
}

.wizard>.steps .current a .number,
.wizard>.steps .current a:hover .number,
.wizard>.steps .current a:active .number {
    border-color: #0168fa;
    background-color: #0168fa;
    color: #fff
}

.wizard>.steps .done a,
.wizard>.steps .done a:hover,
.wizard>.steps .done a:active {
    color: #10b759
}

.wizard>.steps .done a .title,
.wizard>.steps .done a:hover .title,
.wizard>.steps .done a:active .title {
    display: none
}

@media (min-width: 768px) {

    .wizard>.steps .done a .title,
    .wizard>.steps .done a:hover .title,
    .wizard>.steps .done a:active .title {
        display: inline-block
    }
}

.wizard>.steps .done a .number,
.wizard>.steps .done a:hover .number,
.wizard>.steps .done a:active .number {
    border-color: #10b759;
    background-color: #10b759;
    color: #fff
}

.wizard>.content {
    border-top: 1px solid rgba(72, 94, 144, 0.16);
    border-bottom: 1px solid rgba(72, 94, 144, 0.16);
    min-height: 150px;
    padding: 20px;
    font-size: inherit
}

@media (min-width: 768px) {
    .wizard>.content {
        padding: 20px
    }
}

.wizard>.content>.title {
    font-size: 18px;
    color: #1c273c;
    font-weight: 600;
    margin-bottom: 5px;
    display: none
}

.wizard>.content>.title.current {
    display: block
}

.wizard>.content>.body {
    float: none;
    position: static;
    width: auto;
    height: auto
}

.wizard>.content>.body input.parsley-error {
    border-color: #dc3545
}

.wizard>.content>.body input.parsley-error+ul {
    list-style: none !important
}

.wizard>.content input,
.wizard>.content textarea,
.wizard>.content select {
    font-size: inherit
}

.wizard>.actions {
    padding: 10px 15px
}

@media (min-width: 768px) {
    .wizard>.actions {
        padding: 15px 20px
    }
}

.wizard>.actions>ul {
    margin: 0;
    padding: 0;
    list-style: none;
    display: flex;
    justify-content: space-between
}

.wizard>.actions>ul>li:last-child a {
    background-color: #10b759
}

.wizard>.actions a,
.wizard>.actions a:hover,
.wizard>.actions a:active {
    display: block;
    background-color: #0168fa;
    padding: 9px 20px;
    line-height: 1.539;
    color: #fff;
    border-radius: .25rem
}

.wizard>.actions .disabled a,
.wizard>.actions .disabled a:hover,
.wizard>.actions .disabled a:active {
    background-color: #97a3b9
}

.wizard.vertical>.steps {
    padding: 15px 20px
}

@media (min-width: 576px) {
    .wizard.vertical>.steps {
        float: left;
        width: 20%
    }
}

@media (min-width: 768px) {
    .wizard.vertical>.steps {
        width: 15%
    }
}

@media (min-width: 992px) {
    .wizard.vertical>.steps {
        width: 30%
    }
}

@media (min-width: 576px) {
    .wizard.vertical>.steps ul {
        flex-direction: column
    }
}

.wizard.vertical>.steps ul li+li {
    margin-left: 10px
}

@media (min-width: 576px) {
    .wizard.vertical>.steps ul li+li {
        margin-top: 10px;
        margin-left: 0
    }
}

@media (min-width: 768px) {
    .wizard.vertical>.steps ul li+li {
        margin-top: 20px
    }
}

.wizard.vertical>.steps ul li+li::before {
    content: '';
    border-left: 1px solid rgba(72, 94, 144, 0.16);
    position: absolute;
    top: -15px;
    left: 15px;
    height: 10px
}

.wizard.vertical>.steps ul li .title {
    display: none
}

@media (min-width: 992px) {
    .wizard.vertical>.steps ul li .title {
        display: block
    }
}

@media (min-width: 992px) {
    .wizard.vertical>.steps a {
        justify-content: flex-start
    }
}

.wizard.vertical>.steps .current a .title {
    display: inline-block
}

@media (min-width: 576px) {
    .wizard.vertical>.steps .current a .title {
        display: none
    }
}

@media (min-width: 992px) {
    .wizard.vertical>.steps .current a .title {
        display: inline-block
    }
}

.wizard.vertical>.content {
    margin: 0;
    padding: 20px
}

@media (min-width: 576px) {
    .wizard.vertical>.content {
        border-top-width: 0;
        border-bottom-width: 0;
        width: 80%;
        float: right;
        border-left: 1px solid rgba(72, 94, 144, 0.16)
    }
}

@media (min-width: 768px) {
    .wizard.vertical>.content {
        width: 85%
    }
}

@media (min-width: 992px) {
    .wizard.vertical>.content {
        width: 70%
    }
}

.wizard.vertical>.actions {
    padding: 10px 15px
}

@media (min-width: 576px) {
    .wizard.vertical>.actions {
        width: 80%;
        float: right;
        border-left: 1px solid rgba(72, 94, 144, 0.16);
        padding: 15px 20px
    }
}

@media (min-width: 768px) {
    .wizard.vertical>.actions {
        width: 85%;
        border-top: 1px solid rgba(72, 94, 144, 0.16)
    }
}

@media (min-width: 992px) {
    .wizard.vertical>.actions {
        width: 70%
    }
}

.wizard.vertical>.actions ul {
    float: none;
    margin: 0;
    padding: 0
}

.wizard-tab {
    border-width: 0
}

.wizard-tab>.steps {
    padding: 0;
    border-bottom-width: 0;
    margin-bottom: 20px
}

.wizard-tab>.steps>ul {
    width: 100%
}

.wizard-tab>.steps>ul>li {
    flex: 1;
    width: auto;
    float: none
}

.wizard-tab>.steps>ul>li+li {
    margin-left: 1px
}

.wizard-tab>.steps>ul>li+li::before {
    display: none
}

.wizard-tab>.steps a {
    padding: 7px 10px
}

@media (min-width: 768px) {
    .wizard-tab>.steps a {
        padding: 10px 20px
    }
}

.wizard-tab>.steps .current a,
.wizard-tab>.steps .current a:hover,
.wizard-tab>.steps .current a:focus,
.wizard-tab>.steps .done a,
.wizard-tab>.steps .done a:hover,
.wizard-tab>.steps .done a:focus {
    color: #fff
}

.wizard-tab>.steps .current a .number,
.wizard-tab>.steps .current a:hover .number,
.wizard-tab>.steps .current a:focus .number,
.wizard-tab>.steps .done a .number,
.wizard-tab>.steps .done a:hover .number,
.wizard-tab>.steps .done a:focus .number {
    border-color: #fff;
    color: #fff
}

.wizard-tab>.steps .current a {
    background-color: #0168fa
}

.wizard-tab>.steps .done a {
    background-color: #10b759
}

.wizard-tab>.steps .disabled a {
    background-color: #c0ccda
}

.wizard-tab>.steps .first a {
    border-top-left-radius: .25rem;
    border-bottom-left-radius: .25rem
}

.wizard-tab>.steps .last a {
    border-top-right-radius: .25rem;
    border-bottom-right-radius: .25rem
}

.wizard-tab>.content {
    padding: 0;
    border: 0
}

.wizard-tab>.actions {
    border-top: 1px solid rgba(72, 94, 144, 0.16);
    padding: 15px 0 0
}

.morris-hover.morris-default-style {
    border-radius: 0
}

input.parsley-error,
input.parsley-error:focus,
textarea.parsley-error,
textarea.parsley-error:focus {
    box-shadow: none !important;
    border-color: #dc3545
}

input.parsley-success,
input.parsley-success:focus,
textarea.parsley-success,
textarea.parsley-success:focus {
    box-shadow: none !important;
    border-color: #10b759
}

.parsley-checkbox.parsley-error,
.parsley-checkbox.parsley-success {
    display: inline-block;
    padding: 10px;
    border-radius: .25rem
}

.parsley-checkbox.parsley-error {
    border: 1px solid #dc3545
}

.parsley-checkbox.parsley-success {
    border: 1px solid #10b759
}

.custom-select.parsley-error,
.custom-select.parsley-success {
    box-shadow: none !important
}

.custom-select.parsley-error {
    border-color: #dc3545
}

.custom-select.parsley-success {
    border-color: #10b759
}

.parsley-errors-list {
    color: #dc3545;
    list-style: none;
    font-size: 12px;
    padding: 0;
    margin-bottom: 0
}

.parsley-errors-list li {
    margin: 5px 0 0
}

.parsley-style-1 .parsley-input.parsley-error .form-control {
    background-color: #fef9f9;
    border-color: #dc3545;
    border-bottom-width: 0;
    border-bottom-right-radius: 0;
    border-bottom-left-radius: 0
}

.parsley-style-1 .parsley-input.parsley-success .form-control {
    border-color: #10b759
}

.parsley-style-1 .parsley-checkbox.parsley-error {
    padding-bottom: 35px;
    border: 1px solid #dc3545;
    background-color: #fef9f9;
    border-top-left-radius: 2px;
    border-top-right-radius: 2px
}

.parsley-style-1 .parsley-checkbox .parsley-errors-list.filled {
    border-bottom-right-radius: 1px;
    border-bottom-left-radius: 1px
}

.parsley-style-1 .custom-select.parsley-error {
    border-bottom-width: 0;
    border-bottom-right-radius: 0;
    border-bottom-left-radius: 0
}

.parsley-style-1 .parsley-errors-list.filled {
    background-color: #dc3545;
    color: #fff;
    padding: 3px 10px;
    font-size: 11px;
    border-bottom-right-radius: 2px;
    border-bottom-left-radius: 2px
}

.parsley-style-1 .parsley-errors-list.filled li:first-child {
    margin-top: 0
}

.jqvmap-label {
    padding: 2px 8px;
    background-color: rgba(17, 17, 17, 0.9);
    border-radius: 2px
}

.jqvmap-zoomin,
.jqvmap-zoomout {
    font-size: 20px;
    padding: 0;
    width: 20px;
    height: 20px;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 2px
}

.jqvmap-zoomout {
    top: 32px
}

.jqvmap-pin {
    font-family: "IBM Plex Sans", sans-serif;
    cursor: default;
    pointer-events: none
}

#jqvmap1_ri_pin,
#jqvmap1_dc_pin,
#jqvmap1_de_pin,
#jqvmap1_md_pin {
    display: none
}

#jqvmap1_ak_pin {
    margin-top: -2%
}

#jqvmap1_ca_pin {
    margin-left: -2%
}

#jqvmap1_ct_pin {
    margin-top: -0.25%;
    margin-left: -0.25%
}

#jqvmap1_fl_pin {
    margin-left: 5%
}

#jqvmap1_id_pin {
    margin-top: 3%;
    margin-left: -1%
}

#jqvmap1_ky_pin {
    margin-left: 2%
}

#jqvmap1_la_pin {
    margin-left: -2%
}

#jqvmap1_mi_pin {
    margin-top: 4%;
    margin-left: 3%
}

#jqvmap1_ma_pin {
    margin-top: -0.25%
}

#jqvmap1_mn_pin {
    margin-top: 2%;
    margin-left: -2%
}

#jqvmap1_nh_pin {
    margin-top: 1%;
    margin-left: -0.25%
}

#jqvmap1_nj_pin {
    margin-top: 1%
}

#jqvmap1_ok_pin {
    margin-left: 2%
}

#jqvmap1_va_pin {
    margin-left: 2%
}

#jqvmap1_wv_pin {
    margin-left: -1%;
    margin-top: 1%
}

@media only screen and (min-width: 320px) {
    .jqvmap-pin {
        font-size: 6px
    }
}

@media only screen and (min-width: 480px) {
    .jqvmap-pin {
        font-size: 8px
    }
}

@media only screen and (min-width: 640px) {
    .jqvmap-pin {
        font-size: 10px
    }
}

@media only screen and (min-width: 800px) {
    .jqvmap-pin {
        font-size: 12px
    }
}

@media only screen and (min-width: 1024px) {
    .jqvmap-pin {
        font-size: 14px
    }
}

.ql-snow.ql-toolbar,
.ql-snow.ql-container {
    border-color: rgba(72, 94, 144, 0.16)
}

.ql-snow.ql-toolbar {
    border-top-left-radius: .25rem;
    border-top-right-radius: .25rem
}

.ql-snow.ql-toolbar button {
    padding: 5px;
    display: flex;
    align-items: center;
    justify-content: center;
    width: 28px;
    height: 28px;
    color: #596882;
    border-radius: 1px
}

.ql-snow.ql-toolbar button:hover,
.ql-snow.ql-toolbar button:focus {
    background-color: #eceff3
}

.ql-snow.ql-toolbar button.ql-active {
    background-color: #eceff3
}

.ql-snow.ql-toolbar button+button {
    margin-left: 1px
}

.ql-snow.ql-toolbar .ql-picker {
    font-size: 13px;
    height: 28px;
    color: #596882
}

.ql-snow.ql-toolbar .ql-picker:hover .ql-picker-label,
.ql-snow.ql-toolbar .ql-picker:focus .ql-picker-label {
    color: #1c273c;
    background-color: #eceff3
}

.ql-snow.ql-toolbar .ql-picker.ql-expanded .ql-picker-label {
    background-color: #eceff3;
    border-color: rgba(72, 94, 144, 0.16);
    color: #1c273c;
    outline: none;
    border-radius: 2px
}

.ql-snow.ql-toolbar .ql-picker.ql-expanded .ql-picker-options {
    border-color: rgba(72, 94, 144, 0.16);
    box-shadow: none;
    border-bottom-right-radius: 2px;
    border-bottom-left-radius: 2px
}

.ql-snow.ql-toolbar .ql-picker:not(.ql-color-picker):not(.ql-icon-picker) .ql-picker-label .ql-stroke {
    stroke: #97a3b9;
    stroke-width: 1.5;
    stroke-linecap: square;
    stroke-linejoin: miter
}

.ql-snow.ql-toolbar .ql-picker-label {
    display: flex;
    align-items: center;
    border-radius: .25rem
}

.ql-snow.ql-toolbar .ql-picker-label.ql-active {
    background-color: #eceff3;
    color: #0168fa
}

.ql-snow.ql-toolbar .ql-formats {
    display: inline-flex
}

.ql-snow.ql-toolbar .ql-formats .ql-picker {
    float: none
}

.ql-snow.ql-toolbar .ql-formats .ql-picker~.ql-picker {
    margin-left: 1px
}

.ql-snow.ql-container {
    border-bottom-right-radius: .25rem;
    border-bottom-left-radius: .25rem
}

.ql-container {
    font-size: 1rem
}

.ql-editor {
    padding: 20px;
    color: #001737
}

.ql-editor.ql-blank::before {
    color: #c0ccda;
    font-style: normal
}

.ql-bubble .ql-tooltip {
    background-color: #1c273c;
    border-radius: .25rem
}

.ql-bubble .ql-tooltip:not(.ql-flip) .ql-tooltip-arrow {
    border-bottom-color: #1c273c
}

.ql-bubble .ql-toolbar button {
    height: 28px;
    padding: 5px;
    border-radius: 1px
}

.ql-bubble .ql-toolbar button.ql-active {
    background-color: #0168fa
}

.ql-bubble .ql-toolbar button+button {
    margin-left: 1px
}

.irs--flat .irs-min,
.irs--flat .irs-max {
    color: #8392a5;
    background-color: #e5e9f2;
    border-radius: .25rem
}

.irs--flat .irs-from,
.irs--flat .irs-to,
.irs--flat .irs-single {
    background-color: #0168fa;
    border-radius: .25rem
}

.irs--flat .irs-from::before,
.irs--flat .irs-to::before,
.irs--flat .irs-single::before {
    border-top-color: #0168fa
}

.irs--flat .irs-line {
    background-color: rgba(72, 94, 144, 0.16);
    border-radius: .25rem
}

.irs--flat .irs-bar {
    background-color: #0168fa
}

.irs--flat .irs-bar--single {
    border-top-left-radius: .25rem;
    border-bottom-left-radius: .25rem
}

.irs--flat .irs-handle>i:first-child {
    background-color: #015de1
}

.irs--flat .irs-handle:hover,
.irs--flat .irs-handle:focus,
.irs--flat .irs-handle:active {
    cursor: w-resize
}

.irs--flat .irs-handle:hover>i:first-child,
.irs--flat .irs-handle:focus>i:first-child,
.irs--flat .irs-handle:active>i:first-child {
    background-color: #0159d6
}

.irs--flat .irs-handle.state_hover>i:first-child,
.irs--flat .irs-handle.state_hover:hover>i:first-child,
.irs--flat .irs-handle.state_hover:focus>i:first-child,
.irs--flat .irs-handle.state_hover:active>i:first-child {
    background-color: #0159d6
}

.irs--flat .irs-grid-text {
    color: #8392a5
}

.irs--flat .irs-grid-pol {
    background-color: rgba(72, 94, 144, 0.16)
}

.irs--round .irs-min,
.irs--round .irs-max,
.irs--round .irs-from,
.irs--round .irs-to {
    font-size: 10px;
    border-radius: .25rem
}

.irs--round .irs-min,
.irs--round .irs-max {
    background-color: #e5e9f2;
    color: #8392a5
}

.irs--round .irs-grid-text {
    font-size: 10px;
    color: #8392a5
}

.irs--round .irs-grid-pol {
    background-color: rgba(72, 94, 144, 0.16)
}

.irs--square .irs-line,
.irs--square .irs-grid-pol {
    background-color: #e5e9f2
}

.irs--square .irs-min,
.irs--square .irs-max {
    background-color: #e5e9f2;
    font-size: 10px;
    color: #8392a5
}

.irs--square .irs-grid-text {
    font-size: 10px;
    color: #8392a5
}

.irs--square .irs-from,
.irs--square .irs-to {
    background-color: #1b2e4b;
    font-size: 10px
}

.irs--square .irs-handle {
    border-color: #1b2e4b
}

.irs--square .irs-bar {
    background-color: #1b2e4b
}

.ps {
    overflow: hidden
}

.ps>.ps__rail-y {
    width: 3px;
    background-color: rgba(72, 83, 114, 0.06);
    z-index: 10;
    position: absolute;
    left: auto !important;
    right: 0;
    opacity: 0;
    margin: 1px;
    transition: opacity 0.2s
}

@media (prefers-reduced-motion: reduce) {
    .ps>.ps__rail-y {
        transition: none
    }
}

.ps>.ps__rail-y>.ps__thumb-y {
    position: absolute;
    border-radius: 0;
    width: 3px;
    left: 0;
    background-color: #97a3b9
}

.ps.ps--active-y:hover>.ps__rail-y,
.ps.ps--active-y:focus>.ps__rail-y {
    opacity: 1
}

.scrollbar-sm>.ps__rail-y {
    width: 1px
}

.scrollbar-sm>.ps__rail-y>.ps__thumb-y {
    width: 1px
}

.scrollbar-lg>.ps__rail-y {
    width: 6px
}

.scrollbar-lg>.ps__rail-y>.ps__thumb-y {
    width: 6px
}

.scrollbar-primary>.ps__rail-y>.ps__thumb-y {
    background-color: #0168fa
}

.scrollbar-dark>.ps__rail-y>.ps__thumb-y {
    background-color: #1c273c
}

.select2-results__option {
    border-radius: 0;
    margin-bottom: 1px;
    font-size: inherit
}

.select2-container--default .select2-selection--single {
    background-color: #fff;
    border-color: #c0ccda;
    border-radius: .25rem;
    height: 38px;
    outline: none
}

.select2-container--default .select2-selection--single .select2-selection__rendered {
    color: #596882;
    line-height: calc(1.5em + .9375rem + 2px)-0.1rem;
    padding-left: .625rem;
    height: 100%;
    display: flex;
    align-items: center
}

.select2-container--default .select2-selection--single .select2-selection__placeholder {
    color: #7987a1
}

.select2-container--default .select2-selection--single .select2-selection__arrow {
    width: 30px;
    height: 38px;
    line-height: calc(1.5em + .9375rem + 2px)
}

.select2-container--default .select2-selection--single .select2-selection__arrow b {
    margin-top: -3px
}

.select2-container--default .select2-selection--single .select2-selection__clear {
    font-size: 20px;
    font-weight: 400;
    order: 2;
    margin-left: auto;
    margin-right: 5px;
    color: #8392a5;
    position: relative;
    z-index: 100
}

.select2-container--default .select2-selection--single .select2-selection__clear:hover {
    color: #1b2e4b
}

.select2-container--default .select2-selection--multiple {
    background-color: #fff;
    border-color: #c0ccda;
    border-radius: .25rem;
    min-height: 38px;
    outline: none
}

.select2-container--default .select2-selection--multiple .select2-selection__rendered {
    padding: 0 4px;
    display: block
}

.select2-container--default .select2-selection--multiple .select2-selection__choice {
    position: relative;
    margin-top: 4px;
    margin-right: 4px;
    padding: 3px 10px 3px 20px;
    border-color: transparent;
    border-radius: .1875rem;
    background-color: #0168fa;
    color: #fff;
    font-size: 13px;
    line-height: 1.45
}

.select2-container--default .select2-selection--multiple .select2-selection__choice__remove {
    color: #fff;
    opacity: .5;
    font-size: 14px;
    font-weight: 400;
    display: inline-block;
    position: absolute;
    top: 4px;
    left: 7px;
    line-height: 1.2
}

.select2-container--default.select2-container--focus .select2-selection--multiple {
    border-color: #c0ccda
}

.select2-container--default .select2-search--dropdown .select2-search__field {
    border-color: #c0ccda;
    border-radius: 0;
    padding: 5px 10px
}

.select2-container--default .select2-search--dropdown .select2-search__field::placeholder {
    color: #8392a5
}

.select2-container--default .select2-results__option[aria-selected="true"] {
    background-color: #fff
}

.select2-container--default .select2-results__option--highlighted[aria-selected] {
    background-color: #0168fa
}

.select2-container--default .select2-results>.select2-results__options {
    margin: 4px
}

.select2-container--default .select2-search--inline .select2-search__field {
    margin-top: 6px;
    line-height: 26px;
    padding-left: 10px;
    opacity: 1
}

.select2-container--default .select2-search--inline .select2-search__field::placeholder {
    color: #8392a5;
    font-weight: 400;
    opacity: 1
}

.select2-container--default.select2-container--disabled .select2-selection--single {
    background-color: #f5f6fa
}

.select2-container--default.select2-container--disabled .select2-selection__choice {
    padding-left: 10px;
    background-color: #97a3b9
}

.select2-container--default.select2-container--disabled .select2-selection__choice .select2-selection__choice__remove {
    display: none
}

.select2-container--open {
    font-size: 14px
}

.select2-container--open .select2-selection--single,
.select2-container--open .select2-selection--multiple {
    background-color: #fff;
    border-color: #c0ccda
}

.select2-container--open .select2-dropdown--above {
    border-top-left-radius: 0;
    border-top-right-radius: 0
}

.select2-container--open .select2-dropdown--below {
    border-bottom-right-radius: 0;
    border-bottom-left-radius: 0;
    top: 0
}

.select2-dropdown {
    border-color: #c0ccda;
    z-index: 200
}

.select2-search--dropdown {
    padding-bottom: 0
}

.select2-results__option {
    padding: 6px 10px;
    font-size: inherit
}

.has-success .select2-container--default .select2-selection--single {
    border-color: #10b759
}

.has-warning .select2-container--default .select2-selection--single {
    border-color: #ffc107
}

.has-danger .select2-container--default .select2-selection--single {
    border-color: #dc3545
}

.select2-xs+.select2-container {
    font-size: 12px
}

.select2-dropdown-xs .select2-results__option {
    font-size: 12px
}

.select2-sm+.select2-container {
    font-size: 14px
}

.select2-dropdown-sm .select2-results__option {
    font-size: 14px
}

.select2-bd-0+.select2-container--default .select2-selection--single {
    border-width: 0
}

.bg-gray+.select2-container--default .select2-selection--single {
    background-color: #455473
}

.bg-gray+.select2-container--default .select2-selection--single .select2-selection__rendered {
    color: #cdd4e0
}

.sp-container {
    background-color: #fff;
    border-color: rgba(72, 94, 144, 0.16);
    z-index: 200
}

.sp-container button {
    border: 0;
    padding: 8px 15px;
    background-image: none;
    background-color: #b4bdce;
    font-family: "IBM Plex Sans", sans-serif;
    font-size: 12px;
    text-shadow: none;
    text-transform: capitalize;
    border-radius: 2px
}

.sp-container button:hover,
.sp-container button:focus {
    border: 0;
    background-image: none;
    background-color: #97a3b9;
    text-shadow: none
}

.sp-button-container .sp-cancel {
    font-family: "IBM Plex Sans", sans-serif;
    font-size: 12px;
    text-transform: capitalize;
    color: #7987a1 !important
}

.sp-button-container .sp-cancel:hover,
.sp-button-container .sp-cancel:focus {
    color: #3b4863 !important;
    text-decoration: none
}

.sp-picker-container {
    border-left: 0
}

.sp-replacer {
    border-color: rgba(72, 94, 144, 0.16);
    background-color: #fff
}

.sp-replacer:hover,
.sp-replacer:focus {
    border-color: #b4bdce
}

.sp-replacer.sp-active {
    border-color: #b4bdce
}

.sp-dd {
    text-indent: -99999px;
    position: relative;
    width: 10px
}

.sp-dd::before {
    content: '\f280';
    font-family: 'Ionicons';
    font-size: 15px;
    color: #97a3b9;
    position: absolute;
    text-indent: 0;
    left: 0;
    z-index: 10
}

.sp-preview {
    border: 0
}

.sp-dragger {
    background-color: transparent
}

.sp-choose {
    background-color: #cdd4e0
}

.sp-palette .sp-thumb-el:hover,
.sp-palette .sp-thumb-el.sp-thumb-active {
    border-color: #3b4863
}

.bootstrap-tagsinput {
    display: block;
    padding: 5px;
    box-shadow: none;
    border-color: #c0ccda;
    border-radius: .25rem
}

.bootstrap-tagsinput .tag {
    display: inline-flex;
    align-items: center;
    background-color: #0168fa;
    color: #fff;
    padding: 2px 7px;
    border-radius: 1px
}

.bootstrap-tagsinput .tag [data-role="remove"] {
    display: inline-block;
    height: 16px;
    width: 10px;
    position: relative;
    opacity: .5
}

.bootstrap-tagsinput .tag [data-role="remove"]::after {
    content: '\f406';
    font-family: 'Ionicons';
    font-size: 18px;
    position: absolute;
    top: calc(50% + .5px);
    line-height: 0
}

.bootstrap-tagsinput .tag [data-role="remove"]:hover,
.bootstrap-tagsinput .tag [data-role="remove"]:focus {
    opacity: 1;
    box-shadow: none
}

.bootstrap-tagsinput .tt-menu {
    margin-top: 7px;
    margin-left: -10px
}

.tt-menu {
    min-width: 150px;
    background-color: #fff;
    border: 1px solid rgba(72, 94, 144, 0.16);
    padding: 5px;
    border-bottom-right-radius: .25rem;
    border-bottom-left-radius: .25rem;
    box-shadow: 0 6px 8px 2px rgba(28, 39, 60, 0.04)
}

.tt-suggestion {
    padding: 2px 10px
}

.tt-suggestion:hover,
.tt-suggestion:focus {
    cursor: pointer;
    background-color: #0168fa;
    color: #fff
}

.backdrop {
    visibility: hidden;
    opacity: 0;
    position: fixed;
    top: 0;
    left: 0;
    width: 100vw;
    height: 100vh;
    background-color: rgba(15, 21, 32, 0.7);
    z-index: 40;
    transition: all 0.4s
}

@media (prefers-reduced-motion: reduce) {
    .backdrop {
        transition: none
    }
}

.backdrop.show {
    visibility: visible;
    opacity: 1
}

@media (max-width: 991.98px) {
    .navbar-nav-show {
        overflow: hidden
    }

    .navbar-nav-show .navbar-menu-wrapper {
        transform: translateX(0)
    }

    .navbar-nav-show .backdrop {
        opacity: 1;
        visibility: visible;
        left: 260px
    }
}

.navbar-header {
    align-items: stretch;
    height: 55px;
    padding: 0;
    justify-content: flex-start;
    background-color: #fff;
    border-bottom: 1px solid rgba(72, 94, 144, 0.16)
}

@media (min-width: 992px) {
    .navbar-header {
        height: 60px
    }
}

.navbar-header>.container {
    padding: 0
}

@media (max-width: 1139px) {
    .navbar-header>.container {
        max-width: none
    }
}

@media (min-width: 1140px) {
    .navbar-header>.container .navbar-brand {
        padding-left: 0
    }

    .navbar-header>.container .navbar-right {
        padding-right: 0
    }
}

.navbar-header .navbar-brand {
    display: flex;
    align-items: center;
    padding-left: 15px;
    margin-right: 0;
    order: 1
}

@media (min-width: 992px) {
    .navbar-header .navbar-brand {
        width: 240px;
        padding: 0 0 0 20px
    }
}

.navbar-header .burger-menu {
    margin-left: 18px;
    color: #8392a5;
    display: flex;
    align-items: center
}

@media (min-width: 576px) {
    .navbar-header .burger-menu {
        margin-left: 20px
    }
}

@media (min-width: 992px) {
    .navbar-header .burger-menu {
        display: none
    }
}

.navbar-header .burger-menu svg {
    width: 20px;
    height: 20px;
    stroke-width: 2.5px
}

.navbar-header .burger-menu:hover {
    color: #0168fa
}

.navbar-header .btn-social {
    padding: 0;
    font-size: 18px;
    color: #1b2e4b;
    min-height: inherit;
    display: none
}

@media (min-width: 1200px) {
    .navbar-header .btn-social {
        display: inline-block
    }
}

.navbar-header .btn-social:hover,
.navbar-header .btn-social:focus {
    color: #001737
}

.navbar-header .btn-social:hover:first-of-type,
.navbar-header .btn-social:focus:first-of-type {
    color: #f10075
}

.navbar-header .btn-social:hover:nth-of-type(3),
.navbar-header .btn-social:focus:nth-of-type(3) {
    color: #00aced
}

.navbar-header .btn-social+.btn-social {
    margin-left: 10px
}

.navbar-header .btn-social.btn-for-dark {
    color: rgba(255, 255, 255, 0.5)
}

.navbar-header .btn-social.btn-for-dark:hover,
.navbar-header .btn-social.btn-for-dark:focus {
    color: #fff
}

.navbar-header .btn-buy {
    letter-spacing: 1px;
    padding: 0;
    line-height: 1.454;
    margin-left: 15px;
    display: flex;
    align-items: center;
    color: #0168fa;
    font-size: 11px;
    font-weight: 600;
    text-transform: uppercase
}

@media (min-width: 992px) {
    .navbar-header .btn-buy {
        min-height: inherit;
        padding: 7px 12px;
        color: #0168fa;
        border: 2px solid #0168fa
    }

    .navbar-header .btn-buy:hover,
    .navbar-header .btn-buy:focus {
        background-color: #0168fa;
        color: #fff
    }
}

.navbar-header .btn-buy .feather {
    width: 18px;
    height: 18px;
    margin-right: 0
}

@media (min-width: 992px) {
    .navbar-header .btn-buy .feather {
        width: 14px;
        height: 14px
    }
}

.navbar-header .btn-buy span {
    margin-left: 8px;
    display: none
}

@media (min-width: 992px) {
    .navbar-header .btn-buy span {
        display: inline
    }
}

.navbar-header .btn-buy.btn-for-dark {
    border-color: #fff;
    color: #fff
}

.navbar-header .navbar-right {
    padding: 0 15px;
    margin-left: auto;
    display: flex;
    align-items: center;
    justify-content: flex-end;
    order: 2
}

@media (min-width: 576px) {
    .navbar-header .navbar-right {
        padding-right: 20px
    }
}

@media (min-width: 992px) {
    .navbar-header .navbar-right {
        order: 3;
        padding: 0 20px 0 0
    }
}

@media (min-width: 1200px) {
    .navbar-header .navbar-right {
        width: 240px
    }
}

.navbar-header .navbar-right .search-link {
    color: #001737
}

.navbar-header .navbar-right .search-link:hover,
.navbar-header .navbar-right .search-link:focus {
    color: rgba(0, 23, 55, 0.8)
}

.navbar-header .navbar-right .search-link svg {
    width: 16px;
    height: 16px;
    stroke-width: 3px
}

@media (min-width: 576px) {
    .navbar-header .navbar-right .search-link svg {
        width: 20px;
        height: 20px;
        stroke-width: 2.8px
    }
}

.navbar-header .navbar-right .dropdown {
    margin-left: 8px
}

@media (min-width: 576px) {
    .navbar-header .navbar-right .dropdown {
        margin-left: 15px
    }
}

.navbar-header .navbar-right .dropdown:last-child {
    margin-left: 15px
}

@media (min-width: 992px) {
    .navbar-header .navbar-right .dropdown:last-child {
        margin-left: 20px
    }
}

@media (min-width: 1200px) {
    .navbar-header .navbar-right .dropdown:last-child {
        margin-left: 30px
    }
}

.navbar-header .navbar-right .dropdown-menu {
    border-top-width: 0;
    border-top-left-radius: 0;
    border-top-right-radius: 0
}

.navbar-header .navbar-right .dropdown-message .dropdown-menu {
    margin-top: 17.5px;
    margin-right: -88px
}

.navbar-header .navbar-right .dropdown-message .dropdown-menu::before {
    right: 83px
}

.navbar-header .navbar-right .dropdown-message .dropdown-menu::after {
    right: 84px
}

@media (min-width: 576px) {
    .navbar-header .navbar-right .dropdown-message .dropdown-menu {
        margin-right: -20px
    }

    .navbar-header .navbar-right .dropdown-message .dropdown-menu::before {
        right: 20px
    }

    .navbar-header .navbar-right .dropdown-message .dropdown-menu::after {
        right: 21px
    }
}

@media (min-width: 992px) {
    .navbar-header .navbar-right .dropdown-message .dropdown-menu {
        margin-top: 19.5px
    }
}

.navbar-header .navbar-right .dropdown-notification .dropdown-menu {
    margin-top: 17.5px;
    margin-right: -53px
}

.navbar-header .navbar-right .dropdown-notification .dropdown-menu::before {
    right: 48px
}

.navbar-header .navbar-right .dropdown-notification .dropdown-menu::after {
    right: 49px
}

@media (min-width: 576px) {
    .navbar-header .navbar-right .dropdown-notification .dropdown-menu {
        margin-right: -20px
    }

    .navbar-header .navbar-right .dropdown-notification .dropdown-menu::before {
        right: 20px
    }

    .navbar-header .navbar-right .dropdown-notification .dropdown-menu::after {
        right: 21px
    }
}

@media (min-width: 992px) {
    .navbar-header .navbar-right .dropdown-notification .dropdown-menu {
        margin-top: 19.5px
    }
}

@media (max-width: 991.98px) {
    .navbar-header .navbar-right .dropdown-profile .dropdown-link .avatar {
        width: 28px;
        height: 28px
    }
}

@media (max-width: 991.98px) {
    .navbar-header .navbar-right .dropdown-profile .dropdown-menu {
        margin-top: 14px
    }
}

@media (min-width: 992px) {
    .navbar-header-fixed {
        position: fixed;
        top: 0;
        left: 0;
        right: 0;
        z-index: 1000
    }
}

.navbar-menu-wrapper {
    order: 3;
    width: 260px;
    position: fixed;
    top: 0;
    bottom: 0;
    left: 0;
    background-color: #fff;
    overflow: hidden;
    z-index: 1060;
    transform: translateX(-260px);
    transition: all 0.4s
}

@media (prefers-reduced-motion: reduce) {
    .navbar-menu-wrapper {
        transition: none
    }
}

@media (min-width: 992px) {
    .navbar-menu-wrapper {
        width: auto;
        padding: 0 25px;
        position: relative;
        top: auto;
        left: auto;
        bottom: auto;
        background-color: transparent;
        overflow: visible;
        display: flex;
        justify-content: center;
        order: 2;
        transform: none;
        z-index: auto;
        flex: 1
    }
}

@media (min-width: 1200px) {
    .navbar-menu-wrapper {
        padding: 0 30px
    }
}

.navbar-menu-wrapper>.ps__rail-y {
    display: none
}

.navbar-menu-header {
    height: 55px;
    padding: 0 15px 0 20px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    border-bottom: 1px solid rgba(72, 94, 144, 0.16)
}

@media (min-width: 992px) {
    .navbar-menu-header {
        display: none
    }
}

.navbar-menu-header .feather-x {
    width: 20px;
    height: 20px;
    stroke-width: 2.5px
}

.navbar-menu-header a:last-child {
    color: #7987a1
}

.navbar-menu-header a:last-child:hover,
.navbar-menu-header a:last-child:focus {
    color: #1c273c
}

.navbar-menu {
    flex-direction: column;
    padding: 25px 0
}

@media (min-width: 992px) {
    .navbar-menu {
        align-items: center;
        flex-direction: row;
        padding: 0;
        width: 100%;
        max-width: 780px;
        margin-top: -3px
    }
}

.navbar-menu .nav-item+.nav-item {
    margin-top: 10px
}

@media (min-width: 992px) {
    .navbar-menu .nav-item+.nav-item {
        margin-top: 0;
        margin-left: 25px
    }
}

@media (min-width: 1200px) {
    .navbar-menu .nav-item+.nav-item {
        margin-left: 30px
    }
}

.navbar-menu .nav-item.active .nav-link {
    position: relative;
    color: #0168fa
}

.navbar-menu .nav-item.active .nav-link::before {
    content: '';
    position: absolute;
    top: calc(50% - 1px);
    left: 0;
    width: 10px;
    height: 2px;
    background-color: #0168fa
}

@media (min-width: 992px) {
    .navbar-menu .nav-item.active .nav-link::before {
        display: none
    }
}

.navbar-menu .nav-item.active .nav-link svg {
    color: #0168fa;
    fill: rgba(1, 104, 250, 0.2)
}

.navbar-menu .nav-item.active.with-sub .nav-link::after {
    border-color: #0168fa
}

.navbar-menu .nav-item.with-sub {
    position: relative
}

.navbar-menu .nav-item.with-sub .nav-link::after {
    content: '';
    width: 6px;
    height: 6px;
    border-right: 1.5px solid #8392a5;
    border-bottom: 1.5px solid #8392a5;
    display: inline-block;
    transform: rotate(45deg);
    margin-left: auto;
    transition: all 0.2s ease-in-out
}

@media (prefers-reduced-motion: reduce) {
    .navbar-menu .nav-item.with-sub .nav-link::after {
        transition: none
    }
}

@media (min-width: 992px) {
    .navbar-menu .nav-item.with-sub .nav-link::after {
        margin-left: 6px
    }
}

.navbar-menu .nav-item.show .navbar-menu-sub {
    display: block
}

.navbar-menu .nav-link {
    display: flex;
    align-items: center;
    justify-content: flex-start;
    font-size: 14px;
    font-weight: 500;
    padding: 0 20px;
    color: #001737;
    transition: all 0.2s ease-in-out
}

@media (prefers-reduced-motion: reduce) {
    .navbar-menu .nav-link {
        transition: none
    }
}

@media (min-width: 992px) {
    .navbar-menu .nav-link {
        font-weight: 500;
        padding: 0
    }
}

.navbar-menu .nav-link:hover {
    color: #0168fa
}

.navbar-menu .nav-link:hover svg {
    color: #0168fa;
    fill: rgba(1, 104, 250, 0.2)
}

.navbar-menu .nav-link svg {
    width: 18px;
    height: 18px;
    stroke-width: 2.2px;
    fill: rgba(27, 46, 75, 0.1);
    margin-right: 15px;
    color: #7987a1;
    transition: all 0.2s ease-in-out
}

@media (prefers-reduced-motion: reduce) {
    .navbar-menu .nav-link svg {
        transition: none
    }
}

@media (min-width: 992px) {
    .navbar-menu .nav-link svg {
        display: none
    }
}

.navbar-menu .nav-label {
    font-size: 10px;
    font-weight: 600;
    font-family: -apple-system, BlinkMacSystemFont, "Inter UI", Roboto, sans-serif;
    letter-spacing: .7px;
    color: #8392a5;
    text-transform: uppercase;
    display: block;
    margin-bottom: 15px
}

.navbar-menu-sub {
    position: relative;
    min-width: 200px;
    list-style: none;
    padding: 0 15px 3px 24px;
    border-left: 1px solid rgba(72, 94, 144, 0.16);
    margin-top: 10px;
    margin-left: 28px;
    z-index: 1000;
    display: none
}

@media (min-width: 992px) {
    .navbar-menu-sub {
        position: absolute;
        top: 41.5px;
        left: -25px;
        background-color: #fff;
        padding: 20px 25px;
        border: 1px solid rgba(192, 204, 218, 0.53);
        margin-top: 0;
        margin-left: 0;
        border-bottom-right-radius: .25rem;
        border-bottom-left-radius: .25rem
    }
}

.navbar-menu-sub::before {
    content: '';
    position: absolute;
    top: -10px;
    left: 25px;
    border-bottom: 10px solid rgba(192, 204, 218, 0.53);
    border-left: 10px solid transparent;
    border-right: 10px solid transparent
}

.navbar-menu-sub::after {
    content: '';
    position: absolute;
    top: -8.5px;
    left: 26px;
    border-bottom: 9px solid #fff;
    border-left: 9px solid transparent;
    border-right: 9px solid transparent
}

.navbar-menu-sub::before,
.navbar-menu-sub::after {
    display: none
}

@media (min-width: 992px) {

    .navbar-menu-sub::before,
    .navbar-menu-sub::after {
        display: block
    }
}

.navbar-menu-sub ul {
    padding: 0;
    margin: 0;
    list-style: none;
    position: relative;
    min-width: 150px
}

.navbar-menu-sub ul+ul {
    margin-top: 25px
}

@media (min-width: 992px) {
    .navbar-menu-sub ul+ul {
        margin-top: 0;
        margin-left: 25px;
        padding-left: 25px;
        border-left: 1px solid rgba(72, 94, 144, 0.16)
    }
}

.navbar-menu-sub .nav-sub-item+.nav-sub-item {
    margin-top: 5px
}

.navbar-menu-sub .nav-sub-link {
    color: #1b2e4b;
    white-space: nowrap;
    font-size: 13px;
    display: flex;
    align-items: center;
    transition: all 0.2s ease-in-out
}

@media (prefers-reduced-motion: reduce) {
    .navbar-menu-sub .nav-sub-link {
        transition: none
    }
}

.navbar-menu-sub .nav-sub-link svg {
    width: 16px;
    height: 16px;
    margin-right: 15px;
    fill: rgba(0, 23, 55, 0.08);
    display: none
}

@media (min-width: 992px) {
    .navbar-menu-sub .nav-sub-link svg {
        display: block
    }
}

.navbar-menu-sub .nav-sub-link .feather-bar-chart-2 {
    margin-left: -4px
}

.navbar-menu-sub .nav-sub-link:hover {
    color: #0168fa
}

.navbar-menu-sub .nav-sub-link:hover svg {
    fill: rgba(1, 104, 250, 0.2)
}

.navbar-menu-sub.navbar-inverse {
    background-color: #141c2b;
    border-width: 0
}

.navbar-menu-sub.navbar-inverse::before {
    border-bottom-color: #141c2b
}

.navbar-menu-sub.navbar-inverse::after {
    display: none
}

.navbar-menu-sub.navbar-inverse .nav-label {
    color: rgba(255, 255, 255, 0.3);
    font-weight: 500
}

.navbar-menu-sub.navbar-inverse ul+ul {
    border-left-color: rgba(255, 255, 255, 0.05)
}

.navbar-menu-sub.navbar-inverse .nav-sub-link {
    color: rgba(255, 255, 255, 0.5);
    font-weight: 400
}

.navbar-menu-sub.navbar-inverse .nav-sub-link:hover,
.navbar-menu-sub.navbar-inverse .nav-sub-link:focus {
    color: #fff
}

.navbar-menu-sub.navbar-inverse .nav-sub-link:hover .feather,
.navbar-menu-sub.navbar-inverse .nav-sub-link:focus .feather {
    color: #fff
}

.navbar-search {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    z-index: 100;
    visibility: hidden;
    opacity: 0;
    transition: all 0.2s ease-in-out
}

@media (prefers-reduced-motion: reduce) {
    .navbar-search {
        transition: none
    }
}

.navbar-search.visible {
    visibility: visible;
    opacity: 1
}

.navbar-search-header {
    height: 55px;
    background-color: #fff;
    padding: 0 15px;
    display: flex;
    align-items: center;
    border-bottom: 1px solid rgba(72, 94, 144, 0.16)
}

@media (min-width: 576px) {
    .navbar-search-header {
        padding: 0 20px;
        height: 60px
    }
}

@media (min-width: 992px) {
    .navbar-search-header {
        padding: 0 25px
    }
}

.navbar-search-header .form-control {
    padding: 0;
    font-size: 14px;
    font-weight: 500;
    color: #001737;
    border-width: 0;
    background-color: transparent
}

@media (min-width: 992px) {
    .navbar-search-header .form-control {
        font-size: 16px
    }
}

.navbar-search-header .form-control::placeholder {
    font-weight: 400
}

.navbar-search-header .form-control:focus,
.navbar-search-header .form-control:active {
    box-shadow: none !important
}

.navbar-search-header .btn,
.navbar-search-header .ui-datepicker-buttonpane button,
.ui-datepicker-buttonpane .navbar-search-header button,
.navbar-search-header .sp-container button,
.sp-container .navbar-search-header button {
    padding: 0
}

.navbar-search-header .btn svg,
.navbar-search-header .ui-datepicker-buttonpane button svg,
.ui-datepicker-buttonpane .navbar-search-header button svg,
.navbar-search-header .sp-container button svg,
.sp-container .navbar-search-header button svg {
    width: 20px;
    height: 20px;
    stroke-width: 2.8px
}

.navbar-search-body {
    padding: 15px 15px 20px;
    background-color: #fff;
    font-size: 13px
}

@media (min-width: 992px) {
    .navbar-search-body {
        padding: 25px 25px 30px
    }
}

.navbar-search-body ul {
    display: flex;
    flex-direction: column;
    margin-bottom: 0
}

@media (min-width: 992px) {
    .navbar-search-body ul {
        flex-direction: row
    }
}

.navbar-search-body li+li {
    margin-top: 2px
}

@media (min-width: 992px) {
    .navbar-search-body li+li {
        margin-top: 0;
        margin-left: 5px
    }
}

.navbar-search-body a {
    padding: 5px 10px;
    display: block;
    color: #1b2e4b;
    border-radius: .25rem
}

@media (min-width: 992px) {
    .navbar-search-body a {
        border: 1px solid rgba(72, 94, 144, 0.16)
    }
}

.navbar-search-body a:hover,
.navbar-search-body a:focus {
    background-color: #e5e9f2
}

@media (min-width: 992px) {

    .navbar-search-body a:hover,
    .navbar-search-body a:focus {
        background-color: #c0ccda;
        border-color: transparent
    }
}

.df-logo {
    font-weight: 700;
    font-size: 22px;
    letter-spacing: -1px;
    color: inherit;
    display: flex;
    align-items: center;
    position: relative;
    color: #031a61;
    margin-top: -4px
}

@media (min-width: 576px) {
    .df-logo {
        font-size: 22px
    }
}

@media (min-width: 992px) {
    .df-logo {
        font-size: 24px
    }
}

.df-logo:hover,
.df-logo:focus {
    color: #03217a
}

.df-logo span {
    display: inline-block;
    font-weight: 400;
    color: #0168fa
}

.df-logo-initial {
    width: 40px;
    height: 40px;
    border: 2px solid #0168fa;
    color: #0168fa;
    border-radius: 6px;
    display: flex;
    align-items: center;
    justify-content: center
}

.df-logo-initial p {
    font-size: 24px;
    font-weight: 600;
    letter-spacing: -2px;
    margin-bottom: 0
}

@media (max-width: 991.98px) {
    .sidebar-show {
        overflow: hidden;
        position: relative
    }

    .sidebar-show .sidebar {
        left: 0;
        box-shadow: 0 0 50px rgba(28, 39, 60, 0.06)
    }

    .sidebar-show .off-canvas-pusher {
        position: static
    }

    .sidebar-show .off-canvas {
        display: none
    }

    .sidebar-show .backdrop {
        opacity: 1;
        visibility: visible;
        left: 260px
    }
}

.sidebar {
    width: 260px;
    position: fixed;
    top: 0;
    bottom: 0;
    left: -260px;
    z-index: 1050;
    overflow: hidden;
    transition: all 0.4s
}

@media (prefers-reduced-motion: reduce) {
    .sidebar {
        transition: none
    }
}

@media (min-width: 992px) {
    .sidebar {
        width: 240px;
        position: relative;
        top: auto;
        bottom: auto;
        left: auto
    }
}

.sidebar-fixed {
    background-color: #fff
}

@media (min-width: 992px) {
    .sidebar-fixed {
        background-color: transparent;
        border-right: 1px solid rgba(72, 94, 144, 0.16);
        width: 240px;
        position: fixed;
        top: 60px;
        bottom: 0;
        z-index: 900;
        left: 0
    }
}

.sidebar-fixed>.ps__rail-y {
    background-color: rgba(72, 94, 144, 0.16);
    width: 2px;
    display: none
}

@media (min-width: 992px) {
    .sidebar-fixed>.ps__rail-y {
        display: block
    }
}

.sidebar-fixed>.ps__rail-y>.ps__thumb-y {
    background-color: #c0ccda;
    width: 2px;
    left: 0
}

@media (min-width: 992px) {
    .sidebar-fixed>.ps__rail-y>.ps__thumb-y {
        background-color: #0168fa
    }
}

.sidebar-header {
    height: 55px;
    padding: 0 20px 0 25px;
    display: flex;
    align-items: center;
    border-bottom: 1px solid rgba(72, 94, 144, 0.16)
}

@media (min-width: 992px) {
    .sidebar-header {
        display: none
    }
}

.sidebar-header a {
    color: #7987a1
}

.sidebar-header a:hover,
.sidebar-header a:focus {
    color: #1c273c
}

.sidebar-header a svg {
    width: 20px;
    height: 20px;
    stroke-width: 2.5px
}

.sidebar-header a:first-child {
    margin-right: 17px
}

.sidebar-header a:last-child {
    margin-left: auto
}

.sidebar-header h5 {
    font-size: 14px;
    font-weight: 700;
    color: #1c273c;
    text-transform: uppercase;
    margin-top: 3px;
    margin-bottom: 0
}

.sidebar-body {
    padding: 25px
}

.sidebar-nav {
    list-style: none;
    padding: 0;
    margin: 0
}

.sidebar-nav .nav-label {
    font-size: 10px;
    font-weight: 500;
    font-family: -apple-system, BlinkMacSystemFont, "Inter UI", Roboto, sans-serif;
    color: #8392a5;
    text-transform: uppercase;
    letter-spacing: .7px
}

.sidebar-nav .nav-item+.nav-item {
    margin-top: 9px
}

.sidebar-nav .nav-item.show .nav {
    display: block
}

.sidebar-nav .nav-link {
    padding: 0;
    color: #001737;
    font-weight: 500;
    font-size: 14px;
    display: flex;
    align-items: center
}

@media (min-width: 992px) {
    .sidebar-nav .nav-link {
        font-size: 13px
    }
}

.sidebar-nav .nav-link svg {
    margin-right: 15px;
    width: 18px;
    height: 18px;
    stroke-width: 2.2px;
    color: #525f70;
    fill: rgba(27, 46, 75, 0.06)
}

.sidebar-nav .nav-link.active {
    position: relative;
    color: #0168fa
}

.sidebar-nav .nav-link.active::before {
    content: '';
    position: absolute;
    top: calc(50% - 1px);
    left: -25px;
    width: 10px;
    height: 2px;
    background-color: #0168fa
}

@media (min-width: 1200px) {
    .sidebar-nav .nav-link.active::before {
        left: -30px;
        width: 13px
    }
}

.sidebar-nav .nav-link.active svg {
    color: #0168fa;
    fill: rgba(1, 104, 250, 0.2)
}

.sidebar-nav .nav-link.with-sub::after {
    content: '';
    width: 6px;
    height: 6px;
    border-right: 1.5px solid #8392a5;
    border-bottom: 1.5px solid #8392a5;
    display: inline-block;
    transform: rotate(45deg);
    margin-top: -3px;
    margin-left: auto;
    align-self: center
}

.sidebar-nav .nav-link.with-sub:hover::after,
.sidebar-nav .nav-link.with-sub:focus::after {
    border-color: #67788e
}

.sidebar-nav .nav {
    display: none;
    border-left: 1px solid rgba(229, 233, 242, 0.7);
    margin-left: 8px;
    margin-top: 10px;
    padding-left: 25px;
    padding-bottom: 3px
}

.sidebar-nav .nav a {
    display: block;
    font-size: 14px;
    color: #1b2e4b
}

@media (min-width: 992px) {
    .sidebar-nav .nav a {
        font-size: 13px
    }
}

.sidebar-nav .nav a:hover {
    color: #0168fa
}

.sidebar-nav .nav a.active {
    position: relative;
    color: #0168fa;
    font-weight: 500
}

.sidebar-nav .nav a.active::before {
    content: '';
    position: absolute;
    top: 0;
    bottom: 0;
    left: -26px;
    border-left: 1px solid #0168fa
}

.sidebar-nav .nav a+a {
    margin-top: 5px
}

.show-aside {
    overflow: hidden
}

.show-aside .aside {
    transform: translateX(0)
}

.show-aside .aside-header {
    transform: none;
    border-right-color: transparent
}

.show-aside .aside-header .burger-menu {
    display: none
}

.show-aside .aside-menu-link svg:first-child {
    display: none
}

.show-aside .aside-menu-link svg:last-child {
    display: block
}

.show-aside .aside-body {
    border-right-color: transparent
}

.show-aside .aside-backdrop {
    opacity: 1;
    visibility: visible
}

.aside {
    width: 240px;
    height: 100%;
    transform: translateX(-100%)
}

@media (min-width: 992px) {
    .aside {
        transform: translateX(0)
    }
}

.aside.minimize {
    width: auto
}

.aside.minimize .aside-header {
    width: 60px;
    padding: 0;
    justify-content: center
}

.aside.minimize .aside-logo,
.aside.minimize .aside-loggedin-user {
    display: none
}

.aside.minimize .aside-body {
    width: 60px;
    padding: 20px;
    transition: none
}

@media (prefers-reduced-motion: reduce) {
    .aside.minimize .aside-body {
        transition: none
    }
}

.aside.minimize .aside-alert-link {
    opacity: 0;
    visibility: hidden;
    position: fixed
}

.aside.minimize .aside-loggedin {
    margin-bottom: 5px
}

.aside.minimize .aside-loggedin .avatar {
    width: 26px;
    height: 26px;
    flex-shrink: 0;
    margin-left: -4px
}

.aside.minimize .nav-aside {
    width: 100%;
    flex-wrap: nowrap
}

.aside.minimize .nav-aside .nav-label,
.aside.minimize .nav-aside .nav-item ul,
.aside.minimize .nav-aside .with-sub .nav-link::after {
    display: none
}

.aside.minimize .nav-aside .nav-link::before {
    right: auto;
    left: -20px
}

.aside.minimize .nav-aside .nav-link span {
    position: fixed;
    opacity: 0;
    visibility: hidden;
    margin-left: 31px
}

.aside.minimize .nav-aside .nav-label+.nav-item {
    position: relative
}

.aside.minimize .nav-aside .nav-label+.nav-item::before {
    content: '';
    display: block;
    border-top: 1px solid rgba(72, 94, 144, 0.16);
    margin: 8px 0
}

.aside.minimize .nav-aside svg {
    margin-right: 0
}

.aside.minimize+.content {
    margin-left: 60px
}

.aside.maximize .aside-body {
    width: 240px;
    padding: 20px;
    display: block
}

.aside.maximize .aside-alert-link {
    opacity: 1;
    visibility: visible;
    position: relative
}

.aside.maximize .nav-aside {
    width: auto;
    flex-wrap: wrap;
    align-items: stretch
}

.aside.maximize .nav-aside .nav-item ul,
.aside.maximize .nav-aside .with-sub .nav-link::after {
    display: block
}

.aside.maximize .nav-aside .nav-link span {
    opacity: 1;
    visibility: visible;
    position: relative;
    margin-left: 0
}

.aside.maximize .nav-aside svg {
    margin-right: 15px
}

.aside-fixed {
    position: fixed;
    top: 0;
    left: 0;
    bottom: 0;
    z-index: 1000
}

@media (min-width: 992px) {
    .aside-fixed+.content {
        margin-left: 60px
    }
}

@media (min-width: 1200px) {
    .aside-fixed+.content {
        margin-left: 240px
    }
}

.aside-header {
    display: flex;
    align-items: center;
    justify-content: space-between;
    height: 54px;
    padding: 0 18px;
    border-right: 1px solid rgba(72, 94, 144, 0.16);
    background-color: #fff;
    transform: translateX(54px)
}

@media (min-width: 992px) {
    .aside-header {
        padding: 0 20px;
        transform: none;
        height: 59px
    }
}

.aside-header .burger-menu {
    color: #8392a5
}

.aside-header .burger-menu:hover,
.aside-header .burger-menu:focus {
    color: #1b2e4b
}

.aside-header .burger-menu svg {
    width: 20px;
    height: 20px;
    stroke-width: 2.5px;
    margin-right: -2px
}

.aside-logo {
    font-size: 22px;
    font-weight: 700;
    letter-spacing: -1px;
    margin-top: -3px
}

@media (min-width: 992px) {
    .aside-logo {
        font-size: 24px
    }
}

.aside-logo span {
    font-weight: normal
}

.aside-logo,
.aside-logo:hover,
.aside-logo:focus {
    color: #031d6b
}

.aside-logo span,
.aside-logo:hover span,
.aside-logo:focus span {
    color: #0168fa
}

.aside-menu-link {
    color: #8392a5
}

.aside-menu-link:hover,
.aside-menu-link:focus {
    color: #1b2e4b
}

.aside-menu-link svg {
    width: 18px;
    height: 18px;
    stroke-width: 2.5px;
    margin-top: -3px
}

.aside-menu-link svg:last-child {
    width: 20px;
    height: 20px;
    display: none;
    margin-top: 0
}

.aside-body {
    position: relative;
    padding: 25px;
    height: calc(100% - 59px);
    border-top: 1px solid rgba(72, 94, 144, 0.16);
    border-right: 1px solid rgba(72, 94, 144, 0.16);
    background-color: #fff
}

.aside-loggedin {
    margin-bottom: 25px
}

.aside-loggedin .avatar {
    width: 48px;
    height: 48px
}

.aside-loggedin svg {
    width: 16px;
    height: 16px;
    stroke-width: 2.3px;
    pointer-events: none
}

.aside-loggedin .collapsing {
    transition: none
}

@media (prefers-reduced-motion: reduce) {
    .aside-loggedin .collapsing {
        transition: none
    }
}

.aside-loggedin .nav {
    margin-top: 15px
}

.aside-loggedin-user {
    margin-top: 15px
}

.aside-loggedin-user a {
    color: #c0ccda
}

.aside-loggedin-user a:hover,
.aside-loggedin-user a:focus {
    color: #1b2e4b
}

.aside-alert-link {
    margin-left: auto;
    display: flex;
    align-items: center
}

.aside-alert-link a {
    color: rgba(27, 46, 75, 0.5);
    display: block
}

.aside-alert-link a+a {
    margin-left: 10px
}

.aside-alert-link a.new {
    position: relative
}

.aside-alert-link a.new::before {
    content: '';
    position: absolute;
    top: 0;
    right: 0;
    width: 7px;
    height: 7px;
    background-color: #dc3545;
    box-shadow: 0 0 0 2px #fff;
    border-radius: 100%
}

.aside-alert-link a:hover,
.aside-alert-link a:focus {
    color: #1b2e4b
}

.nav-aside {
    flex-direction: column;
    line-height: 1.6;
    font-size: 13px;
    white-space: nowrap;
    margin-bottom: 25px
}

.nav-aside .nav-label {
    font-size: 10px;
    font-weight: 700;
    font-family: -apple-system, BlinkMacSystemFont, "Inter UI", Roboto, sans-serif;
    letter-spacing: .5px;
    text-transform: uppercase;
    color: #001737;
    margin-bottom: 5px;
    display: block;
    transition: all 0.25s
}

@media (prefers-reduced-motion: reduce) {
    .nav-aside .nav-label {
        transition: none
    }
}

.nav-aside .nav-item {
    position: relative
}

.nav-aside .nav-item.active .nav-link {
    opacity: 1;
    color: #0168fa;
    font-weight: 500
}

.nav-aside .nav-item.active .nav-link::before {
    opacity: 1;
    visibility: visible;
    border-left-color: #0168fa
}

.nav-aside .nav-item.active svg {
    color: #0168fa;
    fill: rgba(1, 104, 250, 0.2)
}

.nav-aside .nav-item+.nav-item {
    margin-top: 1px
}

.nav-aside .nav-item ul {
    margin: 0;
    padding-left: 34px;
    list-style: none;
    opacity: 0;
    visibility: hidden;
    height: 0
}

.nav-aside .nav-item ul a {
    display: block;
    color: rgba(27, 46, 75, 0.75);
    padding: 2px 0
}

.nav-aside .nav-item ul a:hover,
.nav-aside .nav-item ul a:focus {
    color: #1b2e4b
}

.nav-aside .nav-item ul .active a {
    color: #0168fa
}

.nav-aside .with-sub .nav-link::after {
    content: '\f3d1';
    font-family: 'Ionicons';
    font-size: 12px;
    display: inline-block;
    position: relative;
    margin-left: auto;
    opacity: .5
}

.nav-aside .with-sub.show .nav-link {
    font-weight: 500
}

.nav-aside .with-sub.show .nav-link::before {
    opacity: 1;
    visibility: visible
}

.nav-aside .with-sub.show .nav-link::after {
    transform: rotate(90deg)
}

.nav-aside .with-sub.show ul {
    opacity: 1;
    visibility: visible;
    padding-bottom: 10px;
    height: auto
}

.nav-aside .with-sub.show:not(.active) .nav-link {
    color: #1b2e4b
}

.nav-aside .with-sub.show:not(.active) svg {
    color: #1b2e4b
}

.nav-aside .nav-link {
    position: relative;
    display: flex;
    align-items: center;
    padding: 0;
    height: 30px;
    color: rgba(27, 46, 75, 0.9);
    transition: all 0.25s
}

@media (prefers-reduced-motion: reduce) {
    .nav-aside .nav-link {
        transition: none
    }
}

.nav-aside .nav-link::before {
    content: '';
    position: absolute;
    top: 2px;
    left: -25px;
    bottom: 2px;
    right: -25px;
    border-left: 3px solid #8392a5;
    opacity: 0;
    visibility: hidden
}

.nav-aside .nav-link:hover,
.nav-aside .nav-link:focus {
    color: #1b2e4b
}

.nav-aside svg {
    width: 18px;
    height: 18px;
    stroke-width: 2.3px;
    margin-right: 15px;
    color: rgba(27, 46, 75, 0.65);
    fill: rgba(27, 46, 75, 0.06)
}

.aside-backdrop {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: rgba(28, 39, 60, 0.7);
    opacity: 0;
    z-index: 900;
    visibility: hidden
}

@media (min-width: 992px) {
    .aside-backdrop {
        display: none
    }
}

.content {
    padding: 20px
}

@media (min-width: 992px) {
    .content {
        padding: 25px
    }
}

@media (min-width: 1200px) {
    .content {
        padding: 30px
    }
}

@media (min-width: 992px) {
    .content-fixed {
        margin-top: 60px
    }
}

.content-header {
    background-color: #fff;
    height: 55px;
    padding: 0 15px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    border-bottom: 1px solid rgba(72, 94, 144, 0.16);
    padding-left: 65px
}

@media (min-width: 992px) {
    .content-header {
        height: 60px;
        padding: 0 25px
    }
}

@media (max-width: 575px) {
    .content-header .nav {
        flex-wrap: nowrap
    }
}

.content-header .nav-link {
    padding: 0;
    color: rgba(27, 46, 75, 0.75)
}

.content-header .nav-link:hover,
.content-header .nav-link:focus {
    color: #1b2e4b
}

.content-header .nav-link+.nav-link {
    margin-left: 7px
}

@media (min-width: 576px) {
    .content-header .nav-link+.nav-link {
        margin-left: 10px
    }
}

.content-header svg {
    width: 18px;
    height: 18px
}

@media (min-width: 992px) {
    .content-header svg {
        width: 20px;
        height: 20px
    }
}

.content-header svg.feather-search {
    stroke-width: 2.8px
}

.content-search {
    width: 250px;
    display: flex;
    align-items: center
}

.content-search svg {
    stroke-width: 2.5px;
    margin-right: 10px;
    color: #8392a5
}

.content-search .form-control {
    padding: 0;
    border-width: 0;
    background-color: transparent
}

.content-search .form-control:focus {
    box-shadow: none;
    color: #1b2e4b
}

.content-body {
    position: relative;
    padding: 15px;
    height: calc(100% - 54px);
    overflow-y: auto
}

@media (min-width: 576px) {
    .content-body {
        padding: 20px
    }
}

@media (min-width: 992px) {
    .content-body {
        padding: 25px;
        height: calc(100% - 60px)
    }
}

.content-body .container {
    margin-bottom: 25px
}

@media (max-width: 1199.98px) {
    .content-body .container {
        max-width: none
    }
}

.content-footer {
    font-size: 10px;
    font-family: -apple-system, BlinkMacSystemFont, "Inter UI", Roboto, sans-serif;
    text-transform: uppercase;
    letter-spacing: .3px;
    margin-top: 60px;
    padding-top: 20px;
    border-top: 1px solid rgba(72, 94, 144, 0.16);
    color: #637388
}

@media (min-width: 576px) {
    .content-footer {
        display: flex;
        align-items: center;
        justify-content: space-between
    }
}

@media (min-width: 992px) {
    .content-footer {
        padding-top: 25px
    }
}

@media (min-width: 1200px) {
    .content-footer {
        padding-top: 30px
    }
}

.content-footer a {
    padding: 0;
    color: #1b2e4b
}

.content-footer a:hover,
.content-footer a:focus {
    color: #0168fa
}

.content-footer a+.nav-link {
    margin-left: 25px
}

.content-footer>div:last-child {
    margin-top: 15px
}

@media (min-width: 576px) {
    .content-footer>div:last-child {
        margin-top: 0
    }
}

.footer {
    font-size: 10px;
    font-family: -apple-system, BlinkMacSystemFont, "Inter UI", Roboto, sans-serif;
    letter-spacing: .3px;
    padding: 15px 25px;
    background-color: #fafbfc;
    color: #637388;
    border-top: 1px solid rgba(72, 94, 144, 0.16);
    text-transform: uppercase;
    display: none
}

@media (min-width: 992px) {
    .footer {
        display: flex;
        align-items: center;
        justify-content: space-between
    }
}

.footer a {
    padding: 0;
    color: #1b2e4b
}

.footer a:hover,
.footer a:focus {
    color: #0168fa
}

.footer a+.nav-link {
    margin-left: 25px
}

.footer>div:last-child {
    margin-top: 15px
}

@media (min-width: 576px) {
    .footer>div:last-child {
        margin-top: 0
    }
}

.df-roboto {
    font-family: 'Roboto', sans-serif
}

.df-settings {
    position: fixed;
    top: 0;
    right: 0;
    bottom: 0;
    z-index: 1200;
    transition: all 0.2s
}

@media (prefers-reduced-motion: reduce) {
    .df-settings {
        transition: none
    }
}

.df-settings.show {
    right: 200px
}

.df-settings.show .df-settings-link {
    position: relative
}

.df-settings.show .df-settings-link,
.df-settings.show .df-settings-link:hover,
.df-settings.show .df-settings-link:focus {
    background-color: #fff;
    border-color: #c0ccda;
    color: #1c273c;
    box-shadow: -5px 0 10px rgba(28, 39, 60, 0.1)
}

.df-settings-link {
    position: absolute;
    top: 98px;
    left: -44px;
    width: 45px;
    height: 45px;
    background-color: #1c273c;
    border: 1px solid transparent;
    border-right-width: 0;
    color: #fff;
    display: flex;
    align-items: center;
    justify-content: center;
    border-top-left-radius: .25rem;
    border-bottom-left-radius: .25rem;
    transition: all 0.2s ease-in-out;
    z-index: 1
}

@media (prefers-reduced-motion: reduce) {
    .df-settings-link {
        transition: none
    }
}

.df-settings-link:hover,
.df-settings-link:focus {
    color: #fff;
    background-color: #3b4863;
    box-shadow: 0 0 10px 2px rgba(28, 39, 60, 0.1)
}

@-webkit-keyframes rotating {
    from {
        -webkit-transform: rotate(0deg);
        -o-transform: rotate(0deg);
        transform: rotate(0deg)
    }

    to {
        -webkit-transform: rotate(360deg);
        -o-transform: rotate(360deg);
        transform: rotate(360deg)
    }
}

@keyframes rotating {
    from {
        -ms-transform: rotate(0deg);
        -moz-transform: rotate(0deg);
        -webkit-transform: rotate(0deg);
        -o-transform: rotate(0deg);
        transform: rotate(0deg)
    }

    to {
        -ms-transform: rotate(360deg);
        -moz-transform: rotate(360deg);
        -webkit-transform: rotate(360deg);
        -o-transform: rotate(360deg);
        transform: rotate(360deg)
    }
}

.df-settings-link svg {
    width: 20px;
    height: 20px;
    -webkit-animation: rotating 3s linear infinite;
    -moz-animation: rotating 3s linear infinite;
    -ms-animation: rotating 3s linear infinite;
    -o-animation: rotating 3s linear infinite;
    animation: rotating 3s linear infinite
}

.df-settings-body {
    position: absolute;
    top: 0;
    left: 0;
    width: 250px;
    height: 100%;
    background-color: #fff;
    border-left: 1px solid #c0ccda;
    box-shadow: 0 0 15px rgba(28, 39, 60, 0.1);
    padding: 0 20px;
    overflow-y: auto
}

.df-settings-body label {
    display: block;
    line-height: 1
}

.df-skin,
.df-mode {
    height: 20px;
    border: 1px solid rgba(72, 94, 144, 0.16);
    display: flex;
    margin-bottom: 5px;
    padding: 2px
}

.df-skin:hover,
.df-skin:focus,
.df-mode:hover,
.df-mode:focus {
    border-color: #8392a5;
    box-shadow: 0 0 0 1px #8392a5
}

.df-skin.active,
.df-mode.active {
    border-color: #0168fa;
    box-shadow: 0 0 0 1px #0168fa;
    position: relative
}

.df-skin.active::before,
.df-mode.active::before {
    content: '\f3ff';
    font-family: 'Ionicons';
    font-size: 26px;
    position: absolute;
    top: 50%;
    left: 50%;
    line-height: 0;
    margin-left: -5px;
    margin-top: -1px;
    color: #0168fa
}

.df-skin.active~span,
.df-mode.active~span {
    color: #0168fa
}

.df-skin.disabled,
.df-mode.disabled {
    cursor: default;
    border-color: rgba(72, 94, 144, 0.16);
    box-shadow: none;
    opacity: .75
}

.df-skin>span,
.df-mode>span {
    display: block;
    flex: 1
}

.df-skin-deepblue span:first-child {
    background-color: #4994fe
}

.df-skin-deepblue span:last-child {
    background-color: #0536c5
}

.df-skin-charcoal span:first-child {
    background-color: #e5e9f2
}

.df-skin-charcoal span:last-child {
    background-color: #2d353e
}

.df-skin-gradient1 span:first-child {
    background-color: #5b47fb
}

.df-skin-gradient1 span:last-child {
    background-color: #0168fa
}

.df-skin-name {
    font-size: 9px;
    font-weight: 600;
    font-family: -apple-system, BlinkMacSystemFont, "Inter UI", Roboto, sans-serif;
    text-transform: uppercase;
    display: block;
    text-align: center;
    color: #8392a5
}

.card-widget .card-header {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding-right: 10px;
    padding-left: 15px
}

.card-widget .card-header .nav a {
    color: #8392a5
}

.card-widget .card-header .nav a:hover,
.card-widget .card-header .nav a:focus {
    color: #1b2e4b
}

.card-widget .card-header .nav a+a {
    margin-left: 5px
}

.card-widget .card-header svg {
    width: 16px;
    height: 16px
}

.card-widget .card-title {
    margin-bottom: 0;
    text-transform: uppercase;
    font-weight: 600
}

.card-widget .card-body {
    padding: 15px
}

.card-widget .card-footer {
    padding: 12px 15px;
    font-size: 12px
}

.card-contacts .card-body {
    padding: 0
}

.card-contacts .list-group-item {
    padding: 15px;
    padding-right: 10px;
    display: flex;
    align-items: center
}

.card-contacts .list-group-item:hover,
.card-contacts .list-group-item:focus {
    background-color: #f5f6fa
}

.card-contacts .list-body {
    margin-left: 15px
}

.card-contacts .list-body h6 {
    margin-bottom: 2px;
    font-size: 13px
}

.card-contacts .list-body p {
    font-size: 11px;
    font-family: "Lato", sans-serif;
    margin-bottom: 0;
    color: #8392a5
}

.card-contacts .avatar {
    width: 36px;
    height: 36px
}

.card-contacts .avatar::after {
    width: 6px;
    height: 6px
}

.card-contacts .nav {
    margin-left: auto;
    align-items: center
}

.card-contacts .nav svg {
    width: 15px;
    height: 15px
}

.card-contacts .nav a {
    color: #8392a5
}

.card-contacts .nav a:hover,
.card-contacts .nav a:focus {
    color: #0168fa
}

.card-contacts .nav a+a {
    margin-left: 5px
}

.card-events .media+.media {
    margin-top: 10px
}

.card-events .media-left {
    min-width: 30px;
    text-align: center
}

.card-events .media-left label {
    text-transform: uppercase;
    font-size: 10px;
    font-weight: 600;
    margin-bottom: 0;
    letter-spacing: 1px;
    color: #8392a5
}

.card-events .media-left p {
    margin-bottom: 0;
    font-family: "Rubik", sans-serif;
    font-size: 24px;
    line-height: 1;
    letter-spacing: -1px
}

.card-events .media-body {
    margin-left: 15px;
    padding: 13px 15px;
    border-left: 3px solid transparent
}

.card-events .media-body.event-panel-green {
    background-color: rgba(16, 183, 89, 0.1);
    border-left-color: #10b759;
    color: #08592b
}

.card-events .media-body.event-panel-pink {
    background-color: rgba(241, 0, 117, 0.1);
    border-left-color: #f10075;
    color: #8b0043
}

.card-events .media-body.event-panel-primary {
    background-color: rgba(1, 104, 250, 0.1);
    border-left-color: #0168fa;
    color: #013e94
}

.card-events .media-body.event-panel-orange {
    background-color: rgba(253, 126, 20, 0.1);
    border-left-color: #fd7e14;
    color: #aa4e01
}

.card-events .event-time {
    display: block;
    font-size: 11px;
    margin-bottom: 5px;
    opacity: .6
}

.card-events .event-title {
    margin-bottom: 0;
    font-size: 13px
}

.card-events .event-desc {
    margin-top: 2px;
    margin-bottom: 0;
    font-size: 11px;
    opacity: .7
}

.card-todo .list-group-item {
    padding: 10px 15px 15px
}

.card-todo .list-group-header {
    display: flex;
    align-items: center
}

.card-todo .list-group-header span {
    width: 20px;
    height: 3px
}

.card-todo .list-group-header span+span {
    margin-left: 3px
}

.card-todo .list-group-header svg {
    width: 16px;
    height: 16px
}

.card-todo .todo-title {
    margin-bottom: 3px;
    font-size: 13px
}

.card-todo .todo-date {
    font-size: 12px;
    margin-bottom: 0;
    color: #8392a5
}

.card-todo .card-progress {
    padding-top: 15px;
    padding-bottom: 15px;
    display: flex;
    align-items: center
}

.card-todo .content-label {
    text-transform: uppercase;
    font-size: 10px;
    color: #8392a5
}

.card-todo .progress {
    flex: 1;
    height: 5px;
    margin: 0 10px
}

.card-todo .badge {
    padding-top: 4px;
    text-transform: uppercase;
    font-size: 8px;
    font-weight: 600;
    letter-spacing: 1px;
    border-radius: 1.5px
}

.card-todo .card-footer a {
    text-transform: uppercase;
    font-size: 12px;
    font-weight: 600;
    letter-spacing: .5px;
    border: 1px dashed #b4bdce;
    color: #1b2e4b
}

.card-todo .card-footer a:hover,
.card-todo .card-footer a:focus {
    border-color: #0168fa;
    color: #0168fa;
    background-color: rgba(1, 104, 250, 0.1)
}

.bg-brand-01 {
    background-color: #0168fa
}

.bg-brand-02 {
    background-color: #042893
}

.bg-brand-03 {
    background-color: #1ce1ac
}

.bg-ui-01 {
    background-color: #f5f6fa
}

.bg-ui-02 {
    background-color: #e5e9f2
}

.bg-ui-03 {
    background-color: #c0ccda
}

.bg-ui-04 {
    background-color: #8392a5
}

.bg-gray-50 {
    background-color: #fafbfc
}

.bg-gray-100 {
    background-color: #f4f5f8
}

.bg-gray-200 {
    background-color: #e3e7ed
}

.bg-gray-300 {
    background-color: #cdd4e0
}

.bg-gray-400 {
    background-color: #b4bdce
}

.bg-gray-500 {
    background-color: #97a3b9
}

.bg-gray-600 {
    background-color: #7987a1
}

.bg-gray-700 {
    background-color: #596882
}

.bg-gray-800 {
    background-color: #3b4863
}

.bg-gray-900 {
    background-color: #1c273c
}

.bg-white-1 {
    background-color: rgba(255, 255, 255, 0.1)
}

.bg-white-2 {
    background-color: rgba(255, 255, 255, 0.2)
}

.bg-white-3 {
    background-color: rgba(255, 255, 255, 0.3)
}

.bg-white-4 {
    background-color: rgba(255, 255, 255, 0.4)
}

.bg-white-5 {
    background-color: rgba(255, 255, 255, 0.5)
}

.bg-white-6 {
    background-color: rgba(255, 255, 255, 0.6)
}

.bg-white-7 {
    background-color: rgba(255, 255, 255, 0.7)
}

.bg-white-8 {
    background-color: rgba(255, 255, 255, 0.8)
}

.bg-white-9 {
    background-color: rgba(255, 255, 255, 0.9)
}

.bg-black-1 {
    background-color: rgba(0, 0, 0, 0.1)
}

.bg-black-2 {
    background-color: rgba(0, 0, 0, 0.2)
}

.bg-black-3 {
    background-color: rgba(0, 0, 0, 0.3)
}

.bg-black-4 {
    background-color: rgba(0, 0, 0, 0.4)
}

.bg-black-5 {
    background-color: rgba(0, 0, 0, 0.5)
}

.bg-black-6 {
    background-color: rgba(0, 0, 0, 0.6)
}

.bg-black-7 {
    background-color: rgba(0, 0, 0, 0.7)
}

.bg-black-8 {
    background-color: rgba(0, 0, 0, 0.8)
}

.bg-black-9 {
    background-color: rgba(0, 0, 0, 0.9)
}

.bg-gray-1 {
    background-color: rgba(28, 39, 60, 0.1)
}

.bg-gray-2 {
    background-color: rgba(28, 39, 60, 0.2)
}

.bg-gray-3 {
    background-color: rgba(28, 39, 60, 0.3)
}

.bg-gray-4 {
    background-color: rgba(28, 39, 60, 0.4)
}

.bg-gray-5 {
    background-color: rgba(28, 39, 60, 0.5)
}

.bg-gray-6 {
    background-color: rgba(28, 39, 60, 0.6)
}

.bg-gray-7 {
    background-color: rgba(28, 39, 60, 0.7)
}

.bg-gray-8 {
    background-color: rgba(28, 39, 60, 0.8)
}

.bg-gray-9 {
    background-color: rgba(28, 39, 60, 0.9)
}

.bg-primary-light {
    background-color: rgba(1, 104, 250, 0.15)
}

.bg-success-light {
    background-color: rgba(16, 183, 89, 0.18)
}

.bg-warning-light {
    background-color: rgba(253, 126, 20, 0.18)
}

.bg-pink-light {
    background-color: rgba(241, 0, 117, 0.18)
}

.bg-indigo-light {
    background-color: rgba(91, 71, 251, 0.18)
}

.bg-indigo {
    background-color: #5b47fb
}

.bg-purple {
    background-color: #6f42c1
}

.bg-pink {
    background-color: #f10075
}

.bg-orange {
    background-color: #fd7e14
}

.bg-teal {
    background-color: #0cc
}

.bg-lightblue {
    background-color: #a5d7fd
}

.bg-transparent {
    background-color: transparent
}

.bg-facebook {
    background-color: #4064ac
}

.bg-twitter {
    background-color: #00a7e6
}

a.bg-facebook:hover,
a.bg-facebook:focus,
button.bg-facebook:hover,
button.bg-facebook:focus,
.btn.bg-facebook:hover,
.ui-datepicker-buttonpane button.bg-facebook:hover,
.sp-container button.bg-facebook:hover,
.btn.bg-facebook:focus,
.ui-datepicker-buttonpane button.bg-facebook:focus,
.sp-container button.bg-facebook:focus {
    background-color: #395999
}

a.bg-twitter:hover,
a.bg-twitter:focus,
button.bg-twitter:hover,
button.bg-twitter:focus,
.btn.bg-twitter:hover,
.ui-datepicker-buttonpane button.bg-twitter:hover,
.sp-container button.bg-twitter:hover,
.btn.bg-twitter:focus,
.ui-datepicker-buttonpane button.bg-twitter:focus,
.sp-container button.bg-twitter:focus {
    background-color: #0094cd
}

.bd-transparent {
    border-color: transparent
}

.bd-transparent-f {
    border-color: transparent !important
}

.bd {
    border: 1px solid rgba(72, 94, 144, 0.16)
}

.bd-t {
    border-top: 1px solid rgba(72, 94, 144, 0.16)
}

.bd-r {
    border-right: 1px solid rgba(72, 94, 144, 0.16)
}

.bd-b {
    border-bottom: 1px solid rgba(72, 94, 144, 0.16)
}

.bd-l {
    border-left: 1px solid rgba(72, 94, 144, 0.16)
}

.bd-y {
    border-top: 1px solid rgba(72, 94, 144, 0.16);
    border-bottom: 1px solid rgba(72, 94, 144, 0.16)
}

.bd-x {
    border-left: 1px solid rgba(72, 94, 144, 0.16);
    border-right: 1px solid rgba(72, 94, 144, 0.16)
}

.bd-0 {
    border-width: 0
}

.bd-1 {
    border-width: 1px
}

.bd-2 {
    border-width: 2px
}

.bd-3 {
    border-width: 3px
}

.bd-4 {
    border-width: 4px
}

.bd-5 {
    border-width: 5px
}

.bd-t-0 {
    border-top: 0
}

.bd-r-0 {
    border-right: 0
}

.bd-b-0 {
    border-bottom: 0
}

.bd-l-0 {
    border-left: 0
}

.bd-0-f {
    border: 0 !important
}

.bd-t-0-f {
    border-top: 0 !important
}

.bd-r-0-f {
    border-right: 0 !important
}

.bd-b-0-f {
    border-bottom: 0 !important
}

.bd-l-0-f {
    border-left: 0 !important
}

.bd-y-0 {
    border-top-width: 0;
    border-bottom-width: 0
}

.bd-y-1 {
    border-top-width: 1px;
    border-bottom-width: 1px
}

.bd-x-0 {
    border-left-width: 0;
    border-right-width: 0
}

.bd-x-1 {
    border-left-width: 1px;
    border-right-width: 1px
}

@media (min-width: 576px) {
    .bd-sm-t-0 {
        border-top-width: 0
    }
}

@media (min-width: 992px) {
    .bd-lg-t-0 {
        border-top-width: 0
    }
}

.bd-ui-01 {
    border-color: #f5f6fa
}

.bd-ui-02 {
    border-color: #e5e9f2
}

.bd-ui-03 {
    border-color: #c0ccda
}

.bd-ui-04 {
    border-color: #8392a5
}

.bd-primary {
    border-color: #0168fa
}

.bd-success {
    border-color: #10b759
}

.bd-warning {
    border-color: #ffc107
}

.bd-danger {
    border-color: #dc3545
}

.bd-info {
    border-color: #00b8d4
}

.bd-dark {
    border-color: #1c273c
}

.bd-white {
    border-color: #fff
}

.bd-pink {
    border-color: #f10075
}

.bd-indigo {
    border-color: #5b47fb
}

.bd-purple {
    border-color: #6f42c1
}

.bd-teal {
    border-color: #0cc
}

.bd-primary-light {
    border-color: rgba(1, 104, 250, 0.2)
}

.bd-success-light {
    border-color: rgba(16, 183, 89, 0.3)
}

.bd-gray-100 {
    border-color: #f4f5f8
}

.bd-gray-200 {
    border-color: #e3e7ed
}

.bd-gray-300 {
    border-color: #cdd4e0
}

.bd-gray-400 {
    border-color: #b4bdce
}

.bd-gray-500 {
    border-color: #97a3b9
}

.bd-gray-600 {
    border-color: #7987a1
}

.bd-gray-700 {
    border-color: #596882
}

.bd-gray-800 {
    border-color: #3b4863
}

.bd-gray-900 {
    border-color: #1c273c
}

.bd-white-1 {
    border-color: rgba(255, 255, 255, 0.1)
}

.bd-white-2 {
    border-color: rgba(255, 255, 255, 0.2)
}

.bd-white-3 {
    border-color: rgba(255, 255, 255, 0.3)
}

.bd-white-4 {
    border-color: rgba(255, 255, 255, 0.4)
}

.bd-white-5 {
    border-color: rgba(255, 255, 255, 0.5)
}

.bd-white-6 {
    border-color: rgba(255, 255, 255, 0.6)
}

.bd-white-7 {
    border-color: rgba(255, 255, 255, 0.7)
}

.bd-white-8 {
    border-color: rgba(255, 255, 255, 0.8)
}

.rounded-5 {
    border-radius: 5px
}

.rounded-10 {
    border-radius: 10px
}

.rounded-20 {
    border-radius: 20px
}

.rounded-30 {
    border-radius: 30px
}

.rounded-40 {
    border-radius: 40px
}

.rounded-50 {
    border-radius: 50px
}

.bd-dashed {
    border-style: dashed
}

.bd-dotted {
    border-style: dotted
}

@media (min-width: 480px) {
    .bd-xs {
        border: 1px solid #cdd4e0
    }

    .bd-xs-t {
        border-top: 1px solid #cdd4e0
    }

    .bd-xs-r {
        border-right: 1px solid #cdd4e0
    }

    .bd-xs-b {
        border-bottom: 1px solid #cdd4e0
    }

    .bd-xs-l {
        border-left: 1px solid #cdd4e0
    }

    .bd-xs-y {
        border-top: 1px solid #cdd4e0;
        border-bottom: 1px solid #cdd4e0
    }

    .bd-xs-x {
        border-left: 1px solid #cdd4e0;
        border-right: 1px solid #cdd4e0
    }
}

@media (min-width: 576px) {
    .bd-sm {
        border: 1px solid #cdd4e0
    }

    .bd-sm-t {
        border-top: 1px solid #cdd4e0
    }

    .bd-sm-r {
        border-right: 1px solid #cdd4e0
    }

    .bd-sm-b {
        border-bottom: 1px solid #cdd4e0
    }

    .bd-sm-l {
        border-left: 1px solid #cdd4e0
    }

    .bd-sm-y {
        border-top: 1px solid #cdd4e0;
        border-bottom: 1px solid #cdd4e0
    }

    .bd-sm-x {
        border-left: 1px solid #cdd4e0;
        border-right: 1px solid #cdd4e0
    }
}

@media (min-width: 768px) {
    .bd-md {
        border: 1px solid #cdd4e0
    }

    .bd-md-t {
        border-top: 1px solid #cdd4e0
    }

    .bd-md-r {
        border-right: 1px solid #cdd4e0
    }

    .bd-md-b {
        border-bottom: 1px solid #cdd4e0
    }

    .bd-md-l {
        border-left: 1px solid #cdd4e0
    }

    .bd-md-y {
        border-top: 1px solid #cdd4e0;
        border-bottom: 1px solid #cdd4e0
    }

    .bd-md-x {
        border-left: 1px solid #cdd4e0;
        border-right: 1px solid #cdd4e0
    }
}

@media (min-width: 992px) {
    .bd-lg {
        border: 1px solid #cdd4e0
    }

    .bd-lg-t {
        border-top: 1px solid #cdd4e0
    }

    .bd-lg-r {
        border-right: 1px solid #cdd4e0
    }

    .bd-lg-b {
        border-bottom: 1px solid #cdd4e0
    }

    .bd-lg-l {
        border-left: 1px solid #cdd4e0
    }

    .bd-lg-y {
        border-top: 1px solid #cdd4e0;
        border-bottom: 1px solid #cdd4e0
    }

    .bd-lg-x {
        border-left: 1px solid #cdd4e0;
        border-right: 1px solid #cdd4e0
    }
}

@media (min-width: 1200px) {
    .bd-xl {
        border: 1px solid #cdd4e0
    }

    .bd-xl-t {
        border-top: 1px solid #cdd4e0
    }

    .bd-xl-r {
        border-right: 1px solid #cdd4e0
    }

    .bd-xl-b {
        border-bottom: 1px solid #cdd4e0
    }

    .bd-xl-l {
        border-left: 1px solid #cdd4e0
    }

    .bd-xl-y {
        border-top: 1px solid #cdd4e0;
        border-bottom: 1px solid #cdd4e0
    }

    .bd-xl-x {
        border-left: 1px solid #cdd4e0;
        border-right: 1px solid #cdd4e0
    }
}

.ht-5 {
    height: 5px
}

.ht-5p {
    height: 5%
}

.mx-ht-5p {
    max-height: 5%
}

.mn-ht-5p {
    min-height: 5%
}

.ht-5-f {
    height: 5px !important
}

.ht-5p-f {
    height: 5% !important
}

.ht-10 {
    height: 10px
}

.ht-10p {
    height: 10%
}

.mx-ht-10p {
    max-height: 10%
}

.mn-ht-10p {
    min-height: 10%
}

.ht-10-f {
    height: 10px !important
}

.ht-10p-f {
    height: 10% !important
}

.ht-15 {
    height: 15px
}

.ht-15p {
    height: 15%
}

.mx-ht-15p {
    max-height: 15%
}

.mn-ht-15p {
    min-height: 15%
}

.ht-15-f {
    height: 15px !important
}

.ht-15p-f {
    height: 15% !important
}

.ht-20 {
    height: 20px
}

.ht-20p {
    height: 20%
}

.mx-ht-20p {
    max-height: 20%
}

.mn-ht-20p {
    min-height: 20%
}

.ht-20-f {
    height: 20px !important
}

.ht-20p-f {
    height: 20% !important
}

.ht-25 {
    height: 25px
}

.ht-25p {
    height: 25%
}

.mx-ht-25p {
    max-height: 25%
}

.mn-ht-25p {
    min-height: 25%
}

.ht-25-f {
    height: 25px !important
}

.ht-25p-f {
    height: 25% !important
}

.ht-30 {
    height: 30px
}

.ht-30p {
    height: 30%
}

.mx-ht-30p {
    max-height: 30%
}

.mn-ht-30p {
    min-height: 30%
}

.ht-30-f {
    height: 30px !important
}

.ht-30p-f {
    height: 30% !important
}

.ht-35 {
    height: 35px
}

.ht-35p {
    height: 35%
}

.mx-ht-35p {
    max-height: 35%
}

.mn-ht-35p {
    min-height: 35%
}

.ht-35-f {
    height: 35px !important
}

.ht-35p-f {
    height: 35% !important
}

.ht-40 {
    height: 40px
}

.ht-40p {
    height: 40%
}

.mx-ht-40p {
    max-height: 40%
}

.mn-ht-40p {
    min-height: 40%
}

.ht-40-f {
    height: 40px !important
}

.ht-40p-f {
    height: 40% !important
}

.ht-45 {
    height: 45px
}

.ht-45p {
    height: 45%
}

.mx-ht-45p {
    max-height: 45%
}

.mn-ht-45p {
    min-height: 45%
}

.ht-45-f {
    height: 45px !important
}

.ht-45p-f {
    height: 45% !important
}

.ht-50 {
    height: 50px
}

.ht-50p {
    height: 50%
}

.mx-ht-50p {
    max-height: 50%
}

.mn-ht-50p {
    min-height: 50%
}

.ht-50-f {
    height: 50px !important
}

.ht-50p-f {
    height: 50% !important
}

.ht-55 {
    height: 55px
}

.ht-55p {
    height: 55%
}

.mx-ht-55p {
    max-height: 55%
}

.mn-ht-55p {
    min-height: 55%
}

.ht-55-f {
    height: 55px !important
}

.ht-55p-f {
    height: 55% !important
}

.ht-60 {
    height: 60px
}

.ht-60p {
    height: 60%
}

.mx-ht-60p {
    max-height: 60%
}

.mn-ht-60p {
    min-height: 60%
}

.ht-60-f {
    height: 60px !important
}

.ht-60p-f {
    height: 60% !important
}

.ht-65 {
    height: 65px
}

.ht-65p {
    height: 65%
}

.mx-ht-65p {
    max-height: 65%
}

.mn-ht-65p {
    min-height: 65%
}

.ht-65-f {
    height: 65px !important
}

.ht-65p-f {
    height: 65% !important
}

.ht-70 {
    height: 70px
}

.ht-70p {
    height: 70%
}

.mx-ht-70p {
    max-height: 70%
}

.mn-ht-70p {
    min-height: 70%
}

.ht-70-f {
    height: 70px !important
}

.ht-70p-f {
    height: 70% !important
}

.ht-75 {
    height: 75px
}

.ht-75p {
    height: 75%
}

.mx-ht-75p {
    max-height: 75%
}

.mn-ht-75p {
    min-height: 75%
}

.ht-75-f {
    height: 75px !important
}

.ht-75p-f {
    height: 75% !important
}

.ht-80 {
    height: 80px
}

.ht-80p {
    height: 80%
}

.mx-ht-80p {
    max-height: 80%
}

.mn-ht-80p {
    min-height: 80%
}

.ht-80-f {
    height: 80px !important
}

.ht-80p-f {
    height: 80% !important
}

.ht-85 {
    height: 85px
}

.ht-85p {
    height: 85%
}

.mx-ht-85p {
    max-height: 85%
}

.mn-ht-85p {
    min-height: 85%
}

.ht-85-f {
    height: 85px !important
}

.ht-85p-f {
    height: 85% !important
}

.ht-90 {
    height: 90px
}

.ht-90p {
    height: 90%
}

.mx-ht-90p {
    max-height: 90%
}

.mn-ht-90p {
    min-height: 90%
}

.ht-90-f {
    height: 90px !important
}

.ht-90p-f {
    height: 90% !important
}

.ht-95 {
    height: 95px
}

.ht-95p {
    height: 95%
}

.mx-ht-95p {
    max-height: 95%
}

.mn-ht-95p {
    min-height: 95%
}

.ht-95-f {
    height: 95px !important
}

.ht-95p-f {
    height: 95% !important
}

.ht-100 {
    height: 100px
}

.ht-100p {
    height: 100%
}

.mx-ht-100p {
    max-height: 100%
}

.mn-ht-100p {
    min-height: 100%
}

.ht-100-f {
    height: 100px !important
}

.ht-100p-f {
    height: 100% !important
}

.ht-150 {
    height: 150px
}

.mn-ht-150 {
    min-height: 150px
}

.ht-200 {
    height: 200px
}

.mn-ht-200 {
    min-height: 200px
}

.ht-250 {
    height: 250px
}

.mn-ht-250 {
    min-height: 250px
}

.ht-300 {
    height: 300px
}

.mn-ht-300 {
    min-height: 300px
}

.ht-350 {
    height: 350px
}

.mn-ht-350 {
    min-height: 350px
}

.ht-400 {
    height: 400px
}

.mn-ht-400 {
    min-height: 400px
}

.ht-450 {
    height: 450px
}

.mn-ht-450 {
    min-height: 450px
}

.ht-500 {
    height: 500px
}

.mn-ht-500 {
    min-height: 500px
}

.ht-550 {
    height: 550px
}

.mn-ht-550 {
    min-height: 550px
}

.ht-600 {
    height: 600px
}

.mn-ht-600 {
    min-height: 600px
}

.ht-650 {
    height: 650px
}

.mn-ht-650 {
    min-height: 650px
}

.ht-700 {
    height: 700px
}

.mn-ht-700 {
    min-height: 700px
}

.ht-750 {
    height: 750px
}

.mn-ht-750 {
    min-height: 750px
}

.ht-800 {
    height: 800px
}

.mn-ht-800 {
    min-height: 800px
}

.ht-100v {
    height: 100vh
}

.mn-ht-0 {
    min-height: inherit
}

.mn-ht-100v {
    min-height: 100vh
}

.ht-1 {
    height: 1px
}

.ht-2 {
    height: 2px
}

.ht-3 {
    height: 3px
}

.ht-4 {
    height: 4px
}

.ht-6 {
    height: 6px
}

.ht-7 {
    height: 7px
}

.ht-8 {
    height: 8px
}

.ht-9 {
    height: 9px
}

.ht-auto {
    height: auto
}

.ht-base {
    height: 38px
}

.ht-16 {
    height: 16px
}

.ht-32 {
    height: 32px
}

.ht-36 {
    height: 36px
}

.ht-48 {
    height: 48px
}

.ht-64 {
    height: 64px
}

.ht-72 {
    height: 72px
}

.ht-115 {
    height: 115px
}

.ht-120 {
    height: 120px
}

.ht-160 {
    height: 160px
}

@media (min-width: 480px) {
    .ht-xs-5 {
        height: 5px
    }

    .ht-xs-5p {
        height: 5%
    }

    .mx-ht-xs-5p {
        max-height: 5%
    }

    .mn-ht-xs-5p {
        min-height: 5%
    }

    .ht-xs-5-f {
        height: 5px !important
    }

    .ht-xs-5p-f {
        height: 5% !important
    }

    .ht-xs-10 {
        height: 10px
    }

    .ht-xs-10p {
        height: 10%
    }

    .mx-ht-xs-10p {
        max-height: 10%
    }

    .mn-ht-xs-10p {
        min-height: 10%
    }

    .ht-xs-10-f {
        height: 10px !important
    }

    .ht-xs-10p-f {
        height: 10% !important
    }

    .ht-xs-15 {
        height: 15px
    }

    .ht-xs-15p {
        height: 15%
    }

    .mx-ht-xs-15p {
        max-height: 15%
    }

    .mn-ht-xs-15p {
        min-height: 15%
    }

    .ht-xs-15-f {
        height: 15px !important
    }

    .ht-xs-15p-f {
        height: 15% !important
    }

    .ht-xs-20 {
        height: 20px
    }

    .ht-xs-20p {
        height: 20%
    }

    .mx-ht-xs-20p {
        max-height: 20%
    }

    .mn-ht-xs-20p {
        min-height: 20%
    }

    .ht-xs-20-f {
        height: 20px !important
    }

    .ht-xs-20p-f {
        height: 20% !important
    }

    .ht-xs-25 {
        height: 25px
    }

    .ht-xs-25p {
        height: 25%
    }

    .mx-ht-xs-25p {
        max-height: 25%
    }

    .mn-ht-xs-25p {
        min-height: 25%
    }

    .ht-xs-25-f {
        height: 25px !important
    }

    .ht-xs-25p-f {
        height: 25% !important
    }

    .ht-xs-30 {
        height: 30px
    }

    .ht-xs-30p {
        height: 30%
    }

    .mx-ht-xs-30p {
        max-height: 30%
    }

    .mn-ht-xs-30p {
        min-height: 30%
    }

    .ht-xs-30-f {
        height: 30px !important
    }

    .ht-xs-30p-f {
        height: 30% !important
    }

    .ht-xs-35 {
        height: 35px
    }

    .ht-xs-35p {
        height: 35%
    }

    .mx-ht-xs-35p {
        max-height: 35%
    }

    .mn-ht-xs-35p {
        min-height: 35%
    }

    .ht-xs-35-f {
        height: 35px !important
    }

    .ht-xs-35p-f {
        height: 35% !important
    }

    .ht-xs-40 {
        height: 40px
    }

    .ht-xs-40p {
        height: 40%
    }

    .mx-ht-xs-40p {
        max-height: 40%
    }

    .mn-ht-xs-40p {
        min-height: 40%
    }

    .ht-xs-40-f {
        height: 40px !important
    }

    .ht-xs-40p-f {
        height: 40% !important
    }

    .ht-xs-45 {
        height: 45px
    }

    .ht-xs-45p {
        height: 45%
    }

    .mx-ht-xs-45p {
        max-height: 45%
    }

    .mn-ht-xs-45p {
        min-height: 45%
    }

    .ht-xs-45-f {
        height: 45px !important
    }

    .ht-xs-45p-f {
        height: 45% !important
    }

    .ht-xs-50 {
        height: 50px
    }

    .ht-xs-50p {
        height: 50%
    }

    .mx-ht-xs-50p {
        max-height: 50%
    }

    .mn-ht-xs-50p {
        min-height: 50%
    }

    .ht-xs-50-f {
        height: 50px !important
    }

    .ht-xs-50p-f {
        height: 50% !important
    }

    .ht-xs-55 {
        height: 55px
    }

    .ht-xs-55p {
        height: 55%
    }

    .mx-ht-xs-55p {
        max-height: 55%
    }

    .mn-ht-xs-55p {
        min-height: 55%
    }

    .ht-xs-55-f {
        height: 55px !important
    }

    .ht-xs-55p-f {
        height: 55% !important
    }

    .ht-xs-60 {
        height: 60px
    }

    .ht-xs-60p {
        height: 60%
    }

    .mx-ht-xs-60p {
        max-height: 60%
    }

    .mn-ht-xs-60p {
        min-height: 60%
    }

    .ht-xs-60-f {
        height: 60px !important
    }

    .ht-xs-60p-f {
        height: 60% !important
    }

    .ht-xs-65 {
        height: 65px
    }

    .ht-xs-65p {
        height: 65%
    }

    .mx-ht-xs-65p {
        max-height: 65%
    }

    .mn-ht-xs-65p {
        min-height: 65%
    }

    .ht-xs-65-f {
        height: 65px !important
    }

    .ht-xs-65p-f {
        height: 65% !important
    }

    .ht-xs-70 {
        height: 70px
    }

    .ht-xs-70p {
        height: 70%
    }

    .mx-ht-xs-70p {
        max-height: 70%
    }

    .mn-ht-xs-70p {
        min-height: 70%
    }

    .ht-xs-70-f {
        height: 70px !important
    }

    .ht-xs-70p-f {
        height: 70% !important
    }

    .ht-xs-75 {
        height: 75px
    }

    .ht-xs-75p {
        height: 75%
    }

    .mx-ht-xs-75p {
        max-height: 75%
    }

    .mn-ht-xs-75p {
        min-height: 75%
    }

    .ht-xs-75-f {
        height: 75px !important
    }

    .ht-xs-75p-f {
        height: 75% !important
    }

    .ht-xs-80 {
        height: 80px
    }

    .ht-xs-80p {
        height: 80%
    }

    .mx-ht-xs-80p {
        max-height: 80%
    }

    .mn-ht-xs-80p {
        min-height: 80%
    }

    .ht-xs-80-f {
        height: 80px !important
    }

    .ht-xs-80p-f {
        height: 80% !important
    }

    .ht-xs-85 {
        height: 85px
    }

    .ht-xs-85p {
        height: 85%
    }

    .mx-ht-xs-85p {
        max-height: 85%
    }

    .mn-ht-xs-85p {
        min-height: 85%
    }

    .ht-xs-85-f {
        height: 85px !important
    }

    .ht-xs-85p-f {
        height: 85% !important
    }

    .ht-xs-90 {
        height: 90px
    }

    .ht-xs-90p {
        height: 90%
    }

    .mx-ht-xs-90p {
        max-height: 90%
    }

    .mn-ht-xs-90p {
        min-height: 90%
    }

    .ht-xs-90-f {
        height: 90px !important
    }

    .ht-xs-90p-f {
        height: 90% !important
    }

    .ht-xs-95 {
        height: 95px
    }

    .ht-xs-95p {
        height: 95%
    }

    .mx-ht-xs-95p {
        max-height: 95%
    }

    .mn-ht-xs-95p {
        min-height: 95%
    }

    .ht-xs-95-f {
        height: 95px !important
    }

    .ht-xs-95p-f {
        height: 95% !important
    }

    .ht-xs-100 {
        height: 100px
    }

    .ht-xs-100p {
        height: 100%
    }

    .mx-ht-xs-100p {
        max-height: 100%
    }

    .mn-ht-xs-100p {
        min-height: 100%
    }

    .ht-xs-100-f {
        height: 100px !important
    }

    .ht-xs-100p-f {
        height: 100% !important
    }

    .ht-xs-150 {
        height: 150px
    }

    .ht-xs-150p {
        height: 150%
    }

    .mx-ht-xs-150p {
        max-height: 150%
    }

    .mn-ht-xs-150p {
        min-height: 150%
    }

    .ht-xs-150-f {
        height: 150px !important
    }

    .ht-xs-150p-f {
        height: 150% !important
    }

    .ht-xs-200 {
        height: 200px
    }

    .ht-xs-200p {
        height: 200%
    }

    .mx-ht-xs-200p {
        max-height: 200%
    }

    .mn-ht-xs-200p {
        min-height: 200%
    }

    .ht-xs-200-f {
        height: 200px !important
    }

    .ht-xs-200p-f {
        height: 200% !important
    }

    .ht-xs-250 {
        height: 250px
    }

    .ht-xs-250p {
        height: 250%
    }

    .mx-ht-xs-250p {
        max-height: 250%
    }

    .mn-ht-xs-250p {
        min-height: 250%
    }

    .ht-xs-250-f {
        height: 250px !important
    }

    .ht-xs-250p-f {
        height: 250% !important
    }

    .ht-xs-300 {
        height: 300px
    }

    .ht-xs-300p {
        height: 300%
    }

    .mx-ht-xs-300p {
        max-height: 300%
    }

    .mn-ht-xs-300p {
        min-height: 300%
    }

    .ht-xs-300-f {
        height: 300px !important
    }

    .ht-xs-300p-f {
        height: 300% !important
    }

    .ht-xs-350 {
        height: 350px
    }

    .ht-xs-350p {
        height: 350%
    }

    .mx-ht-xs-350p {
        max-height: 350%
    }

    .mn-ht-xs-350p {
        min-height: 350%
    }

    .ht-xs-350-f {
        height: 350px !important
    }

    .ht-xs-350p-f {
        height: 350% !important
    }

    .ht-xs-400 {
        height: 400px
    }

    .ht-xs-400p {
        height: 400%
    }

    .mx-ht-xs-400p {
        max-height: 400%
    }

    .mn-ht-xs-400p {
        min-height: 400%
    }

    .ht-xs-400-f {
        height: 400px !important
    }

    .ht-xs-400p-f {
        height: 400% !important
    }

    .ht-xs-450 {
        height: 450px
    }

    .ht-xs-450p {
        height: 450%
    }

    .mx-ht-xs-450p {
        max-height: 450%
    }

    .mn-ht-xs-450p {
        min-height: 450%
    }

    .ht-xs-450-f {
        height: 450px !important
    }

    .ht-xs-450p-f {
        height: 450% !important
    }

    .ht-xs-500 {
        height: 500px
    }

    .ht-xs-500p {
        height: 500%
    }

    .mx-ht-xs-500p {
        max-height: 500%
    }

    .mn-ht-xs-500p {
        min-height: 500%
    }

    .ht-xs-500-f {
        height: 500px !important
    }

    .ht-xs-500p-f {
        height: 500% !important
    }

    .ht-xs-550 {
        height: 550px
    }

    .ht-xs-550p {
        height: 550%
    }

    .mx-ht-xs-550p {
        max-height: 550%
    }

    .mn-ht-xs-550p {
        min-height: 550%
    }

    .ht-xs-550-f {
        height: 550px !important
    }

    .ht-xs-550p-f {
        height: 550% !important
    }

    .ht-xs-600 {
        height: 600px
    }

    .ht-xs-600p {
        height: 600%
    }

    .mx-ht-xs-600p {
        max-height: 600%
    }

    .mn-ht-xs-600p {
        min-height: 600%
    }

    .ht-xs-600-f {
        height: 600px !important
    }

    .ht-xs-600p-f {
        height: 600% !important
    }

    .ht-xs-650 {
        height: 650px
    }

    .ht-xs-650p {
        height: 650%
    }

    .mx-ht-xs-650p {
        max-height: 650%
    }

    .mn-ht-xs-650p {
        min-height: 650%
    }

    .ht-xs-650-f {
        height: 650px !important
    }

    .ht-xs-650p-f {
        height: 650% !important
    }

    .ht-xs-700 {
        height: 700px
    }

    .ht-xs-700p {
        height: 700%
    }

    .mx-ht-xs-700p {
        max-height: 700%
    }

    .mn-ht-xs-700p {
        min-height: 700%
    }

    .ht-xs-700-f {
        height: 700px !important
    }

    .ht-xs-700p-f {
        height: 700% !important
    }

    .ht-xs-750 {
        height: 750px
    }

    .ht-xs-750p {
        height: 750%
    }

    .mx-ht-xs-750p {
        max-height: 750%
    }

    .mn-ht-xs-750p {
        min-height: 750%
    }

    .ht-xs-750-f {
        height: 750px !important
    }

    .ht-xs-750p-f {
        height: 750% !important
    }

    .ht-xs-800 {
        height: 800px
    }

    .ht-xs-800p {
        height: 800%
    }

    .mx-ht-xs-800p {
        max-height: 800%
    }

    .mn-ht-xs-800p {
        min-height: 800%
    }

    .ht-xs-800-f {
        height: 800px !important
    }

    .ht-xs-800p-f {
        height: 800% !important
    }

    .ht-xs-850 {
        height: 850px
    }

    .ht-xs-850p {
        height: 850%
    }

    .mx-ht-xs-850p {
        max-height: 850%
    }

    .mn-ht-xs-850p {
        min-height: 850%
    }

    .ht-xs-850-f {
        height: 850px !important
    }

    .ht-xs-850p-f {
        height: 850% !important
    }

    .ht-xs-100v {
        height: 100vh
    }
}

@media (min-width: 576px) {
    .ht-sm-5 {
        height: 5px
    }

    .ht-sm-5p {
        height: 5%
    }

    .mx-ht-sm-5p {
        max-height: 5%
    }

    .mn-ht-sm-5p {
        min-height: 5%
    }

    .ht-sm-5-f {
        height: 5px !important
    }

    .ht-sm-5p-f {
        height: 5% !important
    }

    .ht-sm-10 {
        height: 10px
    }

    .ht-sm-10p {
        height: 10%
    }

    .mx-ht-sm-10p {
        max-height: 10%
    }

    .mn-ht-sm-10p {
        min-height: 10%
    }

    .ht-sm-10-f {
        height: 10px !important
    }

    .ht-sm-10p-f {
        height: 10% !important
    }

    .ht-sm-15 {
        height: 15px
    }

    .ht-sm-15p {
        height: 15%
    }

    .mx-ht-sm-15p {
        max-height: 15%
    }

    .mn-ht-sm-15p {
        min-height: 15%
    }

    .ht-sm-15-f {
        height: 15px !important
    }

    .ht-sm-15p-f {
        height: 15% !important
    }

    .ht-sm-20 {
        height: 20px
    }

    .ht-sm-20p {
        height: 20%
    }

    .mx-ht-sm-20p {
        max-height: 20%
    }

    .mn-ht-sm-20p {
        min-height: 20%
    }

    .ht-sm-20-f {
        height: 20px !important
    }

    .ht-sm-20p-f {
        height: 20% !important
    }

    .ht-sm-25 {
        height: 25px
    }

    .ht-sm-25p {
        height: 25%
    }

    .mx-ht-sm-25p {
        max-height: 25%
    }

    .mn-ht-sm-25p {
        min-height: 25%
    }

    .ht-sm-25-f {
        height: 25px !important
    }

    .ht-sm-25p-f {
        height: 25% !important
    }

    .ht-sm-30 {
        height: 30px
    }

    .ht-sm-30p {
        height: 30%
    }

    .mx-ht-sm-30p {
        max-height: 30%
    }

    .mn-ht-sm-30p {
        min-height: 30%
    }

    .ht-sm-30-f {
        height: 30px !important
    }

    .ht-sm-30p-f {
        height: 30% !important
    }

    .ht-sm-35 {
        height: 35px
    }

    .ht-sm-35p {
        height: 35%
    }

    .mx-ht-sm-35p {
        max-height: 35%
    }

    .mn-ht-sm-35p {
        min-height: 35%
    }

    .ht-sm-35-f {
        height: 35px !important
    }

    .ht-sm-35p-f {
        height: 35% !important
    }

    .ht-sm-40 {
        height: 40px
    }

    .ht-sm-40p {
        height: 40%
    }

    .mx-ht-sm-40p {
        max-height: 40%
    }

    .mn-ht-sm-40p {
        min-height: 40%
    }

    .ht-sm-40-f {
        height: 40px !important
    }

    .ht-sm-40p-f {
        height: 40% !important
    }

    .ht-sm-45 {
        height: 45px
    }

    .ht-sm-45p {
        height: 45%
    }

    .mx-ht-sm-45p {
        max-height: 45%
    }

    .mn-ht-sm-45p {
        min-height: 45%
    }

    .ht-sm-45-f {
        height: 45px !important
    }

    .ht-sm-45p-f {
        height: 45% !important
    }

    .ht-sm-50 {
        height: 50px
    }

    .ht-sm-50p {
        height: 50%
    }

    .mx-ht-sm-50p {
        max-height: 50%
    }

    .mn-ht-sm-50p {
        min-height: 50%
    }

    .ht-sm-50-f {
        height: 50px !important
    }

    .ht-sm-50p-f {
        height: 50% !important
    }

    .ht-sm-55 {
        height: 55px
    }

    .ht-sm-55p {
        height: 55%
    }

    .mx-ht-sm-55p {
        max-height: 55%
    }

    .mn-ht-sm-55p {
        min-height: 55%
    }

    .ht-sm-55-f {
        height: 55px !important
    }

    .ht-sm-55p-f {
        height: 55% !important
    }

    .ht-sm-60 {
        height: 60px
    }

    .ht-sm-60p {
        height: 60%
    }

    .mx-ht-sm-60p {
        max-height: 60%
    }

    .mn-ht-sm-60p {
        min-height: 60%
    }

    .ht-sm-60-f {
        height: 60px !important
    }

    .ht-sm-60p-f {
        height: 60% !important
    }

    .ht-sm-65 {
        height: 65px
    }

    .ht-sm-65p {
        height: 65%
    }

    .mx-ht-sm-65p {
        max-height: 65%
    }

    .mn-ht-sm-65p {
        min-height: 65%
    }

    .ht-sm-65-f {
        height: 65px !important
    }

    .ht-sm-65p-f {
        height: 65% !important
    }

    .ht-sm-70 {
        height: 70px
    }

    .ht-sm-70p {
        height: 70%
    }

    .mx-ht-sm-70p {
        max-height: 70%
    }

    .mn-ht-sm-70p {
        min-height: 70%
    }

    .ht-sm-70-f {
        height: 70px !important
    }

    .ht-sm-70p-f {
        height: 70% !important
    }

    .ht-sm-75 {
        height: 75px
    }

    .ht-sm-75p {
        height: 75%
    }

    .mx-ht-sm-75p {
        max-height: 75%
    }

    .mn-ht-sm-75p {
        min-height: 75%
    }

    .ht-sm-75-f {
        height: 75px !important
    }

    .ht-sm-75p-f {
        height: 75% !important
    }

    .ht-sm-80 {
        height: 80px
    }

    .ht-sm-80p {
        height: 80%
    }

    .mx-ht-sm-80p {
        max-height: 80%
    }

    .mn-ht-sm-80p {
        min-height: 80%
    }

    .ht-sm-80-f {
        height: 80px !important
    }

    .ht-sm-80p-f {
        height: 80% !important
    }

    .ht-sm-85 {
        height: 85px
    }

    .ht-sm-85p {
        height: 85%
    }

    .mx-ht-sm-85p {
        max-height: 85%
    }

    .mn-ht-sm-85p {
        min-height: 85%
    }

    .ht-sm-85-f {
        height: 85px !important
    }

    .ht-sm-85p-f {
        height: 85% !important
    }

    .ht-sm-90 {
        height: 90px
    }

    .ht-sm-90p {
        height: 90%
    }

    .mx-ht-sm-90p {
        max-height: 90%
    }

    .mn-ht-sm-90p {
        min-height: 90%
    }

    .ht-sm-90-f {
        height: 90px !important
    }

    .ht-sm-90p-f {
        height: 90% !important
    }

    .ht-sm-95 {
        height: 95px
    }

    .ht-sm-95p {
        height: 95%
    }

    .mx-ht-sm-95p {
        max-height: 95%
    }

    .mn-ht-sm-95p {
        min-height: 95%
    }

    .ht-sm-95-f {
        height: 95px !important
    }

    .ht-sm-95p-f {
        height: 95% !important
    }

    .ht-sm-100 {
        height: 100px
    }

    .ht-sm-100p {
        height: 100%
    }

    .mx-ht-sm-100p {
        max-height: 100%
    }

    .mn-ht-sm-100p {
        min-height: 100%
    }

    .ht-sm-100-f {
        height: 100px !important
    }

    .ht-sm-100p-f {
        height: 100% !important
    }

    .ht-sm-150 {
        height: 150px
    }

    .ht-sm-150p {
        height: 150%
    }

    .mx-ht-sm-150p {
        max-height: 150%
    }

    .mn-ht-sm-150p {
        min-height: 150%
    }

    .ht-sm-150-f {
        height: 150px !important
    }

    .ht-sm-150p-f {
        height: 150% !important
    }

    .ht-sm-200 {
        height: 200px
    }

    .ht-sm-200p {
        height: 200%
    }

    .mx-ht-sm-200p {
        max-height: 200%
    }

    .mn-ht-sm-200p {
        min-height: 200%
    }

    .ht-sm-200-f {
        height: 200px !important
    }

    .ht-sm-200p-f {
        height: 200% !important
    }

    .ht-sm-250 {
        height: 250px
    }

    .ht-sm-250p {
        height: 250%
    }

    .mx-ht-sm-250p {
        max-height: 250%
    }

    .mn-ht-sm-250p {
        min-height: 250%
    }

    .ht-sm-250-f {
        height: 250px !important
    }

    .ht-sm-250p-f {
        height: 250% !important
    }

    .ht-sm-300 {
        height: 300px
    }

    .ht-sm-300p {
        height: 300%
    }

    .mx-ht-sm-300p {
        max-height: 300%
    }

    .mn-ht-sm-300p {
        min-height: 300%
    }

    .ht-sm-300-f {
        height: 300px !important
    }

    .ht-sm-300p-f {
        height: 300% !important
    }

    .ht-sm-350 {
        height: 350px
    }

    .ht-sm-350p {
        height: 350%
    }

    .mx-ht-sm-350p {
        max-height: 350%
    }

    .mn-ht-sm-350p {
        min-height: 350%
    }

    .ht-sm-350-f {
        height: 350px !important
    }

    .ht-sm-350p-f {
        height: 350% !important
    }

    .ht-sm-400 {
        height: 400px
    }

    .ht-sm-400p {
        height: 400%
    }

    .mx-ht-sm-400p {
        max-height: 400%
    }

    .mn-ht-sm-400p {
        min-height: 400%
    }

    .ht-sm-400-f {
        height: 400px !important
    }

    .ht-sm-400p-f {
        height: 400% !important
    }

    .ht-sm-450 {
        height: 450px
    }

    .ht-sm-450p {
        height: 450%
    }

    .mx-ht-sm-450p {
        max-height: 450%
    }

    .mn-ht-sm-450p {
        min-height: 450%
    }

    .ht-sm-450-f {
        height: 450px !important
    }

    .ht-sm-450p-f {
        height: 450% !important
    }

    .ht-sm-500 {
        height: 500px
    }

    .ht-sm-500p {
        height: 500%
    }

    .mx-ht-sm-500p {
        max-height: 500%
    }

    .mn-ht-sm-500p {
        min-height: 500%
    }

    .ht-sm-500-f {
        height: 500px !important
    }

    .ht-sm-500p-f {
        height: 500% !important
    }

    .ht-sm-550 {
        height: 550px
    }

    .ht-sm-550p {
        height: 550%
    }

    .mx-ht-sm-550p {
        max-height: 550%
    }

    .mn-ht-sm-550p {
        min-height: 550%
    }

    .ht-sm-550-f {
        height: 550px !important
    }

    .ht-sm-550p-f {
        height: 550% !important
    }

    .ht-sm-600 {
        height: 600px
    }

    .ht-sm-600p {
        height: 600%
    }

    .mx-ht-sm-600p {
        max-height: 600%
    }

    .mn-ht-sm-600p {
        min-height: 600%
    }

    .ht-sm-600-f {
        height: 600px !important
    }

    .ht-sm-600p-f {
        height: 600% !important
    }

    .ht-sm-650 {
        height: 650px
    }

    .ht-sm-650p {
        height: 650%
    }

    .mx-ht-sm-650p {
        max-height: 650%
    }

    .mn-ht-sm-650p {
        min-height: 650%
    }

    .ht-sm-650-f {
        height: 650px !important
    }

    .ht-sm-650p-f {
        height: 650% !important
    }

    .ht-sm-700 {
        height: 700px
    }

    .ht-sm-700p {
        height: 700%
    }

    .mx-ht-sm-700p {
        max-height: 700%
    }

    .mn-ht-sm-700p {
        min-height: 700%
    }

    .ht-sm-700-f {
        height: 700px !important
    }

    .ht-sm-700p-f {
        height: 700% !important
    }

    .ht-sm-750 {
        height: 750px
    }

    .ht-sm-750p {
        height: 750%
    }

    .mx-ht-sm-750p {
        max-height: 750%
    }

    .mn-ht-sm-750p {
        min-height: 750%
    }

    .ht-sm-750-f {
        height: 750px !important
    }

    .ht-sm-750p-f {
        height: 750% !important
    }

    .ht-sm-800 {
        height: 800px
    }

    .ht-sm-800p {
        height: 800%
    }

    .mx-ht-sm-800p {
        max-height: 800%
    }

    .mn-ht-sm-800p {
        min-height: 800%
    }

    .ht-sm-800-f {
        height: 800px !important
    }

    .ht-sm-800p-f {
        height: 800% !important
    }

    .ht-sm-850 {
        height: 850px
    }

    .ht-sm-850p {
        height: 850%
    }

    .mx-ht-sm-850p {
        max-height: 850%
    }

    .mn-ht-sm-850p {
        min-height: 850%
    }

    .ht-sm-850-f {
        height: 850px !important
    }

    .ht-sm-850p-f {
        height: 850% !important
    }

    .ht-sm-100v {
        height: 100vh
    }
}

@media (min-width: 768px) {
    .ht-md-5 {
        height: 5px
    }

    .ht-md-5p {
        height: 5%
    }

    .mx-ht-md-5p {
        max-height: 5%
    }

    .mn-ht-md-5p {
        min-height: 5%
    }

    .ht-md-5-f {
        height: 5px !important
    }

    .ht-md-5p-f {
        height: 5% !important
    }

    .ht-md-10 {
        height: 10px
    }

    .ht-md-10p {
        height: 10%
    }

    .mx-ht-md-10p {
        max-height: 10%
    }

    .mn-ht-md-10p {
        min-height: 10%
    }

    .ht-md-10-f {
        height: 10px !important
    }

    .ht-md-10p-f {
        height: 10% !important
    }

    .ht-md-15 {
        height: 15px
    }

    .ht-md-15p {
        height: 15%
    }

    .mx-ht-md-15p {
        max-height: 15%
    }

    .mn-ht-md-15p {
        min-height: 15%
    }

    .ht-md-15-f {
        height: 15px !important
    }

    .ht-md-15p-f {
        height: 15% !important
    }

    .ht-md-20 {
        height: 20px
    }

    .ht-md-20p {
        height: 20%
    }

    .mx-ht-md-20p {
        max-height: 20%
    }

    .mn-ht-md-20p {
        min-height: 20%
    }

    .ht-md-20-f {
        height: 20px !important
    }

    .ht-md-20p-f {
        height: 20% !important
    }

    .ht-md-25 {
        height: 25px
    }

    .ht-md-25p {
        height: 25%
    }

    .mx-ht-md-25p {
        max-height: 25%
    }

    .mn-ht-md-25p {
        min-height: 25%
    }

    .ht-md-25-f {
        height: 25px !important
    }

    .ht-md-25p-f {
        height: 25% !important
    }

    .ht-md-30 {
        height: 30px
    }

    .ht-md-30p {
        height: 30%
    }

    .mx-ht-md-30p {
        max-height: 30%
    }

    .mn-ht-md-30p {
        min-height: 30%
    }

    .ht-md-30-f {
        height: 30px !important
    }

    .ht-md-30p-f {
        height: 30% !important
    }

    .ht-md-35 {
        height: 35px
    }

    .ht-md-35p {
        height: 35%
    }

    .mx-ht-md-35p {
        max-height: 35%
    }

    .mn-ht-md-35p {
        min-height: 35%
    }

    .ht-md-35-f {
        height: 35px !important
    }

    .ht-md-35p-f {
        height: 35% !important
    }

    .ht-md-40 {
        height: 40px
    }

    .ht-md-40p {
        height: 40%
    }

    .mx-ht-md-40p {
        max-height: 40%
    }

    .mn-ht-md-40p {
        min-height: 40%
    }

    .ht-md-40-f {
        height: 40px !important
    }

    .ht-md-40p-f {
        height: 40% !important
    }

    .ht-md-45 {
        height: 45px
    }

    .ht-md-45p {
        height: 45%
    }

    .mx-ht-md-45p {
        max-height: 45%
    }

    .mn-ht-md-45p {
        min-height: 45%
    }

    .ht-md-45-f {
        height: 45px !important
    }

    .ht-md-45p-f {
        height: 45% !important
    }

    .ht-md-50 {
        height: 50px
    }

    .ht-md-50p {
        height: 50%
    }

    .mx-ht-md-50p {
        max-height: 50%
    }

    .mn-ht-md-50p {
        min-height: 50%
    }

    .ht-md-50-f {
        height: 50px !important
    }

    .ht-md-50p-f {
        height: 50% !important
    }

    .ht-md-55 {
        height: 55px
    }

    .ht-md-55p {
        height: 55%
    }

    .mx-ht-md-55p {
        max-height: 55%
    }

    .mn-ht-md-55p {
        min-height: 55%
    }

    .ht-md-55-f {
        height: 55px !important
    }

    .ht-md-55p-f {
        height: 55% !important
    }

    .ht-md-60 {
        height: 60px
    }

    .ht-md-60p {
        height: 60%
    }

    .mx-ht-md-60p {
        max-height: 60%
    }

    .mn-ht-md-60p {
        min-height: 60%
    }

    .ht-md-60-f {
        height: 60px !important
    }

    .ht-md-60p-f {
        height: 60% !important
    }

    .ht-md-65 {
        height: 65px
    }

    .ht-md-65p {
        height: 65%
    }

    .mx-ht-md-65p {
        max-height: 65%
    }

    .mn-ht-md-65p {
        min-height: 65%
    }

    .ht-md-65-f {
        height: 65px !important
    }

    .ht-md-65p-f {
        height: 65% !important
    }

    .ht-md-70 {
        height: 70px
    }

    .ht-md-70p {
        height: 70%
    }

    .mx-ht-md-70p {
        max-height: 70%
    }

    .mn-ht-md-70p {
        min-height: 70%
    }

    .ht-md-70-f {
        height: 70px !important
    }

    .ht-md-70p-f {
        height: 70% !important
    }

    .ht-md-75 {
        height: 75px
    }

    .ht-md-75p {
        height: 75%
    }

    .mx-ht-md-75p {
        max-height: 75%
    }

    .mn-ht-md-75p {
        min-height: 75%
    }

    .ht-md-75-f {
        height: 75px !important
    }

    .ht-md-75p-f {
        height: 75% !important
    }

    .ht-md-80 {
        height: 80px
    }

    .ht-md-80p {
        height: 80%
    }

    .mx-ht-md-80p {
        max-height: 80%
    }

    .mn-ht-md-80p {
        min-height: 80%
    }

    .ht-md-80-f {
        height: 80px !important
    }

    .ht-md-80p-f {
        height: 80% !important
    }

    .ht-md-85 {
        height: 85px
    }

    .ht-md-85p {
        height: 85%
    }

    .mx-ht-md-85p {
        max-height: 85%
    }

    .mn-ht-md-85p {
        min-height: 85%
    }

    .ht-md-85-f {
        height: 85px !important
    }

    .ht-md-85p-f {
        height: 85% !important
    }

    .ht-md-90 {
        height: 90px
    }

    .ht-md-90p {
        height: 90%
    }

    .mx-ht-md-90p {
        max-height: 90%
    }

    .mn-ht-md-90p {
        min-height: 90%
    }

    .ht-md-90-f {
        height: 90px !important
    }

    .ht-md-90p-f {
        height: 90% !important
    }

    .ht-md-95 {
        height: 95px
    }

    .ht-md-95p {
        height: 95%
    }

    .mx-ht-md-95p {
        max-height: 95%
    }

    .mn-ht-md-95p {
        min-height: 95%
    }

    .ht-md-95-f {
        height: 95px !important
    }

    .ht-md-95p-f {
        height: 95% !important
    }

    .ht-md-100 {
        height: 100px
    }

    .ht-md-100p {
        height: 100%
    }

    .mx-ht-md-100p {
        max-height: 100%
    }

    .mn-ht-md-100p {
        min-height: 100%
    }

    .ht-md-100-f {
        height: 100px !important
    }

    .ht-md-100p-f {
        height: 100% !important
    }

    .ht-md-150 {
        height: 150px
    }

    .ht-md-150p {
        height: 150%
    }

    .mx-ht-md-150p {
        max-height: 150%
    }

    .mn-ht-md-150p {
        min-height: 150%
    }

    .ht-md-150-f {
        height: 150px !important
    }

    .ht-md-150p-f {
        height: 150% !important
    }

    .ht-md-200 {
        height: 200px
    }

    .ht-md-200p {
        height: 200%
    }

    .mx-ht-md-200p {
        max-height: 200%
    }

    .mn-ht-md-200p {
        min-height: 200%
    }

    .ht-md-200-f {
        height: 200px !important
    }

    .ht-md-200p-f {
        height: 200% !important
    }

    .ht-md-250 {
        height: 250px
    }

    .ht-md-250p {
        height: 250%
    }

    .mx-ht-md-250p {
        max-height: 250%
    }

    .mn-ht-md-250p {
        min-height: 250%
    }

    .ht-md-250-f {
        height: 250px !important
    }

    .ht-md-250p-f {
        height: 250% !important
    }

    .ht-md-300 {
        height: 300px
    }

    .ht-md-300p {
        height: 300%
    }

    .mx-ht-md-300p {
        max-height: 300%
    }

    .mn-ht-md-300p {
        min-height: 300%
    }

    .ht-md-300-f {
        height: 300px !important
    }

    .ht-md-300p-f {
        height: 300% !important
    }

    .ht-md-350 {
        height: 350px
    }

    .ht-md-350p {
        height: 350%
    }

    .mx-ht-md-350p {
        max-height: 350%
    }

    .mn-ht-md-350p {
        min-height: 350%
    }

    .ht-md-350-f {
        height: 350px !important
    }

    .ht-md-350p-f {
        height: 350% !important
    }

    .ht-md-400 {
        height: 400px
    }

    .ht-md-400p {
        height: 400%
    }

    .mx-ht-md-400p {
        max-height: 400%
    }

    .mn-ht-md-400p {
        min-height: 400%
    }

    .ht-md-400-f {
        height: 400px !important
    }

    .ht-md-400p-f {
        height: 400% !important
    }

    .ht-md-450 {
        height: 450px
    }

    .ht-md-450p {
        height: 450%
    }

    .mx-ht-md-450p {
        max-height: 450%
    }

    .mn-ht-md-450p {
        min-height: 450%
    }

    .ht-md-450-f {
        height: 450px !important
    }

    .ht-md-450p-f {
        height: 450% !important
    }

    .ht-md-500 {
        height: 500px
    }

    .ht-md-500p {
        height: 500%
    }

    .mx-ht-md-500p {
        max-height: 500%
    }

    .mn-ht-md-500p {
        min-height: 500%
    }

    .ht-md-500-f {
        height: 500px !important
    }

    .ht-md-500p-f {
        height: 500% !important
    }

    .ht-md-550 {
        height: 550px
    }

    .ht-md-550p {
        height: 550%
    }

    .mx-ht-md-550p {
        max-height: 550%
    }

    .mn-ht-md-550p {
        min-height: 550%
    }

    .ht-md-550-f {
        height: 550px !important
    }

    .ht-md-550p-f {
        height: 550% !important
    }

    .ht-md-600 {
        height: 600px
    }

    .ht-md-600p {
        height: 600%
    }

    .mx-ht-md-600p {
        max-height: 600%
    }

    .mn-ht-md-600p {
        min-height: 600%
    }

    .ht-md-600-f {
        height: 600px !important
    }

    .ht-md-600p-f {
        height: 600% !important
    }

    .ht-md-650 {
        height: 650px
    }

    .ht-md-650p {
        height: 650%
    }

    .mx-ht-md-650p {
        max-height: 650%
    }

    .mn-ht-md-650p {
        min-height: 650%
    }

    .ht-md-650-f {
        height: 650px !important
    }

    .ht-md-650p-f {
        height: 650% !important
    }

    .ht-md-700 {
        height: 700px
    }

    .ht-md-700p {
        height: 700%
    }

    .mx-ht-md-700p {
        max-height: 700%
    }

    .mn-ht-md-700p {
        min-height: 700%
    }

    .ht-md-700-f {
        height: 700px !important
    }

    .ht-md-700p-f {
        height: 700% !important
    }

    .ht-md-750 {
        height: 750px
    }

    .ht-md-750p {
        height: 750%
    }

    .mx-ht-md-750p {
        max-height: 750%
    }

    .mn-ht-md-750p {
        min-height: 750%
    }

    .ht-md-750-f {
        height: 750px !important
    }

    .ht-md-750p-f {
        height: 750% !important
    }

    .ht-md-800 {
        height: 800px
    }

    .ht-md-800p {
        height: 800%
    }

    .mx-ht-md-800p {
        max-height: 800%
    }

    .mn-ht-md-800p {
        min-height: 800%
    }

    .ht-md-800-f {
        height: 800px !important
    }

    .ht-md-800p-f {
        height: 800% !important
    }

    .ht-md-850 {
        height: 850px
    }

    .ht-md-850p {
        height: 850%
    }

    .mx-ht-md-850p {
        max-height: 850%
    }

    .mn-ht-md-850p {
        min-height: 850%
    }

    .ht-md-850-f {
        height: 850px !important
    }

    .ht-md-850p-f {
        height: 850% !important
    }

    .ht-md-100v {
        height: 100vh
    }
}

@media (min-width: 992px) {
    .ht-lg-5 {
        height: 5px
    }

    .ht-lg-5p {
        height: 5%
    }

    .mx-ht-lg-5p {
        max-height: 5%
    }

    .mn-ht-lg-5p {
        min-height: 5%
    }

    .ht-lg-5-f {
        height: 5px !important
    }

    .ht-lg-5p-f {
        height: 5% !important
    }

    .ht-lg-10 {
        height: 10px
    }

    .ht-lg-10p {
        height: 10%
    }

    .mx-ht-lg-10p {
        max-height: 10%
    }

    .mn-ht-lg-10p {
        min-height: 10%
    }

    .ht-lg-10-f {
        height: 10px !important
    }

    .ht-lg-10p-f {
        height: 10% !important
    }

    .ht-lg-15 {
        height: 15px
    }

    .ht-lg-15p {
        height: 15%
    }

    .mx-ht-lg-15p {
        max-height: 15%
    }

    .mn-ht-lg-15p {
        min-height: 15%
    }

    .ht-lg-15-f {
        height: 15px !important
    }

    .ht-lg-15p-f {
        height: 15% !important
    }

    .ht-lg-20 {
        height: 20px
    }

    .ht-lg-20p {
        height: 20%
    }

    .mx-ht-lg-20p {
        max-height: 20%
    }

    .mn-ht-lg-20p {
        min-height: 20%
    }

    .ht-lg-20-f {
        height: 20px !important
    }

    .ht-lg-20p-f {
        height: 20% !important
    }

    .ht-lg-25 {
        height: 25px
    }

    .ht-lg-25p {
        height: 25%
    }

    .mx-ht-lg-25p {
        max-height: 25%
    }

    .mn-ht-lg-25p {
        min-height: 25%
    }

    .ht-lg-25-f {
        height: 25px !important
    }

    .ht-lg-25p-f {
        height: 25% !important
    }

    .ht-lg-30 {
        height: 30px
    }

    .ht-lg-30p {
        height: 30%
    }

    .mx-ht-lg-30p {
        max-height: 30%
    }

    .mn-ht-lg-30p {
        min-height: 30%
    }

    .ht-lg-30-f {
        height: 30px !important
    }

    .ht-lg-30p-f {
        height: 30% !important
    }

    .ht-lg-35 {
        height: 35px
    }

    .ht-lg-35p {
        height: 35%
    }

    .mx-ht-lg-35p {
        max-height: 35%
    }

    .mn-ht-lg-35p {
        min-height: 35%
    }

    .ht-lg-35-f {
        height: 35px !important
    }

    .ht-lg-35p-f {
        height: 35% !important
    }

    .ht-lg-40 {
        height: 40px
    }

    .ht-lg-40p {
        height: 40%
    }

    .mx-ht-lg-40p {
        max-height: 40%
    }

    .mn-ht-lg-40p {
        min-height: 40%
    }

    .ht-lg-40-f {
        height: 40px !important
    }

    .ht-lg-40p-f {
        height: 40% !important
    }

    .ht-lg-45 {
        height: 45px
    }

    .ht-lg-45p {
        height: 45%
    }

    .mx-ht-lg-45p {
        max-height: 45%
    }

    .mn-ht-lg-45p {
        min-height: 45%
    }

    .ht-lg-45-f {
        height: 45px !important
    }

    .ht-lg-45p-f {
        height: 45% !important
    }

    .ht-lg-50 {
        height: 50px
    }

    .ht-lg-50p {
        height: 50%
    }

    .mx-ht-lg-50p {
        max-height: 50%
    }

    .mn-ht-lg-50p {
        min-height: 50%
    }

    .ht-lg-50-f {
        height: 50px !important
    }

    .ht-lg-50p-f {
        height: 50% !important
    }

    .ht-lg-55 {
        height: 55px
    }

    .ht-lg-55p {
        height: 55%
    }

    .mx-ht-lg-55p {
        max-height: 55%
    }

    .mn-ht-lg-55p {
        min-height: 55%
    }

    .ht-lg-55-f {
        height: 55px !important
    }

    .ht-lg-55p-f {
        height: 55% !important
    }

    .ht-lg-60 {
        height: 60px
    }

    .ht-lg-60p {
        height: 60%
    }

    .mx-ht-lg-60p {
        max-height: 60%
    }

    .mn-ht-lg-60p {
        min-height: 60%
    }

    .ht-lg-60-f {
        height: 60px !important
    }

    .ht-lg-60p-f {
        height: 60% !important
    }

    .ht-lg-65 {
        height: 65px
    }

    .ht-lg-65p {
        height: 65%
    }

    .mx-ht-lg-65p {
        max-height: 65%
    }

    .mn-ht-lg-65p {
        min-height: 65%
    }

    .ht-lg-65-f {
        height: 65px !important
    }

    .ht-lg-65p-f {
        height: 65% !important
    }

    .ht-lg-70 {
        height: 70px
    }

    .ht-lg-70p {
        height: 70%
    }

    .mx-ht-lg-70p {
        max-height: 70%
    }

    .mn-ht-lg-70p {
        min-height: 70%
    }

    .ht-lg-70-f {
        height: 70px !important
    }

    .ht-lg-70p-f {
        height: 70% !important
    }

    .ht-lg-75 {
        height: 75px
    }

    .ht-lg-75p {
        height: 75%
    }

    .mx-ht-lg-75p {
        max-height: 75%
    }

    .mn-ht-lg-75p {
        min-height: 75%
    }

    .ht-lg-75-f {
        height: 75px !important
    }

    .ht-lg-75p-f {
        height: 75% !important
    }

    .ht-lg-80 {
        height: 80px
    }

    .ht-lg-80p {
        height: 80%
    }

    .mx-ht-lg-80p {
        max-height: 80%
    }

    .mn-ht-lg-80p {
        min-height: 80%
    }

    .ht-lg-80-f {
        height: 80px !important
    }

    .ht-lg-80p-f {
        height: 80% !important
    }

    .ht-lg-85 {
        height: 85px
    }

    .ht-lg-85p {
        height: 85%
    }

    .mx-ht-lg-85p {
        max-height: 85%
    }

    .mn-ht-lg-85p {
        min-height: 85%
    }

    .ht-lg-85-f {
        height: 85px !important
    }

    .ht-lg-85p-f {
        height: 85% !important
    }

    .ht-lg-90 {
        height: 90px
    }

    .ht-lg-90p {
        height: 90%
    }

    .mx-ht-lg-90p {
        max-height: 90%
    }

    .mn-ht-lg-90p {
        min-height: 90%
    }

    .ht-lg-90-f {
        height: 90px !important
    }

    .ht-lg-90p-f {
        height: 90% !important
    }

    .ht-lg-95 {
        height: 95px
    }

    .ht-lg-95p {
        height: 95%
    }

    .mx-ht-lg-95p {
        max-height: 95%
    }

    .mn-ht-lg-95p {
        min-height: 95%
    }

    .ht-lg-95-f {
        height: 95px !important
    }

    .ht-lg-95p-f {
        height: 95% !important
    }

    .ht-lg-100 {
        height: 100px
    }

    .ht-lg-100p {
        height: 100%
    }

    .mx-ht-lg-100p {
        max-height: 100%
    }

    .mn-ht-lg-100p {
        min-height: 100%
    }

    .ht-lg-100-f {
        height: 100px !important
    }

    .ht-lg-100p-f {
        height: 100% !important
    }

    .ht-lg-150 {
        height: 150px
    }

    .ht-lg-150p {
        height: 150%
    }

    .mx-ht-lg-150p {
        max-height: 150%
    }

    .mn-ht-lg-150p {
        min-height: 150%
    }

    .ht-lg-150-f {
        height: 150px !important
    }

    .ht-lg-150p-f {
        height: 150% !important
    }

    .ht-lg-200 {
        height: 200px
    }

    .ht-lg-200p {
        height: 200%
    }

    .mx-ht-lg-200p {
        max-height: 200%
    }

    .mn-ht-lg-200p {
        min-height: 200%
    }

    .ht-lg-200-f {
        height: 200px !important
    }

    .ht-lg-200p-f {
        height: 200% !important
    }

    .ht-lg-250 {
        height: 250px
    }

    .ht-lg-250p {
        height: 250%
    }

    .mx-ht-lg-250p {
        max-height: 250%
    }

    .mn-ht-lg-250p {
        min-height: 250%
    }

    .ht-lg-250-f {
        height: 250px !important
    }

    .ht-lg-250p-f {
        height: 250% !important
    }

    .ht-lg-300 {
        height: 300px
    }

    .ht-lg-300p {
        height: 300%
    }

    .mx-ht-lg-300p {
        max-height: 300%
    }

    .mn-ht-lg-300p {
        min-height: 300%
    }

    .ht-lg-300-f {
        height: 300px !important
    }

    .ht-lg-300p-f {
        height: 300% !important
    }

    .ht-lg-350 {
        height: 350px
    }

    .ht-lg-350p {
        height: 350%
    }

    .mx-ht-lg-350p {
        max-height: 350%
    }

    .mn-ht-lg-350p {
        min-height: 350%
    }

    .ht-lg-350-f {
        height: 350px !important
    }

    .ht-lg-350p-f {
        height: 350% !important
    }

    .ht-lg-400 {
        height: 400px
    }

    .ht-lg-400p {
        height: 400%
    }

    .mx-ht-lg-400p {
        max-height: 400%
    }

    .mn-ht-lg-400p {
        min-height: 400%
    }

    .ht-lg-400-f {
        height: 400px !important
    }

    .ht-lg-400p-f {
        height: 400% !important
    }

    .ht-lg-450 {
        height: 450px
    }

    .ht-lg-450p {
        height: 450%
    }

    .mx-ht-lg-450p {
        max-height: 450%
    }

    .mn-ht-lg-450p {
        min-height: 450%
    }

    .ht-lg-450-f {
        height: 450px !important
    }

    .ht-lg-450p-f {
        height: 450% !important
    }

    .ht-lg-500 {
        height: 500px
    }

    .ht-lg-500p {
        height: 500%
    }

    .mx-ht-lg-500p {
        max-height: 500%
    }

    .mn-ht-lg-500p {
        min-height: 500%
    }

    .ht-lg-500-f {
        height: 500px !important
    }

    .ht-lg-500p-f {
        height: 500% !important
    }

    .ht-lg-550 {
        height: 550px
    }

    .ht-lg-550p {
        height: 550%
    }

    .mx-ht-lg-550p {
        max-height: 550%
    }

    .mn-ht-lg-550p {
        min-height: 550%
    }

    .ht-lg-550-f {
        height: 550px !important
    }

    .ht-lg-550p-f {
        height: 550% !important
    }

    .ht-lg-600 {
        height: 600px
    }

    .ht-lg-600p {
        height: 600%
    }

    .mx-ht-lg-600p {
        max-height: 600%
    }

    .mn-ht-lg-600p {
        min-height: 600%
    }

    .ht-lg-600-f {
        height: 600px !important
    }

    .ht-lg-600p-f {
        height: 600% !important
    }

    .ht-lg-650 {
        height: 650px
    }

    .ht-lg-650p {
        height: 650%
    }

    .mx-ht-lg-650p {
        max-height: 650%
    }

    .mn-ht-lg-650p {
        min-height: 650%
    }

    .ht-lg-650-f {
        height: 650px !important
    }

    .ht-lg-650p-f {
        height: 650% !important
    }

    .ht-lg-700 {
        height: 700px
    }

    .ht-lg-700p {
        height: 700%
    }

    .mx-ht-lg-700p {
        max-height: 700%
    }

    .mn-ht-lg-700p {
        min-height: 700%
    }

    .ht-lg-700-f {
        height: 700px !important
    }

    .ht-lg-700p-f {
        height: 700% !important
    }

    .ht-lg-750 {
        height: 750px
    }

    .ht-lg-750p {
        height: 750%
    }

    .mx-ht-lg-750p {
        max-height: 750%
    }

    .mn-ht-lg-750p {
        min-height: 750%
    }

    .ht-lg-750-f {
        height: 750px !important
    }

    .ht-lg-750p-f {
        height: 750% !important
    }

    .ht-lg-800 {
        height: 800px
    }

    .ht-lg-800p {
        height: 800%
    }

    .mx-ht-lg-800p {
        max-height: 800%
    }

    .mn-ht-lg-800p {
        min-height: 800%
    }

    .ht-lg-800-f {
        height: 800px !important
    }

    .ht-lg-800p-f {
        height: 800% !important
    }

    .ht-lg-850 {
        height: 850px
    }

    .ht-lg-850p {
        height: 850%
    }

    .mx-ht-lg-850p {
        max-height: 850%
    }

    .mn-ht-lg-850p {
        min-height: 850%
    }

    .ht-lg-850-f {
        height: 850px !important
    }

    .ht-lg-850p-f {
        height: 850% !important
    }

    .ht-lg-100v {
        height: 100vh
    }
}

@media (min-width: 1200px) {
    .ht-xl-5 {
        height: 5px
    }

    .ht-xl-5p {
        height: 5%
    }

    .mx-ht-xl-5p {
        max-height: 5%
    }

    .mn-ht-xl-5p {
        min-height: 5%
    }

    .ht-xl-5-f {
        height: 5px !important
    }

    .ht-xl-5p-f {
        height: 5% !important
    }

    .ht-xl-10 {
        height: 10px
    }

    .ht-xl-10p {
        height: 10%
    }

    .mx-ht-xl-10p {
        max-height: 10%
    }

    .mn-ht-xl-10p {
        min-height: 10%
    }

    .ht-xl-10-f {
        height: 10px !important
    }

    .ht-xl-10p-f {
        height: 10% !important
    }

    .ht-xl-15 {
        height: 15px
    }

    .ht-xl-15p {
        height: 15%
    }

    .mx-ht-xl-15p {
        max-height: 15%
    }

    .mn-ht-xl-15p {
        min-height: 15%
    }

    .ht-xl-15-f {
        height: 15px !important
    }

    .ht-xl-15p-f {
        height: 15% !important
    }

    .ht-xl-20 {
        height: 20px
    }

    .ht-xl-20p {
        height: 20%
    }

    .mx-ht-xl-20p {
        max-height: 20%
    }

    .mn-ht-xl-20p {
        min-height: 20%
    }

    .ht-xl-20-f {
        height: 20px !important
    }

    .ht-xl-20p-f {
        height: 20% !important
    }

    .ht-xl-25 {
        height: 25px
    }

    .ht-xl-25p {
        height: 25%
    }

    .mx-ht-xl-25p {
        max-height: 25%
    }

    .mn-ht-xl-25p {
        min-height: 25%
    }

    .ht-xl-25-f {
        height: 25px !important
    }

    .ht-xl-25p-f {
        height: 25% !important
    }

    .ht-xl-30 {
        height: 30px
    }

    .ht-xl-30p {
        height: 30%
    }

    .mx-ht-xl-30p {
        max-height: 30%
    }

    .mn-ht-xl-30p {
        min-height: 30%
    }

    .ht-xl-30-f {
        height: 30px !important
    }

    .ht-xl-30p-f {
        height: 30% !important
    }

    .ht-xl-35 {
        height: 35px
    }

    .ht-xl-35p {
        height: 35%
    }

    .mx-ht-xl-35p {
        max-height: 35%
    }

    .mn-ht-xl-35p {
        min-height: 35%
    }

    .ht-xl-35-f {
        height: 35px !important
    }

    .ht-xl-35p-f {
        height: 35% !important
    }

    .ht-xl-40 {
        height: 40px
    }

    .ht-xl-40p {
        height: 40%
    }

    .mx-ht-xl-40p {
        max-height: 40%
    }

    .mn-ht-xl-40p {
        min-height: 40%
    }

    .ht-xl-40-f {
        height: 40px !important
    }

    .ht-xl-40p-f {
        height: 40% !important
    }

    .ht-xl-45 {
        height: 45px
    }

    .ht-xl-45p {
        height: 45%
    }

    .mx-ht-xl-45p {
        max-height: 45%
    }

    .mn-ht-xl-45p {
        min-height: 45%
    }

    .ht-xl-45-f {
        height: 45px !important
    }

    .ht-xl-45p-f {
        height: 45% !important
    }

    .ht-xl-50 {
        height: 50px
    }

    .ht-xl-50p {
        height: 50%
    }

    .mx-ht-xl-50p {
        max-height: 50%
    }

    .mn-ht-xl-50p {
        min-height: 50%
    }

    .ht-xl-50-f {
        height: 50px !important
    }

    .ht-xl-50p-f {
        height: 50% !important
    }

    .ht-xl-55 {
        height: 55px
    }

    .ht-xl-55p {
        height: 55%
    }

    .mx-ht-xl-55p {
        max-height: 55%
    }

    .mn-ht-xl-55p {
        min-height: 55%
    }

    .ht-xl-55-f {
        height: 55px !important
    }

    .ht-xl-55p-f {
        height: 55% !important
    }

    .ht-xl-60 {
        height: 60px
    }

    .ht-xl-60p {
        height: 60%
    }

    .mx-ht-xl-60p {
        max-height: 60%
    }

    .mn-ht-xl-60p {
        min-height: 60%
    }

    .ht-xl-60-f {
        height: 60px !important
    }

    .ht-xl-60p-f {
        height: 60% !important
    }

    .ht-xl-65 {
        height: 65px
    }

    .ht-xl-65p {
        height: 65%
    }

    .mx-ht-xl-65p {
        max-height: 65%
    }

    .mn-ht-xl-65p {
        min-height: 65%
    }

    .ht-xl-65-f {
        height: 65px !important
    }

    .ht-xl-65p-f {
        height: 65% !important
    }

    .ht-xl-70 {
        height: 70px
    }

    .ht-xl-70p {
        height: 70%
    }

    .mx-ht-xl-70p {
        max-height: 70%
    }

    .mn-ht-xl-70p {
        min-height: 70%
    }

    .ht-xl-70-f {
        height: 70px !important
    }

    .ht-xl-70p-f {
        height: 70% !important
    }

    .ht-xl-75 {
        height: 75px
    }

    .ht-xl-75p {
        height: 75%
    }

    .mx-ht-xl-75p {
        max-height: 75%
    }

    .mn-ht-xl-75p {
        min-height: 75%
    }

    .ht-xl-75-f {
        height: 75px !important
    }

    .ht-xl-75p-f {
        height: 75% !important
    }

    .ht-xl-80 {
        height: 80px
    }

    .ht-xl-80p {
        height: 80%
    }

    .mx-ht-xl-80p {
        max-height: 80%
    }

    .mn-ht-xl-80p {
        min-height: 80%
    }

    .ht-xl-80-f {
        height: 80px !important
    }

    .ht-xl-80p-f {
        height: 80% !important
    }

    .ht-xl-85 {
        height: 85px
    }

    .ht-xl-85p {
        height: 85%
    }

    .mx-ht-xl-85p {
        max-height: 85%
    }

    .mn-ht-xl-85p {
        min-height: 85%
    }

    .ht-xl-85-f {
        height: 85px !important
    }

    .ht-xl-85p-f {
        height: 85% !important
    }

    .ht-xl-90 {
        height: 90px
    }

    .ht-xl-90p {
        height: 90%
    }

    .mx-ht-xl-90p {
        max-height: 90%
    }

    .mn-ht-xl-90p {
        min-height: 90%
    }

    .ht-xl-90-f {
        height: 90px !important
    }

    .ht-xl-90p-f {
        height: 90% !important
    }

    .ht-xl-95 {
        height: 95px
    }

    .ht-xl-95p {
        height: 95%
    }

    .mx-ht-xl-95p {
        max-height: 95%
    }

    .mn-ht-xl-95p {
        min-height: 95%
    }

    .ht-xl-95-f {
        height: 95px !important
    }

    .ht-xl-95p-f {
        height: 95% !important
    }

    .ht-xl-100 {
        height: 100px
    }

    .ht-xl-100p {
        height: 100%
    }

    .mx-ht-xl-100p {
        max-height: 100%
    }

    .mn-ht-xl-100p {
        min-height: 100%
    }

    .ht-xl-100-f {
        height: 100px !important
    }

    .ht-xl-100p-f {
        height: 100% !important
    }

    .ht-xl-150 {
        height: 150px
    }

    .ht-xl-150p {
        height: 150%
    }

    .mx-ht-xl-150p {
        max-height: 150%
    }

    .mn-ht-xl-150p {
        min-height: 150%
    }

    .ht-xl-150-f {
        height: 150px !important
    }

    .ht-xl-150p-f {
        height: 150% !important
    }

    .ht-xl-200 {
        height: 200px
    }

    .ht-xl-200p {
        height: 200%
    }

    .mx-ht-xl-200p {
        max-height: 200%
    }

    .mn-ht-xl-200p {
        min-height: 200%
    }

    .ht-xl-200-f {
        height: 200px !important
    }

    .ht-xl-200p-f {
        height: 200% !important
    }

    .ht-xl-250 {
        height: 250px
    }

    .ht-xl-250p {
        height: 250%
    }

    .mx-ht-xl-250p {
        max-height: 250%
    }

    .mn-ht-xl-250p {
        min-height: 250%
    }

    .ht-xl-250-f {
        height: 250px !important
    }

    .ht-xl-250p-f {
        height: 250% !important
    }

    .ht-xl-300 {
        height: 300px
    }

    .ht-xl-300p {
        height: 300%
    }

    .mx-ht-xl-300p {
        max-height: 300%
    }

    .mn-ht-xl-300p {
        min-height: 300%
    }

    .ht-xl-300-f {
        height: 300px !important
    }

    .ht-xl-300p-f {
        height: 300% !important
    }

    .ht-xl-350 {
        height: 350px
    }

    .ht-xl-350p {
        height: 350%
    }

    .mx-ht-xl-350p {
        max-height: 350%
    }

    .mn-ht-xl-350p {
        min-height: 350%
    }

    .ht-xl-350-f {
        height: 350px !important
    }

    .ht-xl-350p-f {
        height: 350% !important
    }

    .ht-xl-400 {
        height: 400px
    }

    .ht-xl-400p {
        height: 400%
    }

    .mx-ht-xl-400p {
        max-height: 400%
    }

    .mn-ht-xl-400p {
        min-height: 400%
    }

    .ht-xl-400-f {
        height: 400px !important
    }

    .ht-xl-400p-f {
        height: 400% !important
    }

    .ht-xl-450 {
        height: 450px
    }

    .ht-xl-450p {
        height: 450%
    }

    .mx-ht-xl-450p {
        max-height: 450%
    }

    .mn-ht-xl-450p {
        min-height: 450%
    }

    .ht-xl-450-f {
        height: 450px !important
    }

    .ht-xl-450p-f {
        height: 450% !important
    }

    .ht-xl-500 {
        height: 500px
    }

    .ht-xl-500p {
        height: 500%
    }

    .mx-ht-xl-500p {
        max-height: 500%
    }

    .mn-ht-xl-500p {
        min-height: 500%
    }

    .ht-xl-500-f {
        height: 500px !important
    }

    .ht-xl-500p-f {
        height: 500% !important
    }

    .ht-xl-550 {
        height: 550px
    }

    .ht-xl-550p {
        height: 550%
    }

    .mx-ht-xl-550p {
        max-height: 550%
    }

    .mn-ht-xl-550p {
        min-height: 550%
    }

    .ht-xl-550-f {
        height: 550px !important
    }

    .ht-xl-550p-f {
        height: 550% !important
    }

    .ht-xl-600 {
        height: 600px
    }

    .ht-xl-600p {
        height: 600%
    }

    .mx-ht-xl-600p {
        max-height: 600%
    }

    .mn-ht-xl-600p {
        min-height: 600%
    }

    .ht-xl-600-f {
        height: 600px !important
    }

    .ht-xl-600p-f {
        height: 600% !important
    }

    .ht-xl-650 {
        height: 650px
    }

    .ht-xl-650p {
        height: 650%
    }

    .mx-ht-xl-650p {
        max-height: 650%
    }

    .mn-ht-xl-650p {
        min-height: 650%
    }

    .ht-xl-650-f {
        height: 650px !important
    }

    .ht-xl-650p-f {
        height: 650% !important
    }

    .ht-xl-700 {
        height: 700px
    }

    .ht-xl-700p {
        height: 700%
    }

    .mx-ht-xl-700p {
        max-height: 700%
    }

    .mn-ht-xl-700p {
        min-height: 700%
    }

    .ht-xl-700-f {
        height: 700px !important
    }

    .ht-xl-700p-f {
        height: 700% !important
    }

    .ht-xl-750 {
        height: 750px
    }

    .ht-xl-750p {
        height: 750%
    }

    .mx-ht-xl-750p {
        max-height: 750%
    }

    .mn-ht-xl-750p {
        min-height: 750%
    }

    .ht-xl-750-f {
        height: 750px !important
    }

    .ht-xl-750p-f {
        height: 750% !important
    }

    .ht-xl-800 {
        height: 800px
    }

    .ht-xl-800p {
        height: 800%
    }

    .mx-ht-xl-800p {
        max-height: 800%
    }

    .mn-ht-xl-800p {
        min-height: 800%
    }

    .ht-xl-800-f {
        height: 800px !important
    }

    .ht-xl-800p-f {
        height: 800% !important
    }

    .ht-xl-850 {
        height: 850px
    }

    .ht-xl-850p {
        height: 850%
    }

    .mx-ht-xl-850p {
        max-height: 850%
    }

    .mn-ht-xl-850p {
        min-height: 850%
    }

    .ht-xl-850-f {
        height: 850px !important
    }

    .ht-xl-850p-f {
        height: 850% !important
    }

    .ht-xl-100v {
        height: 100vh
    }
}

.mg-0 {
    margin: 0px
}

.mg-0-f {
    margin: 0px !important
}

.mg-y-0 {
    margin-top: 0px;
    margin-bottom: 0px
}

.mg-y-0-f {
    margin-top: 0px !important;
    margin-bottom: 0px !important
}

.mg-x-0 {
    margin-left: 0px;
    margin-right: 0px
}

.mg-x-0-f {
    margin-left: 0px !important;
    margin-right: 0px !important
}

.mg-t-0 {
    margin-top: 0px
}

.mg-r-0 {
    margin-right: 0px
}

.mg-b-0 {
    margin-bottom: 0px
}

.mg-l-0 {
    margin-left: 0px
}

.mg-t-0-f {
    margin-top: 0px !important
}

.mg-r-0-f {
    margin-right: 0px !important
}

.mg-b-0-f {
    margin-bottom: 0px !important
}

.mg-l-0-f {
    margin-left: 0px !important
}

.mg-1 {
    margin: 1px
}

.mg-1-f {
    margin: 1px !important
}

.mg-y-1 {
    margin-top: 1px;
    margin-bottom: 1px
}

.mg-y-1-f {
    margin-top: 1px !important;
    margin-bottom: 1px !important
}

.mg-x-1 {
    margin-left: 1px;
    margin-right: 1px
}

.mg-x-1-f {
    margin-left: 1px !important;
    margin-right: 1px !important
}

.mg-t-1 {
    margin-top: 1px
}

.mg-r-1 {
    margin-right: 1px
}

.mg-b-1 {
    margin-bottom: 1px
}

.mg-l-1 {
    margin-left: 1px
}

.mg-t-1-f {
    margin-top: 1px !important
}

.mg-r-1-f {
    margin-right: 1px !important
}

.mg-b-1-f {
    margin-bottom: 1px !important
}

.mg-l-1-f {
    margin-left: 1px !important
}

.mg-2 {
    margin: 2px
}

.mg-2-f {
    margin: 2px !important
}

.mg-y-2 {
    margin-top: 2px;
    margin-bottom: 2px
}

.mg-y-2-f {
    margin-top: 2px !important;
    margin-bottom: 2px !important
}

.mg-x-2 {
    margin-left: 2px;
    margin-right: 2px
}

.mg-x-2-f {
    margin-left: 2px !important;
    margin-right: 2px !important
}

.mg-t-2 {
    margin-top: 2px
}

.mg-r-2 {
    margin-right: 2px
}

.mg-b-2 {
    margin-bottom: 2px
}

.mg-l-2 {
    margin-left: 2px
}

.mg-t-2-f {
    margin-top: 2px !important
}

.mg-r-2-f {
    margin-right: 2px !important
}

.mg-b-2-f {
    margin-bottom: 2px !important
}

.mg-l-2-f {
    margin-left: 2px !important
}

.mg-3 {
    margin: 3px
}

.mg-3-f {
    margin: 3px !important
}

.mg-y-3 {
    margin-top: 3px;
    margin-bottom: 3px
}

.mg-y-3-f {
    margin-top: 3px !important;
    margin-bottom: 3px !important
}

.mg-x-3 {
    margin-left: 3px;
    margin-right: 3px
}

.mg-x-3-f {
    margin-left: 3px !important;
    margin-right: 3px !important
}

.mg-t-3 {
    margin-top: 3px
}

.mg-r-3 {
    margin-right: 3px
}

.mg-b-3 {
    margin-bottom: 3px
}

.mg-l-3 {
    margin-left: 3px
}

.mg-t-3-f {
    margin-top: 3px !important
}

.mg-r-3-f {
    margin-right: 3px !important
}

.mg-b-3-f {
    margin-bottom: 3px !important
}

.mg-l-3-f {
    margin-left: 3px !important
}

.mg-4 {
    margin: 4px
}

.mg-4-f {
    margin: 4px !important
}

.mg-y-4 {
    margin-top: 4px;
    margin-bottom: 4px
}

.mg-y-4-f {
    margin-top: 4px !important;
    margin-bottom: 4px !important
}

.mg-x-4 {
    margin-left: 4px;
    margin-right: 4px
}

.mg-x-4-f {
    margin-left: 4px !important;
    margin-right: 4px !important
}

.mg-t-4 {
    margin-top: 4px
}

.mg-r-4 {
    margin-right: 4px
}

.mg-b-4 {
    margin-bottom: 4px
}

.mg-l-4 {
    margin-left: 4px
}

.mg-t-4-f {
    margin-top: 4px !important
}

.mg-r-4-f {
    margin-right: 4px !important
}

.mg-b-4-f {
    margin-bottom: 4px !important
}

.mg-l-4-f {
    margin-left: 4px !important
}

.mg-5 {
    margin: 5px
}

.mg-5-f {
    margin: 5px !important
}

.mg-y-5 {
    margin-top: 5px;
    margin-bottom: 5px
}

.mg-y-5-f {
    margin-top: 5px !important;
    margin-bottom: 5px !important
}

.mg-x-5 {
    margin-left: 5px;
    margin-right: 5px
}

.mg-x-5-f {
    margin-left: 5px !important;
    margin-right: 5px !important
}

.mg-t-5 {
    margin-top: 5px
}

.mg-r-5 {
    margin-right: 5px
}

.mg-b-5 {
    margin-bottom: 5px
}

.mg-l-5 {
    margin-left: 5px
}

.mg-t-5-f {
    margin-top: 5px !important
}

.mg-r-5-f {
    margin-right: 5px !important
}

.mg-b-5-f {
    margin-bottom: 5px !important
}

.mg-l-5-f {
    margin-left: 5px !important
}

.mg-6 {
    margin: 6px
}

.mg-6-f {
    margin: 6px !important
}

.mg-y-6 {
    margin-top: 6px;
    margin-bottom: 6px
}

.mg-y-6-f {
    margin-top: 6px !important;
    margin-bottom: 6px !important
}

.mg-x-6 {
    margin-left: 6px;
    margin-right: 6px
}

.mg-x-6-f {
    margin-left: 6px !important;
    margin-right: 6px !important
}

.mg-t-6 {
    margin-top: 6px
}

.mg-r-6 {
    margin-right: 6px
}

.mg-b-6 {
    margin-bottom: 6px
}

.mg-l-6 {
    margin-left: 6px
}

.mg-t-6-f {
    margin-top: 6px !important
}

.mg-r-6-f {
    margin-right: 6px !important
}

.mg-b-6-f {
    margin-bottom: 6px !important
}

.mg-l-6-f {
    margin-left: 6px !important
}

.mg-7 {
    margin: 7px
}

.mg-7-f {
    margin: 7px !important
}

.mg-y-7 {
    margin-top: 7px;
    margin-bottom: 7px
}

.mg-y-7-f {
    margin-top: 7px !important;
    margin-bottom: 7px !important
}

.mg-x-7 {
    margin-left: 7px;
    margin-right: 7px
}

.mg-x-7-f {
    margin-left: 7px !important;
    margin-right: 7px !important
}

.mg-t-7 {
    margin-top: 7px
}

.mg-r-7 {
    margin-right: 7px
}

.mg-b-7 {
    margin-bottom: 7px
}

.mg-l-7 {
    margin-left: 7px
}

.mg-t-7-f {
    margin-top: 7px !important
}

.mg-r-7-f {
    margin-right: 7px !important
}

.mg-b-7-f {
    margin-bottom: 7px !important
}

.mg-l-7-f {
    margin-left: 7px !important
}

.mg-8 {
    margin: 8px
}

.mg-8-f {
    margin: 8px !important
}

.mg-y-8 {
    margin-top: 8px;
    margin-bottom: 8px
}

.mg-y-8-f {
    margin-top: 8px !important;
    margin-bottom: 8px !important
}

.mg-x-8 {
    margin-left: 8px;
    margin-right: 8px
}

.mg-x-8-f {
    margin-left: 8px !important;
    margin-right: 8px !important
}

.mg-t-8 {
    margin-top: 8px
}

.mg-r-8 {
    margin-right: 8px
}

.mg-b-8 {
    margin-bottom: 8px
}

.mg-l-8 {
    margin-left: 8px
}

.mg-t-8-f {
    margin-top: 8px !important
}

.mg-r-8-f {
    margin-right: 8px !important
}

.mg-b-8-f {
    margin-bottom: 8px !important
}

.mg-l-8-f {
    margin-left: 8px !important
}

.mg-9 {
    margin: 9px
}

.mg-9-f {
    margin: 9px !important
}

.mg-y-9 {
    margin-top: 9px;
    margin-bottom: 9px
}

.mg-y-9-f {
    margin-top: 9px !important;
    margin-bottom: 9px !important
}

.mg-x-9 {
    margin-left: 9px;
    margin-right: 9px
}

.mg-x-9-f {
    margin-left: 9px !important;
    margin-right: 9px !important
}

.mg-t-9 {
    margin-top: 9px
}

.mg-r-9 {
    margin-right: 9px
}

.mg-b-9 {
    margin-bottom: 9px
}

.mg-l-9 {
    margin-left: 9px
}

.mg-t-9-f {
    margin-top: 9px !important
}

.mg-r-9-f {
    margin-right: 9px !important
}

.mg-b-9-f {
    margin-bottom: 9px !important
}

.mg-l-9-f {
    margin-left: 9px !important
}

.mg-10 {
    margin: 10px
}

.mg-10-f {
    margin: 10px !important
}

.mg-y-10 {
    margin-top: 10px;
    margin-bottom: 10px
}

.mg-y-10-f {
    margin-top: 10px !important;
    margin-bottom: 10px !important
}

.mg-x-10 {
    margin-left: 10px;
    margin-right: 10px
}

.mg-x-10-f {
    margin-left: 10px !important;
    margin-right: 10px !important
}

.mg-t-10 {
    margin-top: 10px
}

.mg-r-10 {
    margin-right: 10px
}

.mg-b-10 {
    margin-bottom: 10px
}

.mg-l-10 {
    margin-left: 10px
}

.mg-t-10-f {
    margin-top: 10px !important
}

.mg-r-10-f {
    margin-right: 10px !important
}

.mg-b-10-f {
    margin-bottom: 10px !important
}

.mg-l-10-f {
    margin-left: 10px !important
}

.mg-15 {
    margin: 15px
}

.mg-15-f {
    margin: 15px !important
}

.mg-y-15 {
    margin-top: 15px;
    margin-bottom: 15px
}

.mg-y-15-f {
    margin-top: 15px !important;
    margin-bottom: 15px !important
}

.mg-x-15 {
    margin-left: 15px;
    margin-right: 15px
}

.mg-x-15-f {
    margin-left: 15px !important;
    margin-right: 15px !important
}

.mg-t-15 {
    margin-top: 15px
}

.mg-r-15 {
    margin-right: 15px
}

.mg-b-15 {
    margin-bottom: 15px
}

.mg-l-15 {
    margin-left: 15px
}

.mg-t-15-f {
    margin-top: 15px !important
}

.mg-r-15-f {
    margin-right: 15px !important
}

.mg-b-15-f {
    margin-bottom: 15px !important
}

.mg-l-15-f {
    margin-left: 15px !important
}

.mg-20 {
    margin: 20px
}

.mg-20-f {
    margin: 20px !important
}

.mg-y-20 {
    margin-top: 20px;
    margin-bottom: 20px
}

.mg-y-20-f {
    margin-top: 20px !important;
    margin-bottom: 20px !important
}

.mg-x-20 {
    margin-left: 20px;
    margin-right: 20px
}

.mg-x-20-f {
    margin-left: 20px !important;
    margin-right: 20px !important
}

.mg-t-20 {
    margin-top: 20px
}

.mg-r-20 {
    margin-right: 20px
}

.mg-b-20 {
    margin-bottom: 20px
}

.mg-l-20 {
    margin-left: 20px
}

.mg-t-20-f {
    margin-top: 20px !important
}

.mg-r-20-f {
    margin-right: 20px !important
}

.mg-b-20-f {
    margin-bottom: 20px !important
}

.mg-l-20-f {
    margin-left: 20px !important
}

.mg-25 {
    margin: 25px
}

.mg-25-f {
    margin: 25px !important
}

.mg-y-25 {
    margin-top: 25px;
    margin-bottom: 25px
}

.mg-y-25-f {
    margin-top: 25px !important;
    margin-bottom: 25px !important
}

.mg-x-25 {
    margin-left: 25px;
    margin-right: 25px
}

.mg-x-25-f {
    margin-left: 25px !important;
    margin-right: 25px !important
}

.mg-t-25 {
    margin-top: 25px
}

.mg-r-25 {
    margin-right: 25px
}

.mg-b-25 {
    margin-bottom: 25px
}

.mg-l-25 {
    margin-left: 25px
}

.mg-t-25-f {
    margin-top: 25px !important
}

.mg-r-25-f {
    margin-right: 25px !important
}

.mg-b-25-f {
    margin-bottom: 25px !important
}

.mg-l-25-f {
    margin-left: 25px !important
}

.mg-30 {
    margin: 30px
}

.mg-30-f {
    margin: 30px !important
}

.mg-y-30 {
    margin-top: 30px;
    margin-bottom: 30px
}

.mg-y-30-f {
    margin-top: 30px !important;
    margin-bottom: 30px !important
}

.mg-x-30 {
    margin-left: 30px;
    margin-right: 30px
}

.mg-x-30-f {
    margin-left: 30px !important;
    margin-right: 30px !important
}

.mg-t-30 {
    margin-top: 30px
}

.mg-r-30 {
    margin-right: 30px
}

.mg-b-30 {
    margin-bottom: 30px
}

.mg-l-30 {
    margin-left: 30px
}

.mg-t-30-f {
    margin-top: 30px !important
}

.mg-r-30-f {
    margin-right: 30px !important
}

.mg-b-30-f {
    margin-bottom: 30px !important
}

.mg-l-30-f {
    margin-left: 30px !important
}

.mg-35 {
    margin: 35px
}

.mg-35-f {
    margin: 35px !important
}

.mg-y-35 {
    margin-top: 35px;
    margin-bottom: 35px
}

.mg-y-35-f {
    margin-top: 35px !important;
    margin-bottom: 35px !important
}

.mg-x-35 {
    margin-left: 35px;
    margin-right: 35px
}

.mg-x-35-f {
    margin-left: 35px !important;
    margin-right: 35px !important
}

.mg-t-35 {
    margin-top: 35px
}

.mg-r-35 {
    margin-right: 35px
}

.mg-b-35 {
    margin-bottom: 35px
}

.mg-l-35 {
    margin-left: 35px
}

.mg-t-35-f {
    margin-top: 35px !important
}

.mg-r-35-f {
    margin-right: 35px !important
}

.mg-b-35-f {
    margin-bottom: 35px !important
}

.mg-l-35-f {
    margin-left: 35px !important
}

.mg-40 {
    margin: 40px
}

.mg-40-f {
    margin: 40px !important
}

.mg-y-40 {
    margin-top: 40px;
    margin-bottom: 40px
}

.mg-y-40-f {
    margin-top: 40px !important;
    margin-bottom: 40px !important
}

.mg-x-40 {
    margin-left: 40px;
    margin-right: 40px
}

.mg-x-40-f {
    margin-left: 40px !important;
    margin-right: 40px !important
}

.mg-t-40 {
    margin-top: 40px
}

.mg-r-40 {
    margin-right: 40px
}

.mg-b-40 {
    margin-bottom: 40px
}

.mg-l-40 {
    margin-left: 40px
}

.mg-t-40-f {
    margin-top: 40px !important
}

.mg-r-40-f {
    margin-right: 40px !important
}

.mg-b-40-f {
    margin-bottom: 40px !important
}

.mg-l-40-f {
    margin-left: 40px !important
}

.mg-45 {
    margin: 45px
}

.mg-45-f {
    margin: 45px !important
}

.mg-y-45 {
    margin-top: 45px;
    margin-bottom: 45px
}

.mg-y-45-f {
    margin-top: 45px !important;
    margin-bottom: 45px !important
}

.mg-x-45 {
    margin-left: 45px;
    margin-right: 45px
}

.mg-x-45-f {
    margin-left: 45px !important;
    margin-right: 45px !important
}

.mg-t-45 {
    margin-top: 45px
}

.mg-r-45 {
    margin-right: 45px
}

.mg-b-45 {
    margin-bottom: 45px
}

.mg-l-45 {
    margin-left: 45px
}

.mg-t-45-f {
    margin-top: 45px !important
}

.mg-r-45-f {
    margin-right: 45px !important
}

.mg-b-45-f {
    margin-bottom: 45px !important
}

.mg-l-45-f {
    margin-left: 45px !important
}

.mg-50 {
    margin: 50px
}

.mg-50-f {
    margin: 50px !important
}

.mg-y-50 {
    margin-top: 50px;
    margin-bottom: 50px
}

.mg-y-50-f {
    margin-top: 50px !important;
    margin-bottom: 50px !important
}

.mg-x-50 {
    margin-left: 50px;
    margin-right: 50px
}

.mg-x-50-f {
    margin-left: 50px !important;
    margin-right: 50px !important
}

.mg-t-50 {
    margin-top: 50px
}

.mg-r-50 {
    margin-right: 50px
}

.mg-b-50 {
    margin-bottom: 50px
}

.mg-l-50 {
    margin-left: 50px
}

.mg-t-50-f {
    margin-top: 50px !important
}

.mg-r-50-f {
    margin-right: 50px !important
}

.mg-b-50-f {
    margin-bottom: 50px !important
}

.mg-l-50-f {
    margin-left: 50px !important
}

.mg-55 {
    margin: 55px
}

.mg-55-f {
    margin: 55px !important
}

.mg-y-55 {
    margin-top: 55px;
    margin-bottom: 55px
}

.mg-y-55-f {
    margin-top: 55px !important;
    margin-bottom: 55px !important
}

.mg-x-55 {
    margin-left: 55px;
    margin-right: 55px
}

.mg-x-55-f {
    margin-left: 55px !important;
    margin-right: 55px !important
}

.mg-t-55 {
    margin-top: 55px
}

.mg-r-55 {
    margin-right: 55px
}

.mg-b-55 {
    margin-bottom: 55px
}

.mg-l-55 {
    margin-left: 55px
}

.mg-t-55-f {
    margin-top: 55px !important
}

.mg-r-55-f {
    margin-right: 55px !important
}

.mg-b-55-f {
    margin-bottom: 55px !important
}

.mg-l-55-f {
    margin-left: 55px !important
}

.mg-60 {
    margin: 60px
}

.mg-60-f {
    margin: 60px !important
}

.mg-y-60 {
    margin-top: 60px;
    margin-bottom: 60px
}

.mg-y-60-f {
    margin-top: 60px !important;
    margin-bottom: 60px !important
}

.mg-x-60 {
    margin-left: 60px;
    margin-right: 60px
}

.mg-x-60-f {
    margin-left: 60px !important;
    margin-right: 60px !important
}

.mg-t-60 {
    margin-top: 60px
}

.mg-r-60 {
    margin-right: 60px
}

.mg-b-60 {
    margin-bottom: 60px
}

.mg-l-60 {
    margin-left: 60px
}

.mg-t-60-f {
    margin-top: 60px !important
}

.mg-r-60-f {
    margin-right: 60px !important
}

.mg-b-60-f {
    margin-bottom: 60px !important
}

.mg-l-60-f {
    margin-left: 60px !important
}

.mg-65 {
    margin: 65px
}

.mg-65-f {
    margin: 65px !important
}

.mg-y-65 {
    margin-top: 65px;
    margin-bottom: 65px
}

.mg-y-65-f {
    margin-top: 65px !important;
    margin-bottom: 65px !important
}

.mg-x-65 {
    margin-left: 65px;
    margin-right: 65px
}

.mg-x-65-f {
    margin-left: 65px !important;
    margin-right: 65px !important
}

.mg-t-65 {
    margin-top: 65px
}

.mg-r-65 {
    margin-right: 65px
}

.mg-b-65 {
    margin-bottom: 65px
}

.mg-l-65 {
    margin-left: 65px
}

.mg-t-65-f {
    margin-top: 65px !important
}

.mg-r-65-f {
    margin-right: 65px !important
}

.mg-b-65-f {
    margin-bottom: 65px !important
}

.mg-l-65-f {
    margin-left: 65px !important
}

.mg-70 {
    margin: 70px
}

.mg-70-f {
    margin: 70px !important
}

.mg-y-70 {
    margin-top: 70px;
    margin-bottom: 70px
}

.mg-y-70-f {
    margin-top: 70px !important;
    margin-bottom: 70px !important
}

.mg-x-70 {
    margin-left: 70px;
    margin-right: 70px
}

.mg-x-70-f {
    margin-left: 70px !important;
    margin-right: 70px !important
}

.mg-t-70 {
    margin-top: 70px
}

.mg-r-70 {
    margin-right: 70px
}

.mg-b-70 {
    margin-bottom: 70px
}

.mg-l-70 {
    margin-left: 70px
}

.mg-t-70-f {
    margin-top: 70px !important
}

.mg-r-70-f {
    margin-right: 70px !important
}

.mg-b-70-f {
    margin-bottom: 70px !important
}

.mg-l-70-f {
    margin-left: 70px !important
}

.mg-75 {
    margin: 75px
}

.mg-75-f {
    margin: 75px !important
}

.mg-y-75 {
    margin-top: 75px;
    margin-bottom: 75px
}

.mg-y-75-f {
    margin-top: 75px !important;
    margin-bottom: 75px !important
}

.mg-x-75 {
    margin-left: 75px;
    margin-right: 75px
}

.mg-x-75-f {
    margin-left: 75px !important;
    margin-right: 75px !important
}

.mg-t-75 {
    margin-top: 75px
}

.mg-r-75 {
    margin-right: 75px
}

.mg-b-75 {
    margin-bottom: 75px
}

.mg-l-75 {
    margin-left: 75px
}

.mg-t-75-f {
    margin-top: 75px !important
}

.mg-r-75-f {
    margin-right: 75px !important
}

.mg-b-75-f {
    margin-bottom: 75px !important
}

.mg-l-75-f {
    margin-left: 75px !important
}

.mg-80 {
    margin: 80px
}

.mg-80-f {
    margin: 80px !important
}

.mg-y-80 {
    margin-top: 80px;
    margin-bottom: 80px
}

.mg-y-80-f {
    margin-top: 80px !important;
    margin-bottom: 80px !important
}

.mg-x-80 {
    margin-left: 80px;
    margin-right: 80px
}

.mg-x-80-f {
    margin-left: 80px !important;
    margin-right: 80px !important
}

.mg-t-80 {
    margin-top: 80px
}

.mg-r-80 {
    margin-right: 80px
}

.mg-b-80 {
    margin-bottom: 80px
}

.mg-l-80 {
    margin-left: 80px
}

.mg-t-80-f {
    margin-top: 80px !important
}

.mg-r-80-f {
    margin-right: 80px !important
}

.mg-b-80-f {
    margin-bottom: 80px !important
}

.mg-l-80-f {
    margin-left: 80px !important
}

.mg-85 {
    margin: 85px
}

.mg-85-f {
    margin: 85px !important
}

.mg-y-85 {
    margin-top: 85px;
    margin-bottom: 85px
}

.mg-y-85-f {
    margin-top: 85px !important;
    margin-bottom: 85px !important
}

.mg-x-85 {
    margin-left: 85px;
    margin-right: 85px
}

.mg-x-85-f {
    margin-left: 85px !important;
    margin-right: 85px !important
}

.mg-t-85 {
    margin-top: 85px
}

.mg-r-85 {
    margin-right: 85px
}

.mg-b-85 {
    margin-bottom: 85px
}

.mg-l-85 {
    margin-left: 85px
}

.mg-t-85-f {
    margin-top: 85px !important
}

.mg-r-85-f {
    margin-right: 85px !important
}

.mg-b-85-f {
    margin-bottom: 85px !important
}

.mg-l-85-f {
    margin-left: 85px !important
}

.mg-90 {
    margin: 90px
}

.mg-90-f {
    margin: 90px !important
}

.mg-y-90 {
    margin-top: 90px;
    margin-bottom: 90px
}

.mg-y-90-f {
    margin-top: 90px !important;
    margin-bottom: 90px !important
}

.mg-x-90 {
    margin-left: 90px;
    margin-right: 90px
}

.mg-x-90-f {
    margin-left: 90px !important;
    margin-right: 90px !important
}

.mg-t-90 {
    margin-top: 90px
}

.mg-r-90 {
    margin-right: 90px
}

.mg-b-90 {
    margin-bottom: 90px
}

.mg-l-90 {
    margin-left: 90px
}

.mg-t-90-f {
    margin-top: 90px !important
}

.mg-r-90-f {
    margin-right: 90px !important
}

.mg-b-90-f {
    margin-bottom: 90px !important
}

.mg-l-90-f {
    margin-left: 90px !important
}

.mg-95 {
    margin: 95px
}

.mg-95-f {
    margin: 95px !important
}

.mg-y-95 {
    margin-top: 95px;
    margin-bottom: 95px
}

.mg-y-95-f {
    margin-top: 95px !important;
    margin-bottom: 95px !important
}

.mg-x-95 {
    margin-left: 95px;
    margin-right: 95px
}

.mg-x-95-f {
    margin-left: 95px !important;
    margin-right: 95px !important
}

.mg-t-95 {
    margin-top: 95px
}

.mg-r-95 {
    margin-right: 95px
}

.mg-b-95 {
    margin-bottom: 95px
}

.mg-l-95 {
    margin-left: 95px
}

.mg-t-95-f {
    margin-top: 95px !important
}

.mg-r-95-f {
    margin-right: 95px !important
}

.mg-b-95-f {
    margin-bottom: 95px !important
}

.mg-l-95-f {
    margin-left: 95px !important
}

.mg-100 {
    margin: 100px
}

.mg-100-f {
    margin: 100px !important
}

.mg-y-100 {
    margin-top: 100px;
    margin-bottom: 100px
}

.mg-y-100-f {
    margin-top: 100px !important;
    margin-bottom: 100px !important
}

.mg-x-100 {
    margin-left: 100px;
    margin-right: 100px
}

.mg-x-100-f {
    margin-left: 100px !important;
    margin-right: 100px !important
}

.mg-t-100 {
    margin-top: 100px
}

.mg-r-100 {
    margin-right: 100px
}

.mg-b-100 {
    margin-bottom: 100px
}

.mg-l-100 {
    margin-left: 100px
}

.mg-t-100-f {
    margin-top: 100px !important
}

.mg-r-100-f {
    margin-right: 100px !important
}

.mg-b-100-f {
    margin-bottom: 100px !important
}

.mg-l-100-f {
    margin-left: 100px !important
}

.mg-150 {
    margin: 150px
}

.mg-150-f {
    margin: 150px !important
}

.mg-y-150 {
    margin-top: 150px;
    margin-bottom: 150px
}

.mg-y-150-f {
    margin-top: 150px !important;
    margin-bottom: 150px !important
}

.mg-x-150 {
    margin-left: 150px;
    margin-right: 150px
}

.mg-x-150-f {
    margin-left: 150px !important;
    margin-right: 150px !important
}

.mg-t-150 {
    margin-top: 150px
}

.mg-r-150 {
    margin-right: 150px
}

.mg-b-150 {
    margin-bottom: 150px
}

.mg-l-150 {
    margin-left: 150px
}

.mg-t-150-f {
    margin-top: 150px !important
}

.mg-r-150-f {
    margin-right: 150px !important
}

.mg-b-150-f {
    margin-bottom: 150px !important
}

.mg-l-150-f {
    margin-left: 150px !important
}

.mg-200 {
    margin: 200px
}

.mg-200-f {
    margin: 200px !important
}

.mg-y-200 {
    margin-top: 200px;
    margin-bottom: 200px
}

.mg-y-200-f {
    margin-top: 200px !important;
    margin-bottom: 200px !important
}

.mg-x-200 {
    margin-left: 200px;
    margin-right: 200px
}

.mg-x-200-f {
    margin-left: 200px !important;
    margin-right: 200px !important
}

.mg-t-200 {
    margin-top: 200px
}

.mg-r-200 {
    margin-right: 200px
}

.mg-b-200 {
    margin-bottom: 200px
}

.mg-l-200 {
    margin-left: 200px
}

.mg-t-200-f {
    margin-top: 200px !important
}

.mg-r-200-f {
    margin-right: 200px !important
}

.mg-b-200-f {
    margin-bottom: 200px !important
}

.mg-l-200-f {
    margin-left: 200px !important
}

.mg-250 {
    margin: 250px
}

.mg-250-f {
    margin: 250px !important
}

.mg-y-250 {
    margin-top: 250px;
    margin-bottom: 250px
}

.mg-y-250-f {
    margin-top: 250px !important;
    margin-bottom: 250px !important
}

.mg-x-250 {
    margin-left: 250px;
    margin-right: 250px
}

.mg-x-250-f {
    margin-left: 250px !important;
    margin-right: 250px !important
}

.mg-t-250 {
    margin-top: 250px
}

.mg-r-250 {
    margin-right: 250px
}

.mg-b-250 {
    margin-bottom: 250px
}

.mg-l-250 {
    margin-left: 250px
}

.mg-t-250-f {
    margin-top: 250px !important
}

.mg-r-250-f {
    margin-right: 250px !important
}

.mg-b-250-f {
    margin-bottom: 250px !important
}

.mg-l-250-f {
    margin-left: 250px !important
}

.mg-300 {
    margin: 300px
}

.mg-300-f {
    margin: 300px !important
}

.mg-y-300 {
    margin-top: 300px;
    margin-bottom: 300px
}

.mg-y-300-f {
    margin-top: 300px !important;
    margin-bottom: 300px !important
}

.mg-x-300 {
    margin-left: 300px;
    margin-right: 300px
}

.mg-x-300-f {
    margin-left: 300px !important;
    margin-right: 300px !important
}

.mg-t-300 {
    margin-top: 300px
}

.mg-r-300 {
    margin-right: 300px
}

.mg-b-300 {
    margin-bottom: 300px
}

.mg-l-300 {
    margin-left: 300px
}

.mg-t-300-f {
    margin-top: 300px !important
}

.mg-r-300-f {
    margin-right: 300px !important
}

.mg-b-300-f {
    margin-bottom: 300px !important
}

.mg-l-300-f {
    margin-left: 300px !important
}

.mg-350 {
    margin: 350px
}

.mg-350-f {
    margin: 350px !important
}

.mg-y-350 {
    margin-top: 350px;
    margin-bottom: 350px
}

.mg-y-350-f {
    margin-top: 350px !important;
    margin-bottom: 350px !important
}

.mg-x-350 {
    margin-left: 350px;
    margin-right: 350px
}

.mg-x-350-f {
    margin-left: 350px !important;
    margin-right: 350px !important
}

.mg-t-350 {
    margin-top: 350px
}

.mg-r-350 {
    margin-right: 350px
}

.mg-b-350 {
    margin-bottom: 350px
}

.mg-l-350 {
    margin-left: 350px
}

.mg-t-350-f {
    margin-top: 350px !important
}

.mg-r-350-f {
    margin-right: 350px !important
}

.mg-b-350-f {
    margin-bottom: 350px !important
}

.mg-l-350-f {
    margin-left: 350px !important
}

.mg-400 {
    margin: 400px
}

.mg-400-f {
    margin: 400px !important
}

.mg-y-400 {
    margin-top: 400px;
    margin-bottom: 400px
}

.mg-y-400-f {
    margin-top: 400px !important;
    margin-bottom: 400px !important
}

.mg-x-400 {
    margin-left: 400px;
    margin-right: 400px
}

.mg-x-400-f {
    margin-left: 400px !important;
    margin-right: 400px !important
}

.mg-t-400 {
    margin-top: 400px
}

.mg-r-400 {
    margin-right: 400px
}

.mg-b-400 {
    margin-bottom: 400px
}

.mg-l-400 {
    margin-left: 400px
}

.mg-t-400-f {
    margin-top: 400px !important
}

.mg-r-400-f {
    margin-right: 400px !important
}

.mg-b-400-f {
    margin-bottom: 400px !important
}

.mg-l-400-f {
    margin-left: 400px !important
}

.mg-450 {
    margin: 450px
}

.mg-450-f {
    margin: 450px !important
}

.mg-y-450 {
    margin-top: 450px;
    margin-bottom: 450px
}

.mg-y-450-f {
    margin-top: 450px !important;
    margin-bottom: 450px !important
}

.mg-x-450 {
    margin-left: 450px;
    margin-right: 450px
}

.mg-x-450-f {
    margin-left: 450px !important;
    margin-right: 450px !important
}

.mg-t-450 {
    margin-top: 450px
}

.mg-r-450 {
    margin-right: 450px
}

.mg-b-450 {
    margin-bottom: 450px
}

.mg-l-450 {
    margin-left: 450px
}

.mg-t-450-f {
    margin-top: 450px !important
}

.mg-r-450-f {
    margin-right: 450px !important
}

.mg-b-450-f {
    margin-bottom: 450px !important
}

.mg-l-450-f {
    margin-left: 450px !important
}

.mg-500 {
    margin: 500px
}

.mg-500-f {
    margin: 500px !important
}

.mg-y-500 {
    margin-top: 500px;
    margin-bottom: 500px
}

.mg-y-500-f {
    margin-top: 500px !important;
    margin-bottom: 500px !important
}

.mg-x-500 {
    margin-left: 500px;
    margin-right: 500px
}

.mg-x-500-f {
    margin-left: 500px !important;
    margin-right: 500px !important
}

.mg-t-500 {
    margin-top: 500px
}

.mg-r-500 {
    margin-right: 500px
}

.mg-b-500 {
    margin-bottom: 500px
}

.mg-l-500 {
    margin-left: 500px
}

.mg-t-500-f {
    margin-top: 500px !important
}

.mg-r-500-f {
    margin-right: 500px !important
}

.mg-b-500-f {
    margin-bottom: 500px !important
}

.mg-l-500-f {
    margin-left: 500px !important
}

.mg-550 {
    margin: 550px
}

.mg-550-f {
    margin: 550px !important
}

.mg-y-550 {
    margin-top: 550px;
    margin-bottom: 550px
}

.mg-y-550-f {
    margin-top: 550px !important;
    margin-bottom: 550px !important
}

.mg-x-550 {
    margin-left: 550px;
    margin-right: 550px
}

.mg-x-550-f {
    margin-left: 550px !important;
    margin-right: 550px !important
}

.mg-t-550 {
    margin-top: 550px
}

.mg-r-550 {
    margin-right: 550px
}

.mg-b-550 {
    margin-bottom: 550px
}

.mg-l-550 {
    margin-left: 550px
}

.mg-t-550-f {
    margin-top: 550px !important
}

.mg-r-550-f {
    margin-right: 550px !important
}

.mg-b-550-f {
    margin-bottom: 550px !important
}

.mg-l-550-f {
    margin-left: 550px !important
}

.mg-600 {
    margin: 600px
}

.mg-600-f {
    margin: 600px !important
}

.mg-y-600 {
    margin-top: 600px;
    margin-bottom: 600px
}

.mg-y-600-f {
    margin-top: 600px !important;
    margin-bottom: 600px !important
}

.mg-x-600 {
    margin-left: 600px;
    margin-right: 600px
}

.mg-x-600-f {
    margin-left: 600px !important;
    margin-right: 600px !important
}

.mg-t-600 {
    margin-top: 600px
}

.mg-r-600 {
    margin-right: 600px
}

.mg-b-600 {
    margin-bottom: 600px
}

.mg-l-600 {
    margin-left: 600px
}

.mg-t-600-f {
    margin-top: 600px !important
}

.mg-r-600-f {
    margin-right: 600px !important
}

.mg-b-600-f {
    margin-bottom: 600px !important
}

.mg-l-600-f {
    margin-left: 600px !important
}

.mg-650 {
    margin: 650px
}

.mg-650-f {
    margin: 650px !important
}

.mg-y-650 {
    margin-top: 650px;
    margin-bottom: 650px
}

.mg-y-650-f {
    margin-top: 650px !important;
    margin-bottom: 650px !important
}

.mg-x-650 {
    margin-left: 650px;
    margin-right: 650px
}

.mg-x-650-f {
    margin-left: 650px !important;
    margin-right: 650px !important
}

.mg-t-650 {
    margin-top: 650px
}

.mg-r-650 {
    margin-right: 650px
}

.mg-b-650 {
    margin-bottom: 650px
}

.mg-l-650 {
    margin-left: 650px
}

.mg-t-650-f {
    margin-top: 650px !important
}

.mg-r-650-f {
    margin-right: 650px !important
}

.mg-b-650-f {
    margin-bottom: 650px !important
}

.mg-l-650-f {
    margin-left: 650px !important
}

.mg-t-auto {
    margin-top: auto
}

.mg-r-auto {
    margin-right: auto
}

.mg-b-auto {
    margin-bottom: auto
}

.mg-l-auto {
    margin-left: auto
}

.mg-x-auto {
    margin: auto
}

@media (min-width: 480px) {
    .mg-xs-0 {
        margin: 0px
    }

    .mg-xs-0-f {
        margin: 0px !important
    }

    .mg-xs-y-0 {
        margin-top: 0px;
        margin-bottom: 0px
    }

    .mg-xs-y-0-f {
        margin-top: 0px !important;
        margin-bottom: 0px !important
    }

    .mg-xs-x-0 {
        margin-left: 0px;
        margin-right: 0px
    }

    .mg-xs-x-0-f {
        margin-left: 0px !important;
        margin-right: 0px !important
    }

    .mg-xs-t-0 {
        margin-top: 0px
    }

    .mg-xs-r-0 {
        margin-right: 0px
    }

    .mg-xs-b-0 {
        margin-bottom: 0px
    }

    .mg-xs-l-0 {
        margin-left: 0px
    }

    .mg-xs-t-0-f {
        margin-top: 0px !important
    }

    .mg-xs-r-0-f {
        margin-right: 0px !important
    }

    .mg-xs-b-0-f {
        margin-bottom: 0px !important
    }

    .mg-xs-l-0-f {
        margin-left: 0px !important
    }

    .mg-xs-1 {
        margin: 1px
    }

    .mg-xs-1-f {
        margin: 1px !important
    }

    .mg-xs-y-1 {
        margin-top: 1px;
        margin-bottom: 1px
    }

    .mg-xs-y-1-f {
        margin-top: 1px !important;
        margin-bottom: 1px !important
    }

    .mg-xs-x-1 {
        margin-left: 1px;
        margin-right: 1px
    }

    .mg-xs-x-1-f {
        margin-left: 1px !important;
        margin-right: 1px !important
    }

    .mg-xs-t-1 {
        margin-top: 1px
    }

    .mg-xs-r-1 {
        margin-right: 1px
    }

    .mg-xs-b-1 {
        margin-bottom: 1px
    }

    .mg-xs-l-1 {
        margin-left: 1px
    }

    .mg-xs-t-1-f {
        margin-top: 1px !important
    }

    .mg-xs-r-1-f {
        margin-right: 1px !important
    }

    .mg-xs-b-1-f {
        margin-bottom: 1px !important
    }

    .mg-xs-l-1-f {
        margin-left: 1px !important
    }

    .mg-xs-2 {
        margin: 2px
    }

    .mg-xs-2-f {
        margin: 2px !important
    }

    .mg-xs-y-2 {
        margin-top: 2px;
        margin-bottom: 2px
    }

    .mg-xs-y-2-f {
        margin-top: 2px !important;
        margin-bottom: 2px !important
    }

    .mg-xs-x-2 {
        margin-left: 2px;
        margin-right: 2px
    }

    .mg-xs-x-2-f {
        margin-left: 2px !important;
        margin-right: 2px !important
    }

    .mg-xs-t-2 {
        margin-top: 2px
    }

    .mg-xs-r-2 {
        margin-right: 2px
    }

    .mg-xs-b-2 {
        margin-bottom: 2px
    }

    .mg-xs-l-2 {
        margin-left: 2px
    }

    .mg-xs-t-2-f {
        margin-top: 2px !important
    }

    .mg-xs-r-2-f {
        margin-right: 2px !important
    }

    .mg-xs-b-2-f {
        margin-bottom: 2px !important
    }

    .mg-xs-l-2-f {
        margin-left: 2px !important
    }

    .mg-xs-3 {
        margin: 3px
    }

    .mg-xs-3-f {
        margin: 3px !important
    }

    .mg-xs-y-3 {
        margin-top: 3px;
        margin-bottom: 3px
    }

    .mg-xs-y-3-f {
        margin-top: 3px !important;
        margin-bottom: 3px !important
    }

    .mg-xs-x-3 {
        margin-left: 3px;
        margin-right: 3px
    }

    .mg-xs-x-3-f {
        margin-left: 3px !important;
        margin-right: 3px !important
    }

    .mg-xs-t-3 {
        margin-top: 3px
    }

    .mg-xs-r-3 {
        margin-right: 3px
    }

    .mg-xs-b-3 {
        margin-bottom: 3px
    }

    .mg-xs-l-3 {
        margin-left: 3px
    }

    .mg-xs-t-3-f {
        margin-top: 3px !important
    }

    .mg-xs-r-3-f {
        margin-right: 3px !important
    }

    .mg-xs-b-3-f {
        margin-bottom: 3px !important
    }

    .mg-xs-l-3-f {
        margin-left: 3px !important
    }

    .mg-xs-4 {
        margin: 4px
    }

    .mg-xs-4-f {
        margin: 4px !important
    }

    .mg-xs-y-4 {
        margin-top: 4px;
        margin-bottom: 4px
    }

    .mg-xs-y-4-f {
        margin-top: 4px !important;
        margin-bottom: 4px !important
    }

    .mg-xs-x-4 {
        margin-left: 4px;
        margin-right: 4px
    }

    .mg-xs-x-4-f {
        margin-left: 4px !important;
        margin-right: 4px !important
    }

    .mg-xs-t-4 {
        margin-top: 4px
    }

    .mg-xs-r-4 {
        margin-right: 4px
    }

    .mg-xs-b-4 {
        margin-bottom: 4px
    }

    .mg-xs-l-4 {
        margin-left: 4px
    }

    .mg-xs-t-4-f {
        margin-top: 4px !important
    }

    .mg-xs-r-4-f {
        margin-right: 4px !important
    }

    .mg-xs-b-4-f {
        margin-bottom: 4px !important
    }

    .mg-xs-l-4-f {
        margin-left: 4px !important
    }

    .mg-xs-5 {
        margin: 5px
    }

    .mg-xs-5-f {
        margin: 5px !important
    }

    .mg-xs-y-5 {
        margin-top: 5px;
        margin-bottom: 5px
    }

    .mg-xs-y-5-f {
        margin-top: 5px !important;
        margin-bottom: 5px !important
    }

    .mg-xs-x-5 {
        margin-left: 5px;
        margin-right: 5px
    }

    .mg-xs-x-5-f {
        margin-left: 5px !important;
        margin-right: 5px !important
    }

    .mg-xs-t-5 {
        margin-top: 5px
    }

    .mg-xs-r-5 {
        margin-right: 5px
    }

    .mg-xs-b-5 {
        margin-bottom: 5px
    }

    .mg-xs-l-5 {
        margin-left: 5px
    }

    .mg-xs-t-5-f {
        margin-top: 5px !important
    }

    .mg-xs-r-5-f {
        margin-right: 5px !important
    }

    .mg-xs-b-5-f {
        margin-bottom: 5px !important
    }

    .mg-xs-l-5-f {
        margin-left: 5px !important
    }

    .mg-xs-6 {
        margin: 6px
    }

    .mg-xs-6-f {
        margin: 6px !important
    }

    .mg-xs-y-6 {
        margin-top: 6px;
        margin-bottom: 6px
    }

    .mg-xs-y-6-f {
        margin-top: 6px !important;
        margin-bottom: 6px !important
    }

    .mg-xs-x-6 {
        margin-left: 6px;
        margin-right: 6px
    }

    .mg-xs-x-6-f {
        margin-left: 6px !important;
        margin-right: 6px !important
    }

    .mg-xs-t-6 {
        margin-top: 6px
    }

    .mg-xs-r-6 {
        margin-right: 6px
    }

    .mg-xs-b-6 {
        margin-bottom: 6px
    }

    .mg-xs-l-6 {
        margin-left: 6px
    }

    .mg-xs-t-6-f {
        margin-top: 6px !important
    }

    .mg-xs-r-6-f {
        margin-right: 6px !important
    }

    .mg-xs-b-6-f {
        margin-bottom: 6px !important
    }

    .mg-xs-l-6-f {
        margin-left: 6px !important
    }

    .mg-xs-7 {
        margin: 7px
    }

    .mg-xs-7-f {
        margin: 7px !important
    }

    .mg-xs-y-7 {
        margin-top: 7px;
        margin-bottom: 7px
    }

    .mg-xs-y-7-f {
        margin-top: 7px !important;
        margin-bottom: 7px !important
    }

    .mg-xs-x-7 {
        margin-left: 7px;
        margin-right: 7px
    }

    .mg-xs-x-7-f {
        margin-left: 7px !important;
        margin-right: 7px !important
    }

    .mg-xs-t-7 {
        margin-top: 7px
    }

    .mg-xs-r-7 {
        margin-right: 7px
    }

    .mg-xs-b-7 {
        margin-bottom: 7px
    }

    .mg-xs-l-7 {
        margin-left: 7px
    }

    .mg-xs-t-7-f {
        margin-top: 7px !important
    }

    .mg-xs-r-7-f {
        margin-right: 7px !important
    }

    .mg-xs-b-7-f {
        margin-bottom: 7px !important
    }

    .mg-xs-l-7-f {
        margin-left: 7px !important
    }

    .mg-xs-8 {
        margin: 8px
    }

    .mg-xs-8-f {
        margin: 8px !important
    }

    .mg-xs-y-8 {
        margin-top: 8px;
        margin-bottom: 8px
    }

    .mg-xs-y-8-f {
        margin-top: 8px !important;
        margin-bottom: 8px !important
    }

    .mg-xs-x-8 {
        margin-left: 8px;
        margin-right: 8px
    }

    .mg-xs-x-8-f {
        margin-left: 8px !important;
        margin-right: 8px !important
    }

    .mg-xs-t-8 {
        margin-top: 8px
    }

    .mg-xs-r-8 {
        margin-right: 8px
    }

    .mg-xs-b-8 {
        margin-bottom: 8px
    }

    .mg-xs-l-8 {
        margin-left: 8px
    }

    .mg-xs-t-8-f {
        margin-top: 8px !important
    }

    .mg-xs-r-8-f {
        margin-right: 8px !important
    }

    .mg-xs-b-8-f {
        margin-bottom: 8px !important
    }

    .mg-xs-l-8-f {
        margin-left: 8px !important
    }

    .mg-xs-9 {
        margin: 9px
    }

    .mg-xs-9-f {
        margin: 9px !important
    }

    .mg-xs-y-9 {
        margin-top: 9px;
        margin-bottom: 9px
    }

    .mg-xs-y-9-f {
        margin-top: 9px !important;
        margin-bottom: 9px !important
    }

    .mg-xs-x-9 {
        margin-left: 9px;
        margin-right: 9px
    }

    .mg-xs-x-9-f {
        margin-left: 9px !important;
        margin-right: 9px !important
    }

    .mg-xs-t-9 {
        margin-top: 9px
    }

    .mg-xs-r-9 {
        margin-right: 9px
    }

    .mg-xs-b-9 {
        margin-bottom: 9px
    }

    .mg-xs-l-9 {
        margin-left: 9px
    }

    .mg-xs-t-9-f {
        margin-top: 9px !important
    }

    .mg-xs-r-9-f {
        margin-right: 9px !important
    }

    .mg-xs-b-9-f {
        margin-bottom: 9px !important
    }

    .mg-xs-l-9-f {
        margin-left: 9px !important
    }

    .mg-xs-10 {
        margin: 10px
    }

    .mg-xs-10-f {
        margin: 10px !important
    }

    .mg-xs-y-10 {
        margin-top: 10px;
        margin-bottom: 10px
    }

    .mg-xs-y-10-f {
        margin-top: 10px !important;
        margin-bottom: 10px !important
    }

    .mg-xs-x-10 {
        margin-left: 10px;
        margin-right: 10px
    }

    .mg-xs-x-10-f {
        margin-left: 10px !important;
        margin-right: 10px !important
    }

    .mg-xs-t-10 {
        margin-top: 10px
    }

    .mg-xs-r-10 {
        margin-right: 10px
    }

    .mg-xs-b-10 {
        margin-bottom: 10px
    }

    .mg-xs-l-10 {
        margin-left: 10px
    }

    .mg-xs-t-10-f {
        margin-top: 10px !important
    }

    .mg-xs-r-10-f {
        margin-right: 10px !important
    }

    .mg-xs-b-10-f {
        margin-bottom: 10px !important
    }

    .mg-xs-l-10-f {
        margin-left: 10px !important
    }

    .mg-xs-15 {
        margin: 15px
    }

    .mg-xs-15-f {
        margin: 15px !important
    }

    .mg-xs-y-15 {
        margin-top: 15px;
        margin-bottom: 15px
    }

    .mg-xs-y-15-f {
        margin-top: 15px !important;
        margin-bottom: 15px !important
    }

    .mg-xs-x-15 {
        margin-left: 15px;
        margin-right: 15px
    }

    .mg-xs-x-15-f {
        margin-left: 15px !important;
        margin-right: 15px !important
    }

    .mg-xs-t-15 {
        margin-top: 15px
    }

    .mg-xs-r-15 {
        margin-right: 15px
    }

    .mg-xs-b-15 {
        margin-bottom: 15px
    }

    .mg-xs-l-15 {
        margin-left: 15px
    }

    .mg-xs-t-15-f {
        margin-top: 15px !important
    }

    .mg-xs-r-15-f {
        margin-right: 15px !important
    }

    .mg-xs-b-15-f {
        margin-bottom: 15px !important
    }

    .mg-xs-l-15-f {
        margin-left: 15px !important
    }

    .mg-xs-20 {
        margin: 20px
    }

    .mg-xs-20-f {
        margin: 20px !important
    }

    .mg-xs-y-20 {
        margin-top: 20px;
        margin-bottom: 20px
    }

    .mg-xs-y-20-f {
        margin-top: 20px !important;
        margin-bottom: 20px !important
    }

    .mg-xs-x-20 {
        margin-left: 20px;
        margin-right: 20px
    }

    .mg-xs-x-20-f {
        margin-left: 20px !important;
        margin-right: 20px !important
    }

    .mg-xs-t-20 {
        margin-top: 20px
    }

    .mg-xs-r-20 {
        margin-right: 20px
    }

    .mg-xs-b-20 {
        margin-bottom: 20px
    }

    .mg-xs-l-20 {
        margin-left: 20px
    }

    .mg-xs-t-20-f {
        margin-top: 20px !important
    }

    .mg-xs-r-20-f {
        margin-right: 20px !important
    }

    .mg-xs-b-20-f {
        margin-bottom: 20px !important
    }

    .mg-xs-l-20-f {
        margin-left: 20px !important
    }

    .mg-xs-25 {
        margin: 25px
    }

    .mg-xs-25-f {
        margin: 25px !important
    }

    .mg-xs-y-25 {
        margin-top: 25px;
        margin-bottom: 25px
    }

    .mg-xs-y-25-f {
        margin-top: 25px !important;
        margin-bottom: 25px !important
    }

    .mg-xs-x-25 {
        margin-left: 25px;
        margin-right: 25px
    }

    .mg-xs-x-25-f {
        margin-left: 25px !important;
        margin-right: 25px !important
    }

    .mg-xs-t-25 {
        margin-top: 25px
    }

    .mg-xs-r-25 {
        margin-right: 25px
    }

    .mg-xs-b-25 {
        margin-bottom: 25px
    }

    .mg-xs-l-25 {
        margin-left: 25px
    }

    .mg-xs-t-25-f {
        margin-top: 25px !important
    }

    .mg-xs-r-25-f {
        margin-right: 25px !important
    }

    .mg-xs-b-25-f {
        margin-bottom: 25px !important
    }

    .mg-xs-l-25-f {
        margin-left: 25px !important
    }

    .mg-xs-30 {
        margin: 30px
    }

    .mg-xs-30-f {
        margin: 30px !important
    }

    .mg-xs-y-30 {
        margin-top: 30px;
        margin-bottom: 30px
    }

    .mg-xs-y-30-f {
        margin-top: 30px !important;
        margin-bottom: 30px !important
    }

    .mg-xs-x-30 {
        margin-left: 30px;
        margin-right: 30px
    }

    .mg-xs-x-30-f {
        margin-left: 30px !important;
        margin-right: 30px !important
    }

    .mg-xs-t-30 {
        margin-top: 30px
    }

    .mg-xs-r-30 {
        margin-right: 30px
    }

    .mg-xs-b-30 {
        margin-bottom: 30px
    }

    .mg-xs-l-30 {
        margin-left: 30px
    }

    .mg-xs-t-30-f {
        margin-top: 30px !important
    }

    .mg-xs-r-30-f {
        margin-right: 30px !important
    }

    .mg-xs-b-30-f {
        margin-bottom: 30px !important
    }

    .mg-xs-l-30-f {
        margin-left: 30px !important
    }

    .mg-xs-35 {
        margin: 35px
    }

    .mg-xs-35-f {
        margin: 35px !important
    }

    .mg-xs-y-35 {
        margin-top: 35px;
        margin-bottom: 35px
    }

    .mg-xs-y-35-f {
        margin-top: 35px !important;
        margin-bottom: 35px !important
    }

    .mg-xs-x-35 {
        margin-left: 35px;
        margin-right: 35px
    }

    .mg-xs-x-35-f {
        margin-left: 35px !important;
        margin-right: 35px !important
    }

    .mg-xs-t-35 {
        margin-top: 35px
    }

    .mg-xs-r-35 {
        margin-right: 35px
    }

    .mg-xs-b-35 {
        margin-bottom: 35px
    }

    .mg-xs-l-35 {
        margin-left: 35px
    }

    .mg-xs-t-35-f {
        margin-top: 35px !important
    }

    .mg-xs-r-35-f {
        margin-right: 35px !important
    }

    .mg-xs-b-35-f {
        margin-bottom: 35px !important
    }

    .mg-xs-l-35-f {
        margin-left: 35px !important
    }

    .mg-xs-40 {
        margin: 40px
    }

    .mg-xs-40-f {
        margin: 40px !important
    }

    .mg-xs-y-40 {
        margin-top: 40px;
        margin-bottom: 40px
    }

    .mg-xs-y-40-f {
        margin-top: 40px !important;
        margin-bottom: 40px !important
    }

    .mg-xs-x-40 {
        margin-left: 40px;
        margin-right: 40px
    }

    .mg-xs-x-40-f {
        margin-left: 40px !important;
        margin-right: 40px !important
    }

    .mg-xs-t-40 {
        margin-top: 40px
    }

    .mg-xs-r-40 {
        margin-right: 40px
    }

    .mg-xs-b-40 {
        margin-bottom: 40px
    }

    .mg-xs-l-40 {
        margin-left: 40px
    }

    .mg-xs-t-40-f {
        margin-top: 40px !important
    }

    .mg-xs-r-40-f {
        margin-right: 40px !important
    }

    .mg-xs-b-40-f {
        margin-bottom: 40px !important
    }

    .mg-xs-l-40-f {
        margin-left: 40px !important
    }

    .mg-xs-45 {
        margin: 45px
    }

    .mg-xs-45-f {
        margin: 45px !important
    }

    .mg-xs-y-45 {
        margin-top: 45px;
        margin-bottom: 45px
    }

    .mg-xs-y-45-f {
        margin-top: 45px !important;
        margin-bottom: 45px !important
    }

    .mg-xs-x-45 {
        margin-left: 45px;
        margin-right: 45px
    }

    .mg-xs-x-45-f {
        margin-left: 45px !important;
        margin-right: 45px !important
    }

    .mg-xs-t-45 {
        margin-top: 45px
    }

    .mg-xs-r-45 {
        margin-right: 45px
    }

    .mg-xs-b-45 {
        margin-bottom: 45px
    }

    .mg-xs-l-45 {
        margin-left: 45px
    }

    .mg-xs-t-45-f {
        margin-top: 45px !important
    }

    .mg-xs-r-45-f {
        margin-right: 45px !important
    }

    .mg-xs-b-45-f {
        margin-bottom: 45px !important
    }

    .mg-xs-l-45-f {
        margin-left: 45px !important
    }

    .mg-xs-50 {
        margin: 50px
    }

    .mg-xs-50-f {
        margin: 50px !important
    }

    .mg-xs-y-50 {
        margin-top: 50px;
        margin-bottom: 50px
    }

    .mg-xs-y-50-f {
        margin-top: 50px !important;
        margin-bottom: 50px !important
    }

    .mg-xs-x-50 {
        margin-left: 50px;
        margin-right: 50px
    }

    .mg-xs-x-50-f {
        margin-left: 50px !important;
        margin-right: 50px !important
    }

    .mg-xs-t-50 {
        margin-top: 50px
    }

    .mg-xs-r-50 {
        margin-right: 50px
    }

    .mg-xs-b-50 {
        margin-bottom: 50px
    }

    .mg-xs-l-50 {
        margin-left: 50px
    }

    .mg-xs-t-50-f {
        margin-top: 50px !important
    }

    .mg-xs-r-50-f {
        margin-right: 50px !important
    }

    .mg-xs-b-50-f {
        margin-bottom: 50px !important
    }

    .mg-xs-l-50-f {
        margin-left: 50px !important
    }

    .mg-xs-55 {
        margin: 55px
    }

    .mg-xs-55-f {
        margin: 55px !important
    }

    .mg-xs-y-55 {
        margin-top: 55px;
        margin-bottom: 55px
    }

    .mg-xs-y-55-f {
        margin-top: 55px !important;
        margin-bottom: 55px !important
    }

    .mg-xs-x-55 {
        margin-left: 55px;
        margin-right: 55px
    }

    .mg-xs-x-55-f {
        margin-left: 55px !important;
        margin-right: 55px !important
    }

    .mg-xs-t-55 {
        margin-top: 55px
    }

    .mg-xs-r-55 {
        margin-right: 55px
    }

    .mg-xs-b-55 {
        margin-bottom: 55px
    }

    .mg-xs-l-55 {
        margin-left: 55px
    }

    .mg-xs-t-55-f {
        margin-top: 55px !important
    }

    .mg-xs-r-55-f {
        margin-right: 55px !important
    }

    .mg-xs-b-55-f {
        margin-bottom: 55px !important
    }

    .mg-xs-l-55-f {
        margin-left: 55px !important
    }

    .mg-xs-60 {
        margin: 60px
    }

    .mg-xs-60-f {
        margin: 60px !important
    }

    .mg-xs-y-60 {
        margin-top: 60px;
        margin-bottom: 60px
    }

    .mg-xs-y-60-f {
        margin-top: 60px !important;
        margin-bottom: 60px !important
    }

    .mg-xs-x-60 {
        margin-left: 60px;
        margin-right: 60px
    }

    .mg-xs-x-60-f {
        margin-left: 60px !important;
        margin-right: 60px !important
    }

    .mg-xs-t-60 {
        margin-top: 60px
    }

    .mg-xs-r-60 {
        margin-right: 60px
    }

    .mg-xs-b-60 {
        margin-bottom: 60px
    }

    .mg-xs-l-60 {
        margin-left: 60px
    }

    .mg-xs-t-60-f {
        margin-top: 60px !important
    }

    .mg-xs-r-60-f {
        margin-right: 60px !important
    }

    .mg-xs-b-60-f {
        margin-bottom: 60px !important
    }

    .mg-xs-l-60-f {
        margin-left: 60px !important
    }

    .mg-xs-65 {
        margin: 65px
    }

    .mg-xs-65-f {
        margin: 65px !important
    }

    .mg-xs-y-65 {
        margin-top: 65px;
        margin-bottom: 65px
    }

    .mg-xs-y-65-f {
        margin-top: 65px !important;
        margin-bottom: 65px !important
    }

    .mg-xs-x-65 {
        margin-left: 65px;
        margin-right: 65px
    }

    .mg-xs-x-65-f {
        margin-left: 65px !important;
        margin-right: 65px !important
    }

    .mg-xs-t-65 {
        margin-top: 65px
    }

    .mg-xs-r-65 {
        margin-right: 65px
    }

    .mg-xs-b-65 {
        margin-bottom: 65px
    }

    .mg-xs-l-65 {
        margin-left: 65px
    }

    .mg-xs-t-65-f {
        margin-top: 65px !important
    }

    .mg-xs-r-65-f {
        margin-right: 65px !important
    }

    .mg-xs-b-65-f {
        margin-bottom: 65px !important
    }

    .mg-xs-l-65-f {
        margin-left: 65px !important
    }

    .mg-xs-70 {
        margin: 70px
    }

    .mg-xs-70-f {
        margin: 70px !important
    }

    .mg-xs-y-70 {
        margin-top: 70px;
        margin-bottom: 70px
    }

    .mg-xs-y-70-f {
        margin-top: 70px !important;
        margin-bottom: 70px !important
    }

    .mg-xs-x-70 {
        margin-left: 70px;
        margin-right: 70px
    }

    .mg-xs-x-70-f {
        margin-left: 70px !important;
        margin-right: 70px !important
    }

    .mg-xs-t-70 {
        margin-top: 70px
    }

    .mg-xs-r-70 {
        margin-right: 70px
    }

    .mg-xs-b-70 {
        margin-bottom: 70px
    }

    .mg-xs-l-70 {
        margin-left: 70px
    }

    .mg-xs-t-70-f {
        margin-top: 70px !important
    }

    .mg-xs-r-70-f {
        margin-right: 70px !important
    }

    .mg-xs-b-70-f {
        margin-bottom: 70px !important
    }

    .mg-xs-l-70-f {
        margin-left: 70px !important
    }

    .mg-xs-75 {
        margin: 75px
    }

    .mg-xs-75-f {
        margin: 75px !important
    }

    .mg-xs-y-75 {
        margin-top: 75px;
        margin-bottom: 75px
    }

    .mg-xs-y-75-f {
        margin-top: 75px !important;
        margin-bottom: 75px !important
    }

    .mg-xs-x-75 {
        margin-left: 75px;
        margin-right: 75px
    }

    .mg-xs-x-75-f {
        margin-left: 75px !important;
        margin-right: 75px !important
    }

    .mg-xs-t-75 {
        margin-top: 75px
    }

    .mg-xs-r-75 {
        margin-right: 75px
    }

    .mg-xs-b-75 {
        margin-bottom: 75px
    }

    .mg-xs-l-75 {
        margin-left: 75px
    }

    .mg-xs-t-75-f {
        margin-top: 75px !important
    }

    .mg-xs-r-75-f {
        margin-right: 75px !important
    }

    .mg-xs-b-75-f {
        margin-bottom: 75px !important
    }

    .mg-xs-l-75-f {
        margin-left: 75px !important
    }

    .mg-xs-80 {
        margin: 80px
    }

    .mg-xs-80-f {
        margin: 80px !important
    }

    .mg-xs-y-80 {
        margin-top: 80px;
        margin-bottom: 80px
    }

    .mg-xs-y-80-f {
        margin-top: 80px !important;
        margin-bottom: 80px !important
    }

    .mg-xs-x-80 {
        margin-left: 80px;
        margin-right: 80px
    }

    .mg-xs-x-80-f {
        margin-left: 80px !important;
        margin-right: 80px !important
    }

    .mg-xs-t-80 {
        margin-top: 80px
    }

    .mg-xs-r-80 {
        margin-right: 80px
    }

    .mg-xs-b-80 {
        margin-bottom: 80px
    }

    .mg-xs-l-80 {
        margin-left: 80px
    }

    .mg-xs-t-80-f {
        margin-top: 80px !important
    }

    .mg-xs-r-80-f {
        margin-right: 80px !important
    }

    .mg-xs-b-80-f {
        margin-bottom: 80px !important
    }

    .mg-xs-l-80-f {
        margin-left: 80px !important
    }

    .mg-xs-85 {
        margin: 85px
    }

    .mg-xs-85-f {
        margin: 85px !important
    }

    .mg-xs-y-85 {
        margin-top: 85px;
        margin-bottom: 85px
    }

    .mg-xs-y-85-f {
        margin-top: 85px !important;
        margin-bottom: 85px !important
    }

    .mg-xs-x-85 {
        margin-left: 85px;
        margin-right: 85px
    }

    .mg-xs-x-85-f {
        margin-left: 85px !important;
        margin-right: 85px !important
    }

    .mg-xs-t-85 {
        margin-top: 85px
    }

    .mg-xs-r-85 {
        margin-right: 85px
    }

    .mg-xs-b-85 {
        margin-bottom: 85px
    }

    .mg-xs-l-85 {
        margin-left: 85px
    }

    .mg-xs-t-85-f {
        margin-top: 85px !important
    }

    .mg-xs-r-85-f {
        margin-right: 85px !important
    }

    .mg-xs-b-85-f {
        margin-bottom: 85px !important
    }

    .mg-xs-l-85-f {
        margin-left: 85px !important
    }

    .mg-xs-90 {
        margin: 90px
    }

    .mg-xs-90-f {
        margin: 90px !important
    }

    .mg-xs-y-90 {
        margin-top: 90px;
        margin-bottom: 90px
    }

    .mg-xs-y-90-f {
        margin-top: 90px !important;
        margin-bottom: 90px !important
    }

    .mg-xs-x-90 {
        margin-left: 90px;
        margin-right: 90px
    }

    .mg-xs-x-90-f {
        margin-left: 90px !important;
        margin-right: 90px !important
    }

    .mg-xs-t-90 {
        margin-top: 90px
    }

    .mg-xs-r-90 {
        margin-right: 90px
    }

    .mg-xs-b-90 {
        margin-bottom: 90px
    }

    .mg-xs-l-90 {
        margin-left: 90px
    }

    .mg-xs-t-90-f {
        margin-top: 90px !important
    }

    .mg-xs-r-90-f {
        margin-right: 90px !important
    }

    .mg-xs-b-90-f {
        margin-bottom: 90px !important
    }

    .mg-xs-l-90-f {
        margin-left: 90px !important
    }

    .mg-xs-95 {
        margin: 95px
    }

    .mg-xs-95-f {
        margin: 95px !important
    }

    .mg-xs-y-95 {
        margin-top: 95px;
        margin-bottom: 95px
    }

    .mg-xs-y-95-f {
        margin-top: 95px !important;
        margin-bottom: 95px !important
    }

    .mg-xs-x-95 {
        margin-left: 95px;
        margin-right: 95px
    }

    .mg-xs-x-95-f {
        margin-left: 95px !important;
        margin-right: 95px !important
    }

    .mg-xs-t-95 {
        margin-top: 95px
    }

    .mg-xs-r-95 {
        margin-right: 95px
    }

    .mg-xs-b-95 {
        margin-bottom: 95px
    }

    .mg-xs-l-95 {
        margin-left: 95px
    }

    .mg-xs-t-95-f {
        margin-top: 95px !important
    }

    .mg-xs-r-95-f {
        margin-right: 95px !important
    }

    .mg-xs-b-95-f {
        margin-bottom: 95px !important
    }

    .mg-xs-l-95-f {
        margin-left: 95px !important
    }

    .mg-xs-100 {
        margin: 100px
    }

    .mg-xs-100-f {
        margin: 100px !important
    }

    .mg-xs-y-100 {
        margin-top: 100px;
        margin-bottom: 100px
    }

    .mg-xs-y-100-f {
        margin-top: 100px !important;
        margin-bottom: 100px !important
    }

    .mg-xs-x-100 {
        margin-left: 100px;
        margin-right: 100px
    }

    .mg-xs-x-100-f {
        margin-left: 100px !important;
        margin-right: 100px !important
    }

    .mg-xs-t-100 {
        margin-top: 100px
    }

    .mg-xs-r-100 {
        margin-right: 100px
    }

    .mg-xs-b-100 {
        margin-bottom: 100px
    }

    .mg-xs-l-100 {
        margin-left: 100px
    }

    .mg-xs-t-100-f {
        margin-top: 100px !important
    }

    .mg-xs-r-100-f {
        margin-right: 100px !important
    }

    .mg-xs-b-100-f {
        margin-bottom: 100px !important
    }

    .mg-xs-l-100-f {
        margin-left: 100px !important
    }

    .mg-xs-150 {
        margin: 150px
    }

    .mg-xs-150-f {
        margin: 150px !important
    }

    .mg-xs-y-150 {
        margin-top: 150px;
        margin-bottom: 150px
    }

    .mg-xs-y-150-f {
        margin-top: 150px !important;
        margin-bottom: 150px !important
    }

    .mg-xs-x-150 {
        margin-left: 150px;
        margin-right: 150px
    }

    .mg-xs-x-150-f {
        margin-left: 150px !important;
        margin-right: 150px !important
    }

    .mg-xs-t-150 {
        margin-top: 150px
    }

    .mg-xs-r-150 {
        margin-right: 150px
    }

    .mg-xs-b-150 {
        margin-bottom: 150px
    }

    .mg-xs-l-150 {
        margin-left: 150px
    }

    .mg-xs-t-150-f {
        margin-top: 150px !important
    }

    .mg-xs-r-150-f {
        margin-right: 150px !important
    }

    .mg-xs-b-150-f {
        margin-bottom: 150px !important
    }

    .mg-xs-l-150-f {
        margin-left: 150px !important
    }

    .mg-xs-200 {
        margin: 200px
    }

    .mg-xs-200-f {
        margin: 200px !important
    }

    .mg-xs-y-200 {
        margin-top: 200px;
        margin-bottom: 200px
    }

    .mg-xs-y-200-f {
        margin-top: 200px !important;
        margin-bottom: 200px !important
    }

    .mg-xs-x-200 {
        margin-left: 200px;
        margin-right: 200px
    }

    .mg-xs-x-200-f {
        margin-left: 200px !important;
        margin-right: 200px !important
    }

    .mg-xs-t-200 {
        margin-top: 200px
    }

    .mg-xs-r-200 {
        margin-right: 200px
    }

    .mg-xs-b-200 {
        margin-bottom: 200px
    }

    .mg-xs-l-200 {
        margin-left: 200px
    }

    .mg-xs-t-200-f {
        margin-top: 200px !important
    }

    .mg-xs-r-200-f {
        margin-right: 200px !important
    }

    .mg-xs-b-200-f {
        margin-bottom: 200px !important
    }

    .mg-xs-l-200-f {
        margin-left: 200px !important
    }

    .mg-xs-250 {
        margin: 250px
    }

    .mg-xs-250-f {
        margin: 250px !important
    }

    .mg-xs-y-250 {
        margin-top: 250px;
        margin-bottom: 250px
    }

    .mg-xs-y-250-f {
        margin-top: 250px !important;
        margin-bottom: 250px !important
    }

    .mg-xs-x-250 {
        margin-left: 250px;
        margin-right: 250px
    }

    .mg-xs-x-250-f {
        margin-left: 250px !important;
        margin-right: 250px !important
    }

    .mg-xs-t-250 {
        margin-top: 250px
    }

    .mg-xs-r-250 {
        margin-right: 250px
    }

    .mg-xs-b-250 {
        margin-bottom: 250px
    }

    .mg-xs-l-250 {
        margin-left: 250px
    }

    .mg-xs-t-250-f {
        margin-top: 250px !important
    }

    .mg-xs-r-250-f {
        margin-right: 250px !important
    }

    .mg-xs-b-250-f {
        margin-bottom: 250px !important
    }

    .mg-xs-l-250-f {
        margin-left: 250px !important
    }

    .mg-xs-300 {
        margin: 300px
    }

    .mg-xs-300-f {
        margin: 300px !important
    }

    .mg-xs-y-300 {
        margin-top: 300px;
        margin-bottom: 300px
    }

    .mg-xs-y-300-f {
        margin-top: 300px !important;
        margin-bottom: 300px !important
    }

    .mg-xs-x-300 {
        margin-left: 300px;
        margin-right: 300px
    }

    .mg-xs-x-300-f {
        margin-left: 300px !important;
        margin-right: 300px !important
    }

    .mg-xs-t-300 {
        margin-top: 300px
    }

    .mg-xs-r-300 {
        margin-right: 300px
    }

    .mg-xs-b-300 {
        margin-bottom: 300px
    }

    .mg-xs-l-300 {
        margin-left: 300px
    }

    .mg-xs-t-300-f {
        margin-top: 300px !important
    }

    .mg-xs-r-300-f {
        margin-right: 300px !important
    }

    .mg-xs-b-300-f {
        margin-bottom: 300px !important
    }

    .mg-xs-l-300-f {
        margin-left: 300px !important
    }

    .mg-xs-350 {
        margin: 350px
    }

    .mg-xs-350-f {
        margin: 350px !important
    }

    .mg-xs-y-350 {
        margin-top: 350px;
        margin-bottom: 350px
    }

    .mg-xs-y-350-f {
        margin-top: 350px !important;
        margin-bottom: 350px !important
    }

    .mg-xs-x-350 {
        margin-left: 350px;
        margin-right: 350px
    }

    .mg-xs-x-350-f {
        margin-left: 350px !important;
        margin-right: 350px !important
    }

    .mg-xs-t-350 {
        margin-top: 350px
    }

    .mg-xs-r-350 {
        margin-right: 350px
    }

    .mg-xs-b-350 {
        margin-bottom: 350px
    }

    .mg-xs-l-350 {
        margin-left: 350px
    }

    .mg-xs-t-350-f {
        margin-top: 350px !important
    }

    .mg-xs-r-350-f {
        margin-right: 350px !important
    }

    .mg-xs-b-350-f {
        margin-bottom: 350px !important
    }

    .mg-xs-l-350-f {
        margin-left: 350px !important
    }

    .mg-xs-400 {
        margin: 400px
    }

    .mg-xs-400-f {
        margin: 400px !important
    }

    .mg-xs-y-400 {
        margin-top: 400px;
        margin-bottom: 400px
    }

    .mg-xs-y-400-f {
        margin-top: 400px !important;
        margin-bottom: 400px !important
    }

    .mg-xs-x-400 {
        margin-left: 400px;
        margin-right: 400px
    }

    .mg-xs-x-400-f {
        margin-left: 400px !important;
        margin-right: 400px !important
    }

    .mg-xs-t-400 {
        margin-top: 400px
    }

    .mg-xs-r-400 {
        margin-right: 400px
    }

    .mg-xs-b-400 {
        margin-bottom: 400px
    }

    .mg-xs-l-400 {
        margin-left: 400px
    }

    .mg-xs-t-400-f {
        margin-top: 400px !important
    }

    .mg-xs-r-400-f {
        margin-right: 400px !important
    }

    .mg-xs-b-400-f {
        margin-bottom: 400px !important
    }

    .mg-xs-l-400-f {
        margin-left: 400px !important
    }

    .mg-xs-450 {
        margin: 450px
    }

    .mg-xs-450-f {
        margin: 450px !important
    }

    .mg-xs-y-450 {
        margin-top: 450px;
        margin-bottom: 450px
    }

    .mg-xs-y-450-f {
        margin-top: 450px !important;
        margin-bottom: 450px !important
    }

    .mg-xs-x-450 {
        margin-left: 450px;
        margin-right: 450px
    }

    .mg-xs-x-450-f {
        margin-left: 450px !important;
        margin-right: 450px !important
    }

    .mg-xs-t-450 {
        margin-top: 450px
    }

    .mg-xs-r-450 {
        margin-right: 450px
    }

    .mg-xs-b-450 {
        margin-bottom: 450px
    }

    .mg-xs-l-450 {
        margin-left: 450px
    }

    .mg-xs-t-450-f {
        margin-top: 450px !important
    }

    .mg-xs-r-450-f {
        margin-right: 450px !important
    }

    .mg-xs-b-450-f {
        margin-bottom: 450px !important
    }

    .mg-xs-l-450-f {
        margin-left: 450px !important
    }

    .mg-xs-500 {
        margin: 500px
    }

    .mg-xs-500-f {
        margin: 500px !important
    }

    .mg-xs-y-500 {
        margin-top: 500px;
        margin-bottom: 500px
    }

    .mg-xs-y-500-f {
        margin-top: 500px !important;
        margin-bottom: 500px !important
    }

    .mg-xs-x-500 {
        margin-left: 500px;
        margin-right: 500px
    }

    .mg-xs-x-500-f {
        margin-left: 500px !important;
        margin-right: 500px !important
    }

    .mg-xs-t-500 {
        margin-top: 500px
    }

    .mg-xs-r-500 {
        margin-right: 500px
    }

    .mg-xs-b-500 {
        margin-bottom: 500px
    }

    .mg-xs-l-500 {
        margin-left: 500px
    }

    .mg-xs-t-500-f {
        margin-top: 500px !important
    }

    .mg-xs-r-500-f {
        margin-right: 500px !important
    }

    .mg-xs-b-500-f {
        margin-bottom: 500px !important
    }

    .mg-xs-l-500-f {
        margin-left: 500px !important
    }

    .mg-xs-550 {
        margin: 550px
    }

    .mg-xs-550-f {
        margin: 550px !important
    }

    .mg-xs-y-550 {
        margin-top: 550px;
        margin-bottom: 550px
    }

    .mg-xs-y-550-f {
        margin-top: 550px !important;
        margin-bottom: 550px !important
    }

    .mg-xs-x-550 {
        margin-left: 550px;
        margin-right: 550px
    }

    .mg-xs-x-550-f {
        margin-left: 550px !important;
        margin-right: 550px !important
    }

    .mg-xs-t-550 {
        margin-top: 550px
    }

    .mg-xs-r-550 {
        margin-right: 550px
    }

    .mg-xs-b-550 {
        margin-bottom: 550px
    }

    .mg-xs-l-550 {
        margin-left: 550px
    }

    .mg-xs-t-550-f {
        margin-top: 550px !important
    }

    .mg-xs-r-550-f {
        margin-right: 550px !important
    }

    .mg-xs-b-550-f {
        margin-bottom: 550px !important
    }

    .mg-xs-l-550-f {
        margin-left: 550px !important
    }

    .mg-xs-600 {
        margin: 600px
    }

    .mg-xs-600-f {
        margin: 600px !important
    }

    .mg-xs-y-600 {
        margin-top: 600px;
        margin-bottom: 600px
    }

    .mg-xs-y-600-f {
        margin-top: 600px !important;
        margin-bottom: 600px !important
    }

    .mg-xs-x-600 {
        margin-left: 600px;
        margin-right: 600px
    }

    .mg-xs-x-600-f {
        margin-left: 600px !important;
        margin-right: 600px !important
    }

    .mg-xs-t-600 {
        margin-top: 600px
    }

    .mg-xs-r-600 {
        margin-right: 600px
    }

    .mg-xs-b-600 {
        margin-bottom: 600px
    }

    .mg-xs-l-600 {
        margin-left: 600px
    }

    .mg-xs-t-600-f {
        margin-top: 600px !important
    }

    .mg-xs-r-600-f {
        margin-right: 600px !important
    }

    .mg-xs-b-600-f {
        margin-bottom: 600px !important
    }

    .mg-xs-l-600-f {
        margin-left: 600px !important
    }

    .mg-xs-650 {
        margin: 650px
    }

    .mg-xs-650-f {
        margin: 650px !important
    }

    .mg-xs-y-650 {
        margin-top: 650px;
        margin-bottom: 650px
    }

    .mg-xs-y-650-f {
        margin-top: 650px !important;
        margin-bottom: 650px !important
    }

    .mg-xs-x-650 {
        margin-left: 650px;
        margin-right: 650px
    }

    .mg-xs-x-650-f {
        margin-left: 650px !important;
        margin-right: 650px !important
    }

    .mg-xs-t-650 {
        margin-top: 650px
    }

    .mg-xs-r-650 {
        margin-right: 650px
    }

    .mg-xs-b-650 {
        margin-bottom: 650px
    }

    .mg-xs-l-650 {
        margin-left: 650px
    }

    .mg-xs-t-650-f {
        margin-top: 650px !important
    }

    .mg-xs-r-650-f {
        margin-right: 650px !important
    }

    .mg-xs-b-650-f {
        margin-bottom: 650px !important
    }

    .mg-xs-l-650-f {
        margin-left: 650px !important
    }

    .mg-xs-t-auto {
        margin-top: auto
    }

    .mg-xs-r-auto {
        margin-right: auto
    }

    .mg-xs-b-auto {
        margin-bottom: auto
    }

    .mg-xs-l-auto {
        margin-left: auto
    }

    .mg-xs-auto {
        margin: auto
    }

    .mg-xs-x-auto {
        margin-left: auto;
        margin-right: auto
    }
}

@media (min-width: 576px) {
    .mg-sm-0 {
        margin: 0px
    }

    .mg-sm-0-f {
        margin: 0px !important
    }

    .mg-sm-y-0 {
        margin-top: 0px;
        margin-bottom: 0px
    }

    .mg-sm-y-0-f {
        margin-top: 0px !important;
        margin-bottom: 0px !important
    }

    .mg-sm-x-0 {
        margin-left: 0px;
        margin-right: 0px
    }

    .mg-sm-x-0-f {
        margin-left: 0px !important;
        margin-right: 0px !important
    }

    .mg-sm-t-0 {
        margin-top: 0px
    }

    .mg-sm-r-0 {
        margin-right: 0px
    }

    .mg-sm-b-0 {
        margin-bottom: 0px
    }

    .mg-sm-l-0 {
        margin-left: 0px
    }

    .mg-sm-t-0-f {
        margin-top: 0px !important
    }

    .mg-sm-r-0-f {
        margin-right: 0px !important
    }

    .mg-sm-b-0-f {
        margin-bottom: 0px !important
    }

    .mg-sm-l-0-f {
        margin-left: 0px !important
    }

    .mg-sm-1 {
        margin: 1px
    }

    .mg-sm-1-f {
        margin: 1px !important
    }

    .mg-sm-y-1 {
        margin-top: 1px;
        margin-bottom: 1px
    }

    .mg-sm-y-1-f {
        margin-top: 1px !important;
        margin-bottom: 1px !important
    }

    .mg-sm-x-1 {
        margin-left: 1px;
        margin-right: 1px
    }

    .mg-sm-x-1-f {
        margin-left: 1px !important;
        margin-right: 1px !important
    }

    .mg-sm-t-1 {
        margin-top: 1px
    }

    .mg-sm-r-1 {
        margin-right: 1px
    }

    .mg-sm-b-1 {
        margin-bottom: 1px
    }

    .mg-sm-l-1 {
        margin-left: 1px
    }

    .mg-sm-t-1-f {
        margin-top: 1px !important
    }

    .mg-sm-r-1-f {
        margin-right: 1px !important
    }

    .mg-sm-b-1-f {
        margin-bottom: 1px !important
    }

    .mg-sm-l-1-f {
        margin-left: 1px !important
    }

    .mg-sm-2 {
        margin: 2px
    }

    .mg-sm-2-f {
        margin: 2px !important
    }

    .mg-sm-y-2 {
        margin-top: 2px;
        margin-bottom: 2px
    }

    .mg-sm-y-2-f {
        margin-top: 2px !important;
        margin-bottom: 2px !important
    }

    .mg-sm-x-2 {
        margin-left: 2px;
        margin-right: 2px
    }

    .mg-sm-x-2-f {
        margin-left: 2px !important;
        margin-right: 2px !important
    }

    .mg-sm-t-2 {
        margin-top: 2px
    }

    .mg-sm-r-2 {
        margin-right: 2px
    }

    .mg-sm-b-2 {
        margin-bottom: 2px
    }

    .mg-sm-l-2 {
        margin-left: 2px
    }

    .mg-sm-t-2-f {
        margin-top: 2px !important
    }

    .mg-sm-r-2-f {
        margin-right: 2px !important
    }

    .mg-sm-b-2-f {
        margin-bottom: 2px !important
    }

    .mg-sm-l-2-f {
        margin-left: 2px !important
    }

    .mg-sm-3 {
        margin: 3px
    }

    .mg-sm-3-f {
        margin: 3px !important
    }

    .mg-sm-y-3 {
        margin-top: 3px;
        margin-bottom: 3px
    }

    .mg-sm-y-3-f {
        margin-top: 3px !important;
        margin-bottom: 3px !important
    }

    .mg-sm-x-3 {
        margin-left: 3px;
        margin-right: 3px
    }

    .mg-sm-x-3-f {
        margin-left: 3px !important;
        margin-right: 3px !important
    }

    .mg-sm-t-3 {
        margin-top: 3px
    }

    .mg-sm-r-3 {
        margin-right: 3px
    }

    .mg-sm-b-3 {
        margin-bottom: 3px
    }

    .mg-sm-l-3 {
        margin-left: 3px
    }

    .mg-sm-t-3-f {
        margin-top: 3px !important
    }

    .mg-sm-r-3-f {
        margin-right: 3px !important
    }

    .mg-sm-b-3-f {
        margin-bottom: 3px !important
    }

    .mg-sm-l-3-f {
        margin-left: 3px !important
    }

    .mg-sm-4 {
        margin: 4px
    }

    .mg-sm-4-f {
        margin: 4px !important
    }

    .mg-sm-y-4 {
        margin-top: 4px;
        margin-bottom: 4px
    }

    .mg-sm-y-4-f {
        margin-top: 4px !important;
        margin-bottom: 4px !important
    }

    .mg-sm-x-4 {
        margin-left: 4px;
        margin-right: 4px
    }

    .mg-sm-x-4-f {
        margin-left: 4px !important;
        margin-right: 4px !important
    }

    .mg-sm-t-4 {
        margin-top: 4px
    }

    .mg-sm-r-4 {
        margin-right: 4px
    }

    .mg-sm-b-4 {
        margin-bottom: 4px
    }

    .mg-sm-l-4 {
        margin-left: 4px
    }

    .mg-sm-t-4-f {
        margin-top: 4px !important
    }

    .mg-sm-r-4-f {
        margin-right: 4px !important
    }

    .mg-sm-b-4-f {
        margin-bottom: 4px !important
    }

    .mg-sm-l-4-f {
        margin-left: 4px !important
    }

    .mg-sm-5 {
        margin: 5px
    }

    .mg-sm-5-f {
        margin: 5px !important
    }

    .mg-sm-y-5 {
        margin-top: 5px;
        margin-bottom: 5px
    }

    .mg-sm-y-5-f {
        margin-top: 5px !important;
        margin-bottom: 5px !important
    }

    .mg-sm-x-5 {
        margin-left: 5px;
        margin-right: 5px
    }

    .mg-sm-x-5-f {
        margin-left: 5px !important;
        margin-right: 5px !important
    }

    .mg-sm-t-5 {
        margin-top: 5px
    }

    .mg-sm-r-5 {
        margin-right: 5px
    }

    .mg-sm-b-5 {
        margin-bottom: 5px
    }

    .mg-sm-l-5 {
        margin-left: 5px
    }

    .mg-sm-t-5-f {
        margin-top: 5px !important
    }

    .mg-sm-r-5-f {
        margin-right: 5px !important
    }

    .mg-sm-b-5-f {
        margin-bottom: 5px !important
    }

    .mg-sm-l-5-f {
        margin-left: 5px !important
    }

    .mg-sm-6 {
        margin: 6px
    }

    .mg-sm-6-f {
        margin: 6px !important
    }

    .mg-sm-y-6 {
        margin-top: 6px;
        margin-bottom: 6px
    }

    .mg-sm-y-6-f {
        margin-top: 6px !important;
        margin-bottom: 6px !important
    }

    .mg-sm-x-6 {
        margin-left: 6px;
        margin-right: 6px
    }

    .mg-sm-x-6-f {
        margin-left: 6px !important;
        margin-right: 6px !important
    }

    .mg-sm-t-6 {
        margin-top: 6px
    }

    .mg-sm-r-6 {
        margin-right: 6px
    }

    .mg-sm-b-6 {
        margin-bottom: 6px
    }

    .mg-sm-l-6 {
        margin-left: 6px
    }

    .mg-sm-t-6-f {
        margin-top: 6px !important
    }

    .mg-sm-r-6-f {
        margin-right: 6px !important
    }

    .mg-sm-b-6-f {
        margin-bottom: 6px !important
    }

    .mg-sm-l-6-f {
        margin-left: 6px !important
    }

    .mg-sm-7 {
        margin: 7px
    }

    .mg-sm-7-f {
        margin: 7px !important
    }

    .mg-sm-y-7 {
        margin-top: 7px;
        margin-bottom: 7px
    }

    .mg-sm-y-7-f {
        margin-top: 7px !important;
        margin-bottom: 7px !important
    }

    .mg-sm-x-7 {
        margin-left: 7px;
        margin-right: 7px
    }

    .mg-sm-x-7-f {
        margin-left: 7px !important;
        margin-right: 7px !important
    }

    .mg-sm-t-7 {
        margin-top: 7px
    }

    .mg-sm-r-7 {
        margin-right: 7px
    }

    .mg-sm-b-7 {
        margin-bottom: 7px
    }

    .mg-sm-l-7 {
        margin-left: 7px
    }

    .mg-sm-t-7-f {
        margin-top: 7px !important
    }

    .mg-sm-r-7-f {
        margin-right: 7px !important
    }

    .mg-sm-b-7-f {
        margin-bottom: 7px !important
    }

    .mg-sm-l-7-f {
        margin-left: 7px !important
    }

    .mg-sm-8 {
        margin: 8px
    }

    .mg-sm-8-f {
        margin: 8px !important
    }

    .mg-sm-y-8 {
        margin-top: 8px;
        margin-bottom: 8px
    }

    .mg-sm-y-8-f {
        margin-top: 8px !important;
        margin-bottom: 8px !important
    }

    .mg-sm-x-8 {
        margin-left: 8px;
        margin-right: 8px
    }

    .mg-sm-x-8-f {
        margin-left: 8px !important;
        margin-right: 8px !important
    }

    .mg-sm-t-8 {
        margin-top: 8px
    }

    .mg-sm-r-8 {
        margin-right: 8px
    }

    .mg-sm-b-8 {
        margin-bottom: 8px
    }

    .mg-sm-l-8 {
        margin-left: 8px
    }

    .mg-sm-t-8-f {
        margin-top: 8px !important
    }

    .mg-sm-r-8-f {
        margin-right: 8px !important
    }

    .mg-sm-b-8-f {
        margin-bottom: 8px !important
    }

    .mg-sm-l-8-f {
        margin-left: 8px !important
    }

    .mg-sm-9 {
        margin: 9px
    }

    .mg-sm-9-f {
        margin: 9px !important
    }

    .mg-sm-y-9 {
        margin-top: 9px;
        margin-bottom: 9px
    }

    .mg-sm-y-9-f {
        margin-top: 9px !important;
        margin-bottom: 9px !important
    }

    .mg-sm-x-9 {
        margin-left: 9px;
        margin-right: 9px
    }

    .mg-sm-x-9-f {
        margin-left: 9px !important;
        margin-right: 9px !important
    }

    .mg-sm-t-9 {
        margin-top: 9px
    }

    .mg-sm-r-9 {
        margin-right: 9px
    }

    .mg-sm-b-9 {
        margin-bottom: 9px
    }

    .mg-sm-l-9 {
        margin-left: 9px
    }

    .mg-sm-t-9-f {
        margin-top: 9px !important
    }

    .mg-sm-r-9-f {
        margin-right: 9px !important
    }

    .mg-sm-b-9-f {
        margin-bottom: 9px !important
    }

    .mg-sm-l-9-f {
        margin-left: 9px !important
    }

    .mg-sm-10 {
        margin: 10px
    }

    .mg-sm-10-f {
        margin: 10px !important
    }

    .mg-sm-y-10 {
        margin-top: 10px;
        margin-bottom: 10px
    }

    .mg-sm-y-10-f {
        margin-top: 10px !important;
        margin-bottom: 10px !important
    }

    .mg-sm-x-10 {
        margin-left: 10px;
        margin-right: 10px
    }

    .mg-sm-x-10-f {
        margin-left: 10px !important;
        margin-right: 10px !important
    }

    .mg-sm-t-10 {
        margin-top: 10px
    }

    .mg-sm-r-10 {
        margin-right: 10px
    }

    .mg-sm-b-10 {
        margin-bottom: 10px
    }

    .mg-sm-l-10 {
        margin-left: 10px
    }

    .mg-sm-t-10-f {
        margin-top: 10px !important
    }

    .mg-sm-r-10-f {
        margin-right: 10px !important
    }

    .mg-sm-b-10-f {
        margin-bottom: 10px !important
    }

    .mg-sm-l-10-f {
        margin-left: 10px !important
    }

    .mg-sm-15 {
        margin: 15px
    }

    .mg-sm-15-f {
        margin: 15px !important
    }

    .mg-sm-y-15 {
        margin-top: 15px;
        margin-bottom: 15px
    }

    .mg-sm-y-15-f {
        margin-top: 15px !important;
        margin-bottom: 15px !important
    }

    .mg-sm-x-15 {
        margin-left: 15px;
        margin-right: 15px
    }

    .mg-sm-x-15-f {
        margin-left: 15px !important;
        margin-right: 15px !important
    }

    .mg-sm-t-15 {
        margin-top: 15px
    }

    .mg-sm-r-15 {
        margin-right: 15px
    }

    .mg-sm-b-15 {
        margin-bottom: 15px
    }

    .mg-sm-l-15 {
        margin-left: 15px
    }

    .mg-sm-t-15-f {
        margin-top: 15px !important
    }

    .mg-sm-r-15-f {
        margin-right: 15px !important
    }

    .mg-sm-b-15-f {
        margin-bottom: 15px !important
    }

    .mg-sm-l-15-f {
        margin-left: 15px !important
    }

    .mg-sm-20 {
        margin: 20px
    }

    .mg-sm-20-f {
        margin: 20px !important
    }

    .mg-sm-y-20 {
        margin-top: 20px;
        margin-bottom: 20px
    }

    .mg-sm-y-20-f {
        margin-top: 20px !important;
        margin-bottom: 20px !important
    }

    .mg-sm-x-20 {
        margin-left: 20px;
        margin-right: 20px
    }

    .mg-sm-x-20-f {
        margin-left: 20px !important;
        margin-right: 20px !important
    }

    .mg-sm-t-20 {
        margin-top: 20px
    }

    .mg-sm-r-20 {
        margin-right: 20px
    }

    .mg-sm-b-20 {
        margin-bottom: 20px
    }

    .mg-sm-l-20 {
        margin-left: 20px
    }

    .mg-sm-t-20-f {
        margin-top: 20px !important
    }

    .mg-sm-r-20-f {
        margin-right: 20px !important
    }

    .mg-sm-b-20-f {
        margin-bottom: 20px !important
    }

    .mg-sm-l-20-f {
        margin-left: 20px !important
    }

    .mg-sm-25 {
        margin: 25px
    }

    .mg-sm-25-f {
        margin: 25px !important
    }

    .mg-sm-y-25 {
        margin-top: 25px;
        margin-bottom: 25px
    }

    .mg-sm-y-25-f {
        margin-top: 25px !important;
        margin-bottom: 25px !important
    }

    .mg-sm-x-25 {
        margin-left: 25px;
        margin-right: 25px
    }

    .mg-sm-x-25-f {
        margin-left: 25px !important;
        margin-right: 25px !important
    }

    .mg-sm-t-25 {
        margin-top: 25px
    }

    .mg-sm-r-25 {
        margin-right: 25px
    }

    .mg-sm-b-25 {
        margin-bottom: 25px
    }

    .mg-sm-l-25 {
        margin-left: 25px
    }

    .mg-sm-t-25-f {
        margin-top: 25px !important
    }

    .mg-sm-r-25-f {
        margin-right: 25px !important
    }

    .mg-sm-b-25-f {
        margin-bottom: 25px !important
    }

    .mg-sm-l-25-f {
        margin-left: 25px !important
    }

    .mg-sm-30 {
        margin: 30px
    }

    .mg-sm-30-f {
        margin: 30px !important
    }

    .mg-sm-y-30 {
        margin-top: 30px;
        margin-bottom: 30px
    }

    .mg-sm-y-30-f {
        margin-top: 30px !important;
        margin-bottom: 30px !important
    }

    .mg-sm-x-30 {
        margin-left: 30px;
        margin-right: 30px
    }

    .mg-sm-x-30-f {
        margin-left: 30px !important;
        margin-right: 30px !important
    }

    .mg-sm-t-30 {
        margin-top: 30px
    }

    .mg-sm-r-30 {
        margin-right: 30px
    }

    .mg-sm-b-30 {
        margin-bottom: 30px
    }

    .mg-sm-l-30 {
        margin-left: 30px
    }

    .mg-sm-t-30-f {
        margin-top: 30px !important
    }

    .mg-sm-r-30-f {
        margin-right: 30px !important
    }

    .mg-sm-b-30-f {
        margin-bottom: 30px !important
    }

    .mg-sm-l-30-f {
        margin-left: 30px !important
    }

    .mg-sm-35 {
        margin: 35px
    }

    .mg-sm-35-f {
        margin: 35px !important
    }

    .mg-sm-y-35 {
        margin-top: 35px;
        margin-bottom: 35px
    }

    .mg-sm-y-35-f {
        margin-top: 35px !important;
        margin-bottom: 35px !important
    }

    .mg-sm-x-35 {
        margin-left: 35px;
        margin-right: 35px
    }

    .mg-sm-x-35-f {
        margin-left: 35px !important;
        margin-right: 35px !important
    }

    .mg-sm-t-35 {
        margin-top: 35px
    }

    .mg-sm-r-35 {
        margin-right: 35px
    }

    .mg-sm-b-35 {
        margin-bottom: 35px
    }

    .mg-sm-l-35 {
        margin-left: 35px
    }

    .mg-sm-t-35-f {
        margin-top: 35px !important
    }

    .mg-sm-r-35-f {
        margin-right: 35px !important
    }

    .mg-sm-b-35-f {
        margin-bottom: 35px !important
    }

    .mg-sm-l-35-f {
        margin-left: 35px !important
    }

    .mg-sm-40 {
        margin: 40px
    }

    .mg-sm-40-f {
        margin: 40px !important
    }

    .mg-sm-y-40 {
        margin-top: 40px;
        margin-bottom: 40px
    }

    .mg-sm-y-40-f {
        margin-top: 40px !important;
        margin-bottom: 40px !important
    }

    .mg-sm-x-40 {
        margin-left: 40px;
        margin-right: 40px
    }

    .mg-sm-x-40-f {
        margin-left: 40px !important;
        margin-right: 40px !important
    }

    .mg-sm-t-40 {
        margin-top: 40px
    }

    .mg-sm-r-40 {
        margin-right: 40px
    }

    .mg-sm-b-40 {
        margin-bottom: 40px
    }

    .mg-sm-l-40 {
        margin-left: 40px
    }

    .mg-sm-t-40-f {
        margin-top: 40px !important
    }

    .mg-sm-r-40-f {
        margin-right: 40px !important
    }

    .mg-sm-b-40-f {
        margin-bottom: 40px !important
    }

    .mg-sm-l-40-f {
        margin-left: 40px !important
    }

    .mg-sm-45 {
        margin: 45px
    }

    .mg-sm-45-f {
        margin: 45px !important
    }

    .mg-sm-y-45 {
        margin-top: 45px;
        margin-bottom: 45px
    }

    .mg-sm-y-45-f {
        margin-top: 45px !important;
        margin-bottom: 45px !important
    }

    .mg-sm-x-45 {
        margin-left: 45px;
        margin-right: 45px
    }

    .mg-sm-x-45-f {
        margin-left: 45px !important;
        margin-right: 45px !important
    }

    .mg-sm-t-45 {
        margin-top: 45px
    }

    .mg-sm-r-45 {
        margin-right: 45px
    }

    .mg-sm-b-45 {
        margin-bottom: 45px
    }

    .mg-sm-l-45 {
        margin-left: 45px
    }

    .mg-sm-t-45-f {
        margin-top: 45px !important
    }

    .mg-sm-r-45-f {
        margin-right: 45px !important
    }

    .mg-sm-b-45-f {
        margin-bottom: 45px !important
    }

    .mg-sm-l-45-f {
        margin-left: 45px !important
    }

    .mg-sm-50 {
        margin: 50px
    }

    .mg-sm-50-f {
        margin: 50px !important
    }

    .mg-sm-y-50 {
        margin-top: 50px;
        margin-bottom: 50px
    }

    .mg-sm-y-50-f {
        margin-top: 50px !important;
        margin-bottom: 50px !important
    }

    .mg-sm-x-50 {
        margin-left: 50px;
        margin-right: 50px
    }

    .mg-sm-x-50-f {
        margin-left: 50px !important;
        margin-right: 50px !important
    }

    .mg-sm-t-50 {
        margin-top: 50px
    }

    .mg-sm-r-50 {
        margin-right: 50px
    }

    .mg-sm-b-50 {
        margin-bottom: 50px
    }

    .mg-sm-l-50 {
        margin-left: 50px
    }

    .mg-sm-t-50-f {
        margin-top: 50px !important
    }

    .mg-sm-r-50-f {
        margin-right: 50px !important
    }

    .mg-sm-b-50-f {
        margin-bottom: 50px !important
    }

    .mg-sm-l-50-f {
        margin-left: 50px !important
    }

    .mg-sm-55 {
        margin: 55px
    }

    .mg-sm-55-f {
        margin: 55px !important
    }

    .mg-sm-y-55 {
        margin-top: 55px;
        margin-bottom: 55px
    }

    .mg-sm-y-55-f {
        margin-top: 55px !important;
        margin-bottom: 55px !important
    }

    .mg-sm-x-55 {
        margin-left: 55px;
        margin-right: 55px
    }

    .mg-sm-x-55-f {
        margin-left: 55px !important;
        margin-right: 55px !important
    }

    .mg-sm-t-55 {
        margin-top: 55px
    }

    .mg-sm-r-55 {
        margin-right: 55px
    }

    .mg-sm-b-55 {
        margin-bottom: 55px
    }

    .mg-sm-l-55 {
        margin-left: 55px
    }

    .mg-sm-t-55-f {
        margin-top: 55px !important
    }

    .mg-sm-r-55-f {
        margin-right: 55px !important
    }

    .mg-sm-b-55-f {
        margin-bottom: 55px !important
    }

    .mg-sm-l-55-f {
        margin-left: 55px !important
    }

    .mg-sm-60 {
        margin: 60px
    }

    .mg-sm-60-f {
        margin: 60px !important
    }

    .mg-sm-y-60 {
        margin-top: 60px;
        margin-bottom: 60px
    }

    .mg-sm-y-60-f {
        margin-top: 60px !important;
        margin-bottom: 60px !important
    }

    .mg-sm-x-60 {
        margin-left: 60px;
        margin-right: 60px
    }

    .mg-sm-x-60-f {
        margin-left: 60px !important;
        margin-right: 60px !important
    }

    .mg-sm-t-60 {
        margin-top: 60px
    }

    .mg-sm-r-60 {
        margin-right: 60px
    }

    .mg-sm-b-60 {
        margin-bottom: 60px
    }

    .mg-sm-l-60 {
        margin-left: 60px
    }

    .mg-sm-t-60-f {
        margin-top: 60px !important
    }

    .mg-sm-r-60-f {
        margin-right: 60px !important
    }

    .mg-sm-b-60-f {
        margin-bottom: 60px !important
    }

    .mg-sm-l-60-f {
        margin-left: 60px !important
    }

    .mg-sm-65 {
        margin: 65px
    }

    .mg-sm-65-f {
        margin: 65px !important
    }

    .mg-sm-y-65 {
        margin-top: 65px;
        margin-bottom: 65px
    }

    .mg-sm-y-65-f {
        margin-top: 65px !important;
        margin-bottom: 65px !important
    }

    .mg-sm-x-65 {
        margin-left: 65px;
        margin-right: 65px
    }

    .mg-sm-x-65-f {
        margin-left: 65px !important;
        margin-right: 65px !important
    }

    .mg-sm-t-65 {
        margin-top: 65px
    }

    .mg-sm-r-65 {
        margin-right: 65px
    }

    .mg-sm-b-65 {
        margin-bottom: 65px
    }

    .mg-sm-l-65 {
        margin-left: 65px
    }

    .mg-sm-t-65-f {
        margin-top: 65px !important
    }

    .mg-sm-r-65-f {
        margin-right: 65px !important
    }

    .mg-sm-b-65-f {
        margin-bottom: 65px !important
    }

    .mg-sm-l-65-f {
        margin-left: 65px !important
    }

    .mg-sm-70 {
        margin: 70px
    }

    .mg-sm-70-f {
        margin: 70px !important
    }

    .mg-sm-y-70 {
        margin-top: 70px;
        margin-bottom: 70px
    }

    .mg-sm-y-70-f {
        margin-top: 70px !important;
        margin-bottom: 70px !important
    }

    .mg-sm-x-70 {
        margin-left: 70px;
        margin-right: 70px
    }

    .mg-sm-x-70-f {
        margin-left: 70px !important;
        margin-right: 70px !important
    }

    .mg-sm-t-70 {
        margin-top: 70px
    }

    .mg-sm-r-70 {
        margin-right: 70px
    }

    .mg-sm-b-70 {
        margin-bottom: 70px
    }

    .mg-sm-l-70 {
        margin-left: 70px
    }

    .mg-sm-t-70-f {
        margin-top: 70px !important
    }

    .mg-sm-r-70-f {
        margin-right: 70px !important
    }

    .mg-sm-b-70-f {
        margin-bottom: 70px !important
    }

    .mg-sm-l-70-f {
        margin-left: 70px !important
    }

    .mg-sm-75 {
        margin: 75px
    }

    .mg-sm-75-f {
        margin: 75px !important
    }

    .mg-sm-y-75 {
        margin-top: 75px;
        margin-bottom: 75px
    }

    .mg-sm-y-75-f {
        margin-top: 75px !important;
        margin-bottom: 75px !important
    }

    .mg-sm-x-75 {
        margin-left: 75px;
        margin-right: 75px
    }

    .mg-sm-x-75-f {
        margin-left: 75px !important;
        margin-right: 75px !important
    }

    .mg-sm-t-75 {
        margin-top: 75px
    }

    .mg-sm-r-75 {
        margin-right: 75px
    }

    .mg-sm-b-75 {
        margin-bottom: 75px
    }

    .mg-sm-l-75 {
        margin-left: 75px
    }

    .mg-sm-t-75-f {
        margin-top: 75px !important
    }

    .mg-sm-r-75-f {
        margin-right: 75px !important
    }

    .mg-sm-b-75-f {
        margin-bottom: 75px !important
    }

    .mg-sm-l-75-f {
        margin-left: 75px !important
    }

    .mg-sm-80 {
        margin: 80px
    }

    .mg-sm-80-f {
        margin: 80px !important
    }

    .mg-sm-y-80 {
        margin-top: 80px;
        margin-bottom: 80px
    }

    .mg-sm-y-80-f {
        margin-top: 80px !important;
        margin-bottom: 80px !important
    }

    .mg-sm-x-80 {
        margin-left: 80px;
        margin-right: 80px
    }

    .mg-sm-x-80-f {
        margin-left: 80px !important;
        margin-right: 80px !important
    }

    .mg-sm-t-80 {
        margin-top: 80px
    }

    .mg-sm-r-80 {
        margin-right: 80px
    }

    .mg-sm-b-80 {
        margin-bottom: 80px
    }

    .mg-sm-l-80 {
        margin-left: 80px
    }

    .mg-sm-t-80-f {
        margin-top: 80px !important
    }

    .mg-sm-r-80-f {
        margin-right: 80px !important
    }

    .mg-sm-b-80-f {
        margin-bottom: 80px !important
    }

    .mg-sm-l-80-f {
        margin-left: 80px !important
    }

    .mg-sm-85 {
        margin: 85px
    }

    .mg-sm-85-f {
        margin: 85px !important
    }

    .mg-sm-y-85 {
        margin-top: 85px;
        margin-bottom: 85px
    }

    .mg-sm-y-85-f {
        margin-top: 85px !important;
        margin-bottom: 85px !important
    }

    .mg-sm-x-85 {
        margin-left: 85px;
        margin-right: 85px
    }

    .mg-sm-x-85-f {
        margin-left: 85px !important;
        margin-right: 85px !important
    }

    .mg-sm-t-85 {
        margin-top: 85px
    }

    .mg-sm-r-85 {
        margin-right: 85px
    }

    .mg-sm-b-85 {
        margin-bottom: 85px
    }

    .mg-sm-l-85 {
        margin-left: 85px
    }

    .mg-sm-t-85-f {
        margin-top: 85px !important
    }

    .mg-sm-r-85-f {
        margin-right: 85px !important
    }

    .mg-sm-b-85-f {
        margin-bottom: 85px !important
    }

    .mg-sm-l-85-f {
        margin-left: 85px !important
    }

    .mg-sm-90 {
        margin: 90px
    }

    .mg-sm-90-f {
        margin: 90px !important
    }

    .mg-sm-y-90 {
        margin-top: 90px;
        margin-bottom: 90px
    }

    .mg-sm-y-90-f {
        margin-top: 90px !important;
        margin-bottom: 90px !important
    }

    .mg-sm-x-90 {
        margin-left: 90px;
        margin-right: 90px
    }

    .mg-sm-x-90-f {
        margin-left: 90px !important;
        margin-right: 90px !important
    }

    .mg-sm-t-90 {
        margin-top: 90px
    }

    .mg-sm-r-90 {
        margin-right: 90px
    }

    .mg-sm-b-90 {
        margin-bottom: 90px
    }

    .mg-sm-l-90 {
        margin-left: 90px
    }

    .mg-sm-t-90-f {
        margin-top: 90px !important
    }

    .mg-sm-r-90-f {
        margin-right: 90px !important
    }

    .mg-sm-b-90-f {
        margin-bottom: 90px !important
    }

    .mg-sm-l-90-f {
        margin-left: 90px !important
    }

    .mg-sm-95 {
        margin: 95px
    }

    .mg-sm-95-f {
        margin: 95px !important
    }

    .mg-sm-y-95 {
        margin-top: 95px;
        margin-bottom: 95px
    }

    .mg-sm-y-95-f {
        margin-top: 95px !important;
        margin-bottom: 95px !important
    }

    .mg-sm-x-95 {
        margin-left: 95px;
        margin-right: 95px
    }

    .mg-sm-x-95-f {
        margin-left: 95px !important;
        margin-right: 95px !important
    }

    .mg-sm-t-95 {
        margin-top: 95px
    }

    .mg-sm-r-95 {
        margin-right: 95px
    }

    .mg-sm-b-95 {
        margin-bottom: 95px
    }

    .mg-sm-l-95 {
        margin-left: 95px
    }

    .mg-sm-t-95-f {
        margin-top: 95px !important
    }

    .mg-sm-r-95-f {
        margin-right: 95px !important
    }

    .mg-sm-b-95-f {
        margin-bottom: 95px !important
    }

    .mg-sm-l-95-f {
        margin-left: 95px !important
    }

    .mg-sm-100 {
        margin: 100px
    }

    .mg-sm-100-f {
        margin: 100px !important
    }

    .mg-sm-y-100 {
        margin-top: 100px;
        margin-bottom: 100px
    }

    .mg-sm-y-100-f {
        margin-top: 100px !important;
        margin-bottom: 100px !important
    }

    .mg-sm-x-100 {
        margin-left: 100px;
        margin-right: 100px
    }

    .mg-sm-x-100-f {
        margin-left: 100px !important;
        margin-right: 100px !important
    }

    .mg-sm-t-100 {
        margin-top: 100px
    }

    .mg-sm-r-100 {
        margin-right: 100px
    }

    .mg-sm-b-100 {
        margin-bottom: 100px
    }

    .mg-sm-l-100 {
        margin-left: 100px
    }

    .mg-sm-t-100-f {
        margin-top: 100px !important
    }

    .mg-sm-r-100-f {
        margin-right: 100px !important
    }

    .mg-sm-b-100-f {
        margin-bottom: 100px !important
    }

    .mg-sm-l-100-f {
        margin-left: 100px !important
    }

    .mg-sm-150 {
        margin: 150px
    }

    .mg-sm-150-f {
        margin: 150px !important
    }

    .mg-sm-y-150 {
        margin-top: 150px;
        margin-bottom: 150px
    }

    .mg-sm-y-150-f {
        margin-top: 150px !important;
        margin-bottom: 150px !important
    }

    .mg-sm-x-150 {
        margin-left: 150px;
        margin-right: 150px
    }

    .mg-sm-x-150-f {
        margin-left: 150px !important;
        margin-right: 150px !important
    }

    .mg-sm-t-150 {
        margin-top: 150px
    }

    .mg-sm-r-150 {
        margin-right: 150px
    }

    .mg-sm-b-150 {
        margin-bottom: 150px
    }

    .mg-sm-l-150 {
        margin-left: 150px
    }

    .mg-sm-t-150-f {
        margin-top: 150px !important
    }

    .mg-sm-r-150-f {
        margin-right: 150px !important
    }

    .mg-sm-b-150-f {
        margin-bottom: 150px !important
    }

    .mg-sm-l-150-f {
        margin-left: 150px !important
    }

    .mg-sm-200 {
        margin: 200px
    }

    .mg-sm-200-f {
        margin: 200px !important
    }

    .mg-sm-y-200 {
        margin-top: 200px;
        margin-bottom: 200px
    }

    .mg-sm-y-200-f {
        margin-top: 200px !important;
        margin-bottom: 200px !important
    }

    .mg-sm-x-200 {
        margin-left: 200px;
        margin-right: 200px
    }

    .mg-sm-x-200-f {
        margin-left: 200px !important;
        margin-right: 200px !important
    }

    .mg-sm-t-200 {
        margin-top: 200px
    }

    .mg-sm-r-200 {
        margin-right: 200px
    }

    .mg-sm-b-200 {
        margin-bottom: 200px
    }

    .mg-sm-l-200 {
        margin-left: 200px
    }

    .mg-sm-t-200-f {
        margin-top: 200px !important
    }

    .mg-sm-r-200-f {
        margin-right: 200px !important
    }

    .mg-sm-b-200-f {
        margin-bottom: 200px !important
    }

    .mg-sm-l-200-f {
        margin-left: 200px !important
    }

    .mg-sm-250 {
        margin: 250px
    }

    .mg-sm-250-f {
        margin: 250px !important
    }

    .mg-sm-y-250 {
        margin-top: 250px;
        margin-bottom: 250px
    }

    .mg-sm-y-250-f {
        margin-top: 250px !important;
        margin-bottom: 250px !important
    }

    .mg-sm-x-250 {
        margin-left: 250px;
        margin-right: 250px
    }

    .mg-sm-x-250-f {
        margin-left: 250px !important;
        margin-right: 250px !important
    }

    .mg-sm-t-250 {
        margin-top: 250px
    }

    .mg-sm-r-250 {
        margin-right: 250px
    }

    .mg-sm-b-250 {
        margin-bottom: 250px
    }

    .mg-sm-l-250 {
        margin-left: 250px
    }

    .mg-sm-t-250-f {
        margin-top: 250px !important
    }

    .mg-sm-r-250-f {
        margin-right: 250px !important
    }

    .mg-sm-b-250-f {
        margin-bottom: 250px !important
    }

    .mg-sm-l-250-f {
        margin-left: 250px !important
    }

    .mg-sm-300 {
        margin: 300px
    }

    .mg-sm-300-f {
        margin: 300px !important
    }

    .mg-sm-y-300 {
        margin-top: 300px;
        margin-bottom: 300px
    }

    .mg-sm-y-300-f {
        margin-top: 300px !important;
        margin-bottom: 300px !important
    }

    .mg-sm-x-300 {
        margin-left: 300px;
        margin-right: 300px
    }

    .mg-sm-x-300-f {
        margin-left: 300px !important;
        margin-right: 300px !important
    }

    .mg-sm-t-300 {
        margin-top: 300px
    }

    .mg-sm-r-300 {
        margin-right: 300px
    }

    .mg-sm-b-300 {
        margin-bottom: 300px
    }

    .mg-sm-l-300 {
        margin-left: 300px
    }

    .mg-sm-t-300-f {
        margin-top: 300px !important
    }

    .mg-sm-r-300-f {
        margin-right: 300px !important
    }

    .mg-sm-b-300-f {
        margin-bottom: 300px !important
    }

    .mg-sm-l-300-f {
        margin-left: 300px !important
    }

    .mg-sm-350 {
        margin: 350px
    }

    .mg-sm-350-f {
        margin: 350px !important
    }

    .mg-sm-y-350 {
        margin-top: 350px;
        margin-bottom: 350px
    }

    .mg-sm-y-350-f {
        margin-top: 350px !important;
        margin-bottom: 350px !important
    }

    .mg-sm-x-350 {
        margin-left: 350px;
        margin-right: 350px
    }

    .mg-sm-x-350-f {
        margin-left: 350px !important;
        margin-right: 350px !important
    }

    .mg-sm-t-350 {
        margin-top: 350px
    }

    .mg-sm-r-350 {
        margin-right: 350px
    }

    .mg-sm-b-350 {
        margin-bottom: 350px
    }

    .mg-sm-l-350 {
        margin-left: 350px
    }

    .mg-sm-t-350-f {
        margin-top: 350px !important
    }

    .mg-sm-r-350-f {
        margin-right: 350px !important
    }

    .mg-sm-b-350-f {
        margin-bottom: 350px !important
    }

    .mg-sm-l-350-f {
        margin-left: 350px !important
    }

    .mg-sm-400 {
        margin: 400px
    }

    .mg-sm-400-f {
        margin: 400px !important
    }

    .mg-sm-y-400 {
        margin-top: 400px;
        margin-bottom: 400px
    }

    .mg-sm-y-400-f {
        margin-top: 400px !important;
        margin-bottom: 400px !important
    }

    .mg-sm-x-400 {
        margin-left: 400px;
        margin-right: 400px
    }

    .mg-sm-x-400-f {
        margin-left: 400px !important;
        margin-right: 400px !important
    }

    .mg-sm-t-400 {
        margin-top: 400px
    }

    .mg-sm-r-400 {
        margin-right: 400px
    }

    .mg-sm-b-400 {
        margin-bottom: 400px
    }

    .mg-sm-l-400 {
        margin-left: 400px
    }

    .mg-sm-t-400-f {
        margin-top: 400px !important
    }

    .mg-sm-r-400-f {
        margin-right: 400px !important
    }

    .mg-sm-b-400-f {
        margin-bottom: 400px !important
    }

    .mg-sm-l-400-f {
        margin-left: 400px !important
    }

    .mg-sm-450 {
        margin: 450px
    }

    .mg-sm-450-f {
        margin: 450px !important
    }

    .mg-sm-y-450 {
        margin-top: 450px;
        margin-bottom: 450px
    }

    .mg-sm-y-450-f {
        margin-top: 450px !important;
        margin-bottom: 450px !important
    }

    .mg-sm-x-450 {
        margin-left: 450px;
        margin-right: 450px
    }

    .mg-sm-x-450-f {
        margin-left: 450px !important;
        margin-right: 450px !important
    }

    .mg-sm-t-450 {
        margin-top: 450px
    }

    .mg-sm-r-450 {
        margin-right: 450px
    }

    .mg-sm-b-450 {
        margin-bottom: 450px
    }

    .mg-sm-l-450 {
        margin-left: 450px
    }

    .mg-sm-t-450-f {
        margin-top: 450px !important
    }

    .mg-sm-r-450-f {
        margin-right: 450px !important
    }

    .mg-sm-b-450-f {
        margin-bottom: 450px !important
    }

    .mg-sm-l-450-f {
        margin-left: 450px !important
    }

    .mg-sm-500 {
        margin: 500px
    }

    .mg-sm-500-f {
        margin: 500px !important
    }

    .mg-sm-y-500 {
        margin-top: 500px;
        margin-bottom: 500px
    }

    .mg-sm-y-500-f {
        margin-top: 500px !important;
        margin-bottom: 500px !important
    }

    .mg-sm-x-500 {
        margin-left: 500px;
        margin-right: 500px
    }

    .mg-sm-x-500-f {
        margin-left: 500px !important;
        margin-right: 500px !important
    }

    .mg-sm-t-500 {
        margin-top: 500px
    }

    .mg-sm-r-500 {
        margin-right: 500px
    }

    .mg-sm-b-500 {
        margin-bottom: 500px
    }

    .mg-sm-l-500 {
        margin-left: 500px
    }

    .mg-sm-t-500-f {
        margin-top: 500px !important
    }

    .mg-sm-r-500-f {
        margin-right: 500px !important
    }

    .mg-sm-b-500-f {
        margin-bottom: 500px !important
    }

    .mg-sm-l-500-f {
        margin-left: 500px !important
    }

    .mg-sm-550 {
        margin: 550px
    }

    .mg-sm-550-f {
        margin: 550px !important
    }

    .mg-sm-y-550 {
        margin-top: 550px;
        margin-bottom: 550px
    }

    .mg-sm-y-550-f {
        margin-top: 550px !important;
        margin-bottom: 550px !important
    }

    .mg-sm-x-550 {
        margin-left: 550px;
        margin-right: 550px
    }

    .mg-sm-x-550-f {
        margin-left: 550px !important;
        margin-right: 550px !important
    }

    .mg-sm-t-550 {
        margin-top: 550px
    }

    .mg-sm-r-550 {
        margin-right: 550px
    }

    .mg-sm-b-550 {
        margin-bottom: 550px
    }

    .mg-sm-l-550 {
        margin-left: 550px
    }

    .mg-sm-t-550-f {
        margin-top: 550px !important
    }

    .mg-sm-r-550-f {
        margin-right: 550px !important
    }

    .mg-sm-b-550-f {
        margin-bottom: 550px !important
    }

    .mg-sm-l-550-f {
        margin-left: 550px !important
    }

    .mg-sm-600 {
        margin: 600px
    }

    .mg-sm-600-f {
        margin: 600px !important
    }

    .mg-sm-y-600 {
        margin-top: 600px;
        margin-bottom: 600px
    }

    .mg-sm-y-600-f {
        margin-top: 600px !important;
        margin-bottom: 600px !important
    }

    .mg-sm-x-600 {
        margin-left: 600px;
        margin-right: 600px
    }

    .mg-sm-x-600-f {
        margin-left: 600px !important;
        margin-right: 600px !important
    }

    .mg-sm-t-600 {
        margin-top: 600px
    }

    .mg-sm-r-600 {
        margin-right: 600px
    }

    .mg-sm-b-600 {
        margin-bottom: 600px
    }

    .mg-sm-l-600 {
        margin-left: 600px
    }

    .mg-sm-t-600-f {
        margin-top: 600px !important
    }

    .mg-sm-r-600-f {
        margin-right: 600px !important
    }

    .mg-sm-b-600-f {
        margin-bottom: 600px !important
    }

    .mg-sm-l-600-f {
        margin-left: 600px !important
    }

    .mg-sm-650 {
        margin: 650px
    }

    .mg-sm-650-f {
        margin: 650px !important
    }

    .mg-sm-y-650 {
        margin-top: 650px;
        margin-bottom: 650px
    }

    .mg-sm-y-650-f {
        margin-top: 650px !important;
        margin-bottom: 650px !important
    }

    .mg-sm-x-650 {
        margin-left: 650px;
        margin-right: 650px
    }

    .mg-sm-x-650-f {
        margin-left: 650px !important;
        margin-right: 650px !important
    }

    .mg-sm-t-650 {
        margin-top: 650px
    }

    .mg-sm-r-650 {
        margin-right: 650px
    }

    .mg-sm-b-650 {
        margin-bottom: 650px
    }

    .mg-sm-l-650 {
        margin-left: 650px
    }

    .mg-sm-t-650-f {
        margin-top: 650px !important
    }

    .mg-sm-r-650-f {
        margin-right: 650px !important
    }

    .mg-sm-b-650-f {
        margin-bottom: 650px !important
    }

    .mg-sm-l-650-f {
        margin-left: 650px !important
    }

    .mg-sm-t-auto {
        margin-top: auto
    }

    .mg-sm-r-auto {
        margin-right: auto
    }

    .mg-sm-b-auto {
        margin-bottom: auto
    }

    .mg-sm-l-auto {
        margin-left: auto
    }

    .mg-sm-auto {
        margin: auto
    }

    .mg-sm-x-auto {
        margin-left: auto;
        margin-right: auto
    }
}

@media (min-width: 768px) {
    .mg-md-0 {
        margin: 0px
    }

    .mg-md-0-f {
        margin: 0px !important
    }

    .mg-md-y-0 {
        margin-top: 0px;
        margin-bottom: 0px
    }

    .mg-md-y-0-f {
        margin-top: 0px !important;
        margin-bottom: 0px !important
    }

    .mg-md-x-0 {
        margin-left: 0px;
        margin-right: 0px
    }

    .mg-md-x-0-f {
        margin-left: 0px !important;
        margin-right: 0px !important
    }

    .mg-md-t-0 {
        margin-top: 0px
    }

    .mg-md-r-0 {
        margin-right: 0px
    }

    .mg-md-b-0 {
        margin-bottom: 0px
    }

    .mg-md-l-0 {
        margin-left: 0px
    }

    .mg-md-t-0-f {
        margin-top: 0px !important
    }

    .mg-md-r-0-f {
        margin-right: 0px !important
    }

    .mg-md-b-0-f {
        margin-bottom: 0px !important
    }

    .mg-md-l-0-f {
        margin-left: 0px !important
    }

    .mg-md-1 {
        margin: 1px
    }

    .mg-md-1-f {
        margin: 1px !important
    }

    .mg-md-y-1 {
        margin-top: 1px;
        margin-bottom: 1px
    }

    .mg-md-y-1-f {
        margin-top: 1px !important;
        margin-bottom: 1px !important
    }

    .mg-md-x-1 {
        margin-left: 1px;
        margin-right: 1px
    }

    .mg-md-x-1-f {
        margin-left: 1px !important;
        margin-right: 1px !important
    }

    .mg-md-t-1 {
        margin-top: 1px
    }

    .mg-md-r-1 {
        margin-right: 1px
    }

    .mg-md-b-1 {
        margin-bottom: 1px
    }

    .mg-md-l-1 {
        margin-left: 1px
    }

    .mg-md-t-1-f {
        margin-top: 1px !important
    }

    .mg-md-r-1-f {
        margin-right: 1px !important
    }

    .mg-md-b-1-f {
        margin-bottom: 1px !important
    }

    .mg-md-l-1-f {
        margin-left: 1px !important
    }

    .mg-md-2 {
        margin: 2px
    }

    .mg-md-2-f {
        margin: 2px !important
    }

    .mg-md-y-2 {
        margin-top: 2px;
        margin-bottom: 2px
    }

    .mg-md-y-2-f {
        margin-top: 2px !important;
        margin-bottom: 2px !important
    }

    .mg-md-x-2 {
        margin-left: 2px;
        margin-right: 2px
    }

    .mg-md-x-2-f {
        margin-left: 2px !important;
        margin-right: 2px !important
    }

    .mg-md-t-2 {
        margin-top: 2px
    }

    .mg-md-r-2 {
        margin-right: 2px
    }

    .mg-md-b-2 {
        margin-bottom: 2px
    }

    .mg-md-l-2 {
        margin-left: 2px
    }

    .mg-md-t-2-f {
        margin-top: 2px !important
    }

    .mg-md-r-2-f {
        margin-right: 2px !important
    }

    .mg-md-b-2-f {
        margin-bottom: 2px !important
    }

    .mg-md-l-2-f {
        margin-left: 2px !important
    }

    .mg-md-3 {
        margin: 3px
    }

    .mg-md-3-f {
        margin: 3px !important
    }

    .mg-md-y-3 {
        margin-top: 3px;
        margin-bottom: 3px
    }

    .mg-md-y-3-f {
        margin-top: 3px !important;
        margin-bottom: 3px !important
    }

    .mg-md-x-3 {
        margin-left: 3px;
        margin-right: 3px
    }

    .mg-md-x-3-f {
        margin-left: 3px !important;
        margin-right: 3px !important
    }

    .mg-md-t-3 {
        margin-top: 3px
    }

    .mg-md-r-3 {
        margin-right: 3px
    }

    .mg-md-b-3 {
        margin-bottom: 3px
    }

    .mg-md-l-3 {
        margin-left: 3px
    }

    .mg-md-t-3-f {
        margin-top: 3px !important
    }

    .mg-md-r-3-f {
        margin-right: 3px !important
    }

    .mg-md-b-3-f {
        margin-bottom: 3px !important
    }

    .mg-md-l-3-f {
        margin-left: 3px !important
    }

    .mg-md-4 {
        margin: 4px
    }

    .mg-md-4-f {
        margin: 4px !important
    }

    .mg-md-y-4 {
        margin-top: 4px;
        margin-bottom: 4px
    }

    .mg-md-y-4-f {
        margin-top: 4px !important;
        margin-bottom: 4px !important
    }

    .mg-md-x-4 {
        margin-left: 4px;
        margin-right: 4px
    }

    .mg-md-x-4-f {
        margin-left: 4px !important;
        margin-right: 4px !important
    }

    .mg-md-t-4 {
        margin-top: 4px
    }

    .mg-md-r-4 {
        margin-right: 4px
    }

    .mg-md-b-4 {
        margin-bottom: 4px
    }

    .mg-md-l-4 {
        margin-left: 4px
    }

    .mg-md-t-4-f {
        margin-top: 4px !important
    }

    .mg-md-r-4-f {
        margin-right: 4px !important
    }

    .mg-md-b-4-f {
        margin-bottom: 4px !important
    }

    .mg-md-l-4-f {
        margin-left: 4px !important
    }

    .mg-md-5 {
        margin: 5px
    }

    .mg-md-5-f {
        margin: 5px !important
    }

    .mg-md-y-5 {
        margin-top: 5px;
        margin-bottom: 5px
    }

    .mg-md-y-5-f {
        margin-top: 5px !important;
        margin-bottom: 5px !important
    }

    .mg-md-x-5 {
        margin-left: 5px;
        margin-right: 5px
    }

    .mg-md-x-5-f {
        margin-left: 5px !important;
        margin-right: 5px !important
    }

    .mg-md-t-5 {
        margin-top: 5px
    }

    .mg-md-r-5 {
        margin-right: 5px
    }

    .mg-md-b-5 {
        margin-bottom: 5px
    }

    .mg-md-l-5 {
        margin-left: 5px
    }

    .mg-md-t-5-f {
        margin-top: 5px !important
    }

    .mg-md-r-5-f {
        margin-right: 5px !important
    }

    .mg-md-b-5-f {
        margin-bottom: 5px !important
    }

    .mg-md-l-5-f {
        margin-left: 5px !important
    }

    .mg-md-6 {
        margin: 6px
    }

    .mg-md-6-f {
        margin: 6px !important
    }

    .mg-md-y-6 {
        margin-top: 6px;
        margin-bottom: 6px
    }

    .mg-md-y-6-f {
        margin-top: 6px !important;
        margin-bottom: 6px !important
    }

    .mg-md-x-6 {
        margin-left: 6px;
        margin-right: 6px
    }

    .mg-md-x-6-f {
        margin-left: 6px !important;
        margin-right: 6px !important
    }

    .mg-md-t-6 {
        margin-top: 6px
    }

    .mg-md-r-6 {
        margin-right: 6px
    }

    .mg-md-b-6 {
        margin-bottom: 6px
    }

    .mg-md-l-6 {
        margin-left: 6px
    }

    .mg-md-t-6-f {
        margin-top: 6px !important
    }

    .mg-md-r-6-f {
        margin-right: 6px !important
    }

    .mg-md-b-6-f {
        margin-bottom: 6px !important
    }

    .mg-md-l-6-f {
        margin-left: 6px !important
    }

    .mg-md-7 {
        margin: 7px
    }

    .mg-md-7-f {
        margin: 7px !important
    }

    .mg-md-y-7 {
        margin-top: 7px;
        margin-bottom: 7px
    }

    .mg-md-y-7-f {
        margin-top: 7px !important;
        margin-bottom: 7px !important
    }

    .mg-md-x-7 {
        margin-left: 7px;
        margin-right: 7px
    }

    .mg-md-x-7-f {
        margin-left: 7px !important;
        margin-right: 7px !important
    }

    .mg-md-t-7 {
        margin-top: 7px
    }

    .mg-md-r-7 {
        margin-right: 7px
    }

    .mg-md-b-7 {
        margin-bottom: 7px
    }

    .mg-md-l-7 {
        margin-left: 7px
    }

    .mg-md-t-7-f {
        margin-top: 7px !important
    }

    .mg-md-r-7-f {
        margin-right: 7px !important
    }

    .mg-md-b-7-f {
        margin-bottom: 7px !important
    }

    .mg-md-l-7-f {
        margin-left: 7px !important
    }

    .mg-md-8 {
        margin: 8px
    }

    .mg-md-8-f {
        margin: 8px !important
    }

    .mg-md-y-8 {
        margin-top: 8px;
        margin-bottom: 8px
    }

    .mg-md-y-8-f {
        margin-top: 8px !important;
        margin-bottom: 8px !important
    }

    .mg-md-x-8 {
        margin-left: 8px;
        margin-right: 8px
    }

    .mg-md-x-8-f {
        margin-left: 8px !important;
        margin-right: 8px !important
    }

    .mg-md-t-8 {
        margin-top: 8px
    }

    .mg-md-r-8 {
        margin-right: 8px
    }

    .mg-md-b-8 {
        margin-bottom: 8px
    }

    .mg-md-l-8 {
        margin-left: 8px
    }

    .mg-md-t-8-f {
        margin-top: 8px !important
    }

    .mg-md-r-8-f {
        margin-right: 8px !important
    }

    .mg-md-b-8-f {
        margin-bottom: 8px !important
    }

    .mg-md-l-8-f {
        margin-left: 8px !important
    }

    .mg-md-9 {
        margin: 9px
    }

    .mg-md-9-f {
        margin: 9px !important
    }

    .mg-md-y-9 {
        margin-top: 9px;
        margin-bottom: 9px
    }

    .mg-md-y-9-f {
        margin-top: 9px !important;
        margin-bottom: 9px !important
    }

    .mg-md-x-9 {
        margin-left: 9px;
        margin-right: 9px
    }

    .mg-md-x-9-f {
        margin-left: 9px !important;
        margin-right: 9px !important
    }

    .mg-md-t-9 {
        margin-top: 9px
    }

    .mg-md-r-9 {
        margin-right: 9px
    }

    .mg-md-b-9 {
        margin-bottom: 9px
    }

    .mg-md-l-9 {
        margin-left: 9px
    }

    .mg-md-t-9-f {
        margin-top: 9px !important
    }

    .mg-md-r-9-f {
        margin-right: 9px !important
    }

    .mg-md-b-9-f {
        margin-bottom: 9px !important
    }

    .mg-md-l-9-f {
        margin-left: 9px !important
    }

    .mg-md-10 {
        margin: 10px
    }

    .mg-md-10-f {
        margin: 10px !important
    }

    .mg-md-y-10 {
        margin-top: 10px;
        margin-bottom: 10px
    }

    .mg-md-y-10-f {
        margin-top: 10px !important;
        margin-bottom: 10px !important
    }

    .mg-md-x-10 {
        margin-left: 10px;
        margin-right: 10px
    }

    .mg-md-x-10-f {
        margin-left: 10px !important;
        margin-right: 10px !important
    }

    .mg-md-t-10 {
        margin-top: 10px
    }

    .mg-md-r-10 {
        margin-right: 10px
    }

    .mg-md-b-10 {
        margin-bottom: 10px
    }

    .mg-md-l-10 {
        margin-left: 10px
    }

    .mg-md-t-10-f {
        margin-top: 10px !important
    }

    .mg-md-r-10-f {
        margin-right: 10px !important
    }

    .mg-md-b-10-f {
        margin-bottom: 10px !important
    }

    .mg-md-l-10-f {
        margin-left: 10px !important
    }

    .mg-md-15 {
        margin: 15px
    }

    .mg-md-15-f {
        margin: 15px !important
    }

    .mg-md-y-15 {
        margin-top: 15px;
        margin-bottom: 15px
    }

    .mg-md-y-15-f {
        margin-top: 15px !important;
        margin-bottom: 15px !important
    }

    .mg-md-x-15 {
        margin-left: 15px;
        margin-right: 15px
    }

    .mg-md-x-15-f {
        margin-left: 15px !important;
        margin-right: 15px !important
    }

    .mg-md-t-15 {
        margin-top: 15px
    }

    .mg-md-r-15 {
        margin-right: 15px
    }

    .mg-md-b-15 {
        margin-bottom: 15px
    }

    .mg-md-l-15 {
        margin-left: 15px
    }

    .mg-md-t-15-f {
        margin-top: 15px !important
    }

    .mg-md-r-15-f {
        margin-right: 15px !important
    }

    .mg-md-b-15-f {
        margin-bottom: 15px !important
    }

    .mg-md-l-15-f {
        margin-left: 15px !important
    }

    .mg-md-20 {
        margin: 20px
    }

    .mg-md-20-f {
        margin: 20px !important
    }

    .mg-md-y-20 {
        margin-top: 20px;
        margin-bottom: 20px
    }

    .mg-md-y-20-f {
        margin-top: 20px !important;
        margin-bottom: 20px !important
    }

    .mg-md-x-20 {
        margin-left: 20px;
        margin-right: 20px
    }

    .mg-md-x-20-f {
        margin-left: 20px !important;
        margin-right: 20px !important
    }

    .mg-md-t-20 {
        margin-top: 20px
    }

    .mg-md-r-20 {
        margin-right: 20px
    }

    .mg-md-b-20 {
        margin-bottom: 20px
    }

    .mg-md-l-20 {
        margin-left: 20px
    }

    .mg-md-t-20-f {
        margin-top: 20px !important
    }

    .mg-md-r-20-f {
        margin-right: 20px !important
    }

    .mg-md-b-20-f {
        margin-bottom: 20px !important
    }

    .mg-md-l-20-f {
        margin-left: 20px !important
    }

    .mg-md-25 {
        margin: 25px
    }

    .mg-md-25-f {
        margin: 25px !important
    }

    .mg-md-y-25 {
        margin-top: 25px;
        margin-bottom: 25px
    }

    .mg-md-y-25-f {
        margin-top: 25px !important;
        margin-bottom: 25px !important
    }

    .mg-md-x-25 {
        margin-left: 25px;
        margin-right: 25px
    }

    .mg-md-x-25-f {
        margin-left: 25px !important;
        margin-right: 25px !important
    }

    .mg-md-t-25 {
        margin-top: 25px
    }

    .mg-md-r-25 {
        margin-right: 25px
    }

    .mg-md-b-25 {
        margin-bottom: 25px
    }

    .mg-md-l-25 {
        margin-left: 25px
    }

    .mg-md-t-25-f {
        margin-top: 25px !important
    }

    .mg-md-r-25-f {
        margin-right: 25px !important
    }

    .mg-md-b-25-f {
        margin-bottom: 25px !important
    }

    .mg-md-l-25-f {
        margin-left: 25px !important
    }

    .mg-md-30 {
        margin: 30px
    }

    .mg-md-30-f {
        margin: 30px !important
    }

    .mg-md-y-30 {
        margin-top: 30px;
        margin-bottom: 30px
    }

    .mg-md-y-30-f {
        margin-top: 30px !important;
        margin-bottom: 30px !important
    }

    .mg-md-x-30 {
        margin-left: 30px;
        margin-right: 30px
    }

    .mg-md-x-30-f {
        margin-left: 30px !important;
        margin-right: 30px !important
    }

    .mg-md-t-30 {
        margin-top: 30px
    }

    .mg-md-r-30 {
        margin-right: 30px
    }

    .mg-md-b-30 {
        margin-bottom: 30px
    }

    .mg-md-l-30 {
        margin-left: 30px
    }

    .mg-md-t-30-f {
        margin-top: 30px !important
    }

    .mg-md-r-30-f {
        margin-right: 30px !important
    }

    .mg-md-b-30-f {
        margin-bottom: 30px !important
    }

    .mg-md-l-30-f {
        margin-left: 30px !important
    }

    .mg-md-35 {
        margin: 35px
    }

    .mg-md-35-f {
        margin: 35px !important
    }

    .mg-md-y-35 {
        margin-top: 35px;
        margin-bottom: 35px
    }

    .mg-md-y-35-f {
        margin-top: 35px !important;
        margin-bottom: 35px !important
    }

    .mg-md-x-35 {
        margin-left: 35px;
        margin-right: 35px
    }

    .mg-md-x-35-f {
        margin-left: 35px !important;
        margin-right: 35px !important
    }

    .mg-md-t-35 {
        margin-top: 35px
    }

    .mg-md-r-35 {
        margin-right: 35px
    }

    .mg-md-b-35 {
        margin-bottom: 35px
    }

    .mg-md-l-35 {
        margin-left: 35px
    }

    .mg-md-t-35-f {
        margin-top: 35px !important
    }

    .mg-md-r-35-f {
        margin-right: 35px !important
    }

    .mg-md-b-35-f {
        margin-bottom: 35px !important
    }

    .mg-md-l-35-f {
        margin-left: 35px !important
    }

    .mg-md-40 {
        margin: 40px
    }

    .mg-md-40-f {
        margin: 40px !important
    }

    .mg-md-y-40 {
        margin-top: 40px;
        margin-bottom: 40px
    }

    .mg-md-y-40-f {
        margin-top: 40px !important;
        margin-bottom: 40px !important
    }

    .mg-md-x-40 {
        margin-left: 40px;
        margin-right: 40px
    }

    .mg-md-x-40-f {
        margin-left: 40px !important;
        margin-right: 40px !important
    }

    .mg-md-t-40 {
        margin-top: 40px
    }

    .mg-md-r-40 {
        margin-right: 40px
    }

    .mg-md-b-40 {
        margin-bottom: 40px
    }

    .mg-md-l-40 {
        margin-left: 40px
    }

    .mg-md-t-40-f {
        margin-top: 40px !important
    }

    .mg-md-r-40-f {
        margin-right: 40px !important
    }

    .mg-md-b-40-f {
        margin-bottom: 40px !important
    }

    .mg-md-l-40-f {
        margin-left: 40px !important
    }

    .mg-md-45 {
        margin: 45px
    }

    .mg-md-45-f {
        margin: 45px !important
    }

    .mg-md-y-45 {
        margin-top: 45px;
        margin-bottom: 45px
    }

    .mg-md-y-45-f {
        margin-top: 45px !important;
        margin-bottom: 45px !important
    }

    .mg-md-x-45 {
        margin-left: 45px;
        margin-right: 45px
    }

    .mg-md-x-45-f {
        margin-left: 45px !important;
        margin-right: 45px !important
    }

    .mg-md-t-45 {
        margin-top: 45px
    }

    .mg-md-r-45 {
        margin-right: 45px
    }

    .mg-md-b-45 {
        margin-bottom: 45px
    }

    .mg-md-l-45 {
        margin-left: 45px
    }

    .mg-md-t-45-f {
        margin-top: 45px !important
    }

    .mg-md-r-45-f {
        margin-right: 45px !important
    }

    .mg-md-b-45-f {
        margin-bottom: 45px !important
    }

    .mg-md-l-45-f {
        margin-left: 45px !important
    }

    .mg-md-50 {
        margin: 50px
    }

    .mg-md-50-f {
        margin: 50px !important
    }

    .mg-md-y-50 {
        margin-top: 50px;
        margin-bottom: 50px
    }

    .mg-md-y-50-f {
        margin-top: 50px !important;
        margin-bottom: 50px !important
    }

    .mg-md-x-50 {
        margin-left: 50px;
        margin-right: 50px
    }

    .mg-md-x-50-f {
        margin-left: 50px !important;
        margin-right: 50px !important
    }

    .mg-md-t-50 {
        margin-top: 50px
    }

    .mg-md-r-50 {
        margin-right: 50px
    }

    .mg-md-b-50 {
        margin-bottom: 50px
    }

    .mg-md-l-50 {
        margin-left: 50px
    }

    .mg-md-t-50-f {
        margin-top: 50px !important
    }

    .mg-md-r-50-f {
        margin-right: 50px !important
    }

    .mg-md-b-50-f {
        margin-bottom: 50px !important
    }

    .mg-md-l-50-f {
        margin-left: 50px !important
    }

    .mg-md-55 {
        margin: 55px
    }

    .mg-md-55-f {
        margin: 55px !important
    }

    .mg-md-y-55 {
        margin-top: 55px;
        margin-bottom: 55px
    }

    .mg-md-y-55-f {
        margin-top: 55px !important;
        margin-bottom: 55px !important
    }

    .mg-md-x-55 {
        margin-left: 55px;
        margin-right: 55px
    }

    .mg-md-x-55-f {
        margin-left: 55px !important;
        margin-right: 55px !important
    }

    .mg-md-t-55 {
        margin-top: 55px
    }

    .mg-md-r-55 {
        margin-right: 55px
    }

    .mg-md-b-55 {
        margin-bottom: 55px
    }

    .mg-md-l-55 {
        margin-left: 55px
    }

    .mg-md-t-55-f {
        margin-top: 55px !important
    }

    .mg-md-r-55-f {
        margin-right: 55px !important
    }

    .mg-md-b-55-f {
        margin-bottom: 55px !important
    }

    .mg-md-l-55-f {
        margin-left: 55px !important
    }

    .mg-md-60 {
        margin: 60px
    }

    .mg-md-60-f {
        margin: 60px !important
    }

    .mg-md-y-60 {
        margin-top: 60px;
        margin-bottom: 60px
    }

    .mg-md-y-60-f {
        margin-top: 60px !important;
        margin-bottom: 60px !important
    }

    .mg-md-x-60 {
        margin-left: 60px;
        margin-right: 60px
    }

    .mg-md-x-60-f {
        margin-left: 60px !important;
        margin-right: 60px !important
    }

    .mg-md-t-60 {
        margin-top: 60px
    }

    .mg-md-r-60 {
        margin-right: 60px
    }

    .mg-md-b-60 {
        margin-bottom: 60px
    }

    .mg-md-l-60 {
        margin-left: 60px
    }

    .mg-md-t-60-f {
        margin-top: 60px !important
    }

    .mg-md-r-60-f {
        margin-right: 60px !important
    }

    .mg-md-b-60-f {
        margin-bottom: 60px !important
    }

    .mg-md-l-60-f {
        margin-left: 60px !important
    }

    .mg-md-65 {
        margin: 65px
    }

    .mg-md-65-f {
        margin: 65px !important
    }

    .mg-md-y-65 {
        margin-top: 65px;
        margin-bottom: 65px
    }

    .mg-md-y-65-f {
        margin-top: 65px !important;
        margin-bottom: 65px !important
    }

    .mg-md-x-65 {
        margin-left: 65px;
        margin-right: 65px
    }

    .mg-md-x-65-f {
        margin-left: 65px !important;
        margin-right: 65px !important
    }

    .mg-md-t-65 {
        margin-top: 65px
    }

    .mg-md-r-65 {
        margin-right: 65px
    }

    .mg-md-b-65 {
        margin-bottom: 65px
    }

    .mg-md-l-65 {
        margin-left: 65px
    }

    .mg-md-t-65-f {
        margin-top: 65px !important
    }

    .mg-md-r-65-f {
        margin-right: 65px !important
    }

    .mg-md-b-65-f {
        margin-bottom: 65px !important
    }

    .mg-md-l-65-f {
        margin-left: 65px !important
    }

    .mg-md-70 {
        margin: 70px
    }

    .mg-md-70-f {
        margin: 70px !important
    }

    .mg-md-y-70 {
        margin-top: 70px;
        margin-bottom: 70px
    }

    .mg-md-y-70-f {
        margin-top: 70px !important;
        margin-bottom: 70px !important
    }

    .mg-md-x-70 {
        margin-left: 70px;
        margin-right: 70px
    }

    .mg-md-x-70-f {
        margin-left: 70px !important;
        margin-right: 70px !important
    }

    .mg-md-t-70 {
        margin-top: 70px
    }

    .mg-md-r-70 {
        margin-right: 70px
    }

    .mg-md-b-70 {
        margin-bottom: 70px
    }

    .mg-md-l-70 {
        margin-left: 70px
    }

    .mg-md-t-70-f {
        margin-top: 70px !important
    }

    .mg-md-r-70-f {
        margin-right: 70px !important
    }

    .mg-md-b-70-f {
        margin-bottom: 70px !important
    }

    .mg-md-l-70-f {
        margin-left: 70px !important
    }

    .mg-md-75 {
        margin: 75px
    }

    .mg-md-75-f {
        margin: 75px !important
    }

    .mg-md-y-75 {
        margin-top: 75px;
        margin-bottom: 75px
    }

    .mg-md-y-75-f {
        margin-top: 75px !important;
        margin-bottom: 75px !important
    }

    .mg-md-x-75 {
        margin-left: 75px;
        margin-right: 75px
    }

    .mg-md-x-75-f {
        margin-left: 75px !important;
        margin-right: 75px !important
    }

    .mg-md-t-75 {
        margin-top: 75px
    }

    .mg-md-r-75 {
        margin-right: 75px
    }

    .mg-md-b-75 {
        margin-bottom: 75px
    }

    .mg-md-l-75 {
        margin-left: 75px
    }

    .mg-md-t-75-f {
        margin-top: 75px !important
    }

    .mg-md-r-75-f {
        margin-right: 75px !important
    }

    .mg-md-b-75-f {
        margin-bottom: 75px !important
    }

    .mg-md-l-75-f {
        margin-left: 75px !important
    }

    .mg-md-80 {
        margin: 80px
    }

    .mg-md-80-f {
        margin: 80px !important
    }

    .mg-md-y-80 {
        margin-top: 80px;
        margin-bottom: 80px
    }

    .mg-md-y-80-f {
        margin-top: 80px !important;
        margin-bottom: 80px !important
    }

    .mg-md-x-80 {
        margin-left: 80px;
        margin-right: 80px
    }

    .mg-md-x-80-f {
        margin-left: 80px !important;
        margin-right: 80px !important
    }

    .mg-md-t-80 {
        margin-top: 80px
    }

    .mg-md-r-80 {
        margin-right: 80px
    }

    .mg-md-b-80 {
        margin-bottom: 80px
    }

    .mg-md-l-80 {
        margin-left: 80px
    }

    .mg-md-t-80-f {
        margin-top: 80px !important
    }

    .mg-md-r-80-f {
        margin-right: 80px !important
    }

    .mg-md-b-80-f {
        margin-bottom: 80px !important
    }

    .mg-md-l-80-f {
        margin-left: 80px !important
    }

    .mg-md-85 {
        margin: 85px
    }

    .mg-md-85-f {
        margin: 85px !important
    }

    .mg-md-y-85 {
        margin-top: 85px;
        margin-bottom: 85px
    }

    .mg-md-y-85-f {
        margin-top: 85px !important;
        margin-bottom: 85px !important
    }

    .mg-md-x-85 {
        margin-left: 85px;
        margin-right: 85px
    }

    .mg-md-x-85-f {
        margin-left: 85px !important;
        margin-right: 85px !important
    }

    .mg-md-t-85 {
        margin-top: 85px
    }

    .mg-md-r-85 {
        margin-right: 85px
    }

    .mg-md-b-85 {
        margin-bottom: 85px
    }

    .mg-md-l-85 {
        margin-left: 85px
    }

    .mg-md-t-85-f {
        margin-top: 85px !important
    }

    .mg-md-r-85-f {
        margin-right: 85px !important
    }

    .mg-md-b-85-f {
        margin-bottom: 85px !important
    }

    .mg-md-l-85-f {
        margin-left: 85px !important
    }

    .mg-md-90 {
        margin: 90px
    }

    .mg-md-90-f {
        margin: 90px !important
    }

    .mg-md-y-90 {
        margin-top: 90px;
        margin-bottom: 90px
    }

    .mg-md-y-90-f {
        margin-top: 90px !important;
        margin-bottom: 90px !important
    }

    .mg-md-x-90 {
        margin-left: 90px;
        margin-right: 90px
    }

    .mg-md-x-90-f {
        margin-left: 90px !important;
        margin-right: 90px !important
    }

    .mg-md-t-90 {
        margin-top: 90px
    }

    .mg-md-r-90 {
        margin-right: 90px
    }

    .mg-md-b-90 {
        margin-bottom: 90px
    }

    .mg-md-l-90 {
        margin-left: 90px
    }

    .mg-md-t-90-f {
        margin-top: 90px !important
    }

    .mg-md-r-90-f {
        margin-right: 90px !important
    }

    .mg-md-b-90-f {
        margin-bottom: 90px !important
    }

    .mg-md-l-90-f {
        margin-left: 90px !important
    }

    .mg-md-95 {
        margin: 95px
    }

    .mg-md-95-f {
        margin: 95px !important
    }

    .mg-md-y-95 {
        margin-top: 95px;
        margin-bottom: 95px
    }

    .mg-md-y-95-f {
        margin-top: 95px !important;
        margin-bottom: 95px !important
    }

    .mg-md-x-95 {
        margin-left: 95px;
        margin-right: 95px
    }

    .mg-md-x-95-f {
        margin-left: 95px !important;
        margin-right: 95px !important
    }

    .mg-md-t-95 {
        margin-top: 95px
    }

    .mg-md-r-95 {
        margin-right: 95px
    }

    .mg-md-b-95 {
        margin-bottom: 95px
    }

    .mg-md-l-95 {
        margin-left: 95px
    }

    .mg-md-t-95-f {
        margin-top: 95px !important
    }

    .mg-md-r-95-f {
        margin-right: 95px !important
    }

    .mg-md-b-95-f {
        margin-bottom: 95px !important
    }

    .mg-md-l-95-f {
        margin-left: 95px !important
    }

    .mg-md-100 {
        margin: 100px
    }

    .mg-md-100-f {
        margin: 100px !important
    }

    .mg-md-y-100 {
        margin-top: 100px;
        margin-bottom: 100px
    }

    .mg-md-y-100-f {
        margin-top: 100px !important;
        margin-bottom: 100px !important
    }

    .mg-md-x-100 {
        margin-left: 100px;
        margin-right: 100px
    }

    .mg-md-x-100-f {
        margin-left: 100px !important;
        margin-right: 100px !important
    }

    .mg-md-t-100 {
        margin-top: 100px
    }

    .mg-md-r-100 {
        margin-right: 100px
    }

    .mg-md-b-100 {
        margin-bottom: 100px
    }

    .mg-md-l-100 {
        margin-left: 100px
    }

    .mg-md-t-100-f {
        margin-top: 100px !important
    }

    .mg-md-r-100-f {
        margin-right: 100px !important
    }

    .mg-md-b-100-f {
        margin-bottom: 100px !important
    }

    .mg-md-l-100-f {
        margin-left: 100px !important
    }

    .mg-md-150 {
        margin: 150px
    }

    .mg-md-150-f {
        margin: 150px !important
    }

    .mg-md-y-150 {
        margin-top: 150px;
        margin-bottom: 150px
    }

    .mg-md-y-150-f {
        margin-top: 150px !important;
        margin-bottom: 150px !important
    }

    .mg-md-x-150 {
        margin-left: 150px;
        margin-right: 150px
    }

    .mg-md-x-150-f {
        margin-left: 150px !important;
        margin-right: 150px !important
    }

    .mg-md-t-150 {
        margin-top: 150px
    }

    .mg-md-r-150 {
        margin-right: 150px
    }

    .mg-md-b-150 {
        margin-bottom: 150px
    }

    .mg-md-l-150 {
        margin-left: 150px
    }

    .mg-md-t-150-f {
        margin-top: 150px !important
    }

    .mg-md-r-150-f {
        margin-right: 150px !important
    }

    .mg-md-b-150-f {
        margin-bottom: 150px !important
    }

    .mg-md-l-150-f {
        margin-left: 150px !important
    }

    .mg-md-200 {
        margin: 200px
    }

    .mg-md-200-f {
        margin: 200px !important
    }

    .mg-md-y-200 {
        margin-top: 200px;
        margin-bottom: 200px
    }

    .mg-md-y-200-f {
        margin-top: 200px !important;
        margin-bottom: 200px !important
    }

    .mg-md-x-200 {
        margin-left: 200px;
        margin-right: 200px
    }

    .mg-md-x-200-f {
        margin-left: 200px !important;
        margin-right: 200px !important
    }

    .mg-md-t-200 {
        margin-top: 200px
    }

    .mg-md-r-200 {
        margin-right: 200px
    }

    .mg-md-b-200 {
        margin-bottom: 200px
    }

    .mg-md-l-200 {
        margin-left: 200px
    }

    .mg-md-t-200-f {
        margin-top: 200px !important
    }

    .mg-md-r-200-f {
        margin-right: 200px !important
    }

    .mg-md-b-200-f {
        margin-bottom: 200px !important
    }

    .mg-md-l-200-f {
        margin-left: 200px !important
    }

    .mg-md-250 {
        margin: 250px
    }

    .mg-md-250-f {
        margin: 250px !important
    }

    .mg-md-y-250 {
        margin-top: 250px;
        margin-bottom: 250px
    }

    .mg-md-y-250-f {
        margin-top: 250px !important;
        margin-bottom: 250px !important
    }

    .mg-md-x-250 {
        margin-left: 250px;
        margin-right: 250px
    }

    .mg-md-x-250-f {
        margin-left: 250px !important;
        margin-right: 250px !important
    }

    .mg-md-t-250 {
        margin-top: 250px
    }

    .mg-md-r-250 {
        margin-right: 250px
    }

    .mg-md-b-250 {
        margin-bottom: 250px
    }

    .mg-md-l-250 {
        margin-left: 250px
    }

    .mg-md-t-250-f {
        margin-top: 250px !important
    }

    .mg-md-r-250-f {
        margin-right: 250px !important
    }

    .mg-md-b-250-f {
        margin-bottom: 250px !important
    }

    .mg-md-l-250-f {
        margin-left: 250px !important
    }

    .mg-md-300 {
        margin: 300px
    }

    .mg-md-300-f {
        margin: 300px !important
    }

    .mg-md-y-300 {
        margin-top: 300px;
        margin-bottom: 300px
    }

    .mg-md-y-300-f {
        margin-top: 300px !important;
        margin-bottom: 300px !important
    }

    .mg-md-x-300 {
        margin-left: 300px;
        margin-right: 300px
    }

    .mg-md-x-300-f {
        margin-left: 300px !important;
        margin-right: 300px !important
    }

    .mg-md-t-300 {
        margin-top: 300px
    }

    .mg-md-r-300 {
        margin-right: 300px
    }

    .mg-md-b-300 {
        margin-bottom: 300px
    }

    .mg-md-l-300 {
        margin-left: 300px
    }

    .mg-md-t-300-f {
        margin-top: 300px !important
    }

    .mg-md-r-300-f {
        margin-right: 300px !important
    }

    .mg-md-b-300-f {
        margin-bottom: 300px !important
    }

    .mg-md-l-300-f {
        margin-left: 300px !important
    }

    .mg-md-350 {
        margin: 350px
    }

    .mg-md-350-f {
        margin: 350px !important
    }

    .mg-md-y-350 {
        margin-top: 350px;
        margin-bottom: 350px
    }

    .mg-md-y-350-f {
        margin-top: 350px !important;
        margin-bottom: 350px !important
    }

    .mg-md-x-350 {
        margin-left: 350px;
        margin-right: 350px
    }

    .mg-md-x-350-f {
        margin-left: 350px !important;
        margin-right: 350px !important
    }

    .mg-md-t-350 {
        margin-top: 350px
    }

    .mg-md-r-350 {
        margin-right: 350px
    }

    .mg-md-b-350 {
        margin-bottom: 350px
    }

    .mg-md-l-350 {
        margin-left: 350px
    }

    .mg-md-t-350-f {
        margin-top: 350px !important
    }

    .mg-md-r-350-f {
        margin-right: 350px !important
    }

    .mg-md-b-350-f {
        margin-bottom: 350px !important
    }

    .mg-md-l-350-f {
        margin-left: 350px !important
    }

    .mg-md-400 {
        margin: 400px
    }

    .mg-md-400-f {
        margin: 400px !important
    }

    .mg-md-y-400 {
        margin-top: 400px;
        margin-bottom: 400px
    }

    .mg-md-y-400-f {
        margin-top: 400px !important;
        margin-bottom: 400px !important
    }

    .mg-md-x-400 {
        margin-left: 400px;
        margin-right: 400px
    }

    .mg-md-x-400-f {
        margin-left: 400px !important;
        margin-right: 400px !important
    }

    .mg-md-t-400 {
        margin-top: 400px
    }

    .mg-md-r-400 {
        margin-right: 400px
    }

    .mg-md-b-400 {
        margin-bottom: 400px
    }

    .mg-md-l-400 {
        margin-left: 400px
    }

    .mg-md-t-400-f {
        margin-top: 400px !important
    }

    .mg-md-r-400-f {
        margin-right: 400px !important
    }

    .mg-md-b-400-f {
        margin-bottom: 400px !important
    }

    .mg-md-l-400-f {
        margin-left: 400px !important
    }

    .mg-md-450 {
        margin: 450px
    }

    .mg-md-450-f {
        margin: 450px !important
    }

    .mg-md-y-450 {
        margin-top: 450px;
        margin-bottom: 450px
    }

    .mg-md-y-450-f {
        margin-top: 450px !important;
        margin-bottom: 450px !important
    }

    .mg-md-x-450 {
        margin-left: 450px;
        margin-right: 450px
    }

    .mg-md-x-450-f {
        margin-left: 450px !important;
        margin-right: 450px !important
    }

    .mg-md-t-450 {
        margin-top: 450px
    }

    .mg-md-r-450 {
        margin-right: 450px
    }

    .mg-md-b-450 {
        margin-bottom: 450px
    }

    .mg-md-l-450 {
        margin-left: 450px
    }

    .mg-md-t-450-f {
        margin-top: 450px !important
    }

    .mg-md-r-450-f {
        margin-right: 450px !important
    }

    .mg-md-b-450-f {
        margin-bottom: 450px !important
    }

    .mg-md-l-450-f {
        margin-left: 450px !important
    }

    .mg-md-500 {
        margin: 500px
    }

    .mg-md-500-f {
        margin: 500px !important
    }

    .mg-md-y-500 {
        margin-top: 500px;
        margin-bottom: 500px
    }

    .mg-md-y-500-f {
        margin-top: 500px !important;
        margin-bottom: 500px !important
    }

    .mg-md-x-500 {
        margin-left: 500px;
        margin-right: 500px
    }

    .mg-md-x-500-f {
        margin-left: 500px !important;
        margin-right: 500px !important
    }

    .mg-md-t-500 {
        margin-top: 500px
    }

    .mg-md-r-500 {
        margin-right: 500px
    }

    .mg-md-b-500 {
        margin-bottom: 500px
    }

    .mg-md-l-500 {
        margin-left: 500px
    }

    .mg-md-t-500-f {
        margin-top: 500px !important
    }

    .mg-md-r-500-f {
        margin-right: 500px !important
    }

    .mg-md-b-500-f {
        margin-bottom: 500px !important
    }

    .mg-md-l-500-f {
        margin-left: 500px !important
    }

    .mg-md-550 {
        margin: 550px
    }

    .mg-md-550-f {
        margin: 550px !important
    }

    .mg-md-y-550 {
        margin-top: 550px;
        margin-bottom: 550px
    }

    .mg-md-y-550-f {
        margin-top: 550px !important;
        margin-bottom: 550px !important
    }

    .mg-md-x-550 {
        margin-left: 550px;
        margin-right: 550px
    }

    .mg-md-x-550-f {
        margin-left: 550px !important;
        margin-right: 550px !important
    }

    .mg-md-t-550 {
        margin-top: 550px
    }

    .mg-md-r-550 {
        margin-right: 550px
    }

    .mg-md-b-550 {
        margin-bottom: 550px
    }

    .mg-md-l-550 {
        margin-left: 550px
    }

    .mg-md-t-550-f {
        margin-top: 550px !important
    }

    .mg-md-r-550-f {
        margin-right: 550px !important
    }

    .mg-md-b-550-f {
        margin-bottom: 550px !important
    }

    .mg-md-l-550-f {
        margin-left: 550px !important
    }

    .mg-md-600 {
        margin: 600px
    }

    .mg-md-600-f {
        margin: 600px !important
    }

    .mg-md-y-600 {
        margin-top: 600px;
        margin-bottom: 600px
    }

    .mg-md-y-600-f {
        margin-top: 600px !important;
        margin-bottom: 600px !important
    }

    .mg-md-x-600 {
        margin-left: 600px;
        margin-right: 600px
    }

    .mg-md-x-600-f {
        margin-left: 600px !important;
        margin-right: 600px !important
    }

    .mg-md-t-600 {
        margin-top: 600px
    }

    .mg-md-r-600 {
        margin-right: 600px
    }

    .mg-md-b-600 {
        margin-bottom: 600px
    }

    .mg-md-l-600 {
        margin-left: 600px
    }

    .mg-md-t-600-f {
        margin-top: 600px !important
    }

    .mg-md-r-600-f {
        margin-right: 600px !important
    }

    .mg-md-b-600-f {
        margin-bottom: 600px !important
    }

    .mg-md-l-600-f {
        margin-left: 600px !important
    }

    .mg-md-650 {
        margin: 650px
    }

    .mg-md-650-f {
        margin: 650px !important
    }

    .mg-md-y-650 {
        margin-top: 650px;
        margin-bottom: 650px
    }

    .mg-md-y-650-f {
        margin-top: 650px !important;
        margin-bottom: 650px !important
    }

    .mg-md-x-650 {
        margin-left: 650px;
        margin-right: 650px
    }

    .mg-md-x-650-f {
        margin-left: 650px !important;
        margin-right: 650px !important
    }

    .mg-md-t-650 {
        margin-top: 650px
    }

    .mg-md-r-650 {
        margin-right: 650px
    }

    .mg-md-b-650 {
        margin-bottom: 650px
    }

    .mg-md-l-650 {
        margin-left: 650px
    }

    .mg-md-t-650-f {
        margin-top: 650px !important
    }

    .mg-md-r-650-f {
        margin-right: 650px !important
    }

    .mg-md-b-650-f {
        margin-bottom: 650px !important
    }

    .mg-md-l-650-f {
        margin-left: 650px !important
    }

    .mg-md-t-auto {
        margin-top: auto
    }

    .mg-md-r-auto {
        margin-right: auto
    }

    .mg-md-b-auto {
        margin-bottom: auto
    }

    .mg-md-l-auto {
        margin-left: auto
    }

    .mg-md-auto {
        margin: auto
    }

    .mg-md-x-auto {
        margin-left: auto;
        margin-right: auto
    }
}

@media (min-width: 992px) {
    .mg-lg-0 {
        margin: 0px
    }

    .mg-lg-0-f {
        margin: 0px !important
    }

    .mg-lg-y-0 {
        margin-top: 0px;
        margin-bottom: 0px
    }

    .mg-lg-y-0-f {
        margin-top: 0px !important;
        margin-bottom: 0px !important
    }

    .mg-lg-x-0 {
        margin-left: 0px;
        margin-right: 0px
    }

    .mg-lg-x-0-f {
        margin-left: 0px !important;
        margin-right: 0px !important
    }

    .mg-lg-t-0 {
        margin-top: 0px
    }

    .mg-lg-r-0 {
        margin-right: 0px
    }

    .mg-lg-b-0 {
        margin-bottom: 0px
    }

    .mg-lg-l-0 {
        margin-left: 0px
    }

    .mg-lg-t-0-f {
        margin-top: 0px !important
    }

    .mg-lg-r-0-f {
        margin-right: 0px !important
    }

    .mg-lg-b-0-f {
        margin-bottom: 0px !important
    }

    .mg-lg-l-0-f {
        margin-left: 0px !important
    }

    .mg-lg-1 {
        margin: 1px
    }

    .mg-lg-1-f {
        margin: 1px !important
    }

    .mg-lg-y-1 {
        margin-top: 1px;
        margin-bottom: 1px
    }

    .mg-lg-y-1-f {
        margin-top: 1px !important;
        margin-bottom: 1px !important
    }

    .mg-lg-x-1 {
        margin-left: 1px;
        margin-right: 1px
    }

    .mg-lg-x-1-f {
        margin-left: 1px !important;
        margin-right: 1px !important
    }

    .mg-lg-t-1 {
        margin-top: 1px
    }

    .mg-lg-r-1 {
        margin-right: 1px
    }

    .mg-lg-b-1 {
        margin-bottom: 1px
    }

    .mg-lg-l-1 {
        margin-left: 1px
    }

    .mg-lg-t-1-f {
        margin-top: 1px !important
    }

    .mg-lg-r-1-f {
        margin-right: 1px !important
    }

    .mg-lg-b-1-f {
        margin-bottom: 1px !important
    }

    .mg-lg-l-1-f {
        margin-left: 1px !important
    }

    .mg-lg-2 {
        margin: 2px
    }

    .mg-lg-2-f {
        margin: 2px !important
    }

    .mg-lg-y-2 {
        margin-top: 2px;
        margin-bottom: 2px
    }

    .mg-lg-y-2-f {
        margin-top: 2px !important;
        margin-bottom: 2px !important
    }

    .mg-lg-x-2 {
        margin-left: 2px;
        margin-right: 2px
    }

    .mg-lg-x-2-f {
        margin-left: 2px !important;
        margin-right: 2px !important
    }

    .mg-lg-t-2 {
        margin-top: 2px
    }

    .mg-lg-r-2 {
        margin-right: 2px
    }

    .mg-lg-b-2 {
        margin-bottom: 2px
    }

    .mg-lg-l-2 {
        margin-left: 2px
    }

    .mg-lg-t-2-f {
        margin-top: 2px !important
    }

    .mg-lg-r-2-f {
        margin-right: 2px !important
    }

    .mg-lg-b-2-f {
        margin-bottom: 2px !important
    }

    .mg-lg-l-2-f {
        margin-left: 2px !important
    }

    .mg-lg-3 {
        margin: 3px
    }

    .mg-lg-3-f {
        margin: 3px !important
    }

    .mg-lg-y-3 {
        margin-top: 3px;
        margin-bottom: 3px
    }

    .mg-lg-y-3-f {
        margin-top: 3px !important;
        margin-bottom: 3px !important
    }

    .mg-lg-x-3 {
        margin-left: 3px;
        margin-right: 3px
    }

    .mg-lg-x-3-f {
        margin-left: 3px !important;
        margin-right: 3px !important
    }

    .mg-lg-t-3 {
        margin-top: 3px
    }

    .mg-lg-r-3 {
        margin-right: 3px
    }

    .mg-lg-b-3 {
        margin-bottom: 3px
    }

    .mg-lg-l-3 {
        margin-left: 3px
    }

    .mg-lg-t-3-f {
        margin-top: 3px !important
    }

    .mg-lg-r-3-f {
        margin-right: 3px !important
    }

    .mg-lg-b-3-f {
        margin-bottom: 3px !important
    }

    .mg-lg-l-3-f {
        margin-left: 3px !important
    }

    .mg-lg-4 {
        margin: 4px
    }

    .mg-lg-4-f {
        margin: 4px !important
    }

    .mg-lg-y-4 {
        margin-top: 4px;
        margin-bottom: 4px
    }

    .mg-lg-y-4-f {
        margin-top: 4px !important;
        margin-bottom: 4px !important
    }

    .mg-lg-x-4 {
        margin-left: 4px;
        margin-right: 4px
    }

    .mg-lg-x-4-f {
        margin-left: 4px !important;
        margin-right: 4px !important
    }

    .mg-lg-t-4 {
        margin-top: 4px
    }

    .mg-lg-r-4 {
        margin-right: 4px
    }

    .mg-lg-b-4 {
        margin-bottom: 4px
    }

    .mg-lg-l-4 {
        margin-left: 4px
    }

    .mg-lg-t-4-f {
        margin-top: 4px !important
    }

    .mg-lg-r-4-f {
        margin-right: 4px !important
    }

    .mg-lg-b-4-f {
        margin-bottom: 4px !important
    }

    .mg-lg-l-4-f {
        margin-left: 4px !important
    }

    .mg-lg-5 {
        margin: 5px
    }

    .mg-lg-5-f {
        margin: 5px !important
    }

    .mg-lg-y-5 {
        margin-top: 5px;
        margin-bottom: 5px
    }

    .mg-lg-y-5-f {
        margin-top: 5px !important;
        margin-bottom: 5px !important
    }

    .mg-lg-x-5 {
        margin-left: 5px;
        margin-right: 5px
    }

    .mg-lg-x-5-f {
        margin-left: 5px !important;
        margin-right: 5px !important
    }

    .mg-lg-t-5 {
        margin-top: 5px
    }

    .mg-lg-r-5 {
        margin-right: 5px
    }

    .mg-lg-b-5 {
        margin-bottom: 5px
    }

    .mg-lg-l-5 {
        margin-left: 5px
    }

    .mg-lg-t-5-f {
        margin-top: 5px !important
    }

    .mg-lg-r-5-f {
        margin-right: 5px !important
    }

    .mg-lg-b-5-f {
        margin-bottom: 5px !important
    }

    .mg-lg-l-5-f {
        margin-left: 5px !important
    }

    .mg-lg-6 {
        margin: 6px
    }

    .mg-lg-6-f {
        margin: 6px !important
    }

    .mg-lg-y-6 {
        margin-top: 6px;
        margin-bottom: 6px
    }

    .mg-lg-y-6-f {
        margin-top: 6px !important;
        margin-bottom: 6px !important
    }

    .mg-lg-x-6 {
        margin-left: 6px;
        margin-right: 6px
    }

    .mg-lg-x-6-f {
        margin-left: 6px !important;
        margin-right: 6px !important
    }

    .mg-lg-t-6 {
        margin-top: 6px
    }

    .mg-lg-r-6 {
        margin-right: 6px
    }

    .mg-lg-b-6 {
        margin-bottom: 6px
    }

    .mg-lg-l-6 {
        margin-left: 6px
    }

    .mg-lg-t-6-f {
        margin-top: 6px !important
    }

    .mg-lg-r-6-f {
        margin-right: 6px !important
    }

    .mg-lg-b-6-f {
        margin-bottom: 6px !important
    }

    .mg-lg-l-6-f {
        margin-left: 6px !important
    }

    .mg-lg-7 {
        margin: 7px
    }

    .mg-lg-7-f {
        margin: 7px !important
    }

    .mg-lg-y-7 {
        margin-top: 7px;
        margin-bottom: 7px
    }

    .mg-lg-y-7-f {
        margin-top: 7px !important;
        margin-bottom: 7px !important
    }

    .mg-lg-x-7 {
        margin-left: 7px;
        margin-right: 7px
    }

    .mg-lg-x-7-f {
        margin-left: 7px !important;
        margin-right: 7px !important
    }

    .mg-lg-t-7 {
        margin-top: 7px
    }

    .mg-lg-r-7 {
        margin-right: 7px
    }

    .mg-lg-b-7 {
        margin-bottom: 7px
    }

    .mg-lg-l-7 {
        margin-left: 7px
    }

    .mg-lg-t-7-f {
        margin-top: 7px !important
    }

    .mg-lg-r-7-f {
        margin-right: 7px !important
    }

    .mg-lg-b-7-f {
        margin-bottom: 7px !important
    }

    .mg-lg-l-7-f {
        margin-left: 7px !important
    }

    .mg-lg-8 {
        margin: 8px
    }

    .mg-lg-8-f {
        margin: 8px !important
    }

    .mg-lg-y-8 {
        margin-top: 8px;
        margin-bottom: 8px
    }

    .mg-lg-y-8-f {
        margin-top: 8px !important;
        margin-bottom: 8px !important
    }

    .mg-lg-x-8 {
        margin-left: 8px;
        margin-right: 8px
    }

    .mg-lg-x-8-f {
        margin-left: 8px !important;
        margin-right: 8px !important
    }

    .mg-lg-t-8 {
        margin-top: 8px
    }

    .mg-lg-r-8 {
        margin-right: 8px
    }

    .mg-lg-b-8 {
        margin-bottom: 8px
    }

    .mg-lg-l-8 {
        margin-left: 8px
    }

    .mg-lg-t-8-f {
        margin-top: 8px !important
    }

    .mg-lg-r-8-f {
        margin-right: 8px !important
    }

    .mg-lg-b-8-f {
        margin-bottom: 8px !important
    }

    .mg-lg-l-8-f {
        margin-left: 8px !important
    }

    .mg-lg-9 {
        margin: 9px
    }

    .mg-lg-9-f {
        margin: 9px !important
    }

    .mg-lg-y-9 {
        margin-top: 9px;
        margin-bottom: 9px
    }

    .mg-lg-y-9-f {
        margin-top: 9px !important;
        margin-bottom: 9px !important
    }

    .mg-lg-x-9 {
        margin-left: 9px;
        margin-right: 9px
    }

    .mg-lg-x-9-f {
        margin-left: 9px !important;
        margin-right: 9px !important
    }

    .mg-lg-t-9 {
        margin-top: 9px
    }

    .mg-lg-r-9 {
        margin-right: 9px
    }

    .mg-lg-b-9 {
        margin-bottom: 9px
    }

    .mg-lg-l-9 {
        margin-left: 9px
    }

    .mg-lg-t-9-f {
        margin-top: 9px !important
    }

    .mg-lg-r-9-f {
        margin-right: 9px !important
    }

    .mg-lg-b-9-f {
        margin-bottom: 9px !important
    }

    .mg-lg-l-9-f {
        margin-left: 9px !important
    }

    .mg-lg-10 {
        margin: 10px
    }

    .mg-lg-10-f {
        margin: 10px !important
    }

    .mg-lg-y-10 {
        margin-top: 10px;
        margin-bottom: 10px
    }

    .mg-lg-y-10-f {
        margin-top: 10px !important;
        margin-bottom: 10px !important
    }

    .mg-lg-x-10 {
        margin-left: 10px;
        margin-right: 10px
    }

    .mg-lg-x-10-f {
        margin-left: 10px !important;
        margin-right: 10px !important
    }

    .mg-lg-t-10 {
        margin-top: 10px
    }

    .mg-lg-r-10 {
        margin-right: 10px
    }

    .mg-lg-b-10 {
        margin-bottom: 10px
    }

    .mg-lg-l-10 {
        margin-left: 10px
    }

    .mg-lg-t-10-f {
        margin-top: 10px !important
    }

    .mg-lg-r-10-f {
        margin-right: 10px !important
    }

    .mg-lg-b-10-f {
        margin-bottom: 10px !important
    }

    .mg-lg-l-10-f {
        margin-left: 10px !important
    }

    .mg-lg-15 {
        margin: 15px
    }

    .mg-lg-15-f {
        margin: 15px !important
    }

    .mg-lg-y-15 {
        margin-top: 15px;
        margin-bottom: 15px
    }

    .mg-lg-y-15-f {
        margin-top: 15px !important;
        margin-bottom: 15px !important
    }

    .mg-lg-x-15 {
        margin-left: 15px;
        margin-right: 15px
    }

    .mg-lg-x-15-f {
        margin-left: 15px !important;
        margin-right: 15px !important
    }

    .mg-lg-t-15 {
        margin-top: 15px
    }

    .mg-lg-r-15 {
        margin-right: 15px
    }

    .mg-lg-b-15 {
        margin-bottom: 15px
    }

    .mg-lg-l-15 {
        margin-left: 15px
    }

    .mg-lg-t-15-f {
        margin-top: 15px !important
    }

    .mg-lg-r-15-f {
        margin-right: 15px !important
    }

    .mg-lg-b-15-f {
        margin-bottom: 15px !important
    }

    .mg-lg-l-15-f {
        margin-left: 15px !important
    }

    .mg-lg-20 {
        margin: 20px
    }

    .mg-lg-20-f {
        margin: 20px !important
    }

    .mg-lg-y-20 {
        margin-top: 20px;
        margin-bottom: 20px
    }

    .mg-lg-y-20-f {
        margin-top: 20px !important;
        margin-bottom: 20px !important
    }

    .mg-lg-x-20 {
        margin-left: 20px;
        margin-right: 20px
    }

    .mg-lg-x-20-f {
        margin-left: 20px !important;
        margin-right: 20px !important
    }

    .mg-lg-t-20 {
        margin-top: 20px
    }

    .mg-lg-r-20 {
        margin-right: 20px
    }

    .mg-lg-b-20 {
        margin-bottom: 20px
    }

    .mg-lg-l-20 {
        margin-left: 20px
    }

    .mg-lg-t-20-f {
        margin-top: 20px !important
    }

    .mg-lg-r-20-f {
        margin-right: 20px !important
    }

    .mg-lg-b-20-f {
        margin-bottom: 20px !important
    }

    .mg-lg-l-20-f {
        margin-left: 20px !important
    }

    .mg-lg-25 {
        margin: 25px
    }

    .mg-lg-25-f {
        margin: 25px !important
    }

    .mg-lg-y-25 {
        margin-top: 25px;
        margin-bottom: 25px
    }

    .mg-lg-y-25-f {
        margin-top: 25px !important;
        margin-bottom: 25px !important
    }

    .mg-lg-x-25 {
        margin-left: 25px;
        margin-right: 25px
    }

    .mg-lg-x-25-f {
        margin-left: 25px !important;
        margin-right: 25px !important
    }

    .mg-lg-t-25 {
        margin-top: 25px
    }

    .mg-lg-r-25 {
        margin-right: 25px
    }

    .mg-lg-b-25 {
        margin-bottom: 25px
    }

    .mg-lg-l-25 {
        margin-left: 25px
    }

    .mg-lg-t-25-f {
        margin-top: 25px !important
    }

    .mg-lg-r-25-f {
        margin-right: 25px !important
    }

    .mg-lg-b-25-f {
        margin-bottom: 25px !important
    }

    .mg-lg-l-25-f {
        margin-left: 25px !important
    }

    .mg-lg-30 {
        margin: 30px
    }

    .mg-lg-30-f {
        margin: 30px !important
    }

    .mg-lg-y-30 {
        margin-top: 30px;
        margin-bottom: 30px
    }

    .mg-lg-y-30-f {
        margin-top: 30px !important;
        margin-bottom: 30px !important
    }

    .mg-lg-x-30 {
        margin-left: 30px;
        margin-right: 30px
    }

    .mg-lg-x-30-f {
        margin-left: 30px !important;
        margin-right: 30px !important
    }

    .mg-lg-t-30 {
        margin-top: 30px
    }

    .mg-lg-r-30 {
        margin-right: 30px
    }

    .mg-lg-b-30 {
        margin-bottom: 30px
    }

    .mg-lg-l-30 {
        margin-left: 30px
    }

    .mg-lg-t-30-f {
        margin-top: 30px !important
    }

    .mg-lg-r-30-f {
        margin-right: 30px !important
    }

    .mg-lg-b-30-f {
        margin-bottom: 30px !important
    }

    .mg-lg-l-30-f {
        margin-left: 30px !important
    }

    .mg-lg-35 {
        margin: 35px
    }

    .mg-lg-35-f {
        margin: 35px !important
    }

    .mg-lg-y-35 {
        margin-top: 35px;
        margin-bottom: 35px
    }

    .mg-lg-y-35-f {
        margin-top: 35px !important;
        margin-bottom: 35px !important
    }

    .mg-lg-x-35 {
        margin-left: 35px;
        margin-right: 35px
    }

    .mg-lg-x-35-f {
        margin-left: 35px !important;
        margin-right: 35px !important
    }

    .mg-lg-t-35 {
        margin-top: 35px
    }

    .mg-lg-r-35 {
        margin-right: 35px
    }

    .mg-lg-b-35 {
        margin-bottom: 35px
    }

    .mg-lg-l-35 {
        margin-left: 35px
    }

    .mg-lg-t-35-f {
        margin-top: 35px !important
    }

    .mg-lg-r-35-f {
        margin-right: 35px !important
    }

    .mg-lg-b-35-f {
        margin-bottom: 35px !important
    }

    .mg-lg-l-35-f {
        margin-left: 35px !important
    }

    .mg-lg-40 {
        margin: 40px
    }

    .mg-lg-40-f {
        margin: 40px !important
    }

    .mg-lg-y-40 {
        margin-top: 40px;
        margin-bottom: 40px
    }

    .mg-lg-y-40-f {
        margin-top: 40px !important;
        margin-bottom: 40px !important
    }

    .mg-lg-x-40 {
        margin-left: 40px;
        margin-right: 40px
    }

    .mg-lg-x-40-f {
        margin-left: 40px !important;
        margin-right: 40px !important
    }

    .mg-lg-t-40 {
        margin-top: 40px
    }

    .mg-lg-r-40 {
        margin-right: 40px
    }

    .mg-lg-b-40 {
        margin-bottom: 40px
    }

    .mg-lg-l-40 {
        margin-left: 40px
    }

    .mg-lg-t-40-f {
        margin-top: 40px !important
    }

    .mg-lg-r-40-f {
        margin-right: 40px !important
    }

    .mg-lg-b-40-f {
        margin-bottom: 40px !important
    }

    .mg-lg-l-40-f {
        margin-left: 40px !important
    }

    .mg-lg-45 {
        margin: 45px
    }

    .mg-lg-45-f {
        margin: 45px !important
    }

    .mg-lg-y-45 {
        margin-top: 45px;
        margin-bottom: 45px
    }

    .mg-lg-y-45-f {
        margin-top: 45px !important;
        margin-bottom: 45px !important
    }

    .mg-lg-x-45 {
        margin-left: 45px;
        margin-right: 45px
    }

    .mg-lg-x-45-f {
        margin-left: 45px !important;
        margin-right: 45px !important
    }

    .mg-lg-t-45 {
        margin-top: 45px
    }

    .mg-lg-r-45 {
        margin-right: 45px
    }

    .mg-lg-b-45 {
        margin-bottom: 45px
    }

    .mg-lg-l-45 {
        margin-left: 45px
    }

    .mg-lg-t-45-f {
        margin-top: 45px !important
    }

    .mg-lg-r-45-f {
        margin-right: 45px !important
    }

    .mg-lg-b-45-f {
        margin-bottom: 45px !important
    }

    .mg-lg-l-45-f {
        margin-left: 45px !important
    }

    .mg-lg-50 {
        margin: 50px
    }

    .mg-lg-50-f {
        margin: 50px !important
    }

    .mg-lg-y-50 {
        margin-top: 50px;
        margin-bottom: 50px
    }

    .mg-lg-y-50-f {
        margin-top: 50px !important;
        margin-bottom: 50px !important
    }

    .mg-lg-x-50 {
        margin-left: 50px;
        margin-right: 50px
    }

    .mg-lg-x-50-f {
        margin-left: 50px !important;
        margin-right: 50px !important
    }

    .mg-lg-t-50 {
        margin-top: 50px
    }

    .mg-lg-r-50 {
        margin-right: 50px
    }

    .mg-lg-b-50 {
        margin-bottom: 50px
    }

    .mg-lg-l-50 {
        margin-left: 50px
    }

    .mg-lg-t-50-f {
        margin-top: 50px !important
    }

    .mg-lg-r-50-f {
        margin-right: 50px !important
    }

    .mg-lg-b-50-f {
        margin-bottom: 50px !important
    }

    .mg-lg-l-50-f {
        margin-left: 50px !important
    }

    .mg-lg-55 {
        margin: 55px
    }

    .mg-lg-55-f {
        margin: 55px !important
    }

    .mg-lg-y-55 {
        margin-top: 55px;
        margin-bottom: 55px
    }

    .mg-lg-y-55-f {
        margin-top: 55px !important;
        margin-bottom: 55px !important
    }

    .mg-lg-x-55 {
        margin-left: 55px;
        margin-right: 55px
    }

    .mg-lg-x-55-f {
        margin-left: 55px !important;
        margin-right: 55px !important
    }

    .mg-lg-t-55 {
        margin-top: 55px
    }

    .mg-lg-r-55 {
        margin-right: 55px
    }

    .mg-lg-b-55 {
        margin-bottom: 55px
    }

    .mg-lg-l-55 {
        margin-left: 55px
    }

    .mg-lg-t-55-f {
        margin-top: 55px !important
    }

    .mg-lg-r-55-f {
        margin-right: 55px !important
    }

    .mg-lg-b-55-f {
        margin-bottom: 55px !important
    }

    .mg-lg-l-55-f {
        margin-left: 55px !important
    }

    .mg-lg-60 {
        margin: 60px
    }

    .mg-lg-60-f {
        margin: 60px !important
    }

    .mg-lg-y-60 {
        margin-top: 60px;
        margin-bottom: 60px
    }

    .mg-lg-y-60-f {
        margin-top: 60px !important;
        margin-bottom: 60px !important
    }

    .mg-lg-x-60 {
        margin-left: 60px;
        margin-right: 60px
    }

    .mg-lg-x-60-f {
        margin-left: 60px !important;
        margin-right: 60px !important
    }

    .mg-lg-t-60 {
        margin-top: 60px
    }

    .mg-lg-r-60 {
        margin-right: 60px
    }

    .mg-lg-b-60 {
        margin-bottom: 60px
    }

    .mg-lg-l-60 {
        margin-left: 60px
    }

    .mg-lg-t-60-f {
        margin-top: 60px !important
    }

    .mg-lg-r-60-f {
        margin-right: 60px !important
    }

    .mg-lg-b-60-f {
        margin-bottom: 60px !important
    }

    .mg-lg-l-60-f {
        margin-left: 60px !important
    }

    .mg-lg-65 {
        margin: 65px
    }

    .mg-lg-65-f {
        margin: 65px !important
    }

    .mg-lg-y-65 {
        margin-top: 65px;
        margin-bottom: 65px
    }

    .mg-lg-y-65-f {
        margin-top: 65px !important;
        margin-bottom: 65px !important
    }

    .mg-lg-x-65 {
        margin-left: 65px;
        margin-right: 65px
    }

    .mg-lg-x-65-f {
        margin-left: 65px !important;
        margin-right: 65px !important
    }

    .mg-lg-t-65 {
        margin-top: 65px
    }

    .mg-lg-r-65 {
        margin-right: 65px
    }

    .mg-lg-b-65 {
        margin-bottom: 65px
    }

    .mg-lg-l-65 {
        margin-left: 65px
    }

    .mg-lg-t-65-f {
        margin-top: 65px !important
    }

    .mg-lg-r-65-f {
        margin-right: 65px !important
    }

    .mg-lg-b-65-f {
        margin-bottom: 65px !important
    }

    .mg-lg-l-65-f {
        margin-left: 65px !important
    }

    .mg-lg-70 {
        margin: 70px
    }

    .mg-lg-70-f {
        margin: 70px !important
    }

    .mg-lg-y-70 {
        margin-top: 70px;
        margin-bottom: 70px
    }

    .mg-lg-y-70-f {
        margin-top: 70px !important;
        margin-bottom: 70px !important
    }

    .mg-lg-x-70 {
        margin-left: 70px;
        margin-right: 70px
    }

    .mg-lg-x-70-f {
        margin-left: 70px !important;
        margin-right: 70px !important
    }

    .mg-lg-t-70 {
        margin-top: 70px
    }

    .mg-lg-r-70 {
        margin-right: 70px
    }

    .mg-lg-b-70 {
        margin-bottom: 70px
    }

    .mg-lg-l-70 {
        margin-left: 70px
    }

    .mg-lg-t-70-f {
        margin-top: 70px !important
    }

    .mg-lg-r-70-f {
        margin-right: 70px !important
    }

    .mg-lg-b-70-f {
        margin-bottom: 70px !important
    }

    .mg-lg-l-70-f {
        margin-left: 70px !important
    }

    .mg-lg-75 {
        margin: 75px
    }

    .mg-lg-75-f {
        margin: 75px !important
    }

    .mg-lg-y-75 {
        margin-top: 75px;
        margin-bottom: 75px
    }

    .mg-lg-y-75-f {
        margin-top: 75px !important;
        margin-bottom: 75px !important
    }

    .mg-lg-x-75 {
        margin-left: 75px;
        margin-right: 75px
    }

    .mg-lg-x-75-f {
        margin-left: 75px !important;
        margin-right: 75px !important
    }

    .mg-lg-t-75 {
        margin-top: 75px
    }

    .mg-lg-r-75 {
        margin-right: 75px
    }

    .mg-lg-b-75 {
        margin-bottom: 75px
    }

    .mg-lg-l-75 {
        margin-left: 75px
    }

    .mg-lg-t-75-f {
        margin-top: 75px !important
    }

    .mg-lg-r-75-f {
        margin-right: 75px !important
    }

    .mg-lg-b-75-f {
        margin-bottom: 75px !important
    }

    .mg-lg-l-75-f {
        margin-left: 75px !important
    }

    .mg-lg-80 {
        margin: 80px
    }

    .mg-lg-80-f {
        margin: 80px !important
    }

    .mg-lg-y-80 {
        margin-top: 80px;
        margin-bottom: 80px
    }

    .mg-lg-y-80-f {
        margin-top: 80px !important;
        margin-bottom: 80px !important
    }

    .mg-lg-x-80 {
        margin-left: 80px;
        margin-right: 80px
    }

    .mg-lg-x-80-f {
        margin-left: 80px !important;
        margin-right: 80px !important
    }

    .mg-lg-t-80 {
        margin-top: 80px
    }

    .mg-lg-r-80 {
        margin-right: 80px
    }

    .mg-lg-b-80 {
        margin-bottom: 80px
    }

    .mg-lg-l-80 {
        margin-left: 80px
    }

    .mg-lg-t-80-f {
        margin-top: 80px !important
    }

    .mg-lg-r-80-f {
        margin-right: 80px !important
    }

    .mg-lg-b-80-f {
        margin-bottom: 80px !important
    }

    .mg-lg-l-80-f {
        margin-left: 80px !important
    }

    .mg-lg-85 {
        margin: 85px
    }

    .mg-lg-85-f {
        margin: 85px !important
    }

    .mg-lg-y-85 {
        margin-top: 85px;
        margin-bottom: 85px
    }

    .mg-lg-y-85-f {
        margin-top: 85px !important;
        margin-bottom: 85px !important
    }

    .mg-lg-x-85 {
        margin-left: 85px;
        margin-right: 85px
    }

    .mg-lg-x-85-f {
        margin-left: 85px !important;
        margin-right: 85px !important
    }

    .mg-lg-t-85 {
        margin-top: 85px
    }

    .mg-lg-r-85 {
        margin-right: 85px
    }

    .mg-lg-b-85 {
        margin-bottom: 85px
    }

    .mg-lg-l-85 {
        margin-left: 85px
    }

    .mg-lg-t-85-f {
        margin-top: 85px !important
    }

    .mg-lg-r-85-f {
        margin-right: 85px !important
    }

    .mg-lg-b-85-f {
        margin-bottom: 85px !important
    }

    .mg-lg-l-85-f {
        margin-left: 85px !important
    }

    .mg-lg-90 {
        margin: 90px
    }

    .mg-lg-90-f {
        margin: 90px !important
    }

    .mg-lg-y-90 {
        margin-top: 90px;
        margin-bottom: 90px
    }

    .mg-lg-y-90-f {
        margin-top: 90px !important;
        margin-bottom: 90px !important
    }

    .mg-lg-x-90 {
        margin-left: 90px;
        margin-right: 90px
    }

    .mg-lg-x-90-f {
        margin-left: 90px !important;
        margin-right: 90px !important
    }

    .mg-lg-t-90 {
        margin-top: 90px
    }

    .mg-lg-r-90 {
        margin-right: 90px
    }

    .mg-lg-b-90 {
        margin-bottom: 90px
    }

    .mg-lg-l-90 {
        margin-left: 90px
    }

    .mg-lg-t-90-f {
        margin-top: 90px !important
    }

    .mg-lg-r-90-f {
        margin-right: 90px !important
    }

    .mg-lg-b-90-f {
        margin-bottom: 90px !important
    }

    .mg-lg-l-90-f {
        margin-left: 90px !important
    }

    .mg-lg-95 {
        margin: 95px
    }

    .mg-lg-95-f {
        margin: 95px !important
    }

    .mg-lg-y-95 {
        margin-top: 95px;
        margin-bottom: 95px
    }

    .mg-lg-y-95-f {
        margin-top: 95px !important;
        margin-bottom: 95px !important
    }

    .mg-lg-x-95 {
        margin-left: 95px;
        margin-right: 95px
    }

    .mg-lg-x-95-f {
        margin-left: 95px !important;
        margin-right: 95px !important
    }

    .mg-lg-t-95 {
        margin-top: 95px
    }

    .mg-lg-r-95 {
        margin-right: 95px
    }

    .mg-lg-b-95 {
        margin-bottom: 95px
    }

    .mg-lg-l-95 {
        margin-left: 95px
    }

    .mg-lg-t-95-f {
        margin-top: 95px !important
    }

    .mg-lg-r-95-f {
        margin-right: 95px !important
    }

    .mg-lg-b-95-f {
        margin-bottom: 95px !important
    }

    .mg-lg-l-95-f {
        margin-left: 95px !important
    }

    .mg-lg-100 {
        margin: 100px
    }

    .mg-lg-100-f {
        margin: 100px !important
    }

    .mg-lg-y-100 {
        margin-top: 100px;
        margin-bottom: 100px
    }

    .mg-lg-y-100-f {
        margin-top: 100px !important;
        margin-bottom: 100px !important
    }

    .mg-lg-x-100 {
        margin-left: 100px;
        margin-right: 100px
    }

    .mg-lg-x-100-f {
        margin-left: 100px !important;
        margin-right: 100px !important
    }

    .mg-lg-t-100 {
        margin-top: 100px
    }

    .mg-lg-r-100 {
        margin-right: 100px
    }

    .mg-lg-b-100 {
        margin-bottom: 100px
    }

    .mg-lg-l-100 {
        margin-left: 100px
    }

    .mg-lg-t-100-f {
        margin-top: 100px !important
    }

    .mg-lg-r-100-f {
        margin-right: 100px !important
    }

    .mg-lg-b-100-f {
        margin-bottom: 100px !important
    }

    .mg-lg-l-100-f {
        margin-left: 100px !important
    }

    .mg-lg-150 {
        margin: 150px
    }

    .mg-lg-150-f {
        margin: 150px !important
    }

    .mg-lg-y-150 {
        margin-top: 150px;
        margin-bottom: 150px
    }

    .mg-lg-y-150-f {
        margin-top: 150px !important;
        margin-bottom: 150px !important
    }

    .mg-lg-x-150 {
        margin-left: 150px;
        margin-right: 150px
    }

    .mg-lg-x-150-f {
        margin-left: 150px !important;
        margin-right: 150px !important
    }

    .mg-lg-t-150 {
        margin-top: 150px
    }

    .mg-lg-r-150 {
        margin-right: 150px
    }

    .mg-lg-b-150 {
        margin-bottom: 150px
    }

    .mg-lg-l-150 {
        margin-left: 150px
    }

    .mg-lg-t-150-f {
        margin-top: 150px !important
    }

    .mg-lg-r-150-f {
        margin-right: 150px !important
    }

    .mg-lg-b-150-f {
        margin-bottom: 150px !important
    }

    .mg-lg-l-150-f {
        margin-left: 150px !important
    }

    .mg-lg-200 {
        margin: 200px
    }

    .mg-lg-200-f {
        margin: 200px !important
    }

    .mg-lg-y-200 {
        margin-top: 200px;
        margin-bottom: 200px
    }

    .mg-lg-y-200-f {
        margin-top: 200px !important;
        margin-bottom: 200px !important
    }

    .mg-lg-x-200 {
        margin-left: 200px;
        margin-right: 200px
    }

    .mg-lg-x-200-f {
        margin-left: 200px !important;
        margin-right: 200px !important
    }

    .mg-lg-t-200 {
        margin-top: 200px
    }

    .mg-lg-r-200 {
        margin-right: 200px
    }

    .mg-lg-b-200 {
        margin-bottom: 200px
    }

    .mg-lg-l-200 {
        margin-left: 200px
    }

    .mg-lg-t-200-f {
        margin-top: 200px !important
    }

    .mg-lg-r-200-f {
        margin-right: 200px !important
    }

    .mg-lg-b-200-f {
        margin-bottom: 200px !important
    }

    .mg-lg-l-200-f {
        margin-left: 200px !important
    }

    .mg-lg-250 {
        margin: 250px
    }

    .mg-lg-250-f {
        margin: 250px !important
    }

    .mg-lg-y-250 {
        margin-top: 250px;
        margin-bottom: 250px
    }

    .mg-lg-y-250-f {
        margin-top: 250px !important;
        margin-bottom: 250px !important
    }

    .mg-lg-x-250 {
        margin-left: 250px;
        margin-right: 250px
    }

    .mg-lg-x-250-f {
        margin-left: 250px !important;
        margin-right: 250px !important
    }

    .mg-lg-t-250 {
        margin-top: 250px
    }

    .mg-lg-r-250 {
        margin-right: 250px
    }

    .mg-lg-b-250 {
        margin-bottom: 250px
    }

    .mg-lg-l-250 {
        margin-left: 250px
    }

    .mg-lg-t-250-f {
        margin-top: 250px !important
    }

    .mg-lg-r-250-f {
        margin-right: 250px !important
    }

    .mg-lg-b-250-f {
        margin-bottom: 250px !important
    }

    .mg-lg-l-250-f {
        margin-left: 250px !important
    }

    .mg-lg-300 {
        margin: 300px
    }

    .mg-lg-300-f {
        margin: 300px !important
    }

    .mg-lg-y-300 {
        margin-top: 300px;
        margin-bottom: 300px
    }

    .mg-lg-y-300-f {
        margin-top: 300px !important;
        margin-bottom: 300px !important
    }

    .mg-lg-x-300 {
        margin-left: 300px;
        margin-right: 300px
    }

    .mg-lg-x-300-f {
        margin-left: 300px !important;
        margin-right: 300px !important
    }

    .mg-lg-t-300 {
        margin-top: 300px
    }

    .mg-lg-r-300 {
        margin-right: 300px
    }

    .mg-lg-b-300 {
        margin-bottom: 300px
    }

    .mg-lg-l-300 {
        margin-left: 300px
    }

    .mg-lg-t-300-f {
        margin-top: 300px !important
    }

    .mg-lg-r-300-f {
        margin-right: 300px !important
    }

    .mg-lg-b-300-f {
        margin-bottom: 300px !important
    }

    .mg-lg-l-300-f {
        margin-left: 300px !important
    }

    .mg-lg-350 {
        margin: 350px
    }

    .mg-lg-350-f {
        margin: 350px !important
    }

    .mg-lg-y-350 {
        margin-top: 350px;
        margin-bottom: 350px
    }

    .mg-lg-y-350-f {
        margin-top: 350px !important;
        margin-bottom: 350px !important
    }

    .mg-lg-x-350 {
        margin-left: 350px;
        margin-right: 350px
    }

    .mg-lg-x-350-f {
        margin-left: 350px !important;
        margin-right: 350px !important
    }

    .mg-lg-t-350 {
        margin-top: 350px
    }

    .mg-lg-r-350 {
        margin-right: 350px
    }

    .mg-lg-b-350 {
        margin-bottom: 350px
    }

    .mg-lg-l-350 {
        margin-left: 350px
    }

    .mg-lg-t-350-f {
        margin-top: 350px !important
    }

    .mg-lg-r-350-f {
        margin-right: 350px !important
    }

    .mg-lg-b-350-f {
        margin-bottom: 350px !important
    }

    .mg-lg-l-350-f {
        margin-left: 350px !important
    }

    .mg-lg-400 {
        margin: 400px
    }

    .mg-lg-400-f {
        margin: 400px !important
    }

    .mg-lg-y-400 {
        margin-top: 400px;
        margin-bottom: 400px
    }

    .mg-lg-y-400-f {
        margin-top: 400px !important;
        margin-bottom: 400px !important
    }

    .mg-lg-x-400 {
        margin-left: 400px;
        margin-right: 400px
    }

    .mg-lg-x-400-f {
        margin-left: 400px !important;
        margin-right: 400px !important
    }

    .mg-lg-t-400 {
        margin-top: 400px
    }

    .mg-lg-r-400 {
        margin-right: 400px
    }

    .mg-lg-b-400 {
        margin-bottom: 400px
    }

    .mg-lg-l-400 {
        margin-left: 400px
    }

    .mg-lg-t-400-f {
        margin-top: 400px !important
    }

    .mg-lg-r-400-f {
        margin-right: 400px !important
    }

    .mg-lg-b-400-f {
        margin-bottom: 400px !important
    }

    .mg-lg-l-400-f {
        margin-left: 400px !important
    }

    .mg-lg-450 {
        margin: 450px
    }

    .mg-lg-450-f {
        margin: 450px !important
    }

    .mg-lg-y-450 {
        margin-top: 450px;
        margin-bottom: 450px
    }

    .mg-lg-y-450-f {
        margin-top: 450px !important;
        margin-bottom: 450px !important
    }

    .mg-lg-x-450 {
        margin-left: 450px;
        margin-right: 450px
    }

    .mg-lg-x-450-f {
        margin-left: 450px !important;
        margin-right: 450px !important
    }

    .mg-lg-t-450 {
        margin-top: 450px
    }

    .mg-lg-r-450 {
        margin-right: 450px
    }

    .mg-lg-b-450 {
        margin-bottom: 450px
    }

    .mg-lg-l-450 {
        margin-left: 450px
    }

    .mg-lg-t-450-f {
        margin-top: 450px !important
    }

    .mg-lg-r-450-f {
        margin-right: 450px !important
    }

    .mg-lg-b-450-f {
        margin-bottom: 450px !important
    }

    .mg-lg-l-450-f {
        margin-left: 450px !important
    }

    .mg-lg-500 {
        margin: 500px
    }

    .mg-lg-500-f {
        margin: 500px !important
    }

    .mg-lg-y-500 {
        margin-top: 500px;
        margin-bottom: 500px
    }

    .mg-lg-y-500-f {
        margin-top: 500px !important;
        margin-bottom: 500px !important
    }

    .mg-lg-x-500 {
        margin-left: 500px;
        margin-right: 500px
    }

    .mg-lg-x-500-f {
        margin-left: 500px !important;
        margin-right: 500px !important
    }

    .mg-lg-t-500 {
        margin-top: 500px
    }

    .mg-lg-r-500 {
        margin-right: 500px
    }

    .mg-lg-b-500 {
        margin-bottom: 500px
    }

    .mg-lg-l-500 {
        margin-left: 500px
    }

    .mg-lg-t-500-f {
        margin-top: 500px !important
    }

    .mg-lg-r-500-f {
        margin-right: 500px !important
    }

    .mg-lg-b-500-f {
        margin-bottom: 500px !important
    }

    .mg-lg-l-500-f {
        margin-left: 500px !important
    }

    .mg-lg-550 {
        margin: 550px
    }

    .mg-lg-550-f {
        margin: 550px !important
    }

    .mg-lg-y-550 {
        margin-top: 550px;
        margin-bottom: 550px
    }

    .mg-lg-y-550-f {
        margin-top: 550px !important;
        margin-bottom: 550px !important
    }

    .mg-lg-x-550 {
        margin-left: 550px;
        margin-right: 550px
    }

    .mg-lg-x-550-f {
        margin-left: 550px !important;
        margin-right: 550px !important
    }

    .mg-lg-t-550 {
        margin-top: 550px
    }

    .mg-lg-r-550 {
        margin-right: 550px
    }

    .mg-lg-b-550 {
        margin-bottom: 550px
    }

    .mg-lg-l-550 {
        margin-left: 550px
    }

    .mg-lg-t-550-f {
        margin-top: 550px !important
    }

    .mg-lg-r-550-f {
        margin-right: 550px !important
    }

    .mg-lg-b-550-f {
        margin-bottom: 550px !important
    }

    .mg-lg-l-550-f {
        margin-left: 550px !important
    }

    .mg-lg-600 {
        margin: 600px
    }

    .mg-lg-600-f {
        margin: 600px !important
    }

    .mg-lg-y-600 {
        margin-top: 600px;
        margin-bottom: 600px
    }

    .mg-lg-y-600-f {
        margin-top: 600px !important;
        margin-bottom: 600px !important
    }

    .mg-lg-x-600 {
        margin-left: 600px;
        margin-right: 600px
    }

    .mg-lg-x-600-f {
        margin-left: 600px !important;
        margin-right: 600px !important
    }

    .mg-lg-t-600 {
        margin-top: 600px
    }

    .mg-lg-r-600 {
        margin-right: 600px
    }

    .mg-lg-b-600 {
        margin-bottom: 600px
    }

    .mg-lg-l-600 {
        margin-left: 600px
    }

    .mg-lg-t-600-f {
        margin-top: 600px !important
    }

    .mg-lg-r-600-f {
        margin-right: 600px !important
    }

    .mg-lg-b-600-f {
        margin-bottom: 600px !important
    }

    .mg-lg-l-600-f {
        margin-left: 600px !important
    }

    .mg-lg-650 {
        margin: 650px
    }

    .mg-lg-650-f {
        margin: 650px !important
    }

    .mg-lg-y-650 {
        margin-top: 650px;
        margin-bottom: 650px
    }

    .mg-lg-y-650-f {
        margin-top: 650px !important;
        margin-bottom: 650px !important
    }

    .mg-lg-x-650 {
        margin-left: 650px;
        margin-right: 650px
    }

    .mg-lg-x-650-f {
        margin-left: 650px !important;
        margin-right: 650px !important
    }

    .mg-lg-t-650 {
        margin-top: 650px
    }

    .mg-lg-r-650 {
        margin-right: 650px
    }

    .mg-lg-b-650 {
        margin-bottom: 650px
    }

    .mg-lg-l-650 {
        margin-left: 650px
    }

    .mg-lg-t-650-f {
        margin-top: 650px !important
    }

    .mg-lg-r-650-f {
        margin-right: 650px !important
    }

    .mg-lg-b-650-f {
        margin-bottom: 650px !important
    }

    .mg-lg-l-650-f {
        margin-left: 650px !important
    }

    .mg-lg-t-auto {
        margin-top: auto
    }

    .mg-lg-r-auto {
        margin-right: auto
    }

    .mg-lg-b-auto {
        margin-bottom: auto
    }

    .mg-lg-l-auto {
        margin-left: auto
    }

    .mg-lg-auto {
        margin: auto
    }

    .mg-lg-x-auto {
        margin-left: auto;
        margin-right: auto
    }

    .mg-lg-l-240 {
        margin-left: 240px
    }
}

@media (min-width: 1200px) {
    .mg-xl-0 {
        margin: 0px
    }

    .mg-xl-0-f {
        margin: 0px !important
    }

    .mg-xl-y-0 {
        margin-top: 0px;
        margin-bottom: 0px
    }

    .mg-xl-y-0-f {
        margin-top: 0px !important;
        margin-bottom: 0px !important
    }

    .mg-xl-x-0 {
        margin-left: 0px;
        margin-right: 0px
    }

    .mg-xl-x-0-f {
        margin-left: 0px !important;
        margin-right: 0px !important
    }

    .mg-xl-t-0 {
        margin-top: 0px
    }

    .mg-xl-r-0 {
        margin-right: 0px
    }

    .mg-xl-b-0 {
        margin-bottom: 0px
    }

    .mg-xl-l-0 {
        margin-left: 0px
    }

    .mg-xl-t-0-f {
        margin-top: 0px !important
    }

    .mg-xl-r-0-f {
        margin-right: 0px !important
    }

    .mg-xl-b-0-f {
        margin-bottom: 0px !important
    }

    .mg-xl-l-0-f {
        margin-left: 0px !important
    }

    .mg-xl-1 {
        margin: 1px
    }

    .mg-xl-1-f {
        margin: 1px !important
    }

    .mg-xl-y-1 {
        margin-top: 1px;
        margin-bottom: 1px
    }

    .mg-xl-y-1-f {
        margin-top: 1px !important;
        margin-bottom: 1px !important
    }

    .mg-xl-x-1 {
        margin-left: 1px;
        margin-right: 1px
    }

    .mg-xl-x-1-f {
        margin-left: 1px !important;
        margin-right: 1px !important
    }

    .mg-xl-t-1 {
        margin-top: 1px
    }

    .mg-xl-r-1 {
        margin-right: 1px
    }

    .mg-xl-b-1 {
        margin-bottom: 1px
    }

    .mg-xl-l-1 {
        margin-left: 1px
    }

    .mg-xl-t-1-f {
        margin-top: 1px !important
    }

    .mg-xl-r-1-f {
        margin-right: 1px !important
    }

    .mg-xl-b-1-f {
        margin-bottom: 1px !important
    }

    .mg-xl-l-1-f {
        margin-left: 1px !important
    }

    .mg-xl-2 {
        margin: 2px
    }

    .mg-xl-2-f {
        margin: 2px !important
    }

    .mg-xl-y-2 {
        margin-top: 2px;
        margin-bottom: 2px
    }

    .mg-xl-y-2-f {
        margin-top: 2px !important;
        margin-bottom: 2px !important
    }

    .mg-xl-x-2 {
        margin-left: 2px;
        margin-right: 2px
    }

    .mg-xl-x-2-f {
        margin-left: 2px !important;
        margin-right: 2px !important
    }

    .mg-xl-t-2 {
        margin-top: 2px
    }

    .mg-xl-r-2 {
        margin-right: 2px
    }

    .mg-xl-b-2 {
        margin-bottom: 2px
    }

    .mg-xl-l-2 {
        margin-left: 2px
    }

    .mg-xl-t-2-f {
        margin-top: 2px !important
    }

    .mg-xl-r-2-f {
        margin-right: 2px !important
    }

    .mg-xl-b-2-f {
        margin-bottom: 2px !important
    }

    .mg-xl-l-2-f {
        margin-left: 2px !important
    }

    .mg-xl-3 {
        margin: 3px
    }

    .mg-xl-3-f {
        margin: 3px !important
    }

    .mg-xl-y-3 {
        margin-top: 3px;
        margin-bottom: 3px
    }

    .mg-xl-y-3-f {
        margin-top: 3px !important;
        margin-bottom: 3px !important
    }

    .mg-xl-x-3 {
        margin-left: 3px;
        margin-right: 3px
    }

    .mg-xl-x-3-f {
        margin-left: 3px !important;
        margin-right: 3px !important
    }

    .mg-xl-t-3 {
        margin-top: 3px
    }

    .mg-xl-r-3 {
        margin-right: 3px
    }

    .mg-xl-b-3 {
        margin-bottom: 3px
    }

    .mg-xl-l-3 {
        margin-left: 3px
    }

    .mg-xl-t-3-f {
        margin-top: 3px !important
    }

    .mg-xl-r-3-f {
        margin-right: 3px !important
    }

    .mg-xl-b-3-f {
        margin-bottom: 3px !important
    }

    .mg-xl-l-3-f {
        margin-left: 3px !important
    }

    .mg-xl-4 {
        margin: 4px
    }

    .mg-xl-4-f {
        margin: 4px !important
    }

    .mg-xl-y-4 {
        margin-top: 4px;
        margin-bottom: 4px
    }

    .mg-xl-y-4-f {
        margin-top: 4px !important;
        margin-bottom: 4px !important
    }

    .mg-xl-x-4 {
        margin-left: 4px;
        margin-right: 4px
    }

    .mg-xl-x-4-f {
        margin-left: 4px !important;
        margin-right: 4px !important
    }

    .mg-xl-t-4 {
        margin-top: 4px
    }

    .mg-xl-r-4 {
        margin-right: 4px
    }

    .mg-xl-b-4 {
        margin-bottom: 4px
    }

    .mg-xl-l-4 {
        margin-left: 4px
    }

    .mg-xl-t-4-f {
        margin-top: 4px !important
    }

    .mg-xl-r-4-f {
        margin-right: 4px !important
    }

    .mg-xl-b-4-f {
        margin-bottom: 4px !important
    }

    .mg-xl-l-4-f {
        margin-left: 4px !important
    }

    .mg-xl-5 {
        margin: 5px
    }

    .mg-xl-5-f {
        margin: 5px !important
    }

    .mg-xl-y-5 {
        margin-top: 5px;
        margin-bottom: 5px
    }

    .mg-xl-y-5-f {
        margin-top: 5px !important;
        margin-bottom: 5px !important
    }

    .mg-xl-x-5 {
        margin-left: 5px;
        margin-right: 5px
    }

    .mg-xl-x-5-f {
        margin-left: 5px !important;
        margin-right: 5px !important
    }

    .mg-xl-t-5 {
        margin-top: 5px
    }

    .mg-xl-r-5 {
        margin-right: 5px
    }

    .mg-xl-b-5 {
        margin-bottom: 5px
    }

    .mg-xl-l-5 {
        margin-left: 5px
    }

    .mg-xl-t-5-f {
        margin-top: 5px !important
    }

    .mg-xl-r-5-f {
        margin-right: 5px !important
    }

    .mg-xl-b-5-f {
        margin-bottom: 5px !important
    }

    .mg-xl-l-5-f {
        margin-left: 5px !important
    }

    .mg-xl-6 {
        margin: 6px
    }

    .mg-xl-6-f {
        margin: 6px !important
    }

    .mg-xl-y-6 {
        margin-top: 6px;
        margin-bottom: 6px
    }

    .mg-xl-y-6-f {
        margin-top: 6px !important;
        margin-bottom: 6px !important
    }

    .mg-xl-x-6 {
        margin-left: 6px;
        margin-right: 6px
    }

    .mg-xl-x-6-f {
        margin-left: 6px !important;
        margin-right: 6px !important
    }

    .mg-xl-t-6 {
        margin-top: 6px
    }

    .mg-xl-r-6 {
        margin-right: 6px
    }

    .mg-xl-b-6 {
        margin-bottom: 6px
    }

    .mg-xl-l-6 {
        margin-left: 6px
    }

    .mg-xl-t-6-f {
        margin-top: 6px !important
    }

    .mg-xl-r-6-f {
        margin-right: 6px !important
    }

    .mg-xl-b-6-f {
        margin-bottom: 6px !important
    }

    .mg-xl-l-6-f {
        margin-left: 6px !important
    }

    .mg-xl-7 {
        margin: 7px
    }

    .mg-xl-7-f {
        margin: 7px !important
    }

    .mg-xl-y-7 {
        margin-top: 7px;
        margin-bottom: 7px
    }

    .mg-xl-y-7-f {
        margin-top: 7px !important;
        margin-bottom: 7px !important
    }

    .mg-xl-x-7 {
        margin-left: 7px;
        margin-right: 7px
    }

    .mg-xl-x-7-f {
        margin-left: 7px !important;
        margin-right: 7px !important
    }

    .mg-xl-t-7 {
        margin-top: 7px
    }

    .mg-xl-r-7 {
        margin-right: 7px
    }

    .mg-xl-b-7 {
        margin-bottom: 7px
    }

    .mg-xl-l-7 {
        margin-left: 7px
    }

    .mg-xl-t-7-f {
        margin-top: 7px !important
    }

    .mg-xl-r-7-f {
        margin-right: 7px !important
    }

    .mg-xl-b-7-f {
        margin-bottom: 7px !important
    }

    .mg-xl-l-7-f {
        margin-left: 7px !important
    }

    .mg-xl-8 {
        margin: 8px
    }

    .mg-xl-8-f {
        margin: 8px !important
    }

    .mg-xl-y-8 {
        margin-top: 8px;
        margin-bottom: 8px
    }

    .mg-xl-y-8-f {
        margin-top: 8px !important;
        margin-bottom: 8px !important
    }

    .mg-xl-x-8 {
        margin-left: 8px;
        margin-right: 8px
    }

    .mg-xl-x-8-f {
        margin-left: 8px !important;
        margin-right: 8px !important
    }

    .mg-xl-t-8 {
        margin-top: 8px
    }

    .mg-xl-r-8 {
        margin-right: 8px
    }

    .mg-xl-b-8 {
        margin-bottom: 8px
    }

    .mg-xl-l-8 {
        margin-left: 8px
    }

    .mg-xl-t-8-f {
        margin-top: 8px !important
    }

    .mg-xl-r-8-f {
        margin-right: 8px !important
    }

    .mg-xl-b-8-f {
        margin-bottom: 8px !important
    }

    .mg-xl-l-8-f {
        margin-left: 8px !important
    }

    .mg-xl-9 {
        margin: 9px
    }

    .mg-xl-9-f {
        margin: 9px !important
    }

    .mg-xl-y-9 {
        margin-top: 9px;
        margin-bottom: 9px
    }

    .mg-xl-y-9-f {
        margin-top: 9px !important;
        margin-bottom: 9px !important
    }

    .mg-xl-x-9 {
        margin-left: 9px;
        margin-right: 9px
    }

    .mg-xl-x-9-f {
        margin-left: 9px !important;
        margin-right: 9px !important
    }

    .mg-xl-t-9 {
        margin-top: 9px
    }

    .mg-xl-r-9 {
        margin-right: 9px
    }

    .mg-xl-b-9 {
        margin-bottom: 9px
    }

    .mg-xl-l-9 {
        margin-left: 9px
    }

    .mg-xl-t-9-f {
        margin-top: 9px !important
    }

    .mg-xl-r-9-f {
        margin-right: 9px !important
    }

    .mg-xl-b-9-f {
        margin-bottom: 9px !important
    }

    .mg-xl-l-9-f {
        margin-left: 9px !important
    }

    .mg-xl-10 {
        margin: 10px
    }

    .mg-xl-10-f {
        margin: 10px !important
    }

    .mg-xl-y-10 {
        margin-top: 10px;
        margin-bottom: 10px
    }

    .mg-xl-y-10-f {
        margin-top: 10px !important;
        margin-bottom: 10px !important
    }

    .mg-xl-x-10 {
        margin-left: 10px;
        margin-right: 10px
    }

    .mg-xl-x-10-f {
        margin-left: 10px !important;
        margin-right: 10px !important
    }

    .mg-xl-t-10 {
        margin-top: 10px
    }

    .mg-xl-r-10 {
        margin-right: 10px
    }

    .mg-xl-b-10 {
        margin-bottom: 10px
    }

    .mg-xl-l-10 {
        margin-left: 10px
    }

    .mg-xl-t-10-f {
        margin-top: 10px !important
    }

    .mg-xl-r-10-f {
        margin-right: 10px !important
    }

    .mg-xl-b-10-f {
        margin-bottom: 10px !important
    }

    .mg-xl-l-10-f {
        margin-left: 10px !important
    }

    .mg-xl-15 {
        margin: 15px
    }

    .mg-xl-15-f {
        margin: 15px !important
    }

    .mg-xl-y-15 {
        margin-top: 15px;
        margin-bottom: 15px
    }

    .mg-xl-y-15-f {
        margin-top: 15px !important;
        margin-bottom: 15px !important
    }

    .mg-xl-x-15 {
        margin-left: 15px;
        margin-right: 15px
    }

    .mg-xl-x-15-f {
        margin-left: 15px !important;
        margin-right: 15px !important
    }

    .mg-xl-t-15 {
        margin-top: 15px
    }

    .mg-xl-r-15 {
        margin-right: 15px
    }

    .mg-xl-b-15 {
        margin-bottom: 15px
    }

    .mg-xl-l-15 {
        margin-left: 15px
    }

    .mg-xl-t-15-f {
        margin-top: 15px !important
    }

    .mg-xl-r-15-f {
        margin-right: 15px !important
    }

    .mg-xl-b-15-f {
        margin-bottom: 15px !important
    }

    .mg-xl-l-15-f {
        margin-left: 15px !important
    }

    .mg-xl-20 {
        margin: 20px
    }

    .mg-xl-20-f {
        margin: 20px !important
    }

    .mg-xl-y-20 {
        margin-top: 20px;
        margin-bottom: 20px
    }

    .mg-xl-y-20-f {
        margin-top: 20px !important;
        margin-bottom: 20px !important
    }

    .mg-xl-x-20 {
        margin-left: 20px;
        margin-right: 20px
    }

    .mg-xl-x-20-f {
        margin-left: 20px !important;
        margin-right: 20px !important
    }

    .mg-xl-t-20 {
        margin-top: 20px
    }

    .mg-xl-r-20 {
        margin-right: 20px
    }

    .mg-xl-b-20 {
        margin-bottom: 20px
    }

    .mg-xl-l-20 {
        margin-left: 20px
    }

    .mg-xl-t-20-f {
        margin-top: 20px !important
    }

    .mg-xl-r-20-f {
        margin-right: 20px !important
    }

    .mg-xl-b-20-f {
        margin-bottom: 20px !important
    }

    .mg-xl-l-20-f {
        margin-left: 20px !important
    }

    .mg-xl-25 {
        margin: 25px
    }

    .mg-xl-25-f {
        margin: 25px !important
    }

    .mg-xl-y-25 {
        margin-top: 25px;
        margin-bottom: 25px
    }

    .mg-xl-y-25-f {
        margin-top: 25px !important;
        margin-bottom: 25px !important
    }

    .mg-xl-x-25 {
        margin-left: 25px;
        margin-right: 25px
    }

    .mg-xl-x-25-f {
        margin-left: 25px !important;
        margin-right: 25px !important
    }

    .mg-xl-t-25 {
        margin-top: 25px
    }

    .mg-xl-r-25 {
        margin-right: 25px
    }

    .mg-xl-b-25 {
        margin-bottom: 25px
    }

    .mg-xl-l-25 {
        margin-left: 25px
    }

    .mg-xl-t-25-f {
        margin-top: 25px !important
    }

    .mg-xl-r-25-f {
        margin-right: 25px !important
    }

    .mg-xl-b-25-f {
        margin-bottom: 25px !important
    }

    .mg-xl-l-25-f {
        margin-left: 25px !important
    }

    .mg-xl-30 {
        margin: 30px
    }

    .mg-xl-30-f {
        margin: 30px !important
    }

    .mg-xl-y-30 {
        margin-top: 30px;
        margin-bottom: 30px
    }

    .mg-xl-y-30-f {
        margin-top: 30px !important;
        margin-bottom: 30px !important
    }

    .mg-xl-x-30 {
        margin-left: 30px;
        margin-right: 30px
    }

    .mg-xl-x-30-f {
        margin-left: 30px !important;
        margin-right: 30px !important
    }

    .mg-xl-t-30 {
        margin-top: 30px
    }

    .mg-xl-r-30 {
        margin-right: 30px
    }

    .mg-xl-b-30 {
        margin-bottom: 30px
    }

    .mg-xl-l-30 {
        margin-left: 30px
    }

    .mg-xl-t-30-f {
        margin-top: 30px !important
    }

    .mg-xl-r-30-f {
        margin-right: 30px !important
    }

    .mg-xl-b-30-f {
        margin-bottom: 30px !important
    }

    .mg-xl-l-30-f {
        margin-left: 30px !important
    }

    .mg-xl-35 {
        margin: 35px
    }

    .mg-xl-35-f {
        margin: 35px !important
    }

    .mg-xl-y-35 {
        margin-top: 35px;
        margin-bottom: 35px
    }

    .mg-xl-y-35-f {
        margin-top: 35px !important;
        margin-bottom: 35px !important
    }

    .mg-xl-x-35 {
        margin-left: 35px;
        margin-right: 35px
    }

    .mg-xl-x-35-f {
        margin-left: 35px !important;
        margin-right: 35px !important
    }

    .mg-xl-t-35 {
        margin-top: 35px
    }

    .mg-xl-r-35 {
        margin-right: 35px
    }

    .mg-xl-b-35 {
        margin-bottom: 35px
    }

    .mg-xl-l-35 {
        margin-left: 35px
    }

    .mg-xl-t-35-f {
        margin-top: 35px !important
    }

    .mg-xl-r-35-f {
        margin-right: 35px !important
    }

    .mg-xl-b-35-f {
        margin-bottom: 35px !important
    }

    .mg-xl-l-35-f {
        margin-left: 35px !important
    }

    .mg-xl-40 {
        margin: 40px
    }

    .mg-xl-40-f {
        margin: 40px !important
    }

    .mg-xl-y-40 {
        margin-top: 40px;
        margin-bottom: 40px
    }

    .mg-xl-y-40-f {
        margin-top: 40px !important;
        margin-bottom: 40px !important
    }

    .mg-xl-x-40 {
        margin-left: 40px;
        margin-right: 40px
    }

    .mg-xl-x-40-f {
        margin-left: 40px !important;
        margin-right: 40px !important
    }

    .mg-xl-t-40 {
        margin-top: 40px
    }

    .mg-xl-r-40 {
        margin-right: 40px
    }

    .mg-xl-b-40 {
        margin-bottom: 40px
    }

    .mg-xl-l-40 {
        margin-left: 40px
    }

    .mg-xl-t-40-f {
        margin-top: 40px !important
    }

    .mg-xl-r-40-f {
        margin-right: 40px !important
    }

    .mg-xl-b-40-f {
        margin-bottom: 40px !important
    }

    .mg-xl-l-40-f {
        margin-left: 40px !important
    }

    .mg-xl-45 {
        margin: 45px
    }

    .mg-xl-45-f {
        margin: 45px !important
    }

    .mg-xl-y-45 {
        margin-top: 45px;
        margin-bottom: 45px
    }

    .mg-xl-y-45-f {
        margin-top: 45px !important;
        margin-bottom: 45px !important
    }

    .mg-xl-x-45 {
        margin-left: 45px;
        margin-right: 45px
    }

    .mg-xl-x-45-f {
        margin-left: 45px !important;
        margin-right: 45px !important
    }

    .mg-xl-t-45 {
        margin-top: 45px
    }

    .mg-xl-r-45 {
        margin-right: 45px
    }

    .mg-xl-b-45 {
        margin-bottom: 45px
    }

    .mg-xl-l-45 {
        margin-left: 45px
    }

    .mg-xl-t-45-f {
        margin-top: 45px !important
    }

    .mg-xl-r-45-f {
        margin-right: 45px !important
    }

    .mg-xl-b-45-f {
        margin-bottom: 45px !important
    }

    .mg-xl-l-45-f {
        margin-left: 45px !important
    }

    .mg-xl-50 {
        margin: 50px
    }

    .mg-xl-50-f {
        margin: 50px !important
    }

    .mg-xl-y-50 {
        margin-top: 50px;
        margin-bottom: 50px
    }

    .mg-xl-y-50-f {
        margin-top: 50px !important;
        margin-bottom: 50px !important
    }

    .mg-xl-x-50 {
        margin-left: 50px;
        margin-right: 50px
    }

    .mg-xl-x-50-f {
        margin-left: 50px !important;
        margin-right: 50px !important
    }

    .mg-xl-t-50 {
        margin-top: 50px
    }

    .mg-xl-r-50 {
        margin-right: 50px
    }

    .mg-xl-b-50 {
        margin-bottom: 50px
    }

    .mg-xl-l-50 {
        margin-left: 50px
    }

    .mg-xl-t-50-f {
        margin-top: 50px !important
    }

    .mg-xl-r-50-f {
        margin-right: 50px !important
    }

    .mg-xl-b-50-f {
        margin-bottom: 50px !important
    }

    .mg-xl-l-50-f {
        margin-left: 50px !important
    }

    .mg-xl-55 {
        margin: 55px
    }

    .mg-xl-55-f {
        margin: 55px !important
    }

    .mg-xl-y-55 {
        margin-top: 55px;
        margin-bottom: 55px
    }

    .mg-xl-y-55-f {
        margin-top: 55px !important;
        margin-bottom: 55px !important
    }

    .mg-xl-x-55 {
        margin-left: 55px;
        margin-right: 55px
    }

    .mg-xl-x-55-f {
        margin-left: 55px !important;
        margin-right: 55px !important
    }

    .mg-xl-t-55 {
        margin-top: 55px
    }

    .mg-xl-r-55 {
        margin-right: 55px
    }

    .mg-xl-b-55 {
        margin-bottom: 55px
    }

    .mg-xl-l-55 {
        margin-left: 55px
    }

    .mg-xl-t-55-f {
        margin-top: 55px !important
    }

    .mg-xl-r-55-f {
        margin-right: 55px !important
    }

    .mg-xl-b-55-f {
        margin-bottom: 55px !important
    }

    .mg-xl-l-55-f {
        margin-left: 55px !important
    }

    .mg-xl-60 {
        margin: 60px
    }

    .mg-xl-60-f {
        margin: 60px !important
    }

    .mg-xl-y-60 {
        margin-top: 60px;
        margin-bottom: 60px
    }

    .mg-xl-y-60-f {
        margin-top: 60px !important;
        margin-bottom: 60px !important
    }

    .mg-xl-x-60 {
        margin-left: 60px;
        margin-right: 60px
    }

    .mg-xl-x-60-f {
        margin-left: 60px !important;
        margin-right: 60px !important
    }

    .mg-xl-t-60 {
        margin-top: 60px
    }

    .mg-xl-r-60 {
        margin-right: 60px
    }

    .mg-xl-b-60 {
        margin-bottom: 60px
    }

    .mg-xl-l-60 {
        margin-left: 60px
    }

    .mg-xl-t-60-f {
        margin-top: 60px !important
    }

    .mg-xl-r-60-f {
        margin-right: 60px !important
    }

    .mg-xl-b-60-f {
        margin-bottom: 60px !important
    }

    .mg-xl-l-60-f {
        margin-left: 60px !important
    }

    .mg-xl-65 {
        margin: 65px
    }

    .mg-xl-65-f {
        margin: 65px !important
    }

    .mg-xl-y-65 {
        margin-top: 65px;
        margin-bottom: 65px
    }

    .mg-xl-y-65-f {
        margin-top: 65px !important;
        margin-bottom: 65px !important
    }

    .mg-xl-x-65 {
        margin-left: 65px;
        margin-right: 65px
    }

    .mg-xl-x-65-f {
        margin-left: 65px !important;
        margin-right: 65px !important
    }

    .mg-xl-t-65 {
        margin-top: 65px
    }

    .mg-xl-r-65 {
        margin-right: 65px
    }

    .mg-xl-b-65 {
        margin-bottom: 65px
    }

    .mg-xl-l-65 {
        margin-left: 65px
    }

    .mg-xl-t-65-f {
        margin-top: 65px !important
    }

    .mg-xl-r-65-f {
        margin-right: 65px !important
    }

    .mg-xl-b-65-f {
        margin-bottom: 65px !important
    }

    .mg-xl-l-65-f {
        margin-left: 65px !important
    }

    .mg-xl-70 {
        margin: 70px
    }

    .mg-xl-70-f {
        margin: 70px !important
    }

    .mg-xl-y-70 {
        margin-top: 70px;
        margin-bottom: 70px
    }

    .mg-xl-y-70-f {
        margin-top: 70px !important;
        margin-bottom: 70px !important
    }

    .mg-xl-x-70 {
        margin-left: 70px;
        margin-right: 70px
    }

    .mg-xl-x-70-f {
        margin-left: 70px !important;
        margin-right: 70px !important
    }

    .mg-xl-t-70 {
        margin-top: 70px
    }

    .mg-xl-r-70 {
        margin-right: 70px
    }

    .mg-xl-b-70 {
        margin-bottom: 70px
    }

    .mg-xl-l-70 {
        margin-left: 70px
    }

    .mg-xl-t-70-f {
        margin-top: 70px !important
    }

    .mg-xl-r-70-f {
        margin-right: 70px !important
    }

    .mg-xl-b-70-f {
        margin-bottom: 70px !important
    }

    .mg-xl-l-70-f {
        margin-left: 70px !important
    }

    .mg-xl-75 {
        margin: 75px
    }

    .mg-xl-75-f {
        margin: 75px !important
    }

    .mg-xl-y-75 {
        margin-top: 75px;
        margin-bottom: 75px
    }

    .mg-xl-y-75-f {
        margin-top: 75px !important;
        margin-bottom: 75px !important
    }

    .mg-xl-x-75 {
        margin-left: 75px;
        margin-right: 75px
    }

    .mg-xl-x-75-f {
        margin-left: 75px !important;
        margin-right: 75px !important
    }

    .mg-xl-t-75 {
        margin-top: 75px
    }

    .mg-xl-r-75 {
        margin-right: 75px
    }

    .mg-xl-b-75 {
        margin-bottom: 75px
    }

    .mg-xl-l-75 {
        margin-left: 75px
    }

    .mg-xl-t-75-f {
        margin-top: 75px !important
    }

    .mg-xl-r-75-f {
        margin-right: 75px !important
    }

    .mg-xl-b-75-f {
        margin-bottom: 75px !important
    }

    .mg-xl-l-75-f {
        margin-left: 75px !important
    }

    .mg-xl-80 {
        margin: 80px
    }

    .mg-xl-80-f {
        margin: 80px !important
    }

    .mg-xl-y-80 {
        margin-top: 80px;
        margin-bottom: 80px
    }

    .mg-xl-y-80-f {
        margin-top: 80px !important;
        margin-bottom: 80px !important
    }

    .mg-xl-x-80 {
        margin-left: 80px;
        margin-right: 80px
    }

    .mg-xl-x-80-f {
        margin-left: 80px !important;
        margin-right: 80px !important
    }

    .mg-xl-t-80 {
        margin-top: 80px
    }

    .mg-xl-r-80 {
        margin-right: 80px
    }

    .mg-xl-b-80 {
        margin-bottom: 80px
    }

    .mg-xl-l-80 {
        margin-left: 80px
    }

    .mg-xl-t-80-f {
        margin-top: 80px !important
    }

    .mg-xl-r-80-f {
        margin-right: 80px !important
    }

    .mg-xl-b-80-f {
        margin-bottom: 80px !important
    }

    .mg-xl-l-80-f {
        margin-left: 80px !important
    }

    .mg-xl-85 {
        margin: 85px
    }

    .mg-xl-85-f {
        margin: 85px !important
    }

    .mg-xl-y-85 {
        margin-top: 85px;
        margin-bottom: 85px
    }

    .mg-xl-y-85-f {
        margin-top: 85px !important;
        margin-bottom: 85px !important
    }

    .mg-xl-x-85 {
        margin-left: 85px;
        margin-right: 85px
    }

    .mg-xl-x-85-f {
        margin-left: 85px !important;
        margin-right: 85px !important
    }

    .mg-xl-t-85 {
        margin-top: 85px
    }

    .mg-xl-r-85 {
        margin-right: 85px
    }

    .mg-xl-b-85 {
        margin-bottom: 85px
    }

    .mg-xl-l-85 {
        margin-left: 85px
    }

    .mg-xl-t-85-f {
        margin-top: 85px !important
    }

    .mg-xl-r-85-f {
        margin-right: 85px !important
    }

    .mg-xl-b-85-f {
        margin-bottom: 85px !important
    }

    .mg-xl-l-85-f {
        margin-left: 85px !important
    }

    .mg-xl-90 {
        margin: 90px
    }

    .mg-xl-90-f {
        margin: 90px !important
    }

    .mg-xl-y-90 {
        margin-top: 90px;
        margin-bottom: 90px
    }

    .mg-xl-y-90-f {
        margin-top: 90px !important;
        margin-bottom: 90px !important
    }

    .mg-xl-x-90 {
        margin-left: 90px;
        margin-right: 90px
    }

    .mg-xl-x-90-f {
        margin-left: 90px !important;
        margin-right: 90px !important
    }

    .mg-xl-t-90 {
        margin-top: 90px
    }

    .mg-xl-r-90 {
        margin-right: 90px
    }

    .mg-xl-b-90 {
        margin-bottom: 90px
    }

    .mg-xl-l-90 {
        margin-left: 90px
    }

    .mg-xl-t-90-f {
        margin-top: 90px !important
    }

    .mg-xl-r-90-f {
        margin-right: 90px !important
    }

    .mg-xl-b-90-f {
        margin-bottom: 90px !important
    }

    .mg-xl-l-90-f {
        margin-left: 90px !important
    }

    .mg-xl-95 {
        margin: 95px
    }

    .mg-xl-95-f {
        margin: 95px !important
    }

    .mg-xl-y-95 {
        margin-top: 95px;
        margin-bottom: 95px
    }

    .mg-xl-y-95-f {
        margin-top: 95px !important;
        margin-bottom: 95px !important
    }

    .mg-xl-x-95 {
        margin-left: 95px;
        margin-right: 95px
    }

    .mg-xl-x-95-f {
        margin-left: 95px !important;
        margin-right: 95px !important
    }

    .mg-xl-t-95 {
        margin-top: 95px
    }

    .mg-xl-r-95 {
        margin-right: 95px
    }

    .mg-xl-b-95 {
        margin-bottom: 95px
    }

    .mg-xl-l-95 {
        margin-left: 95px
    }

    .mg-xl-t-95-f {
        margin-top: 95px !important
    }

    .mg-xl-r-95-f {
        margin-right: 95px !important
    }

    .mg-xl-b-95-f {
        margin-bottom: 95px !important
    }

    .mg-xl-l-95-f {
        margin-left: 95px !important
    }

    .mg-xl-100 {
        margin: 100px
    }

    .mg-xl-100-f {
        margin: 100px !important
    }

    .mg-xl-y-100 {
        margin-top: 100px;
        margin-bottom: 100px
    }

    .mg-xl-y-100-f {
        margin-top: 100px !important;
        margin-bottom: 100px !important
    }

    .mg-xl-x-100 {
        margin-left: 100px;
        margin-right: 100px
    }

    .mg-xl-x-100-f {
        margin-left: 100px !important;
        margin-right: 100px !important
    }

    .mg-xl-t-100 {
        margin-top: 100px
    }

    .mg-xl-r-100 {
        margin-right: 100px
    }

    .mg-xl-b-100 {
        margin-bottom: 100px
    }

    .mg-xl-l-100 {
        margin-left: 100px
    }

    .mg-xl-t-100-f {
        margin-top: 100px !important
    }

    .mg-xl-r-100-f {
        margin-right: 100px !important
    }

    .mg-xl-b-100-f {
        margin-bottom: 100px !important
    }

    .mg-xl-l-100-f {
        margin-left: 100px !important
    }

    .mg-xl-150 {
        margin: 150px
    }

    .mg-xl-150-f {
        margin: 150px !important
    }

    .mg-xl-y-150 {
        margin-top: 150px;
        margin-bottom: 150px
    }

    .mg-xl-y-150-f {
        margin-top: 150px !important;
        margin-bottom: 150px !important
    }

    .mg-xl-x-150 {
        margin-left: 150px;
        margin-right: 150px
    }

    .mg-xl-x-150-f {
        margin-left: 150px !important;
        margin-right: 150px !important
    }

    .mg-xl-t-150 {
        margin-top: 150px
    }

    .mg-xl-r-150 {
        margin-right: 150px
    }

    .mg-xl-b-150 {
        margin-bottom: 150px
    }

    .mg-xl-l-150 {
        margin-left: 150px
    }

    .mg-xl-t-150-f {
        margin-top: 150px !important
    }

    .mg-xl-r-150-f {
        margin-right: 150px !important
    }

    .mg-xl-b-150-f {
        margin-bottom: 150px !important
    }

    .mg-xl-l-150-f {
        margin-left: 150px !important
    }

    .mg-xl-200 {
        margin: 200px
    }

    .mg-xl-200-f {
        margin: 200px !important
    }

    .mg-xl-y-200 {
        margin-top: 200px;
        margin-bottom: 200px
    }

    .mg-xl-y-200-f {
        margin-top: 200px !important;
        margin-bottom: 200px !important
    }

    .mg-xl-x-200 {
        margin-left: 200px;
        margin-right: 200px
    }

    .mg-xl-x-200-f {
        margin-left: 200px !important;
        margin-right: 200px !important
    }

    .mg-xl-t-200 {
        margin-top: 200px
    }

    .mg-xl-r-200 {
        margin-right: 200px
    }

    .mg-xl-b-200 {
        margin-bottom: 200px
    }

    .mg-xl-l-200 {
        margin-left: 200px
    }

    .mg-xl-t-200-f {
        margin-top: 200px !important
    }

    .mg-xl-r-200-f {
        margin-right: 200px !important
    }

    .mg-xl-b-200-f {
        margin-bottom: 200px !important
    }

    .mg-xl-l-200-f {
        margin-left: 200px !important
    }

    .mg-xl-250 {
        margin: 250px
    }

    .mg-xl-250-f {
        margin: 250px !important
    }

    .mg-xl-y-250 {
        margin-top: 250px;
        margin-bottom: 250px
    }

    .mg-xl-y-250-f {
        margin-top: 250px !important;
        margin-bottom: 250px !important
    }

    .mg-xl-x-250 {
        margin-left: 250px;
        margin-right: 250px
    }

    .mg-xl-x-250-f {
        margin-left: 250px !important;
        margin-right: 250px !important
    }

    .mg-xl-t-250 {
        margin-top: 250px
    }

    .mg-xl-r-250 {
        margin-right: 250px
    }

    .mg-xl-b-250 {
        margin-bottom: 250px
    }

    .mg-xl-l-250 {
        margin-left: 250px
    }

    .mg-xl-t-250-f {
        margin-top: 250px !important
    }

    .mg-xl-r-250-f {
        margin-right: 250px !important
    }

    .mg-xl-b-250-f {
        margin-bottom: 250px !important
    }

    .mg-xl-l-250-f {
        margin-left: 250px !important
    }

    .mg-xl-300 {
        margin: 300px
    }

    .mg-xl-300-f {
        margin: 300px !important
    }

    .mg-xl-y-300 {
        margin-top: 300px;
        margin-bottom: 300px
    }

    .mg-xl-y-300-f {
        margin-top: 300px !important;
        margin-bottom: 300px !important
    }

    .mg-xl-x-300 {
        margin-left: 300px;
        margin-right: 300px
    }

    .mg-xl-x-300-f {
        margin-left: 300px !important;
        margin-right: 300px !important
    }

    .mg-xl-t-300 {
        margin-top: 300px
    }

    .mg-xl-r-300 {
        margin-right: 300px
    }

    .mg-xl-b-300 {
        margin-bottom: 300px
    }

    .mg-xl-l-300 {
        margin-left: 300px
    }

    .mg-xl-t-300-f {
        margin-top: 300px !important
    }

    .mg-xl-r-300-f {
        margin-right: 300px !important
    }

    .mg-xl-b-300-f {
        margin-bottom: 300px !important
    }

    .mg-xl-l-300-f {
        margin-left: 300px !important
    }

    .mg-xl-350 {
        margin: 350px
    }

    .mg-xl-350-f {
        margin: 350px !important
    }

    .mg-xl-y-350 {
        margin-top: 350px;
        margin-bottom: 350px
    }

    .mg-xl-y-350-f {
        margin-top: 350px !important;
        margin-bottom: 350px !important
    }

    .mg-xl-x-350 {
        margin-left: 350px;
        margin-right: 350px
    }

    .mg-xl-x-350-f {
        margin-left: 350px !important;
        margin-right: 350px !important
    }

    .mg-xl-t-350 {
        margin-top: 350px
    }

    .mg-xl-r-350 {
        margin-right: 350px
    }

    .mg-xl-b-350 {
        margin-bottom: 350px
    }

    .mg-xl-l-350 {
        margin-left: 350px
    }

    .mg-xl-t-350-f {
        margin-top: 350px !important
    }

    .mg-xl-r-350-f {
        margin-right: 350px !important
    }

    .mg-xl-b-350-f {
        margin-bottom: 350px !important
    }

    .mg-xl-l-350-f {
        margin-left: 350px !important
    }

    .mg-xl-400 {
        margin: 400px
    }

    .mg-xl-400-f {
        margin: 400px !important
    }

    .mg-xl-y-400 {
        margin-top: 400px;
        margin-bottom: 400px
    }

    .mg-xl-y-400-f {
        margin-top: 400px !important;
        margin-bottom: 400px !important
    }

    .mg-xl-x-400 {
        margin-left: 400px;
        margin-right: 400px
    }

    .mg-xl-x-400-f {
        margin-left: 400px !important;
        margin-right: 400px !important
    }

    .mg-xl-t-400 {
        margin-top: 400px
    }

    .mg-xl-r-400 {
        margin-right: 400px
    }

    .mg-xl-b-400 {
        margin-bottom: 400px
    }

    .mg-xl-l-400 {
        margin-left: 400px
    }

    .mg-xl-t-400-f {
        margin-top: 400px !important
    }

    .mg-xl-r-400-f {
        margin-right: 400px !important
    }

    .mg-xl-b-400-f {
        margin-bottom: 400px !important
    }

    .mg-xl-l-400-f {
        margin-left: 400px !important
    }

    .mg-xl-450 {
        margin: 450px
    }

    .mg-xl-450-f {
        margin: 450px !important
    }

    .mg-xl-y-450 {
        margin-top: 450px;
        margin-bottom: 450px
    }

    .mg-xl-y-450-f {
        margin-top: 450px !important;
        margin-bottom: 450px !important
    }

    .mg-xl-x-450 {
        margin-left: 450px;
        margin-right: 450px
    }

    .mg-xl-x-450-f {
        margin-left: 450px !important;
        margin-right: 450px !important
    }

    .mg-xl-t-450 {
        margin-top: 450px
    }

    .mg-xl-r-450 {
        margin-right: 450px
    }

    .mg-xl-b-450 {
        margin-bottom: 450px
    }

    .mg-xl-l-450 {
        margin-left: 450px
    }

    .mg-xl-t-450-f {
        margin-top: 450px !important
    }

    .mg-xl-r-450-f {
        margin-right: 450px !important
    }

    .mg-xl-b-450-f {
        margin-bottom: 450px !important
    }

    .mg-xl-l-450-f {
        margin-left: 450px !important
    }

    .mg-xl-500 {
        margin: 500px
    }

    .mg-xl-500-f {
        margin: 500px !important
    }

    .mg-xl-y-500 {
        margin-top: 500px;
        margin-bottom: 500px
    }

    .mg-xl-y-500-f {
        margin-top: 500px !important;
        margin-bottom: 500px !important
    }

    .mg-xl-x-500 {
        margin-left: 500px;
        margin-right: 500px
    }

    .mg-xl-x-500-f {
        margin-left: 500px !important;
        margin-right: 500px !important
    }

    .mg-xl-t-500 {
        margin-top: 500px
    }

    .mg-xl-r-500 {
        margin-right: 500px
    }

    .mg-xl-b-500 {
        margin-bottom: 500px
    }

    .mg-xl-l-500 {
        margin-left: 500px
    }

    .mg-xl-t-500-f {
        margin-top: 500px !important
    }

    .mg-xl-r-500-f {
        margin-right: 500px !important
    }

    .mg-xl-b-500-f {
        margin-bottom: 500px !important
    }

    .mg-xl-l-500-f {
        margin-left: 500px !important
    }

    .mg-xl-550 {
        margin: 550px
    }

    .mg-xl-550-f {
        margin: 550px !important
    }

    .mg-xl-y-550 {
        margin-top: 550px;
        margin-bottom: 550px
    }

    .mg-xl-y-550-f {
        margin-top: 550px !important;
        margin-bottom: 550px !important
    }

    .mg-xl-x-550 {
        margin-left: 550px;
        margin-right: 550px
    }

    .mg-xl-x-550-f {
        margin-left: 550px !important;
        margin-right: 550px !important
    }

    .mg-xl-t-550 {
        margin-top: 550px
    }

    .mg-xl-r-550 {
        margin-right: 550px
    }

    .mg-xl-b-550 {
        margin-bottom: 550px
    }

    .mg-xl-l-550 {
        margin-left: 550px
    }

    .mg-xl-t-550-f {
        margin-top: 550px !important
    }

    .mg-xl-r-550-f {
        margin-right: 550px !important
    }

    .mg-xl-b-550-f {
        margin-bottom: 550px !important
    }

    .mg-xl-l-550-f {
        margin-left: 550px !important
    }

    .mg-xl-600 {
        margin: 600px
    }

    .mg-xl-600-f {
        margin: 600px !important
    }

    .mg-xl-y-600 {
        margin-top: 600px;
        margin-bottom: 600px
    }

    .mg-xl-y-600-f {
        margin-top: 600px !important;
        margin-bottom: 600px !important
    }

    .mg-xl-x-600 {
        margin-left: 600px;
        margin-right: 600px
    }

    .mg-xl-x-600-f {
        margin-left: 600px !important;
        margin-right: 600px !important
    }

    .mg-xl-t-600 {
        margin-top: 600px
    }

    .mg-xl-r-600 {
        margin-right: 600px
    }

    .mg-xl-b-600 {
        margin-bottom: 600px
    }

    .mg-xl-l-600 {
        margin-left: 600px
    }

    .mg-xl-t-600-f {
        margin-top: 600px !important
    }

    .mg-xl-r-600-f {
        margin-right: 600px !important
    }

    .mg-xl-b-600-f {
        margin-bottom: 600px !important
    }

    .mg-xl-l-600-f {
        margin-left: 600px !important
    }

    .mg-xl-650 {
        margin: 650px
    }

    .mg-xl-650-f {
        margin: 650px !important
    }

    .mg-xl-y-650 {
        margin-top: 650px;
        margin-bottom: 650px
    }

    .mg-xl-y-650-f {
        margin-top: 650px !important;
        margin-bottom: 650px !important
    }

    .mg-xl-x-650 {
        margin-left: 650px;
        margin-right: 650px
    }

    .mg-xl-x-650-f {
        margin-left: 650px !important;
        margin-right: 650px !important
    }

    .mg-xl-t-650 {
        margin-top: 650px
    }

    .mg-xl-r-650 {
        margin-right: 650px
    }

    .mg-xl-b-650 {
        margin-bottom: 650px
    }

    .mg-xl-l-650 {
        margin-left: 650px
    }

    .mg-xl-t-650-f {
        margin-top: 650px !important
    }

    .mg-xl-r-650-f {
        margin-right: 650px !important
    }

    .mg-xl-b-650-f {
        margin-bottom: 650px !important
    }

    .mg-xl-l-650-f {
        margin-left: 650px !important
    }

    .mg-xl-t-auto {
        margin-top: auto
    }

    .mg-xl-r-auto {
        margin-right: auto
    }

    .mg-xl-b-auto {
        margin-bottom: auto
    }

    .mg-xl-l-auto {
        margin-left: auto
    }

    .mg-xl-auto {
        margin: auto
    }

    .mg-xl-x-auto {
        margin-left: auto;
        margin-right: auto
    }
}

.op-0 {
    opacity: 0
}

.op-1 {
    opacity: .1
}

.op-2 {
    opacity: .2
}

.op-3 {
    opacity: .3
}

.op-4 {
    opacity: .4
}

.op-5 {
    opacity: .5
}

.op-6 {
    opacity: .6
}

.op-7 {
    opacity: .7
}

.op-8 {
    opacity: .8
}

.op-9 {
    opacity: .9
}

.outline-none {
    outline: none
}

.op-none {
    opacity: 1
}

.transition-base {
    transition: all 0.2s ease-in-out
}

@media (prefers-reduced-motion: reduce) {
    .transition-base {
        transition: none
    }
}

.shadow-base {
    box-shadow: 0 0 15px rgba(28, 39, 60, 0.06)
}

.shadow-none {
    box-shadow: none
}

.shadow-none-f {
    box-shadow: none !important
}

.rotate-45 {
    transform: rotate(45deg)
}

.rotate--45 {
    transform: rotate(-45deg)
}

.rotate-90 {
    transform: rotate(90deg)
}

.rotate--90 {
    transform: rotate(-90deg)
}

.overflow-hidden {
    overflow: hidden
}

.overflow-y-auto {
    overflow-y: auto
}

.flex-1 {
    flex: 1
}

.flex-0 {
    flex: 0
}

.img-fit-cover {
    width: 100%;
    height: 100%;
    object-fit: cover
}

.img-object-left {
    object-position: left
}

.img-object-top {
    object-position: top
}

.img-object-top-left {
    object-position: top left
}

.stroke-wd-2-5 {
    stroke-width: 2.5px
}

.stroke-wd-3 {
    stroke-width: 3px
}

@media (min-width: 480px) {
    .op-xs-0 {
        opacity: 0
    }

    .op-xs-1 {
        opacity: .1
    }

    .op-xs-2 {
        opacity: .2
    }

    .op-xs-3 {
        opacity: .3
    }

    .op-xs-4 {
        opacity: .4
    }

    .op-xs-5 {
        opacity: .5
    }

    .op-xs-6 {
        opacity: .6
    }

    .op-xs-7 {
        opacity: .7
    }

    .op-xs-8 {
        opacity: .8
    }

    .op-xs-9 {
        opacity: .9
    }
}

@media (min-width: 576px) {
    .op-sm-0 {
        opacity: 0
    }

    .op-sm-1 {
        opacity: .1
    }

    .op-sm-2 {
        opacity: .2
    }

    .op-sm-3 {
        opacity: .3
    }

    .op-sm-4 {
        opacity: .4
    }

    .op-sm-5 {
        opacity: .5
    }

    .op-sm-6 {
        opacity: .6
    }

    .op-sm-7 {
        opacity: .7
    }

    .op-sm-8 {
        opacity: .8
    }

    .op-sm-9 {
        opacity: .9
    }
}

@media (min-width: 768px) {
    .op-md-0 {
        opacity: 0
    }

    .op-md-1 {
        opacity: .1
    }

    .op-md-2 {
        opacity: .2
    }

    .op-md-3 {
        opacity: .3
    }

    .op-md-4 {
        opacity: .4
    }

    .op-md-5 {
        opacity: .5
    }

    .op-md-6 {
        opacity: .6
    }

    .op-md-7 {
        opacity: .7
    }

    .op-md-8 {
        opacity: .8
    }

    .op-md-9 {
        opacity: .9
    }
}

@media (min-width: 992px) {
    .op-lg-0 {
        opacity: 0
    }

    .op-lg-1 {
        opacity: .1
    }

    .op-lg-2 {
        opacity: .2
    }

    .op-lg-3 {
        opacity: .3
    }

    .op-lg-4 {
        opacity: .4
    }

    .op-lg-5 {
        opacity: .5
    }

    .op-lg-6 {
        opacity: .6
    }

    .op-lg-7 {
        opacity: .7
    }

    .op-lg-8 {
        opacity: .8
    }

    .op-lg-9 {
        opacity: .9
    }
}

@media (min-width: 1200px) {
    .op-xl-0 {
        opacity: 0
    }

    .op-xl-1 {
        opacity: .1
    }

    .op-xl-2 {
        opacity: .2
    }

    .op-xl-3 {
        opacity: .3
    }

    .op-xl-4 {
        opacity: .4
    }

    .op-xl-5 {
        opacity: .5
    }

    .op-xl-6 {
        opacity: .6
    }

    .op-xl-7 {
        opacity: .7
    }

    .op-xl-8 {
        opacity: .8
    }

    .op-xl-9 {
        opacity: .9
    }
}

.pd-0 {
    padding: 0px
}

.pd-0-f {
    padding: 0px !important
}

.pd-y-0 {
    padding-top: 0px;
    padding-bottom: 0px
}

.pd-y-0-f {
    padding-top: 0px !important;
    padding-bottom: 0px !important
}

.pd-x-0 {
    padding-left: 0px;
    padding-right: 0px
}

.pd-x-0-f {
    padding-left: 0px !important;
    padding-right: 0px !important
}

.pd-t-0 {
    padding-top: 0px
}

.pd-r-0 {
    padding-right: 0px
}

.pd-b-0 {
    padding-bottom: 0px
}

.pd-l-0 {
    padding-left: 0px
}

.pd-t-0-f {
    padding-top: 0px !important
}

.pd-r-0-f {
    padding-right: 0px !important
}

.pd-b-0-f {
    padding-bottom: 0px !important
}

.pd-l-0-f {
    padding-left: 0px !important
}

.pd-1 {
    padding: 1px
}

.pd-1-f {
    padding: 1px !important
}

.pd-y-1 {
    padding-top: 1px;
    padding-bottom: 1px
}

.pd-y-1-f {
    padding-top: 1px !important;
    padding-bottom: 1px !important
}

.pd-x-1 {
    padding-left: 1px;
    padding-right: 1px
}

.pd-x-1-f {
    padding-left: 1px !important;
    padding-right: 1px !important
}

.pd-t-1 {
    padding-top: 1px
}

.pd-r-1 {
    padding-right: 1px
}

.pd-b-1 {
    padding-bottom: 1px
}

.pd-l-1 {
    padding-left: 1px
}

.pd-t-1-f {
    padding-top: 1px !important
}

.pd-r-1-f {
    padding-right: 1px !important
}

.pd-b-1-f {
    padding-bottom: 1px !important
}

.pd-l-1-f {
    padding-left: 1px !important
}

.pd-2 {
    padding: 2px
}

.pd-2-f {
    padding: 2px !important
}

.pd-y-2 {
    padding-top: 2px;
    padding-bottom: 2px
}

.pd-y-2-f {
    padding-top: 2px !important;
    padding-bottom: 2px !important
}

.pd-x-2 {
    padding-left: 2px;
    padding-right: 2px
}

.pd-x-2-f {
    padding-left: 2px !important;
    padding-right: 2px !important
}

.pd-t-2 {
    padding-top: 2px
}

.pd-r-2 {
    padding-right: 2px
}

.pd-b-2 {
    padding-bottom: 2px
}

.pd-l-2 {
    padding-left: 2px
}

.pd-t-2-f {
    padding-top: 2px !important
}

.pd-r-2-f {
    padding-right: 2px !important
}

.pd-b-2-f {
    padding-bottom: 2px !important
}

.pd-l-2-f {
    padding-left: 2px !important
}

.pd-3 {
    padding: 3px
}

.pd-3-f {
    padding: 3px !important
}

.pd-y-3 {
    padding-top: 3px;
    padding-bottom: 3px
}

.pd-y-3-f {
    padding-top: 3px !important;
    padding-bottom: 3px !important
}

.pd-x-3 {
    padding-left: 3px;
    padding-right: 3px
}

.pd-x-3-f {
    padding-left: 3px !important;
    padding-right: 3px !important
}

.pd-t-3 {
    padding-top: 3px
}

.pd-r-3 {
    padding-right: 3px
}

.pd-b-3 {
    padding-bottom: 3px
}

.pd-l-3 {
    padding-left: 3px
}

.pd-t-3-f {
    padding-top: 3px !important
}

.pd-r-3-f {
    padding-right: 3px !important
}

.pd-b-3-f {
    padding-bottom: 3px !important
}

.pd-l-3-f {
    padding-left: 3px !important
}

.pd-4 {
    padding: 4px
}

.pd-4-f {
    padding: 4px !important
}

.pd-y-4 {
    padding-top: 4px;
    padding-bottom: 4px
}

.pd-y-4-f {
    padding-top: 4px !important;
    padding-bottom: 4px !important
}

.pd-x-4 {
    padding-left: 4px;
    padding-right: 4px
}

.pd-x-4-f {
    padding-left: 4px !important;
    padding-right: 4px !important
}

.pd-t-4 {
    padding-top: 4px
}

.pd-r-4 {
    padding-right: 4px
}

.pd-b-4 {
    padding-bottom: 4px
}

.pd-l-4 {
    padding-left: 4px
}

.pd-t-4-f {
    padding-top: 4px !important
}

.pd-r-4-f {
    padding-right: 4px !important
}

.pd-b-4-f {
    padding-bottom: 4px !important
}

.pd-l-4-f {
    padding-left: 4px !important
}

.pd-5 {
    padding: 5px
}

.pd-5-f {
    padding: 5px !important
}

.pd-y-5 {
    padding-top: 5px;
    padding-bottom: 5px
}

.pd-y-5-f {
    padding-top: 5px !important;
    padding-bottom: 5px !important
}

.pd-x-5 {
    padding-left: 5px;
    padding-right: 5px
}

.pd-x-5-f {
    padding-left: 5px !important;
    padding-right: 5px !important
}

.pd-t-5 {
    padding-top: 5px
}

.pd-r-5 {
    padding-right: 5px
}

.pd-b-5 {
    padding-bottom: 5px
}

.pd-l-5 {
    padding-left: 5px
}

.pd-t-5-f {
    padding-top: 5px !important
}

.pd-r-5-f {
    padding-right: 5px !important
}

.pd-b-5-f {
    padding-bottom: 5px !important
}

.pd-l-5-f {
    padding-left: 5px !important
}

.pd-6 {
    padding: 6px
}

.pd-6-f {
    padding: 6px !important
}

.pd-y-6 {
    padding-top: 6px;
    padding-bottom: 6px
}

.pd-y-6-f {
    padding-top: 6px !important;
    padding-bottom: 6px !important
}

.pd-x-6 {
    padding-left: 6px;
    padding-right: 6px
}

.pd-x-6-f {
    padding-left: 6px !important;
    padding-right: 6px !important
}

.pd-t-6 {
    padding-top: 6px
}

.pd-r-6 {
    padding-right: 6px
}

.pd-b-6 {
    padding-bottom: 6px
}

.pd-l-6 {
    padding-left: 6px
}

.pd-t-6-f {
    padding-top: 6px !important
}

.pd-r-6-f {
    padding-right: 6px !important
}

.pd-b-6-f {
    padding-bottom: 6px !important
}

.pd-l-6-f {
    padding-left: 6px !important
}

.pd-7 {
    padding: 7px
}

.pd-7-f {
    padding: 7px !important
}

.pd-y-7 {
    padding-top: 7px;
    padding-bottom: 7px
}

.pd-y-7-f {
    padding-top: 7px !important;
    padding-bottom: 7px !important
}

.pd-x-7 {
    padding-left: 7px;
    padding-right: 7px
}

.pd-x-7-f {
    padding-left: 7px !important;
    padding-right: 7px !important
}

.pd-t-7 {
    padding-top: 7px
}

.pd-r-7 {
    padding-right: 7px
}

.pd-b-7 {
    padding-bottom: 7px
}

.pd-l-7 {
    padding-left: 7px
}

.pd-t-7-f {
    padding-top: 7px !important
}

.pd-r-7-f {
    padding-right: 7px !important
}

.pd-b-7-f {
    padding-bottom: 7px !important
}

.pd-l-7-f {
    padding-left: 7px !important
}

.pd-8 {
    padding: 8px
}

.pd-8-f {
    padding: 8px !important
}

.pd-y-8 {
    padding-top: 8px;
    padding-bottom: 8px
}

.pd-y-8-f {
    padding-top: 8px !important;
    padding-bottom: 8px !important
}

.pd-x-8 {
    padding-left: 8px;
    padding-right: 8px
}

.pd-x-8-f {
    padding-left: 8px !important;
    padding-right: 8px !important
}

.pd-t-8 {
    padding-top: 8px
}

.pd-r-8 {
    padding-right: 8px
}

.pd-b-8 {
    padding-bottom: 8px
}

.pd-l-8 {
    padding-left: 8px
}

.pd-t-8-f {
    padding-top: 8px !important
}

.pd-r-8-f {
    padding-right: 8px !important
}

.pd-b-8-f {
    padding-bottom: 8px !important
}

.pd-l-8-f {
    padding-left: 8px !important
}

.pd-9 {
    padding: 9px
}

.pd-9-f {
    padding: 9px !important
}

.pd-y-9 {
    padding-top: 9px;
    padding-bottom: 9px
}

.pd-y-9-f {
    padding-top: 9px !important;
    padding-bottom: 9px !important
}

.pd-x-9 {
    padding-left: 9px;
    padding-right: 9px
}

.pd-x-9-f {
    padding-left: 9px !important;
    padding-right: 9px !important
}

.pd-t-9 {
    padding-top: 9px
}

.pd-r-9 {
    padding-right: 9px
}

.pd-b-9 {
    padding-bottom: 9px
}

.pd-l-9 {
    padding-left: 9px
}

.pd-t-9-f {
    padding-top: 9px !important
}

.pd-r-9-f {
    padding-right: 9px !important
}

.pd-b-9-f {
    padding-bottom: 9px !important
}

.pd-l-9-f {
    padding-left: 9px !important
}

.pd-10 {
    padding: 10px
}

.pd-10-f {
    padding: 10px !important
}

.pd-y-10 {
    padding-top: 10px;
    padding-bottom: 10px
}

.pd-y-10-f {
    padding-top: 10px !important;
    padding-bottom: 10px !important
}

.pd-x-10 {
    padding-left: 10px;
    padding-right: 10px
}

.pd-x-10-f {
    padding-left: 10px !important;
    padding-right: 10px !important
}

.pd-t-10 {
    padding-top: 10px
}

.pd-r-10 {
    padding-right: 10px
}

.pd-b-10 {
    padding-bottom: 10px
}

.pd-l-10 {
    padding-left: 10px
}

.pd-t-10-f {
    padding-top: 10px !important
}

.pd-r-10-f {
    padding-right: 10px !important
}

.pd-b-10-f {
    padding-bottom: 10px !important
}

.pd-l-10-f {
    padding-left: 10px !important
}

.pd-15 {
    padding: 15px
}

.pd-15-f {
    padding: 15px !important
}

.pd-y-15 {
    padding-top: 15px;
    padding-bottom: 15px
}

.pd-y-15-f {
    padding-top: 15px !important;
    padding-bottom: 15px !important
}

.pd-x-15 {
    padding-left: 15px;
    padding-right: 15px
}

.pd-x-15-f {
    padding-left: 15px !important;
    padding-right: 15px !important
}

.pd-t-15 {
    padding-top: 15px
}

.pd-r-15 {
    padding-right: 15px
}

.pd-b-15 {
    padding-bottom: 15px
}

.pd-l-15 {
    padding-left: 15px
}

.pd-t-15-f {
    padding-top: 15px !important
}

.pd-r-15-f {
    padding-right: 15px !important
}

.pd-b-15-f {
    padding-bottom: 15px !important
}

.pd-l-15-f {
    padding-left: 15px !important
}

.pd-20 {
    padding: 20px
}

.pd-20-f {
    padding: 20px !important
}

.pd-y-20 {
    padding-top: 20px;
    padding-bottom: 20px
}

.pd-y-20-f {
    padding-top: 20px !important;
    padding-bottom: 20px !important
}

.pd-x-20 {
    padding-left: 20px;
    padding-right: 20px
}

.pd-x-20-f {
    padding-left: 20px !important;
    padding-right: 20px !important
}

.pd-t-20 {
    padding-top: 20px
}

.pd-r-20 {
    padding-right: 20px
}

.pd-b-20 {
    padding-bottom: 20px
}

.pd-l-20 {
    padding-left: 20px
}

.pd-t-20-f {
    padding-top: 20px !important
}

.pd-r-20-f {
    padding-right: 20px !important
}

.pd-b-20-f {
    padding-bottom: 20px !important
}

.pd-l-20-f {
    padding-left: 20px !important
}

.pd-25 {
    padding: 25px
}

.pd-25-f {
    padding: 25px !important
}

.pd-y-25 {
    padding-top: 25px;
    padding-bottom: 25px
}

.pd-y-25-f {
    padding-top: 25px !important;
    padding-bottom: 25px !important
}

.pd-x-25 {
    padding-left: 25px;
    padding-right: 25px
}

.pd-x-25-f {
    padding-left: 25px !important;
    padding-right: 25px !important
}

.pd-t-25 {
    padding-top: 25px
}

.pd-r-25 {
    padding-right: 25px
}

.pd-b-25 {
    padding-bottom: 25px
}

.pd-l-25 {
    padding-left: 25px
}

.pd-t-25-f {
    padding-top: 25px !important
}

.pd-r-25-f {
    padding-right: 25px !important
}

.pd-b-25-f {
    padding-bottom: 25px !important
}

.pd-l-25-f {
    padding-left: 25px !important
}

.pd-30 {
    padding: 30px
}

.pd-30-f {
    padding: 30px !important
}

.pd-y-30 {
    padding-top: 30px;
    padding-bottom: 30px
}

.pd-y-30-f {
    padding-top: 30px !important;
    padding-bottom: 30px !important
}

.pd-x-30 {
    padding-left: 30px;
    padding-right: 30px
}

.pd-x-30-f {
    padding-left: 30px !important;
    padding-right: 30px !important
}

.pd-t-30 {
    padding-top: 30px
}

.pd-r-30 {
    padding-right: 30px
}

.pd-b-30 {
    padding-bottom: 30px
}

.pd-l-30 {
    padding-left: 30px
}

.pd-t-30-f {
    padding-top: 30px !important
}

.pd-r-30-f {
    padding-right: 30px !important
}

.pd-b-30-f {
    padding-bottom: 30px !important
}

.pd-l-30-f {
    padding-left: 30px !important
}

.pd-35 {
    padding: 35px
}

.pd-35-f {
    padding: 35px !important
}

.pd-y-35 {
    padding-top: 35px;
    padding-bottom: 35px
}

.pd-y-35-f {
    padding-top: 35px !important;
    padding-bottom: 35px !important
}

.pd-x-35 {
    padding-left: 35px;
    padding-right: 35px
}

.pd-x-35-f {
    padding-left: 35px !important;
    padding-right: 35px !important
}

.pd-t-35 {
    padding-top: 35px
}

.pd-r-35 {
    padding-right: 35px
}

.pd-b-35 {
    padding-bottom: 35px
}

.pd-l-35 {
    padding-left: 35px
}

.pd-t-35-f {
    padding-top: 35px !important
}

.pd-r-35-f {
    padding-right: 35px !important
}

.pd-b-35-f {
    padding-bottom: 35px !important
}

.pd-l-35-f {
    padding-left: 35px !important
}

.pd-40 {
    padding: 40px
}

.pd-40-f {
    padding: 40px !important
}

.pd-y-40 {
    padding-top: 40px;
    padding-bottom: 40px
}

.pd-y-40-f {
    padding-top: 40px !important;
    padding-bottom: 40px !important
}

.pd-x-40 {
    padding-left: 40px;
    padding-right: 40px
}

.pd-x-40-f {
    padding-left: 40px !important;
    padding-right: 40px !important
}

.pd-t-40 {
    padding-top: 40px
}

.pd-r-40 {
    padding-right: 40px
}

.pd-b-40 {
    padding-bottom: 40px
}

.pd-l-40 {
    padding-left: 40px
}

.pd-t-40-f {
    padding-top: 40px !important
}

.pd-r-40-f {
    padding-right: 40px !important
}

.pd-b-40-f {
    padding-bottom: 40px !important
}

.pd-l-40-f {
    padding-left: 40px !important
}

.pd-45 {
    padding: 45px
}

.pd-45-f {
    padding: 45px !important
}

.pd-y-45 {
    padding-top: 45px;
    padding-bottom: 45px
}

.pd-y-45-f {
    padding-top: 45px !important;
    padding-bottom: 45px !important
}

.pd-x-45 {
    padding-left: 45px;
    padding-right: 45px
}

.pd-x-45-f {
    padding-left: 45px !important;
    padding-right: 45px !important
}

.pd-t-45 {
    padding-top: 45px
}

.pd-r-45 {
    padding-right: 45px
}

.pd-b-45 {
    padding-bottom: 45px
}

.pd-l-45 {
    padding-left: 45px
}

.pd-t-45-f {
    padding-top: 45px !important
}

.pd-r-45-f {
    padding-right: 45px !important
}

.pd-b-45-f {
    padding-bottom: 45px !important
}

.pd-l-45-f {
    padding-left: 45px !important
}

.pd-50 {
    padding: 50px
}

.pd-50-f {
    padding: 50px !important
}

.pd-y-50 {
    padding-top: 50px;
    padding-bottom: 50px
}

.pd-y-50-f {
    padding-top: 50px !important;
    padding-bottom: 50px !important
}

.pd-x-50 {
    padding-left: 50px;
    padding-right: 50px
}

.pd-x-50-f {
    padding-left: 50px !important;
    padding-right: 50px !important
}

.pd-t-50 {
    padding-top: 50px
}

.pd-r-50 {
    padding-right: 50px
}

.pd-b-50 {
    padding-bottom: 50px
}

.pd-l-50 {
    padding-left: 50px
}

.pd-t-50-f {
    padding-top: 50px !important
}

.pd-r-50-f {
    padding-right: 50px !important
}

.pd-b-50-f {
    padding-bottom: 50px !important
}

.pd-l-50-f {
    padding-left: 50px !important
}

.pd-55 {
    padding: 55px
}

.pd-55-f {
    padding: 55px !important
}

.pd-y-55 {
    padding-top: 55px;
    padding-bottom: 55px
}

.pd-y-55-f {
    padding-top: 55px !important;
    padding-bottom: 55px !important
}

.pd-x-55 {
    padding-left: 55px;
    padding-right: 55px
}

.pd-x-55-f {
    padding-left: 55px !important;
    padding-right: 55px !important
}

.pd-t-55 {
    padding-top: 55px
}

.pd-r-55 {
    padding-right: 55px
}

.pd-b-55 {
    padding-bottom: 55px
}

.pd-l-55 {
    padding-left: 55px
}

.pd-t-55-f {
    padding-top: 55px !important
}

.pd-r-55-f {
    padding-right: 55px !important
}

.pd-b-55-f {
    padding-bottom: 55px !important
}

.pd-l-55-f {
    padding-left: 55px !important
}

.pd-60 {
    padding: 60px
}

.pd-60-f {
    padding: 60px !important
}

.pd-y-60 {
    padding-top: 60px;
    padding-bottom: 60px
}

.pd-y-60-f {
    padding-top: 60px !important;
    padding-bottom: 60px !important
}

.pd-x-60 {
    padding-left: 60px;
    padding-right: 60px
}

.pd-x-60-f {
    padding-left: 60px !important;
    padding-right: 60px !important
}

.pd-t-60 {
    padding-top: 60px
}

.pd-r-60 {
    padding-right: 60px
}

.pd-b-60 {
    padding-bottom: 60px
}

.pd-l-60 {
    padding-left: 60px
}

.pd-t-60-f {
    padding-top: 60px !important
}

.pd-r-60-f {
    padding-right: 60px !important
}

.pd-b-60-f {
    padding-bottom: 60px !important
}

.pd-l-60-f {
    padding-left: 60px !important
}

.pd-65 {
    padding: 65px
}

.pd-65-f {
    padding: 65px !important
}

.pd-y-65 {
    padding-top: 65px;
    padding-bottom: 65px
}

.pd-y-65-f {
    padding-top: 65px !important;
    padding-bottom: 65px !important
}

.pd-x-65 {
    padding-left: 65px;
    padding-right: 65px
}

.pd-x-65-f {
    padding-left: 65px !important;
    padding-right: 65px !important
}

.pd-t-65 {
    padding-top: 65px
}

.pd-r-65 {
    padding-right: 65px
}

.pd-b-65 {
    padding-bottom: 65px
}

.pd-l-65 {
    padding-left: 65px
}

.pd-t-65-f {
    padding-top: 65px !important
}

.pd-r-65-f {
    padding-right: 65px !important
}

.pd-b-65-f {
    padding-bottom: 65px !important
}

.pd-l-65-f {
    padding-left: 65px !important
}

.pd-70 {
    padding: 70px
}

.pd-70-f {
    padding: 70px !important
}

.pd-y-70 {
    padding-top: 70px;
    padding-bottom: 70px
}

.pd-y-70-f {
    padding-top: 70px !important;
    padding-bottom: 70px !important
}

.pd-x-70 {
    padding-left: 70px;
    padding-right: 70px
}

.pd-x-70-f {
    padding-left: 70px !important;
    padding-right: 70px !important
}

.pd-t-70 {
    padding-top: 70px
}

.pd-r-70 {
    padding-right: 70px
}

.pd-b-70 {
    padding-bottom: 70px
}

.pd-l-70 {
    padding-left: 70px
}

.pd-t-70-f {
    padding-top: 70px !important
}

.pd-r-70-f {
    padding-right: 70px !important
}

.pd-b-70-f {
    padding-bottom: 70px !important
}

.pd-l-70-f {
    padding-left: 70px !important
}

.pd-75 {
    padding: 75px
}

.pd-75-f {
    padding: 75px !important
}

.pd-y-75 {
    padding-top: 75px;
    padding-bottom: 75px
}

.pd-y-75-f {
    padding-top: 75px !important;
    padding-bottom: 75px !important
}

.pd-x-75 {
    padding-left: 75px;
    padding-right: 75px
}

.pd-x-75-f {
    padding-left: 75px !important;
    padding-right: 75px !important
}

.pd-t-75 {
    padding-top: 75px
}

.pd-r-75 {
    padding-right: 75px
}

.pd-b-75 {
    padding-bottom: 75px
}

.pd-l-75 {
    padding-left: 75px
}

.pd-t-75-f {
    padding-top: 75px !important
}

.pd-r-75-f {
    padding-right: 75px !important
}

.pd-b-75-f {
    padding-bottom: 75px !important
}

.pd-l-75-f {
    padding-left: 75px !important
}

.pd-80 {
    padding: 80px
}

.pd-80-f {
    padding: 80px !important
}

.pd-y-80 {
    padding-top: 80px;
    padding-bottom: 80px
}

.pd-y-80-f {
    padding-top: 80px !important;
    padding-bottom: 80px !important
}

.pd-x-80 {
    padding-left: 80px;
    padding-right: 80px
}

.pd-x-80-f {
    padding-left: 80px !important;
    padding-right: 80px !important
}

.pd-t-80 {
    padding-top: 80px
}

.pd-r-80 {
    padding-right: 80px
}

.pd-b-80 {
    padding-bottom: 80px
}

.pd-l-80 {
    padding-left: 80px
}

.pd-t-80-f {
    padding-top: 80px !important
}

.pd-r-80-f {
    padding-right: 80px !important
}

.pd-b-80-f {
    padding-bottom: 80px !important
}

.pd-l-80-f {
    padding-left: 80px !important
}

.pd-85 {
    padding: 85px
}

.pd-85-f {
    padding: 85px !important
}

.pd-y-85 {
    padding-top: 85px;
    padding-bottom: 85px
}

.pd-y-85-f {
    padding-top: 85px !important;
    padding-bottom: 85px !important
}

.pd-x-85 {
    padding-left: 85px;
    padding-right: 85px
}

.pd-x-85-f {
    padding-left: 85px !important;
    padding-right: 85px !important
}

.pd-t-85 {
    padding-top: 85px
}

.pd-r-85 {
    padding-right: 85px
}

.pd-b-85 {
    padding-bottom: 85px
}

.pd-l-85 {
    padding-left: 85px
}

.pd-t-85-f {
    padding-top: 85px !important
}

.pd-r-85-f {
    padding-right: 85px !important
}

.pd-b-85-f {
    padding-bottom: 85px !important
}

.pd-l-85-f {
    padding-left: 85px !important
}

.pd-90 {
    padding: 90px
}

.pd-90-f {
    padding: 90px !important
}

.pd-y-90 {
    padding-top: 90px;
    padding-bottom: 90px
}

.pd-y-90-f {
    padding-top: 90px !important;
    padding-bottom: 90px !important
}

.pd-x-90 {
    padding-left: 90px;
    padding-right: 90px
}

.pd-x-90-f {
    padding-left: 90px !important;
    padding-right: 90px !important
}

.pd-t-90 {
    padding-top: 90px
}

.pd-r-90 {
    padding-right: 90px
}

.pd-b-90 {
    padding-bottom: 90px
}

.pd-l-90 {
    padding-left: 90px
}

.pd-t-90-f {
    padding-top: 90px !important
}

.pd-r-90-f {
    padding-right: 90px !important
}

.pd-b-90-f {
    padding-bottom: 90px !important
}

.pd-l-90-f {
    padding-left: 90px !important
}

.pd-95 {
    padding: 95px
}

.pd-95-f {
    padding: 95px !important
}

.pd-y-95 {
    padding-top: 95px;
    padding-bottom: 95px
}

.pd-y-95-f {
    padding-top: 95px !important;
    padding-bottom: 95px !important
}

.pd-x-95 {
    padding-left: 95px;
    padding-right: 95px
}

.pd-x-95-f {
    padding-left: 95px !important;
    padding-right: 95px !important
}

.pd-t-95 {
    padding-top: 95px
}

.pd-r-95 {
    padding-right: 95px
}

.pd-b-95 {
    padding-bottom: 95px
}

.pd-l-95 {
    padding-left: 95px
}

.pd-t-95-f {
    padding-top: 95px !important
}

.pd-r-95-f {
    padding-right: 95px !important
}

.pd-b-95-f {
    padding-bottom: 95px !important
}

.pd-l-95-f {
    padding-left: 95px !important
}

.pd-100 {
    padding: 100px
}

.pd-100-f {
    padding: 100px !important
}

.pd-y-100 {
    padding-top: 100px;
    padding-bottom: 100px
}

.pd-y-100-f {
    padding-top: 100px !important;
    padding-bottom: 100px !important
}

.pd-x-100 {
    padding-left: 100px;
    padding-right: 100px
}

.pd-x-100-f {
    padding-left: 100px !important;
    padding-right: 100px !important
}

.pd-t-100 {
    padding-top: 100px
}

.pd-r-100 {
    padding-right: 100px
}

.pd-b-100 {
    padding-bottom: 100px
}

.pd-l-100 {
    padding-left: 100px
}

.pd-t-100-f {
    padding-top: 100px !important
}

.pd-r-100-f {
    padding-right: 100px !important
}

.pd-b-100-f {
    padding-bottom: 100px !important
}

.pd-l-100-f {
    padding-left: 100px !important
}

.pd-110 {
    padding: 110px
}

.pd-110-f {
    padding: 110px !important
}

.pd-y-110 {
    padding-top: 110px;
    padding-bottom: 110px
}

.pd-y-110-f {
    padding-top: 110px !important;
    padding-bottom: 110px !important
}

.pd-x-110 {
    padding-left: 110px;
    padding-right: 110px
}

.pd-x-110-f {
    padding-left: 110px !important;
    padding-right: 110px !important
}

.pd-t-110 {
    padding-top: 110px
}

.pd-r-110 {
    padding-right: 110px
}

.pd-b-110 {
    padding-bottom: 110px
}

.pd-l-110 {
    padding-left: 110px
}

.pd-t-110-f {
    padding-top: 110px !important
}

.pd-r-110-f {
    padding-right: 110px !important
}

.pd-b-110-f {
    padding-bottom: 110px !important
}

.pd-l-110-f {
    padding-left: 110px !important
}

.pd-120 {
    padding: 120px
}

.pd-120-f {
    padding: 120px !important
}

.pd-y-120 {
    padding-top: 120px;
    padding-bottom: 120px
}

.pd-y-120-f {
    padding-top: 120px !important;
    padding-bottom: 120px !important
}

.pd-x-120 {
    padding-left: 120px;
    padding-right: 120px
}

.pd-x-120-f {
    padding-left: 120px !important;
    padding-right: 120px !important
}

.pd-t-120 {
    padding-top: 120px
}

.pd-r-120 {
    padding-right: 120px
}

.pd-b-120 {
    padding-bottom: 120px
}

.pd-l-120 {
    padding-left: 120px
}

.pd-t-120-f {
    padding-top: 120px !important
}

.pd-r-120-f {
    padding-right: 120px !important
}

.pd-b-120-f {
    padding-bottom: 120px !important
}

.pd-l-120-f {
    padding-left: 120px !important
}

.pd-130 {
    padding: 130px
}

.pd-130-f {
    padding: 130px !important
}

.pd-y-130 {
    padding-top: 130px;
    padding-bottom: 130px
}

.pd-y-130-f {
    padding-top: 130px !important;
    padding-bottom: 130px !important
}

.pd-x-130 {
    padding-left: 130px;
    padding-right: 130px
}

.pd-x-130-f {
    padding-left: 130px !important;
    padding-right: 130px !important
}

.pd-t-130 {
    padding-top: 130px
}

.pd-r-130 {
    padding-right: 130px
}

.pd-b-130 {
    padding-bottom: 130px
}

.pd-l-130 {
    padding-left: 130px
}

.pd-t-130-f {
    padding-top: 130px !important
}

.pd-r-130-f {
    padding-right: 130px !important
}

.pd-b-130-f {
    padding-bottom: 130px !important
}

.pd-l-130-f {
    padding-left: 130px !important
}

.pd-140 {
    padding: 140px
}

.pd-140-f {
    padding: 140px !important
}

.pd-y-140 {
    padding-top: 140px;
    padding-bottom: 140px
}

.pd-y-140-f {
    padding-top: 140px !important;
    padding-bottom: 140px !important
}

.pd-x-140 {
    padding-left: 140px;
    padding-right: 140px
}

.pd-x-140-f {
    padding-left: 140px !important;
    padding-right: 140px !important
}

.pd-t-140 {
    padding-top: 140px
}

.pd-r-140 {
    padding-right: 140px
}

.pd-b-140 {
    padding-bottom: 140px
}

.pd-l-140 {
    padding-left: 140px
}

.pd-t-140-f {
    padding-top: 140px !important
}

.pd-r-140-f {
    padding-right: 140px !important
}

.pd-b-140-f {
    padding-bottom: 140px !important
}

.pd-l-140-f {
    padding-left: 140px !important
}

.pd-150 {
    padding: 150px
}

.pd-150-f {
    padding: 150px !important
}

.pd-y-150 {
    padding-top: 150px;
    padding-bottom: 150px
}

.pd-y-150-f {
    padding-top: 150px !important;
    padding-bottom: 150px !important
}

.pd-x-150 {
    padding-left: 150px;
    padding-right: 150px
}

.pd-x-150-f {
    padding-left: 150px !important;
    padding-right: 150px !important
}

.pd-t-150 {
    padding-top: 150px
}

.pd-r-150 {
    padding-right: 150px
}

.pd-b-150 {
    padding-bottom: 150px
}

.pd-l-150 {
    padding-left: 150px
}

.pd-t-150-f {
    padding-top: 150px !important
}

.pd-r-150-f {
    padding-right: 150px !important
}

.pd-b-150-f {
    padding-bottom: 150px !important
}

.pd-l-150-f {
    padding-left: 150px !important
}

.pd-160 {
    padding: 160px
}

.pd-160-f {
    padding: 160px !important
}

.pd-y-160 {
    padding-top: 160px;
    padding-bottom: 160px
}

.pd-y-160-f {
    padding-top: 160px !important;
    padding-bottom: 160px !important
}

.pd-x-160 {
    padding-left: 160px;
    padding-right: 160px
}

.pd-x-160-f {
    padding-left: 160px !important;
    padding-right: 160px !important
}

.pd-t-160 {
    padding-top: 160px
}

.pd-r-160 {
    padding-right: 160px
}

.pd-b-160 {
    padding-bottom: 160px
}

.pd-l-160 {
    padding-left: 160px
}

.pd-t-160-f {
    padding-top: 160px !important
}

.pd-r-160-f {
    padding-right: 160px !important
}

.pd-b-160-f {
    padding-bottom: 160px !important
}

.pd-l-160-f {
    padding-left: 160px !important
}

.pd-170 {
    padding: 170px
}

.pd-170-f {
    padding: 170px !important
}

.pd-y-170 {
    padding-top: 170px;
    padding-bottom: 170px
}

.pd-y-170-f {
    padding-top: 170px !important;
    padding-bottom: 170px !important
}

.pd-x-170 {
    padding-left: 170px;
    padding-right: 170px
}

.pd-x-170-f {
    padding-left: 170px !important;
    padding-right: 170px !important
}

.pd-t-170 {
    padding-top: 170px
}

.pd-r-170 {
    padding-right: 170px
}

.pd-b-170 {
    padding-bottom: 170px
}

.pd-l-170 {
    padding-left: 170px
}

.pd-t-170-f {
    padding-top: 170px !important
}

.pd-r-170-f {
    padding-right: 170px !important
}

.pd-b-170-f {
    padding-bottom: 170px !important
}

.pd-l-170-f {
    padding-left: 170px !important
}

.pd-180 {
    padding: 180px
}

.pd-180-f {
    padding: 180px !important
}

.pd-y-180 {
    padding-top: 180px;
    padding-bottom: 180px
}

.pd-y-180-f {
    padding-top: 180px !important;
    padding-bottom: 180px !important
}

.pd-x-180 {
    padding-left: 180px;
    padding-right: 180px
}

.pd-x-180-f {
    padding-left: 180px !important;
    padding-right: 180px !important
}

.pd-t-180 {
    padding-top: 180px
}

.pd-r-180 {
    padding-right: 180px
}

.pd-b-180 {
    padding-bottom: 180px
}

.pd-l-180 {
    padding-left: 180px
}

.pd-t-180-f {
    padding-top: 180px !important
}

.pd-r-180-f {
    padding-right: 180px !important
}

.pd-b-180-f {
    padding-bottom: 180px !important
}

.pd-l-180-f {
    padding-left: 180px !important
}

.pd-190 {
    padding: 190px
}

.pd-190-f {
    padding: 190px !important
}

.pd-y-190 {
    padding-top: 190px;
    padding-bottom: 190px
}

.pd-y-190-f {
    padding-top: 190px !important;
    padding-bottom: 190px !important
}

.pd-x-190 {
    padding-left: 190px;
    padding-right: 190px
}

.pd-x-190-f {
    padding-left: 190px !important;
    padding-right: 190px !important
}

.pd-t-190 {
    padding-top: 190px
}

.pd-r-190 {
    padding-right: 190px
}

.pd-b-190 {
    padding-bottom: 190px
}

.pd-l-190 {
    padding-left: 190px
}

.pd-t-190-f {
    padding-top: 190px !important
}

.pd-r-190-f {
    padding-right: 190px !important
}

.pd-b-190-f {
    padding-bottom: 190px !important
}

.pd-l-190-f {
    padding-left: 190px !important
}

.pd-200 {
    padding: 200px
}

.pd-200-f {
    padding: 200px !important
}

.pd-y-200 {
    padding-top: 200px;
    padding-bottom: 200px
}

.pd-y-200-f {
    padding-top: 200px !important;
    padding-bottom: 200px !important
}

.pd-x-200 {
    padding-left: 200px;
    padding-right: 200px
}

.pd-x-200-f {
    padding-left: 200px !important;
    padding-right: 200px !important
}

.pd-t-200 {
    padding-top: 200px
}

.pd-r-200 {
    padding-right: 200px
}

.pd-b-200 {
    padding-bottom: 200px
}

.pd-l-200 {
    padding-left: 200px
}

.pd-t-200-f {
    padding-top: 200px !important
}

.pd-r-200-f {
    padding-right: 200px !important
}

.pd-b-200-f {
    padding-bottom: 200px !important
}

.pd-l-200-f {
    padding-left: 200px !important
}

@media (min-width: 480px) {
    .pd-xs-0 {
        padding: 0px
    }

    .pd-xs-0-f {
        padding: 0px !important
    }

    .pd-xs-y-0 {
        padding-top: 0px;
        padding-bottom: 0px
    }

    .pd-xs-y-0-f {
        padding-top: 0px !important;
        padding-bottom: 0px !important
    }

    .pd-xs-x-0 {
        padding-left: 0px;
        padding-right: 0px
    }

    .pd-xs-x-0-f {
        padding-left: 0px !important;
        padding-right: 0px !important
    }

    .pd-xs-t-0 {
        padding-top: 0px
    }

    .pd-xs-r-0 {
        padding-right: 0px
    }

    .pd-xs-b-0 {
        padding-bottom: 0px
    }

    .pd-xs-l-0 {
        padding-left: 0px
    }

    .pd-xs-t-0-f {
        padding-top: 0px !important
    }

    .pd-xs-r-0-f {
        padding-right: 0px !important
    }

    .pd-xs-b-0-f {
        padding-bottom: 0px !important
    }

    .pd-xs-l-0-f {
        padding-left: 0px !important
    }

    .pd-xs-1 {
        padding: 1px
    }

    .pd-xs-1-f {
        padding: 1px !important
    }

    .pd-xs-y-1 {
        padding-top: 1px;
        padding-bottom: 1px
    }

    .pd-xs-y-1-f {
        padding-top: 1px !important;
        padding-bottom: 1px !important
    }

    .pd-xs-x-1 {
        padding-left: 1px;
        padding-right: 1px
    }

    .pd-xs-x-1-f {
        padding-left: 1px !important;
        padding-right: 1px !important
    }

    .pd-xs-t-1 {
        padding-top: 1px
    }

    .pd-xs-r-1 {
        padding-right: 1px
    }

    .pd-xs-b-1 {
        padding-bottom: 1px
    }

    .pd-xs-l-1 {
        padding-left: 1px
    }

    .pd-xs-t-1-f {
        padding-top: 1px !important
    }

    .pd-xs-r-1-f {
        padding-right: 1px !important
    }

    .pd-xs-b-1-f {
        padding-bottom: 1px !important
    }

    .pd-xs-l-1-f {
        padding-left: 1px !important
    }

    .pd-xs-2 {
        padding: 2px
    }

    .pd-xs-2-f {
        padding: 2px !important
    }

    .pd-xs-y-2 {
        padding-top: 2px;
        padding-bottom: 2px
    }

    .pd-xs-y-2-f {
        padding-top: 2px !important;
        padding-bottom: 2px !important
    }

    .pd-xs-x-2 {
        padding-left: 2px;
        padding-right: 2px
    }

    .pd-xs-x-2-f {
        padding-left: 2px !important;
        padding-right: 2px !important
    }

    .pd-xs-t-2 {
        padding-top: 2px
    }

    .pd-xs-r-2 {
        padding-right: 2px
    }

    .pd-xs-b-2 {
        padding-bottom: 2px
    }

    .pd-xs-l-2 {
        padding-left: 2px
    }

    .pd-xs-t-2-f {
        padding-top: 2px !important
    }

    .pd-xs-r-2-f {
        padding-right: 2px !important
    }

    .pd-xs-b-2-f {
        padding-bottom: 2px !important
    }

    .pd-xs-l-2-f {
        padding-left: 2px !important
    }

    .pd-xs-3 {
        padding: 3px
    }

    .pd-xs-3-f {
        padding: 3px !important
    }

    .pd-xs-y-3 {
        padding-top: 3px;
        padding-bottom: 3px
    }

    .pd-xs-y-3-f {
        padding-top: 3px !important;
        padding-bottom: 3px !important
    }

    .pd-xs-x-3 {
        padding-left: 3px;
        padding-right: 3px
    }

    .pd-xs-x-3-f {
        padding-left: 3px !important;
        padding-right: 3px !important
    }

    .pd-xs-t-3 {
        padding-top: 3px
    }

    .pd-xs-r-3 {
        padding-right: 3px
    }

    .pd-xs-b-3 {
        padding-bottom: 3px
    }

    .pd-xs-l-3 {
        padding-left: 3px
    }

    .pd-xs-t-3-f {
        padding-top: 3px !important
    }

    .pd-xs-r-3-f {
        padding-right: 3px !important
    }

    .pd-xs-b-3-f {
        padding-bottom: 3px !important
    }

    .pd-xs-l-3-f {
        padding-left: 3px !important
    }

    .pd-xs-4 {
        padding: 4px
    }

    .pd-xs-4-f {
        padding: 4px !important
    }

    .pd-xs-y-4 {
        padding-top: 4px;
        padding-bottom: 4px
    }

    .pd-xs-y-4-f {
        padding-top: 4px !important;
        padding-bottom: 4px !important
    }

    .pd-xs-x-4 {
        padding-left: 4px;
        padding-right: 4px
    }

    .pd-xs-x-4-f {
        padding-left: 4px !important;
        padding-right: 4px !important
    }

    .pd-xs-t-4 {
        padding-top: 4px
    }

    .pd-xs-r-4 {
        padding-right: 4px
    }

    .pd-xs-b-4 {
        padding-bottom: 4px
    }

    .pd-xs-l-4 {
        padding-left: 4px
    }

    .pd-xs-t-4-f {
        padding-top: 4px !important
    }

    .pd-xs-r-4-f {
        padding-right: 4px !important
    }

    .pd-xs-b-4-f {
        padding-bottom: 4px !important
    }

    .pd-xs-l-4-f {
        padding-left: 4px !important
    }

    .pd-xs-5 {
        padding: 5px
    }

    .pd-xs-5-f {
        padding: 5px !important
    }

    .pd-xs-y-5 {
        padding-top: 5px;
        padding-bottom: 5px
    }

    .pd-xs-y-5-f {
        padding-top: 5px !important;
        padding-bottom: 5px !important
    }

    .pd-xs-x-5 {
        padding-left: 5px;
        padding-right: 5px
    }

    .pd-xs-x-5-f {
        padding-left: 5px !important;
        padding-right: 5px !important
    }

    .pd-xs-t-5 {
        padding-top: 5px
    }

    .pd-xs-r-5 {
        padding-right: 5px
    }

    .pd-xs-b-5 {
        padding-bottom: 5px
    }

    .pd-xs-l-5 {
        padding-left: 5px
    }

    .pd-xs-t-5-f {
        padding-top: 5px !important
    }

    .pd-xs-r-5-f {
        padding-right: 5px !important
    }

    .pd-xs-b-5-f {
        padding-bottom: 5px !important
    }

    .pd-xs-l-5-f {
        padding-left: 5px !important
    }

    .pd-xs-6 {
        padding: 6px
    }

    .pd-xs-6-f {
        padding: 6px !important
    }

    .pd-xs-y-6 {
        padding-top: 6px;
        padding-bottom: 6px
    }

    .pd-xs-y-6-f {
        padding-top: 6px !important;
        padding-bottom: 6px !important
    }

    .pd-xs-x-6 {
        padding-left: 6px;
        padding-right: 6px
    }

    .pd-xs-x-6-f {
        padding-left: 6px !important;
        padding-right: 6px !important
    }

    .pd-xs-t-6 {
        padding-top: 6px
    }

    .pd-xs-r-6 {
        padding-right: 6px
    }

    .pd-xs-b-6 {
        padding-bottom: 6px
    }

    .pd-xs-l-6 {
        padding-left: 6px
    }

    .pd-xs-t-6-f {
        padding-top: 6px !important
    }

    .pd-xs-r-6-f {
        padding-right: 6px !important
    }

    .pd-xs-b-6-f {
        padding-bottom: 6px !important
    }

    .pd-xs-l-6-f {
        padding-left: 6px !important
    }

    .pd-xs-7 {
        padding: 7px
    }

    .pd-xs-7-f {
        padding: 7px !important
    }

    .pd-xs-y-7 {
        padding-top: 7px;
        padding-bottom: 7px
    }

    .pd-xs-y-7-f {
        padding-top: 7px !important;
        padding-bottom: 7px !important
    }

    .pd-xs-x-7 {
        padding-left: 7px;
        padding-right: 7px
    }

    .pd-xs-x-7-f {
        padding-left: 7px !important;
        padding-right: 7px !important
    }

    .pd-xs-t-7 {
        padding-top: 7px
    }

    .pd-xs-r-7 {
        padding-right: 7px
    }

    .pd-xs-b-7 {
        padding-bottom: 7px
    }

    .pd-xs-l-7 {
        padding-left: 7px
    }

    .pd-xs-t-7-f {
        padding-top: 7px !important
    }

    .pd-xs-r-7-f {
        padding-right: 7px !important
    }

    .pd-xs-b-7-f {
        padding-bottom: 7px !important
    }

    .pd-xs-l-7-f {
        padding-left: 7px !important
    }

    .pd-xs-8 {
        padding: 8px
    }

    .pd-xs-8-f {
        padding: 8px !important
    }

    .pd-xs-y-8 {
        padding-top: 8px;
        padding-bottom: 8px
    }

    .pd-xs-y-8-f {
        padding-top: 8px !important;
        padding-bottom: 8px !important
    }

    .pd-xs-x-8 {
        padding-left: 8px;
        padding-right: 8px
    }

    .pd-xs-x-8-f {
        padding-left: 8px !important;
        padding-right: 8px !important
    }

    .pd-xs-t-8 {
        padding-top: 8px
    }

    .pd-xs-r-8 {
        padding-right: 8px
    }

    .pd-xs-b-8 {
        padding-bottom: 8px
    }

    .pd-xs-l-8 {
        padding-left: 8px
    }

    .pd-xs-t-8-f {
        padding-top: 8px !important
    }

    .pd-xs-r-8-f {
        padding-right: 8px !important
    }

    .pd-xs-b-8-f {
        padding-bottom: 8px !important
    }

    .pd-xs-l-8-f {
        padding-left: 8px !important
    }

    .pd-xs-9 {
        padding: 9px
    }

    .pd-xs-9-f {
        padding: 9px !important
    }

    .pd-xs-y-9 {
        padding-top: 9px;
        padding-bottom: 9px
    }

    .pd-xs-y-9-f {
        padding-top: 9px !important;
        padding-bottom: 9px !important
    }

    .pd-xs-x-9 {
        padding-left: 9px;
        padding-right: 9px
    }

    .pd-xs-x-9-f {
        padding-left: 9px !important;
        padding-right: 9px !important
    }

    .pd-xs-t-9 {
        padding-top: 9px
    }

    .pd-xs-r-9 {
        padding-right: 9px
    }

    .pd-xs-b-9 {
        padding-bottom: 9px
    }

    .pd-xs-l-9 {
        padding-left: 9px
    }

    .pd-xs-t-9-f {
        padding-top: 9px !important
    }

    .pd-xs-r-9-f {
        padding-right: 9px !important
    }

    .pd-xs-b-9-f {
        padding-bottom: 9px !important
    }

    .pd-xs-l-9-f {
        padding-left: 9px !important
    }

    .pd-xs-10 {
        padding: 10px
    }

    .pd-xs-10-f {
        padding: 10px !important
    }

    .pd-xs-y-10 {
        padding-top: 10px;
        padding-bottom: 10px
    }

    .pd-xs-y-10-f {
        padding-top: 10px !important;
        padding-bottom: 10px !important
    }

    .pd-xs-x-10 {
        padding-left: 10px;
        padding-right: 10px
    }

    .pd-xs-x-10-f {
        padding-left: 10px !important;
        padding-right: 10px !important
    }

    .pd-xs-t-10 {
        padding-top: 10px
    }

    .pd-xs-r-10 {
        padding-right: 10px
    }

    .pd-xs-b-10 {
        padding-bottom: 10px
    }

    .pd-xs-l-10 {
        padding-left: 10px
    }

    .pd-xs-t-10-f {
        padding-top: 10px !important
    }

    .pd-xs-r-10-f {
        padding-right: 10px !important
    }

    .pd-xs-b-10-f {
        padding-bottom: 10px !important
    }

    .pd-xs-l-10-f {
        padding-left: 10px !important
    }

    .pd-xs-15 {
        padding: 15px
    }

    .pd-xs-15-f {
        padding: 15px !important
    }

    .pd-xs-y-15 {
        padding-top: 15px;
        padding-bottom: 15px
    }

    .pd-xs-y-15-f {
        padding-top: 15px !important;
        padding-bottom: 15px !important
    }

    .pd-xs-x-15 {
        padding-left: 15px;
        padding-right: 15px
    }

    .pd-xs-x-15-f {
        padding-left: 15px !important;
        padding-right: 15px !important
    }

    .pd-xs-t-15 {
        padding-top: 15px
    }

    .pd-xs-r-15 {
        padding-right: 15px
    }

    .pd-xs-b-15 {
        padding-bottom: 15px
    }

    .pd-xs-l-15 {
        padding-left: 15px
    }

    .pd-xs-t-15-f {
        padding-top: 15px !important
    }

    .pd-xs-r-15-f {
        padding-right: 15px !important
    }

    .pd-xs-b-15-f {
        padding-bottom: 15px !important
    }

    .pd-xs-l-15-f {
        padding-left: 15px !important
    }

    .pd-xs-20 {
        padding: 20px
    }

    .pd-xs-20-f {
        padding: 20px !important
    }

    .pd-xs-y-20 {
        padding-top: 20px;
        padding-bottom: 20px
    }

    .pd-xs-y-20-f {
        padding-top: 20px !important;
        padding-bottom: 20px !important
    }

    .pd-xs-x-20 {
        padding-left: 20px;
        padding-right: 20px
    }

    .pd-xs-x-20-f {
        padding-left: 20px !important;
        padding-right: 20px !important
    }

    .pd-xs-t-20 {
        padding-top: 20px
    }

    .pd-xs-r-20 {
        padding-right: 20px
    }

    .pd-xs-b-20 {
        padding-bottom: 20px
    }

    .pd-xs-l-20 {
        padding-left: 20px
    }

    .pd-xs-t-20-f {
        padding-top: 20px !important
    }

    .pd-xs-r-20-f {
        padding-right: 20px !important
    }

    .pd-xs-b-20-f {
        padding-bottom: 20px !important
    }

    .pd-xs-l-20-f {
        padding-left: 20px !important
    }

    .pd-xs-25 {
        padding: 25px
    }

    .pd-xs-25-f {
        padding: 25px !important
    }

    .pd-xs-y-25 {
        padding-top: 25px;
        padding-bottom: 25px
    }

    .pd-xs-y-25-f {
        padding-top: 25px !important;
        padding-bottom: 25px !important
    }

    .pd-xs-x-25 {
        padding-left: 25px;
        padding-right: 25px
    }

    .pd-xs-x-25-f {
        padding-left: 25px !important;
        padding-right: 25px !important
    }

    .pd-xs-t-25 {
        padding-top: 25px
    }

    .pd-xs-r-25 {
        padding-right: 25px
    }

    .pd-xs-b-25 {
        padding-bottom: 25px
    }

    .pd-xs-l-25 {
        padding-left: 25px
    }

    .pd-xs-t-25-f {
        padding-top: 25px !important
    }

    .pd-xs-r-25-f {
        padding-right: 25px !important
    }

    .pd-xs-b-25-f {
        padding-bottom: 25px !important
    }

    .pd-xs-l-25-f {
        padding-left: 25px !important
    }

    .pd-xs-30 {
        padding: 30px
    }

    .pd-xs-30-f {
        padding: 30px !important
    }

    .pd-xs-y-30 {
        padding-top: 30px;
        padding-bottom: 30px
    }

    .pd-xs-y-30-f {
        padding-top: 30px !important;
        padding-bottom: 30px !important
    }

    .pd-xs-x-30 {
        padding-left: 30px;
        padding-right: 30px
    }

    .pd-xs-x-30-f {
        padding-left: 30px !important;
        padding-right: 30px !important
    }

    .pd-xs-t-30 {
        padding-top: 30px
    }

    .pd-xs-r-30 {
        padding-right: 30px
    }

    .pd-xs-b-30 {
        padding-bottom: 30px
    }

    .pd-xs-l-30 {
        padding-left: 30px
    }

    .pd-xs-t-30-f {
        padding-top: 30px !important
    }

    .pd-xs-r-30-f {
        padding-right: 30px !important
    }

    .pd-xs-b-30-f {
        padding-bottom: 30px !important
    }

    .pd-xs-l-30-f {
        padding-left: 30px !important
    }

    .pd-xs-35 {
        padding: 35px
    }

    .pd-xs-35-f {
        padding: 35px !important
    }

    .pd-xs-y-35 {
        padding-top: 35px;
        padding-bottom: 35px
    }

    .pd-xs-y-35-f {
        padding-top: 35px !important;
        padding-bottom: 35px !important
    }

    .pd-xs-x-35 {
        padding-left: 35px;
        padding-right: 35px
    }

    .pd-xs-x-35-f {
        padding-left: 35px !important;
        padding-right: 35px !important
    }

    .pd-xs-t-35 {
        padding-top: 35px
    }

    .pd-xs-r-35 {
        padding-right: 35px
    }

    .pd-xs-b-35 {
        padding-bottom: 35px
    }

    .pd-xs-l-35 {
        padding-left: 35px
    }

    .pd-xs-t-35-f {
        padding-top: 35px !important
    }

    .pd-xs-r-35-f {
        padding-right: 35px !important
    }

    .pd-xs-b-35-f {
        padding-bottom: 35px !important
    }

    .pd-xs-l-35-f {
        padding-left: 35px !important
    }

    .pd-xs-40 {
        padding: 40px
    }

    .pd-xs-40-f {
        padding: 40px !important
    }

    .pd-xs-y-40 {
        padding-top: 40px;
        padding-bottom: 40px
    }

    .pd-xs-y-40-f {
        padding-top: 40px !important;
        padding-bottom: 40px !important
    }

    .pd-xs-x-40 {
        padding-left: 40px;
        padding-right: 40px
    }

    .pd-xs-x-40-f {
        padding-left: 40px !important;
        padding-right: 40px !important
    }

    .pd-xs-t-40 {
        padding-top: 40px
    }

    .pd-xs-r-40 {
        padding-right: 40px
    }

    .pd-xs-b-40 {
        padding-bottom: 40px
    }

    .pd-xs-l-40 {
        padding-left: 40px
    }

    .pd-xs-t-40-f {
        padding-top: 40px !important
    }

    .pd-xs-r-40-f {
        padding-right: 40px !important
    }

    .pd-xs-b-40-f {
        padding-bottom: 40px !important
    }

    .pd-xs-l-40-f {
        padding-left: 40px !important
    }

    .pd-xs-45 {
        padding: 45px
    }

    .pd-xs-45-f {
        padding: 45px !important
    }

    .pd-xs-y-45 {
        padding-top: 45px;
        padding-bottom: 45px
    }

    .pd-xs-y-45-f {
        padding-top: 45px !important;
        padding-bottom: 45px !important
    }

    .pd-xs-x-45 {
        padding-left: 45px;
        padding-right: 45px
    }

    .pd-xs-x-45-f {
        padding-left: 45px !important;
        padding-right: 45px !important
    }

    .pd-xs-t-45 {
        padding-top: 45px
    }

    .pd-xs-r-45 {
        padding-right: 45px
    }

    .pd-xs-b-45 {
        padding-bottom: 45px
    }

    .pd-xs-l-45 {
        padding-left: 45px
    }

    .pd-xs-t-45-f {
        padding-top: 45px !important
    }

    .pd-xs-r-45-f {
        padding-right: 45px !important
    }

    .pd-xs-b-45-f {
        padding-bottom: 45px !important
    }

    .pd-xs-l-45-f {
        padding-left: 45px !important
    }

    .pd-xs-50 {
        padding: 50px
    }

    .pd-xs-50-f {
        padding: 50px !important
    }

    .pd-xs-y-50 {
        padding-top: 50px;
        padding-bottom: 50px
    }

    .pd-xs-y-50-f {
        padding-top: 50px !important;
        padding-bottom: 50px !important
    }

    .pd-xs-x-50 {
        padding-left: 50px;
        padding-right: 50px
    }

    .pd-xs-x-50-f {
        padding-left: 50px !important;
        padding-right: 50px !important
    }

    .pd-xs-t-50 {
        padding-top: 50px
    }

    .pd-xs-r-50 {
        padding-right: 50px
    }

    .pd-xs-b-50 {
        padding-bottom: 50px
    }

    .pd-xs-l-50 {
        padding-left: 50px
    }

    .pd-xs-t-50-f {
        padding-top: 50px !important
    }

    .pd-xs-r-50-f {
        padding-right: 50px !important
    }

    .pd-xs-b-50-f {
        padding-bottom: 50px !important
    }

    .pd-xs-l-50-f {
        padding-left: 50px !important
    }

    .pd-xs-55 {
        padding: 55px
    }

    .pd-xs-55-f {
        padding: 55px !important
    }

    .pd-xs-y-55 {
        padding-top: 55px;
        padding-bottom: 55px
    }

    .pd-xs-y-55-f {
        padding-top: 55px !important;
        padding-bottom: 55px !important
    }

    .pd-xs-x-55 {
        padding-left: 55px;
        padding-right: 55px
    }

    .pd-xs-x-55-f {
        padding-left: 55px !important;
        padding-right: 55px !important
    }

    .pd-xs-t-55 {
        padding-top: 55px
    }

    .pd-xs-r-55 {
        padding-right: 55px
    }

    .pd-xs-b-55 {
        padding-bottom: 55px
    }

    .pd-xs-l-55 {
        padding-left: 55px
    }

    .pd-xs-t-55-f {
        padding-top: 55px !important
    }

    .pd-xs-r-55-f {
        padding-right: 55px !important
    }

    .pd-xs-b-55-f {
        padding-bottom: 55px !important
    }

    .pd-xs-l-55-f {
        padding-left: 55px !important
    }

    .pd-xs-60 {
        padding: 60px
    }

    .pd-xs-60-f {
        padding: 60px !important
    }

    .pd-xs-y-60 {
        padding-top: 60px;
        padding-bottom: 60px
    }

    .pd-xs-y-60-f {
        padding-top: 60px !important;
        padding-bottom: 60px !important
    }

    .pd-xs-x-60 {
        padding-left: 60px;
        padding-right: 60px
    }

    .pd-xs-x-60-f {
        padding-left: 60px !important;
        padding-right: 60px !important
    }

    .pd-xs-t-60 {
        padding-top: 60px
    }

    .pd-xs-r-60 {
        padding-right: 60px
    }

    .pd-xs-b-60 {
        padding-bottom: 60px
    }

    .pd-xs-l-60 {
        padding-left: 60px
    }

    .pd-xs-t-60-f {
        padding-top: 60px !important
    }

    .pd-xs-r-60-f {
        padding-right: 60px !important
    }

    .pd-xs-b-60-f {
        padding-bottom: 60px !important
    }

    .pd-xs-l-60-f {
        padding-left: 60px !important
    }

    .pd-xs-65 {
        padding: 65px
    }

    .pd-xs-65-f {
        padding: 65px !important
    }

    .pd-xs-y-65 {
        padding-top: 65px;
        padding-bottom: 65px
    }

    .pd-xs-y-65-f {
        padding-top: 65px !important;
        padding-bottom: 65px !important
    }

    .pd-xs-x-65 {
        padding-left: 65px;
        padding-right: 65px
    }

    .pd-xs-x-65-f {
        padding-left: 65px !important;
        padding-right: 65px !important
    }

    .pd-xs-t-65 {
        padding-top: 65px
    }

    .pd-xs-r-65 {
        padding-right: 65px
    }

    .pd-xs-b-65 {
        padding-bottom: 65px
    }

    .pd-xs-l-65 {
        padding-left: 65px
    }

    .pd-xs-t-65-f {
        padding-top: 65px !important
    }

    .pd-xs-r-65-f {
        padding-right: 65px !important
    }

    .pd-xs-b-65-f {
        padding-bottom: 65px !important
    }

    .pd-xs-l-65-f {
        padding-left: 65px !important
    }

    .pd-xs-70 {
        padding: 70px
    }

    .pd-xs-70-f {
        padding: 70px !important
    }

    .pd-xs-y-70 {
        padding-top: 70px;
        padding-bottom: 70px
    }

    .pd-xs-y-70-f {
        padding-top: 70px !important;
        padding-bottom: 70px !important
    }

    .pd-xs-x-70 {
        padding-left: 70px;
        padding-right: 70px
    }

    .pd-xs-x-70-f {
        padding-left: 70px !important;
        padding-right: 70px !important
    }

    .pd-xs-t-70 {
        padding-top: 70px
    }

    .pd-xs-r-70 {
        padding-right: 70px
    }

    .pd-xs-b-70 {
        padding-bottom: 70px
    }

    .pd-xs-l-70 {
        padding-left: 70px
    }

    .pd-xs-t-70-f {
        padding-top: 70px !important
    }

    .pd-xs-r-70-f {
        padding-right: 70px !important
    }

    .pd-xs-b-70-f {
        padding-bottom: 70px !important
    }

    .pd-xs-l-70-f {
        padding-left: 70px !important
    }

    .pd-xs-75 {
        padding: 75px
    }

    .pd-xs-75-f {
        padding: 75px !important
    }

    .pd-xs-y-75 {
        padding-top: 75px;
        padding-bottom: 75px
    }

    .pd-xs-y-75-f {
        padding-top: 75px !important;
        padding-bottom: 75px !important
    }

    .pd-xs-x-75 {
        padding-left: 75px;
        padding-right: 75px
    }

    .pd-xs-x-75-f {
        padding-left: 75px !important;
        padding-right: 75px !important
    }

    .pd-xs-t-75 {
        padding-top: 75px
    }

    .pd-xs-r-75 {
        padding-right: 75px
    }

    .pd-xs-b-75 {
        padding-bottom: 75px
    }

    .pd-xs-l-75 {
        padding-left: 75px
    }

    .pd-xs-t-75-f {
        padding-top: 75px !important
    }

    .pd-xs-r-75-f {
        padding-right: 75px !important
    }

    .pd-xs-b-75-f {
        padding-bottom: 75px !important
    }

    .pd-xs-l-75-f {
        padding-left: 75px !important
    }

    .pd-xs-80 {
        padding: 80px
    }

    .pd-xs-80-f {
        padding: 80px !important
    }

    .pd-xs-y-80 {
        padding-top: 80px;
        padding-bottom: 80px
    }

    .pd-xs-y-80-f {
        padding-top: 80px !important;
        padding-bottom: 80px !important
    }

    .pd-xs-x-80 {
        padding-left: 80px;
        padding-right: 80px
    }

    .pd-xs-x-80-f {
        padding-left: 80px !important;
        padding-right: 80px !important
    }

    .pd-xs-t-80 {
        padding-top: 80px
    }

    .pd-xs-r-80 {
        padding-right: 80px
    }

    .pd-xs-b-80 {
        padding-bottom: 80px
    }

    .pd-xs-l-80 {
        padding-left: 80px
    }

    .pd-xs-t-80-f {
        padding-top: 80px !important
    }

    .pd-xs-r-80-f {
        padding-right: 80px !important
    }

    .pd-xs-b-80-f {
        padding-bottom: 80px !important
    }

    .pd-xs-l-80-f {
        padding-left: 80px !important
    }

    .pd-xs-85 {
        padding: 85px
    }

    .pd-xs-85-f {
        padding: 85px !important
    }

    .pd-xs-y-85 {
        padding-top: 85px;
        padding-bottom: 85px
    }

    .pd-xs-y-85-f {
        padding-top: 85px !important;
        padding-bottom: 85px !important
    }

    .pd-xs-x-85 {
        padding-left: 85px;
        padding-right: 85px
    }

    .pd-xs-x-85-f {
        padding-left: 85px !important;
        padding-right: 85px !important
    }

    .pd-xs-t-85 {
        padding-top: 85px
    }

    .pd-xs-r-85 {
        padding-right: 85px
    }

    .pd-xs-b-85 {
        padding-bottom: 85px
    }

    .pd-xs-l-85 {
        padding-left: 85px
    }

    .pd-xs-t-85-f {
        padding-top: 85px !important
    }

    .pd-xs-r-85-f {
        padding-right: 85px !important
    }

    .pd-xs-b-85-f {
        padding-bottom: 85px !important
    }

    .pd-xs-l-85-f {
        padding-left: 85px !important
    }

    .pd-xs-90 {
        padding: 90px
    }

    .pd-xs-90-f {
        padding: 90px !important
    }

    .pd-xs-y-90 {
        padding-top: 90px;
        padding-bottom: 90px
    }

    .pd-xs-y-90-f {
        padding-top: 90px !important;
        padding-bottom: 90px !important
    }

    .pd-xs-x-90 {
        padding-left: 90px;
        padding-right: 90px
    }

    .pd-xs-x-90-f {
        padding-left: 90px !important;
        padding-right: 90px !important
    }

    .pd-xs-t-90 {
        padding-top: 90px
    }

    .pd-xs-r-90 {
        padding-right: 90px
    }

    .pd-xs-b-90 {
        padding-bottom: 90px
    }

    .pd-xs-l-90 {
        padding-left: 90px
    }

    .pd-xs-t-90-f {
        padding-top: 90px !important
    }

    .pd-xs-r-90-f {
        padding-right: 90px !important
    }

    .pd-xs-b-90-f {
        padding-bottom: 90px !important
    }

    .pd-xs-l-90-f {
        padding-left: 90px !important
    }

    .pd-xs-95 {
        padding: 95px
    }

    .pd-xs-95-f {
        padding: 95px !important
    }

    .pd-xs-y-95 {
        padding-top: 95px;
        padding-bottom: 95px
    }

    .pd-xs-y-95-f {
        padding-top: 95px !important;
        padding-bottom: 95px !important
    }

    .pd-xs-x-95 {
        padding-left: 95px;
        padding-right: 95px
    }

    .pd-xs-x-95-f {
        padding-left: 95px !important;
        padding-right: 95px !important
    }

    .pd-xs-t-95 {
        padding-top: 95px
    }

    .pd-xs-r-95 {
        padding-right: 95px
    }

    .pd-xs-b-95 {
        padding-bottom: 95px
    }

    .pd-xs-l-95 {
        padding-left: 95px
    }

    .pd-xs-t-95-f {
        padding-top: 95px !important
    }

    .pd-xs-r-95-f {
        padding-right: 95px !important
    }

    .pd-xs-b-95-f {
        padding-bottom: 95px !important
    }

    .pd-xs-l-95-f {
        padding-left: 95px !important
    }

    .pd-xs-100 {
        padding: 100px
    }

    .pd-xs-100-f {
        padding: 100px !important
    }

    .pd-xs-y-100 {
        padding-top: 100px;
        padding-bottom: 100px
    }

    .pd-xs-y-100-f {
        padding-top: 100px !important;
        padding-bottom: 100px !important
    }

    .pd-xs-x-100 {
        padding-left: 100px;
        padding-right: 100px
    }

    .pd-xs-x-100-f {
        padding-left: 100px !important;
        padding-right: 100px !important
    }

    .pd-xs-t-100 {
        padding-top: 100px
    }

    .pd-xs-r-100 {
        padding-right: 100px
    }

    .pd-xs-b-100 {
        padding-bottom: 100px
    }

    .pd-xs-l-100 {
        padding-left: 100px
    }

    .pd-xs-t-100-f {
        padding-top: 100px !important
    }

    .pd-xs-r-100-f {
        padding-right: 100px !important
    }

    .pd-xs-b-100-f {
        padding-bottom: 100px !important
    }

    .pd-xs-l-100-f {
        padding-left: 100px !important
    }

    .pd-xs-110 {
        padding: 110px
    }

    .pd-xs-110-f {
        padding: 110px !important
    }

    .pd-xs-y-110 {
        padding-top: 110px;
        padding-bottom: 110px
    }

    .pd-xs-y-110-f {
        padding-top: 110px !important;
        padding-bottom: 110px !important
    }

    .pd-xs-x-110 {
        padding-left: 110px;
        padding-right: 110px
    }

    .pd-xs-x-110-f {
        padding-left: 110px !important;
        padding-right: 110px !important
    }

    .pd-xs-t-110 {
        padding-top: 110px
    }

    .pd-xs-r-110 {
        padding-right: 110px
    }

    .pd-xs-b-110 {
        padding-bottom: 110px
    }

    .pd-xs-l-110 {
        padding-left: 110px
    }

    .pd-xs-t-110-f {
        padding-top: 110px !important
    }

    .pd-xs-r-110-f {
        padding-right: 110px !important
    }

    .pd-xs-b-110-f {
        padding-bottom: 110px !important
    }

    .pd-xs-l-110-f {
        padding-left: 110px !important
    }

    .pd-xs-120 {
        padding: 120px
    }

    .pd-xs-120-f {
        padding: 120px !important
    }

    .pd-xs-y-120 {
        padding-top: 120px;
        padding-bottom: 120px
    }

    .pd-xs-y-120-f {
        padding-top: 120px !important;
        padding-bottom: 120px !important
    }

    .pd-xs-x-120 {
        padding-left: 120px;
        padding-right: 120px
    }

    .pd-xs-x-120-f {
        padding-left: 120px !important;
        padding-right: 120px !important
    }

    .pd-xs-t-120 {
        padding-top: 120px
    }

    .pd-xs-r-120 {
        padding-right: 120px
    }

    .pd-xs-b-120 {
        padding-bottom: 120px
    }

    .pd-xs-l-120 {
        padding-left: 120px
    }

    .pd-xs-t-120-f {
        padding-top: 120px !important
    }

    .pd-xs-r-120-f {
        padding-right: 120px !important
    }

    .pd-xs-b-120-f {
        padding-bottom: 120px !important
    }

    .pd-xs-l-120-f {
        padding-left: 120px !important
    }

    .pd-xs-130 {
        padding: 130px
    }

    .pd-xs-130-f {
        padding: 130px !important
    }

    .pd-xs-y-130 {
        padding-top: 130px;
        padding-bottom: 130px
    }

    .pd-xs-y-130-f {
        padding-top: 130px !important;
        padding-bottom: 130px !important
    }

    .pd-xs-x-130 {
        padding-left: 130px;
        padding-right: 130px
    }

    .pd-xs-x-130-f {
        padding-left: 130px !important;
        padding-right: 130px !important
    }

    .pd-xs-t-130 {
        padding-top: 130px
    }

    .pd-xs-r-130 {
        padding-right: 130px
    }

    .pd-xs-b-130 {
        padding-bottom: 130px
    }

    .pd-xs-l-130 {
        padding-left: 130px
    }

    .pd-xs-t-130-f {
        padding-top: 130px !important
    }

    .pd-xs-r-130-f {
        padding-right: 130px !important
    }

    .pd-xs-b-130-f {
        padding-bottom: 130px !important
    }

    .pd-xs-l-130-f {
        padding-left: 130px !important
    }

    .pd-xs-140 {
        padding: 140px
    }

    .pd-xs-140-f {
        padding: 140px !important
    }

    .pd-xs-y-140 {
        padding-top: 140px;
        padding-bottom: 140px
    }

    .pd-xs-y-140-f {
        padding-top: 140px !important;
        padding-bottom: 140px !important
    }

    .pd-xs-x-140 {
        padding-left: 140px;
        padding-right: 140px
    }

    .pd-xs-x-140-f {
        padding-left: 140px !important;
        padding-right: 140px !important
    }

    .pd-xs-t-140 {
        padding-top: 140px
    }

    .pd-xs-r-140 {
        padding-right: 140px
    }

    .pd-xs-b-140 {
        padding-bottom: 140px
    }

    .pd-xs-l-140 {
        padding-left: 140px
    }

    .pd-xs-t-140-f {
        padding-top: 140px !important
    }

    .pd-xs-r-140-f {
        padding-right: 140px !important
    }

    .pd-xs-b-140-f {
        padding-bottom: 140px !important
    }

    .pd-xs-l-140-f {
        padding-left: 140px !important
    }

    .pd-xs-150 {
        padding: 150px
    }

    .pd-xs-150-f {
        padding: 150px !important
    }

    .pd-xs-y-150 {
        padding-top: 150px;
        padding-bottom: 150px
    }

    .pd-xs-y-150-f {
        padding-top: 150px !important;
        padding-bottom: 150px !important
    }

    .pd-xs-x-150 {
        padding-left: 150px;
        padding-right: 150px
    }

    .pd-xs-x-150-f {
        padding-left: 150px !important;
        padding-right: 150px !important
    }

    .pd-xs-t-150 {
        padding-top: 150px
    }

    .pd-xs-r-150 {
        padding-right: 150px
    }

    .pd-xs-b-150 {
        padding-bottom: 150px
    }

    .pd-xs-l-150 {
        padding-left: 150px
    }

    .pd-xs-t-150-f {
        padding-top: 150px !important
    }

    .pd-xs-r-150-f {
        padding-right: 150px !important
    }

    .pd-xs-b-150-f {
        padding-bottom: 150px !important
    }

    .pd-xs-l-150-f {
        padding-left: 150px !important
    }

    .pd-xs-160 {
        padding: 160px
    }

    .pd-xs-160-f {
        padding: 160px !important
    }

    .pd-xs-y-160 {
        padding-top: 160px;
        padding-bottom: 160px
    }

    .pd-xs-y-160-f {
        padding-top: 160px !important;
        padding-bottom: 160px !important
    }

    .pd-xs-x-160 {
        padding-left: 160px;
        padding-right: 160px
    }

    .pd-xs-x-160-f {
        padding-left: 160px !important;
        padding-right: 160px !important
    }

    .pd-xs-t-160 {
        padding-top: 160px
    }

    .pd-xs-r-160 {
        padding-right: 160px
    }

    .pd-xs-b-160 {
        padding-bottom: 160px
    }

    .pd-xs-l-160 {
        padding-left: 160px
    }

    .pd-xs-t-160-f {
        padding-top: 160px !important
    }

    .pd-xs-r-160-f {
        padding-right: 160px !important
    }

    .pd-xs-b-160-f {
        padding-bottom: 160px !important
    }

    .pd-xs-l-160-f {
        padding-left: 160px !important
    }

    .pd-xs-170 {
        padding: 170px
    }

    .pd-xs-170-f {
        padding: 170px !important
    }

    .pd-xs-y-170 {
        padding-top: 170px;
        padding-bottom: 170px
    }

    .pd-xs-y-170-f {
        padding-top: 170px !important;
        padding-bottom: 170px !important
    }

    .pd-xs-x-170 {
        padding-left: 170px;
        padding-right: 170px
    }

    .pd-xs-x-170-f {
        padding-left: 170px !important;
        padding-right: 170px !important
    }

    .pd-xs-t-170 {
        padding-top: 170px
    }

    .pd-xs-r-170 {
        padding-right: 170px
    }

    .pd-xs-b-170 {
        padding-bottom: 170px
    }

    .pd-xs-l-170 {
        padding-left: 170px
    }

    .pd-xs-t-170-f {
        padding-top: 170px !important
    }

    .pd-xs-r-170-f {
        padding-right: 170px !important
    }

    .pd-xs-b-170-f {
        padding-bottom: 170px !important
    }

    .pd-xs-l-170-f {
        padding-left: 170px !important
    }

    .pd-xs-180 {
        padding: 180px
    }

    .pd-xs-180-f {
        padding: 180px !important
    }

    .pd-xs-y-180 {
        padding-top: 180px;
        padding-bottom: 180px
    }

    .pd-xs-y-180-f {
        padding-top: 180px !important;
        padding-bottom: 180px !important
    }

    .pd-xs-x-180 {
        padding-left: 180px;
        padding-right: 180px
    }

    .pd-xs-x-180-f {
        padding-left: 180px !important;
        padding-right: 180px !important
    }

    .pd-xs-t-180 {
        padding-top: 180px
    }

    .pd-xs-r-180 {
        padding-right: 180px
    }

    .pd-xs-b-180 {
        padding-bottom: 180px
    }

    .pd-xs-l-180 {
        padding-left: 180px
    }

    .pd-xs-t-180-f {
        padding-top: 180px !important
    }

    .pd-xs-r-180-f {
        padding-right: 180px !important
    }

    .pd-xs-b-180-f {
        padding-bottom: 180px !important
    }

    .pd-xs-l-180-f {
        padding-left: 180px !important
    }

    .pd-xs-190 {
        padding: 190px
    }

    .pd-xs-190-f {
        padding: 190px !important
    }

    .pd-xs-y-190 {
        padding-top: 190px;
        padding-bottom: 190px
    }

    .pd-xs-y-190-f {
        padding-top: 190px !important;
        padding-bottom: 190px !important
    }

    .pd-xs-x-190 {
        padding-left: 190px;
        padding-right: 190px
    }

    .pd-xs-x-190-f {
        padding-left: 190px !important;
        padding-right: 190px !important
    }

    .pd-xs-t-190 {
        padding-top: 190px
    }

    .pd-xs-r-190 {
        padding-right: 190px
    }

    .pd-xs-b-190 {
        padding-bottom: 190px
    }

    .pd-xs-l-190 {
        padding-left: 190px
    }

    .pd-xs-t-190-f {
        padding-top: 190px !important
    }

    .pd-xs-r-190-f {
        padding-right: 190px !important
    }

    .pd-xs-b-190-f {
        padding-bottom: 190px !important
    }

    .pd-xs-l-190-f {
        padding-left: 190px !important
    }

    .pd-xs-200 {
        padding: 200px
    }

    .pd-xs-200-f {
        padding: 200px !important
    }

    .pd-xs-y-200 {
        padding-top: 200px;
        padding-bottom: 200px
    }

    .pd-xs-y-200-f {
        padding-top: 200px !important;
        padding-bottom: 200px !important
    }

    .pd-xs-x-200 {
        padding-left: 200px;
        padding-right: 200px
    }

    .pd-xs-x-200-f {
        padding-left: 200px !important;
        padding-right: 200px !important
    }

    .pd-xs-t-200 {
        padding-top: 200px
    }

    .pd-xs-r-200 {
        padding-right: 200px
    }

    .pd-xs-b-200 {
        padding-bottom: 200px
    }

    .pd-xs-l-200 {
        padding-left: 200px
    }

    .pd-xs-t-200-f {
        padding-top: 200px !important
    }

    .pd-xs-r-200-f {
        padding-right: 200px !important
    }

    .pd-xs-b-200-f {
        padding-bottom: 200px !important
    }

    .pd-xs-l-200-f {
        padding-left: 200px !important
    }
}

@media (min-width: 576px) {
    .pd-sm-0 {
        padding: 0px
    }

    .pd-sm-0-f {
        padding: 0px !important
    }

    .pd-sm-y-0 {
        padding-top: 0px;
        padding-bottom: 0px
    }

    .pd-sm-y-0-f {
        padding-top: 0px !important;
        padding-bottom: 0px !important
    }

    .pd-sm-x-0 {
        padding-left: 0px;
        padding-right: 0px
    }

    .pd-sm-x-0-f {
        padding-left: 0px !important;
        padding-right: 0px !important
    }

    .pd-sm-t-0 {
        padding-top: 0px
    }

    .pd-sm-r-0 {
        padding-right: 0px
    }

    .pd-sm-b-0 {
        padding-bottom: 0px
    }

    .pd-sm-l-0 {
        padding-left: 0px
    }

    .pd-sm-t-0-f {
        padding-top: 0px !important
    }

    .pd-sm-r-0-f {
        padding-right: 0px !important
    }

    .pd-sm-b-0-f {
        padding-bottom: 0px !important
    }

    .pd-sm-l-0-f {
        padding-left: 0px !important
    }

    .pd-sm-1 {
        padding: 1px
    }

    .pd-sm-1-f {
        padding: 1px !important
    }

    .pd-sm-y-1 {
        padding-top: 1px;
        padding-bottom: 1px
    }

    .pd-sm-y-1-f {
        padding-top: 1px !important;
        padding-bottom: 1px !important
    }

    .pd-sm-x-1 {
        padding-left: 1px;
        padding-right: 1px
    }

    .pd-sm-x-1-f {
        padding-left: 1px !important;
        padding-right: 1px !important
    }

    .pd-sm-t-1 {
        padding-top: 1px
    }

    .pd-sm-r-1 {
        padding-right: 1px
    }

    .pd-sm-b-1 {
        padding-bottom: 1px
    }

    .pd-sm-l-1 {
        padding-left: 1px
    }

    .pd-sm-t-1-f {
        padding-top: 1px !important
    }

    .pd-sm-r-1-f {
        padding-right: 1px !important
    }

    .pd-sm-b-1-f {
        padding-bottom: 1px !important
    }

    .pd-sm-l-1-f {
        padding-left: 1px !important
    }

    .pd-sm-2 {
        padding: 2px
    }

    .pd-sm-2-f {
        padding: 2px !important
    }

    .pd-sm-y-2 {
        padding-top: 2px;
        padding-bottom: 2px
    }

    .pd-sm-y-2-f {
        padding-top: 2px !important;
        padding-bottom: 2px !important
    }

    .pd-sm-x-2 {
        padding-left: 2px;
        padding-right: 2px
    }

    .pd-sm-x-2-f {
        padding-left: 2px !important;
        padding-right: 2px !important
    }

    .pd-sm-t-2 {
        padding-top: 2px
    }

    .pd-sm-r-2 {
        padding-right: 2px
    }

    .pd-sm-b-2 {
        padding-bottom: 2px
    }

    .pd-sm-l-2 {
        padding-left: 2px
    }

    .pd-sm-t-2-f {
        padding-top: 2px !important
    }

    .pd-sm-r-2-f {
        padding-right: 2px !important
    }

    .pd-sm-b-2-f {
        padding-bottom: 2px !important
    }

    .pd-sm-l-2-f {
        padding-left: 2px !important
    }

    .pd-sm-3 {
        padding: 3px
    }

    .pd-sm-3-f {
        padding: 3px !important
    }

    .pd-sm-y-3 {
        padding-top: 3px;
        padding-bottom: 3px
    }

    .pd-sm-y-3-f {
        padding-top: 3px !important;
        padding-bottom: 3px !important
    }

    .pd-sm-x-3 {
        padding-left: 3px;
        padding-right: 3px
    }

    .pd-sm-x-3-f {
        padding-left: 3px !important;
        padding-right: 3px !important
    }

    .pd-sm-t-3 {
        padding-top: 3px
    }

    .pd-sm-r-3 {
        padding-right: 3px
    }

    .pd-sm-b-3 {
        padding-bottom: 3px
    }

    .pd-sm-l-3 {
        padding-left: 3px
    }

    .pd-sm-t-3-f {
        padding-top: 3px !important
    }

    .pd-sm-r-3-f {
        padding-right: 3px !important
    }

    .pd-sm-b-3-f {
        padding-bottom: 3px !important
    }

    .pd-sm-l-3-f {
        padding-left: 3px !important
    }

    .pd-sm-4 {
        padding: 4px
    }

    .pd-sm-4-f {
        padding: 4px !important
    }

    .pd-sm-y-4 {
        padding-top: 4px;
        padding-bottom: 4px
    }

    .pd-sm-y-4-f {
        padding-top: 4px !important;
        padding-bottom: 4px !important
    }

    .pd-sm-x-4 {
        padding-left: 4px;
        padding-right: 4px
    }

    .pd-sm-x-4-f {
        padding-left: 4px !important;
        padding-right: 4px !important
    }

    .pd-sm-t-4 {
        padding-top: 4px
    }

    .pd-sm-r-4 {
        padding-right: 4px
    }

    .pd-sm-b-4 {
        padding-bottom: 4px
    }

    .pd-sm-l-4 {
        padding-left: 4px
    }

    .pd-sm-t-4-f {
        padding-top: 4px !important
    }

    .pd-sm-r-4-f {
        padding-right: 4px !important
    }

    .pd-sm-b-4-f {
        padding-bottom: 4px !important
    }

    .pd-sm-l-4-f {
        padding-left: 4px !important
    }

    .pd-sm-5 {
        padding: 5px
    }

    .pd-sm-5-f {
        padding: 5px !important
    }

    .pd-sm-y-5 {
        padding-top: 5px;
        padding-bottom: 5px
    }

    .pd-sm-y-5-f {
        padding-top: 5px !important;
        padding-bottom: 5px !important
    }

    .pd-sm-x-5 {
        padding-left: 5px;
        padding-right: 5px
    }

    .pd-sm-x-5-f {
        padding-left: 5px !important;
        padding-right: 5px !important
    }

    .pd-sm-t-5 {
        padding-top: 5px
    }

    .pd-sm-r-5 {
        padding-right: 5px
    }

    .pd-sm-b-5 {
        padding-bottom: 5px
    }

    .pd-sm-l-5 {
        padding-left: 5px
    }

    .pd-sm-t-5-f {
        padding-top: 5px !important
    }

    .pd-sm-r-5-f {
        padding-right: 5px !important
    }

    .pd-sm-b-5-f {
        padding-bottom: 5px !important
    }

    .pd-sm-l-5-f {
        padding-left: 5px !important
    }

    .pd-sm-6 {
        padding: 6px
    }

    .pd-sm-6-f {
        padding: 6px !important
    }

    .pd-sm-y-6 {
        padding-top: 6px;
        padding-bottom: 6px
    }

    .pd-sm-y-6-f {
        padding-top: 6px !important;
        padding-bottom: 6px !important
    }

    .pd-sm-x-6 {
        padding-left: 6px;
        padding-right: 6px
    }

    .pd-sm-x-6-f {
        padding-left: 6px !important;
        padding-right: 6px !important
    }

    .pd-sm-t-6 {
        padding-top: 6px
    }

    .pd-sm-r-6 {
        padding-right: 6px
    }

    .pd-sm-b-6 {
        padding-bottom: 6px
    }

    .pd-sm-l-6 {
        padding-left: 6px
    }

    .pd-sm-t-6-f {
        padding-top: 6px !important
    }

    .pd-sm-r-6-f {
        padding-right: 6px !important
    }

    .pd-sm-b-6-f {
        padding-bottom: 6px !important
    }

    .pd-sm-l-6-f {
        padding-left: 6px !important
    }

    .pd-sm-7 {
        padding: 7px
    }

    .pd-sm-7-f {
        padding: 7px !important
    }

    .pd-sm-y-7 {
        padding-top: 7px;
        padding-bottom: 7px
    }

    .pd-sm-y-7-f {
        padding-top: 7px !important;
        padding-bottom: 7px !important
    }

    .pd-sm-x-7 {
        padding-left: 7px;
        padding-right: 7px
    }

    .pd-sm-x-7-f {
        padding-left: 7px !important;
        padding-right: 7px !important
    }

    .pd-sm-t-7 {
        padding-top: 7px
    }

    .pd-sm-r-7 {
        padding-right: 7px
    }

    .pd-sm-b-7 {
        padding-bottom: 7px
    }

    .pd-sm-l-7 {
        padding-left: 7px
    }

    .pd-sm-t-7-f {
        padding-top: 7px !important
    }

    .pd-sm-r-7-f {
        padding-right: 7px !important
    }

    .pd-sm-b-7-f {
        padding-bottom: 7px !important
    }

    .pd-sm-l-7-f {
        padding-left: 7px !important
    }

    .pd-sm-8 {
        padding: 8px
    }

    .pd-sm-8-f {
        padding: 8px !important
    }

    .pd-sm-y-8 {
        padding-top: 8px;
        padding-bottom: 8px
    }

    .pd-sm-y-8-f {
        padding-top: 8px !important;
        padding-bottom: 8px !important
    }

    .pd-sm-x-8 {
        padding-left: 8px;
        padding-right: 8px
    }

    .pd-sm-x-8-f {
        padding-left: 8px !important;
        padding-right: 8px !important
    }

    .pd-sm-t-8 {
        padding-top: 8px
    }

    .pd-sm-r-8 {
        padding-right: 8px
    }

    .pd-sm-b-8 {
        padding-bottom: 8px
    }

    .pd-sm-l-8 {
        padding-left: 8px
    }

    .pd-sm-t-8-f {
        padding-top: 8px !important
    }

    .pd-sm-r-8-f {
        padding-right: 8px !important
    }

    .pd-sm-b-8-f {
        padding-bottom: 8px !important
    }

    .pd-sm-l-8-f {
        padding-left: 8px !important
    }

    .pd-sm-9 {
        padding: 9px
    }

    .pd-sm-9-f {
        padding: 9px !important
    }

    .pd-sm-y-9 {
        padding-top: 9px;
        padding-bottom: 9px
    }

    .pd-sm-y-9-f {
        padding-top: 9px !important;
        padding-bottom: 9px !important
    }

    .pd-sm-x-9 {
        padding-left: 9px;
        padding-right: 9px
    }

    .pd-sm-x-9-f {
        padding-left: 9px !important;
        padding-right: 9px !important
    }

    .pd-sm-t-9 {
        padding-top: 9px
    }

    .pd-sm-r-9 {
        padding-right: 9px
    }

    .pd-sm-b-9 {
        padding-bottom: 9px
    }

    .pd-sm-l-9 {
        padding-left: 9px
    }

    .pd-sm-t-9-f {
        padding-top: 9px !important
    }

    .pd-sm-r-9-f {
        padding-right: 9px !important
    }

    .pd-sm-b-9-f {
        padding-bottom: 9px !important
    }

    .pd-sm-l-9-f {
        padding-left: 9px !important
    }

    .pd-sm-10 {
        padding: 10px
    }

    .pd-sm-10-f {
        padding: 10px !important
    }

    .pd-sm-y-10 {
        padding-top: 10px;
        padding-bottom: 10px
    }

    .pd-sm-y-10-f {
        padding-top: 10px !important;
        padding-bottom: 10px !important
    }

    .pd-sm-x-10 {
        padding-left: 10px;
        padding-right: 10px
    }

    .pd-sm-x-10-f {
        padding-left: 10px !important;
        padding-right: 10px !important
    }

    .pd-sm-t-10 {
        padding-top: 10px
    }

    .pd-sm-r-10 {
        padding-right: 10px
    }

    .pd-sm-b-10 {
        padding-bottom: 10px
    }

    .pd-sm-l-10 {
        padding-left: 10px
    }

    .pd-sm-t-10-f {
        padding-top: 10px !important
    }

    .pd-sm-r-10-f {
        padding-right: 10px !important
    }

    .pd-sm-b-10-f {
        padding-bottom: 10px !important
    }

    .pd-sm-l-10-f {
        padding-left: 10px !important
    }

    .pd-sm-15 {
        padding: 15px
    }

    .pd-sm-15-f {
        padding: 15px !important
    }

    .pd-sm-y-15 {
        padding-top: 15px;
        padding-bottom: 15px
    }

    .pd-sm-y-15-f {
        padding-top: 15px !important;
        padding-bottom: 15px !important
    }

    .pd-sm-x-15 {
        padding-left: 15px;
        padding-right: 15px
    }

    .pd-sm-x-15-f {
        padding-left: 15px !important;
        padding-right: 15px !important
    }

    .pd-sm-t-15 {
        padding-top: 15px
    }

    .pd-sm-r-15 {
        padding-right: 15px
    }

    .pd-sm-b-15 {
        padding-bottom: 15px
    }

    .pd-sm-l-15 {
        padding-left: 15px
    }

    .pd-sm-t-15-f {
        padding-top: 15px !important
    }

    .pd-sm-r-15-f {
        padding-right: 15px !important
    }

    .pd-sm-b-15-f {
        padding-bottom: 15px !important
    }

    .pd-sm-l-15-f {
        padding-left: 15px !important
    }

    .pd-sm-20 {
        padding: 20px
    }

    .pd-sm-20-f {
        padding: 20px !important
    }

    .pd-sm-y-20 {
        padding-top: 20px;
        padding-bottom: 20px
    }

    .pd-sm-y-20-f {
        padding-top: 20px !important;
        padding-bottom: 20px !important
    }

    .pd-sm-x-20 {
        padding-left: 20px;
        padding-right: 20px
    }

    .pd-sm-x-20-f {
        padding-left: 20px !important;
        padding-right: 20px !important
    }

    .pd-sm-t-20 {
        padding-top: 20px
    }

    .pd-sm-r-20 {
        padding-right: 20px
    }

    .pd-sm-b-20 {
        padding-bottom: 20px
    }

    .pd-sm-l-20 {
        padding-left: 20px
    }

    .pd-sm-t-20-f {
        padding-top: 20px !important
    }

    .pd-sm-r-20-f {
        padding-right: 20px !important
    }

    .pd-sm-b-20-f {
        padding-bottom: 20px !important
    }

    .pd-sm-l-20-f {
        padding-left: 20px !important
    }

    .pd-sm-25 {
        padding: 25px
    }

    .pd-sm-25-f {
        padding: 25px !important
    }

    .pd-sm-y-25 {
        padding-top: 25px;
        padding-bottom: 25px
    }

    .pd-sm-y-25-f {
        padding-top: 25px !important;
        padding-bottom: 25px !important
    }

    .pd-sm-x-25 {
        padding-left: 25px;
        padding-right: 25px
    }

    .pd-sm-x-25-f {
        padding-left: 25px !important;
        padding-right: 25px !important
    }

    .pd-sm-t-25 {
        padding-top: 25px
    }

    .pd-sm-r-25 {
        padding-right: 25px
    }

    .pd-sm-b-25 {
        padding-bottom: 25px
    }

    .pd-sm-l-25 {
        padding-left: 25px
    }

    .pd-sm-t-25-f {
        padding-top: 25px !important
    }

    .pd-sm-r-25-f {
        padding-right: 25px !important
    }

    .pd-sm-b-25-f {
        padding-bottom: 25px !important
    }

    .pd-sm-l-25-f {
        padding-left: 25px !important
    }

    .pd-sm-30 {
        padding: 30px
    }

    .pd-sm-30-f {
        padding: 30px !important
    }

    .pd-sm-y-30 {
        padding-top: 30px;
        padding-bottom: 30px
    }

    .pd-sm-y-30-f {
        padding-top: 30px !important;
        padding-bottom: 30px !important
    }

    .pd-sm-x-30 {
        padding-left: 30px;
        padding-right: 30px
    }

    .pd-sm-x-30-f {
        padding-left: 30px !important;
        padding-right: 30px !important
    }

    .pd-sm-t-30 {
        padding-top: 30px
    }

    .pd-sm-r-30 {
        padding-right: 30px
    }

    .pd-sm-b-30 {
        padding-bottom: 30px
    }

    .pd-sm-l-30 {
        padding-left: 30px
    }

    .pd-sm-t-30-f {
        padding-top: 30px !important
    }

    .pd-sm-r-30-f {
        padding-right: 30px !important
    }

    .pd-sm-b-30-f {
        padding-bottom: 30px !important
    }

    .pd-sm-l-30-f {
        padding-left: 30px !important
    }

    .pd-sm-35 {
        padding: 35px
    }

    .pd-sm-35-f {
        padding: 35px !important
    }

    .pd-sm-y-35 {
        padding-top: 35px;
        padding-bottom: 35px
    }

    .pd-sm-y-35-f {
        padding-top: 35px !important;
        padding-bottom: 35px !important
    }

    .pd-sm-x-35 {
        padding-left: 35px;
        padding-right: 35px
    }

    .pd-sm-x-35-f {
        padding-left: 35px !important;
        padding-right: 35px !important
    }

    .pd-sm-t-35 {
        padding-top: 35px
    }

    .pd-sm-r-35 {
        padding-right: 35px
    }

    .pd-sm-b-35 {
        padding-bottom: 35px
    }

    .pd-sm-l-35 {
        padding-left: 35px
    }

    .pd-sm-t-35-f {
        padding-top: 35px !important
    }

    .pd-sm-r-35-f {
        padding-right: 35px !important
    }

    .pd-sm-b-35-f {
        padding-bottom: 35px !important
    }

    .pd-sm-l-35-f {
        padding-left: 35px !important
    }

    .pd-sm-40 {
        padding: 40px
    }

    .pd-sm-40-f {
        padding: 40px !important
    }

    .pd-sm-y-40 {
        padding-top: 40px;
        padding-bottom: 40px
    }

    .pd-sm-y-40-f {
        padding-top: 40px !important;
        padding-bottom: 40px !important
    }

    .pd-sm-x-40 {
        padding-left: 40px;
        padding-right: 40px
    }

    .pd-sm-x-40-f {
        padding-left: 40px !important;
        padding-right: 40px !important
    }

    .pd-sm-t-40 {
        padding-top: 40px
    }

    .pd-sm-r-40 {
        padding-right: 40px
    }

    .pd-sm-b-40 {
        padding-bottom: 40px
    }

    .pd-sm-l-40 {
        padding-left: 40px
    }

    .pd-sm-t-40-f {
        padding-top: 40px !important
    }

    .pd-sm-r-40-f {
        padding-right: 40px !important
    }

    .pd-sm-b-40-f {
        padding-bottom: 40px !important
    }

    .pd-sm-l-40-f {
        padding-left: 40px !important
    }

    .pd-sm-45 {
        padding: 45px
    }

    .pd-sm-45-f {
        padding: 45px !important
    }

    .pd-sm-y-45 {
        padding-top: 45px;
        padding-bottom: 45px
    }

    .pd-sm-y-45-f {
        padding-top: 45px !important;
        padding-bottom: 45px !important
    }

    .pd-sm-x-45 {
        padding-left: 45px;
        padding-right: 45px
    }

    .pd-sm-x-45-f {
        padding-left: 45px !important;
        padding-right: 45px !important
    }

    .pd-sm-t-45 {
        padding-top: 45px
    }

    .pd-sm-r-45 {
        padding-right: 45px
    }

    .pd-sm-b-45 {
        padding-bottom: 45px
    }

    .pd-sm-l-45 {
        padding-left: 45px
    }

    .pd-sm-t-45-f {
        padding-top: 45px !important
    }

    .pd-sm-r-45-f {
        padding-right: 45px !important
    }

    .pd-sm-b-45-f {
        padding-bottom: 45px !important
    }

    .pd-sm-l-45-f {
        padding-left: 45px !important
    }

    .pd-sm-50 {
        padding: 50px
    }

    .pd-sm-50-f {
        padding: 50px !important
    }

    .pd-sm-y-50 {
        padding-top: 50px;
        padding-bottom: 50px
    }

    .pd-sm-y-50-f {
        padding-top: 50px !important;
        padding-bottom: 50px !important
    }

    .pd-sm-x-50 {
        padding-left: 50px;
        padding-right: 50px
    }

    .pd-sm-x-50-f {
        padding-left: 50px !important;
        padding-right: 50px !important
    }

    .pd-sm-t-50 {
        padding-top: 50px
    }

    .pd-sm-r-50 {
        padding-right: 50px
    }

    .pd-sm-b-50 {
        padding-bottom: 50px
    }

    .pd-sm-l-50 {
        padding-left: 50px
    }

    .pd-sm-t-50-f {
        padding-top: 50px !important
    }

    .pd-sm-r-50-f {
        padding-right: 50px !important
    }

    .pd-sm-b-50-f {
        padding-bottom: 50px !important
    }

    .pd-sm-l-50-f {
        padding-left: 50px !important
    }

    .pd-sm-55 {
        padding: 55px
    }

    .pd-sm-55-f {
        padding: 55px !important
    }

    .pd-sm-y-55 {
        padding-top: 55px;
        padding-bottom: 55px
    }

    .pd-sm-y-55-f {
        padding-top: 55px !important;
        padding-bottom: 55px !important
    }

    .pd-sm-x-55 {
        padding-left: 55px;
        padding-right: 55px
    }

    .pd-sm-x-55-f {
        padding-left: 55px !important;
        padding-right: 55px !important
    }

    .pd-sm-t-55 {
        padding-top: 55px
    }

    .pd-sm-r-55 {
        padding-right: 55px
    }

    .pd-sm-b-55 {
        padding-bottom: 55px
    }

    .pd-sm-l-55 {
        padding-left: 55px
    }

    .pd-sm-t-55-f {
        padding-top: 55px !important
    }

    .pd-sm-r-55-f {
        padding-right: 55px !important
    }

    .pd-sm-b-55-f {
        padding-bottom: 55px !important
    }

    .pd-sm-l-55-f {
        padding-left: 55px !important
    }

    .pd-sm-60 {
        padding: 60px
    }

    .pd-sm-60-f {
        padding: 60px !important
    }

    .pd-sm-y-60 {
        padding-top: 60px;
        padding-bottom: 60px
    }

    .pd-sm-y-60-f {
        padding-top: 60px !important;
        padding-bottom: 60px !important
    }

    .pd-sm-x-60 {
        padding-left: 60px;
        padding-right: 60px
    }

    .pd-sm-x-60-f {
        padding-left: 60px !important;
        padding-right: 60px !important
    }

    .pd-sm-t-60 {
        padding-top: 60px
    }

    .pd-sm-r-60 {
        padding-right: 60px
    }

    .pd-sm-b-60 {
        padding-bottom: 60px
    }

    .pd-sm-l-60 {
        padding-left: 60px
    }

    .pd-sm-t-60-f {
        padding-top: 60px !important
    }

    .pd-sm-r-60-f {
        padding-right: 60px !important
    }

    .pd-sm-b-60-f {
        padding-bottom: 60px !important
    }

    .pd-sm-l-60-f {
        padding-left: 60px !important
    }

    .pd-sm-65 {
        padding: 65px
    }

    .pd-sm-65-f {
        padding: 65px !important
    }

    .pd-sm-y-65 {
        padding-top: 65px;
        padding-bottom: 65px
    }

    .pd-sm-y-65-f {
        padding-top: 65px !important;
        padding-bottom: 65px !important
    }

    .pd-sm-x-65 {
        padding-left: 65px;
        padding-right: 65px
    }

    .pd-sm-x-65-f {
        padding-left: 65px !important;
        padding-right: 65px !important
    }

    .pd-sm-t-65 {
        padding-top: 65px
    }

    .pd-sm-r-65 {
        padding-right: 65px
    }

    .pd-sm-b-65 {
        padding-bottom: 65px
    }

    .pd-sm-l-65 {
        padding-left: 65px
    }

    .pd-sm-t-65-f {
        padding-top: 65px !important
    }

    .pd-sm-r-65-f {
        padding-right: 65px !important
    }

    .pd-sm-b-65-f {
        padding-bottom: 65px !important
    }

    .pd-sm-l-65-f {
        padding-left: 65px !important
    }

    .pd-sm-70 {
        padding: 70px
    }

    .pd-sm-70-f {
        padding: 70px !important
    }

    .pd-sm-y-70 {
        padding-top: 70px;
        padding-bottom: 70px
    }

    .pd-sm-y-70-f {
        padding-top: 70px !important;
        padding-bottom: 70px !important
    }

    .pd-sm-x-70 {
        padding-left: 70px;
        padding-right: 70px
    }

    .pd-sm-x-70-f {
        padding-left: 70px !important;
        padding-right: 70px !important
    }

    .pd-sm-t-70 {
        padding-top: 70px
    }

    .pd-sm-r-70 {
        padding-right: 70px
    }

    .pd-sm-b-70 {
        padding-bottom: 70px
    }

    .pd-sm-l-70 {
        padding-left: 70px
    }

    .pd-sm-t-70-f {
        padding-top: 70px !important
    }

    .pd-sm-r-70-f {
        padding-right: 70px !important
    }

    .pd-sm-b-70-f {
        padding-bottom: 70px !important
    }

    .pd-sm-l-70-f {
        padding-left: 70px !important
    }

    .pd-sm-75 {
        padding: 75px
    }

    .pd-sm-75-f {
        padding: 75px !important
    }

    .pd-sm-y-75 {
        padding-top: 75px;
        padding-bottom: 75px
    }

    .pd-sm-y-75-f {
        padding-top: 75px !important;
        padding-bottom: 75px !important
    }

    .pd-sm-x-75 {
        padding-left: 75px;
        padding-right: 75px
    }

    .pd-sm-x-75-f {
        padding-left: 75px !important;
        padding-right: 75px !important
    }

    .pd-sm-t-75 {
        padding-top: 75px
    }

    .pd-sm-r-75 {
        padding-right: 75px
    }

    .pd-sm-b-75 {
        padding-bottom: 75px
    }

    .pd-sm-l-75 {
        padding-left: 75px
    }

    .pd-sm-t-75-f {
        padding-top: 75px !important
    }

    .pd-sm-r-75-f {
        padding-right: 75px !important
    }

    .pd-sm-b-75-f {
        padding-bottom: 75px !important
    }

    .pd-sm-l-75-f {
        padding-left: 75px !important
    }

    .pd-sm-80 {
        padding: 80px
    }

    .pd-sm-80-f {
        padding: 80px !important
    }

    .pd-sm-y-80 {
        padding-top: 80px;
        padding-bottom: 80px
    }

    .pd-sm-y-80-f {
        padding-top: 80px !important;
        padding-bottom: 80px !important
    }

    .pd-sm-x-80 {
        padding-left: 80px;
        padding-right: 80px
    }

    .pd-sm-x-80-f {
        padding-left: 80px !important;
        padding-right: 80px !important
    }

    .pd-sm-t-80 {
        padding-top: 80px
    }

    .pd-sm-r-80 {
        padding-right: 80px
    }

    .pd-sm-b-80 {
        padding-bottom: 80px
    }

    .pd-sm-l-80 {
        padding-left: 80px
    }

    .pd-sm-t-80-f {
        padding-top: 80px !important
    }

    .pd-sm-r-80-f {
        padding-right: 80px !important
    }

    .pd-sm-b-80-f {
        padding-bottom: 80px !important
    }

    .pd-sm-l-80-f {
        padding-left: 80px !important
    }

    .pd-sm-85 {
        padding: 85px
    }

    .pd-sm-85-f {
        padding: 85px !important
    }

    .pd-sm-y-85 {
        padding-top: 85px;
        padding-bottom: 85px
    }

    .pd-sm-y-85-f {
        padding-top: 85px !important;
        padding-bottom: 85px !important
    }

    .pd-sm-x-85 {
        padding-left: 85px;
        padding-right: 85px
    }

    .pd-sm-x-85-f {
        padding-left: 85px !important;
        padding-right: 85px !important
    }

    .pd-sm-t-85 {
        padding-top: 85px
    }

    .pd-sm-r-85 {
        padding-right: 85px
    }

    .pd-sm-b-85 {
        padding-bottom: 85px
    }

    .pd-sm-l-85 {
        padding-left: 85px
    }

    .pd-sm-t-85-f {
        padding-top: 85px !important
    }

    .pd-sm-r-85-f {
        padding-right: 85px !important
    }

    .pd-sm-b-85-f {
        padding-bottom: 85px !important
    }

    .pd-sm-l-85-f {
        padding-left: 85px !important
    }

    .pd-sm-90 {
        padding: 90px
    }

    .pd-sm-90-f {
        padding: 90px !important
    }

    .pd-sm-y-90 {
        padding-top: 90px;
        padding-bottom: 90px
    }

    .pd-sm-y-90-f {
        padding-top: 90px !important;
        padding-bottom: 90px !important
    }

    .pd-sm-x-90 {
        padding-left: 90px;
        padding-right: 90px
    }

    .pd-sm-x-90-f {
        padding-left: 90px !important;
        padding-right: 90px !important
    }

    .pd-sm-t-90 {
        padding-top: 90px
    }

    .pd-sm-r-90 {
        padding-right: 90px
    }

    .pd-sm-b-90 {
        padding-bottom: 90px
    }

    .pd-sm-l-90 {
        padding-left: 90px
    }

    .pd-sm-t-90-f {
        padding-top: 90px !important
    }

    .pd-sm-r-90-f {
        padding-right: 90px !important
    }

    .pd-sm-b-90-f {
        padding-bottom: 90px !important
    }

    .pd-sm-l-90-f {
        padding-left: 90px !important
    }

    .pd-sm-95 {
        padding: 95px
    }

    .pd-sm-95-f {
        padding: 95px !important
    }

    .pd-sm-y-95 {
        padding-top: 95px;
        padding-bottom: 95px
    }

    .pd-sm-y-95-f {
        padding-top: 95px !important;
        padding-bottom: 95px !important
    }

    .pd-sm-x-95 {
        padding-left: 95px;
        padding-right: 95px
    }

    .pd-sm-x-95-f {
        padding-left: 95px !important;
        padding-right: 95px !important
    }

    .pd-sm-t-95 {
        padding-top: 95px
    }

    .pd-sm-r-95 {
        padding-right: 95px
    }

    .pd-sm-b-95 {
        padding-bottom: 95px
    }

    .pd-sm-l-95 {
        padding-left: 95px
    }

    .pd-sm-t-95-f {
        padding-top: 95px !important
    }

    .pd-sm-r-95-f {
        padding-right: 95px !important
    }

    .pd-sm-b-95-f {
        padding-bottom: 95px !important
    }

    .pd-sm-l-95-f {
        padding-left: 95px !important
    }

    .pd-sm-100 {
        padding: 100px
    }

    .pd-sm-100-f {
        padding: 100px !important
    }

    .pd-sm-y-100 {
        padding-top: 100px;
        padding-bottom: 100px
    }

    .pd-sm-y-100-f {
        padding-top: 100px !important;
        padding-bottom: 100px !important
    }

    .pd-sm-x-100 {
        padding-left: 100px;
        padding-right: 100px
    }

    .pd-sm-x-100-f {
        padding-left: 100px !important;
        padding-right: 100px !important
    }

    .pd-sm-t-100 {
        padding-top: 100px
    }

    .pd-sm-r-100 {
        padding-right: 100px
    }

    .pd-sm-b-100 {
        padding-bottom: 100px
    }

    .pd-sm-l-100 {
        padding-left: 100px
    }

    .pd-sm-t-100-f {
        padding-top: 100px !important
    }

    .pd-sm-r-100-f {
        padding-right: 100px !important
    }

    .pd-sm-b-100-f {
        padding-bottom: 100px !important
    }

    .pd-sm-l-100-f {
        padding-left: 100px !important
    }

    .pd-sm-110 {
        padding: 110px
    }

    .pd-sm-110-f {
        padding: 110px !important
    }

    .pd-sm-y-110 {
        padding-top: 110px;
        padding-bottom: 110px
    }

    .pd-sm-y-110-f {
        padding-top: 110px !important;
        padding-bottom: 110px !important
    }

    .pd-sm-x-110 {
        padding-left: 110px;
        padding-right: 110px
    }

    .pd-sm-x-110-f {
        padding-left: 110px !important;
        padding-right: 110px !important
    }

    .pd-sm-t-110 {
        padding-top: 110px
    }

    .pd-sm-r-110 {
        padding-right: 110px
    }

    .pd-sm-b-110 {
        padding-bottom: 110px
    }

    .pd-sm-l-110 {
        padding-left: 110px
    }

    .pd-sm-t-110-f {
        padding-top: 110px !important
    }

    .pd-sm-r-110-f {
        padding-right: 110px !important
    }

    .pd-sm-b-110-f {
        padding-bottom: 110px !important
    }

    .pd-sm-l-110-f {
        padding-left: 110px !important
    }

    .pd-sm-120 {
        padding: 120px
    }

    .pd-sm-120-f {
        padding: 120px !important
    }

    .pd-sm-y-120 {
        padding-top: 120px;
        padding-bottom: 120px
    }

    .pd-sm-y-120-f {
        padding-top: 120px !important;
        padding-bottom: 120px !important
    }

    .pd-sm-x-120 {
        padding-left: 120px;
        padding-right: 120px
    }

    .pd-sm-x-120-f {
        padding-left: 120px !important;
        padding-right: 120px !important
    }

    .pd-sm-t-120 {
        padding-top: 120px
    }

    .pd-sm-r-120 {
        padding-right: 120px
    }

    .pd-sm-b-120 {
        padding-bottom: 120px
    }

    .pd-sm-l-120 {
        padding-left: 120px
    }

    .pd-sm-t-120-f {
        padding-top: 120px !important
    }

    .pd-sm-r-120-f {
        padding-right: 120px !important
    }

    .pd-sm-b-120-f {
        padding-bottom: 120px !important
    }

    .pd-sm-l-120-f {
        padding-left: 120px !important
    }

    .pd-sm-130 {
        padding: 130px
    }

    .pd-sm-130-f {
        padding: 130px !important
    }

    .pd-sm-y-130 {
        padding-top: 130px;
        padding-bottom: 130px
    }

    .pd-sm-y-130-f {
        padding-top: 130px !important;
        padding-bottom: 130px !important
    }

    .pd-sm-x-130 {
        padding-left: 130px;
        padding-right: 130px
    }

    .pd-sm-x-130-f {
        padding-left: 130px !important;
        padding-right: 130px !important
    }

    .pd-sm-t-130 {
        padding-top: 130px
    }

    .pd-sm-r-130 {
        padding-right: 130px
    }

    .pd-sm-b-130 {
        padding-bottom: 130px
    }

    .pd-sm-l-130 {
        padding-left: 130px
    }

    .pd-sm-t-130-f {
        padding-top: 130px !important
    }

    .pd-sm-r-130-f {
        padding-right: 130px !important
    }

    .pd-sm-b-130-f {
        padding-bottom: 130px !important
    }

    .pd-sm-l-130-f {
        padding-left: 130px !important
    }

    .pd-sm-140 {
        padding: 140px
    }

    .pd-sm-140-f {
        padding: 140px !important
    }

    .pd-sm-y-140 {
        padding-top: 140px;
        padding-bottom: 140px
    }

    .pd-sm-y-140-f {
        padding-top: 140px !important;
        padding-bottom: 140px !important
    }

    .pd-sm-x-140 {
        padding-left: 140px;
        padding-right: 140px
    }

    .pd-sm-x-140-f {
        padding-left: 140px !important;
        padding-right: 140px !important
    }

    .pd-sm-t-140 {
        padding-top: 140px
    }

    .pd-sm-r-140 {
        padding-right: 140px
    }

    .pd-sm-b-140 {
        padding-bottom: 140px
    }

    .pd-sm-l-140 {
        padding-left: 140px
    }

    .pd-sm-t-140-f {
        padding-top: 140px !important
    }

    .pd-sm-r-140-f {
        padding-right: 140px !important
    }

    .pd-sm-b-140-f {
        padding-bottom: 140px !important
    }

    .pd-sm-l-140-f {
        padding-left: 140px !important
    }

    .pd-sm-150 {
        padding: 150px
    }

    .pd-sm-150-f {
        padding: 150px !important
    }

    .pd-sm-y-150 {
        padding-top: 150px;
        padding-bottom: 150px
    }

    .pd-sm-y-150-f {
        padding-top: 150px !important;
        padding-bottom: 150px !important
    }

    .pd-sm-x-150 {
        padding-left: 150px;
        padding-right: 150px
    }

    .pd-sm-x-150-f {
        padding-left: 150px !important;
        padding-right: 150px !important
    }

    .pd-sm-t-150 {
        padding-top: 150px
    }

    .pd-sm-r-150 {
        padding-right: 150px
    }

    .pd-sm-b-150 {
        padding-bottom: 150px
    }

    .pd-sm-l-150 {
        padding-left: 150px
    }

    .pd-sm-t-150-f {
        padding-top: 150px !important
    }

    .pd-sm-r-150-f {
        padding-right: 150px !important
    }

    .pd-sm-b-150-f {
        padding-bottom: 150px !important
    }

    .pd-sm-l-150-f {
        padding-left: 150px !important
    }

    .pd-sm-160 {
        padding: 160px
    }

    .pd-sm-160-f {
        padding: 160px !important
    }

    .pd-sm-y-160 {
        padding-top: 160px;
        padding-bottom: 160px
    }

    .pd-sm-y-160-f {
        padding-top: 160px !important;
        padding-bottom: 160px !important
    }

    .pd-sm-x-160 {
        padding-left: 160px;
        padding-right: 160px
    }

    .pd-sm-x-160-f {
        padding-left: 160px !important;
        padding-right: 160px !important
    }

    .pd-sm-t-160 {
        padding-top: 160px
    }

    .pd-sm-r-160 {
        padding-right: 160px
    }

    .pd-sm-b-160 {
        padding-bottom: 160px
    }

    .pd-sm-l-160 {
        padding-left: 160px
    }

    .pd-sm-t-160-f {
        padding-top: 160px !important
    }

    .pd-sm-r-160-f {
        padding-right: 160px !important
    }

    .pd-sm-b-160-f {
        padding-bottom: 160px !important
    }

    .pd-sm-l-160-f {
        padding-left: 160px !important
    }

    .pd-sm-170 {
        padding: 170px
    }

    .pd-sm-170-f {
        padding: 170px !important
    }

    .pd-sm-y-170 {
        padding-top: 170px;
        padding-bottom: 170px
    }

    .pd-sm-y-170-f {
        padding-top: 170px !important;
        padding-bottom: 170px !important
    }

    .pd-sm-x-170 {
        padding-left: 170px;
        padding-right: 170px
    }

    .pd-sm-x-170-f {
        padding-left: 170px !important;
        padding-right: 170px !important
    }

    .pd-sm-t-170 {
        padding-top: 170px
    }

    .pd-sm-r-170 {
        padding-right: 170px
    }

    .pd-sm-b-170 {
        padding-bottom: 170px
    }

    .pd-sm-l-170 {
        padding-left: 170px
    }

    .pd-sm-t-170-f {
        padding-top: 170px !important
    }

    .pd-sm-r-170-f {
        padding-right: 170px !important
    }

    .pd-sm-b-170-f {
        padding-bottom: 170px !important
    }

    .pd-sm-l-170-f {
        padding-left: 170px !important
    }

    .pd-sm-180 {
        padding: 180px
    }

    .pd-sm-180-f {
        padding: 180px !important
    }

    .pd-sm-y-180 {
        padding-top: 180px;
        padding-bottom: 180px
    }

    .pd-sm-y-180-f {
        padding-top: 180px !important;
        padding-bottom: 180px !important
    }

    .pd-sm-x-180 {
        padding-left: 180px;
        padding-right: 180px
    }

    .pd-sm-x-180-f {
        padding-left: 180px !important;
        padding-right: 180px !important
    }

    .pd-sm-t-180 {
        padding-top: 180px
    }

    .pd-sm-r-180 {
        padding-right: 180px
    }

    .pd-sm-b-180 {
        padding-bottom: 180px
    }

    .pd-sm-l-180 {
        padding-left: 180px
    }

    .pd-sm-t-180-f {
        padding-top: 180px !important
    }

    .pd-sm-r-180-f {
        padding-right: 180px !important
    }

    .pd-sm-b-180-f {
        padding-bottom: 180px !important
    }

    .pd-sm-l-180-f {
        padding-left: 180px !important
    }

    .pd-sm-190 {
        padding: 190px
    }

    .pd-sm-190-f {
        padding: 190px !important
    }

    .pd-sm-y-190 {
        padding-top: 190px;
        padding-bottom: 190px
    }

    .pd-sm-y-190-f {
        padding-top: 190px !important;
        padding-bottom: 190px !important
    }

    .pd-sm-x-190 {
        padding-left: 190px;
        padding-right: 190px
    }

    .pd-sm-x-190-f {
        padding-left: 190px !important;
        padding-right: 190px !important
    }

    .pd-sm-t-190 {
        padding-top: 190px
    }

    .pd-sm-r-190 {
        padding-right: 190px
    }

    .pd-sm-b-190 {
        padding-bottom: 190px
    }

    .pd-sm-l-190 {
        padding-left: 190px
    }

    .pd-sm-t-190-f {
        padding-top: 190px !important
    }

    .pd-sm-r-190-f {
        padding-right: 190px !important
    }

    .pd-sm-b-190-f {
        padding-bottom: 190px !important
    }

    .pd-sm-l-190-f {
        padding-left: 190px !important
    }

    .pd-sm-200 {
        padding: 200px
    }

    .pd-sm-200-f {
        padding: 200px !important
    }

    .pd-sm-y-200 {
        padding-top: 200px;
        padding-bottom: 200px
    }

    .pd-sm-y-200-f {
        padding-top: 200px !important;
        padding-bottom: 200px !important
    }

    .pd-sm-x-200 {
        padding-left: 200px;
        padding-right: 200px
    }

    .pd-sm-x-200-f {
        padding-left: 200px !important;
        padding-right: 200px !important
    }

    .pd-sm-t-200 {
        padding-top: 200px
    }

    .pd-sm-r-200 {
        padding-right: 200px
    }

    .pd-sm-b-200 {
        padding-bottom: 200px
    }

    .pd-sm-l-200 {
        padding-left: 200px
    }

    .pd-sm-t-200-f {
        padding-top: 200px !important
    }

    .pd-sm-r-200-f {
        padding-right: 200px !important
    }

    .pd-sm-b-200-f {
        padding-bottom: 200px !important
    }

    .pd-sm-l-200-f {
        padding-left: 200px !important
    }
}

@media (min-width: 768px) {
    .pd-md-0 {
        padding: 0px
    }

    .pd-md-0-f {
        padding: 0px !important
    }

    .pd-md-y-0 {
        padding-top: 0px;
        padding-bottom: 0px
    }

    .pd-md-y-0-f {
        padding-top: 0px !important;
        padding-bottom: 0px !important
    }

    .pd-md-x-0 {
        padding-left: 0px;
        padding-right: 0px
    }

    .pd-md-x-0-f {
        padding-left: 0px !important;
        padding-right: 0px !important
    }

    .pd-md-t-0 {
        padding-top: 0px
    }

    .pd-md-r-0 {
        padding-right: 0px
    }

    .pd-md-b-0 {
        padding-bottom: 0px
    }

    .pd-md-l-0 {
        padding-left: 0px
    }

    .pd-md-t-0-f {
        padding-top: 0px !important
    }

    .pd-md-r-0-f {
        padding-right: 0px !important
    }

    .pd-md-b-0-f {
        padding-bottom: 0px !important
    }

    .pd-md-l-0-f {
        padding-left: 0px !important
    }

    .pd-md-1 {
        padding: 1px
    }

    .pd-md-1-f {
        padding: 1px !important
    }

    .pd-md-y-1 {
        padding-top: 1px;
        padding-bottom: 1px
    }

    .pd-md-y-1-f {
        padding-top: 1px !important;
        padding-bottom: 1px !important
    }

    .pd-md-x-1 {
        padding-left: 1px;
        padding-right: 1px
    }

    .pd-md-x-1-f {
        padding-left: 1px !important;
        padding-right: 1px !important
    }

    .pd-md-t-1 {
        padding-top: 1px
    }

    .pd-md-r-1 {
        padding-right: 1px
    }

    .pd-md-b-1 {
        padding-bottom: 1px
    }

    .pd-md-l-1 {
        padding-left: 1px
    }

    .pd-md-t-1-f {
        padding-top: 1px !important
    }

    .pd-md-r-1-f {
        padding-right: 1px !important
    }

    .pd-md-b-1-f {
        padding-bottom: 1px !important
    }

    .pd-md-l-1-f {
        padding-left: 1px !important
    }

    .pd-md-2 {
        padding: 2px
    }

    .pd-md-2-f {
        padding: 2px !important
    }

    .pd-md-y-2 {
        padding-top: 2px;
        padding-bottom: 2px
    }

    .pd-md-y-2-f {
        padding-top: 2px !important;
        padding-bottom: 2px !important
    }

    .pd-md-x-2 {
        padding-left: 2px;
        padding-right: 2px
    }

    .pd-md-x-2-f {
        padding-left: 2px !important;
        padding-right: 2px !important
    }

    .pd-md-t-2 {
        padding-top: 2px
    }

    .pd-md-r-2 {
        padding-right: 2px
    }

    .pd-md-b-2 {
        padding-bottom: 2px
    }

    .pd-md-l-2 {
        padding-left: 2px
    }

    .pd-md-t-2-f {
        padding-top: 2px !important
    }

    .pd-md-r-2-f {
        padding-right: 2px !important
    }

    .pd-md-b-2-f {
        padding-bottom: 2px !important
    }

    .pd-md-l-2-f {
        padding-left: 2px !important
    }

    .pd-md-3 {
        padding: 3px
    }

    .pd-md-3-f {
        padding: 3px !important
    }

    .pd-md-y-3 {
        padding-top: 3px;
        padding-bottom: 3px
    }

    .pd-md-y-3-f {
        padding-top: 3px !important;
        padding-bottom: 3px !important
    }

    .pd-md-x-3 {
        padding-left: 3px;
        padding-right: 3px
    }

    .pd-md-x-3-f {
        padding-left: 3px !important;
        padding-right: 3px !important
    }

    .pd-md-t-3 {
        padding-top: 3px
    }

    .pd-md-r-3 {
        padding-right: 3px
    }

    .pd-md-b-3 {
        padding-bottom: 3px
    }

    .pd-md-l-3 {
        padding-left: 3px
    }

    .pd-md-t-3-f {
        padding-top: 3px !important
    }

    .pd-md-r-3-f {
        padding-right: 3px !important
    }

    .pd-md-b-3-f {
        padding-bottom: 3px !important
    }

    .pd-md-l-3-f {
        padding-left: 3px !important
    }

    .pd-md-4 {
        padding: 4px
    }

    .pd-md-4-f {
        padding: 4px !important
    }

    .pd-md-y-4 {
        padding-top: 4px;
        padding-bottom: 4px
    }

    .pd-md-y-4-f {
        padding-top: 4px !important;
        padding-bottom: 4px !important
    }

    .pd-md-x-4 {
        padding-left: 4px;
        padding-right: 4px
    }

    .pd-md-x-4-f {
        padding-left: 4px !important;
        padding-right: 4px !important
    }

    .pd-md-t-4 {
        padding-top: 4px
    }

    .pd-md-r-4 {
        padding-right: 4px
    }

    .pd-md-b-4 {
        padding-bottom: 4px
    }

    .pd-md-l-4 {
        padding-left: 4px
    }

    .pd-md-t-4-f {
        padding-top: 4px !important
    }

    .pd-md-r-4-f {
        padding-right: 4px !important
    }

    .pd-md-b-4-f {
        padding-bottom: 4px !important
    }

    .pd-md-l-4-f {
        padding-left: 4px !important
    }

    .pd-md-5 {
        padding: 5px
    }

    .pd-md-5-f {
        padding: 5px !important
    }

    .pd-md-y-5 {
        padding-top: 5px;
        padding-bottom: 5px
    }

    .pd-md-y-5-f {
        padding-top: 5px !important;
        padding-bottom: 5px !important
    }

    .pd-md-x-5 {
        padding-left: 5px;
        padding-right: 5px
    }

    .pd-md-x-5-f {
        padding-left: 5px !important;
        padding-right: 5px !important
    }

    .pd-md-t-5 {
        padding-top: 5px
    }

    .pd-md-r-5 {
        padding-right: 5px
    }

    .pd-md-b-5 {
        padding-bottom: 5px
    }

    .pd-md-l-5 {
        padding-left: 5px
    }

    .pd-md-t-5-f {
        padding-top: 5px !important
    }

    .pd-md-r-5-f {
        padding-right: 5px !important
    }

    .pd-md-b-5-f {
        padding-bottom: 5px !important
    }

    .pd-md-l-5-f {
        padding-left: 5px !important
    }

    .pd-md-6 {
        padding: 6px
    }

    .pd-md-6-f {
        padding: 6px !important
    }

    .pd-md-y-6 {
        padding-top: 6px;
        padding-bottom: 6px
    }

    .pd-md-y-6-f {
        padding-top: 6px !important;
        padding-bottom: 6px !important
    }

    .pd-md-x-6 {
        padding-left: 6px;
        padding-right: 6px
    }

    .pd-md-x-6-f {
        padding-left: 6px !important;
        padding-right: 6px !important
    }

    .pd-md-t-6 {
        padding-top: 6px
    }

    .pd-md-r-6 {
        padding-right: 6px
    }

    .pd-md-b-6 {
        padding-bottom: 6px
    }

    .pd-md-l-6 {
        padding-left: 6px
    }

    .pd-md-t-6-f {
        padding-top: 6px !important
    }

    .pd-md-r-6-f {
        padding-right: 6px !important
    }

    .pd-md-b-6-f {
        padding-bottom: 6px !important
    }

    .pd-md-l-6-f {
        padding-left: 6px !important
    }

    .pd-md-7 {
        padding: 7px
    }

    .pd-md-7-f {
        padding: 7px !important
    }

    .pd-md-y-7 {
        padding-top: 7px;
        padding-bottom: 7px
    }

    .pd-md-y-7-f {
        padding-top: 7px !important;
        padding-bottom: 7px !important
    }

    .pd-md-x-7 {
        padding-left: 7px;
        padding-right: 7px
    }

    .pd-md-x-7-f {
        padding-left: 7px !important;
        padding-right: 7px !important
    }

    .pd-md-t-7 {
        padding-top: 7px
    }

    .pd-md-r-7 {
        padding-right: 7px
    }

    .pd-md-b-7 {
        padding-bottom: 7px
    }

    .pd-md-l-7 {
        padding-left: 7px
    }

    .pd-md-t-7-f {
        padding-top: 7px !important
    }

    .pd-md-r-7-f {
        padding-right: 7px !important
    }

    .pd-md-b-7-f {
        padding-bottom: 7px !important
    }

    .pd-md-l-7-f {
        padding-left: 7px !important
    }

    .pd-md-8 {
        padding: 8px
    }

    .pd-md-8-f {
        padding: 8px !important
    }

    .pd-md-y-8 {
        padding-top: 8px;
        padding-bottom: 8px
    }

    .pd-md-y-8-f {
        padding-top: 8px !important;
        padding-bottom: 8px !important
    }

    .pd-md-x-8 {
        padding-left: 8px;
        padding-right: 8px
    }

    .pd-md-x-8-f {
        padding-left: 8px !important;
        padding-right: 8px !important
    }

    .pd-md-t-8 {
        padding-top: 8px
    }

    .pd-md-r-8 {
        padding-right: 8px
    }

    .pd-md-b-8 {
        padding-bottom: 8px
    }

    .pd-md-l-8 {
        padding-left: 8px
    }

    .pd-md-t-8-f {
        padding-top: 8px !important
    }

    .pd-md-r-8-f {
        padding-right: 8px !important
    }

    .pd-md-b-8-f {
        padding-bottom: 8px !important
    }

    .pd-md-l-8-f {
        padding-left: 8px !important
    }

    .pd-md-9 {
        padding: 9px
    }

    .pd-md-9-f {
        padding: 9px !important
    }

    .pd-md-y-9 {
        padding-top: 9px;
        padding-bottom: 9px
    }

    .pd-md-y-9-f {
        padding-top: 9px !important;
        padding-bottom: 9px !important
    }

    .pd-md-x-9 {
        padding-left: 9px;
        padding-right: 9px
    }

    .pd-md-x-9-f {
        padding-left: 9px !important;
        padding-right: 9px !important
    }

    .pd-md-t-9 {
        padding-top: 9px
    }

    .pd-md-r-9 {
        padding-right: 9px
    }

    .pd-md-b-9 {
        padding-bottom: 9px
    }

    .pd-md-l-9 {
        padding-left: 9px
    }

    .pd-md-t-9-f {
        padding-top: 9px !important
    }

    .pd-md-r-9-f {
        padding-right: 9px !important
    }

    .pd-md-b-9-f {
        padding-bottom: 9px !important
    }

    .pd-md-l-9-f {
        padding-left: 9px !important
    }

    .pd-md-10 {
        padding: 10px
    }

    .pd-md-10-f {
        padding: 10px !important
    }

    .pd-md-y-10 {
        padding-top: 10px;
        padding-bottom: 10px
    }

    .pd-md-y-10-f {
        padding-top: 10px !important;
        padding-bottom: 10px !important
    }

    .pd-md-x-10 {
        padding-left: 10px;
        padding-right: 10px
    }

    .pd-md-x-10-f {
        padding-left: 10px !important;
        padding-right: 10px !important
    }

    .pd-md-t-10 {
        padding-top: 10px
    }

    .pd-md-r-10 {
        padding-right: 10px
    }

    .pd-md-b-10 {
        padding-bottom: 10px
    }

    .pd-md-l-10 {
        padding-left: 10px
    }

    .pd-md-t-10-f {
        padding-top: 10px !important
    }

    .pd-md-r-10-f {
        padding-right: 10px !important
    }

    .pd-md-b-10-f {
        padding-bottom: 10px !important
    }

    .pd-md-l-10-f {
        padding-left: 10px !important
    }

    .pd-md-15 {
        padding: 15px
    }

    .pd-md-15-f {
        padding: 15px !important
    }

    .pd-md-y-15 {
        padding-top: 15px;
        padding-bottom: 15px
    }

    .pd-md-y-15-f {
        padding-top: 15px !important;
        padding-bottom: 15px !important
    }

    .pd-md-x-15 {
        padding-left: 15px;
        padding-right: 15px
    }

    .pd-md-x-15-f {
        padding-left: 15px !important;
        padding-right: 15px !important
    }

    .pd-md-t-15 {
        padding-top: 15px
    }

    .pd-md-r-15 {
        padding-right: 15px
    }

    .pd-md-b-15 {
        padding-bottom: 15px
    }

    .pd-md-l-15 {
        padding-left: 15px
    }

    .pd-md-t-15-f {
        padding-top: 15px !important
    }

    .pd-md-r-15-f {
        padding-right: 15px !important
    }

    .pd-md-b-15-f {
        padding-bottom: 15px !important
    }

    .pd-md-l-15-f {
        padding-left: 15px !important
    }

    .pd-md-20 {
        padding: 20px
    }

    .pd-md-20-f {
        padding: 20px !important
    }

    .pd-md-y-20 {
        padding-top: 20px;
        padding-bottom: 20px
    }

    .pd-md-y-20-f {
        padding-top: 20px !important;
        padding-bottom: 20px !important
    }

    .pd-md-x-20 {
        padding-left: 20px;
        padding-right: 20px
    }

    .pd-md-x-20-f {
        padding-left: 20px !important;
        padding-right: 20px !important
    }

    .pd-md-t-20 {
        padding-top: 20px
    }

    .pd-md-r-20 {
        padding-right: 20px
    }

    .pd-md-b-20 {
        padding-bottom: 20px
    }

    .pd-md-l-20 {
        padding-left: 20px
    }

    .pd-md-t-20-f {
        padding-top: 20px !important
    }

    .pd-md-r-20-f {
        padding-right: 20px !important
    }

    .pd-md-b-20-f {
        padding-bottom: 20px !important
    }

    .pd-md-l-20-f {
        padding-left: 20px !important
    }

    .pd-md-25 {
        padding: 25px
    }

    .pd-md-25-f {
        padding: 25px !important
    }

    .pd-md-y-25 {
        padding-top: 25px;
        padding-bottom: 25px
    }

    .pd-md-y-25-f {
        padding-top: 25px !important;
        padding-bottom: 25px !important
    }

    .pd-md-x-25 {
        padding-left: 25px;
        padding-right: 25px
    }

    .pd-md-x-25-f {
        padding-left: 25px !important;
        padding-right: 25px !important
    }

    .pd-md-t-25 {
        padding-top: 25px
    }

    .pd-md-r-25 {
        padding-right: 25px
    }

    .pd-md-b-25 {
        padding-bottom: 25px
    }

    .pd-md-l-25 {
        padding-left: 25px
    }

    .pd-md-t-25-f {
        padding-top: 25px !important
    }

    .pd-md-r-25-f {
        padding-right: 25px !important
    }

    .pd-md-b-25-f {
        padding-bottom: 25px !important
    }

    .pd-md-l-25-f {
        padding-left: 25px !important
    }

    .pd-md-30 {
        padding: 30px
    }

    .pd-md-30-f {
        padding: 30px !important
    }

    .pd-md-y-30 {
        padding-top: 30px;
        padding-bottom: 30px
    }

    .pd-md-y-30-f {
        padding-top: 30px !important;
        padding-bottom: 30px !important
    }

    .pd-md-x-30 {
        padding-left: 30px;
        padding-right: 30px
    }

    .pd-md-x-30-f {
        padding-left: 30px !important;
        padding-right: 30px !important
    }

    .pd-md-t-30 {
        padding-top: 30px
    }

    .pd-md-r-30 {
        padding-right: 30px
    }

    .pd-md-b-30 {
        padding-bottom: 30px
    }

    .pd-md-l-30 {
        padding-left: 30px
    }

    .pd-md-t-30-f {
        padding-top: 30px !important
    }

    .pd-md-r-30-f {
        padding-right: 30px !important
    }

    .pd-md-b-30-f {
        padding-bottom: 30px !important
    }

    .pd-md-l-30-f {
        padding-left: 30px !important
    }

    .pd-md-35 {
        padding: 35px
    }

    .pd-md-35-f {
        padding: 35px !important
    }

    .pd-md-y-35 {
        padding-top: 35px;
        padding-bottom: 35px
    }

    .pd-md-y-35-f {
        padding-top: 35px !important;
        padding-bottom: 35px !important
    }

    .pd-md-x-35 {
        padding-left: 35px;
        padding-right: 35px
    }

    .pd-md-x-35-f {
        padding-left: 35px !important;
        padding-right: 35px !important
    }

    .pd-md-t-35 {
        padding-top: 35px
    }

    .pd-md-r-35 {
        padding-right: 35px
    }

    .pd-md-b-35 {
        padding-bottom: 35px
    }

    .pd-md-l-35 {
        padding-left: 35px
    }

    .pd-md-t-35-f {
        padding-top: 35px !important
    }

    .pd-md-r-35-f {
        padding-right: 35px !important
    }

    .pd-md-b-35-f {
        padding-bottom: 35px !important
    }

    .pd-md-l-35-f {
        padding-left: 35px !important
    }

    .pd-md-40 {
        padding: 40px
    }

    .pd-md-40-f {
        padding: 40px !important
    }

    .pd-md-y-40 {
        padding-top: 40px;
        padding-bottom: 40px
    }

    .pd-md-y-40-f {
        padding-top: 40px !important;
        padding-bottom: 40px !important
    }

    .pd-md-x-40 {
        padding-left: 40px;
        padding-right: 40px
    }

    .pd-md-x-40-f {
        padding-left: 40px !important;
        padding-right: 40px !important
    }

    .pd-md-t-40 {
        padding-top: 40px
    }

    .pd-md-r-40 {
        padding-right: 40px
    }

    .pd-md-b-40 {
        padding-bottom: 40px
    }

    .pd-md-l-40 {
        padding-left: 40px
    }

    .pd-md-t-40-f {
        padding-top: 40px !important
    }

    .pd-md-r-40-f {
        padding-right: 40px !important
    }

    .pd-md-b-40-f {
        padding-bottom: 40px !important
    }

    .pd-md-l-40-f {
        padding-left: 40px !important
    }

    .pd-md-45 {
        padding: 45px
    }

    .pd-md-45-f {
        padding: 45px !important
    }

    .pd-md-y-45 {
        padding-top: 45px;
        padding-bottom: 45px
    }

    .pd-md-y-45-f {
        padding-top: 45px !important;
        padding-bottom: 45px !important
    }

    .pd-md-x-45 {
        padding-left: 45px;
        padding-right: 45px
    }

    .pd-md-x-45-f {
        padding-left: 45px !important;
        padding-right: 45px !important
    }

    .pd-md-t-45 {
        padding-top: 45px
    }

    .pd-md-r-45 {
        padding-right: 45px
    }

    .pd-md-b-45 {
        padding-bottom: 45px
    }

    .pd-md-l-45 {
        padding-left: 45px
    }

    .pd-md-t-45-f {
        padding-top: 45px !important
    }

    .pd-md-r-45-f {
        padding-right: 45px !important
    }

    .pd-md-b-45-f {
        padding-bottom: 45px !important
    }

    .pd-md-l-45-f {
        padding-left: 45px !important
    }

    .pd-md-50 {
        padding: 50px
    }

    .pd-md-50-f {
        padding: 50px !important
    }

    .pd-md-y-50 {
        padding-top: 50px;
        padding-bottom: 50px
    }

    .pd-md-y-50-f {
        padding-top: 50px !important;
        padding-bottom: 50px !important
    }

    .pd-md-x-50 {
        padding-left: 50px;
        padding-right: 50px
    }

    .pd-md-x-50-f {
        padding-left: 50px !important;
        padding-right: 50px !important
    }

    .pd-md-t-50 {
        padding-top: 50px
    }

    .pd-md-r-50 {
        padding-right: 50px
    }

    .pd-md-b-50 {
        padding-bottom: 50px
    }

    .pd-md-l-50 {
        padding-left: 50px
    }

    .pd-md-t-50-f {
        padding-top: 50px !important
    }

    .pd-md-r-50-f {
        padding-right: 50px !important
    }

    .pd-md-b-50-f {
        padding-bottom: 50px !important
    }

    .pd-md-l-50-f {
        padding-left: 50px !important
    }

    .pd-md-55 {
        padding: 55px
    }

    .pd-md-55-f {
        padding: 55px !important
    }

    .pd-md-y-55 {
        padding-top: 55px;
        padding-bottom: 55px
    }

    .pd-md-y-55-f {
        padding-top: 55px !important;
        padding-bottom: 55px !important
    }

    .pd-md-x-55 {
        padding-left: 55px;
        padding-right: 55px
    }

    .pd-md-x-55-f {
        padding-left: 55px !important;
        padding-right: 55px !important
    }

    .pd-md-t-55 {
        padding-top: 55px
    }

    .pd-md-r-55 {
        padding-right: 55px
    }

    .pd-md-b-55 {
        padding-bottom: 55px
    }

    .pd-md-l-55 {
        padding-left: 55px
    }

    .pd-md-t-55-f {
        padding-top: 55px !important
    }

    .pd-md-r-55-f {
        padding-right: 55px !important
    }

    .pd-md-b-55-f {
        padding-bottom: 55px !important
    }

    .pd-md-l-55-f {
        padding-left: 55px !important
    }

    .pd-md-60 {
        padding: 60px
    }

    .pd-md-60-f {
        padding: 60px !important
    }

    .pd-md-y-60 {
        padding-top: 60px;
        padding-bottom: 60px
    }

    .pd-md-y-60-f {
        padding-top: 60px !important;
        padding-bottom: 60px !important
    }

    .pd-md-x-60 {
        padding-left: 60px;
        padding-right: 60px
    }

    .pd-md-x-60-f {
        padding-left: 60px !important;
        padding-right: 60px !important
    }

    .pd-md-t-60 {
        padding-top: 60px
    }

    .pd-md-r-60 {
        padding-right: 60px
    }

    .pd-md-b-60 {
        padding-bottom: 60px
    }

    .pd-md-l-60 {
        padding-left: 60px
    }

    .pd-md-t-60-f {
        padding-top: 60px !important
    }

    .pd-md-r-60-f {
        padding-right: 60px !important
    }

    .pd-md-b-60-f {
        padding-bottom: 60px !important
    }

    .pd-md-l-60-f {
        padding-left: 60px !important
    }

    .pd-md-65 {
        padding: 65px
    }

    .pd-md-65-f {
        padding: 65px !important
    }

    .pd-md-y-65 {
        padding-top: 65px;
        padding-bottom: 65px
    }

    .pd-md-y-65-f {
        padding-top: 65px !important;
        padding-bottom: 65px !important
    }

    .pd-md-x-65 {
        padding-left: 65px;
        padding-right: 65px
    }

    .pd-md-x-65-f {
        padding-left: 65px !important;
        padding-right: 65px !important
    }

    .pd-md-t-65 {
        padding-top: 65px
    }

    .pd-md-r-65 {
        padding-right: 65px
    }

    .pd-md-b-65 {
        padding-bottom: 65px
    }

    .pd-md-l-65 {
        padding-left: 65px
    }

    .pd-md-t-65-f {
        padding-top: 65px !important
    }

    .pd-md-r-65-f {
        padding-right: 65px !important
    }

    .pd-md-b-65-f {
        padding-bottom: 65px !important
    }

    .pd-md-l-65-f {
        padding-left: 65px !important
    }

    .pd-md-70 {
        padding: 70px
    }

    .pd-md-70-f {
        padding: 70px !important
    }

    .pd-md-y-70 {
        padding-top: 70px;
        padding-bottom: 70px
    }

    .pd-md-y-70-f {
        padding-top: 70px !important;
        padding-bottom: 70px !important
    }

    .pd-md-x-70 {
        padding-left: 70px;
        padding-right: 70px
    }

    .pd-md-x-70-f {
        padding-left: 70px !important;
        padding-right: 70px !important
    }

    .pd-md-t-70 {
        padding-top: 70px
    }

    .pd-md-r-70 {
        padding-right: 70px
    }

    .pd-md-b-70 {
        padding-bottom: 70px
    }

    .pd-md-l-70 {
        padding-left: 70px
    }

    .pd-md-t-70-f {
        padding-top: 70px !important
    }

    .pd-md-r-70-f {
        padding-right: 70px !important
    }

    .pd-md-b-70-f {
        padding-bottom: 70px !important
    }

    .pd-md-l-70-f {
        padding-left: 70px !important
    }

    .pd-md-75 {
        padding: 75px
    }

    .pd-md-75-f {
        padding: 75px !important
    }

    .pd-md-y-75 {
        padding-top: 75px;
        padding-bottom: 75px
    }

    .pd-md-y-75-f {
        padding-top: 75px !important;
        padding-bottom: 75px !important
    }

    .pd-md-x-75 {
        padding-left: 75px;
        padding-right: 75px
    }

    .pd-md-x-75-f {
        padding-left: 75px !important;
        padding-right: 75px !important
    }

    .pd-md-t-75 {
        padding-top: 75px
    }

    .pd-md-r-75 {
        padding-right: 75px
    }

    .pd-md-b-75 {
        padding-bottom: 75px
    }

    .pd-md-l-75 {
        padding-left: 75px
    }

    .pd-md-t-75-f {
        padding-top: 75px !important
    }

    .pd-md-r-75-f {
        padding-right: 75px !important
    }

    .pd-md-b-75-f {
        padding-bottom: 75px !important
    }

    .pd-md-l-75-f {
        padding-left: 75px !important
    }

    .pd-md-80 {
        padding: 80px
    }

    .pd-md-80-f {
        padding: 80px !important
    }

    .pd-md-y-80 {
        padding-top: 80px;
        padding-bottom: 80px
    }

    .pd-md-y-80-f {
        padding-top: 80px !important;
        padding-bottom: 80px !important
    }

    .pd-md-x-80 {
        padding-left: 80px;
        padding-right: 80px
    }

    .pd-md-x-80-f {
        padding-left: 80px !important;
        padding-right: 80px !important
    }

    .pd-md-t-80 {
        padding-top: 80px
    }

    .pd-md-r-80 {
        padding-right: 80px
    }

    .pd-md-b-80 {
        padding-bottom: 80px
    }

    .pd-md-l-80 {
        padding-left: 80px
    }

    .pd-md-t-80-f {
        padding-top: 80px !important
    }

    .pd-md-r-80-f {
        padding-right: 80px !important
    }

    .pd-md-b-80-f {
        padding-bottom: 80px !important
    }

    .pd-md-l-80-f {
        padding-left: 80px !important
    }

    .pd-md-85 {
        padding: 85px
    }

    .pd-md-85-f {
        padding: 85px !important
    }

    .pd-md-y-85 {
        padding-top: 85px;
        padding-bottom: 85px
    }

    .pd-md-y-85-f {
        padding-top: 85px !important;
        padding-bottom: 85px !important
    }

    .pd-md-x-85 {
        padding-left: 85px;
        padding-right: 85px
    }

    .pd-md-x-85-f {
        padding-left: 85px !important;
        padding-right: 85px !important
    }

    .pd-md-t-85 {
        padding-top: 85px
    }

    .pd-md-r-85 {
        padding-right: 85px
    }

    .pd-md-b-85 {
        padding-bottom: 85px
    }

    .pd-md-l-85 {
        padding-left: 85px
    }

    .pd-md-t-85-f {
        padding-top: 85px !important
    }

    .pd-md-r-85-f {
        padding-right: 85px !important
    }

    .pd-md-b-85-f {
        padding-bottom: 85px !important
    }

    .pd-md-l-85-f {
        padding-left: 85px !important
    }

    .pd-md-90 {
        padding: 90px
    }

    .pd-md-90-f {
        padding: 90px !important
    }

    .pd-md-y-90 {
        padding-top: 90px;
        padding-bottom: 90px
    }

    .pd-md-y-90-f {
        padding-top: 90px !important;
        padding-bottom: 90px !important
    }

    .pd-md-x-90 {
        padding-left: 90px;
        padding-right: 90px
    }

    .pd-md-x-90-f {
        padding-left: 90px !important;
        padding-right: 90px !important
    }

    .pd-md-t-90 {
        padding-top: 90px
    }

    .pd-md-r-90 {
        padding-right: 90px
    }

    .pd-md-b-90 {
        padding-bottom: 90px
    }

    .pd-md-l-90 {
        padding-left: 90px
    }

    .pd-md-t-90-f {
        padding-top: 90px !important
    }

    .pd-md-r-90-f {
        padding-right: 90px !important
    }

    .pd-md-b-90-f {
        padding-bottom: 90px !important
    }

    .pd-md-l-90-f {
        padding-left: 90px !important
    }

    .pd-md-95 {
        padding: 95px
    }

    .pd-md-95-f {
        padding: 95px !important
    }

    .pd-md-y-95 {
        padding-top: 95px;
        padding-bottom: 95px
    }

    .pd-md-y-95-f {
        padding-top: 95px !important;
        padding-bottom: 95px !important
    }

    .pd-md-x-95 {
        padding-left: 95px;
        padding-right: 95px
    }

    .pd-md-x-95-f {
        padding-left: 95px !important;
        padding-right: 95px !important
    }

    .pd-md-t-95 {
        padding-top: 95px
    }

    .pd-md-r-95 {
        padding-right: 95px
    }

    .pd-md-b-95 {
        padding-bottom: 95px
    }

    .pd-md-l-95 {
        padding-left: 95px
    }

    .pd-md-t-95-f {
        padding-top: 95px !important
    }

    .pd-md-r-95-f {
        padding-right: 95px !important
    }

    .pd-md-b-95-f {
        padding-bottom: 95px !important
    }

    .pd-md-l-95-f {
        padding-left: 95px !important
    }

    .pd-md-100 {
        padding: 100px
    }

    .pd-md-100-f {
        padding: 100px !important
    }

    .pd-md-y-100 {
        padding-top: 100px;
        padding-bottom: 100px
    }

    .pd-md-y-100-f {
        padding-top: 100px !important;
        padding-bottom: 100px !important
    }

    .pd-md-x-100 {
        padding-left: 100px;
        padding-right: 100px
    }

    .pd-md-x-100-f {
        padding-left: 100px !important;
        padding-right: 100px !important
    }

    .pd-md-t-100 {
        padding-top: 100px
    }

    .pd-md-r-100 {
        padding-right: 100px
    }

    .pd-md-b-100 {
        padding-bottom: 100px
    }

    .pd-md-l-100 {
        padding-left: 100px
    }

    .pd-md-t-100-f {
        padding-top: 100px !important
    }

    .pd-md-r-100-f {
        padding-right: 100px !important
    }

    .pd-md-b-100-f {
        padding-bottom: 100px !important
    }

    .pd-md-l-100-f {
        padding-left: 100px !important
    }

    .pd-md-110 {
        padding: 110px
    }

    .pd-md-110-f {
        padding: 110px !important
    }

    .pd-md-y-110 {
        padding-top: 110px;
        padding-bottom: 110px
    }

    .pd-md-y-110-f {
        padding-top: 110px !important;
        padding-bottom: 110px !important
    }

    .pd-md-x-110 {
        padding-left: 110px;
        padding-right: 110px
    }

    .pd-md-x-110-f {
        padding-left: 110px !important;
        padding-right: 110px !important
    }

    .pd-md-t-110 {
        padding-top: 110px
    }

    .pd-md-r-110 {
        padding-right: 110px
    }

    .pd-md-b-110 {
        padding-bottom: 110px
    }

    .pd-md-l-110 {
        padding-left: 110px
    }

    .pd-md-t-110-f {
        padding-top: 110px !important
    }

    .pd-md-r-110-f {
        padding-right: 110px !important
    }

    .pd-md-b-110-f {
        padding-bottom: 110px !important
    }

    .pd-md-l-110-f {
        padding-left: 110px !important
    }

    .pd-md-120 {
        padding: 120px
    }

    .pd-md-120-f {
        padding: 120px !important
    }

    .pd-md-y-120 {
        padding-top: 120px;
        padding-bottom: 120px
    }

    .pd-md-y-120-f {
        padding-top: 120px !important;
        padding-bottom: 120px !important
    }

    .pd-md-x-120 {
        padding-left: 120px;
        padding-right: 120px
    }

    .pd-md-x-120-f {
        padding-left: 120px !important;
        padding-right: 120px !important
    }

    .pd-md-t-120 {
        padding-top: 120px
    }

    .pd-md-r-120 {
        padding-right: 120px
    }

    .pd-md-b-120 {
        padding-bottom: 120px
    }

    .pd-md-l-120 {
        padding-left: 120px
    }

    .pd-md-t-120-f {
        padding-top: 120px !important
    }

    .pd-md-r-120-f {
        padding-right: 120px !important
    }

    .pd-md-b-120-f {
        padding-bottom: 120px !important
    }

    .pd-md-l-120-f {
        padding-left: 120px !important
    }

    .pd-md-130 {
        padding: 130px
    }

    .pd-md-130-f {
        padding: 130px !important
    }

    .pd-md-y-130 {
        padding-top: 130px;
        padding-bottom: 130px
    }

    .pd-md-y-130-f {
        padding-top: 130px !important;
        padding-bottom: 130px !important
    }

    .pd-md-x-130 {
        padding-left: 130px;
        padding-right: 130px
    }

    .pd-md-x-130-f {
        padding-left: 130px !important;
        padding-right: 130px !important
    }

    .pd-md-t-130 {
        padding-top: 130px
    }

    .pd-md-r-130 {
        padding-right: 130px
    }

    .pd-md-b-130 {
        padding-bottom: 130px
    }

    .pd-md-l-130 {
        padding-left: 130px
    }

    .pd-md-t-130-f {
        padding-top: 130px !important
    }

    .pd-md-r-130-f {
        padding-right: 130px !important
    }

    .pd-md-b-130-f {
        padding-bottom: 130px !important
    }

    .pd-md-l-130-f {
        padding-left: 130px !important
    }

    .pd-md-140 {
        padding: 140px
    }

    .pd-md-140-f {
        padding: 140px !important
    }

    .pd-md-y-140 {
        padding-top: 140px;
        padding-bottom: 140px
    }

    .pd-md-y-140-f {
        padding-top: 140px !important;
        padding-bottom: 140px !important
    }

    .pd-md-x-140 {
        padding-left: 140px;
        padding-right: 140px
    }

    .pd-md-x-140-f {
        padding-left: 140px !important;
        padding-right: 140px !important
    }

    .pd-md-t-140 {
        padding-top: 140px
    }

    .pd-md-r-140 {
        padding-right: 140px
    }

    .pd-md-b-140 {
        padding-bottom: 140px
    }

    .pd-md-l-140 {
        padding-left: 140px
    }

    .pd-md-t-140-f {
        padding-top: 140px !important
    }

    .pd-md-r-140-f {
        padding-right: 140px !important
    }

    .pd-md-b-140-f {
        padding-bottom: 140px !important
    }

    .pd-md-l-140-f {
        padding-left: 140px !important
    }

    .pd-md-150 {
        padding: 150px
    }

    .pd-md-150-f {
        padding: 150px !important
    }

    .pd-md-y-150 {
        padding-top: 150px;
        padding-bottom: 150px
    }

    .pd-md-y-150-f {
        padding-top: 150px !important;
        padding-bottom: 150px !important
    }

    .pd-md-x-150 {
        padding-left: 150px;
        padding-right: 150px
    }

    .pd-md-x-150-f {
        padding-left: 150px !important;
        padding-right: 150px !important
    }

    .pd-md-t-150 {
        padding-top: 150px
    }

    .pd-md-r-150 {
        padding-right: 150px
    }

    .pd-md-b-150 {
        padding-bottom: 150px
    }

    .pd-md-l-150 {
        padding-left: 150px
    }

    .pd-md-t-150-f {
        padding-top: 150px !important
    }

    .pd-md-r-150-f {
        padding-right: 150px !important
    }

    .pd-md-b-150-f {
        padding-bottom: 150px !important
    }

    .pd-md-l-150-f {
        padding-left: 150px !important
    }

    .pd-md-160 {
        padding: 160px
    }

    .pd-md-160-f {
        padding: 160px !important
    }

    .pd-md-y-160 {
        padding-top: 160px;
        padding-bottom: 160px
    }

    .pd-md-y-160-f {
        padding-top: 160px !important;
        padding-bottom: 160px !important
    }

    .pd-md-x-160 {
        padding-left: 160px;
        padding-right: 160px
    }

    .pd-md-x-160-f {
        padding-left: 160px !important;
        padding-right: 160px !important
    }

    .pd-md-t-160 {
        padding-top: 160px
    }

    .pd-md-r-160 {
        padding-right: 160px
    }

    .pd-md-b-160 {
        padding-bottom: 160px
    }

    .pd-md-l-160 {
        padding-left: 160px
    }

    .pd-md-t-160-f {
        padding-top: 160px !important
    }

    .pd-md-r-160-f {
        padding-right: 160px !important
    }

    .pd-md-b-160-f {
        padding-bottom: 160px !important
    }

    .pd-md-l-160-f {
        padding-left: 160px !important
    }

    .pd-md-170 {
        padding: 170px
    }

    .pd-md-170-f {
        padding: 170px !important
    }

    .pd-md-y-170 {
        padding-top: 170px;
        padding-bottom: 170px
    }

    .pd-md-y-170-f {
        padding-top: 170px !important;
        padding-bottom: 170px !important
    }

    .pd-md-x-170 {
        padding-left: 170px;
        padding-right: 170px
    }

    .pd-md-x-170-f {
        padding-left: 170px !important;
        padding-right: 170px !important
    }

    .pd-md-t-170 {
        padding-top: 170px
    }

    .pd-md-r-170 {
        padding-right: 170px
    }

    .pd-md-b-170 {
        padding-bottom: 170px
    }

    .pd-md-l-170 {
        padding-left: 170px
    }

    .pd-md-t-170-f {
        padding-top: 170px !important
    }

    .pd-md-r-170-f {
        padding-right: 170px !important
    }

    .pd-md-b-170-f {
        padding-bottom: 170px !important
    }

    .pd-md-l-170-f {
        padding-left: 170px !important
    }

    .pd-md-180 {
        padding: 180px
    }

    .pd-md-180-f {
        padding: 180px !important
    }

    .pd-md-y-180 {
        padding-top: 180px;
        padding-bottom: 180px
    }

    .pd-md-y-180-f {
        padding-top: 180px !important;
        padding-bottom: 180px !important
    }

    .pd-md-x-180 {
        padding-left: 180px;
        padding-right: 180px
    }

    .pd-md-x-180-f {
        padding-left: 180px !important;
        padding-right: 180px !important
    }

    .pd-md-t-180 {
        padding-top: 180px
    }

    .pd-md-r-180 {
        padding-right: 180px
    }

    .pd-md-b-180 {
        padding-bottom: 180px
    }

    .pd-md-l-180 {
        padding-left: 180px
    }

    .pd-md-t-180-f {
        padding-top: 180px !important
    }

    .pd-md-r-180-f {
        padding-right: 180px !important
    }

    .pd-md-b-180-f {
        padding-bottom: 180px !important
    }

    .pd-md-l-180-f {
        padding-left: 180px !important
    }

    .pd-md-190 {
        padding: 190px
    }

    .pd-md-190-f {
        padding: 190px !important
    }

    .pd-md-y-190 {
        padding-top: 190px;
        padding-bottom: 190px
    }

    .pd-md-y-190-f {
        padding-top: 190px !important;
        padding-bottom: 190px !important
    }

    .pd-md-x-190 {
        padding-left: 190px;
        padding-right: 190px
    }

    .pd-md-x-190-f {
        padding-left: 190px !important;
        padding-right: 190px !important
    }

    .pd-md-t-190 {
        padding-top: 190px
    }

    .pd-md-r-190 {
        padding-right: 190px
    }

    .pd-md-b-190 {
        padding-bottom: 190px
    }

    .pd-md-l-190 {
        padding-left: 190px
    }

    .pd-md-t-190-f {
        padding-top: 190px !important
    }

    .pd-md-r-190-f {
        padding-right: 190px !important
    }

    .pd-md-b-190-f {
        padding-bottom: 190px !important
    }

    .pd-md-l-190-f {
        padding-left: 190px !important
    }

    .pd-md-200 {
        padding: 200px
    }

    .pd-md-200-f {
        padding: 200px !important
    }

    .pd-md-y-200 {
        padding-top: 200px;
        padding-bottom: 200px
    }

    .pd-md-y-200-f {
        padding-top: 200px !important;
        padding-bottom: 200px !important
    }

    .pd-md-x-200 {
        padding-left: 200px;
        padding-right: 200px
    }

    .pd-md-x-200-f {
        padding-left: 200px !important;
        padding-right: 200px !important
    }

    .pd-md-t-200 {
        padding-top: 200px
    }

    .pd-md-r-200 {
        padding-right: 200px
    }

    .pd-md-b-200 {
        padding-bottom: 200px
    }

    .pd-md-l-200 {
        padding-left: 200px
    }

    .pd-md-t-200-f {
        padding-top: 200px !important
    }

    .pd-md-r-200-f {
        padding-right: 200px !important
    }

    .pd-md-b-200-f {
        padding-bottom: 200px !important
    }

    .pd-md-l-200-f {
        padding-left: 200px !important
    }
}

@media (min-width: 992px) {
    .pd-lg-0 {
        padding: 0px
    }

    .pd-lg-0-f {
        padding: 0px !important
    }

    .pd-lg-y-0 {
        padding-top: 0px;
        padding-bottom: 0px
    }

    .pd-lg-y-0-f {
        padding-top: 0px !important;
        padding-bottom: 0px !important
    }

    .pd-lg-x-0 {
        padding-left: 0px;
        padding-right: 0px
    }

    .pd-lg-x-0-f {
        padding-left: 0px !important;
        padding-right: 0px !important
    }

    .pd-lg-t-0 {
        padding-top: 0px
    }

    .pd-lg-r-0 {
        padding-right: 0px
    }

    .pd-lg-b-0 {
        padding-bottom: 0px
    }

    .pd-lg-l-0 {
        padding-left: 0px
    }

    .pd-lg-t-0-f {
        padding-top: 0px !important
    }

    .pd-lg-r-0-f {
        padding-right: 0px !important
    }

    .pd-lg-b-0-f {
        padding-bottom: 0px !important
    }

    .pd-lg-l-0-f {
        padding-left: 0px !important
    }

    .pd-lg-1 {
        padding: 1px
    }

    .pd-lg-1-f {
        padding: 1px !important
    }

    .pd-lg-y-1 {
        padding-top: 1px;
        padding-bottom: 1px
    }

    .pd-lg-y-1-f {
        padding-top: 1px !important;
        padding-bottom: 1px !important
    }

    .pd-lg-x-1 {
        padding-left: 1px;
        padding-right: 1px
    }

    .pd-lg-x-1-f {
        padding-left: 1px !important;
        padding-right: 1px !important
    }

    .pd-lg-t-1 {
        padding-top: 1px
    }

    .pd-lg-r-1 {
        padding-right: 1px
    }

    .pd-lg-b-1 {
        padding-bottom: 1px
    }

    .pd-lg-l-1 {
        padding-left: 1px
    }

    .pd-lg-t-1-f {
        padding-top: 1px !important
    }

    .pd-lg-r-1-f {
        padding-right: 1px !important
    }

    .pd-lg-b-1-f {
        padding-bottom: 1px !important
    }

    .pd-lg-l-1-f {
        padding-left: 1px !important
    }

    .pd-lg-2 {
        padding: 2px
    }

    .pd-lg-2-f {
        padding: 2px !important
    }

    .pd-lg-y-2 {
        padding-top: 2px;
        padding-bottom: 2px
    }

    .pd-lg-y-2-f {
        padding-top: 2px !important;
        padding-bottom: 2px !important
    }

    .pd-lg-x-2 {
        padding-left: 2px;
        padding-right: 2px
    }

    .pd-lg-x-2-f {
        padding-left: 2px !important;
        padding-right: 2px !important
    }

    .pd-lg-t-2 {
        padding-top: 2px
    }

    .pd-lg-r-2 {
        padding-right: 2px
    }

    .pd-lg-b-2 {
        padding-bottom: 2px
    }

    .pd-lg-l-2 {
        padding-left: 2px
    }

    .pd-lg-t-2-f {
        padding-top: 2px !important
    }

    .pd-lg-r-2-f {
        padding-right: 2px !important
    }

    .pd-lg-b-2-f {
        padding-bottom: 2px !important
    }

    .pd-lg-l-2-f {
        padding-left: 2px !important
    }

    .pd-lg-3 {
        padding: 3px
    }

    .pd-lg-3-f {
        padding: 3px !important
    }

    .pd-lg-y-3 {
        padding-top: 3px;
        padding-bottom: 3px
    }

    .pd-lg-y-3-f {
        padding-top: 3px !important;
        padding-bottom: 3px !important
    }

    .pd-lg-x-3 {
        padding-left: 3px;
        padding-right: 3px
    }

    .pd-lg-x-3-f {
        padding-left: 3px !important;
        padding-right: 3px !important
    }

    .pd-lg-t-3 {
        padding-top: 3px
    }

    .pd-lg-r-3 {
        padding-right: 3px
    }

    .pd-lg-b-3 {
        padding-bottom: 3px
    }

    .pd-lg-l-3 {
        padding-left: 3px
    }

    .pd-lg-t-3-f {
        padding-top: 3px !important
    }

    .pd-lg-r-3-f {
        padding-right: 3px !important
    }

    .pd-lg-b-3-f {
        padding-bottom: 3px !important
    }

    .pd-lg-l-3-f {
        padding-left: 3px !important
    }

    .pd-lg-4 {
        padding: 4px
    }

    .pd-lg-4-f {
        padding: 4px !important
    }

    .pd-lg-y-4 {
        padding-top: 4px;
        padding-bottom: 4px
    }

    .pd-lg-y-4-f {
        padding-top: 4px !important;
        padding-bottom: 4px !important
    }

    .pd-lg-x-4 {
        padding-left: 4px;
        padding-right: 4px
    }

    .pd-lg-x-4-f {
        padding-left: 4px !important;
        padding-right: 4px !important
    }

    .pd-lg-t-4 {
        padding-top: 4px
    }

    .pd-lg-r-4 {
        padding-right: 4px
    }

    .pd-lg-b-4 {
        padding-bottom: 4px
    }

    .pd-lg-l-4 {
        padding-left: 4px
    }

    .pd-lg-t-4-f {
        padding-top: 4px !important
    }

    .pd-lg-r-4-f {
        padding-right: 4px !important
    }

    .pd-lg-b-4-f {
        padding-bottom: 4px !important
    }

    .pd-lg-l-4-f {
        padding-left: 4px !important
    }

    .pd-lg-5 {
        padding: 5px
    }

    .pd-lg-5-f {
        padding: 5px !important
    }

    .pd-lg-y-5 {
        padding-top: 5px;
        padding-bottom: 5px
    }

    .pd-lg-y-5-f {
        padding-top: 5px !important;
        padding-bottom: 5px !important
    }

    .pd-lg-x-5 {
        padding-left: 5px;
        padding-right: 5px
    }

    .pd-lg-x-5-f {
        padding-left: 5px !important;
        padding-right: 5px !important
    }

    .pd-lg-t-5 {
        padding-top: 5px
    }

    .pd-lg-r-5 {
        padding-right: 5px
    }

    .pd-lg-b-5 {
        padding-bottom: 5px
    }

    .pd-lg-l-5 {
        padding-left: 5px
    }

    .pd-lg-t-5-f {
        padding-top: 5px !important
    }

    .pd-lg-r-5-f {
        padding-right: 5px !important
    }

    .pd-lg-b-5-f {
        padding-bottom: 5px !important
    }

    .pd-lg-l-5-f {
        padding-left: 5px !important
    }

    .pd-lg-6 {
        padding: 6px
    }

    .pd-lg-6-f {
        padding: 6px !important
    }

    .pd-lg-y-6 {
        padding-top: 6px;
        padding-bottom: 6px
    }

    .pd-lg-y-6-f {
        padding-top: 6px !important;
        padding-bottom: 6px !important
    }

    .pd-lg-x-6 {
        padding-left: 6px;
        padding-right: 6px
    }

    .pd-lg-x-6-f {
        padding-left: 6px !important;
        padding-right: 6px !important
    }

    .pd-lg-t-6 {
        padding-top: 6px
    }

    .pd-lg-r-6 {
        padding-right: 6px
    }

    .pd-lg-b-6 {
        padding-bottom: 6px
    }

    .pd-lg-l-6 {
        padding-left: 6px
    }

    .pd-lg-t-6-f {
        padding-top: 6px !important
    }

    .pd-lg-r-6-f {
        padding-right: 6px !important
    }

    .pd-lg-b-6-f {
        padding-bottom: 6px !important
    }

    .pd-lg-l-6-f {
        padding-left: 6px !important
    }

    .pd-lg-7 {
        padding: 7px
    }

    .pd-lg-7-f {
        padding: 7px !important
    }

    .pd-lg-y-7 {
        padding-top: 7px;
        padding-bottom: 7px
    }

    .pd-lg-y-7-f {
        padding-top: 7px !important;
        padding-bottom: 7px !important
    }

    .pd-lg-x-7 {
        padding-left: 7px;
        padding-right: 7px
    }

    .pd-lg-x-7-f {
        padding-left: 7px !important;
        padding-right: 7px !important
    }

    .pd-lg-t-7 {
        padding-top: 7px
    }

    .pd-lg-r-7 {
        padding-right: 7px
    }

    .pd-lg-b-7 {
        padding-bottom: 7px
    }

    .pd-lg-l-7 {
        padding-left: 7px
    }

    .pd-lg-t-7-f {
        padding-top: 7px !important
    }

    .pd-lg-r-7-f {
        padding-right: 7px !important
    }

    .pd-lg-b-7-f {
        padding-bottom: 7px !important
    }

    .pd-lg-l-7-f {
        padding-left: 7px !important
    }

    .pd-lg-8 {
        padding: 8px
    }

    .pd-lg-8-f {
        padding: 8px !important
    }

    .pd-lg-y-8 {
        padding-top: 8px;
        padding-bottom: 8px
    }

    .pd-lg-y-8-f {
        padding-top: 8px !important;
        padding-bottom: 8px !important
    }

    .pd-lg-x-8 {
        padding-left: 8px;
        padding-right: 8px
    }

    .pd-lg-x-8-f {
        padding-left: 8px !important;
        padding-right: 8px !important
    }

    .pd-lg-t-8 {
        padding-top: 8px
    }

    .pd-lg-r-8 {
        padding-right: 8px
    }

    .pd-lg-b-8 {
        padding-bottom: 8px
    }

    .pd-lg-l-8 {
        padding-left: 8px
    }

    .pd-lg-t-8-f {
        padding-top: 8px !important
    }

    .pd-lg-r-8-f {
        padding-right: 8px !important
    }

    .pd-lg-b-8-f {
        padding-bottom: 8px !important
    }

    .pd-lg-l-8-f {
        padding-left: 8px !important
    }

    .pd-lg-9 {
        padding: 9px
    }

    .pd-lg-9-f {
        padding: 9px !important
    }

    .pd-lg-y-9 {
        padding-top: 9px;
        padding-bottom: 9px
    }

    .pd-lg-y-9-f {
        padding-top: 9px !important;
        padding-bottom: 9px !important
    }

    .pd-lg-x-9 {
        padding-left: 9px;
        padding-right: 9px
    }

    .pd-lg-x-9-f {
        padding-left: 9px !important;
        padding-right: 9px !important
    }

    .pd-lg-t-9 {
        padding-top: 9px
    }

    .pd-lg-r-9 {
        padding-right: 9px
    }

    .pd-lg-b-9 {
        padding-bottom: 9px
    }

    .pd-lg-l-9 {
        padding-left: 9px
    }

    .pd-lg-t-9-f {
        padding-top: 9px !important
    }

    .pd-lg-r-9-f {
        padding-right: 9px !important
    }

    .pd-lg-b-9-f {
        padding-bottom: 9px !important
    }

    .pd-lg-l-9-f {
        padding-left: 9px !important
    }

    .pd-lg-10 {
        padding: 10px
    }

    .pd-lg-10-f {
        padding: 10px !important
    }

    .pd-lg-y-10 {
        padding-top: 10px;
        padding-bottom: 10px
    }

    .pd-lg-y-10-f {
        padding-top: 10px !important;
        padding-bottom: 10px !important
    }

    .pd-lg-x-10 {
        padding-left: 10px;
        padding-right: 10px
    }

    .pd-lg-x-10-f {
        padding-left: 10px !important;
        padding-right: 10px !important
    }

    .pd-lg-t-10 {
        padding-top: 10px
    }

    .pd-lg-r-10 {
        padding-right: 10px
    }

    .pd-lg-b-10 {
        padding-bottom: 10px
    }

    .pd-lg-l-10 {
        padding-left: 10px
    }

    .pd-lg-t-10-f {
        padding-top: 10px !important
    }

    .pd-lg-r-10-f {
        padding-right: 10px !important
    }

    .pd-lg-b-10-f {
        padding-bottom: 10px !important
    }

    .pd-lg-l-10-f {
        padding-left: 10px !important
    }

    .pd-lg-15 {
        padding: 15px
    }

    .pd-lg-15-f {
        padding: 15px !important
    }

    .pd-lg-y-15 {
        padding-top: 15px;
        padding-bottom: 15px
    }

    .pd-lg-y-15-f {
        padding-top: 15px !important;
        padding-bottom: 15px !important
    }

    .pd-lg-x-15 {
        padding-left: 15px;
        padding-right: 15px
    }

    .pd-lg-x-15-f {
        padding-left: 15px !important;
        padding-right: 15px !important
    }

    .pd-lg-t-15 {
        padding-top: 15px
    }

    .pd-lg-r-15 {
        padding-right: 15px
    }

    .pd-lg-b-15 {
        padding-bottom: 15px
    }

    .pd-lg-l-15 {
        padding-left: 15px
    }

    .pd-lg-t-15-f {
        padding-top: 15px !important
    }

    .pd-lg-r-15-f {
        padding-right: 15px !important
    }

    .pd-lg-b-15-f {
        padding-bottom: 15px !important
    }

    .pd-lg-l-15-f {
        padding-left: 15px !important
    }

    .pd-lg-20 {
        padding: 20px
    }

    .pd-lg-20-f {
        padding: 20px !important
    }

    .pd-lg-y-20 {
        padding-top: 20px;
        padding-bottom: 20px
    }

    .pd-lg-y-20-f {
        padding-top: 20px !important;
        padding-bottom: 20px !important
    }

    .pd-lg-x-20 {
        padding-left: 20px;
        padding-right: 20px
    }

    .pd-lg-x-20-f {
        padding-left: 20px !important;
        padding-right: 20px !important
    }

    .pd-lg-t-20 {
        padding-top: 20px
    }

    .pd-lg-r-20 {
        padding-right: 20px
    }

    .pd-lg-b-20 {
        padding-bottom: 20px
    }

    .pd-lg-l-20 {
        padding-left: 20px
    }

    .pd-lg-t-20-f {
        padding-top: 20px !important
    }

    .pd-lg-r-20-f {
        padding-right: 20px !important
    }

    .pd-lg-b-20-f {
        padding-bottom: 20px !important
    }

    .pd-lg-l-20-f {
        padding-left: 20px !important
    }

    .pd-lg-25 {
        padding: 25px
    }

    .pd-lg-25-f {
        padding: 25px !important
    }

    .pd-lg-y-25 {
        padding-top: 25px;
        padding-bottom: 25px
    }

    .pd-lg-y-25-f {
        padding-top: 25px !important;
        padding-bottom: 25px !important
    }

    .pd-lg-x-25 {
        padding-left: 25px;
        padding-right: 25px
    }

    .pd-lg-x-25-f {
        padding-left: 25px !important;
        padding-right: 25px !important
    }

    .pd-lg-t-25 {
        padding-top: 25px
    }

    .pd-lg-r-25 {
        padding-right: 25px
    }

    .pd-lg-b-25 {
        padding-bottom: 25px
    }

    .pd-lg-l-25 {
        padding-left: 25px
    }

    .pd-lg-t-25-f {
        padding-top: 25px !important
    }

    .pd-lg-r-25-f {
        padding-right: 25px !important
    }

    .pd-lg-b-25-f {
        padding-bottom: 25px !important
    }

    .pd-lg-l-25-f {
        padding-left: 25px !important
    }

    .pd-lg-30 {
        padding: 30px
    }

    .pd-lg-30-f {
        padding: 30px !important
    }

    .pd-lg-y-30 {
        padding-top: 30px;
        padding-bottom: 30px
    }

    .pd-lg-y-30-f {
        padding-top: 30px !important;
        padding-bottom: 30px !important
    }

    .pd-lg-x-30 {
        padding-left: 30px;
        padding-right: 30px
    }

    .pd-lg-x-30-f {
        padding-left: 30px !important;
        padding-right: 30px !important
    }

    .pd-lg-t-30 {
        padding-top: 30px
    }

    .pd-lg-r-30 {
        padding-right: 30px
    }

    .pd-lg-b-30 {
        padding-bottom: 30px
    }

    .pd-lg-l-30 {
        padding-left: 30px
    }

    .pd-lg-t-30-f {
        padding-top: 30px !important
    }

    .pd-lg-r-30-f {
        padding-right: 30px !important
    }

    .pd-lg-b-30-f {
        padding-bottom: 30px !important
    }

    .pd-lg-l-30-f {
        padding-left: 30px !important
    }

    .pd-lg-35 {
        padding: 35px
    }

    .pd-lg-35-f {
        padding: 35px !important
    }

    .pd-lg-y-35 {
        padding-top: 35px;
        padding-bottom: 35px
    }

    .pd-lg-y-35-f {
        padding-top: 35px !important;
        padding-bottom: 35px !important
    }

    .pd-lg-x-35 {
        padding-left: 35px;
        padding-right: 35px
    }

    .pd-lg-x-35-f {
        padding-left: 35px !important;
        padding-right: 35px !important
    }

    .pd-lg-t-35 {
        padding-top: 35px
    }

    .pd-lg-r-35 {
        padding-right: 35px
    }

    .pd-lg-b-35 {
        padding-bottom: 35px
    }

    .pd-lg-l-35 {
        padding-left: 35px
    }

    .pd-lg-t-35-f {
        padding-top: 35px !important
    }

    .pd-lg-r-35-f {
        padding-right: 35px !important
    }

    .pd-lg-b-35-f {
        padding-bottom: 35px !important
    }

    .pd-lg-l-35-f {
        padding-left: 35px !important
    }

    .pd-lg-40 {
        padding: 40px
    }

    .pd-lg-40-f {
        padding: 40px !important
    }

    .pd-lg-y-40 {
        padding-top: 40px;
        padding-bottom: 40px
    }

    .pd-lg-y-40-f {
        padding-top: 40px !important;
        padding-bottom: 40px !important
    }

    .pd-lg-x-40 {
        padding-left: 40px;
        padding-right: 40px
    }

    .pd-lg-x-40-f {
        padding-left: 40px !important;
        padding-right: 40px !important
    }

    .pd-lg-t-40 {
        padding-top: 40px
    }

    .pd-lg-r-40 {
        padding-right: 40px
    }

    .pd-lg-b-40 {
        padding-bottom: 40px
    }

    .pd-lg-l-40 {
        padding-left: 40px
    }

    .pd-lg-t-40-f {
        padding-top: 40px !important
    }

    .pd-lg-r-40-f {
        padding-right: 40px !important
    }

    .pd-lg-b-40-f {
        padding-bottom: 40px !important
    }

    .pd-lg-l-40-f {
        padding-left: 40px !important
    }

    .pd-lg-45 {
        padding: 45px
    }

    .pd-lg-45-f {
        padding: 45px !important
    }

    .pd-lg-y-45 {
        padding-top: 45px;
        padding-bottom: 45px
    }

    .pd-lg-y-45-f {
        padding-top: 45px !important;
        padding-bottom: 45px !important
    }

    .pd-lg-x-45 {
        padding-left: 45px;
        padding-right: 45px
    }

    .pd-lg-x-45-f {
        padding-left: 45px !important;
        padding-right: 45px !important
    }

    .pd-lg-t-45 {
        padding-top: 45px
    }

    .pd-lg-r-45 {
        padding-right: 45px
    }

    .pd-lg-b-45 {
        padding-bottom: 45px
    }

    .pd-lg-l-45 {
        padding-left: 45px
    }

    .pd-lg-t-45-f {
        padding-top: 45px !important
    }

    .pd-lg-r-45-f {
        padding-right: 45px !important
    }

    .pd-lg-b-45-f {
        padding-bottom: 45px !important
    }

    .pd-lg-l-45-f {
        padding-left: 45px !important
    }

    .pd-lg-50 {
        padding: 50px
    }

    .pd-lg-50-f {
        padding: 50px !important
    }

    .pd-lg-y-50 {
        padding-top: 50px;
        padding-bottom: 50px
    }

    .pd-lg-y-50-f {
        padding-top: 50px !important;
        padding-bottom: 50px !important
    }

    .pd-lg-x-50 {
        padding-left: 50px;
        padding-right: 50px
    }

    .pd-lg-x-50-f {
        padding-left: 50px !important;
        padding-right: 50px !important
    }

    .pd-lg-t-50 {
        padding-top: 50px
    }

    .pd-lg-r-50 {
        padding-right: 50px
    }

    .pd-lg-b-50 {
        padding-bottom: 50px
    }

    .pd-lg-l-50 {
        padding-left: 50px
    }

    .pd-lg-t-50-f {
        padding-top: 50px !important
    }

    .pd-lg-r-50-f {
        padding-right: 50px !important
    }

    .pd-lg-b-50-f {
        padding-bottom: 50px !important
    }

    .pd-lg-l-50-f {
        padding-left: 50px !important
    }

    .pd-lg-55 {
        padding: 55px
    }

    .pd-lg-55-f {
        padding: 55px !important
    }

    .pd-lg-y-55 {
        padding-top: 55px;
        padding-bottom: 55px
    }

    .pd-lg-y-55-f {
        padding-top: 55px !important;
        padding-bottom: 55px !important
    }

    .pd-lg-x-55 {
        padding-left: 55px;
        padding-right: 55px
    }

    .pd-lg-x-55-f {
        padding-left: 55px !important;
        padding-right: 55px !important
    }

    .pd-lg-t-55 {
        padding-top: 55px
    }

    .pd-lg-r-55 {
        padding-right: 55px
    }

    .pd-lg-b-55 {
        padding-bottom: 55px
    }

    .pd-lg-l-55 {
        padding-left: 55px
    }

    .pd-lg-t-55-f {
        padding-top: 55px !important
    }

    .pd-lg-r-55-f {
        padding-right: 55px !important
    }

    .pd-lg-b-55-f {
        padding-bottom: 55px !important
    }

    .pd-lg-l-55-f {
        padding-left: 55px !important
    }

    .pd-lg-60 {
        padding: 60px
    }

    .pd-lg-60-f {
        padding: 60px !important
    }

    .pd-lg-y-60 {
        padding-top: 60px;
        padding-bottom: 60px
    }

    .pd-lg-y-60-f {
        padding-top: 60px !important;
        padding-bottom: 60px !important
    }

    .pd-lg-x-60 {
        padding-left: 60px;
        padding-right: 60px
    }

    .pd-lg-x-60-f {
        padding-left: 60px !important;
        padding-right: 60px !important
    }

    .pd-lg-t-60 {
        padding-top: 60px
    }

    .pd-lg-r-60 {
        padding-right: 60px
    }

    .pd-lg-b-60 {
        padding-bottom: 60px
    }

    .pd-lg-l-60 {
        padding-left: 60px
    }

    .pd-lg-t-60-f {
        padding-top: 60px !important
    }

    .pd-lg-r-60-f {
        padding-right: 60px !important
    }

    .pd-lg-b-60-f {
        padding-bottom: 60px !important
    }

    .pd-lg-l-60-f {
        padding-left: 60px !important
    }

    .pd-lg-65 {
        padding: 65px
    }

    .pd-lg-65-f {
        padding: 65px !important
    }

    .pd-lg-y-65 {
        padding-top: 65px;
        padding-bottom: 65px
    }

    .pd-lg-y-65-f {
        padding-top: 65px !important;
        padding-bottom: 65px !important
    }

    .pd-lg-x-65 {
        padding-left: 65px;
        padding-right: 65px
    }

    .pd-lg-x-65-f {
        padding-left: 65px !important;
        padding-right: 65px !important
    }

    .pd-lg-t-65 {
        padding-top: 65px
    }

    .pd-lg-r-65 {
        padding-right: 65px
    }

    .pd-lg-b-65 {
        padding-bottom: 65px
    }

    .pd-lg-l-65 {
        padding-left: 65px
    }

    .pd-lg-t-65-f {
        padding-top: 65px !important
    }

    .pd-lg-r-65-f {
        padding-right: 65px !important
    }

    .pd-lg-b-65-f {
        padding-bottom: 65px !important
    }

    .pd-lg-l-65-f {
        padding-left: 65px !important
    }

    .pd-lg-70 {
        padding: 70px
    }

    .pd-lg-70-f {
        padding: 70px !important
    }

    .pd-lg-y-70 {
        padding-top: 70px;
        padding-bottom: 70px
    }

    .pd-lg-y-70-f {
        padding-top: 70px !important;
        padding-bottom: 70px !important
    }

    .pd-lg-x-70 {
        padding-left: 70px;
        padding-right: 70px
    }

    .pd-lg-x-70-f {
        padding-left: 70px !important;
        padding-right: 70px !important
    }

    .pd-lg-t-70 {
        padding-top: 70px
    }

    .pd-lg-r-70 {
        padding-right: 70px
    }

    .pd-lg-b-70 {
        padding-bottom: 70px
    }

    .pd-lg-l-70 {
        padding-left: 70px
    }

    .pd-lg-t-70-f {
        padding-top: 70px !important
    }

    .pd-lg-r-70-f {
        padding-right: 70px !important
    }

    .pd-lg-b-70-f {
        padding-bottom: 70px !important
    }

    .pd-lg-l-70-f {
        padding-left: 70px !important
    }

    .pd-lg-75 {
        padding: 75px
    }

    .pd-lg-75-f {
        padding: 75px !important
    }

    .pd-lg-y-75 {
        padding-top: 75px;
        padding-bottom: 75px
    }

    .pd-lg-y-75-f {
        padding-top: 75px !important;
        padding-bottom: 75px !important
    }

    .pd-lg-x-75 {
        padding-left: 75px;
        padding-right: 75px
    }

    .pd-lg-x-75-f {
        padding-left: 75px !important;
        padding-right: 75px !important
    }

    .pd-lg-t-75 {
        padding-top: 75px
    }

    .pd-lg-r-75 {
        padding-right: 75px
    }

    .pd-lg-b-75 {
        padding-bottom: 75px
    }

    .pd-lg-l-75 {
        padding-left: 75px
    }

    .pd-lg-t-75-f {
        padding-top: 75px !important
    }

    .pd-lg-r-75-f {
        padding-right: 75px !important
    }

    .pd-lg-b-75-f {
        padding-bottom: 75px !important
    }

    .pd-lg-l-75-f {
        padding-left: 75px !important
    }

    .pd-lg-80 {
        padding: 80px
    }

    .pd-lg-80-f {
        padding: 80px !important
    }

    .pd-lg-y-80 {
        padding-top: 80px;
        padding-bottom: 80px
    }

    .pd-lg-y-80-f {
        padding-top: 80px !important;
        padding-bottom: 80px !important
    }

    .pd-lg-x-80 {
        padding-left: 80px;
        padding-right: 80px
    }

    .pd-lg-x-80-f {
        padding-left: 80px !important;
        padding-right: 80px !important
    }

    .pd-lg-t-80 {
        padding-top: 80px
    }

    .pd-lg-r-80 {
        padding-right: 80px
    }

    .pd-lg-b-80 {
        padding-bottom: 80px
    }

    .pd-lg-l-80 {
        padding-left: 80px
    }

    .pd-lg-t-80-f {
        padding-top: 80px !important
    }

    .pd-lg-r-80-f {
        padding-right: 80px !important
    }

    .pd-lg-b-80-f {
        padding-bottom: 80px !important
    }

    .pd-lg-l-80-f {
        padding-left: 80px !important
    }

    .pd-lg-85 {
        padding: 85px
    }

    .pd-lg-85-f {
        padding: 85px !important
    }

    .pd-lg-y-85 {
        padding-top: 85px;
        padding-bottom: 85px
    }

    .pd-lg-y-85-f {
        padding-top: 85px !important;
        padding-bottom: 85px !important
    }

    .pd-lg-x-85 {
        padding-left: 85px;
        padding-right: 85px
    }

    .pd-lg-x-85-f {
        padding-left: 85px !important;
        padding-right: 85px !important
    }

    .pd-lg-t-85 {
        padding-top: 85px
    }

    .pd-lg-r-85 {
        padding-right: 85px
    }

    .pd-lg-b-85 {
        padding-bottom: 85px
    }

    .pd-lg-l-85 {
        padding-left: 85px
    }

    .pd-lg-t-85-f {
        padding-top: 85px !important
    }

    .pd-lg-r-85-f {
        padding-right: 85px !important
    }

    .pd-lg-b-85-f {
        padding-bottom: 85px !important
    }

    .pd-lg-l-85-f {
        padding-left: 85px !important
    }

    .pd-lg-90 {
        padding: 90px
    }

    .pd-lg-90-f {
        padding: 90px !important
    }

    .pd-lg-y-90 {
        padding-top: 90px;
        padding-bottom: 90px
    }

    .pd-lg-y-90-f {
        padding-top: 90px !important;
        padding-bottom: 90px !important
    }

    .pd-lg-x-90 {
        padding-left: 90px;
        padding-right: 90px
    }

    .pd-lg-x-90-f {
        padding-left: 90px !important;
        padding-right: 90px !important
    }

    .pd-lg-t-90 {
        padding-top: 90px
    }

    .pd-lg-r-90 {
        padding-right: 90px
    }

    .pd-lg-b-90 {
        padding-bottom: 90px
    }

    .pd-lg-l-90 {
        padding-left: 90px
    }

    .pd-lg-t-90-f {
        padding-top: 90px !important
    }

    .pd-lg-r-90-f {
        padding-right: 90px !important
    }

    .pd-lg-b-90-f {
        padding-bottom: 90px !important
    }

    .pd-lg-l-90-f {
        padding-left: 90px !important
    }

    .pd-lg-95 {
        padding: 95px
    }

    .pd-lg-95-f {
        padding: 95px !important
    }

    .pd-lg-y-95 {
        padding-top: 95px;
        padding-bottom: 95px
    }

    .pd-lg-y-95-f {
        padding-top: 95px !important;
        padding-bottom: 95px !important
    }

    .pd-lg-x-95 {
        padding-left: 95px;
        padding-right: 95px
    }

    .pd-lg-x-95-f {
        padding-left: 95px !important;
        padding-right: 95px !important
    }

    .pd-lg-t-95 {
        padding-top: 95px
    }

    .pd-lg-r-95 {
        padding-right: 95px
    }

    .pd-lg-b-95 {
        padding-bottom: 95px
    }

    .pd-lg-l-95 {
        padding-left: 95px
    }

    .pd-lg-t-95-f {
        padding-top: 95px !important
    }

    .pd-lg-r-95-f {
        padding-right: 95px !important
    }

    .pd-lg-b-95-f {
        padding-bottom: 95px !important
    }

    .pd-lg-l-95-f {
        padding-left: 95px !important
    }

    .pd-lg-100 {
        padding: 100px
    }

    .pd-lg-100-f {
        padding: 100px !important
    }

    .pd-lg-y-100 {
        padding-top: 100px;
        padding-bottom: 100px
    }

    .pd-lg-y-100-f {
        padding-top: 100px !important;
        padding-bottom: 100px !important
    }

    .pd-lg-x-100 {
        padding-left: 100px;
        padding-right: 100px
    }

    .pd-lg-x-100-f {
        padding-left: 100px !important;
        padding-right: 100px !important
    }

    .pd-lg-t-100 {
        padding-top: 100px
    }

    .pd-lg-r-100 {
        padding-right: 100px
    }

    .pd-lg-b-100 {
        padding-bottom: 100px
    }

    .pd-lg-l-100 {
        padding-left: 100px
    }

    .pd-lg-t-100-f {
        padding-top: 100px !important
    }

    .pd-lg-r-100-f {
        padding-right: 100px !important
    }

    .pd-lg-b-100-f {
        padding-bottom: 100px !important
    }

    .pd-lg-l-100-f {
        padding-left: 100px !important
    }

    .pd-lg-110 {
        padding: 110px
    }

    .pd-lg-110-f {
        padding: 110px !important
    }

    .pd-lg-y-110 {
        padding-top: 110px;
        padding-bottom: 110px
    }

    .pd-lg-y-110-f {
        padding-top: 110px !important;
        padding-bottom: 110px !important
    }

    .pd-lg-x-110 {
        padding-left: 110px;
        padding-right: 110px
    }

    .pd-lg-x-110-f {
        padding-left: 110px !important;
        padding-right: 110px !important
    }

    .pd-lg-t-110 {
        padding-top: 110px
    }

    .pd-lg-r-110 {
        padding-right: 110px
    }

    .pd-lg-b-110 {
        padding-bottom: 110px
    }

    .pd-lg-l-110 {
        padding-left: 110px
    }

    .pd-lg-t-110-f {
        padding-top: 110px !important
    }

    .pd-lg-r-110-f {
        padding-right: 110px !important
    }

    .pd-lg-b-110-f {
        padding-bottom: 110px !important
    }

    .pd-lg-l-110-f {
        padding-left: 110px !important
    }

    .pd-lg-120 {
        padding: 120px
    }

    .pd-lg-120-f {
        padding: 120px !important
    }

    .pd-lg-y-120 {
        padding-top: 120px;
        padding-bottom: 120px
    }

    .pd-lg-y-120-f {
        padding-top: 120px !important;
        padding-bottom: 120px !important
    }

    .pd-lg-x-120 {
        padding-left: 120px;
        padding-right: 120px
    }

    .pd-lg-x-120-f {
        padding-left: 120px !important;
        padding-right: 120px !important
    }

    .pd-lg-t-120 {
        padding-top: 120px
    }

    .pd-lg-r-120 {
        padding-right: 120px
    }

    .pd-lg-b-120 {
        padding-bottom: 120px
    }

    .pd-lg-l-120 {
        padding-left: 120px
    }

    .pd-lg-t-120-f {
        padding-top: 120px !important
    }

    .pd-lg-r-120-f {
        padding-right: 120px !important
    }

    .pd-lg-b-120-f {
        padding-bottom: 120px !important
    }

    .pd-lg-l-120-f {
        padding-left: 120px !important
    }

    .pd-lg-130 {
        padding: 130px
    }

    .pd-lg-130-f {
        padding: 130px !important
    }

    .pd-lg-y-130 {
        padding-top: 130px;
        padding-bottom: 130px
    }

    .pd-lg-y-130-f {
        padding-top: 130px !important;
        padding-bottom: 130px !important
    }

    .pd-lg-x-130 {
        padding-left: 130px;
        padding-right: 130px
    }

    .pd-lg-x-130-f {
        padding-left: 130px !important;
        padding-right: 130px !important
    }

    .pd-lg-t-130 {
        padding-top: 130px
    }

    .pd-lg-r-130 {
        padding-right: 130px
    }

    .pd-lg-b-130 {
        padding-bottom: 130px
    }

    .pd-lg-l-130 {
        padding-left: 130px
    }

    .pd-lg-t-130-f {
        padding-top: 130px !important
    }

    .pd-lg-r-130-f {
        padding-right: 130px !important
    }

    .pd-lg-b-130-f {
        padding-bottom: 130px !important
    }

    .pd-lg-l-130-f {
        padding-left: 130px !important
    }

    .pd-lg-140 {
        padding: 140px
    }

    .pd-lg-140-f {
        padding: 140px !important
    }

    .pd-lg-y-140 {
        padding-top: 140px;
        padding-bottom: 140px
    }

    .pd-lg-y-140-f {
        padding-top: 140px !important;
        padding-bottom: 140px !important
    }

    .pd-lg-x-140 {
        padding-left: 140px;
        padding-right: 140px
    }

    .pd-lg-x-140-f {
        padding-left: 140px !important;
        padding-right: 140px !important
    }

    .pd-lg-t-140 {
        padding-top: 140px
    }

    .pd-lg-r-140 {
        padding-right: 140px
    }

    .pd-lg-b-140 {
        padding-bottom: 140px
    }

    .pd-lg-l-140 {
        padding-left: 140px
    }

    .pd-lg-t-140-f {
        padding-top: 140px !important
    }

    .pd-lg-r-140-f {
        padding-right: 140px !important
    }

    .pd-lg-b-140-f {
        padding-bottom: 140px !important
    }

    .pd-lg-l-140-f {
        padding-left: 140px !important
    }

    .pd-lg-150 {
        padding: 150px
    }

    .pd-lg-150-f {
        padding: 150px !important
    }

    .pd-lg-y-150 {
        padding-top: 150px;
        padding-bottom: 150px
    }

    .pd-lg-y-150-f {
        padding-top: 150px !important;
        padding-bottom: 150px !important
    }

    .pd-lg-x-150 {
        padding-left: 150px;
        padding-right: 150px
    }

    .pd-lg-x-150-f {
        padding-left: 150px !important;
        padding-right: 150px !important
    }

    .pd-lg-t-150 {
        padding-top: 150px
    }

    .pd-lg-r-150 {
        padding-right: 150px
    }

    .pd-lg-b-150 {
        padding-bottom: 150px
    }

    .pd-lg-l-150 {
        padding-left: 150px
    }

    .pd-lg-t-150-f {
        padding-top: 150px !important
    }

    .pd-lg-r-150-f {
        padding-right: 150px !important
    }

    .pd-lg-b-150-f {
        padding-bottom: 150px !important
    }

    .pd-lg-l-150-f {
        padding-left: 150px !important
    }

    .pd-lg-160 {
        padding: 160px
    }

    .pd-lg-160-f {
        padding: 160px !important
    }

    .pd-lg-y-160 {
        padding-top: 160px;
        padding-bottom: 160px
    }

    .pd-lg-y-160-f {
        padding-top: 160px !important;
        padding-bottom: 160px !important
    }

    .pd-lg-x-160 {
        padding-left: 160px;
        padding-right: 160px
    }

    .pd-lg-x-160-f {
        padding-left: 160px !important;
        padding-right: 160px !important
    }

    .pd-lg-t-160 {
        padding-top: 160px
    }

    .pd-lg-r-160 {
        padding-right: 160px
    }

    .pd-lg-b-160 {
        padding-bottom: 160px
    }

    .pd-lg-l-160 {
        padding-left: 160px
    }

    .pd-lg-t-160-f {
        padding-top: 160px !important
    }

    .pd-lg-r-160-f {
        padding-right: 160px !important
    }

    .pd-lg-b-160-f {
        padding-bottom: 160px !important
    }

    .pd-lg-l-160-f {
        padding-left: 160px !important
    }

    .pd-lg-170 {
        padding: 170px
    }

    .pd-lg-170-f {
        padding: 170px !important
    }

    .pd-lg-y-170 {
        padding-top: 170px;
        padding-bottom: 170px
    }

    .pd-lg-y-170-f {
        padding-top: 170px !important;
        padding-bottom: 170px !important
    }

    .pd-lg-x-170 {
        padding-left: 170px;
        padding-right: 170px
    }

    .pd-lg-x-170-f {
        padding-left: 170px !important;
        padding-right: 170px !important
    }

    .pd-lg-t-170 {
        padding-top: 170px
    }

    .pd-lg-r-170 {
        padding-right: 170px
    }

    .pd-lg-b-170 {
        padding-bottom: 170px
    }

    .pd-lg-l-170 {
        padding-left: 170px
    }

    .pd-lg-t-170-f {
        padding-top: 170px !important
    }

    .pd-lg-r-170-f {
        padding-right: 170px !important
    }

    .pd-lg-b-170-f {
        padding-bottom: 170px !important
    }

    .pd-lg-l-170-f {
        padding-left: 170px !important
    }

    .pd-lg-180 {
        padding: 180px
    }

    .pd-lg-180-f {
        padding: 180px !important
    }

    .pd-lg-y-180 {
        padding-top: 180px;
        padding-bottom: 180px
    }

    .pd-lg-y-180-f {
        padding-top: 180px !important;
        padding-bottom: 180px !important
    }

    .pd-lg-x-180 {
        padding-left: 180px;
        padding-right: 180px
    }

    .pd-lg-x-180-f {
        padding-left: 180px !important;
        padding-right: 180px !important
    }

    .pd-lg-t-180 {
        padding-top: 180px
    }

    .pd-lg-r-180 {
        padding-right: 180px
    }

    .pd-lg-b-180 {
        padding-bottom: 180px
    }

    .pd-lg-l-180 {
        padding-left: 180px
    }

    .pd-lg-t-180-f {
        padding-top: 180px !important
    }

    .pd-lg-r-180-f {
        padding-right: 180px !important
    }

    .pd-lg-b-180-f {
        padding-bottom: 180px !important
    }

    .pd-lg-l-180-f {
        padding-left: 180px !important
    }

    .pd-lg-190 {
        padding: 190px
    }

    .pd-lg-190-f {
        padding: 190px !important
    }

    .pd-lg-y-190 {
        padding-top: 190px;
        padding-bottom: 190px
    }

    .pd-lg-y-190-f {
        padding-top: 190px !important;
        padding-bottom: 190px !important
    }

    .pd-lg-x-190 {
        padding-left: 190px;
        padding-right: 190px
    }

    .pd-lg-x-190-f {
        padding-left: 190px !important;
        padding-right: 190px !important
    }

    .pd-lg-t-190 {
        padding-top: 190px
    }

    .pd-lg-r-190 {
        padding-right: 190px
    }

    .pd-lg-b-190 {
        padding-bottom: 190px
    }

    .pd-lg-l-190 {
        padding-left: 190px
    }

    .pd-lg-t-190-f {
        padding-top: 190px !important
    }

    .pd-lg-r-190-f {
        padding-right: 190px !important
    }

    .pd-lg-b-190-f {
        padding-bottom: 190px !important
    }

    .pd-lg-l-190-f {
        padding-left: 190px !important
    }

    .pd-lg-200 {
        padding: 200px
    }

    .pd-lg-200-f {
        padding: 200px !important
    }

    .pd-lg-y-200 {
        padding-top: 200px;
        padding-bottom: 200px
    }

    .pd-lg-y-200-f {
        padding-top: 200px !important;
        padding-bottom: 200px !important
    }

    .pd-lg-x-200 {
        padding-left: 200px;
        padding-right: 200px
    }

    .pd-lg-x-200-f {
        padding-left: 200px !important;
        padding-right: 200px !important
    }

    .pd-lg-t-200 {
        padding-top: 200px
    }

    .pd-lg-r-200 {
        padding-right: 200px
    }

    .pd-lg-b-200 {
        padding-bottom: 200px
    }

    .pd-lg-l-200 {
        padding-left: 200px
    }

    .pd-lg-t-200-f {
        padding-top: 200px !important
    }

    .pd-lg-r-200-f {
        padding-right: 200px !important
    }

    .pd-lg-b-200-f {
        padding-bottom: 200px !important
    }

    .pd-lg-l-200-f {
        padding-left: 200px !important
    }
}

@media (min-width: 1200px) {
    .pd-xl-0 {
        padding: 0px
    }

    .pd-xl-0-f {
        padding: 0px !important
    }

    .pd-xl-y-0 {
        padding-top: 0px;
        padding-bottom: 0px
    }

    .pd-xl-y-0-f {
        padding-top: 0px !important;
        padding-bottom: 0px !important
    }

    .pd-xl-x-0 {
        padding-left: 0px;
        padding-right: 0px
    }

    .pd-xl-x-0-f {
        padding-left: 0px !important;
        padding-right: 0px !important
    }

    .pd-xl-t-0 {
        padding-top: 0px
    }

    .pd-xl-r-0 {
        padding-right: 0px
    }

    .pd-xl-b-0 {
        padding-bottom: 0px
    }

    .pd-xl-l-0 {
        padding-left: 0px
    }

    .pd-xl-t-0-f {
        padding-top: 0px !important
    }

    .pd-xl-r-0-f {
        padding-right: 0px !important
    }

    .pd-xl-b-0-f {
        padding-bottom: 0px !important
    }

    .pd-xl-l-0-f {
        padding-left: 0px !important
    }

    .pd-xl-1 {
        padding: 1px
    }

    .pd-xl-1-f {
        padding: 1px !important
    }

    .pd-xl-y-1 {
        padding-top: 1px;
        padding-bottom: 1px
    }

    .pd-xl-y-1-f {
        padding-top: 1px !important;
        padding-bottom: 1px !important
    }

    .pd-xl-x-1 {
        padding-left: 1px;
        padding-right: 1px
    }

    .pd-xl-x-1-f {
        padding-left: 1px !important;
        padding-right: 1px !important
    }

    .pd-xl-t-1 {
        padding-top: 1px
    }

    .pd-xl-r-1 {
        padding-right: 1px
    }

    .pd-xl-b-1 {
        padding-bottom: 1px
    }

    .pd-xl-l-1 {
        padding-left: 1px
    }

    .pd-xl-t-1-f {
        padding-top: 1px !important
    }

    .pd-xl-r-1-f {
        padding-right: 1px !important
    }

    .pd-xl-b-1-f {
        padding-bottom: 1px !important
    }

    .pd-xl-l-1-f {
        padding-left: 1px !important
    }

    .pd-xl-2 {
        padding: 2px
    }

    .pd-xl-2-f {
        padding: 2px !important
    }

    .pd-xl-y-2 {
        padding-top: 2px;
        padding-bottom: 2px
    }

    .pd-xl-y-2-f {
        padding-top: 2px !important;
        padding-bottom: 2px !important
    }

    .pd-xl-x-2 {
        padding-left: 2px;
        padding-right: 2px
    }

    .pd-xl-x-2-f {
        padding-left: 2px !important;
        padding-right: 2px !important
    }

    .pd-xl-t-2 {
        padding-top: 2px
    }

    .pd-xl-r-2 {
        padding-right: 2px
    }

    .pd-xl-b-2 {
        padding-bottom: 2px
    }

    .pd-xl-l-2 {
        padding-left: 2px
    }

    .pd-xl-t-2-f {
        padding-top: 2px !important
    }

    .pd-xl-r-2-f {
        padding-right: 2px !important
    }

    .pd-xl-b-2-f {
        padding-bottom: 2px !important
    }

    .pd-xl-l-2-f {
        padding-left: 2px !important
    }

    .pd-xl-3 {
        padding: 3px
    }

    .pd-xl-3-f {
        padding: 3px !important
    }

    .pd-xl-y-3 {
        padding-top: 3px;
        padding-bottom: 3px
    }

    .pd-xl-y-3-f {
        padding-top: 3px !important;
        padding-bottom: 3px !important
    }

    .pd-xl-x-3 {
        padding-left: 3px;
        padding-right: 3px
    }

    .pd-xl-x-3-f {
        padding-left: 3px !important;
        padding-right: 3px !important
    }

    .pd-xl-t-3 {
        padding-top: 3px
    }

    .pd-xl-r-3 {
        padding-right: 3px
    }

    .pd-xl-b-3 {
        padding-bottom: 3px
    }

    .pd-xl-l-3 {
        padding-left: 3px
    }

    .pd-xl-t-3-f {
        padding-top: 3px !important
    }

    .pd-xl-r-3-f {
        padding-right: 3px !important
    }

    .pd-xl-b-3-f {
        padding-bottom: 3px !important
    }

    .pd-xl-l-3-f {
        padding-left: 3px !important
    }

    .pd-xl-4 {
        padding: 4px
    }

    .pd-xl-4-f {
        padding: 4px !important
    }

    .pd-xl-y-4 {
        padding-top: 4px;
        padding-bottom: 4px
    }

    .pd-xl-y-4-f {
        padding-top: 4px !important;
        padding-bottom: 4px !important
    }

    .pd-xl-x-4 {
        padding-left: 4px;
        padding-right: 4px
    }

    .pd-xl-x-4-f {
        padding-left: 4px !important;
        padding-right: 4px !important
    }

    .pd-xl-t-4 {
        padding-top: 4px
    }

    .pd-xl-r-4 {
        padding-right: 4px
    }

    .pd-xl-b-4 {
        padding-bottom: 4px
    }

    .pd-xl-l-4 {
        padding-left: 4px
    }

    .pd-xl-t-4-f {
        padding-top: 4px !important
    }

    .pd-xl-r-4-f {
        padding-right: 4px !important
    }

    .pd-xl-b-4-f {
        padding-bottom: 4px !important
    }

    .pd-xl-l-4-f {
        padding-left: 4px !important
    }

    .pd-xl-5 {
        padding: 5px
    }

    .pd-xl-5-f {
        padding: 5px !important
    }

    .pd-xl-y-5 {
        padding-top: 5px;
        padding-bottom: 5px
    }

    .pd-xl-y-5-f {
        padding-top: 5px !important;
        padding-bottom: 5px !important
    }

    .pd-xl-x-5 {
        padding-left: 5px;
        padding-right: 5px
    }

    .pd-xl-x-5-f {
        padding-left: 5px !important;
        padding-right: 5px !important
    }

    .pd-xl-t-5 {
        padding-top: 5px
    }

    .pd-xl-r-5 {
        padding-right: 5px
    }

    .pd-xl-b-5 {
        padding-bottom: 5px
    }

    .pd-xl-l-5 {
        padding-left: 5px
    }

    .pd-xl-t-5-f {
        padding-top: 5px !important
    }

    .pd-xl-r-5-f {
        padding-right: 5px !important
    }

    .pd-xl-b-5-f {
        padding-bottom: 5px !important
    }

    .pd-xl-l-5-f {
        padding-left: 5px !important
    }

    .pd-xl-6 {
        padding: 6px
    }

    .pd-xl-6-f {
        padding: 6px !important
    }

    .pd-xl-y-6 {
        padding-top: 6px;
        padding-bottom: 6px
    }

    .pd-xl-y-6-f {
        padding-top: 6px !important;
        padding-bottom: 6px !important
    }

    .pd-xl-x-6 {
        padding-left: 6px;
        padding-right: 6px
    }

    .pd-xl-x-6-f {
        padding-left: 6px !important;
        padding-right: 6px !important
    }

    .pd-xl-t-6 {
        padding-top: 6px
    }

    .pd-xl-r-6 {
        padding-right: 6px
    }

    .pd-xl-b-6 {
        padding-bottom: 6px
    }

    .pd-xl-l-6 {
        padding-left: 6px
    }

    .pd-xl-t-6-f {
        padding-top: 6px !important
    }

    .pd-xl-r-6-f {
        padding-right: 6px !important
    }

    .pd-xl-b-6-f {
        padding-bottom: 6px !important
    }

    .pd-xl-l-6-f {
        padding-left: 6px !important
    }

    .pd-xl-7 {
        padding: 7px
    }

    .pd-xl-7-f {
        padding: 7px !important
    }

    .pd-xl-y-7 {
        padding-top: 7px;
        padding-bottom: 7px
    }

    .pd-xl-y-7-f {
        padding-top: 7px !important;
        padding-bottom: 7px !important
    }

    .pd-xl-x-7 {
        padding-left: 7px;
        padding-right: 7px
    }

    .pd-xl-x-7-f {
        padding-left: 7px !important;
        padding-right: 7px !important
    }

    .pd-xl-t-7 {
        padding-top: 7px
    }

    .pd-xl-r-7 {
        padding-right: 7px
    }

    .pd-xl-b-7 {
        padding-bottom: 7px
    }

    .pd-xl-l-7 {
        padding-left: 7px
    }

    .pd-xl-t-7-f {
        padding-top: 7px !important
    }

    .pd-xl-r-7-f {
        padding-right: 7px !important
    }

    .pd-xl-b-7-f {
        padding-bottom: 7px !important
    }

    .pd-xl-l-7-f {
        padding-left: 7px !important
    }

    .pd-xl-8 {
        padding: 8px
    }

    .pd-xl-8-f {
        padding: 8px !important
    }

    .pd-xl-y-8 {
        padding-top: 8px;
        padding-bottom: 8px
    }

    .pd-xl-y-8-f {
        padding-top: 8px !important;
        padding-bottom: 8px !important
    }

    .pd-xl-x-8 {
        padding-left: 8px;
        padding-right: 8px
    }

    .pd-xl-x-8-f {
        padding-left: 8px !important;
        padding-right: 8px !important
    }

    .pd-xl-t-8 {
        padding-top: 8px
    }

    .pd-xl-r-8 {
        padding-right: 8px
    }

    .pd-xl-b-8 {
        padding-bottom: 8px
    }

    .pd-xl-l-8 {
        padding-left: 8px
    }

    .pd-xl-t-8-f {
        padding-top: 8px !important
    }

    .pd-xl-r-8-f {
        padding-right: 8px !important
    }

    .pd-xl-b-8-f {
        padding-bottom: 8px !important
    }

    .pd-xl-l-8-f {
        padding-left: 8px !important
    }

    .pd-xl-9 {
        padding: 9px
    }

    .pd-xl-9-f {
        padding: 9px !important
    }

    .pd-xl-y-9 {
        padding-top: 9px;
        padding-bottom: 9px
    }

    .pd-xl-y-9-f {
        padding-top: 9px !important;
        padding-bottom: 9px !important
    }

    .pd-xl-x-9 {
        padding-left: 9px;
        padding-right: 9px
    }

    .pd-xl-x-9-f {
        padding-left: 9px !important;
        padding-right: 9px !important
    }

    .pd-xl-t-9 {
        padding-top: 9px
    }

    .pd-xl-r-9 {
        padding-right: 9px
    }

    .pd-xl-b-9 {
        padding-bottom: 9px
    }

    .pd-xl-l-9 {
        padding-left: 9px
    }

    .pd-xl-t-9-f {
        padding-top: 9px !important
    }

    .pd-xl-r-9-f {
        padding-right: 9px !important
    }

    .pd-xl-b-9-f {
        padding-bottom: 9px !important
    }

    .pd-xl-l-9-f {
        padding-left: 9px !important
    }

    .pd-xl-10 {
        padding: 10px
    }

    .pd-xl-10-f {
        padding: 10px !important
    }

    .pd-xl-y-10 {
        padding-top: 10px;
        padding-bottom: 10px
    }

    .pd-xl-y-10-f {
        padding-top: 10px !important;
        padding-bottom: 10px !important
    }

    .pd-xl-x-10 {
        padding-left: 10px;
        padding-right: 10px
    }

    .pd-xl-x-10-f {
        padding-left: 10px !important;
        padding-right: 10px !important
    }

    .pd-xl-t-10 {
        padding-top: 10px
    }

    .pd-xl-r-10 {
        padding-right: 10px
    }

    .pd-xl-b-10 {
        padding-bottom: 10px
    }

    .pd-xl-l-10 {
        padding-left: 10px
    }

    .pd-xl-t-10-f {
        padding-top: 10px !important
    }

    .pd-xl-r-10-f {
        padding-right: 10px !important
    }

    .pd-xl-b-10-f {
        padding-bottom: 10px !important
    }

    .pd-xl-l-10-f {
        padding-left: 10px !important
    }

    .pd-xl-15 {
        padding: 15px
    }

    .pd-xl-15-f {
        padding: 15px !important
    }

    .pd-xl-y-15 {
        padding-top: 15px;
        padding-bottom: 15px
    }

    .pd-xl-y-15-f {
        padding-top: 15px !important;
        padding-bottom: 15px !important
    }

    .pd-xl-x-15 {
        padding-left: 15px;
        padding-right: 15px
    }

    .pd-xl-x-15-f {
        padding-left: 15px !important;
        padding-right: 15px !important
    }

    .pd-xl-t-15 {
        padding-top: 15px
    }

    .pd-xl-r-15 {
        padding-right: 15px
    }

    .pd-xl-b-15 {
        padding-bottom: 15px
    }

    .pd-xl-l-15 {
        padding-left: 15px
    }

    .pd-xl-t-15-f {
        padding-top: 15px !important
    }

    .pd-xl-r-15-f {
        padding-right: 15px !important
    }

    .pd-xl-b-15-f {
        padding-bottom: 15px !important
    }

    .pd-xl-l-15-f {
        padding-left: 15px !important
    }

    .pd-xl-20 {
        padding: 20px
    }

    .pd-xl-20-f {
        padding: 20px !important
    }

    .pd-xl-y-20 {
        padding-top: 20px;
        padding-bottom: 20px
    }

    .pd-xl-y-20-f {
        padding-top: 20px !important;
        padding-bottom: 20px !important
    }

    .pd-xl-x-20 {
        padding-left: 20px;
        padding-right: 20px
    }

    .pd-xl-x-20-f {
        padding-left: 20px !important;
        padding-right: 20px !important
    }

    .pd-xl-t-20 {
        padding-top: 20px
    }

    .pd-xl-r-20 {
        padding-right: 20px
    }

    .pd-xl-b-20 {
        padding-bottom: 20px
    }

    .pd-xl-l-20 {
        padding-left: 20px
    }

    .pd-xl-t-20-f {
        padding-top: 20px !important
    }

    .pd-xl-r-20-f {
        padding-right: 20px !important
    }

    .pd-xl-b-20-f {
        padding-bottom: 20px !important
    }

    .pd-xl-l-20-f {
        padding-left: 20px !important
    }

    .pd-xl-25 {
        padding: 25px
    }

    .pd-xl-25-f {
        padding: 25px !important
    }

    .pd-xl-y-25 {
        padding-top: 25px;
        padding-bottom: 25px
    }

    .pd-xl-y-25-f {
        padding-top: 25px !important;
        padding-bottom: 25px !important
    }

    .pd-xl-x-25 {
        padding-left: 25px;
        padding-right: 25px
    }

    .pd-xl-x-25-f {
        padding-left: 25px !important;
        padding-right: 25px !important
    }

    .pd-xl-t-25 {
        padding-top: 25px
    }

    .pd-xl-r-25 {
        padding-right: 25px
    }

    .pd-xl-b-25 {
        padding-bottom: 25px
    }

    .pd-xl-l-25 {
        padding-left: 25px
    }

    .pd-xl-t-25-f {
        padding-top: 25px !important
    }

    .pd-xl-r-25-f {
        padding-right: 25px !important
    }

    .pd-xl-b-25-f {
        padding-bottom: 25px !important
    }

    .pd-xl-l-25-f {
        padding-left: 25px !important
    }

    .pd-xl-30 {
        padding: 30px
    }

    .pd-xl-30-f {
        padding: 30px !important
    }

    .pd-xl-y-30 {
        padding-top: 30px;
        padding-bottom: 30px
    }

    .pd-xl-y-30-f {
        padding-top: 30px !important;
        padding-bottom: 30px !important
    }

    .pd-xl-x-30 {
        padding-left: 30px;
        padding-right: 30px
    }

    .pd-xl-x-30-f {
        padding-left: 30px !important;
        padding-right: 30px !important
    }

    .pd-xl-t-30 {
        padding-top: 30px
    }

    .pd-xl-r-30 {
        padding-right: 30px
    }

    .pd-xl-b-30 {
        padding-bottom: 30px
    }

    .pd-xl-l-30 {
        padding-left: 30px
    }

    .pd-xl-t-30-f {
        padding-top: 30px !important
    }

    .pd-xl-r-30-f {
        padding-right: 30px !important
    }

    .pd-xl-b-30-f {
        padding-bottom: 30px !important
    }

    .pd-xl-l-30-f {
        padding-left: 30px !important
    }

    .pd-xl-35 {
        padding: 35px
    }

    .pd-xl-35-f {
        padding: 35px !important
    }

    .pd-xl-y-35 {
        padding-top: 35px;
        padding-bottom: 35px
    }

    .pd-xl-y-35-f {
        padding-top: 35px !important;
        padding-bottom: 35px !important
    }

    .pd-xl-x-35 {
        padding-left: 35px;
        padding-right: 35px
    }

    .pd-xl-x-35-f {
        padding-left: 35px !important;
        padding-right: 35px !important
    }

    .pd-xl-t-35 {
        padding-top: 35px
    }

    .pd-xl-r-35 {
        padding-right: 35px
    }

    .pd-xl-b-35 {
        padding-bottom: 35px
    }

    .pd-xl-l-35 {
        padding-left: 35px
    }

    .pd-xl-t-35-f {
        padding-top: 35px !important
    }

    .pd-xl-r-35-f {
        padding-right: 35px !important
    }

    .pd-xl-b-35-f {
        padding-bottom: 35px !important
    }

    .pd-xl-l-35-f {
        padding-left: 35px !important
    }

    .pd-xl-40 {
        padding: 40px
    }

    .pd-xl-40-f {
        padding: 40px !important
    }

    .pd-xl-y-40 {
        padding-top: 40px;
        padding-bottom: 40px
    }

    .pd-xl-y-40-f {
        padding-top: 40px !important;
        padding-bottom: 40px !important
    }

    .pd-xl-x-40 {
        padding-left: 40px;
        padding-right: 40px
    }

    .pd-xl-x-40-f {
        padding-left: 40px !important;
        padding-right: 40px !important
    }

    .pd-xl-t-40 {
        padding-top: 40px
    }

    .pd-xl-r-40 {
        padding-right: 40px
    }

    .pd-xl-b-40 {
        padding-bottom: 40px
    }

    .pd-xl-l-40 {
        padding-left: 40px
    }

    .pd-xl-t-40-f {
        padding-top: 40px !important
    }

    .pd-xl-r-40-f {
        padding-right: 40px !important
    }

    .pd-xl-b-40-f {
        padding-bottom: 40px !important
    }

    .pd-xl-l-40-f {
        padding-left: 40px !important
    }

    .pd-xl-45 {
        padding: 45px
    }

    .pd-xl-45-f {
        padding: 45px !important
    }

    .pd-xl-y-45 {
        padding-top: 45px;
        padding-bottom: 45px
    }

    .pd-xl-y-45-f {
        padding-top: 45px !important;
        padding-bottom: 45px !important
    }

    .pd-xl-x-45 {
        padding-left: 45px;
        padding-right: 45px
    }

    .pd-xl-x-45-f {
        padding-left: 45px !important;
        padding-right: 45px !important
    }

    .pd-xl-t-45 {
        padding-top: 45px
    }

    .pd-xl-r-45 {
        padding-right: 45px
    }

    .pd-xl-b-45 {
        padding-bottom: 45px
    }

    .pd-xl-l-45 {
        padding-left: 45px
    }

    .pd-xl-t-45-f {
        padding-top: 45px !important
    }

    .pd-xl-r-45-f {
        padding-right: 45px !important
    }

    .pd-xl-b-45-f {
        padding-bottom: 45px !important
    }

    .pd-xl-l-45-f {
        padding-left: 45px !important
    }

    .pd-xl-50 {
        padding: 50px
    }

    .pd-xl-50-f {
        padding: 50px !important
    }

    .pd-xl-y-50 {
        padding-top: 50px;
        padding-bottom: 50px
    }

    .pd-xl-y-50-f {
        padding-top: 50px !important;
        padding-bottom: 50px !important
    }

    .pd-xl-x-50 {
        padding-left: 50px;
        padding-right: 50px
    }

    .pd-xl-x-50-f {
        padding-left: 50px !important;
        padding-right: 50px !important
    }

    .pd-xl-t-50 {
        padding-top: 50px
    }

    .pd-xl-r-50 {
        padding-right: 50px
    }

    .pd-xl-b-50 {
        padding-bottom: 50px
    }

    .pd-xl-l-50 {
        padding-left: 50px
    }

    .pd-xl-t-50-f {
        padding-top: 50px !important
    }

    .pd-xl-r-50-f {
        padding-right: 50px !important
    }

    .pd-xl-b-50-f {
        padding-bottom: 50px !important
    }

    .pd-xl-l-50-f {
        padding-left: 50px !important
    }

    .pd-xl-55 {
        padding: 55px
    }

    .pd-xl-55-f {
        padding: 55px !important
    }

    .pd-xl-y-55 {
        padding-top: 55px;
        padding-bottom: 55px
    }

    .pd-xl-y-55-f {
        padding-top: 55px !important;
        padding-bottom: 55px !important
    }

    .pd-xl-x-55 {
        padding-left: 55px;
        padding-right: 55px
    }

    .pd-xl-x-55-f {
        padding-left: 55px !important;
        padding-right: 55px !important
    }

    .pd-xl-t-55 {
        padding-top: 55px
    }

    .pd-xl-r-55 {
        padding-right: 55px
    }

    .pd-xl-b-55 {
        padding-bottom: 55px
    }

    .pd-xl-l-55 {
        padding-left: 55px
    }

    .pd-xl-t-55-f {
        padding-top: 55px !important
    }

    .pd-xl-r-55-f {
        padding-right: 55px !important
    }

    .pd-xl-b-55-f {
        padding-bottom: 55px !important
    }

    .pd-xl-l-55-f {
        padding-left: 55px !important
    }

    .pd-xl-60 {
        padding: 60px
    }

    .pd-xl-60-f {
        padding: 60px !important
    }

    .pd-xl-y-60 {
        padding-top: 60px;
        padding-bottom: 60px
    }

    .pd-xl-y-60-f {
        padding-top: 60px !important;
        padding-bottom: 60px !important
    }

    .pd-xl-x-60 {
        padding-left: 60px;
        padding-right: 60px
    }

    .pd-xl-x-60-f {
        padding-left: 60px !important;
        padding-right: 60px !important
    }

    .pd-xl-t-60 {
        padding-top: 60px
    }

    .pd-xl-r-60 {
        padding-right: 60px
    }

    .pd-xl-b-60 {
        padding-bottom: 60px
    }

    .pd-xl-l-60 {
        padding-left: 60px
    }

    .pd-xl-t-60-f {
        padding-top: 60px !important
    }

    .pd-xl-r-60-f {
        padding-right: 60px !important
    }

    .pd-xl-b-60-f {
        padding-bottom: 60px !important
    }

    .pd-xl-l-60-f {
        padding-left: 60px !important
    }

    .pd-xl-65 {
        padding: 65px
    }

    .pd-xl-65-f {
        padding: 65px !important
    }

    .pd-xl-y-65 {
        padding-top: 65px;
        padding-bottom: 65px
    }

    .pd-xl-y-65-f {
        padding-top: 65px !important;
        padding-bottom: 65px !important
    }

    .pd-xl-x-65 {
        padding-left: 65px;
        padding-right: 65px
    }

    .pd-xl-x-65-f {
        padding-left: 65px !important;
        padding-right: 65px !important
    }

    .pd-xl-t-65 {
        padding-top: 65px
    }

    .pd-xl-r-65 {
        padding-right: 65px
    }

    .pd-xl-b-65 {
        padding-bottom: 65px
    }

    .pd-xl-l-65 {
        padding-left: 65px
    }

    .pd-xl-t-65-f {
        padding-top: 65px !important
    }

    .pd-xl-r-65-f {
        padding-right: 65px !important
    }

    .pd-xl-b-65-f {
        padding-bottom: 65px !important
    }

    .pd-xl-l-65-f {
        padding-left: 65px !important
    }

    .pd-xl-70 {
        padding: 70px
    }

    .pd-xl-70-f {
        padding: 70px !important
    }

    .pd-xl-y-70 {
        padding-top: 70px;
        padding-bottom: 70px
    }

    .pd-xl-y-70-f {
        padding-top: 70px !important;
        padding-bottom: 70px !important
    }

    .pd-xl-x-70 {
        padding-left: 70px;
        padding-right: 70px
    }

    .pd-xl-x-70-f {
        padding-left: 70px !important;
        padding-right: 70px !important
    }

    .pd-xl-t-70 {
        padding-top: 70px
    }

    .pd-xl-r-70 {
        padding-right: 70px
    }

    .pd-xl-b-70 {
        padding-bottom: 70px
    }

    .pd-xl-l-70 {
        padding-left: 70px
    }

    .pd-xl-t-70-f {
        padding-top: 70px !important
    }

    .pd-xl-r-70-f {
        padding-right: 70px !important
    }

    .pd-xl-b-70-f {
        padding-bottom: 70px !important
    }

    .pd-xl-l-70-f {
        padding-left: 70px !important
    }

    .pd-xl-75 {
        padding: 75px
    }

    .pd-xl-75-f {
        padding: 75px !important
    }

    .pd-xl-y-75 {
        padding-top: 75px;
        padding-bottom: 75px
    }

    .pd-xl-y-75-f {
        padding-top: 75px !important;
        padding-bottom: 75px !important
    }

    .pd-xl-x-75 {
        padding-left: 75px;
        padding-right: 75px
    }

    .pd-xl-x-75-f {
        padding-left: 75px !important;
        padding-right: 75px !important
    }

    .pd-xl-t-75 {
        padding-top: 75px
    }

    .pd-xl-r-75 {
        padding-right: 75px
    }

    .pd-xl-b-75 {
        padding-bottom: 75px
    }

    .pd-xl-l-75 {
        padding-left: 75px
    }

    .pd-xl-t-75-f {
        padding-top: 75px !important
    }

    .pd-xl-r-75-f {
        padding-right: 75px !important
    }

    .pd-xl-b-75-f {
        padding-bottom: 75px !important
    }

    .pd-xl-l-75-f {
        padding-left: 75px !important
    }

    .pd-xl-80 {
        padding: 80px
    }

    .pd-xl-80-f {
        padding: 80px !important
    }

    .pd-xl-y-80 {
        padding-top: 80px;
        padding-bottom: 80px
    }

    .pd-xl-y-80-f {
        padding-top: 80px !important;
        padding-bottom: 80px !important
    }

    .pd-xl-x-80 {
        padding-left: 80px;
        padding-right: 80px
    }

    .pd-xl-x-80-f {
        padding-left: 80px !important;
        padding-right: 80px !important
    }

    .pd-xl-t-80 {
        padding-top: 80px
    }

    .pd-xl-r-80 {
        padding-right: 80px
    }

    .pd-xl-b-80 {
        padding-bottom: 80px
    }

    .pd-xl-l-80 {
        padding-left: 80px
    }

    .pd-xl-t-80-f {
        padding-top: 80px !important
    }

    .pd-xl-r-80-f {
        padding-right: 80px !important
    }

    .pd-xl-b-80-f {
        padding-bottom: 80px !important
    }

    .pd-xl-l-80-f {
        padding-left: 80px !important
    }

    .pd-xl-85 {
        padding: 85px
    }

    .pd-xl-85-f {
        padding: 85px !important
    }

    .pd-xl-y-85 {
        padding-top: 85px;
        padding-bottom: 85px
    }

    .pd-xl-y-85-f {
        padding-top: 85px !important;
        padding-bottom: 85px !important
    }

    .pd-xl-x-85 {
        padding-left: 85px;
        padding-right: 85px
    }

    .pd-xl-x-85-f {
        padding-left: 85px !important;
        padding-right: 85px !important
    }

    .pd-xl-t-85 {
        padding-top: 85px
    }

    .pd-xl-r-85 {
        padding-right: 85px
    }

    .pd-xl-b-85 {
        padding-bottom: 85px
    }

    .pd-xl-l-85 {
        padding-left: 85px
    }

    .pd-xl-t-85-f {
        padding-top: 85px !important
    }

    .pd-xl-r-85-f {
        padding-right: 85px !important
    }

    .pd-xl-b-85-f {
        padding-bottom: 85px !important
    }

    .pd-xl-l-85-f {
        padding-left: 85px !important
    }

    .pd-xl-90 {
        padding: 90px
    }

    .pd-xl-90-f {
        padding: 90px !important
    }

    .pd-xl-y-90 {
        padding-top: 90px;
        padding-bottom: 90px
    }

    .pd-xl-y-90-f {
        padding-top: 90px !important;
        padding-bottom: 90px !important
    }

    .pd-xl-x-90 {
        padding-left: 90px;
        padding-right: 90px
    }

    .pd-xl-x-90-f {
        padding-left: 90px !important;
        padding-right: 90px !important
    }

    .pd-xl-t-90 {
        padding-top: 90px
    }

    .pd-xl-r-90 {
        padding-right: 90px
    }

    .pd-xl-b-90 {
        padding-bottom: 90px
    }

    .pd-xl-l-90 {
        padding-left: 90px
    }

    .pd-xl-t-90-f {
        padding-top: 90px !important
    }

    .pd-xl-r-90-f {
        padding-right: 90px !important
    }

    .pd-xl-b-90-f {
        padding-bottom: 90px !important
    }

    .pd-xl-l-90-f {
        padding-left: 90px !important
    }

    .pd-xl-95 {
        padding: 95px
    }

    .pd-xl-95-f {
        padding: 95px !important
    }

    .pd-xl-y-95 {
        padding-top: 95px;
        padding-bottom: 95px
    }

    .pd-xl-y-95-f {
        padding-top: 95px !important;
        padding-bottom: 95px !important
    }

    .pd-xl-x-95 {
        padding-left: 95px;
        padding-right: 95px
    }

    .pd-xl-x-95-f {
        padding-left: 95px !important;
        padding-right: 95px !important
    }

    .pd-xl-t-95 {
        padding-top: 95px
    }

    .pd-xl-r-95 {
        padding-right: 95px
    }

    .pd-xl-b-95 {
        padding-bottom: 95px
    }

    .pd-xl-l-95 {
        padding-left: 95px
    }

    .pd-xl-t-95-f {
        padding-top: 95px !important
    }

    .pd-xl-r-95-f {
        padding-right: 95px !important
    }

    .pd-xl-b-95-f {
        padding-bottom: 95px !important
    }

    .pd-xl-l-95-f {
        padding-left: 95px !important
    }

    .pd-xl-100 {
        padding: 100px
    }

    .pd-xl-100-f {
        padding: 100px !important
    }

    .pd-xl-y-100 {
        padding-top: 100px;
        padding-bottom: 100px
    }

    .pd-xl-y-100-f {
        padding-top: 100px !important;
        padding-bottom: 100px !important
    }

    .pd-xl-x-100 {
        padding-left: 100px;
        padding-right: 100px
    }

    .pd-xl-x-100-f {
        padding-left: 100px !important;
        padding-right: 100px !important
    }

    .pd-xl-t-100 {
        padding-top: 100px
    }

    .pd-xl-r-100 {
        padding-right: 100px
    }

    .pd-xl-b-100 {
        padding-bottom: 100px
    }

    .pd-xl-l-100 {
        padding-left: 100px
    }

    .pd-xl-t-100-f {
        padding-top: 100px !important
    }

    .pd-xl-r-100-f {
        padding-right: 100px !important
    }

    .pd-xl-b-100-f {
        padding-bottom: 100px !important
    }

    .pd-xl-l-100-f {
        padding-left: 100px !important
    }

    .pd-xl-110 {
        padding: 110px
    }

    .pd-xl-110-f {
        padding: 110px !important
    }

    .pd-xl-y-110 {
        padding-top: 110px;
        padding-bottom: 110px
    }

    .pd-xl-y-110-f {
        padding-top: 110px !important;
        padding-bottom: 110px !important
    }

    .pd-xl-x-110 {
        padding-left: 110px;
        padding-right: 110px
    }

    .pd-xl-x-110-f {
        padding-left: 110px !important;
        padding-right: 110px !important
    }

    .pd-xl-t-110 {
        padding-top: 110px
    }

    .pd-xl-r-110 {
        padding-right: 110px
    }

    .pd-xl-b-110 {
        padding-bottom: 110px
    }

    .pd-xl-l-110 {
        padding-left: 110px
    }

    .pd-xl-t-110-f {
        padding-top: 110px !important
    }

    .pd-xl-r-110-f {
        padding-right: 110px !important
    }

    .pd-xl-b-110-f {
        padding-bottom: 110px !important
    }

    .pd-xl-l-110-f {
        padding-left: 110px !important
    }

    .pd-xl-120 {
        padding: 120px
    }

    .pd-xl-120-f {
        padding: 120px !important
    }

    .pd-xl-y-120 {
        padding-top: 120px;
        padding-bottom: 120px
    }

    .pd-xl-y-120-f {
        padding-top: 120px !important;
        padding-bottom: 120px !important
    }

    .pd-xl-x-120 {
        padding-left: 120px;
        padding-right: 120px
    }

    .pd-xl-x-120-f {
        padding-left: 120px !important;
        padding-right: 120px !important
    }

    .pd-xl-t-120 {
        padding-top: 120px
    }

    .pd-xl-r-120 {
        padding-right: 120px
    }

    .pd-xl-b-120 {
        padding-bottom: 120px
    }

    .pd-xl-l-120 {
        padding-left: 120px
    }

    .pd-xl-t-120-f {
        padding-top: 120px !important
    }

    .pd-xl-r-120-f {
        padding-right: 120px !important
    }

    .pd-xl-b-120-f {
        padding-bottom: 120px !important
    }

    .pd-xl-l-120-f {
        padding-left: 120px !important
    }

    .pd-xl-130 {
        padding: 130px
    }

    .pd-xl-130-f {
        padding: 130px !important
    }

    .pd-xl-y-130 {
        padding-top: 130px;
        padding-bottom: 130px
    }

    .pd-xl-y-130-f {
        padding-top: 130px !important;
        padding-bottom: 130px !important
    }

    .pd-xl-x-130 {
        padding-left: 130px;
        padding-right: 130px
    }

    .pd-xl-x-130-f {
        padding-left: 130px !important;
        padding-right: 130px !important
    }

    .pd-xl-t-130 {
        padding-top: 130px
    }

    .pd-xl-r-130 {
        padding-right: 130px
    }

    .pd-xl-b-130 {
        padding-bottom: 130px
    }

    .pd-xl-l-130 {
        padding-left: 130px
    }

    .pd-xl-t-130-f {
        padding-top: 130px !important
    }

    .pd-xl-r-130-f {
        padding-right: 130px !important
    }

    .pd-xl-b-130-f {
        padding-bottom: 130px !important
    }

    .pd-xl-l-130-f {
        padding-left: 130px !important
    }

    .pd-xl-140 {
        padding: 140px
    }

    .pd-xl-140-f {
        padding: 140px !important
    }

    .pd-xl-y-140 {
        padding-top: 140px;
        padding-bottom: 140px
    }

    .pd-xl-y-140-f {
        padding-top: 140px !important;
        padding-bottom: 140px !important
    }

    .pd-xl-x-140 {
        padding-left: 140px;
        padding-right: 140px
    }

    .pd-xl-x-140-f {
        padding-left: 140px !important;
        padding-right: 140px !important
    }

    .pd-xl-t-140 {
        padding-top: 140px
    }

    .pd-xl-r-140 {
        padding-right: 140px
    }

    .pd-xl-b-140 {
        padding-bottom: 140px
    }

    .pd-xl-l-140 {
        padding-left: 140px
    }

    .pd-xl-t-140-f {
        padding-top: 140px !important
    }

    .pd-xl-r-140-f {
        padding-right: 140px !important
    }

    .pd-xl-b-140-f {
        padding-bottom: 140px !important
    }

    .pd-xl-l-140-f {
        padding-left: 140px !important
    }

    .pd-xl-150 {
        padding: 150px
    }

    .pd-xl-150-f {
        padding: 150px !important
    }

    .pd-xl-y-150 {
        padding-top: 150px;
        padding-bottom: 150px
    }

    .pd-xl-y-150-f {
        padding-top: 150px !important;
        padding-bottom: 150px !important
    }

    .pd-xl-x-150 {
        padding-left: 150px;
        padding-right: 150px
    }

    .pd-xl-x-150-f {
        padding-left: 150px !important;
        padding-right: 150px !important
    }

    .pd-xl-t-150 {
        padding-top: 150px
    }

    .pd-xl-r-150 {
        padding-right: 150px
    }

    .pd-xl-b-150 {
        padding-bottom: 150px
    }

    .pd-xl-l-150 {
        padding-left: 150px
    }

    .pd-xl-t-150-f {
        padding-top: 150px !important
    }

    .pd-xl-r-150-f {
        padding-right: 150px !important
    }

    .pd-xl-b-150-f {
        padding-bottom: 150px !important
    }

    .pd-xl-l-150-f {
        padding-left: 150px !important
    }

    .pd-xl-160 {
        padding: 160px
    }

    .pd-xl-160-f {
        padding: 160px !important
    }

    .pd-xl-y-160 {
        padding-top: 160px;
        padding-bottom: 160px
    }

    .pd-xl-y-160-f {
        padding-top: 160px !important;
        padding-bottom: 160px !important
    }

    .pd-xl-x-160 {
        padding-left: 160px;
        padding-right: 160px
    }

    .pd-xl-x-160-f {
        padding-left: 160px !important;
        padding-right: 160px !important
    }

    .pd-xl-t-160 {
        padding-top: 160px
    }

    .pd-xl-r-160 {
        padding-right: 160px
    }

    .pd-xl-b-160 {
        padding-bottom: 160px
    }

    .pd-xl-l-160 {
        padding-left: 160px
    }

    .pd-xl-t-160-f {
        padding-top: 160px !important
    }

    .pd-xl-r-160-f {
        padding-right: 160px !important
    }

    .pd-xl-b-160-f {
        padding-bottom: 160px !important
    }

    .pd-xl-l-160-f {
        padding-left: 160px !important
    }

    .pd-xl-170 {
        padding: 170px
    }

    .pd-xl-170-f {
        padding: 170px !important
    }

    .pd-xl-y-170 {
        padding-top: 170px;
        padding-bottom: 170px
    }

    .pd-xl-y-170-f {
        padding-top: 170px !important;
        padding-bottom: 170px !important
    }

    .pd-xl-x-170 {
        padding-left: 170px;
        padding-right: 170px
    }

    .pd-xl-x-170-f {
        padding-left: 170px !important;
        padding-right: 170px !important
    }

    .pd-xl-t-170 {
        padding-top: 170px
    }

    .pd-xl-r-170 {
        padding-right: 170px
    }

    .pd-xl-b-170 {
        padding-bottom: 170px
    }

    .pd-xl-l-170 {
        padding-left: 170px
    }

    .pd-xl-t-170-f {
        padding-top: 170px !important
    }

    .pd-xl-r-170-f {
        padding-right: 170px !important
    }

    .pd-xl-b-170-f {
        padding-bottom: 170px !important
    }

    .pd-xl-l-170-f {
        padding-left: 170px !important
    }

    .pd-xl-180 {
        padding: 180px
    }

    .pd-xl-180-f {
        padding: 180px !important
    }

    .pd-xl-y-180 {
        padding-top: 180px;
        padding-bottom: 180px
    }

    .pd-xl-y-180-f {
        padding-top: 180px !important;
        padding-bottom: 180px !important
    }

    .pd-xl-x-180 {
        padding-left: 180px;
        padding-right: 180px
    }

    .pd-xl-x-180-f {
        padding-left: 180px !important;
        padding-right: 180px !important
    }

    .pd-xl-t-180 {
        padding-top: 180px
    }

    .pd-xl-r-180 {
        padding-right: 180px
    }

    .pd-xl-b-180 {
        padding-bottom: 180px
    }

    .pd-xl-l-180 {
        padding-left: 180px
    }

    .pd-xl-t-180-f {
        padding-top: 180px !important
    }

    .pd-xl-r-180-f {
        padding-right: 180px !important
    }

    .pd-xl-b-180-f {
        padding-bottom: 180px !important
    }

    .pd-xl-l-180-f {
        padding-left: 180px !important
    }

    .pd-xl-190 {
        padding: 190px
    }

    .pd-xl-190-f {
        padding: 190px !important
    }

    .pd-xl-y-190 {
        padding-top: 190px;
        padding-bottom: 190px
    }

    .pd-xl-y-190-f {
        padding-top: 190px !important;
        padding-bottom: 190px !important
    }

    .pd-xl-x-190 {
        padding-left: 190px;
        padding-right: 190px
    }

    .pd-xl-x-190-f {
        padding-left: 190px !important;
        padding-right: 190px !important
    }

    .pd-xl-t-190 {
        padding-top: 190px
    }

    .pd-xl-r-190 {
        padding-right: 190px
    }

    .pd-xl-b-190 {
        padding-bottom: 190px
    }

    .pd-xl-l-190 {
        padding-left: 190px
    }

    .pd-xl-t-190-f {
        padding-top: 190px !important
    }

    .pd-xl-r-190-f {
        padding-right: 190px !important
    }

    .pd-xl-b-190-f {
        padding-bottom: 190px !important
    }

    .pd-xl-l-190-f {
        padding-left: 190px !important
    }

    .pd-xl-200 {
        padding: 200px
    }

    .pd-xl-200-f {
        padding: 200px !important
    }

    .pd-xl-y-200 {
        padding-top: 200px;
        padding-bottom: 200px
    }

    .pd-xl-y-200-f {
        padding-top: 200px !important;
        padding-bottom: 200px !important
    }

    .pd-xl-x-200 {
        padding-left: 200px;
        padding-right: 200px
    }

    .pd-xl-x-200-f {
        padding-left: 200px !important;
        padding-right: 200px !important
    }

    .pd-xl-t-200 {
        padding-top: 200px
    }

    .pd-xl-r-200 {
        padding-right: 200px
    }

    .pd-xl-b-200 {
        padding-bottom: 200px
    }

    .pd-xl-l-200 {
        padding-left: 200px
    }

    .pd-xl-t-200-f {
        padding-top: 200px !important
    }

    .pd-xl-r-200-f {
        padding-right: 200px !important
    }

    .pd-xl-b-200-f {
        padding-bottom: 200px !important
    }

    .pd-xl-l-200-f {
        padding-left: 200px !important
    }
}

.pd-12 {
    padding: 12px
}

.pd-12-f {
    padding: 12px !important
}

.pd-y-12 {
    padding-top: 12px;
    padding-bottom: 12px
}

.pd-y-12-f {
    padding-top: 12px !important;
    padding-bottom: 12px !important
}

.pd-x-12 {
    padding-left: 12px;
    padding-right: 12px
}

.pd-x-12-f {
    padding-left: 12px !important;
    padding-right: 12px !important
}

.pd-t-12 {
    padding-top: 12px
}

.pd-r-12 {
    padding-right: 12px
}

.pd-b-12 {
    padding-bottom: 12px
}

.pd-l-12 {
    padding-left: 12px
}

.pd-t-12-f {
    padding-top: 12px !important
}

.pd-r-12-f {
    padding-right: 12px !important
}

.pd-b-12-f {
    padding-bottom: 12px !important
}

.pd-l-12-f {
    padding-left: 12px !important
}

.pd-13 {
    padding: 13px
}

.pd-13-f {
    padding: 13px !important
}

.pd-y-13 {
    padding-top: 13px;
    padding-bottom: 13px
}

.pd-y-13-f {
    padding-top: 13px !important;
    padding-bottom: 13px !important
}

.pd-x-13 {
    padding-left: 13px;
    padding-right: 13px
}

.pd-x-13-f {
    padding-left: 13px !important;
    padding-right: 13px !important
}

.pd-t-13 {
    padding-top: 13px
}

.pd-r-13 {
    padding-right: 13px
}

.pd-b-13 {
    padding-bottom: 13px
}

.pd-l-13 {
    padding-left: 13px
}

.pd-t-13-f {
    padding-top: 13px !important
}

.pd-r-13-f {
    padding-right: 13px !important
}

.pd-b-13-f {
    padding-bottom: 13px !important
}

.pd-l-13-f {
    padding-left: 13px !important
}

.pos-absolute {
    position: absolute
}

.pos-relative {
    position: relative
}

.pos-fixed {
    position: fixed
}

.pos-static {
    position: static
}

.pos-absolute-f {
    position: absolute !important
}

.pos-relative-f {
    position: relative !important
}

.pos-fixed-f {
    position: fixed !important
}

.pos-static-f {
    position: static !important
}

.t-0 {
    top: 0px
}

.r-0 {
    right: 0px
}

.b-0 {
    bottom: 0px
}

.l-0 {
    left: 0px
}

.t--0 {
    top: -0px
}

.r--0 {
    right: -0px
}

.b--0 {
    bottom: -0px
}

.l--0 {
    left: -0px
}

.a-0 {
    top: 0px;
    right: 0px;
    bottom: 0px;
    left: 0px
}

.x-0 {
    left: 0px;
    right: 0px
}

.y-0 {
    left: 0px;
    right: 0px
}

.t-5 {
    top: 5px
}

.r-5 {
    right: 5px
}

.b-5 {
    bottom: 5px
}

.l-5 {
    left: 5px
}

.t--5 {
    top: -5px
}

.r--5 {
    right: -5px
}

.b--5 {
    bottom: -5px
}

.l--5 {
    left: -5px
}

.a-5 {
    top: 5px;
    right: 5px;
    bottom: 5px;
    left: 5px
}

.x-5 {
    left: 5px;
    right: 5px
}

.y-5 {
    left: 5px;
    right: 5px
}

.t-10 {
    top: 10px
}

.r-10 {
    right: 10px
}

.b-10 {
    bottom: 10px
}

.l-10 {
    left: 10px
}

.t--10 {
    top: -10px
}

.r--10 {
    right: -10px
}

.b--10 {
    bottom: -10px
}

.l--10 {
    left: -10px
}

.a-10 {
    top: 10px;
    right: 10px;
    bottom: 10px;
    left: 10px
}

.x-10 {
    left: 10px;
    right: 10px
}

.y-10 {
    left: 10px;
    right: 10px
}

.t-15 {
    top: 15px
}

.r-15 {
    right: 15px
}

.b-15 {
    bottom: 15px
}

.l-15 {
    left: 15px
}

.t--15 {
    top: -15px
}

.r--15 {
    right: -15px
}

.b--15 {
    bottom: -15px
}

.l--15 {
    left: -15px
}

.a-15 {
    top: 15px;
    right: 15px;
    bottom: 15px;
    left: 15px
}

.x-15 {
    left: 15px;
    right: 15px
}

.y-15 {
    left: 15px;
    right: 15px
}

.t-20 {
    top: 20px
}

.r-20 {
    right: 20px
}

.b-20 {
    bottom: 20px
}

.l-20 {
    left: 20px
}

.t--20 {
    top: -20px
}

.r--20 {
    right: -20px
}

.b--20 {
    bottom: -20px
}

.l--20 {
    left: -20px
}

.a-20 {
    top: 20px;
    right: 20px;
    bottom: 20px;
    left: 20px
}

.x-20 {
    left: 20px;
    right: 20px
}

.y-20 {
    left: 20px;
    right: 20px
}

.t-25 {
    top: 25px
}

.r-25 {
    right: 25px
}

.b-25 {
    bottom: 25px
}

.l-25 {
    left: 25px
}

.t--25 {
    top: -25px
}

.r--25 {
    right: -25px
}

.b--25 {
    bottom: -25px
}

.l--25 {
    left: -25px
}

.a-25 {
    top: 25px;
    right: 25px;
    bottom: 25px;
    left: 25px
}

.x-25 {
    left: 25px;
    right: 25px
}

.y-25 {
    left: 25px;
    right: 25px
}

.t-30 {
    top: 30px
}

.r-30 {
    right: 30px
}

.b-30 {
    bottom: 30px
}

.l-30 {
    left: 30px
}

.t--30 {
    top: -30px
}

.r--30 {
    right: -30px
}

.b--30 {
    bottom: -30px
}

.l--30 {
    left: -30px
}

.a-30 {
    top: 30px;
    right: 30px;
    bottom: 30px;
    left: 30px
}

.x-30 {
    left: 30px;
    right: 30px
}

.y-30 {
    left: 30px;
    right: 30px
}

.t-35 {
    top: 35px
}

.r-35 {
    right: 35px
}

.b-35 {
    bottom: 35px
}

.l-35 {
    left: 35px
}

.t--35 {
    top: -35px
}

.r--35 {
    right: -35px
}

.b--35 {
    bottom: -35px
}

.l--35 {
    left: -35px
}

.a-35 {
    top: 35px;
    right: 35px;
    bottom: 35px;
    left: 35px
}

.x-35 {
    left: 35px;
    right: 35px
}

.y-35 {
    left: 35px;
    right: 35px
}

.t-40 {
    top: 40px
}

.r-40 {
    right: 40px
}

.b-40 {
    bottom: 40px
}

.l-40 {
    left: 40px
}

.t--40 {
    top: -40px
}

.r--40 {
    right: -40px
}

.b--40 {
    bottom: -40px
}

.l--40 {
    left: -40px
}

.a-40 {
    top: 40px;
    right: 40px;
    bottom: 40px;
    left: 40px
}

.x-40 {
    left: 40px;
    right: 40px
}

.y-40 {
    left: 40px;
    right: 40px
}

.t-45 {
    top: 45px
}

.r-45 {
    right: 45px
}

.b-45 {
    bottom: 45px
}

.l-45 {
    left: 45px
}

.t--45 {
    top: -45px
}

.r--45 {
    right: -45px
}

.b--45 {
    bottom: -45px
}

.l--45 {
    left: -45px
}

.a-45 {
    top: 45px;
    right: 45px;
    bottom: 45px;
    left: 45px
}

.x-45 {
    left: 45px;
    right: 45px
}

.y-45 {
    left: 45px;
    right: 45px
}

.t-50 {
    top: 50px
}

.r-50 {
    right: 50px
}

.b-50 {
    bottom: 50px
}

.l-50 {
    left: 50px
}

.t--50 {
    top: -50px
}

.r--50 {
    right: -50px
}

.b--50 {
    bottom: -50px
}

.l--50 {
    left: -50px
}

.a-50 {
    top: 50px;
    right: 50px;
    bottom: 50px;
    left: 50px
}

.x-50 {
    left: 50px;
    right: 50px
}

.y-50 {
    left: 50px;
    right: 50px
}

.t-55 {
    top: 55px
}

.r-55 {
    right: 55px
}

.b-55 {
    bottom: 55px
}

.l-55 {
    left: 55px
}

.t--55 {
    top: -55px
}

.r--55 {
    right: -55px
}

.b--55 {
    bottom: -55px
}

.l--55 {
    left: -55px
}

.a-55 {
    top: 55px;
    right: 55px;
    bottom: 55px;
    left: 55px
}

.x-55 {
    left: 55px;
    right: 55px
}

.y-55 {
    left: 55px;
    right: 55px
}

.t-60 {
    top: 60px
}

.r-60 {
    right: 60px
}

.b-60 {
    bottom: 60px
}

.l-60 {
    left: 60px
}

.t--60 {
    top: -60px
}

.r--60 {
    right: -60px
}

.b--60 {
    bottom: -60px
}

.l--60 {
    left: -60px
}

.a-60 {
    top: 60px;
    right: 60px;
    bottom: 60px;
    left: 60px
}

.x-60 {
    left: 60px;
    right: 60px
}

.y-60 {
    left: 60px;
    right: 60px
}

.t-65 {
    top: 65px
}

.r-65 {
    right: 65px
}

.b-65 {
    bottom: 65px
}

.l-65 {
    left: 65px
}

.t--65 {
    top: -65px
}

.r--65 {
    right: -65px
}

.b--65 {
    bottom: -65px
}

.l--65 {
    left: -65px
}

.a-65 {
    top: 65px;
    right: 65px;
    bottom: 65px;
    left: 65px
}

.x-65 {
    left: 65px;
    right: 65px
}

.y-65 {
    left: 65px;
    right: 65px
}

.t-70 {
    top: 70px
}

.r-70 {
    right: 70px
}

.b-70 {
    bottom: 70px
}

.l-70 {
    left: 70px
}

.t--70 {
    top: -70px
}

.r--70 {
    right: -70px
}

.b--70 {
    bottom: -70px
}

.l--70 {
    left: -70px
}

.a-70 {
    top: 70px;
    right: 70px;
    bottom: 70px;
    left: 70px
}

.x-70 {
    left: 70px;
    right: 70px
}

.y-70 {
    left: 70px;
    right: 70px
}

.t-75 {
    top: 75px
}

.r-75 {
    right: 75px
}

.b-75 {
    bottom: 75px
}

.l-75 {
    left: 75px
}

.t--75 {
    top: -75px
}

.r--75 {
    right: -75px
}

.b--75 {
    bottom: -75px
}

.l--75 {
    left: -75px
}

.a-75 {
    top: 75px;
    right: 75px;
    bottom: 75px;
    left: 75px
}

.x-75 {
    left: 75px;
    right: 75px
}

.y-75 {
    left: 75px;
    right: 75px
}

.t-80 {
    top: 80px
}

.r-80 {
    right: 80px
}

.b-80 {
    bottom: 80px
}

.l-80 {
    left: 80px
}

.t--80 {
    top: -80px
}

.r--80 {
    right: -80px
}

.b--80 {
    bottom: -80px
}

.l--80 {
    left: -80px
}

.a-80 {
    top: 80px;
    right: 80px;
    bottom: 80px;
    left: 80px
}

.x-80 {
    left: 80px;
    right: 80px
}

.y-80 {
    left: 80px;
    right: 80px
}

.t-85 {
    top: 85px
}

.r-85 {
    right: 85px
}

.b-85 {
    bottom: 85px
}

.l-85 {
    left: 85px
}

.t--85 {
    top: -85px
}

.r--85 {
    right: -85px
}

.b--85 {
    bottom: -85px
}

.l--85 {
    left: -85px
}

.a-85 {
    top: 85px;
    right: 85px;
    bottom: 85px;
    left: 85px
}

.x-85 {
    left: 85px;
    right: 85px
}

.y-85 {
    left: 85px;
    right: 85px
}

.t-90 {
    top: 90px
}

.r-90 {
    right: 90px
}

.b-90 {
    bottom: 90px
}

.l-90 {
    left: 90px
}

.t--90 {
    top: -90px
}

.r--90 {
    right: -90px
}

.b--90 {
    bottom: -90px
}

.l--90 {
    left: -90px
}

.a-90 {
    top: 90px;
    right: 90px;
    bottom: 90px;
    left: 90px
}

.x-90 {
    left: 90px;
    right: 90px
}

.y-90 {
    left: 90px;
    right: 90px
}

.t-95 {
    top: 95px
}

.r-95 {
    right: 95px
}

.b-95 {
    bottom: 95px
}

.l-95 {
    left: 95px
}

.t--95 {
    top: -95px
}

.r--95 {
    right: -95px
}

.b--95 {
    bottom: -95px
}

.l--95 {
    left: -95px
}

.a-95 {
    top: 95px;
    right: 95px;
    bottom: 95px;
    left: 95px
}

.x-95 {
    left: 95px;
    right: 95px
}

.y-95 {
    left: 95px;
    right: 95px
}

.t-100 {
    top: 100px
}

.r-100 {
    right: 100px
}

.b-100 {
    bottom: 100px
}

.l-100 {
    left: 100px
}

.t--100 {
    top: -100px
}

.r--100 {
    right: -100px
}

.b--100 {
    bottom: -100px
}

.l--100 {
    left: -100px
}

.a-100 {
    top: 100px;
    right: 100px;
    bottom: 100px;
    left: 100px
}

.x-100 {
    left: 100px;
    right: 100px
}

.y-100 {
    left: 100px;
    right: 100px
}

.z-index-0 {
    z-index: 0
}

.z-index-10 {
    z-index: 10
}

.z-index-50 {
    z-index: 50
}

.z-index-100 {
    z-index: 100
}

.z-index-150 {
    z-index: 150
}

.z-index-200 {
    z-index: 200
}

@media (min-width: 480px) {
    .pos-xs-absolute {
        position: absolute
    }

    .pos-xs-relative {
        position: relative
    }

    .pos-xs-fixed {
        position: fixed
    }

    .pos-xs-static {
        position: static
    }

    .t-xs-auto {
        top: auto
    }

    .r-xs-auto {
        right: auto
    }

    .b-xs-auto {
        bottom: auto
    }

    .l-xs-auto {
        left: auto
    }
}

@media (min-width: 576px) {
    .pos-sm-absolute {
        position: absolute
    }

    .pos-sm-relative {
        position: relative
    }

    .pos-sm-fixed {
        position: fixed
    }

    .pos-sm-static {
        position: static
    }

    .t-sm-auto {
        top: auto
    }

    .r-sm-auto {
        right: auto
    }

    .b-sm-auto {
        bottom: auto
    }

    .l-sm-auto {
        left: auto
    }
}

@media (min-width: 768px) {
    .pos-md-absolute {
        position: absolute
    }

    .pos-md-relative {
        position: relative
    }

    .pos-md-fixed {
        position: fixed
    }

    .pos-md-static {
        position: static
    }

    .t-md-auto {
        top: auto
    }

    .r-md-auto {
        right: auto
    }

    .b-md-auto {
        bottom: auto
    }

    .l-md-auto {
        left: auto
    }
}

@media (min-width: 992px) {
    .pos-lg-absolute {
        position: absolute
    }

    .pos-lg-relative {
        position: relative
    }

    .pos-lg-fixed {
        position: fixed
    }

    .pos-lg-static {
        position: static
    }

    .t-lg-auto {
        top: auto
    }

    .r-lg-auto {
        right: auto
    }

    .b-lg-auto {
        bottom: auto
    }

    .l-lg-auto {
        left: auto
    }
}

@media (min-width: 1200px) {
    .pos-xl-absolute {
        position: absolute
    }

    .pos-xl-relative {
        position: relative
    }

    .pos-xl-fixed {
        position: fixed
    }

    .pos-xl-static {
        position: static
    }

    .t-xl-auto {
        top: auto
    }

    .r-xl-auto {
        right: auto
    }

    .b-xl-auto {
        bottom: auto
    }

    .l-xl-auto {
        left: auto
    }
}

.t--1 {
    top: -1px
}

.l--1 {
    left: -1px
}

.l-0-f {
    left: 0 !important
}

.rotate-90 {
    transform: rotate(90deg)
}

.rotate--90 {
    transform: rotate(-90deg)
}

.tx-brand-01 {
    color: #0168fa !important
}

.tx-brand-02 {
    color: #042893 !important
}

.tx-brand-03 {
    color: #1ce1ac !important
}

.tx-color-01 {
    color: #001737
}

.tx-color-02 {
    color: #1b2e4b
}

.tx-color-03 {
    color: #8392a5
}

.tx-color-04 {
    color: #c0ccda
}

.tx-white {
    color: #fff
}

.tx-black {
    color: #000
}

.tx-primary {
    color: #0168fa
}

.tx-success {
    color: #10b759
}

.tx-warning {
    color: #ffc107
}

.tx-danger {
    color: #dc3545
}

.tx-info {
    color: #00b8d4
}

.tx-teal {
    color: #0cc
}

.tx-indigo {
    color: #5b47fb
}

.tx-purple {
    color: #6f42c1
}

.tx-orange {
    color: #fd7e14
}

.tx-pink {
    color: #f10075
}

.tx-dark {
    color: #1b2e4b
}

.tx-inverse {
    color: #001737
}

.tx-gray-100 {
    color: #f4f5f8
}

.tx-gray-200 {
    color: #e3e7ed
}

.tx-gray-300 {
    color: #cdd4e0
}

.tx-gray-400 {
    color: #b4bdce
}

.tx-gray-500 {
    color: #97a3b9
}

.tx-gray-600 {
    color: #7987a1
}

.tx-gray-700 {
    color: #596882
}

.tx-gray-800 {
    color: #3b4863
}

.tx-gray-900 {
    color: #1c273c
}

.tx-white-2 {
    color: rgba(255, 255, 255, 0.2)
}

.tx-white-3 {
    color: rgba(255, 255, 255, 0.3)
}

.tx-white-4 {
    color: rgba(255, 255, 255, 0.4)
}

.tx-white-5 {
    color: rgba(255, 255, 255, 0.5)
}

.tx-white-6 {
    color: rgba(255, 255, 255, 0.6)
}

.tx-white-7 {
    color: rgba(255, 255, 255, 0.7)
}

.tx-white-8 {
    color: rgba(255, 255, 255, 0.8)
}

.tx-bolder {
    font-weight: 900
}

.tx-bold {
    font-weight: 700
}

.tx-semibold {
    font-weight: 600
}

.tx-medium {
    font-weight: 500
}

.tx-normal {
    font-weight: 400
}

.tx-light {
    font-weight: 300
}

.tx-thin {
    font-weight: 200
}

.tx-xthin {
    font-weight: 100
}

.tx-sans {
    font-family: -apple-system, BlinkMacSystemFont, "Inter UI", Roboto, sans-serif
}

.tx-lato {
    font-family: 'Lato', sans-serif
}

.tx-interui {
    font-family: 'Inter UI', sans-serif
}

.tx-roboto {
    font-family: 'Roboto', sans-serif
}

.tx-rubik {
    font-family: 'Rubik', sans-serif
}

.tx-spacing-1 {
    letter-spacing: 0.5px
}

.tx-spacing-2 {
    letter-spacing: 1px
}

.tx-spacing-3 {
    letter-spacing: 1.5px
}

.tx-spacing-4 {
    letter-spacing: 2px
}

.tx-spacing-5 {
    letter-spacing: 2.5px
}

.tx-spacing-6 {
    letter-spacing: 3px
}

.tx-spacing-7 {
    letter-spacing: 3.5px
}

.tx-spacing-8 {
    letter-spacing: 4px
}

.tx-spacing--1 {
    letter-spacing: -0.5px
}

.tx-spacing--2 {
    letter-spacing: -1px
}

.tx-spacing--3 {
    letter-spacing: -1.5px
}

.tx-spacing--4 {
    letter-spacing: -2px
}

.tx-spacing--5 {
    letter-spacing: -2.5px
}

.tx-spacing--6 {
    letter-spacing: -3px
}

.tx-spacing--7 {
    letter-spacing: -3.5px
}

.tx-spacing--8 {
    letter-spacing: -4px
}

.tx-uppercase {
    text-transform: uppercase
}

.tx-lowercase {
    text-transform: lowercase
}

.tx-transform-none {
    text-transform: none
}

.tx-shadow-none {
    text-shadow: none
}

.tx-center {
    text-align: center
}

.tx-right {
    text-align: right
}

.tx-left {
    text-align: left
}

.tx-center-f {
    text-align: center !important
}

.tx-right-f {
    text-align: right !important
}

.tx-left-f {
    text-align: left !important
}

.tx-italic {
    font-style: italic
}

.tx-style-normal {
    font-style: normal
}

.tx-nowrap {
    white-space: nowrap
}

.tx-indent--1 {
    text-indent: -1px
}

.tx-indent--2 {
    text-indent: -2px
}

.lh-base {
    line-height: 1.5
}

.lh-normal {
    line-height: normal
}

.lh-0 {
    line-height: 0
}

.lh--1 {
    line-height: .1
}

.lh--2 {
    line-height: .2
}

.lh--3 {
    line-height: .3
}

.lh--4 {
    line-height: .4
}

.lh--5 {
    line-height: .5
}

.lh--6 {
    line-height: .6
}

.lh--7 {
    line-height: .7
}

.lh--8 {
    line-height: .8
}

.lh--9 {
    line-height: .9
}

.lh-1 {
    line-height: 1.1
}

.lh-2 {
    line-height: 1.2
}

.lh-3 {
    line-height: 1.3
}

.lh-4 {
    line-height: 1.4
}

.lh-5 {
    line-height: 1.5
}

.lh-6 {
    line-height: 1.6
}

.lh-7 {
    line-height: 1.7
}

.lh-8 {
    line-height: 1.8
}

.lh-9 {
    line-height: 1.9
}

.lh-10 {
    line-height: 2.0
}

.lh-11 {
    line-height: 2.1
}

.lh-12 {
    line-height: 2.2
}

.lh-13 {
    line-height: 2.3
}

.lh-14 {
    line-height: 2.4
}

.lh-15 {
    line-height: 2.5
}

.valign-top {
    vertical-align: top
}

.valign-middle {
    vertical-align: middle
}

.valign-bottom {
    vertical-align: baseline
}

.valign-top-f {
    vertical-align: top !important
}

.valign-middle-f {
    vertical-align: middle !important
}

.valign-bottom-f {
    vertical-align: baseline !important
}

.tx-base {
    font-size: .875rem
}

.tx-8 {
    font-size: 8px
}

.tx-8-f {
    font-size: 8px !important
}

.tx-9 {
    font-size: 9px
}

.tx-9-f {
    font-size: 9px !important
}

.tx-10 {
    font-size: 10px
}

.tx-10-f {
    font-size: 10px !important
}

.tx-11 {
    font-size: 11px
}

.tx-11-f {
    font-size: 11px !important
}

.tx-12 {
    font-size: 12px
}

.tx-12-f {
    font-size: 12px !important
}

.tx-13 {
    font-size: 13px
}

.tx-13-f {
    font-size: 13px !important
}

.tx-14 {
    font-size: 14px
}

.tx-14-f {
    font-size: 14px !important
}

.tx-15 {
    font-size: 15px
}

.tx-15-f {
    font-size: 15px !important
}

.tx-16 {
    font-size: 16px
}

.tx-16-f {
    font-size: 16px !important
}

.tx-18 {
    font-size: 18px
}

.tx-18-f {
    font-size: 18px !important
}

.tx-20 {
    font-size: 20px
}

.tx-20-f {
    font-size: 20px !important
}

.tx-22 {
    font-size: 22px
}

.tx-22-f {
    font-size: 22px !important
}

.tx-24 {
    font-size: 24px
}

.tx-24-f {
    font-size: 24px !important
}

.tx-26 {
    font-size: 26px
}

.tx-26-f {
    font-size: 26px !important
}

.tx-28 {
    font-size: 28px
}

.tx-28-f {
    font-size: 28px !important
}

.tx-30 {
    font-size: 30px
}

.tx-30-f {
    font-size: 30px !important
}

.tx-32 {
    font-size: 32px
}

.tx-32-f {
    font-size: 32px !important
}

.tx-34 {
    font-size: 34px
}

.tx-34-f {
    font-size: 34px !important
}

.tx-36 {
    font-size: 36px
}

.tx-36-f {
    font-size: 36px !important
}

.tx-38 {
    font-size: 38px
}

.tx-38-f {
    font-size: 38px !important
}

.tx-40 {
    font-size: 40px
}

.tx-40-f {
    font-size: 40px !important
}

.tx-42 {
    font-size: 42px
}

.tx-42-f {
    font-size: 42px !important
}

.tx-44 {
    font-size: 44px
}

.tx-44-f {
    font-size: 44px !important
}

.tx-46 {
    font-size: 46px
}

.tx-46-f {
    font-size: 46px !important
}

.tx-48 {
    font-size: 48px
}

.tx-48-f {
    font-size: 48px !important
}

.tx-50 {
    font-size: 50px
}

.tx-50-f {
    font-size: 50px !important
}

.tx-52 {
    font-size: 52px
}

.tx-52-f {
    font-size: 52px !important
}

.tx-54 {
    font-size: 54px
}

.tx-54-f {
    font-size: 54px !important
}

.tx-56 {
    font-size: 56px
}

.tx-56-f {
    font-size: 56px !important
}

.tx-58 {
    font-size: 58px
}

.tx-58-f {
    font-size: 58px !important
}

.tx-60 {
    font-size: 60px
}

.tx-60-f {
    font-size: 60px !important
}

.tx-62 {
    font-size: 62px
}

.tx-62-f {
    font-size: 62px !important
}

.tx-64 {
    font-size: 64px
}

.tx-64-f {
    font-size: 64px !important
}

.tx-66 {
    font-size: 66px
}

.tx-66-f {
    font-size: 66px !important
}

.tx-68 {
    font-size: 68px
}

.tx-68-f {
    font-size: 68px !important
}

.tx-70 {
    font-size: 70px
}

.tx-70-f {
    font-size: 70px !important
}

.tx-72 {
    font-size: 72px
}

.tx-72-f {
    font-size: 72px !important
}

.tx-74 {
    font-size: 74px
}

.tx-74-f {
    font-size: 74px !important
}

.tx-76 {
    font-size: 76px
}

.tx-76-f {
    font-size: 76px !important
}

.tx-78 {
    font-size: 78px
}

.tx-78-f {
    font-size: 78px !important
}

.tx-80 {
    font-size: 80px
}

.tx-80-f {
    font-size: 80px !important
}

.tx-82 {
    font-size: 82px
}

.tx-82-f {
    font-size: 82px !important
}

.tx-84 {
    font-size: 84px
}

.tx-84-f {
    font-size: 84px !important
}

.tx-86 {
    font-size: 86px
}

.tx-86-f {
    font-size: 86px !important
}

.tx-88 {
    font-size: 88px
}

.tx-88-f {
    font-size: 88px !important
}

.tx-90 {
    font-size: 90px
}

.tx-90-f {
    font-size: 90px !important
}

.tx-92 {
    font-size: 92px
}

.tx-92-f {
    font-size: 92px !important
}

.tx-94 {
    font-size: 94px
}

.tx-94-f {
    font-size: 94px !important
}

.tx-96 {
    font-size: 96px
}

.tx-96-f {
    font-size: 96px !important
}

.tx-98 {
    font-size: 98px
}

.tx-98-f {
    font-size: 98px !important
}

.tx-100 {
    font-size: 100px
}

.tx-100-f {
    font-size: 100px !important
}

.tx-102 {
    font-size: 102px
}

.tx-102-f {
    font-size: 102px !important
}

.tx-104 {
    font-size: 104px
}

.tx-104-f {
    font-size: 104px !important
}

.tx-106 {
    font-size: 106px
}

.tx-106-f {
    font-size: 106px !important
}

.tx-108 {
    font-size: 108px
}

.tx-108-f {
    font-size: 108px !important
}

.tx-110 {
    font-size: 110px
}

.tx-110-f {
    font-size: 110px !important
}

.tx-112 {
    font-size: 112px
}

.tx-112-f {
    font-size: 112px !important
}

.tx-114 {
    font-size: 114px
}

.tx-114-f {
    font-size: 114px !important
}

.tx-116 {
    font-size: 116px
}

.tx-116-f {
    font-size: 116px !important
}

.tx-118 {
    font-size: 118px
}

.tx-118-f {
    font-size: 118px !important
}

.tx-120 {
    font-size: 120px
}

.tx-120-f {
    font-size: 120px !important
}

.tx-122 {
    font-size: 122px
}

.tx-122-f {
    font-size: 122px !important
}

.tx-124 {
    font-size: 124px
}

.tx-124-f {
    font-size: 124px !important
}

.tx-126 {
    font-size: 126px
}

.tx-126-f {
    font-size: 126px !important
}

.tx-128 {
    font-size: 128px
}

.tx-128-f {
    font-size: 128px !important
}

.tx-130 {
    font-size: 130px
}

.tx-130-f {
    font-size: 130px !important
}

.tx-132 {
    font-size: 132px
}

.tx-132-f {
    font-size: 132px !important
}

.tx-134 {
    font-size: 134px
}

.tx-134-f {
    font-size: 134px !important
}

.tx-136 {
    font-size: 136px
}

.tx-136-f {
    font-size: 136px !important
}

.tx-138 {
    font-size: 138px
}

.tx-138-f {
    font-size: 138px !important
}

.tx-140 {
    font-size: 140px
}

.tx-140-f {
    font-size: 140px !important
}

@media (min-width: 480px) {
    .tx-xs-8 {
        font-size: 8px
    }

    .tx-xs-8-f {
        font-size: 8px !important
    }

    .tx-xs-9 {
        font-size: 9px
    }

    .tx-xs-9-f {
        font-size: 9px !important
    }

    .tx-xs-10 {
        font-size: 10px
    }

    .tx-xs-10-f {
        font-size: 10px !important
    }

    .tx-xs-11 {
        font-size: 11px
    }

    .tx-xs-11-f {
        font-size: 11px !important
    }

    .tx-xs-12 {
        font-size: 12px
    }

    .tx-xs-12-f {
        font-size: 12px !important
    }

    .tx-xs-13 {
        font-size: 13px
    }

    .tx-xs-13-f {
        font-size: 13px !important
    }

    .tx-xs-14 {
        font-size: 14px
    }

    .tx-xs-14-f {
        font-size: 14px !important
    }

    .tx-xs-15 {
        font-size: 15px
    }

    .tx-xs-15-f {
        font-size: 15px !important
    }

    .tx-xs-16 {
        font-size: 16px
    }

    .tx-xs-16-f {
        font-size: 16px !important
    }

    .tx-xs-18 {
        font-size: 18px
    }

    .tx-xs-18-f {
        font-size: 18px !important
    }

    .tx-xs-20 {
        font-size: 20px
    }

    .tx-xs-20-f {
        font-size: 20px !important
    }

    .tx-xs-22 {
        font-size: 22px
    }

    .tx-xs-22-f {
        font-size: 22px !important
    }

    .tx-xs-24 {
        font-size: 24px
    }

    .tx-xs-24-f {
        font-size: 24px !important
    }

    .tx-xs-26 {
        font-size: 26px
    }

    .tx-xs-26-f {
        font-size: 26px !important
    }

    .tx-xs-28 {
        font-size: 28px
    }

    .tx-xs-28-f {
        font-size: 28px !important
    }

    .tx-xs-30 {
        font-size: 30px
    }

    .tx-xs-30-f {
        font-size: 30px !important
    }

    .tx-xs-32 {
        font-size: 32px
    }

    .tx-xs-32-f {
        font-size: 32px !important
    }

    .tx-xs-34 {
        font-size: 34px
    }

    .tx-xs-34-f {
        font-size: 34px !important
    }

    .tx-xs-36 {
        font-size: 36px
    }

    .tx-xs-36-f {
        font-size: 36px !important
    }

    .tx-xs-38 {
        font-size: 38px
    }

    .tx-xs-38-f {
        font-size: 38px !important
    }

    .tx-xs-40 {
        font-size: 40px
    }

    .tx-xs-40-f {
        font-size: 40px !important
    }

    .tx-xs-42 {
        font-size: 42px
    }

    .tx-xs-42-f {
        font-size: 42px !important
    }

    .tx-xs-44 {
        font-size: 44px
    }

    .tx-xs-44-f {
        font-size: 44px !important
    }

    .tx-xs-46 {
        font-size: 46px
    }

    .tx-xs-46-f {
        font-size: 46px !important
    }

    .tx-xs-48 {
        font-size: 48px
    }

    .tx-xs-48-f {
        font-size: 48px !important
    }

    .tx-xs-50 {
        font-size: 50px
    }

    .tx-xs-50-f {
        font-size: 50px !important
    }

    .tx-xs-52 {
        font-size: 52px
    }

    .tx-xs-52-f {
        font-size: 52px !important
    }

    .tx-xs-54 {
        font-size: 54px
    }

    .tx-xs-54-f {
        font-size: 54px !important
    }

    .tx-xs-56 {
        font-size: 56px
    }

    .tx-xs-56-f {
        font-size: 56px !important
    }

    .tx-xs-58 {
        font-size: 58px
    }

    .tx-xs-58-f {
        font-size: 58px !important
    }

    .tx-xs-60 {
        font-size: 60px
    }

    .tx-xs-60-f {
        font-size: 60px !important
    }

    .tx-xs-62 {
        font-size: 62px
    }

    .tx-xs-62-f {
        font-size: 62px !important
    }

    .tx-xs-64 {
        font-size: 64px
    }

    .tx-xs-64-f {
        font-size: 64px !important
    }

    .tx-xs-66 {
        font-size: 66px
    }

    .tx-xs-66-f {
        font-size: 66px !important
    }

    .tx-xs-68 {
        font-size: 68px
    }

    .tx-xs-68-f {
        font-size: 68px !important
    }

    .tx-xs-70 {
        font-size: 70px
    }

    .tx-xs-70-f {
        font-size: 70px !important
    }

    .tx-xs-72 {
        font-size: 72px
    }

    .tx-xs-72-f {
        font-size: 72px !important
    }

    .tx-xs-74 {
        font-size: 74px
    }

    .tx-xs-74-f {
        font-size: 74px !important
    }

    .tx-xs-76 {
        font-size: 76px
    }

    .tx-xs-76-f {
        font-size: 76px !important
    }

    .tx-xs-78 {
        font-size: 78px
    }

    .tx-xs-78-f {
        font-size: 78px !important
    }

    .tx-xs-80 {
        font-size: 80px
    }

    .tx-xs-80-f {
        font-size: 80px !important
    }

    .tx-xs-82 {
        font-size: 82px
    }

    .tx-xs-82-f {
        font-size: 82px !important
    }

    .tx-xs-84 {
        font-size: 84px
    }

    .tx-xs-84-f {
        font-size: 84px !important
    }

    .tx-xs-86 {
        font-size: 86px
    }

    .tx-xs-86-f {
        font-size: 86px !important
    }

    .tx-xs-88 {
        font-size: 88px
    }

    .tx-xs-88-f {
        font-size: 88px !important
    }

    .tx-xs-90 {
        font-size: 90px
    }

    .tx-xs-90-f {
        font-size: 90px !important
    }

    .tx-xs-92 {
        font-size: 92px
    }

    .tx-xs-92-f {
        font-size: 92px !important
    }

    .tx-xs-94 {
        font-size: 94px
    }

    .tx-xs-94-f {
        font-size: 94px !important
    }

    .tx-xs-96 {
        font-size: 96px
    }

    .tx-xs-96-f {
        font-size: 96px !important
    }

    .tx-xs-98 {
        font-size: 98px
    }

    .tx-xs-98-f {
        font-size: 98px !important
    }

    .tx-xs-100 {
        font-size: 100px
    }

    .tx-xs-100-f {
        font-size: 100px !important
    }

    .tx-xs-102 {
        font-size: 102px
    }

    .tx-xs-102-f {
        font-size: 102px !important
    }

    .tx-xs-104 {
        font-size: 104px
    }

    .tx-xs-104-f {
        font-size: 104px !important
    }

    .tx-xs-106 {
        font-size: 106px
    }

    .tx-xs-106-f {
        font-size: 106px !important
    }

    .tx-xs-108 {
        font-size: 108px
    }

    .tx-xs-108-f {
        font-size: 108px !important
    }

    .tx-xs-110 {
        font-size: 110px
    }

    .tx-xs-110-f {
        font-size: 110px !important
    }

    .tx-xs-112 {
        font-size: 112px
    }

    .tx-xs-112-f {
        font-size: 112px !important
    }

    .tx-xs-114 {
        font-size: 114px
    }

    .tx-xs-114-f {
        font-size: 114px !important
    }

    .tx-xs-116 {
        font-size: 116px
    }

    .tx-xs-116-f {
        font-size: 116px !important
    }

    .tx-xs-118 {
        font-size: 118px
    }

    .tx-xs-118-f {
        font-size: 118px !important
    }

    .tx-xs-120 {
        font-size: 120px
    }

    .tx-xs-120-f {
        font-size: 120px !important
    }

    .tx-xs-122 {
        font-size: 122px
    }

    .tx-xs-122-f {
        font-size: 122px !important
    }

    .tx-xs-124 {
        font-size: 124px
    }

    .tx-xs-124-f {
        font-size: 124px !important
    }

    .tx-xs-126 {
        font-size: 126px
    }

    .tx-xs-126-f {
        font-size: 126px !important
    }

    .tx-xs-128 {
        font-size: 128px
    }

    .tx-xs-128-f {
        font-size: 128px !important
    }

    .tx-xs-130 {
        font-size: 130px
    }

    .tx-xs-130-f {
        font-size: 130px !important
    }

    .tx-xs-132 {
        font-size: 132px
    }

    .tx-xs-132-f {
        font-size: 132px !important
    }

    .tx-xs-134 {
        font-size: 134px
    }

    .tx-xs-134-f {
        font-size: 134px !important
    }

    .tx-xs-136 {
        font-size: 136px
    }

    .tx-xs-136-f {
        font-size: 136px !important
    }

    .tx-xs-138 {
        font-size: 138px
    }

    .tx-xs-138-f {
        font-size: 138px !important
    }

    .tx-xs-140 {
        font-size: 140px
    }

    .tx-xs-140-f {
        font-size: 140px !important
    }

    .tx-xs-base {
        font-size: .875rem
    }

    .tx-xs-bold {
        font-weight: 700
    }

    .tx-xs-semibold {
        font-weight: 600
    }

    .tx-xs-medium {
        font-weight: 500
    }

    .tx-xs-normal {
        font-weight: 400
    }

    .tx-xs-light {
        font-weight: 300
    }

    .tx-xs-thin {
        font-weight: 200
    }

    .tx-xs-xthin {
        font-weight: 100
    }

    .lh-xs-0 {
        line-height: 0
    }

    .lh-xs-1 {
        line-height: 1.1
    }

    .lh-xs-2 {
        line-height: 1.2
    }

    .lh-xs-3 {
        line-height: 1.3
    }

    .lh-xs-4 {
        line-height: 1.4
    }

    .lh-xs-5 {
        line-height: 1.5
    }

    .lh-xs-6 {
        line-height: 1.6
    }

    .lh-xs-7 {
        line-height: 1.7
    }

    .lh-xs-8 {
        line-height: 1.8
    }

    .lh-xs-9 {
        line-height: 1.9
    }

    .lh-xs-10 {
        line-height: 2.0
    }

    .lh-xs-11 {
        line-height: 2.1
    }

    .lh-xs-12 {
        line-height: 2.2
    }

    .lh-xs-13 {
        line-height: 2.3
    }

    .lh-xs-14 {
        line-height: 2.4
    }

    .lh-xs-15 {
        line-height: 2.5
    }

    .tx-xs-center {
        text-align: center
    }

    .tx-xs-right {
        text-align: right
    }

    .tx-xs-left {
        text-align: left
    }

    .tx-xs-center-f {
        text-align: center !important
    }

    .tx-xs-right-f {
        text-align: right !important
    }

    .tx-xs-left-f {
        text-align: left !important
    }
}

@media (min-width: 576px) {
    .tx-sm-8 {
        font-size: 8px
    }

    .tx-sm-8-f {
        font-size: 8px !important
    }

    .tx-sm-9 {
        font-size: 9px
    }

    .tx-sm-9-f {
        font-size: 9px !important
    }

    .tx-sm-10 {
        font-size: 10px
    }

    .tx-sm-10-f {
        font-size: 10px !important
    }

    .tx-sm-11 {
        font-size: 11px
    }

    .tx-sm-11-f {
        font-size: 11px !important
    }

    .tx-sm-12 {
        font-size: 12px
    }

    .tx-sm-12-f {
        font-size: 12px !important
    }

    .tx-sm-13 {
        font-size: 13px
    }

    .tx-sm-13-f {
        font-size: 13px !important
    }

    .tx-sm-14 {
        font-size: 14px
    }

    .tx-sm-14-f {
        font-size: 14px !important
    }

    .tx-sm-15 {
        font-size: 15px
    }

    .tx-sm-15-f {
        font-size: 15px !important
    }

    .tx-sm-16 {
        font-size: 16px
    }

    .tx-sm-16-f {
        font-size: 16px !important
    }

    .tx-sm-18 {
        font-size: 18px
    }

    .tx-sm-18-f {
        font-size: 18px !important
    }

    .tx-sm-20 {
        font-size: 20px
    }

    .tx-sm-20-f {
        font-size: 20px !important
    }

    .tx-sm-22 {
        font-size: 22px
    }

    .tx-sm-22-f {
        font-size: 22px !important
    }

    .tx-sm-24 {
        font-size: 24px
    }

    .tx-sm-24-f {
        font-size: 24px !important
    }

    .tx-sm-26 {
        font-size: 26px
    }

    .tx-sm-26-f {
        font-size: 26px !important
    }

    .tx-sm-28 {
        font-size: 28px
    }

    .tx-sm-28-f {
        font-size: 28px !important
    }

    .tx-sm-30 {
        font-size: 30px
    }

    .tx-sm-30-f {
        font-size: 30px !important
    }

    .tx-sm-32 {
        font-size: 32px
    }

    .tx-sm-32-f {
        font-size: 32px !important
    }

    .tx-sm-34 {
        font-size: 34px
    }

    .tx-sm-34-f {
        font-size: 34px !important
    }

    .tx-sm-36 {
        font-size: 36px
    }

    .tx-sm-36-f {
        font-size: 36px !important
    }

    .tx-sm-38 {
        font-size: 38px
    }

    .tx-sm-38-f {
        font-size: 38px !important
    }

    .tx-sm-40 {
        font-size: 40px
    }

    .tx-sm-40-f {
        font-size: 40px !important
    }

    .tx-sm-42 {
        font-size: 42px
    }

    .tx-sm-42-f {
        font-size: 42px !important
    }

    .tx-sm-44 {
        font-size: 44px
    }

    .tx-sm-44-f {
        font-size: 44px !important
    }

    .tx-sm-46 {
        font-size: 46px
    }

    .tx-sm-46-f {
        font-size: 46px !important
    }

    .tx-sm-48 {
        font-size: 48px
    }

    .tx-sm-48-f {
        font-size: 48px !important
    }

    .tx-sm-50 {
        font-size: 50px
    }

    .tx-sm-50-f {
        font-size: 50px !important
    }

    .tx-sm-52 {
        font-size: 52px
    }

    .tx-sm-52-f {
        font-size: 52px !important
    }

    .tx-sm-54 {
        font-size: 54px
    }

    .tx-sm-54-f {
        font-size: 54px !important
    }

    .tx-sm-56 {
        font-size: 56px
    }

    .tx-sm-56-f {
        font-size: 56px !important
    }

    .tx-sm-58 {
        font-size: 58px
    }

    .tx-sm-58-f {
        font-size: 58px !important
    }

    .tx-sm-60 {
        font-size: 60px
    }

    .tx-sm-60-f {
        font-size: 60px !important
    }

    .tx-sm-62 {
        font-size: 62px
    }

    .tx-sm-62-f {
        font-size: 62px !important
    }

    .tx-sm-64 {
        font-size: 64px
    }

    .tx-sm-64-f {
        font-size: 64px !important
    }

    .tx-sm-66 {
        font-size: 66px
    }

    .tx-sm-66-f {
        font-size: 66px !important
    }

    .tx-sm-68 {
        font-size: 68px
    }

    .tx-sm-68-f {
        font-size: 68px !important
    }

    .tx-sm-70 {
        font-size: 70px
    }

    .tx-sm-70-f {
        font-size: 70px !important
    }

    .tx-sm-72 {
        font-size: 72px
    }

    .tx-sm-72-f {
        font-size: 72px !important
    }

    .tx-sm-74 {
        font-size: 74px
    }

    .tx-sm-74-f {
        font-size: 74px !important
    }

    .tx-sm-76 {
        font-size: 76px
    }

    .tx-sm-76-f {
        font-size: 76px !important
    }

    .tx-sm-78 {
        font-size: 78px
    }

    .tx-sm-78-f {
        font-size: 78px !important
    }

    .tx-sm-80 {
        font-size: 80px
    }

    .tx-sm-80-f {
        font-size: 80px !important
    }

    .tx-sm-82 {
        font-size: 82px
    }

    .tx-sm-82-f {
        font-size: 82px !important
    }

    .tx-sm-84 {
        font-size: 84px
    }

    .tx-sm-84-f {
        font-size: 84px !important
    }

    .tx-sm-86 {
        font-size: 86px
    }

    .tx-sm-86-f {
        font-size: 86px !important
    }

    .tx-sm-88 {
        font-size: 88px
    }

    .tx-sm-88-f {
        font-size: 88px !important
    }

    .tx-sm-90 {
        font-size: 90px
    }

    .tx-sm-90-f {
        font-size: 90px !important
    }

    .tx-sm-92 {
        font-size: 92px
    }

    .tx-sm-92-f {
        font-size: 92px !important
    }

    .tx-sm-94 {
        font-size: 94px
    }

    .tx-sm-94-f {
        font-size: 94px !important
    }

    .tx-sm-96 {
        font-size: 96px
    }

    .tx-sm-96-f {
        font-size: 96px !important
    }

    .tx-sm-98 {
        font-size: 98px
    }

    .tx-sm-98-f {
        font-size: 98px !important
    }

    .tx-sm-100 {
        font-size: 100px
    }

    .tx-sm-100-f {
        font-size: 100px !important
    }

    .tx-sm-102 {
        font-size: 102px
    }

    .tx-sm-102-f {
        font-size: 102px !important
    }

    .tx-sm-104 {
        font-size: 104px
    }

    .tx-sm-104-f {
        font-size: 104px !important
    }

    .tx-sm-106 {
        font-size: 106px
    }

    .tx-sm-106-f {
        font-size: 106px !important
    }

    .tx-sm-108 {
        font-size: 108px
    }

    .tx-sm-108-f {
        font-size: 108px !important
    }

    .tx-sm-110 {
        font-size: 110px
    }

    .tx-sm-110-f {
        font-size: 110px !important
    }

    .tx-sm-112 {
        font-size: 112px
    }

    .tx-sm-112-f {
        font-size: 112px !important
    }

    .tx-sm-114 {
        font-size: 114px
    }

    .tx-sm-114-f {
        font-size: 114px !important
    }

    .tx-sm-116 {
        font-size: 116px
    }

    .tx-sm-116-f {
        font-size: 116px !important
    }

    .tx-sm-118 {
        font-size: 118px
    }

    .tx-sm-118-f {
        font-size: 118px !important
    }

    .tx-sm-120 {
        font-size: 120px
    }

    .tx-sm-120-f {
        font-size: 120px !important
    }

    .tx-sm-122 {
        font-size: 122px
    }

    .tx-sm-122-f {
        font-size: 122px !important
    }

    .tx-sm-124 {
        font-size: 124px
    }

    .tx-sm-124-f {
        font-size: 124px !important
    }

    .tx-sm-126 {
        font-size: 126px
    }

    .tx-sm-126-f {
        font-size: 126px !important
    }

    .tx-sm-128 {
        font-size: 128px
    }

    .tx-sm-128-f {
        font-size: 128px !important
    }

    .tx-sm-130 {
        font-size: 130px
    }

    .tx-sm-130-f {
        font-size: 130px !important
    }

    .tx-sm-132 {
        font-size: 132px
    }

    .tx-sm-132-f {
        font-size: 132px !important
    }

    .tx-sm-134 {
        font-size: 134px
    }

    .tx-sm-134-f {
        font-size: 134px !important
    }

    .tx-sm-136 {
        font-size: 136px
    }

    .tx-sm-136-f {
        font-size: 136px !important
    }

    .tx-sm-138 {
        font-size: 138px
    }

    .tx-sm-138-f {
        font-size: 138px !important
    }

    .tx-sm-140 {
        font-size: 140px
    }

    .tx-sm-140-f {
        font-size: 140px !important
    }

    .tx-sm-base {
        font-size: .875rem
    }

    .tx-sm-bold {
        font-weight: 700
    }

    .tx-sm-semibold {
        font-weight: 600
    }

    .tx-sm-medium {
        font-weight: 500
    }

    .tx-sm-normal {
        font-weight: 400
    }

    .tx-sm-light {
        font-weight: 300
    }

    .tx-sm-thin {
        font-weight: 200
    }

    .tx-sm-xthin {
        font-weight: 100
    }

    .lh-sm-0 {
        line-height: 0
    }

    .lh-sm-1 {
        line-height: 1.1
    }

    .lh-sm-2 {
        line-height: 1.2
    }

    .lh-sm-3 {
        line-height: 1.3
    }

    .lh-sm-4 {
        line-height: 1.4
    }

    .lh-sm-5 {
        line-height: 1.5
    }

    .lh-sm-6 {
        line-height: 1.6
    }

    .lh-sm-7 {
        line-height: 1.7
    }

    .lh-sm-8 {
        line-height: 1.8
    }

    .lh-sm-9 {
        line-height: 1.9
    }

    .lh-sm-10 {
        line-height: 2.0
    }

    .lh-sm-11 {
        line-height: 2.1
    }

    .lh-sm-12 {
        line-height: 2.2
    }

    .lh-sm-13 {
        line-height: 2.3
    }

    .lh-sm-14 {
        line-height: 2.4
    }

    .lh-sm-15 {
        line-height: 2.5
    }

    .tx-sm-center {
        text-align: center
    }

    .tx-sm-right {
        text-align: right
    }

    .tx-sm-left {
        text-align: left
    }

    .tx-sm-center-f {
        text-align: center !important
    }

    .tx-sm-right-f {
        text-align: right !important
    }

    .tx-sm-left-f {
        text-align: left !important
    }
}

@media (min-width: 768px) {
    .tx-md-8 {
        font-size: 8px
    }

    .tx-md-8-f {
        font-size: 8px !important
    }

    .tx-md-9 {
        font-size: 9px
    }

    .tx-md-9-f {
        font-size: 9px !important
    }

    .tx-md-10 {
        font-size: 10px
    }

    .tx-md-10-f {
        font-size: 10px !important
    }

    .tx-md-11 {
        font-size: 11px
    }

    .tx-md-11-f {
        font-size: 11px !important
    }

    .tx-md-12 {
        font-size: 12px
    }

    .tx-md-12-f {
        font-size: 12px !important
    }

    .tx-md-13 {
        font-size: 13px
    }

    .tx-md-13-f {
        font-size: 13px !important
    }

    .tx-md-14 {
        font-size: 14px
    }

    .tx-md-14-f {
        font-size: 14px !important
    }

    .tx-md-15 {
        font-size: 15px
    }

    .tx-md-15-f {
        font-size: 15px !important
    }

    .tx-md-16 {
        font-size: 16px
    }

    .tx-md-16-f {
        font-size: 16px !important
    }

    .tx-md-18 {
        font-size: 18px
    }

    .tx-md-18-f {
        font-size: 18px !important
    }

    .tx-md-20 {
        font-size: 20px
    }

    .tx-md-20-f {
        font-size: 20px !important
    }

    .tx-md-22 {
        font-size: 22px
    }

    .tx-md-22-f {
        font-size: 22px !important
    }

    .tx-md-24 {
        font-size: 24px
    }

    .tx-md-24-f {
        font-size: 24px !important
    }

    .tx-md-26 {
        font-size: 26px
    }

    .tx-md-26-f {
        font-size: 26px !important
    }

    .tx-md-28 {
        font-size: 28px
    }

    .tx-md-28-f {
        font-size: 28px !important
    }

    .tx-md-30 {
        font-size: 30px
    }

    .tx-md-30-f {
        font-size: 30px !important
    }

    .tx-md-32 {
        font-size: 32px
    }

    .tx-md-32-f {
        font-size: 32px !important
    }

    .tx-md-34 {
        font-size: 34px
    }

    .tx-md-34-f {
        font-size: 34px !important
    }

    .tx-md-36 {
        font-size: 36px
    }

    .tx-md-36-f {
        font-size: 36px !important
    }

    .tx-md-38 {
        font-size: 38px
    }

    .tx-md-38-f {
        font-size: 38px !important
    }

    .tx-md-40 {
        font-size: 40px
    }

    .tx-md-40-f {
        font-size: 40px !important
    }

    .tx-md-42 {
        font-size: 42px
    }

    .tx-md-42-f {
        font-size: 42px !important
    }

    .tx-md-44 {
        font-size: 44px
    }

    .tx-md-44-f {
        font-size: 44px !important
    }

    .tx-md-46 {
        font-size: 46px
    }

    .tx-md-46-f {
        font-size: 46px !important
    }

    .tx-md-48 {
        font-size: 48px
    }

    .tx-md-48-f {
        font-size: 48px !important
    }

    .tx-md-50 {
        font-size: 50px
    }

    .tx-md-50-f {
        font-size: 50px !important
    }

    .tx-md-52 {
        font-size: 52px
    }

    .tx-md-52-f {
        font-size: 52px !important
    }

    .tx-md-54 {
        font-size: 54px
    }

    .tx-md-54-f {
        font-size: 54px !important
    }

    .tx-md-56 {
        font-size: 56px
    }

    .tx-md-56-f {
        font-size: 56px !important
    }

    .tx-md-58 {
        font-size: 58px
    }

    .tx-md-58-f {
        font-size: 58px !important
    }

    .tx-md-60 {
        font-size: 60px
    }

    .tx-md-60-f {
        font-size: 60px !important
    }

    .tx-md-62 {
        font-size: 62px
    }

    .tx-md-62-f {
        font-size: 62px !important
    }

    .tx-md-64 {
        font-size: 64px
    }

    .tx-md-64-f {
        font-size: 64px !important
    }

    .tx-md-66 {
        font-size: 66px
    }

    .tx-md-66-f {
        font-size: 66px !important
    }

    .tx-md-68 {
        font-size: 68px
    }

    .tx-md-68-f {
        font-size: 68px !important
    }

    .tx-md-70 {
        font-size: 70px
    }

    .tx-md-70-f {
        font-size: 70px !important
    }

    .tx-md-72 {
        font-size: 72px
    }

    .tx-md-72-f {
        font-size: 72px !important
    }

    .tx-md-74 {
        font-size: 74px
    }

    .tx-md-74-f {
        font-size: 74px !important
    }

    .tx-md-76 {
        font-size: 76px
    }

    .tx-md-76-f {
        font-size: 76px !important
    }

    .tx-md-78 {
        font-size: 78px
    }

    .tx-md-78-f {
        font-size: 78px !important
    }

    .tx-md-80 {
        font-size: 80px
    }

    .tx-md-80-f {
        font-size: 80px !important
    }

    .tx-md-82 {
        font-size: 82px
    }

    .tx-md-82-f {
        font-size: 82px !important
    }

    .tx-md-84 {
        font-size: 84px
    }

    .tx-md-84-f {
        font-size: 84px !important
    }

    .tx-md-86 {
        font-size: 86px
    }

    .tx-md-86-f {
        font-size: 86px !important
    }

    .tx-md-88 {
        font-size: 88px
    }

    .tx-md-88-f {
        font-size: 88px !important
    }

    .tx-md-90 {
        font-size: 90px
    }

    .tx-md-90-f {
        font-size: 90px !important
    }

    .tx-md-92 {
        font-size: 92px
    }

    .tx-md-92-f {
        font-size: 92px !important
    }

    .tx-md-94 {
        font-size: 94px
    }

    .tx-md-94-f {
        font-size: 94px !important
    }

    .tx-md-96 {
        font-size: 96px
    }

    .tx-md-96-f {
        font-size: 96px !important
    }

    .tx-md-98 {
        font-size: 98px
    }

    .tx-md-98-f {
        font-size: 98px !important
    }

    .tx-md-100 {
        font-size: 100px
    }

    .tx-md-100-f {
        font-size: 100px !important
    }

    .tx-md-102 {
        font-size: 102px
    }

    .tx-md-102-f {
        font-size: 102px !important
    }

    .tx-md-104 {
        font-size: 104px
    }

    .tx-md-104-f {
        font-size: 104px !important
    }

    .tx-md-106 {
        font-size: 106px
    }

    .tx-md-106-f {
        font-size: 106px !important
    }

    .tx-md-108 {
        font-size: 108px
    }

    .tx-md-108-f {
        font-size: 108px !important
    }

    .tx-md-110 {
        font-size: 110px
    }

    .tx-md-110-f {
        font-size: 110px !important
    }

    .tx-md-112 {
        font-size: 112px
    }

    .tx-md-112-f {
        font-size: 112px !important
    }

    .tx-md-114 {
        font-size: 114px
    }

    .tx-md-114-f {
        font-size: 114px !important
    }

    .tx-md-116 {
        font-size: 116px
    }

    .tx-md-116-f {
        font-size: 116px !important
    }

    .tx-md-118 {
        font-size: 118px
    }

    .tx-md-118-f {
        font-size: 118px !important
    }

    .tx-md-120 {
        font-size: 120px
    }

    .tx-md-120-f {
        font-size: 120px !important
    }

    .tx-md-122 {
        font-size: 122px
    }

    .tx-md-122-f {
        font-size: 122px !important
    }

    .tx-md-124 {
        font-size: 124px
    }

    .tx-md-124-f {
        font-size: 124px !important
    }

    .tx-md-126 {
        font-size: 126px
    }

    .tx-md-126-f {
        font-size: 126px !important
    }

    .tx-md-128 {
        font-size: 128px
    }

    .tx-md-128-f {
        font-size: 128px !important
    }

    .tx-md-130 {
        font-size: 130px
    }

    .tx-md-130-f {
        font-size: 130px !important
    }

    .tx-md-132 {
        font-size: 132px
    }

    .tx-md-132-f {
        font-size: 132px !important
    }

    .tx-md-134 {
        font-size: 134px
    }

    .tx-md-134-f {
        font-size: 134px !important
    }

    .tx-md-136 {
        font-size: 136px
    }

    .tx-md-136-f {
        font-size: 136px !important
    }

    .tx-md-138 {
        font-size: 138px
    }

    .tx-md-138-f {
        font-size: 138px !important
    }

    .tx-md-140 {
        font-size: 140px
    }

    .tx-md-140-f {
        font-size: 140px !important
    }

    .tx-md-base {
        font-size: .875rem
    }

    .tx-md-bold {
        font-weight: 700
    }

    .tx-md-semibold {
        font-weight: 600
    }

    .tx-md-medium {
        font-weight: 500
    }

    .tx-md-normal {
        font-weight: 400
    }

    .tx-md-light {
        font-weight: 300
    }

    .tx-md-thin {
        font-weight: 200
    }

    .tx-md-xthin {
        font-weight: 100
    }

    .lh-md-0 {
        line-height: 0
    }

    .lh-md-1 {
        line-height: 1.1
    }

    .lh-md-2 {
        line-height: 1.2
    }

    .lh-md-3 {
        line-height: 1.3
    }

    .lh-md-4 {
        line-height: 1.4
    }

    .lh-md-5 {
        line-height: 1.5
    }

    .lh-md-6 {
        line-height: 1.6
    }

    .lh-md-7 {
        line-height: 1.7
    }

    .lh-md-8 {
        line-height: 1.8
    }

    .lh-md-9 {
        line-height: 1.9
    }

    .lh-md-10 {
        line-height: 2.0
    }

    .lh-md-11 {
        line-height: 2.1
    }

    .lh-md-12 {
        line-height: 2.2
    }

    .lh-md-13 {
        line-height: 2.3
    }

    .lh-md-14 {
        line-height: 2.4
    }

    .lh-md-15 {
        line-height: 2.5
    }

    .tx-md-center {
        text-align: center
    }

    .tx-md-right {
        text-align: right
    }

    .tx-md-left {
        text-align: left
    }

    .tx-md-center-f {
        text-align: center !important
    }

    .tx-md-right-f {
        text-align: right !important
    }

    .tx-md-left-f {
        text-align: left !important
    }
}

@media (min-width: 992px) {
    .tx-lg-8 {
        font-size: 8px
    }

    .tx-lg-8-f {
        font-size: 8px !important
    }

    .tx-lg-9 {
        font-size: 9px
    }

    .tx-lg-9-f {
        font-size: 9px !important
    }

    .tx-lg-10 {
        font-size: 10px
    }

    .tx-lg-10-f {
        font-size: 10px !important
    }

    .tx-lg-11 {
        font-size: 11px
    }

    .tx-lg-11-f {
        font-size: 11px !important
    }

    .tx-lg-12 {
        font-size: 12px
    }

    .tx-lg-12-f {
        font-size: 12px !important
    }

    .tx-lg-13 {
        font-size: 13px
    }

    .tx-lg-13-f {
        font-size: 13px !important
    }

    .tx-lg-14 {
        font-size: 14px
    }

    .tx-lg-14-f {
        font-size: 14px !important
    }

    .tx-lg-15 {
        font-size: 15px
    }

    .tx-lg-15-f {
        font-size: 15px !important
    }

    .tx-lg-16 {
        font-size: 16px
    }

    .tx-lg-16-f {
        font-size: 16px !important
    }

    .tx-lg-18 {
        font-size: 18px
    }

    .tx-lg-18-f {
        font-size: 18px !important
    }

    .tx-lg-20 {
        font-size: 20px
    }

    .tx-lg-20-f {
        font-size: 20px !important
    }

    .tx-lg-22 {
        font-size: 22px
    }

    .tx-lg-22-f {
        font-size: 22px !important
    }

    .tx-lg-24 {
        font-size: 24px
    }

    .tx-lg-24-f {
        font-size: 24px !important
    }

    .tx-lg-26 {
        font-size: 26px
    }

    .tx-lg-26-f {
        font-size: 26px !important
    }

    .tx-lg-28 {
        font-size: 28px
    }

    .tx-lg-28-f {
        font-size: 28px !important
    }

    .tx-lg-30 {
        font-size: 30px
    }

    .tx-lg-30-f {
        font-size: 30px !important
    }

    .tx-lg-32 {
        font-size: 32px
    }

    .tx-lg-32-f {
        font-size: 32px !important
    }

    .tx-lg-34 {
        font-size: 34px
    }

    .tx-lg-34-f {
        font-size: 34px !important
    }

    .tx-lg-36 {
        font-size: 36px
    }

    .tx-lg-36-f {
        font-size: 36px !important
    }

    .tx-lg-38 {
        font-size: 38px
    }

    .tx-lg-38-f {
        font-size: 38px !important
    }

    .tx-lg-40 {
        font-size: 40px
    }

    .tx-lg-40-f {
        font-size: 40px !important
    }

    .tx-lg-42 {
        font-size: 42px
    }

    .tx-lg-42-f {
        font-size: 42px !important
    }

    .tx-lg-44 {
        font-size: 44px
    }

    .tx-lg-44-f {
        font-size: 44px !important
    }

    .tx-lg-46 {
        font-size: 46px
    }

    .tx-lg-46-f {
        font-size: 46px !important
    }

    .tx-lg-48 {
        font-size: 48px
    }

    .tx-lg-48-f {
        font-size: 48px !important
    }

    .tx-lg-50 {
        font-size: 50px
    }

    .tx-lg-50-f {
        font-size: 50px !important
    }

    .tx-lg-52 {
        font-size: 52px
    }

    .tx-lg-52-f {
        font-size: 52px !important
    }

    .tx-lg-54 {
        font-size: 54px
    }

    .tx-lg-54-f {
        font-size: 54px !important
    }

    .tx-lg-56 {
        font-size: 56px
    }

    .tx-lg-56-f {
        font-size: 56px !important
    }

    .tx-lg-58 {
        font-size: 58px
    }

    .tx-lg-58-f {
        font-size: 58px !important
    }

    .tx-lg-60 {
        font-size: 60px
    }

    .tx-lg-60-f {
        font-size: 60px !important
    }

    .tx-lg-62 {
        font-size: 62px
    }

    .tx-lg-62-f {
        font-size: 62px !important
    }

    .tx-lg-64 {
        font-size: 64px
    }

    .tx-lg-64-f {
        font-size: 64px !important
    }

    .tx-lg-66 {
        font-size: 66px
    }

    .tx-lg-66-f {
        font-size: 66px !important
    }

    .tx-lg-68 {
        font-size: 68px
    }

    .tx-lg-68-f {
        font-size: 68px !important
    }

    .tx-lg-70 {
        font-size: 70px
    }

    .tx-lg-70-f {
        font-size: 70px !important
    }

    .tx-lg-72 {
        font-size: 72px
    }

    .tx-lg-72-f {
        font-size: 72px !important
    }

    .tx-lg-74 {
        font-size: 74px
    }

    .tx-lg-74-f {
        font-size: 74px !important
    }

    .tx-lg-76 {
        font-size: 76px
    }

    .tx-lg-76-f {
        font-size: 76px !important
    }

    .tx-lg-78 {
        font-size: 78px
    }

    .tx-lg-78-f {
        font-size: 78px !important
    }

    .tx-lg-80 {
        font-size: 80px
    }

    .tx-lg-80-f {
        font-size: 80px !important
    }

    .tx-lg-82 {
        font-size: 82px
    }

    .tx-lg-82-f {
        font-size: 82px !important
    }

    .tx-lg-84 {
        font-size: 84px
    }

    .tx-lg-84-f {
        font-size: 84px !important
    }

    .tx-lg-86 {
        font-size: 86px
    }

    .tx-lg-86-f {
        font-size: 86px !important
    }

    .tx-lg-88 {
        font-size: 88px
    }

    .tx-lg-88-f {
        font-size: 88px !important
    }

    .tx-lg-90 {
        font-size: 90px
    }

    .tx-lg-90-f {
        font-size: 90px !important
    }

    .tx-lg-92 {
        font-size: 92px
    }

    .tx-lg-92-f {
        font-size: 92px !important
    }

    .tx-lg-94 {
        font-size: 94px
    }

    .tx-lg-94-f {
        font-size: 94px !important
    }

    .tx-lg-96 {
        font-size: 96px
    }

    .tx-lg-96-f {
        font-size: 96px !important
    }

    .tx-lg-98 {
        font-size: 98px
    }

    .tx-lg-98-f {
        font-size: 98px !important
    }

    .tx-lg-100 {
        font-size: 100px
    }

    .tx-lg-100-f {
        font-size: 100px !important
    }

    .tx-lg-102 {
        font-size: 102px
    }

    .tx-lg-102-f {
        font-size: 102px !important
    }

    .tx-lg-104 {
        font-size: 104px
    }

    .tx-lg-104-f {
        font-size: 104px !important
    }

    .tx-lg-106 {
        font-size: 106px
    }

    .tx-lg-106-f {
        font-size: 106px !important
    }

    .tx-lg-108 {
        font-size: 108px
    }

    .tx-lg-108-f {
        font-size: 108px !important
    }

    .tx-lg-110 {
        font-size: 110px
    }

    .tx-lg-110-f {
        font-size: 110px !important
    }

    .tx-lg-112 {
        font-size: 112px
    }

    .tx-lg-112-f {
        font-size: 112px !important
    }

    .tx-lg-114 {
        font-size: 114px
    }

    .tx-lg-114-f {
        font-size: 114px !important
    }

    .tx-lg-116 {
        font-size: 116px
    }

    .tx-lg-116-f {
        font-size: 116px !important
    }

    .tx-lg-118 {
        font-size: 118px
    }

    .tx-lg-118-f {
        font-size: 118px !important
    }

    .tx-lg-120 {
        font-size: 120px
    }

    .tx-lg-120-f {
        font-size: 120px !important
    }

    .tx-lg-122 {
        font-size: 122px
    }

    .tx-lg-122-f {
        font-size: 122px !important
    }

    .tx-lg-124 {
        font-size: 124px
    }

    .tx-lg-124-f {
        font-size: 124px !important
    }

    .tx-lg-126 {
        font-size: 126px
    }

    .tx-lg-126-f {
        font-size: 126px !important
    }

    .tx-lg-128 {
        font-size: 128px
    }

    .tx-lg-128-f {
        font-size: 128px !important
    }

    .tx-lg-130 {
        font-size: 130px
    }

    .tx-lg-130-f {
        font-size: 130px !important
    }

    .tx-lg-132 {
        font-size: 132px
    }

    .tx-lg-132-f {
        font-size: 132px !important
    }

    .tx-lg-134 {
        font-size: 134px
    }

    .tx-lg-134-f {
        font-size: 134px !important
    }

    .tx-lg-136 {
        font-size: 136px
    }

    .tx-lg-136-f {
        font-size: 136px !important
    }

    .tx-lg-138 {
        font-size: 138px
    }

    .tx-lg-138-f {
        font-size: 138px !important
    }

    .tx-lg-140 {
        font-size: 140px
    }

    .tx-lg-140-f {
        font-size: 140px !important
    }

    .tx-lg-base {
        font-size: .875rem
    }

    .tx-lg-bold {
        font-weight: 700
    }

    .tx-lg-semibold {
        font-weight: 600
    }

    .tx-lg-medium {
        font-weight: 500
    }

    .tx-lg-normal {
        font-weight: 400
    }

    .tx-lg-light {
        font-weight: 300
    }

    .tx-lg-thin {
        font-weight: 200
    }

    .tx-lg-xthin {
        font-weight: 100
    }

    .lh-lg-0 {
        line-height: 0
    }

    .lh-lg-1 {
        line-height: 1.1
    }

    .lh-lg-2 {
        line-height: 1.2
    }

    .lh-lg-3 {
        line-height: 1.3
    }

    .lh-lg-4 {
        line-height: 1.4
    }

    .lh-lg-5 {
        line-height: 1.5
    }

    .lh-lg-6 {
        line-height: 1.6
    }

    .lh-lg-7 {
        line-height: 1.7
    }

    .lh-lg-8 {
        line-height: 1.8
    }

    .lh-lg-9 {
        line-height: 1.9
    }

    .lh-lg-10 {
        line-height: 2.0
    }

    .lh-lg-11 {
        line-height: 2.1
    }

    .lh-lg-12 {
        line-height: 2.2
    }

    .lh-lg-13 {
        line-height: 2.3
    }

    .lh-lg-14 {
        line-height: 2.4
    }

    .lh-lg-15 {
        line-height: 2.5
    }

    .tx-lg-center {
        text-align: center
    }

    .tx-lg-right {
        text-align: right
    }

    .tx-lg-left {
        text-align: left
    }

    .tx-lg-center-f {
        text-align: center !important
    }

    .tx-lg-right-f {
        text-align: right !important
    }

    .tx-lg-left-f {
        text-align: left !important
    }
}

@media (min-width: 1200px) {
    .tx-xl-8 {
        font-size: 8px
    }

    .tx-xl-8-f {
        font-size: 8px !important
    }

    .tx-xl-9 {
        font-size: 9px
    }

    .tx-xl-9-f {
        font-size: 9px !important
    }

    .tx-xl-10 {
        font-size: 10px
    }

    .tx-xl-10-f {
        font-size: 10px !important
    }

    .tx-xl-11 {
        font-size: 11px
    }

    .tx-xl-11-f {
        font-size: 11px !important
    }

    .tx-xl-12 {
        font-size: 12px
    }

    .tx-xl-12-f {
        font-size: 12px !important
    }

    .tx-xl-13 {
        font-size: 13px
    }

    .tx-xl-13-f {
        font-size: 13px !important
    }

    .tx-xl-14 {
        font-size: 14px
    }

    .tx-xl-14-f {
        font-size: 14px !important
    }

    .tx-xl-15 {
        font-size: 15px
    }

    .tx-xl-15-f {
        font-size: 15px !important
    }

    .tx-xl-16 {
        font-size: 16px
    }

    .tx-xl-16-f {
        font-size: 16px !important
    }

    .tx-xl-18 {
        font-size: 18px
    }

    .tx-xl-18-f {
        font-size: 18px !important
    }

    .tx-xl-20 {
        font-size: 20px
    }

    .tx-xl-20-f {
        font-size: 20px !important
    }

    .tx-xl-22 {
        font-size: 22px
    }

    .tx-xl-22-f {
        font-size: 22px !important
    }

    .tx-xl-24 {
        font-size: 24px
    }

    .tx-xl-24-f {
        font-size: 24px !important
    }

    .tx-xl-26 {
        font-size: 26px
    }

    .tx-xl-26-f {
        font-size: 26px !important
    }

    .tx-xl-28 {
        font-size: 28px
    }

    .tx-xl-28-f {
        font-size: 28px !important
    }

    .tx-xl-30 {
        font-size: 30px
    }

    .tx-xl-30-f {
        font-size: 30px !important
    }

    .tx-xl-32 {
        font-size: 32px
    }

    .tx-xl-32-f {
        font-size: 32px !important
    }

    .tx-xl-34 {
        font-size: 34px
    }

    .tx-xl-34-f {
        font-size: 34px !important
    }

    .tx-xl-36 {
        font-size: 36px
    }

    .tx-xl-36-f {
        font-size: 36px !important
    }

    .tx-xl-38 {
        font-size: 38px
    }

    .tx-xl-38-f {
        font-size: 38px !important
    }

    .tx-xl-40 {
        font-size: 40px
    }

    .tx-xl-40-f {
        font-size: 40px !important
    }

    .tx-xl-42 {
        font-size: 42px
    }

    .tx-xl-42-f {
        font-size: 42px !important
    }

    .tx-xl-44 {
        font-size: 44px
    }

    .tx-xl-44-f {
        font-size: 44px !important
    }

    .tx-xl-46 {
        font-size: 46px
    }

    .tx-xl-46-f {
        font-size: 46px !important
    }

    .tx-xl-48 {
        font-size: 48px
    }

    .tx-xl-48-f {
        font-size: 48px !important
    }

    .tx-xl-50 {
        font-size: 50px
    }

    .tx-xl-50-f {
        font-size: 50px !important
    }

    .tx-xl-52 {
        font-size: 52px
    }

    .tx-xl-52-f {
        font-size: 52px !important
    }

    .tx-xl-54 {
        font-size: 54px
    }

    .tx-xl-54-f {
        font-size: 54px !important
    }

    .tx-xl-56 {
        font-size: 56px
    }

    .tx-xl-56-f {
        font-size: 56px !important
    }

    .tx-xl-58 {
        font-size: 58px
    }

    .tx-xl-58-f {
        font-size: 58px !important
    }

    .tx-xl-60 {
        font-size: 60px
    }

    .tx-xl-60-f {
        font-size: 60px !important
    }

    .tx-xl-62 {
        font-size: 62px
    }

    .tx-xl-62-f {
        font-size: 62px !important
    }

    .tx-xl-64 {
        font-size: 64px
    }

    .tx-xl-64-f {
        font-size: 64px !important
    }

    .tx-xl-66 {
        font-size: 66px
    }

    .tx-xl-66-f {
        font-size: 66px !important
    }

    .tx-xl-68 {
        font-size: 68px
    }

    .tx-xl-68-f {
        font-size: 68px !important
    }

    .tx-xl-70 {
        font-size: 70px
    }

    .tx-xl-70-f {
        font-size: 70px !important
    }

    .tx-xl-72 {
        font-size: 72px
    }

    .tx-xl-72-f {
        font-size: 72px !important
    }

    .tx-xl-74 {
        font-size: 74px
    }

    .tx-xl-74-f {
        font-size: 74px !important
    }

    .tx-xl-76 {
        font-size: 76px
    }

    .tx-xl-76-f {
        font-size: 76px !important
    }

    .tx-xl-78 {
        font-size: 78px
    }

    .tx-xl-78-f {
        font-size: 78px !important
    }

    .tx-xl-80 {
        font-size: 80px
    }

    .tx-xl-80-f {
        font-size: 80px !important
    }

    .tx-xl-82 {
        font-size: 82px
    }

    .tx-xl-82-f {
        font-size: 82px !important
    }

    .tx-xl-84 {
        font-size: 84px
    }

    .tx-xl-84-f {
        font-size: 84px !important
    }

    .tx-xl-86 {
        font-size: 86px
    }

    .tx-xl-86-f {
        font-size: 86px !important
    }

    .tx-xl-88 {
        font-size: 88px
    }

    .tx-xl-88-f {
        font-size: 88px !important
    }

    .tx-xl-90 {
        font-size: 90px
    }

    .tx-xl-90-f {
        font-size: 90px !important
    }

    .tx-xl-92 {
        font-size: 92px
    }

    .tx-xl-92-f {
        font-size: 92px !important
    }

    .tx-xl-94 {
        font-size: 94px
    }

    .tx-xl-94-f {
        font-size: 94px !important
    }

    .tx-xl-96 {
        font-size: 96px
    }

    .tx-xl-96-f {
        font-size: 96px !important
    }

    .tx-xl-98 {
        font-size: 98px
    }

    .tx-xl-98-f {
        font-size: 98px !important
    }

    .tx-xl-100 {
        font-size: 100px
    }

    .tx-xl-100-f {
        font-size: 100px !important
    }

    .tx-xl-102 {
        font-size: 102px
    }

    .tx-xl-102-f {
        font-size: 102px !important
    }

    .tx-xl-104 {
        font-size: 104px
    }

    .tx-xl-104-f {
        font-size: 104px !important
    }

    .tx-xl-106 {
        font-size: 106px
    }

    .tx-xl-106-f {
        font-size: 106px !important
    }

    .tx-xl-108 {
        font-size: 108px
    }

    .tx-xl-108-f {
        font-size: 108px !important
    }

    .tx-xl-110 {
        font-size: 110px
    }

    .tx-xl-110-f {
        font-size: 110px !important
    }

    .tx-xl-112 {
        font-size: 112px
    }

    .tx-xl-112-f {
        font-size: 112px !important
    }

    .tx-xl-114 {
        font-size: 114px
    }

    .tx-xl-114-f {
        font-size: 114px !important
    }

    .tx-xl-116 {
        font-size: 116px
    }

    .tx-xl-116-f {
        font-size: 116px !important
    }

    .tx-xl-118 {
        font-size: 118px
    }

    .tx-xl-118-f {
        font-size: 118px !important
    }

    .tx-xl-120 {
        font-size: 120px
    }

    .tx-xl-120-f {
        font-size: 120px !important
    }

    .tx-xl-122 {
        font-size: 122px
    }

    .tx-xl-122-f {
        font-size: 122px !important
    }

    .tx-xl-124 {
        font-size: 124px
    }

    .tx-xl-124-f {
        font-size: 124px !important
    }

    .tx-xl-126 {
        font-size: 126px
    }

    .tx-xl-126-f {
        font-size: 126px !important
    }

    .tx-xl-128 {
        font-size: 128px
    }

    .tx-xl-128-f {
        font-size: 128px !important
    }

    .tx-xl-130 {
        font-size: 130px
    }

    .tx-xl-130-f {
        font-size: 130px !important
    }

    .tx-xl-132 {
        font-size: 132px
    }

    .tx-xl-132-f {
        font-size: 132px !important
    }

    .tx-xl-134 {
        font-size: 134px
    }

    .tx-xl-134-f {
        font-size: 134px !important
    }

    .tx-xl-136 {
        font-size: 136px
    }

    .tx-xl-136-f {
        font-size: 136px !important
    }

    .tx-xl-138 {
        font-size: 138px
    }

    .tx-xl-138-f {
        font-size: 138px !important
    }

    .tx-xl-140 {
        font-size: 140px
    }

    .tx-xl-140-f {
        font-size: 140px !important
    }

    .tx-xl-base {
        font-size: .875rem
    }

    .tx-xl-bold {
        font-weight: 700
    }

    .tx-xl-semibold {
        font-weight: 600
    }

    .tx-xl-medium {
        font-weight: 500
    }

    .tx-xl-normal {
        font-weight: 400
    }

    .tx-xl-light {
        font-weight: 300
    }

    .tx-xl-thin {
        font-weight: 200
    }

    .tx-xl-xthin {
        font-weight: 100
    }

    .lh-xl-0 {
        line-height: 0
    }

    .lh-xl-1 {
        line-height: 1.1
    }

    .lh-xl-2 {
        line-height: 1.2
    }

    .lh-xl-3 {
        line-height: 1.3
    }

    .lh-xl-4 {
        line-height: 1.4
    }

    .lh-xl-5 {
        line-height: 1.5
    }

    .lh-xl-6 {
        line-height: 1.6
    }

    .lh-xl-7 {
        line-height: 1.7
    }

    .lh-xl-8 {
        line-height: 1.8
    }

    .lh-xl-9 {
        line-height: 1.9
    }

    .lh-xl-10 {
        line-height: 2.0
    }

    .lh-xl-11 {
        line-height: 2.1
    }

    .lh-xl-12 {
        line-height: 2.2
    }

    .lh-xl-13 {
        line-height: 2.3
    }

    .lh-xl-14 {
        line-height: 2.4
    }

    .lh-xl-15 {
        line-height: 2.5
    }

    .tx-xl-center {
        text-align: center
    }

    .tx-xl-right {
        text-align: right
    }

    .tx-xl-left {
        text-align: left
    }

    .tx-xl-center-f {
        text-align: center !important
    }

    .tx-xl-right-f {
        text-align: right !important
    }

    .tx-xl-left-f {
        text-align: left !important
    }
}

.wd-5 {
    width: 5px
}

.wd-5p {
    width: 5%
}

.mx-wd-5p {
    max-width: 5%
}

.mn-wd-5p {
    min-width: 5%
}

.wd-5-f {
    width: 5px !important
}

.wd-5p-f {
    width: 5% !important
}

.mx-wd-5p-f {
    max-width: 5% !important
}

.mn-wd-5p-f {
    min-width: 5% !important
}

.wd-10 {
    width: 10px
}

.wd-10p {
    width: 10%
}

.mx-wd-10p {
    max-width: 10%
}

.mn-wd-10p {
    min-width: 10%
}

.wd-10-f {
    width: 10px !important
}

.wd-10p-f {
    width: 10% !important
}

.mx-wd-10p-f {
    max-width: 10% !important
}

.mn-wd-10p-f {
    min-width: 10% !important
}

.wd-15 {
    width: 15px
}

.wd-15p {
    width: 15%
}

.mx-wd-15p {
    max-width: 15%
}

.mn-wd-15p {
    min-width: 15%
}

.wd-15-f {
    width: 15px !important
}

.wd-15p-f {
    width: 15% !important
}

.mx-wd-15p-f {
    max-width: 15% !important
}

.mn-wd-15p-f {
    min-width: 15% !important
}

.wd-20 {
    width: 20px
}

.wd-20p {
    width: 20%
}

.mx-wd-20p {
    max-width: 20%
}

.mn-wd-20p {
    min-width: 20%
}

.wd-20-f {
    width: 20px !important
}

.wd-20p-f {
    width: 20% !important
}

.mx-wd-20p-f {
    max-width: 20% !important
}

.mn-wd-20p-f {
    min-width: 20% !important
}

.wd-25 {
    width: 25px
}

.wd-25p {
    width: 25%
}

.mx-wd-25p {
    max-width: 25%
}

.mn-wd-25p {
    min-width: 25%
}

.wd-25-f {
    width: 25px !important
}

.wd-25p-f {
    width: 25% !important
}

.mx-wd-25p-f {
    max-width: 25% !important
}

.mn-wd-25p-f {
    min-width: 25% !important
}

.wd-30 {
    width: 30px
}

.wd-30p {
    width: 30%
}

.mx-wd-30p {
    max-width: 30%
}

.mn-wd-30p {
    min-width: 30%
}

.wd-30-f {
    width: 30px !important
}

.wd-30p-f {
    width: 30% !important
}

.mx-wd-30p-f {
    max-width: 30% !important
}

.mn-wd-30p-f {
    min-width: 30% !important
}

.wd-35 {
    width: 35px
}

.wd-35p {
    width: 35%
}

.mx-wd-35p {
    max-width: 35%
}

.mn-wd-35p {
    min-width: 35%
}

.wd-35-f {
    width: 35px !important
}

.wd-35p-f {
    width: 35% !important
}

.mx-wd-35p-f {
    max-width: 35% !important
}

.mn-wd-35p-f {
    min-width: 35% !important
}

.wd-40 {
    width: 40px
}

.wd-40p {
    width: 40%
}

.mx-wd-40p {
    max-width: 40%
}

.mn-wd-40p {
    min-width: 40%
}

.wd-40-f {
    width: 40px !important
}

.wd-40p-f {
    width: 40% !important
}

.mx-wd-40p-f {
    max-width: 40% !important
}

.mn-wd-40p-f {
    min-width: 40% !important
}

.wd-45 {
    width: 45px
}

.wd-45p {
    width: 45%
}

.mx-wd-45p {
    max-width: 45%
}

.mn-wd-45p {
    min-width: 45%
}

.wd-45-f {
    width: 45px !important
}

.wd-45p-f {
    width: 45% !important
}

.mx-wd-45p-f {
    max-width: 45% !important
}

.mn-wd-45p-f {
    min-width: 45% !important
}

.wd-50 {
    width: 50px
}

.wd-50p {
    width: 50%
}

.mx-wd-50p {
    max-width: 50%
}

.mn-wd-50p {
    min-width: 50%
}

.wd-50-f {
    width: 50px !important
}

.wd-50p-f {
    width: 50% !important
}

.mx-wd-50p-f {
    max-width: 50% !important
}

.mn-wd-50p-f {
    min-width: 50% !important
}

.wd-55 {
    width: 55px
}

.wd-55p {
    width: 55%
}

.mx-wd-55p {
    max-width: 55%
}

.mn-wd-55p {
    min-width: 55%
}

.wd-55-f {
    width: 55px !important
}

.wd-55p-f {
    width: 55% !important
}

.mx-wd-55p-f {
    max-width: 55% !important
}

.mn-wd-55p-f {
    min-width: 55% !important
}

.wd-60 {
    width: 60px
}

.wd-60p {
    width: 60%
}

.mx-wd-60p {
    max-width: 60%
}

.mn-wd-60p {
    min-width: 60%
}

.wd-60-f {
    width: 60px !important
}

.wd-60p-f {
    width: 60% !important
}

.mx-wd-60p-f {
    max-width: 60% !important
}

.mn-wd-60p-f {
    min-width: 60% !important
}

.wd-65 {
    width: 65px
}

.wd-65p {
    width: 65%
}

.mx-wd-65p {
    max-width: 65%
}

.mn-wd-65p {
    min-width: 65%
}

.wd-65-f {
    width: 65px !important
}

.wd-65p-f {
    width: 65% !important
}

.mx-wd-65p-f {
    max-width: 65% !important
}

.mn-wd-65p-f {
    min-width: 65% !important
}

.wd-70 {
    width: 70px
}

.wd-70p {
    width: 70%
}

.mx-wd-70p {
    max-width: 70%
}

.mn-wd-70p {
    min-width: 70%
}

.wd-70-f {
    width: 70px !important
}

.wd-70p-f {
    width: 70% !important
}

.mx-wd-70p-f {
    max-width: 70% !important
}

.mn-wd-70p-f {
    min-width: 70% !important
}

.wd-75 {
    width: 75px
}

.wd-75p {
    width: 75%
}

.mx-wd-75p {
    max-width: 75%
}

.mn-wd-75p {
    min-width: 75%
}

.wd-75-f {
    width: 75px !important
}

.wd-75p-f {
    width: 75% !important
}

.mx-wd-75p-f {
    max-width: 75% !important
}

.mn-wd-75p-f {
    min-width: 75% !important
}

.wd-80 {
    width: 80px
}

.wd-80p {
    width: 80%
}

.mx-wd-80p {
    max-width: 80%
}

.mn-wd-80p {
    min-width: 80%
}

.wd-80-f {
    width: 80px !important
}

.wd-80p-f {
    width: 80% !important
}

.mx-wd-80p-f {
    max-width: 80% !important
}

.mn-wd-80p-f {
    min-width: 80% !important
}

.wd-85 {
    width: 85px
}

.wd-85p {
    width: 85%
}

.mx-wd-85p {
    max-width: 85%
}

.mn-wd-85p {
    min-width: 85%
}

.wd-85-f {
    width: 85px !important
}

.wd-85p-f {
    width: 85% !important
}

.mx-wd-85p-f {
    max-width: 85% !important
}

.mn-wd-85p-f {
    min-width: 85% !important
}

.wd-90 {
    width: 90px
}

.wd-90p {
    width: 90%
}

.mx-wd-90p {
    max-width: 90%
}

.mn-wd-90p {
    min-width: 90%
}

.wd-90-f {
    width: 90px !important
}

.wd-90p-f {
    width: 90% !important
}

.mx-wd-90p-f {
    max-width: 90% !important
}

.mn-wd-90p-f {
    min-width: 90% !important
}

.wd-95 {
    width: 95px
}

.wd-95p {
    width: 95%
}

.mx-wd-95p {
    max-width: 95%
}

.mn-wd-95p {
    min-width: 95%
}

.wd-95-f {
    width: 95px !important
}

.wd-95p-f {
    width: 95% !important
}

.mx-wd-95p-f {
    max-width: 95% !important
}

.mn-wd-95p-f {
    min-width: 95% !important
}

.wd-100 {
    width: 100px
}

.wd-100p {
    width: 100%
}

.mx-wd-100p {
    max-width: 100%
}

.mn-wd-100p {
    min-width: 100%
}

.wd-100-f {
    width: 100px !important
}

.wd-100p-f {
    width: 100% !important
}

.mx-wd-100p-f {
    max-width: 100% !important
}

.mn-wd-100p-f {
    min-width: 100% !important
}

.wd-150 {
    width: 150px
}

.wd-150-f {
    width: 150px !important
}

.mn-wd-150 {
    width: 150px
}

.mx-wd-150 {
    width: 150px
}

.wd-200 {
    width: 200px
}

.wd-200-f {
    width: 200px !important
}

.mn-wd-200 {
    width: 200px
}

.mx-wd-200 {
    width: 200px
}

.wd-250 {
    width: 250px
}

.wd-250-f {
    width: 250px !important
}

.mn-wd-250 {
    width: 250px
}

.mx-wd-250 {
    width: 250px
}

.wd-300 {
    width: 300px
}

.wd-300-f {
    width: 300px !important
}

.mn-wd-300 {
    width: 300px
}

.mx-wd-300 {
    width: 300px
}

.wd-350 {
    width: 350px
}

.wd-350-f {
    width: 350px !important
}

.mn-wd-350 {
    width: 350px
}

.mx-wd-350 {
    width: 350px
}

.wd-400 {
    width: 400px
}

.wd-400-f {
    width: 400px !important
}

.mn-wd-400 {
    width: 400px
}

.mx-wd-400 {
    width: 400px
}

.wd-450 {
    width: 450px
}

.wd-450-f {
    width: 450px !important
}

.mn-wd-450 {
    width: 450px
}

.mx-wd-450 {
    width: 450px
}

.wd-500 {
    width: 500px
}

.wd-500-f {
    width: 500px !important
}

.mn-wd-500 {
    width: 500px
}

.mx-wd-500 {
    width: 500px
}

.wd-550 {
    width: 550px
}

.wd-550-f {
    width: 550px !important
}

.mn-wd-550 {
    width: 550px
}

.mx-wd-550 {
    width: 550px
}

.wd-600 {
    width: 600px
}

.wd-600-f {
    width: 600px !important
}

.mn-wd-600 {
    width: 600px
}

.mx-wd-600 {
    width: 600px
}

.wd-650 {
    width: 650px
}

.wd-650-f {
    width: 650px !important
}

.mn-wd-650 {
    width: 650px
}

.mx-wd-650 {
    width: 650px
}

.wd-700 {
    width: 700px
}

.wd-700-f {
    width: 700px !important
}

.mn-wd-700 {
    width: 700px
}

.mx-wd-700 {
    width: 700px
}

.wd-750 {
    width: 750px
}

.wd-750-f {
    width: 750px !important
}

.mn-wd-750 {
    width: 750px
}

.mx-wd-750 {
    width: 750px
}

.wd-800 {
    width: 800px
}

.wd-800-f {
    width: 800px !important
}

.mn-wd-800 {
    width: 800px
}

.mx-wd-800 {
    width: 800px
}

.wd-850 {
    width: 850px
}

.wd-850-f {
    width: 850px !important
}

.mn-wd-850 {
    width: 850px
}

.mx-wd-850 {
    width: 850px
}

.wd-900 {
    width: 900px
}

.wd-900-f {
    width: 900px !important
}

.mn-wd-900 {
    width: 900px
}

.mx-wd-900 {
    width: 900px
}

.wd-950 {
    width: 950px
}

.wd-950-f {
    width: 950px !important
}

.mn-wd-950 {
    width: 950px
}

.mx-wd-950 {
    width: 950px
}

.wd-1000 {
    width: 1000px
}

.wd-1000-f {
    width: 1000px !important
}

.mn-wd-1000 {
    width: 1000px
}

.mx-wd-1000 {
    width: 1000px
}

@media (min-width: 480px) {
    .wd-xs-5 {
        width: 5px
    }

    .wd-xs-5p {
        width: 5%
    }

    .mx-wd-xs-5p {
        max-width: 5%
    }

    .mn-wd-xs-5p {
        min-width: 5%
    }

    .wd-xs-5-f {
        width: 5px !important
    }

    .wd-xs-5p-f {
        width: 5% !important
    }

    .mx-wd-xs-5p-f {
        max-width: 5% !important
    }

    .mn-wd-xs-5p-f {
        min-width: 5% !important
    }

    .wd-xs-10 {
        width: 10px
    }

    .wd-xs-10p {
        width: 10%
    }

    .mx-wd-xs-10p {
        max-width: 10%
    }

    .mn-wd-xs-10p {
        min-width: 10%
    }

    .wd-xs-10-f {
        width: 10px !important
    }

    .wd-xs-10p-f {
        width: 10% !important
    }

    .mx-wd-xs-10p-f {
        max-width: 10% !important
    }

    .mn-wd-xs-10p-f {
        min-width: 10% !important
    }

    .wd-xs-15 {
        width: 15px
    }

    .wd-xs-15p {
        width: 15%
    }

    .mx-wd-xs-15p {
        max-width: 15%
    }

    .mn-wd-xs-15p {
        min-width: 15%
    }

    .wd-xs-15-f {
        width: 15px !important
    }

    .wd-xs-15p-f {
        width: 15% !important
    }

    .mx-wd-xs-15p-f {
        max-width: 15% !important
    }

    .mn-wd-xs-15p-f {
        min-width: 15% !important
    }

    .wd-xs-20 {
        width: 20px
    }

    .wd-xs-20p {
        width: 20%
    }

    .mx-wd-xs-20p {
        max-width: 20%
    }

    .mn-wd-xs-20p {
        min-width: 20%
    }

    .wd-xs-20-f {
        width: 20px !important
    }

    .wd-xs-20p-f {
        width: 20% !important
    }

    .mx-wd-xs-20p-f {
        max-width: 20% !important
    }

    .mn-wd-xs-20p-f {
        min-width: 20% !important
    }

    .wd-xs-25 {
        width: 25px
    }

    .wd-xs-25p {
        width: 25%
    }

    .mx-wd-xs-25p {
        max-width: 25%
    }

    .mn-wd-xs-25p {
        min-width: 25%
    }

    .wd-xs-25-f {
        width: 25px !important
    }

    .wd-xs-25p-f {
        width: 25% !important
    }

    .mx-wd-xs-25p-f {
        max-width: 25% !important
    }

    .mn-wd-xs-25p-f {
        min-width: 25% !important
    }

    .wd-xs-30 {
        width: 30px
    }

    .wd-xs-30p {
        width: 30%
    }

    .mx-wd-xs-30p {
        max-width: 30%
    }

    .mn-wd-xs-30p {
        min-width: 30%
    }

    .wd-xs-30-f {
        width: 30px !important
    }

    .wd-xs-30p-f {
        width: 30% !important
    }

    .mx-wd-xs-30p-f {
        max-width: 30% !important
    }

    .mn-wd-xs-30p-f {
        min-width: 30% !important
    }

    .wd-xs-35 {
        width: 35px
    }

    .wd-xs-35p {
        width: 35%
    }

    .mx-wd-xs-35p {
        max-width: 35%
    }

    .mn-wd-xs-35p {
        min-width: 35%
    }

    .wd-xs-35-f {
        width: 35px !important
    }

    .wd-xs-35p-f {
        width: 35% !important
    }

    .mx-wd-xs-35p-f {
        max-width: 35% !important
    }

    .mn-wd-xs-35p-f {
        min-width: 35% !important
    }

    .wd-xs-40 {
        width: 40px
    }

    .wd-xs-40p {
        width: 40%
    }

    .mx-wd-xs-40p {
        max-width: 40%
    }

    .mn-wd-xs-40p {
        min-width: 40%
    }

    .wd-xs-40-f {
        width: 40px !important
    }

    .wd-xs-40p-f {
        width: 40% !important
    }

    .mx-wd-xs-40p-f {
        max-width: 40% !important
    }

    .mn-wd-xs-40p-f {
        min-width: 40% !important
    }

    .wd-xs-45 {
        width: 45px
    }

    .wd-xs-45p {
        width: 45%
    }

    .mx-wd-xs-45p {
        max-width: 45%
    }

    .mn-wd-xs-45p {
        min-width: 45%
    }

    .wd-xs-45-f {
        width: 45px !important
    }

    .wd-xs-45p-f {
        width: 45% !important
    }

    .mx-wd-xs-45p-f {
        max-width: 45% !important
    }

    .mn-wd-xs-45p-f {
        min-width: 45% !important
    }

    .wd-xs-50 {
        width: 50px
    }

    .wd-xs-50p {
        width: 50%
    }

    .mx-wd-xs-50p {
        max-width: 50%
    }

    .mn-wd-xs-50p {
        min-width: 50%
    }

    .wd-xs-50-f {
        width: 50px !important
    }

    .wd-xs-50p-f {
        width: 50% !important
    }

    .mx-wd-xs-50p-f {
        max-width: 50% !important
    }

    .mn-wd-xs-50p-f {
        min-width: 50% !important
    }

    .wd-xs-55 {
        width: 55px
    }

    .wd-xs-55p {
        width: 55%
    }

    .mx-wd-xs-55p {
        max-width: 55%
    }

    .mn-wd-xs-55p {
        min-width: 55%
    }

    .wd-xs-55-f {
        width: 55px !important
    }

    .wd-xs-55p-f {
        width: 55% !important
    }

    .mx-wd-xs-55p-f {
        max-width: 55% !important
    }

    .mn-wd-xs-55p-f {
        min-width: 55% !important
    }

    .wd-xs-60 {
        width: 60px
    }

    .wd-xs-60p {
        width: 60%
    }

    .mx-wd-xs-60p {
        max-width: 60%
    }

    .mn-wd-xs-60p {
        min-width: 60%
    }

    .wd-xs-60-f {
        width: 60px !important
    }

    .wd-xs-60p-f {
        width: 60% !important
    }

    .mx-wd-xs-60p-f {
        max-width: 60% !important
    }

    .mn-wd-xs-60p-f {
        min-width: 60% !important
    }

    .wd-xs-65 {
        width: 65px
    }

    .wd-xs-65p {
        width: 65%
    }

    .mx-wd-xs-65p {
        max-width: 65%
    }

    .mn-wd-xs-65p {
        min-width: 65%
    }

    .wd-xs-65-f {
        width: 65px !important
    }

    .wd-xs-65p-f {
        width: 65% !important
    }

    .mx-wd-xs-65p-f {
        max-width: 65% !important
    }

    .mn-wd-xs-65p-f {
        min-width: 65% !important
    }

    .wd-xs-70 {
        width: 70px
    }

    .wd-xs-70p {
        width: 70%
    }

    .mx-wd-xs-70p {
        max-width: 70%
    }

    .mn-wd-xs-70p {
        min-width: 70%
    }

    .wd-xs-70-f {
        width: 70px !important
    }

    .wd-xs-70p-f {
        width: 70% !important
    }

    .mx-wd-xs-70p-f {
        max-width: 70% !important
    }

    .mn-wd-xs-70p-f {
        min-width: 70% !important
    }

    .wd-xs-75 {
        width: 75px
    }

    .wd-xs-75p {
        width: 75%
    }

    .mx-wd-xs-75p {
        max-width: 75%
    }

    .mn-wd-xs-75p {
        min-width: 75%
    }

    .wd-xs-75-f {
        width: 75px !important
    }

    .wd-xs-75p-f {
        width: 75% !important
    }

    .mx-wd-xs-75p-f {
        max-width: 75% !important
    }

    .mn-wd-xs-75p-f {
        min-width: 75% !important
    }

    .wd-xs-80 {
        width: 80px
    }

    .wd-xs-80p {
        width: 80%
    }

    .mx-wd-xs-80p {
        max-width: 80%
    }

    .mn-wd-xs-80p {
        min-width: 80%
    }

    .wd-xs-80-f {
        width: 80px !important
    }

    .wd-xs-80p-f {
        width: 80% !important
    }

    .mx-wd-xs-80p-f {
        max-width: 80% !important
    }

    .mn-wd-xs-80p-f {
        min-width: 80% !important
    }

    .wd-xs-85 {
        width: 85px
    }

    .wd-xs-85p {
        width: 85%
    }

    .mx-wd-xs-85p {
        max-width: 85%
    }

    .mn-wd-xs-85p {
        min-width: 85%
    }

    .wd-xs-85-f {
        width: 85px !important
    }

    .wd-xs-85p-f {
        width: 85% !important
    }

    .mx-wd-xs-85p-f {
        max-width: 85% !important
    }

    .mn-wd-xs-85p-f {
        min-width: 85% !important
    }

    .wd-xs-90 {
        width: 90px
    }

    .wd-xs-90p {
        width: 90%
    }

    .mx-wd-xs-90p {
        max-width: 90%
    }

    .mn-wd-xs-90p {
        min-width: 90%
    }

    .wd-xs-90-f {
        width: 90px !important
    }

    .wd-xs-90p-f {
        width: 90% !important
    }

    .mx-wd-xs-90p-f {
        max-width: 90% !important
    }

    .mn-wd-xs-90p-f {
        min-width: 90% !important
    }

    .wd-xs-95 {
        width: 95px
    }

    .wd-xs-95p {
        width: 95%
    }

    .mx-wd-xs-95p {
        max-width: 95%
    }

    .mn-wd-xs-95p {
        min-width: 95%
    }

    .wd-xs-95-f {
        width: 95px !important
    }

    .wd-xs-95p-f {
        width: 95% !important
    }

    .mx-wd-xs-95p-f {
        max-width: 95% !important
    }

    .mn-wd-xs-95p-f {
        min-width: 95% !important
    }

    .wd-xs-100 {
        width: 100px
    }

    .wd-xs-100p {
        width: 100%
    }

    .mx-wd-xs-100p {
        max-width: 100%
    }

    .mn-wd-xs-100p {
        min-width: 100%
    }

    .wd-xs-100-f {
        width: 100px !important
    }

    .wd-xs-100p-f {
        width: 100% !important
    }

    .mx-wd-xs-100p-f {
        max-width: 100% !important
    }

    .mn-wd-xs-100p-f {
        min-width: 100% !important
    }

    .wd-xs-150 {
        width: 150px
    }

    .wd-xs-150p {
        width: 150%
    }

    .mx-wd-xs-150p {
        max-width: 150%
    }

    .mn-wd-xs-150p {
        min-width: 150%
    }

    .wd-xs-150-f {
        width: 150px !important
    }

    .wd-xs-150p-f {
        width: 150% !important
    }

    .mx-wd-xs-150p-f {
        max-width: 150% !important
    }

    .mn-wd-xs-150p-f {
        min-width: 150% !important
    }

    .wd-xs-200 {
        width: 200px
    }

    .wd-xs-200p {
        width: 200%
    }

    .mx-wd-xs-200p {
        max-width: 200%
    }

    .mn-wd-xs-200p {
        min-width: 200%
    }

    .wd-xs-200-f {
        width: 200px !important
    }

    .wd-xs-200p-f {
        width: 200% !important
    }

    .mx-wd-xs-200p-f {
        max-width: 200% !important
    }

    .mn-wd-xs-200p-f {
        min-width: 200% !important
    }

    .wd-xs-250 {
        width: 250px
    }

    .wd-xs-250p {
        width: 250%
    }

    .mx-wd-xs-250p {
        max-width: 250%
    }

    .mn-wd-xs-250p {
        min-width: 250%
    }

    .wd-xs-250-f {
        width: 250px !important
    }

    .wd-xs-250p-f {
        width: 250% !important
    }

    .mx-wd-xs-250p-f {
        max-width: 250% !important
    }

    .mn-wd-xs-250p-f {
        min-width: 250% !important
    }

    .wd-xs-300 {
        width: 300px
    }

    .wd-xs-300p {
        width: 300%
    }

    .mx-wd-xs-300p {
        max-width: 300%
    }

    .mn-wd-xs-300p {
        min-width: 300%
    }

    .wd-xs-300-f {
        width: 300px !important
    }

    .wd-xs-300p-f {
        width: 300% !important
    }

    .mx-wd-xs-300p-f {
        max-width: 300% !important
    }

    .mn-wd-xs-300p-f {
        min-width: 300% !important
    }

    .wd-xs-350 {
        width: 350px
    }

    .wd-xs-350p {
        width: 350%
    }

    .mx-wd-xs-350p {
        max-width: 350%
    }

    .mn-wd-xs-350p {
        min-width: 350%
    }

    .wd-xs-350-f {
        width: 350px !important
    }

    .wd-xs-350p-f {
        width: 350% !important
    }

    .mx-wd-xs-350p-f {
        max-width: 350% !important
    }

    .mn-wd-xs-350p-f {
        min-width: 350% !important
    }

    .wd-xs-400 {
        width: 400px
    }

    .wd-xs-400p {
        width: 400%
    }

    .mx-wd-xs-400p {
        max-width: 400%
    }

    .mn-wd-xs-400p {
        min-width: 400%
    }

    .wd-xs-400-f {
        width: 400px !important
    }

    .wd-xs-400p-f {
        width: 400% !important
    }

    .mx-wd-xs-400p-f {
        max-width: 400% !important
    }

    .mn-wd-xs-400p-f {
        min-width: 400% !important
    }

    .wd-xs-450 {
        width: 450px
    }

    .wd-xs-450p {
        width: 450%
    }

    .mx-wd-xs-450p {
        max-width: 450%
    }

    .mn-wd-xs-450p {
        min-width: 450%
    }

    .wd-xs-450-f {
        width: 450px !important
    }

    .wd-xs-450p-f {
        width: 450% !important
    }

    .mx-wd-xs-450p-f {
        max-width: 450% !important
    }

    .mn-wd-xs-450p-f {
        min-width: 450% !important
    }

    .wd-xs-500 {
        width: 500px
    }

    .wd-xs-500p {
        width: 500%
    }

    .mx-wd-xs-500p {
        max-width: 500%
    }

    .mn-wd-xs-500p {
        min-width: 500%
    }

    .wd-xs-500-f {
        width: 500px !important
    }

    .wd-xs-500p-f {
        width: 500% !important
    }

    .mx-wd-xs-500p-f {
        max-width: 500% !important
    }

    .mn-wd-xs-500p-f {
        min-width: 500% !important
    }

    .wd-xs-550 {
        width: 550px
    }

    .wd-xs-550p {
        width: 550%
    }

    .mx-wd-xs-550p {
        max-width: 550%
    }

    .mn-wd-xs-550p {
        min-width: 550%
    }

    .wd-xs-550-f {
        width: 550px !important
    }

    .wd-xs-550p-f {
        width: 550% !important
    }

    .mx-wd-xs-550p-f {
        max-width: 550% !important
    }

    .mn-wd-xs-550p-f {
        min-width: 550% !important
    }

    .wd-xs-600 {
        width: 600px
    }

    .wd-xs-600p {
        width: 600%
    }

    .mx-wd-xs-600p {
        max-width: 600%
    }

    .mn-wd-xs-600p {
        min-width: 600%
    }

    .wd-xs-600-f {
        width: 600px !important
    }

    .wd-xs-600p-f {
        width: 600% !important
    }

    .mx-wd-xs-600p-f {
        max-width: 600% !important
    }

    .mn-wd-xs-600p-f {
        min-width: 600% !important
    }

    .wd-xs-650 {
        width: 650px
    }

    .wd-xs-650p {
        width: 650%
    }

    .mx-wd-xs-650p {
        max-width: 650%
    }

    .mn-wd-xs-650p {
        min-width: 650%
    }

    .wd-xs-650-f {
        width: 650px !important
    }

    .wd-xs-650p-f {
        width: 650% !important
    }

    .mx-wd-xs-650p-f {
        max-width: 650% !important
    }

    .mn-wd-xs-650p-f {
        min-width: 650% !important
    }

    .wd-xs-700 {
        width: 700px
    }

    .wd-xs-700p {
        width: 700%
    }

    .mx-wd-xs-700p {
        max-width: 700%
    }

    .mn-wd-xs-700p {
        min-width: 700%
    }

    .wd-xs-700-f {
        width: 700px !important
    }

    .wd-xs-700p-f {
        width: 700% !important
    }

    .mx-wd-xs-700p-f {
        max-width: 700% !important
    }

    .mn-wd-xs-700p-f {
        min-width: 700% !important
    }

    .wd-xs-750 {
        width: 750px
    }

    .wd-xs-750p {
        width: 750%
    }

    .mx-wd-xs-750p {
        max-width: 750%
    }

    .mn-wd-xs-750p {
        min-width: 750%
    }

    .wd-xs-750-f {
        width: 750px !important
    }

    .wd-xs-750p-f {
        width: 750% !important
    }

    .mx-wd-xs-750p-f {
        max-width: 750% !important
    }

    .mn-wd-xs-750p-f {
        min-width: 750% !important
    }

    .wd-xs-800 {
        width: 800px
    }

    .wd-xs-800p {
        width: 800%
    }

    .mx-wd-xs-800p {
        max-width: 800%
    }

    .mn-wd-xs-800p {
        min-width: 800%
    }

    .wd-xs-800-f {
        width: 800px !important
    }

    .wd-xs-800p-f {
        width: 800% !important
    }

    .mx-wd-xs-800p-f {
        max-width: 800% !important
    }

    .mn-wd-xs-800p-f {
        min-width: 800% !important
    }

    .wd-xs-850 {
        width: 850px
    }

    .wd-xs-850p {
        width: 850%
    }

    .mx-wd-xs-850p {
        max-width: 850%
    }

    .mn-wd-xs-850p {
        min-width: 850%
    }

    .wd-xs-850-f {
        width: 850px !important
    }

    .wd-xs-850p-f {
        width: 850% !important
    }

    .mx-wd-xs-850p-f {
        max-width: 850% !important
    }

    .mn-wd-xs-850p-f {
        min-width: 850% !important
    }

    .wd-xs-900 {
        width: 900px
    }

    .wd-xs-900p {
        width: 900%
    }

    .mx-wd-xs-900p {
        max-width: 900%
    }

    .mn-wd-xs-900p {
        min-width: 900%
    }

    .wd-xs-900-f {
        width: 900px !important
    }

    .wd-xs-900p-f {
        width: 900% !important
    }

    .mx-wd-xs-900p-f {
        max-width: 900% !important
    }

    .mn-wd-xs-900p-f {
        min-width: 900% !important
    }

    .wd-xs-950 {
        width: 950px
    }

    .wd-xs-950p {
        width: 950%
    }

    .mx-wd-xs-950p {
        max-width: 950%
    }

    .mn-wd-xs-950p {
        min-width: 950%
    }

    .wd-xs-950-f {
        width: 950px !important
    }

    .wd-xs-950p-f {
        width: 950% !important
    }

    .mx-wd-xs-950p-f {
        max-width: 950% !important
    }

    .mn-wd-xs-950p-f {
        min-width: 950% !important
    }

    .wd-xs-1000 {
        width: 1000px
    }

    .wd-xs-1000p {
        width: 1000%
    }

    .mx-wd-xs-1000p {
        max-width: 1000%
    }

    .mn-wd-xs-1000p {
        min-width: 1000%
    }

    .wd-xs-1000-f {
        width: 1000px !important
    }

    .wd-xs-1000p-f {
        width: 1000% !important
    }

    .mx-wd-xs-1000p-f {
        max-width: 1000% !important
    }

    .mn-wd-xs-1000p-f {
        min-width: 1000% !important
    }

    .wd-xs-auto {
        width: auto
    }

    .wd-xs-auto-f {
        width: auto !important
    }
}

@media (min-width: 576px) {
    .wd-sm-5 {
        width: 5px
    }

    .wd-sm-5p {
        width: 5%
    }

    .mx-wd-sm-5p {
        max-width: 5%
    }

    .mn-wd-sm-5p {
        min-width: 5%
    }

    .wd-sm-5-f {
        width: 5px !important
    }

    .wd-sm-5p-f {
        width: 5% !important
    }

    .mx-wd-sm-5p-f {
        max-width: 5% !important
    }

    .mn-wd-sm-5p-f {
        min-width: 5% !important
    }

    .wd-sm-10 {
        width: 10px
    }

    .wd-sm-10p {
        width: 10%
    }

    .mx-wd-sm-10p {
        max-width: 10%
    }

    .mn-wd-sm-10p {
        min-width: 10%
    }

    .wd-sm-10-f {
        width: 10px !important
    }

    .wd-sm-10p-f {
        width: 10% !important
    }

    .mx-wd-sm-10p-f {
        max-width: 10% !important
    }

    .mn-wd-sm-10p-f {
        min-width: 10% !important
    }

    .wd-sm-15 {
        width: 15px
    }

    .wd-sm-15p {
        width: 15%
    }

    .mx-wd-sm-15p {
        max-width: 15%
    }

    .mn-wd-sm-15p {
        min-width: 15%
    }

    .wd-sm-15-f {
        width: 15px !important
    }

    .wd-sm-15p-f {
        width: 15% !important
    }

    .mx-wd-sm-15p-f {
        max-width: 15% !important
    }

    .mn-wd-sm-15p-f {
        min-width: 15% !important
    }

    .wd-sm-20 {
        width: 20px
    }

    .wd-sm-20p {
        width: 20%
    }

    .mx-wd-sm-20p {
        max-width: 20%
    }

    .mn-wd-sm-20p {
        min-width: 20%
    }

    .wd-sm-20-f {
        width: 20px !important
    }

    .wd-sm-20p-f {
        width: 20% !important
    }

    .mx-wd-sm-20p-f {
        max-width: 20% !important
    }

    .mn-wd-sm-20p-f {
        min-width: 20% !important
    }

    .wd-sm-25 {
        width: 25px
    }

    .wd-sm-25p {
        width: 25%
    }

    .mx-wd-sm-25p {
        max-width: 25%
    }

    .mn-wd-sm-25p {
        min-width: 25%
    }

    .wd-sm-25-f {
        width: 25px !important
    }

    .wd-sm-25p-f {
        width: 25% !important
    }

    .mx-wd-sm-25p-f {
        max-width: 25% !important
    }

    .mn-wd-sm-25p-f {
        min-width: 25% !important
    }

    .wd-sm-30 {
        width: 30px
    }

    .wd-sm-30p {
        width: 30%
    }

    .mx-wd-sm-30p {
        max-width: 30%
    }

    .mn-wd-sm-30p {
        min-width: 30%
    }

    .wd-sm-30-f {
        width: 30px !important
    }

    .wd-sm-30p-f {
        width: 30% !important
    }

    .mx-wd-sm-30p-f {
        max-width: 30% !important
    }

    .mn-wd-sm-30p-f {
        min-width: 30% !important
    }

    .wd-sm-35 {
        width: 35px
    }

    .wd-sm-35p {
        width: 35%
    }

    .mx-wd-sm-35p {
        max-width: 35%
    }

    .mn-wd-sm-35p {
        min-width: 35%
    }

    .wd-sm-35-f {
        width: 35px !important
    }

    .wd-sm-35p-f {
        width: 35% !important
    }

    .mx-wd-sm-35p-f {
        max-width: 35% !important
    }

    .mn-wd-sm-35p-f {
        min-width: 35% !important
    }

    .wd-sm-40 {
        width: 40px
    }

    .wd-sm-40p {
        width: 40%
    }

    .mx-wd-sm-40p {
        max-width: 40%
    }

    .mn-wd-sm-40p {
        min-width: 40%
    }

    .wd-sm-40-f {
        width: 40px !important
    }

    .wd-sm-40p-f {
        width: 40% !important
    }

    .mx-wd-sm-40p-f {
        max-width: 40% !important
    }

    .mn-wd-sm-40p-f {
        min-width: 40% !important
    }

    .wd-sm-45 {
        width: 45px
    }

    .wd-sm-45p {
        width: 45%
    }

    .mx-wd-sm-45p {
        max-width: 45%
    }

    .mn-wd-sm-45p {
        min-width: 45%
    }

    .wd-sm-45-f {
        width: 45px !important
    }

    .wd-sm-45p-f {
        width: 45% !important
    }

    .mx-wd-sm-45p-f {
        max-width: 45% !important
    }

    .mn-wd-sm-45p-f {
        min-width: 45% !important
    }

    .wd-sm-50 {
        width: 50px
    }

    .wd-sm-50p {
        width: 50%
    }

    .mx-wd-sm-50p {
        max-width: 50%
    }

    .mn-wd-sm-50p {
        min-width: 50%
    }

    .wd-sm-50-f {
        width: 50px !important
    }

    .wd-sm-50p-f {
        width: 50% !important
    }

    .mx-wd-sm-50p-f {
        max-width: 50% !important
    }

    .mn-wd-sm-50p-f {
        min-width: 50% !important
    }

    .wd-sm-55 {
        width: 55px
    }

    .wd-sm-55p {
        width: 55%
    }

    .mx-wd-sm-55p {
        max-width: 55%
    }

    .mn-wd-sm-55p {
        min-width: 55%
    }

    .wd-sm-55-f {
        width: 55px !important
    }

    .wd-sm-55p-f {
        width: 55% !important
    }

    .mx-wd-sm-55p-f {
        max-width: 55% !important
    }

    .mn-wd-sm-55p-f {
        min-width: 55% !important
    }

    .wd-sm-60 {
        width: 60px
    }

    .wd-sm-60p {
        width: 60%
    }

    .mx-wd-sm-60p {
        max-width: 60%
    }

    .mn-wd-sm-60p {
        min-width: 60%
    }

    .wd-sm-60-f {
        width: 60px !important
    }

    .wd-sm-60p-f {
        width: 60% !important
    }

    .mx-wd-sm-60p-f {
        max-width: 60% !important
    }

    .mn-wd-sm-60p-f {
        min-width: 60% !important
    }

    .wd-sm-65 {
        width: 65px
    }

    .wd-sm-65p {
        width: 65%
    }

    .mx-wd-sm-65p {
        max-width: 65%
    }

    .mn-wd-sm-65p {
        min-width: 65%
    }

    .wd-sm-65-f {
        width: 65px !important
    }

    .wd-sm-65p-f {
        width: 65% !important
    }

    .mx-wd-sm-65p-f {
        max-width: 65% !important
    }

    .mn-wd-sm-65p-f {
        min-width: 65% !important
    }

    .wd-sm-70 {
        width: 70px
    }

    .wd-sm-70p {
        width: 70%
    }

    .mx-wd-sm-70p {
        max-width: 70%
    }

    .mn-wd-sm-70p {
        min-width: 70%
    }

    .wd-sm-70-f {
        width: 70px !important
    }

    .wd-sm-70p-f {
        width: 70% !important
    }

    .mx-wd-sm-70p-f {
        max-width: 70% !important
    }

    .mn-wd-sm-70p-f {
        min-width: 70% !important
    }

    .wd-sm-75 {
        width: 75px
    }

    .wd-sm-75p {
        width: 75%
    }

    .mx-wd-sm-75p {
        max-width: 75%
    }

    .mn-wd-sm-75p {
        min-width: 75%
    }

    .wd-sm-75-f {
        width: 75px !important
    }

    .wd-sm-75p-f {
        width: 75% !important
    }

    .mx-wd-sm-75p-f {
        max-width: 75% !important
    }

    .mn-wd-sm-75p-f {
        min-width: 75% !important
    }

    .wd-sm-80 {
        width: 80px
    }

    .wd-sm-80p {
        width: 80%
    }

    .mx-wd-sm-80p {
        max-width: 80%
    }

    .mn-wd-sm-80p {
        min-width: 80%
    }

    .wd-sm-80-f {
        width: 80px !important
    }

    .wd-sm-80p-f {
        width: 80% !important
    }

    .mx-wd-sm-80p-f {
        max-width: 80% !important
    }

    .mn-wd-sm-80p-f {
        min-width: 80% !important
    }

    .wd-sm-85 {
        width: 85px
    }

    .wd-sm-85p {
        width: 85%
    }

    .mx-wd-sm-85p {
        max-width: 85%
    }

    .mn-wd-sm-85p {
        min-width: 85%
    }

    .wd-sm-85-f {
        width: 85px !important
    }

    .wd-sm-85p-f {
        width: 85% !important
    }

    .mx-wd-sm-85p-f {
        max-width: 85% !important
    }

    .mn-wd-sm-85p-f {
        min-width: 85% !important
    }

    .wd-sm-90 {
        width: 90px
    }

    .wd-sm-90p {
        width: 90%
    }

    .mx-wd-sm-90p {
        max-width: 90%
    }

    .mn-wd-sm-90p {
        min-width: 90%
    }

    .wd-sm-90-f {
        width: 90px !important
    }

    .wd-sm-90p-f {
        width: 90% !important
    }

    .mx-wd-sm-90p-f {
        max-width: 90% !important
    }

    .mn-wd-sm-90p-f {
        min-width: 90% !important
    }

    .wd-sm-95 {
        width: 95px
    }

    .wd-sm-95p {
        width: 95%
    }

    .mx-wd-sm-95p {
        max-width: 95%
    }

    .mn-wd-sm-95p {
        min-width: 95%
    }

    .wd-sm-95-f {
        width: 95px !important
    }

    .wd-sm-95p-f {
        width: 95% !important
    }

    .mx-wd-sm-95p-f {
        max-width: 95% !important
    }

    .mn-wd-sm-95p-f {
        min-width: 95% !important
    }

    .wd-sm-100 {
        width: 100px
    }

    .wd-sm-100p {
        width: 100%
    }

    .mx-wd-sm-100p {
        max-width: 100%
    }

    .mn-wd-sm-100p {
        min-width: 100%
    }

    .wd-sm-100-f {
        width: 100px !important
    }

    .wd-sm-100p-f {
        width: 100% !important
    }

    .mx-wd-sm-100p-f {
        max-width: 100% !important
    }

    .mn-wd-sm-100p-f {
        min-width: 100% !important
    }

    .wd-sm-150 {
        width: 150px
    }

    .wd-sm-150p {
        width: 150%
    }

    .mx-wd-sm-150p {
        max-width: 150%
    }

    .mn-wd-sm-150p {
        min-width: 150%
    }

    .wd-sm-150-f {
        width: 150px !important
    }

    .wd-sm-150p-f {
        width: 150% !important
    }

    .mx-wd-sm-150p-f {
        max-width: 150% !important
    }

    .mn-wd-sm-150p-f {
        min-width: 150% !important
    }

    .wd-sm-200 {
        width: 200px
    }

    .wd-sm-200p {
        width: 200%
    }

    .mx-wd-sm-200p {
        max-width: 200%
    }

    .mn-wd-sm-200p {
        min-width: 200%
    }

    .wd-sm-200-f {
        width: 200px !important
    }

    .wd-sm-200p-f {
        width: 200% !important
    }

    .mx-wd-sm-200p-f {
        max-width: 200% !important
    }

    .mn-wd-sm-200p-f {
        min-width: 200% !important
    }

    .wd-sm-250 {
        width: 250px
    }

    .wd-sm-250p {
        width: 250%
    }

    .mx-wd-sm-250p {
        max-width: 250%
    }

    .mn-wd-sm-250p {
        min-width: 250%
    }

    .wd-sm-250-f {
        width: 250px !important
    }

    .wd-sm-250p-f {
        width: 250% !important
    }

    .mx-wd-sm-250p-f {
        max-width: 250% !important
    }

    .mn-wd-sm-250p-f {
        min-width: 250% !important
    }

    .wd-sm-300 {
        width: 300px
    }

    .wd-sm-300p {
        width: 300%
    }

    .mx-wd-sm-300p {
        max-width: 300%
    }

    .mn-wd-sm-300p {
        min-width: 300%
    }

    .wd-sm-300-f {
        width: 300px !important
    }

    .wd-sm-300p-f {
        width: 300% !important
    }

    .mx-wd-sm-300p-f {
        max-width: 300% !important
    }

    .mn-wd-sm-300p-f {
        min-width: 300% !important
    }

    .wd-sm-350 {
        width: 350px
    }

    .wd-sm-350p {
        width: 350%
    }

    .mx-wd-sm-350p {
        max-width: 350%
    }

    .mn-wd-sm-350p {
        min-width: 350%
    }

    .wd-sm-350-f {
        width: 350px !important
    }

    .wd-sm-350p-f {
        width: 350% !important
    }

    .mx-wd-sm-350p-f {
        max-width: 350% !important
    }

    .mn-wd-sm-350p-f {
        min-width: 350% !important
    }

    .wd-sm-400 {
        width: 400px
    }

    .wd-sm-400p {
        width: 400%
    }

    .mx-wd-sm-400p {
        max-width: 400%
    }

    .mn-wd-sm-400p {
        min-width: 400%
    }

    .wd-sm-400-f {
        width: 400px !important
    }

    .wd-sm-400p-f {
        width: 400% !important
    }

    .mx-wd-sm-400p-f {
        max-width: 400% !important
    }

    .mn-wd-sm-400p-f {
        min-width: 400% !important
    }

    .wd-sm-450 {
        width: 450px
    }

    .wd-sm-450p {
        width: 450%
    }

    .mx-wd-sm-450p {
        max-width: 450%
    }

    .mn-wd-sm-450p {
        min-width: 450%
    }

    .wd-sm-450-f {
        width: 450px !important
    }

    .wd-sm-450p-f {
        width: 450% !important
    }

    .mx-wd-sm-450p-f {
        max-width: 450% !important
    }

    .mn-wd-sm-450p-f {
        min-width: 450% !important
    }

    .wd-sm-500 {
        width: 500px
    }

    .wd-sm-500p {
        width: 500%
    }

    .mx-wd-sm-500p {
        max-width: 500%
    }

    .mn-wd-sm-500p {
        min-width: 500%
    }

    .wd-sm-500-f {
        width: 500px !important
    }

    .wd-sm-500p-f {
        width: 500% !important
    }

    .mx-wd-sm-500p-f {
        max-width: 500% !important
    }

    .mn-wd-sm-500p-f {
        min-width: 500% !important
    }

    .wd-sm-550 {
        width: 550px
    }

    .wd-sm-550p {
        width: 550%
    }

    .mx-wd-sm-550p {
        max-width: 550%
    }

    .mn-wd-sm-550p {
        min-width: 550%
    }

    .wd-sm-550-f {
        width: 550px !important
    }

    .wd-sm-550p-f {
        width: 550% !important
    }

    .mx-wd-sm-550p-f {
        max-width: 550% !important
    }

    .mn-wd-sm-550p-f {
        min-width: 550% !important
    }

    .wd-sm-600 {
        width: 600px
    }

    .wd-sm-600p {
        width: 600%
    }

    .mx-wd-sm-600p {
        max-width: 600%
    }

    .mn-wd-sm-600p {
        min-width: 600%
    }

    .wd-sm-600-f {
        width: 600px !important
    }

    .wd-sm-600p-f {
        width: 600% !important
    }

    .mx-wd-sm-600p-f {
        max-width: 600% !important
    }

    .mn-wd-sm-600p-f {
        min-width: 600% !important
    }

    .wd-sm-650 {
        width: 650px
    }

    .wd-sm-650p {
        width: 650%
    }

    .mx-wd-sm-650p {
        max-width: 650%
    }

    .mn-wd-sm-650p {
        min-width: 650%
    }

    .wd-sm-650-f {
        width: 650px !important
    }

    .wd-sm-650p-f {
        width: 650% !important
    }

    .mx-wd-sm-650p-f {
        max-width: 650% !important
    }

    .mn-wd-sm-650p-f {
        min-width: 650% !important
    }

    .wd-sm-700 {
        width: 700px
    }

    .wd-sm-700p {
        width: 700%
    }

    .mx-wd-sm-700p {
        max-width: 700%
    }

    .mn-wd-sm-700p {
        min-width: 700%
    }

    .wd-sm-700-f {
        width: 700px !important
    }

    .wd-sm-700p-f {
        width: 700% !important
    }

    .mx-wd-sm-700p-f {
        max-width: 700% !important
    }

    .mn-wd-sm-700p-f {
        min-width: 700% !important
    }

    .wd-sm-750 {
        width: 750px
    }

    .wd-sm-750p {
        width: 750%
    }

    .mx-wd-sm-750p {
        max-width: 750%
    }

    .mn-wd-sm-750p {
        min-width: 750%
    }

    .wd-sm-750-f {
        width: 750px !important
    }

    .wd-sm-750p-f {
        width: 750% !important
    }

    .mx-wd-sm-750p-f {
        max-width: 750% !important
    }

    .mn-wd-sm-750p-f {
        min-width: 750% !important
    }

    .wd-sm-800 {
        width: 800px
    }

    .wd-sm-800p {
        width: 800%
    }

    .mx-wd-sm-800p {
        max-width: 800%
    }

    .mn-wd-sm-800p {
        min-width: 800%
    }

    .wd-sm-800-f {
        width: 800px !important
    }

    .wd-sm-800p-f {
        width: 800% !important
    }

    .mx-wd-sm-800p-f {
        max-width: 800% !important
    }

    .mn-wd-sm-800p-f {
        min-width: 800% !important
    }

    .wd-sm-850 {
        width: 850px
    }

    .wd-sm-850p {
        width: 850%
    }

    .mx-wd-sm-850p {
        max-width: 850%
    }

    .mn-wd-sm-850p {
        min-width: 850%
    }

    .wd-sm-850-f {
        width: 850px !important
    }

    .wd-sm-850p-f {
        width: 850% !important
    }

    .mx-wd-sm-850p-f {
        max-width: 850% !important
    }

    .mn-wd-sm-850p-f {
        min-width: 850% !important
    }

    .wd-sm-900 {
        width: 900px
    }

    .wd-sm-900p {
        width: 900%
    }

    .mx-wd-sm-900p {
        max-width: 900%
    }

    .mn-wd-sm-900p {
        min-width: 900%
    }

    .wd-sm-900-f {
        width: 900px !important
    }

    .wd-sm-900p-f {
        width: 900% !important
    }

    .mx-wd-sm-900p-f {
        max-width: 900% !important
    }

    .mn-wd-sm-900p-f {
        min-width: 900% !important
    }

    .wd-sm-950 {
        width: 950px
    }

    .wd-sm-950p {
        width: 950%
    }

    .mx-wd-sm-950p {
        max-width: 950%
    }

    .mn-wd-sm-950p {
        min-width: 950%
    }

    .wd-sm-950-f {
        width: 950px !important
    }

    .wd-sm-950p-f {
        width: 950% !important
    }

    .mx-wd-sm-950p-f {
        max-width: 950% !important
    }

    .mn-wd-sm-950p-f {
        min-width: 950% !important
    }

    .wd-sm-1000 {
        width: 1000px
    }

    .wd-sm-1000p {
        width: 1000%
    }

    .mx-wd-sm-1000p {
        max-width: 1000%
    }

    .mn-wd-sm-1000p {
        min-width: 1000%
    }

    .wd-sm-1000-f {
        width: 1000px !important
    }

    .wd-sm-1000p-f {
        width: 1000% !important
    }

    .mx-wd-sm-1000p-f {
        max-width: 1000% !important
    }

    .mn-wd-sm-1000p-f {
        min-width: 1000% !important
    }

    .wd-sm-auto {
        width: auto
    }

    .wd-sm-auto-f {
        width: auto !important
    }

    .mx-wd-sm-650 {
        max-width: 650px
    }
}

@media (min-width: 768px) {
    .wd-md-5 {
        width: 5px
    }

    .wd-md-5p {
        width: 5%
    }

    .mx-wd-md-5p {
        max-width: 5%
    }

    .mn-wd-md-5p {
        min-width: 5%
    }

    .wd-md-5-f {
        width: 5px !important
    }

    .wd-md-5p-f {
        width: 5% !important
    }

    .mx-wd-md-5p-f {
        max-width: 5% !important
    }

    .mn-wd-md-5p-f {
        min-width: 5% !important
    }

    .wd-md-10 {
        width: 10px
    }

    .wd-md-10p {
        width: 10%
    }

    .mx-wd-md-10p {
        max-width: 10%
    }

    .mn-wd-md-10p {
        min-width: 10%
    }

    .wd-md-10-f {
        width: 10px !important
    }

    .wd-md-10p-f {
        width: 10% !important
    }

    .mx-wd-md-10p-f {
        max-width: 10% !important
    }

    .mn-wd-md-10p-f {
        min-width: 10% !important
    }

    .wd-md-15 {
        width: 15px
    }

    .wd-md-15p {
        width: 15%
    }

    .mx-wd-md-15p {
        max-width: 15%
    }

    .mn-wd-md-15p {
        min-width: 15%
    }

    .wd-md-15-f {
        width: 15px !important
    }

    .wd-md-15p-f {
        width: 15% !important
    }

    .mx-wd-md-15p-f {
        max-width: 15% !important
    }

    .mn-wd-md-15p-f {
        min-width: 15% !important
    }

    .wd-md-20 {
        width: 20px
    }

    .wd-md-20p {
        width: 20%
    }

    .mx-wd-md-20p {
        max-width: 20%
    }

    .mn-wd-md-20p {
        min-width: 20%
    }

    .wd-md-20-f {
        width: 20px !important
    }

    .wd-md-20p-f {
        width: 20% !important
    }

    .mx-wd-md-20p-f {
        max-width: 20% !important
    }

    .mn-wd-md-20p-f {
        min-width: 20% !important
    }

    .wd-md-25 {
        width: 25px
    }

    .wd-md-25p {
        width: 25%
    }

    .mx-wd-md-25p {
        max-width: 25%
    }

    .mn-wd-md-25p {
        min-width: 25%
    }

    .wd-md-25-f {
        width: 25px !important
    }

    .wd-md-25p-f {
        width: 25% !important
    }

    .mx-wd-md-25p-f {
        max-width: 25% !important
    }

    .mn-wd-md-25p-f {
        min-width: 25% !important
    }

    .wd-md-30 {
        width: 30px
    }

    .wd-md-30p {
        width: 30%
    }

    .mx-wd-md-30p {
        max-width: 30%
    }

    .mn-wd-md-30p {
        min-width: 30%
    }

    .wd-md-30-f {
        width: 30px !important
    }

    .wd-md-30p-f {
        width: 30% !important
    }

    .mx-wd-md-30p-f {
        max-width: 30% !important
    }

    .mn-wd-md-30p-f {
        min-width: 30% !important
    }

    .wd-md-35 {
        width: 35px
    }

    .wd-md-35p {
        width: 35%
    }

    .mx-wd-md-35p {
        max-width: 35%
    }

    .mn-wd-md-35p {
        min-width: 35%
    }

    .wd-md-35-f {
        width: 35px !important
    }

    .wd-md-35p-f {
        width: 35% !important
    }

    .mx-wd-md-35p-f {
        max-width: 35% !important
    }

    .mn-wd-md-35p-f {
        min-width: 35% !important
    }

    .wd-md-40 {
        width: 40px
    }

    .wd-md-40p {
        width: 40%
    }

    .mx-wd-md-40p {
        max-width: 40%
    }

    .mn-wd-md-40p {
        min-width: 40%
    }

    .wd-md-40-f {
        width: 40px !important
    }

    .wd-md-40p-f {
        width: 40% !important
    }

    .mx-wd-md-40p-f {
        max-width: 40% !important
    }

    .mn-wd-md-40p-f {
        min-width: 40% !important
    }

    .wd-md-45 {
        width: 45px
    }

    .wd-md-45p {
        width: 45%
    }

    .mx-wd-md-45p {
        max-width: 45%
    }

    .mn-wd-md-45p {
        min-width: 45%
    }

    .wd-md-45-f {
        width: 45px !important
    }

    .wd-md-45p-f {
        width: 45% !important
    }

    .mx-wd-md-45p-f {
        max-width: 45% !important
    }

    .mn-wd-md-45p-f {
        min-width: 45% !important
    }

    .wd-md-50 {
        width: 50px
    }

    .wd-md-50p {
        width: 50%
    }

    .mx-wd-md-50p {
        max-width: 50%
    }

    .mn-wd-md-50p {
        min-width: 50%
    }

    .wd-md-50-f {
        width: 50px !important
    }

    .wd-md-50p-f {
        width: 50% !important
    }

    .mx-wd-md-50p-f {
        max-width: 50% !important
    }

    .mn-wd-md-50p-f {
        min-width: 50% !important
    }

    .wd-md-55 {
        width: 55px
    }

    .wd-md-55p {
        width: 55%
    }

    .mx-wd-md-55p {
        max-width: 55%
    }

    .mn-wd-md-55p {
        min-width: 55%
    }

    .wd-md-55-f {
        width: 55px !important
    }

    .wd-md-55p-f {
        width: 55% !important
    }

    .mx-wd-md-55p-f {
        max-width: 55% !important
    }

    .mn-wd-md-55p-f {
        min-width: 55% !important
    }

    .wd-md-60 {
        width: 60px
    }

    .wd-md-60p {
        width: 60%
    }

    .mx-wd-md-60p {
        max-width: 60%
    }

    .mn-wd-md-60p {
        min-width: 60%
    }

    .wd-md-60-f {
        width: 60px !important
    }

    .wd-md-60p-f {
        width: 60% !important
    }

    .mx-wd-md-60p-f {
        max-width: 60% !important
    }

    .mn-wd-md-60p-f {
        min-width: 60% !important
    }

    .wd-md-65 {
        width: 65px
    }

    .wd-md-65p {
        width: 65%
    }

    .mx-wd-md-65p {
        max-width: 65%
    }

    .mn-wd-md-65p {
        min-width: 65%
    }

    .wd-md-65-f {
        width: 65px !important
    }

    .wd-md-65p-f {
        width: 65% !important
    }

    .mx-wd-md-65p-f {
        max-width: 65% !important
    }

    .mn-wd-md-65p-f {
        min-width: 65% !important
    }

    .wd-md-70 {
        width: 70px
    }

    .wd-md-70p {
        width: 70%
    }

    .mx-wd-md-70p {
        max-width: 70%
    }

    .mn-wd-md-70p {
        min-width: 70%
    }

    .wd-md-70-f {
        width: 70px !important
    }

    .wd-md-70p-f {
        width: 70% !important
    }

    .mx-wd-md-70p-f {
        max-width: 70% !important
    }

    .mn-wd-md-70p-f {
        min-width: 70% !important
    }

    .wd-md-75 {
        width: 75px
    }

    .wd-md-75p {
        width: 75%
    }

    .mx-wd-md-75p {
        max-width: 75%
    }

    .mn-wd-md-75p {
        min-width: 75%
    }

    .wd-md-75-f {
        width: 75px !important
    }

    .wd-md-75p-f {
        width: 75% !important
    }

    .mx-wd-md-75p-f {
        max-width: 75% !important
    }

    .mn-wd-md-75p-f {
        min-width: 75% !important
    }

    .wd-md-80 {
        width: 80px
    }

    .wd-md-80p {
        width: 80%
    }

    .mx-wd-md-80p {
        max-width: 80%
    }

    .mn-wd-md-80p {
        min-width: 80%
    }

    .wd-md-80-f {
        width: 80px !important
    }

    .wd-md-80p-f {
        width: 80% !important
    }

    .mx-wd-md-80p-f {
        max-width: 80% !important
    }

    .mn-wd-md-80p-f {
        min-width: 80% !important
    }

    .wd-md-85 {
        width: 85px
    }

    .wd-md-85p {
        width: 85%
    }

    .mx-wd-md-85p {
        max-width: 85%
    }

    .mn-wd-md-85p {
        min-width: 85%
    }

    .wd-md-85-f {
        width: 85px !important
    }

    .wd-md-85p-f {
        width: 85% !important
    }

    .mx-wd-md-85p-f {
        max-width: 85% !important
    }

    .mn-wd-md-85p-f {
        min-width: 85% !important
    }

    .wd-md-90 {
        width: 90px
    }

    .wd-md-90p {
        width: 90%
    }

    .mx-wd-md-90p {
        max-width: 90%
    }

    .mn-wd-md-90p {
        min-width: 90%
    }

    .wd-md-90-f {
        width: 90px !important
    }

    .wd-md-90p-f {
        width: 90% !important
    }

    .mx-wd-md-90p-f {
        max-width: 90% !important
    }

    .mn-wd-md-90p-f {
        min-width: 90% !important
    }

    .wd-md-95 {
        width: 95px
    }

    .wd-md-95p {
        width: 95%
    }

    .mx-wd-md-95p {
        max-width: 95%
    }

    .mn-wd-md-95p {
        min-width: 95%
    }

    .wd-md-95-f {
        width: 95px !important
    }

    .wd-md-95p-f {
        width: 95% !important
    }

    .mx-wd-md-95p-f {
        max-width: 95% !important
    }

    .mn-wd-md-95p-f {
        min-width: 95% !important
    }

    .wd-md-100 {
        width: 100px
    }

    .wd-md-100p {
        width: 100%
    }

    .mx-wd-md-100p {
        max-width: 100%
    }

    .mn-wd-md-100p {
        min-width: 100%
    }

    .wd-md-100-f {
        width: 100px !important
    }

    .wd-md-100p-f {
        width: 100% !important
    }

    .mx-wd-md-100p-f {
        max-width: 100% !important
    }

    .mn-wd-md-100p-f {
        min-width: 100% !important
    }

    .wd-md-150 {
        width: 150px
    }

    .wd-md-150p {
        width: 150%
    }

    .mx-wd-md-150p {
        max-width: 150%
    }

    .mn-wd-md-150p {
        min-width: 150%
    }

    .wd-md-150-f {
        width: 150px !important
    }

    .wd-md-150p-f {
        width: 150% !important
    }

    .mx-wd-md-150p-f {
        max-width: 150% !important
    }

    .mn-wd-md-150p-f {
        min-width: 150% !important
    }

    .wd-md-200 {
        width: 200px
    }

    .wd-md-200p {
        width: 200%
    }

    .mx-wd-md-200p {
        max-width: 200%
    }

    .mn-wd-md-200p {
        min-width: 200%
    }

    .wd-md-200-f {
        width: 200px !important
    }

    .wd-md-200p-f {
        width: 200% !important
    }

    .mx-wd-md-200p-f {
        max-width: 200% !important
    }

    .mn-wd-md-200p-f {
        min-width: 200% !important
    }

    .wd-md-250 {
        width: 250px
    }

    .wd-md-250p {
        width: 250%
    }

    .mx-wd-md-250p {
        max-width: 250%
    }

    .mn-wd-md-250p {
        min-width: 250%
    }

    .wd-md-250-f {
        width: 250px !important
    }

    .wd-md-250p-f {
        width: 250% !important
    }

    .mx-wd-md-250p-f {
        max-width: 250% !important
    }

    .mn-wd-md-250p-f {
        min-width: 250% !important
    }

    .wd-md-300 {
        width: 300px
    }

    .wd-md-300p {
        width: 300%
    }

    .mx-wd-md-300p {
        max-width: 300%
    }

    .mn-wd-md-300p {
        min-width: 300%
    }

    .wd-md-300-f {
        width: 300px !important
    }

    .wd-md-300p-f {
        width: 300% !important
    }

    .mx-wd-md-300p-f {
        max-width: 300% !important
    }

    .mn-wd-md-300p-f {
        min-width: 300% !important
    }

    .wd-md-350 {
        width: 350px
    }

    .wd-md-350p {
        width: 350%
    }

    .mx-wd-md-350p {
        max-width: 350%
    }

    .mn-wd-md-350p {
        min-width: 350%
    }

    .wd-md-350-f {
        width: 350px !important
    }

    .wd-md-350p-f {
        width: 350% !important
    }

    .mx-wd-md-350p-f {
        max-width: 350% !important
    }

    .mn-wd-md-350p-f {
        min-width: 350% !important
    }

    .wd-md-400 {
        width: 400px
    }

    .wd-md-400p {
        width: 400%
    }

    .mx-wd-md-400p {
        max-width: 400%
    }

    .mn-wd-md-400p {
        min-width: 400%
    }

    .wd-md-400-f {
        width: 400px !important
    }

    .wd-md-400p-f {
        width: 400% !important
    }

    .mx-wd-md-400p-f {
        max-width: 400% !important
    }

    .mn-wd-md-400p-f {
        min-width: 400% !important
    }

    .wd-md-450 {
        width: 450px
    }

    .wd-md-450p {
        width: 450%
    }

    .mx-wd-md-450p {
        max-width: 450%
    }

    .mn-wd-md-450p {
        min-width: 450%
    }

    .wd-md-450-f {
        width: 450px !important
    }

    .wd-md-450p-f {
        width: 450% !important
    }

    .mx-wd-md-450p-f {
        max-width: 450% !important
    }

    .mn-wd-md-450p-f {
        min-width: 450% !important
    }

    .wd-md-500 {
        width: 500px
    }

    .wd-md-500p {
        width: 500%
    }

    .mx-wd-md-500p {
        max-width: 500%
    }

    .mn-wd-md-500p {
        min-width: 500%
    }

    .wd-md-500-f {
        width: 500px !important
    }

    .wd-md-500p-f {
        width: 500% !important
    }

    .mx-wd-md-500p-f {
        max-width: 500% !important
    }

    .mn-wd-md-500p-f {
        min-width: 500% !important
    }

    .wd-md-550 {
        width: 550px
    }

    .wd-md-550p {
        width: 550%
    }

    .mx-wd-md-550p {
        max-width: 550%
    }

    .mn-wd-md-550p {
        min-width: 550%
    }

    .wd-md-550-f {
        width: 550px !important
    }

    .wd-md-550p-f {
        width: 550% !important
    }

    .mx-wd-md-550p-f {
        max-width: 550% !important
    }

    .mn-wd-md-550p-f {
        min-width: 550% !important
    }

    .wd-md-600 {
        width: 600px
    }

    .wd-md-600p {
        width: 600%
    }

    .mx-wd-md-600p {
        max-width: 600%
    }

    .mn-wd-md-600p {
        min-width: 600%
    }

    .wd-md-600-f {
        width: 600px !important
    }

    .wd-md-600p-f {
        width: 600% !important
    }

    .mx-wd-md-600p-f {
        max-width: 600% !important
    }

    .mn-wd-md-600p-f {
        min-width: 600% !important
    }

    .wd-md-650 {
        width: 650px
    }

    .wd-md-650p {
        width: 650%
    }

    .mx-wd-md-650p {
        max-width: 650%
    }

    .mn-wd-md-650p {
        min-width: 650%
    }

    .wd-md-650-f {
        width: 650px !important
    }

    .wd-md-650p-f {
        width: 650% !important
    }

    .mx-wd-md-650p-f {
        max-width: 650% !important
    }

    .mn-wd-md-650p-f {
        min-width: 650% !important
    }

    .wd-md-700 {
        width: 700px
    }

    .wd-md-700p {
        width: 700%
    }

    .mx-wd-md-700p {
        max-width: 700%
    }

    .mn-wd-md-700p {
        min-width: 700%
    }

    .wd-md-700-f {
        width: 700px !important
    }

    .wd-md-700p-f {
        width: 700% !important
    }

    .mx-wd-md-700p-f {
        max-width: 700% !important
    }

    .mn-wd-md-700p-f {
        min-width: 700% !important
    }

    .wd-md-750 {
        width: 750px
    }

    .wd-md-750p {
        width: 750%
    }

    .mx-wd-md-750p {
        max-width: 750%
    }

    .mn-wd-md-750p {
        min-width: 750%
    }

    .wd-md-750-f {
        width: 750px !important
    }

    .wd-md-750p-f {
        width: 750% !important
    }

    .mx-wd-md-750p-f {
        max-width: 750% !important
    }

    .mn-wd-md-750p-f {
        min-width: 750% !important
    }

    .wd-md-800 {
        width: 800px
    }

    .wd-md-800p {
        width: 800%
    }

    .mx-wd-md-800p {
        max-width: 800%
    }

    .mn-wd-md-800p {
        min-width: 800%
    }

    .wd-md-800-f {
        width: 800px !important
    }

    .wd-md-800p-f {
        width: 800% !important
    }

    .mx-wd-md-800p-f {
        max-width: 800% !important
    }

    .mn-wd-md-800p-f {
        min-width: 800% !important
    }

    .wd-md-850 {
        width: 850px
    }

    .wd-md-850p {
        width: 850%
    }

    .mx-wd-md-850p {
        max-width: 850%
    }

    .mn-wd-md-850p {
        min-width: 850%
    }

    .wd-md-850-f {
        width: 850px !important
    }

    .wd-md-850p-f {
        width: 850% !important
    }

    .mx-wd-md-850p-f {
        max-width: 850% !important
    }

    .mn-wd-md-850p-f {
        min-width: 850% !important
    }

    .wd-md-900 {
        width: 900px
    }

    .wd-md-900p {
        width: 900%
    }

    .mx-wd-md-900p {
        max-width: 900%
    }

    .mn-wd-md-900p {
        min-width: 900%
    }

    .wd-md-900-f {
        width: 900px !important
    }

    .wd-md-900p-f {
        width: 900% !important
    }

    .mx-wd-md-900p-f {
        max-width: 900% !important
    }

    .mn-wd-md-900p-f {
        min-width: 900% !important
    }

    .wd-md-950 {
        width: 950px
    }

    .wd-md-950p {
        width: 950%
    }

    .mx-wd-md-950p {
        max-width: 950%
    }

    .mn-wd-md-950p {
        min-width: 950%
    }

    .wd-md-950-f {
        width: 950px !important
    }

    .wd-md-950p-f {
        width: 950% !important
    }

    .mx-wd-md-950p-f {
        max-width: 950% !important
    }

    .mn-wd-md-950p-f {
        min-width: 950% !important
    }

    .wd-md-1000 {
        width: 1000px
    }

    .wd-md-1000p {
        width: 1000%
    }

    .mx-wd-md-1000p {
        max-width: 1000%
    }

    .mn-wd-md-1000p {
        min-width: 1000%
    }

    .wd-md-1000-f {
        width: 1000px !important
    }

    .wd-md-1000p-f {
        width: 1000% !important
    }

    .mx-wd-md-1000p-f {
        max-width: 1000% !important
    }

    .mn-wd-md-1000p-f {
        min-width: 1000% !important
    }

    .wd-md-auto {
        width: auto
    }

    .wd-md-auto-f {
        width: auto !important
    }

    .wd-md-120 {
        width: 120px
    }
}

@media (min-width: 992px) {
    .wd-lg-5 {
        width: 5px
    }

    .wd-lg-5p {
        width: 5%
    }

    .mx-wd-lg-5p {
        max-width: 5%
    }

    .mn-wd-lg-5p {
        min-width: 5%
    }

    .wd-lg-5-f {
        width: 5px !important
    }

    .wd-lg-5p-f {
        width: 5% !important
    }

    .mx-wd-lg-5p-f {
        max-width: 5% !important
    }

    .mn-wd-lg-5p-f {
        min-width: 5% !important
    }

    .wd-lg-10 {
        width: 10px
    }

    .wd-lg-10p {
        width: 10%
    }

    .mx-wd-lg-10p {
        max-width: 10%
    }

    .mn-wd-lg-10p {
        min-width: 10%
    }

    .wd-lg-10-f {
        width: 10px !important
    }

    .wd-lg-10p-f {
        width: 10% !important
    }

    .mx-wd-lg-10p-f {
        max-width: 10% !important
    }

    .mn-wd-lg-10p-f {
        min-width: 10% !important
    }

    .wd-lg-15 {
        width: 15px
    }

    .wd-lg-15p {
        width: 15%
    }

    .mx-wd-lg-15p {
        max-width: 15%
    }

    .mn-wd-lg-15p {
        min-width: 15%
    }

    .wd-lg-15-f {
        width: 15px !important
    }

    .wd-lg-15p-f {
        width: 15% !important
    }

    .mx-wd-lg-15p-f {
        max-width: 15% !important
    }

    .mn-wd-lg-15p-f {
        min-width: 15% !important
    }

    .wd-lg-20 {
        width: 20px
    }

    .wd-lg-20p {
        width: 20%
    }

    .mx-wd-lg-20p {
        max-width: 20%
    }

    .mn-wd-lg-20p {
        min-width: 20%
    }

    .wd-lg-20-f {
        width: 20px !important
    }

    .wd-lg-20p-f {
        width: 20% !important
    }

    .mx-wd-lg-20p-f {
        max-width: 20% !important
    }

    .mn-wd-lg-20p-f {
        min-width: 20% !important
    }

    .wd-lg-25 {
        width: 25px
    }

    .wd-lg-25p {
        width: 25%
    }

    .mx-wd-lg-25p {
        max-width: 25%
    }

    .mn-wd-lg-25p {
        min-width: 25%
    }

    .wd-lg-25-f {
        width: 25px !important
    }

    .wd-lg-25p-f {
        width: 25% !important
    }

    .mx-wd-lg-25p-f {
        max-width: 25% !important
    }

    .mn-wd-lg-25p-f {
        min-width: 25% !important
    }

    .wd-lg-30 {
        width: 30px
    }

    .wd-lg-30p {
        width: 30%
    }

    .mx-wd-lg-30p {
        max-width: 30%
    }

    .mn-wd-lg-30p {
        min-width: 30%
    }

    .wd-lg-30-f {
        width: 30px !important
    }

    .wd-lg-30p-f {
        width: 30% !important
    }

    .mx-wd-lg-30p-f {
        max-width: 30% !important
    }

    .mn-wd-lg-30p-f {
        min-width: 30% !important
    }

    .wd-lg-35 {
        width: 35px
    }

    .wd-lg-35p {
        width: 35%
    }

    .mx-wd-lg-35p {
        max-width: 35%
    }

    .mn-wd-lg-35p {
        min-width: 35%
    }

    .wd-lg-35-f {
        width: 35px !important
    }

    .wd-lg-35p-f {
        width: 35% !important
    }

    .mx-wd-lg-35p-f {
        max-width: 35% !important
    }

    .mn-wd-lg-35p-f {
        min-width: 35% !important
    }

    .wd-lg-40 {
        width: 40px
    }

    .wd-lg-40p {
        width: 40%
    }

    .mx-wd-lg-40p {
        max-width: 40%
    }

    .mn-wd-lg-40p {
        min-width: 40%
    }

    .wd-lg-40-f {
        width: 40px !important
    }

    .wd-lg-40p-f {
        width: 40% !important
    }

    .mx-wd-lg-40p-f {
        max-width: 40% !important
    }

    .mn-wd-lg-40p-f {
        min-width: 40% !important
    }

    .wd-lg-45 {
        width: 45px
    }

    .wd-lg-45p {
        width: 45%
    }

    .mx-wd-lg-45p {
        max-width: 45%
    }

    .mn-wd-lg-45p {
        min-width: 45%
    }

    .wd-lg-45-f {
        width: 45px !important
    }

    .wd-lg-45p-f {
        width: 45% !important
    }

    .mx-wd-lg-45p-f {
        max-width: 45% !important
    }

    .mn-wd-lg-45p-f {
        min-width: 45% !important
    }

    .wd-lg-50 {
        width: 50px
    }

    .wd-lg-50p {
        width: 50%
    }

    .mx-wd-lg-50p {
        max-width: 50%
    }

    .mn-wd-lg-50p {
        min-width: 50%
    }

    .wd-lg-50-f {
        width: 50px !important
    }

    .wd-lg-50p-f {
        width: 50% !important
    }

    .mx-wd-lg-50p-f {
        max-width: 50% !important
    }

    .mn-wd-lg-50p-f {
        min-width: 50% !important
    }

    .wd-lg-55 {
        width: 55px
    }

    .wd-lg-55p {
        width: 55%
    }

    .mx-wd-lg-55p {
        max-width: 55%
    }

    .mn-wd-lg-55p {
        min-width: 55%
    }

    .wd-lg-55-f {
        width: 55px !important
    }

    .wd-lg-55p-f {
        width: 55% !important
    }

    .mx-wd-lg-55p-f {
        max-width: 55% !important
    }

    .mn-wd-lg-55p-f {
        min-width: 55% !important
    }

    .wd-lg-60 {
        width: 60px
    }

    .wd-lg-60p {
        width: 60%
    }

    .mx-wd-lg-60p {
        max-width: 60%
    }

    .mn-wd-lg-60p {
        min-width: 60%
    }

    .wd-lg-60-f {
        width: 60px !important
    }

    .wd-lg-60p-f {
        width: 60% !important
    }

    .mx-wd-lg-60p-f {
        max-width: 60% !important
    }

    .mn-wd-lg-60p-f {
        min-width: 60% !important
    }

    .wd-lg-65 {
        width: 65px
    }

    .wd-lg-65p {
        width: 65%
    }

    .mx-wd-lg-65p {
        max-width: 65%
    }

    .mn-wd-lg-65p {
        min-width: 65%
    }

    .wd-lg-65-f {
        width: 65px !important
    }

    .wd-lg-65p-f {
        width: 65% !important
    }

    .mx-wd-lg-65p-f {
        max-width: 65% !important
    }

    .mn-wd-lg-65p-f {
        min-width: 65% !important
    }

    .wd-lg-70 {
        width: 70px
    }

    .wd-lg-70p {
        width: 70%
    }

    .mx-wd-lg-70p {
        max-width: 70%
    }

    .mn-wd-lg-70p {
        min-width: 70%
    }

    .wd-lg-70-f {
        width: 70px !important
    }

    .wd-lg-70p-f {
        width: 70% !important
    }

    .mx-wd-lg-70p-f {
        max-width: 70% !important
    }

    .mn-wd-lg-70p-f {
        min-width: 70% !important
    }

    .wd-lg-75 {
        width: 75px
    }

    .wd-lg-75p {
        width: 75%
    }

    .mx-wd-lg-75p {
        max-width: 75%
    }

    .mn-wd-lg-75p {
        min-width: 75%
    }

    .wd-lg-75-f {
        width: 75px !important
    }

    .wd-lg-75p-f {
        width: 75% !important
    }

    .mx-wd-lg-75p-f {
        max-width: 75% !important
    }

    .mn-wd-lg-75p-f {
        min-width: 75% !important
    }

    .wd-lg-80 {
        width: 80px
    }

    .wd-lg-80p {
        width: 80%
    }

    .mx-wd-lg-80p {
        max-width: 80%
    }

    .mn-wd-lg-80p {
        min-width: 80%
    }

    .wd-lg-80-f {
        width: 80px !important
    }

    .wd-lg-80p-f {
        width: 80% !important
    }

    .mx-wd-lg-80p-f {
        max-width: 80% !important
    }

    .mn-wd-lg-80p-f {
        min-width: 80% !important
    }

    .wd-lg-85 {
        width: 85px
    }

    .wd-lg-85p {
        width: 85%
    }

    .mx-wd-lg-85p {
        max-width: 85%
    }

    .mn-wd-lg-85p {
        min-width: 85%
    }

    .wd-lg-85-f {
        width: 85px !important
    }

    .wd-lg-85p-f {
        width: 85% !important
    }

    .mx-wd-lg-85p-f {
        max-width: 85% !important
    }

    .mn-wd-lg-85p-f {
        min-width: 85% !important
    }

    .wd-lg-90 {
        width: 90px
    }

    .wd-lg-90p {
        width: 90%
    }

    .mx-wd-lg-90p {
        max-width: 90%
    }

    .mn-wd-lg-90p {
        min-width: 90%
    }

    .wd-lg-90-f {
        width: 90px !important
    }

    .wd-lg-90p-f {
        width: 90% !important
    }

    .mx-wd-lg-90p-f {
        max-width: 90% !important
    }

    .mn-wd-lg-90p-f {
        min-width: 90% !important
    }

    .wd-lg-95 {
        width: 95px
    }

    .wd-lg-95p {
        width: 95%
    }

    .mx-wd-lg-95p {
        max-width: 95%
    }

    .mn-wd-lg-95p {
        min-width: 95%
    }

    .wd-lg-95-f {
        width: 95px !important
    }

    .wd-lg-95p-f {
        width: 95% !important
    }

    .mx-wd-lg-95p-f {
        max-width: 95% !important
    }

    .mn-wd-lg-95p-f {
        min-width: 95% !important
    }

    .wd-lg-100 {
        width: 100px
    }

    .wd-lg-100p {
        width: 100%
    }

    .mx-wd-lg-100p {
        max-width: 100%
    }

    .mn-wd-lg-100p {
        min-width: 100%
    }

    .wd-lg-100-f {
        width: 100px !important
    }

    .wd-lg-100p-f {
        width: 100% !important
    }

    .mx-wd-lg-100p-f {
        max-width: 100% !important
    }

    .mn-wd-lg-100p-f {
        min-width: 100% !important
    }

    .wd-lg-150 {
        width: 150px
    }

    .wd-lg-150p {
        width: 150%
    }

    .mx-wd-lg-150p {
        max-width: 150%
    }

    .mn-wd-lg-150p {
        min-width: 150%
    }

    .wd-lg-150-f {
        width: 150px !important
    }

    .wd-lg-150p-f {
        width: 150% !important
    }

    .mx-wd-lg-150p-f {
        max-width: 150% !important
    }

    .mn-wd-lg-150p-f {
        min-width: 150% !important
    }

    .wd-lg-200 {
        width: 200px
    }

    .wd-lg-200p {
        width: 200%
    }

    .mx-wd-lg-200p {
        max-width: 200%
    }

    .mn-wd-lg-200p {
        min-width: 200%
    }

    .wd-lg-200-f {
        width: 200px !important
    }

    .wd-lg-200p-f {
        width: 200% !important
    }

    .mx-wd-lg-200p-f {
        max-width: 200% !important
    }

    .mn-wd-lg-200p-f {
        min-width: 200% !important
    }

    .wd-lg-250 {
        width: 250px
    }

    .wd-lg-250p {
        width: 250%
    }

    .mx-wd-lg-250p {
        max-width: 250%
    }

    .mn-wd-lg-250p {
        min-width: 250%
    }

    .wd-lg-250-f {
        width: 250px !important
    }

    .wd-lg-250p-f {
        width: 250% !important
    }

    .mx-wd-lg-250p-f {
        max-width: 250% !important
    }

    .mn-wd-lg-250p-f {
        min-width: 250% !important
    }

    .wd-lg-300 {
        width: 300px
    }

    .wd-lg-300p {
        width: 300%
    }

    .mx-wd-lg-300p {
        max-width: 300%
    }

    .mn-wd-lg-300p {
        min-width: 300%
    }

    .wd-lg-300-f {
        width: 300px !important
    }

    .wd-lg-300p-f {
        width: 300% !important
    }

    .mx-wd-lg-300p-f {
        max-width: 300% !important
    }

    .mn-wd-lg-300p-f {
        min-width: 300% !important
    }

    .wd-lg-350 {
        width: 350px
    }

    .wd-lg-350p {
        width: 350%
    }

    .mx-wd-lg-350p {
        max-width: 350%
    }

    .mn-wd-lg-350p {
        min-width: 350%
    }

    .wd-lg-350-f {
        width: 350px !important
    }

    .wd-lg-350p-f {
        width: 350% !important
    }

    .mx-wd-lg-350p-f {
        max-width: 350% !important
    }

    .mn-wd-lg-350p-f {
        min-width: 350% !important
    }

    .wd-lg-400 {
        width: 400px
    }

    .wd-lg-400p {
        width: 400%
    }

    .mx-wd-lg-400p {
        max-width: 400%
    }

    .mn-wd-lg-400p {
        min-width: 400%
    }

    .wd-lg-400-f {
        width: 400px !important
    }

    .wd-lg-400p-f {
        width: 400% !important
    }

    .mx-wd-lg-400p-f {
        max-width: 400% !important
    }

    .mn-wd-lg-400p-f {
        min-width: 400% !important
    }

    .wd-lg-450 {
        width: 450px
    }

    .wd-lg-450p {
        width: 450%
    }

    .mx-wd-lg-450p {
        max-width: 450%
    }

    .mn-wd-lg-450p {
        min-width: 450%
    }

    .wd-lg-450-f {
        width: 450px !important
    }

    .wd-lg-450p-f {
        width: 450% !important
    }

    .mx-wd-lg-450p-f {
        max-width: 450% !important
    }

    .mn-wd-lg-450p-f {
        min-width: 450% !important
    }

    .wd-lg-500 {
        width: 500px
    }

    .wd-lg-500p {
        width: 500%
    }

    .mx-wd-lg-500p {
        max-width: 500%
    }

    .mn-wd-lg-500p {
        min-width: 500%
    }

    .wd-lg-500-f {
        width: 500px !important
    }

    .wd-lg-500p-f {
        width: 500% !important
    }

    .mx-wd-lg-500p-f {
        max-width: 500% !important
    }

    .mn-wd-lg-500p-f {
        min-width: 500% !important
    }

    .wd-lg-550 {
        width: 550px
    }

    .wd-lg-550p {
        width: 550%
    }

    .mx-wd-lg-550p {
        max-width: 550%
    }

    .mn-wd-lg-550p {
        min-width: 550%
    }

    .wd-lg-550-f {
        width: 550px !important
    }

    .wd-lg-550p-f {
        width: 550% !important
    }

    .mx-wd-lg-550p-f {
        max-width: 550% !important
    }

    .mn-wd-lg-550p-f {
        min-width: 550% !important
    }

    .wd-lg-600 {
        width: 600px
    }

    .wd-lg-600p {
        width: 600%
    }

    .mx-wd-lg-600p {
        max-width: 600%
    }

    .mn-wd-lg-600p {
        min-width: 600%
    }

    .wd-lg-600-f {
        width: 600px !important
    }

    .wd-lg-600p-f {
        width: 600% !important
    }

    .mx-wd-lg-600p-f {
        max-width: 600% !important
    }

    .mn-wd-lg-600p-f {
        min-width: 600% !important
    }

    .wd-lg-650 {
        width: 650px
    }

    .wd-lg-650p {
        width: 650%
    }

    .mx-wd-lg-650p {
        max-width: 650%
    }

    .mn-wd-lg-650p {
        min-width: 650%
    }

    .wd-lg-650-f {
        width: 650px !important
    }

    .wd-lg-650p-f {
        width: 650% !important
    }

    .mx-wd-lg-650p-f {
        max-width: 650% !important
    }

    .mn-wd-lg-650p-f {
        min-width: 650% !important
    }

    .wd-lg-700 {
        width: 700px
    }

    .wd-lg-700p {
        width: 700%
    }

    .mx-wd-lg-700p {
        max-width: 700%
    }

    .mn-wd-lg-700p {
        min-width: 700%
    }

    .wd-lg-700-f {
        width: 700px !important
    }

    .wd-lg-700p-f {
        width: 700% !important
    }

    .mx-wd-lg-700p-f {
        max-width: 700% !important
    }

    .mn-wd-lg-700p-f {
        min-width: 700% !important
    }

    .wd-lg-750 {
        width: 750px
    }

    .wd-lg-750p {
        width: 750%
    }

    .mx-wd-lg-750p {
        max-width: 750%
    }

    .mn-wd-lg-750p {
        min-width: 750%
    }

    .wd-lg-750-f {
        width: 750px !important
    }

    .wd-lg-750p-f {
        width: 750% !important
    }

    .mx-wd-lg-750p-f {
        max-width: 750% !important
    }

    .mn-wd-lg-750p-f {
        min-width: 750% !important
    }

    .wd-lg-800 {
        width: 800px
    }

    .wd-lg-800p {
        width: 800%
    }

    .mx-wd-lg-800p {
        max-width: 800%
    }

    .mn-wd-lg-800p {
        min-width: 800%
    }

    .wd-lg-800-f {
        width: 800px !important
    }

    .wd-lg-800p-f {
        width: 800% !important
    }

    .mx-wd-lg-800p-f {
        max-width: 800% !important
    }

    .mn-wd-lg-800p-f {
        min-width: 800% !important
    }

    .wd-lg-850 {
        width: 850px
    }

    .wd-lg-850p {
        width: 850%
    }

    .mx-wd-lg-850p {
        max-width: 850%
    }

    .mn-wd-lg-850p {
        min-width: 850%
    }

    .wd-lg-850-f {
        width: 850px !important
    }

    .wd-lg-850p-f {
        width: 850% !important
    }

    .mx-wd-lg-850p-f {
        max-width: 850% !important
    }

    .mn-wd-lg-850p-f {
        min-width: 850% !important
    }

    .wd-lg-900 {
        width: 900px
    }

    .wd-lg-900p {
        width: 900%
    }

    .mx-wd-lg-900p {
        max-width: 900%
    }

    .mn-wd-lg-900p {
        min-width: 900%
    }

    .wd-lg-900-f {
        width: 900px !important
    }

    .wd-lg-900p-f {
        width: 900% !important
    }

    .mx-wd-lg-900p-f {
        max-width: 900% !important
    }

    .mn-wd-lg-900p-f {
        min-width: 900% !important
    }

    .wd-lg-950 {
        width: 950px
    }

    .wd-lg-950p {
        width: 950%
    }

    .mx-wd-lg-950p {
        max-width: 950%
    }

    .mn-wd-lg-950p {
        min-width: 950%
    }

    .wd-lg-950-f {
        width: 950px !important
    }

    .wd-lg-950p-f {
        width: 950% !important
    }

    .mx-wd-lg-950p-f {
        max-width: 950% !important
    }

    .mn-wd-lg-950p-f {
        min-width: 950% !important
    }

    .wd-lg-1000 {
        width: 1000px
    }

    .wd-lg-1000p {
        width: 1000%
    }

    .mx-wd-lg-1000p {
        max-width: 1000%
    }

    .mn-wd-lg-1000p {
        min-width: 1000%
    }

    .wd-lg-1000-f {
        width: 1000px !important
    }

    .wd-lg-1000p-f {
        width: 1000% !important
    }

    .mx-wd-lg-1000p-f {
        max-width: 1000% !important
    }

    .mn-wd-lg-1000p-f {
        min-width: 1000% !important
    }

    .wd-lg-auto {
        width: auto
    }

    .wd-lg-auto-f {
        width: auto !important
    }
}

@media (min-width: 1200px) {
    .wd-xl-5 {
        width: 5px
    }

    .wd-xl-5p {
        width: 5%
    }

    .mx-wd-xl-5p {
        max-width: 5%
    }

    .mn-wd-xl-5p {
        min-width: 5%
    }

    .wd-xl-5-f {
        width: 5px !important
    }

    .wd-xl-5p-f {
        width: 5% !important
    }

    .mx-wd-xl-5p-f {
        max-width: 5% !important
    }

    .mn-wd-xl-5p-f {
        min-width: 5% !important
    }

    .wd-xl-10 {
        width: 10px
    }

    .wd-xl-10p {
        width: 10%
    }

    .mx-wd-xl-10p {
        max-width: 10%
    }

    .mn-wd-xl-10p {
        min-width: 10%
    }

    .wd-xl-10-f {
        width: 10px !important
    }

    .wd-xl-10p-f {
        width: 10% !important
    }

    .mx-wd-xl-10p-f {
        max-width: 10% !important
    }

    .mn-wd-xl-10p-f {
        min-width: 10% !important
    }

    .wd-xl-15 {
        width: 15px
    }

    .wd-xl-15p {
        width: 15%
    }

    .mx-wd-xl-15p {
        max-width: 15%
    }

    .mn-wd-xl-15p {
        min-width: 15%
    }

    .wd-xl-15-f {
        width: 15px !important
    }

    .wd-xl-15p-f {
        width: 15% !important
    }

    .mx-wd-xl-15p-f {
        max-width: 15% !important
    }

    .mn-wd-xl-15p-f {
        min-width: 15% !important
    }

    .wd-xl-20 {
        width: 20px
    }

    .wd-xl-20p {
        width: 20%
    }

    .mx-wd-xl-20p {
        max-width: 20%
    }

    .mn-wd-xl-20p {
        min-width: 20%
    }

    .wd-xl-20-f {
        width: 20px !important
    }

    .wd-xl-20p-f {
        width: 20% !important
    }

    .mx-wd-xl-20p-f {
        max-width: 20% !important
    }

    .mn-wd-xl-20p-f {
        min-width: 20% !important
    }

    .wd-xl-25 {
        width: 25px
    }

    .wd-xl-25p {
        width: 25%
    }

    .mx-wd-xl-25p {
        max-width: 25%
    }

    .mn-wd-xl-25p {
        min-width: 25%
    }

    .wd-xl-25-f {
        width: 25px !important
    }

    .wd-xl-25p-f {
        width: 25% !important
    }

    .mx-wd-xl-25p-f {
        max-width: 25% !important
    }

    .mn-wd-xl-25p-f {
        min-width: 25% !important
    }

    .wd-xl-30 {
        width: 30px
    }

    .wd-xl-30p {
        width: 30%
    }

    .mx-wd-xl-30p {
        max-width: 30%
    }

    .mn-wd-xl-30p {
        min-width: 30%
    }

    .wd-xl-30-f {
        width: 30px !important
    }

    .wd-xl-30p-f {
        width: 30% !important
    }

    .mx-wd-xl-30p-f {
        max-width: 30% !important
    }

    .mn-wd-xl-30p-f {
        min-width: 30% !important
    }

    .wd-xl-35 {
        width: 35px
    }

    .wd-xl-35p {
        width: 35%
    }

    .mx-wd-xl-35p {
        max-width: 35%
    }

    .mn-wd-xl-35p {
        min-width: 35%
    }

    .wd-xl-35-f {
        width: 35px !important
    }

    .wd-xl-35p-f {
        width: 35% !important
    }

    .mx-wd-xl-35p-f {
        max-width: 35% !important
    }

    .mn-wd-xl-35p-f {
        min-width: 35% !important
    }

    .wd-xl-40 {
        width: 40px
    }

    .wd-xl-40p {
        width: 40%
    }

    .mx-wd-xl-40p {
        max-width: 40%
    }

    .mn-wd-xl-40p {
        min-width: 40%
    }

    .wd-xl-40-f {
        width: 40px !important
    }

    .wd-xl-40p-f {
        width: 40% !important
    }

    .mx-wd-xl-40p-f {
        max-width: 40% !important
    }

    .mn-wd-xl-40p-f {
        min-width: 40% !important
    }

    .wd-xl-45 {
        width: 45px
    }

    .wd-xl-45p {
        width: 45%
    }

    .mx-wd-xl-45p {
        max-width: 45%
    }

    .mn-wd-xl-45p {
        min-width: 45%
    }

    .wd-xl-45-f {
        width: 45px !important
    }

    .wd-xl-45p-f {
        width: 45% !important
    }

    .mx-wd-xl-45p-f {
        max-width: 45% !important
    }

    .mn-wd-xl-45p-f {
        min-width: 45% !important
    }

    .wd-xl-50 {
        width: 50px
    }

    .wd-xl-50p {
        width: 50%
    }

    .mx-wd-xl-50p {
        max-width: 50%
    }

    .mn-wd-xl-50p {
        min-width: 50%
    }

    .wd-xl-50-f {
        width: 50px !important
    }

    .wd-xl-50p-f {
        width: 50% !important
    }

    .mx-wd-xl-50p-f {
        max-width: 50% !important
    }

    .mn-wd-xl-50p-f {
        min-width: 50% !important
    }

    .wd-xl-55 {
        width: 55px
    }

    .wd-xl-55p {
        width: 55%
    }

    .mx-wd-xl-55p {
        max-width: 55%
    }

    .mn-wd-xl-55p {
        min-width: 55%
    }

    .wd-xl-55-f {
        width: 55px !important
    }

    .wd-xl-55p-f {
        width: 55% !important
    }

    .mx-wd-xl-55p-f {
        max-width: 55% !important
    }

    .mn-wd-xl-55p-f {
        min-width: 55% !important
    }

    .wd-xl-60 {
        width: 60px
    }

    .wd-xl-60p {
        width: 60%
    }

    .mx-wd-xl-60p {
        max-width: 60%
    }

    .mn-wd-xl-60p {
        min-width: 60%
    }

    .wd-xl-60-f {
        width: 60px !important
    }

    .wd-xl-60p-f {
        width: 60% !important
    }

    .mx-wd-xl-60p-f {
        max-width: 60% !important
    }

    .mn-wd-xl-60p-f {
        min-width: 60% !important
    }

    .wd-xl-65 {
        width: 65px
    }

    .wd-xl-65p {
        width: 65%
    }

    .mx-wd-xl-65p {
        max-width: 65%
    }

    .mn-wd-xl-65p {
        min-width: 65%
    }

    .wd-xl-65-f {
        width: 65px !important
    }

    .wd-xl-65p-f {
        width: 65% !important
    }

    .mx-wd-xl-65p-f {
        max-width: 65% !important
    }

    .mn-wd-xl-65p-f {
        min-width: 65% !important
    }

    .wd-xl-70 {
        width: 70px
    }

    .wd-xl-70p {
        width: 70%
    }

    .mx-wd-xl-70p {
        max-width: 70%
    }

    .mn-wd-xl-70p {
        min-width: 70%
    }

    .wd-xl-70-f {
        width: 70px !important
    }

    .wd-xl-70p-f {
        width: 70% !important
    }

    .mx-wd-xl-70p-f {
        max-width: 70% !important
    }

    .mn-wd-xl-70p-f {
        min-width: 70% !important
    }

    .wd-xl-75 {
        width: 75px
    }

    .wd-xl-75p {
        width: 75%
    }

    .mx-wd-xl-75p {
        max-width: 75%
    }

    .mn-wd-xl-75p {
        min-width: 75%
    }

    .wd-xl-75-f {
        width: 75px !important
    }

    .wd-xl-75p-f {
        width: 75% !important
    }

    .mx-wd-xl-75p-f {
        max-width: 75% !important
    }

    .mn-wd-xl-75p-f {
        min-width: 75% !important
    }

    .wd-xl-80 {
        width: 80px
    }

    .wd-xl-80p {
        width: 80%
    }

    .mx-wd-xl-80p {
        max-width: 80%
    }

    .mn-wd-xl-80p {
        min-width: 80%
    }

    .wd-xl-80-f {
        width: 80px !important
    }

    .wd-xl-80p-f {
        width: 80% !important
    }

    .mx-wd-xl-80p-f {
        max-width: 80% !important
    }

    .mn-wd-xl-80p-f {
        min-width: 80% !important
    }

    .wd-xl-85 {
        width: 85px
    }

    .wd-xl-85p {
        width: 85%
    }

    .mx-wd-xl-85p {
        max-width: 85%
    }

    .mn-wd-xl-85p {
        min-width: 85%
    }

    .wd-xl-85-f {
        width: 85px !important
    }

    .wd-xl-85p-f {
        width: 85% !important
    }

    .mx-wd-xl-85p-f {
        max-width: 85% !important
    }

    .mn-wd-xl-85p-f {
        min-width: 85% !important
    }

    .wd-xl-90 {
        width: 90px
    }

    .wd-xl-90p {
        width: 90%
    }

    .mx-wd-xl-90p {
        max-width: 90%
    }

    .mn-wd-xl-90p {
        min-width: 90%
    }

    .wd-xl-90-f {
        width: 90px !important
    }

    .wd-xl-90p-f {
        width: 90% !important
    }

    .mx-wd-xl-90p-f {
        max-width: 90% !important
    }

    .mn-wd-xl-90p-f {
        min-width: 90% !important
    }

    .wd-xl-95 {
        width: 95px
    }

    .wd-xl-95p {
        width: 95%
    }

    .mx-wd-xl-95p {
        max-width: 95%
    }

    .mn-wd-xl-95p {
        min-width: 95%
    }

    .wd-xl-95-f {
        width: 95px !important
    }

    .wd-xl-95p-f {
        width: 95% !important
    }

    .mx-wd-xl-95p-f {
        max-width: 95% !important
    }

    .mn-wd-xl-95p-f {
        min-width: 95% !important
    }

    .wd-xl-100 {
        width: 100px
    }

    .wd-xl-100p {
        width: 100%
    }

    .mx-wd-xl-100p {
        max-width: 100%
    }

    .mn-wd-xl-100p {
        min-width: 100%
    }

    .wd-xl-100-f {
        width: 100px !important
    }

    .wd-xl-100p-f {
        width: 100% !important
    }

    .mx-wd-xl-100p-f {
        max-width: 100% !important
    }

    .mn-wd-xl-100p-f {
        min-width: 100% !important
    }

    .wd-xl-150 {
        width: 150px
    }

    .wd-xl-150p {
        width: 150%
    }

    .mx-wd-xl-150p {
        max-width: 150%
    }

    .mn-wd-xl-150p {
        min-width: 150%
    }

    .wd-xl-150-f {
        width: 150px !important
    }

    .wd-xl-150p-f {
        width: 150% !important
    }

    .mx-wd-xl-150p-f {
        max-width: 150% !important
    }

    .mn-wd-xl-150p-f {
        min-width: 150% !important
    }

    .wd-xl-200 {
        width: 200px
    }

    .wd-xl-200p {
        width: 200%
    }

    .mx-wd-xl-200p {
        max-width: 200%
    }

    .mn-wd-xl-200p {
        min-width: 200%
    }

    .wd-xl-200-f {
        width: 200px !important
    }

    .wd-xl-200p-f {
        width: 200% !important
    }

    .mx-wd-xl-200p-f {
        max-width: 200% !important
    }

    .mn-wd-xl-200p-f {
        min-width: 200% !important
    }

    .wd-xl-250 {
        width: 250px
    }

    .wd-xl-250p {
        width: 250%
    }

    .mx-wd-xl-250p {
        max-width: 250%
    }

    .mn-wd-xl-250p {
        min-width: 250%
    }

    .wd-xl-250-f {
        width: 250px !important
    }

    .wd-xl-250p-f {
        width: 250% !important
    }

    .mx-wd-xl-250p-f {
        max-width: 250% !important
    }

    .mn-wd-xl-250p-f {
        min-width: 250% !important
    }

    .wd-xl-300 {
        width: 300px
    }

    .wd-xl-300p {
        width: 300%
    }

    .mx-wd-xl-300p {
        max-width: 300%
    }

    .mn-wd-xl-300p {
        min-width: 300%
    }

    .wd-xl-300-f {
        width: 300px !important
    }

    .wd-xl-300p-f {
        width: 300% !important
    }

    .mx-wd-xl-300p-f {
        max-width: 300% !important
    }

    .mn-wd-xl-300p-f {
        min-width: 300% !important
    }

    .wd-xl-350 {
        width: 350px
    }

    .wd-xl-350p {
        width: 350%
    }

    .mx-wd-xl-350p {
        max-width: 350%
    }

    .mn-wd-xl-350p {
        min-width: 350%
    }

    .wd-xl-350-f {
        width: 350px !important
    }

    .wd-xl-350p-f {
        width: 350% !important
    }

    .mx-wd-xl-350p-f {
        max-width: 350% !important
    }

    .mn-wd-xl-350p-f {
        min-width: 350% !important
    }

    .wd-xl-400 {
        width: 400px
    }

    .wd-xl-400p {
        width: 400%
    }

    .mx-wd-xl-400p {
        max-width: 400%
    }

    .mn-wd-xl-400p {
        min-width: 400%
    }

    .wd-xl-400-f {
        width: 400px !important
    }

    .wd-xl-400p-f {
        width: 400% !important
    }

    .mx-wd-xl-400p-f {
        max-width: 400% !important
    }

    .mn-wd-xl-400p-f {
        min-width: 400% !important
    }

    .wd-xl-450 {
        width: 450px
    }

    .wd-xl-450p {
        width: 450%
    }

    .mx-wd-xl-450p {
        max-width: 450%
    }

    .mn-wd-xl-450p {
        min-width: 450%
    }

    .wd-xl-450-f {
        width: 450px !important
    }

    .wd-xl-450p-f {
        width: 450% !important
    }

    .mx-wd-xl-450p-f {
        max-width: 450% !important
    }

    .mn-wd-xl-450p-f {
        min-width: 450% !important
    }

    .wd-xl-500 {
        width: 500px
    }

    .wd-xl-500p {
        width: 500%
    }

    .mx-wd-xl-500p {
        max-width: 500%
    }

    .mn-wd-xl-500p {
        min-width: 500%
    }

    .wd-xl-500-f {
        width: 500px !important
    }

    .wd-xl-500p-f {
        width: 500% !important
    }

    .mx-wd-xl-500p-f {
        max-width: 500% !important
    }

    .mn-wd-xl-500p-f {
        min-width: 500% !important
    }

    .wd-xl-550 {
        width: 550px
    }

    .wd-xl-550p {
        width: 550%
    }

    .mx-wd-xl-550p {
        max-width: 550%
    }

    .mn-wd-xl-550p {
        min-width: 550%
    }

    .wd-xl-550-f {
        width: 550px !important
    }

    .wd-xl-550p-f {
        width: 550% !important
    }

    .mx-wd-xl-550p-f {
        max-width: 550% !important
    }

    .mn-wd-xl-550p-f {
        min-width: 550% !important
    }

    .wd-xl-600 {
        width: 600px
    }

    .wd-xl-600p {
        width: 600%
    }

    .mx-wd-xl-600p {
        max-width: 600%
    }

    .mn-wd-xl-600p {
        min-width: 600%
    }

    .wd-xl-600-f {
        width: 600px !important
    }

    .wd-xl-600p-f {
        width: 600% !important
    }

    .mx-wd-xl-600p-f {
        max-width: 600% !important
    }

    .mn-wd-xl-600p-f {
        min-width: 600% !important
    }

    .wd-xl-650 {
        width: 650px
    }

    .wd-xl-650p {
        width: 650%
    }

    .mx-wd-xl-650p {
        max-width: 650%
    }

    .mn-wd-xl-650p {
        min-width: 650%
    }

    .wd-xl-650-f {
        width: 650px !important
    }

    .wd-xl-650p-f {
        width: 650% !important
    }

    .mx-wd-xl-650p-f {
        max-width: 650% !important
    }

    .mn-wd-xl-650p-f {
        min-width: 650% !important
    }

    .wd-xl-700 {
        width: 700px
    }

    .wd-xl-700p {
        width: 700%
    }

    .mx-wd-xl-700p {
        max-width: 700%
    }

    .mn-wd-xl-700p {
        min-width: 700%
    }

    .wd-xl-700-f {
        width: 700px !important
    }

    .wd-xl-700p-f {
        width: 700% !important
    }

    .mx-wd-xl-700p-f {
        max-width: 700% !important
    }

    .mn-wd-xl-700p-f {
        min-width: 700% !important
    }

    .wd-xl-750 {
        width: 750px
    }

    .wd-xl-750p {
        width: 750%
    }

    .mx-wd-xl-750p {
        max-width: 750%
    }

    .mn-wd-xl-750p {
        min-width: 750%
    }

    .wd-xl-750-f {
        width: 750px !important
    }

    .wd-xl-750p-f {
        width: 750% !important
    }

    .mx-wd-xl-750p-f {
        max-width: 750% !important
    }

    .mn-wd-xl-750p-f {
        min-width: 750% !important
    }

    .wd-xl-800 {
        width: 800px
    }

    .wd-xl-800p {
        width: 800%
    }

    .mx-wd-xl-800p {
        max-width: 800%
    }

    .mn-wd-xl-800p {
        min-width: 800%
    }

    .wd-xl-800-f {
        width: 800px !important
    }

    .wd-xl-800p-f {
        width: 800% !important
    }

    .mx-wd-xl-800p-f {
        max-width: 800% !important
    }

    .mn-wd-xl-800p-f {
        min-width: 800% !important
    }

    .wd-xl-850 {
        width: 850px
    }

    .wd-xl-850p {
        width: 850%
    }

    .mx-wd-xl-850p {
        max-width: 850%
    }

    .mn-wd-xl-850p {
        min-width: 850%
    }

    .wd-xl-850-f {
        width: 850px !important
    }

    .wd-xl-850p-f {
        width: 850% !important
    }

    .mx-wd-xl-850p-f {
        max-width: 850% !important
    }

    .mn-wd-xl-850p-f {
        min-width: 850% !important
    }

    .wd-xl-900 {
        width: 900px
    }

    .wd-xl-900p {
        width: 900%
    }

    .mx-wd-xl-900p {
        max-width: 900%
    }

    .mn-wd-xl-900p {
        min-width: 900%
    }

    .wd-xl-900-f {
        width: 900px !important
    }

    .wd-xl-900p-f {
        width: 900% !important
    }

    .mx-wd-xl-900p-f {
        max-width: 900% !important
    }

    .mn-wd-xl-900p-f {
        min-width: 900% !important
    }

    .wd-xl-950 {
        width: 950px
    }

    .wd-xl-950p {
        width: 950%
    }

    .mx-wd-xl-950p {
        max-width: 950%
    }

    .mn-wd-xl-950p {
        min-width: 950%
    }

    .wd-xl-950-f {
        width: 950px !important
    }

    .wd-xl-950p-f {
        width: 950% !important
    }

    .mx-wd-xl-950p-f {
        max-width: 950% !important
    }

    .mn-wd-xl-950p-f {
        min-width: 950% !important
    }

    .wd-xl-1000 {
        width: 1000px
    }

    .wd-xl-1000p {
        width: 1000%
    }

    .mx-wd-xl-1000p {
        max-width: 1000%
    }

    .mn-wd-xl-1000p {
        min-width: 1000%
    }

    .wd-xl-1000-f {
        width: 1000px !important
    }

    .wd-xl-1000p-f {
        width: 1000% !important
    }

    .mx-wd-xl-1000p-f {
        max-width: 1000% !important
    }

    .mn-wd-xl-1000p-f {
        min-width: 1000% !important
    }

    .wd-xl-auto {
        width: auto
    }

    .wd-xl-auto {
        width: auto !important
    }
}

.wd-100v {
    width: 100vw
}

.wd-1 {
    width: 1px
}

.wd-2 {
    width: 2px
}

.wd-3 {
    width: 3px
}

.wd-4 {
    width: 4px
}

.wd-6 {
    width: 6px
}

.wd-7 {
    width: 7px
}

.wd-8 {
    width: 8px
}

.wd-9 {
    width: 9px
}

.wd-280 {
    width: 280px
}