.loader-area {
    padding-top: 20%;
    position: absolute;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
    width: 100%;
    height: 100%;
    background-color: #f3f3f3;
    position: fixed;
    z-index: 10000;
}

.loader-area-relative {
    padding-top: 20%;
    position: relative;
    width: 100%;
    height: 100%;
    background-color: #f3f3f3;
}

.loader-area .loader,
.loader-area-relative .loader {
    display: block;
    margin-bottom: 10px;
    border: 3px solid #f3f3f3;
    border-radius: 50%;
    border-top: 3px solid #0168fa;
    width: 40px;
    height: 40px;
    -webkit-animation: spin 1s linear infinite;
    /* Safari */
    animation: spin 1s linear infinite;
}

/* Safari */
@-webkit-keyframes spin {
    0% {
        -webkit-transform: rotate(0deg);
    }

    100% {
        -webkit-transform: rotate(360deg);
    }
}

@keyframes spin {
    0% {
        transform: rotate(0deg);
    }

    100% {
        transform: rotate(360deg);
    }
}